import { dataURItoBlob, getFileExtension } from '../../helpers/dataHelpers';
import { fetchReceipt, fetchWrapper } from '../../helpers/helpers';
import { selectActualLanguage } from '../common/common.selector';
import {
    CONTRACTS_LIST_ERROR,
    CONTRACTS_LIST_RECEIVED,
    TOGGLE_CONTRACTS_LOADING
} from './contract.actionTypes';

const contractsListReceived = data => ({ type: CONTRACTS_LIST_RECEIVED, data });
const contractsListError = () => ({ type: CONTRACTS_LIST_ERROR });
const toggleContractLoading = loading => ({ type: TOGGLE_CONTRACTS_LOADING, loading });

// eslint-disable-next-line import/prefer-default-export
export const getContractsList = () => (dispatch, getState) => {
    const actualLanguage = selectActualLanguage(getState());
    dispatch(toggleContractLoading(true));
    return fetchWrapper({
        url: '/api/v1/customers/contracts/',
        method: 'GET',
        headers: {
            'accept-language': actualLanguage
        }
    }).then((response) => {
        dispatch(contractsListReceived(response));
    }).catch(err => dispatch(contractsListError(err)));
};

export const attachFile = ({ attachment, type }) => (dispatch, getState) => {
    dispatch(toggleContractLoading(true));
    const actualLanguage = selectActualLanguage(getState());
    const payload = new FormData();
    const extension = getFileExtension(type);
    const file = dataURItoBlob(attachment, `attachment.${extension}`);
    payload.append('attachment', file);
    return fetchWrapper({
        url: '/api/v1/customers/attachments/',
        method: 'POST',
        body: payload,
        headers: {
            'accept-language': actualLanguage
        }
    }, true)
        .then(response => ({ ...response, type }))
        .finally(() => {
            dispatch(toggleContractLoading(false));
        });
};

export const submitClaim = payload => (dispatch, getState) => {
    dispatch(toggleContractLoading(true));
    const actualLanguage = selectActualLanguage(getState());
    return fetchWrapper({
        url: '/api/v1/customers/claims/',
        method: 'POST',
        body: payload,
        headers: {
            'accept-language': actualLanguage
        }
    })
        .finally(() => {
            dispatch(toggleContractLoading(false));
        });
};

export const cancelContract = contractId => (dispatch, getState) => {
    dispatch(toggleContractLoading(true));
    const actualLanguage = selectActualLanguage(getState());
    return fetchWrapper({
        url: `/api/v1/customers/contracts/${contractId}/cancellation/`,
        method: 'PUT',
        body: new FormData(),
        headers: {
            'accept-language': actualLanguage
        }
    }, true)
        .finally(() => {
            dispatch(toggleContractLoading(false));
        });
};

export const reactivateContract = contractId => (dispatch) => {
    dispatch(toggleContractLoading(true));
    return fetchWrapper({
        url: `/api/v1/customers/contracts/${contractId}/reactivation/`,
        method: 'PUT',
        body: new FormData(),
    }, true)
        .finally(() => {
            dispatch(toggleContractLoading(false));
        });
};

export const getReceipt = ({ uuid }) => (dispatch, getState) => {
    dispatch(toggleContractLoading(true));
    const language = selectActualLanguage(getState());
    return fetchReceipt({ uuid, language })
        .finally(() => {
            dispatch(toggleContractLoading(false));
        });
};
