import React, { useState } from 'react';
import cn from 'classnames';
import OutsideClick from './OutsideClick';

import downArrow from '../../dist/images/down-arrow.svg';

const LanguageDropdown = ({ cultures, actualLanguage, changeLang }) => {
    const [isLangOpened, setIsLangOpened] = useState(false);
    const selectedCulture = cultures.find(culture => culture.code === actualLanguage) || {};
    const name = selectedCulture.language_code || '';

    return (
        <OutsideClick
            setOpen={setIsLangOpened}>
            <div className="language-option" onClick={() => { setIsLangOpened(!isLangOpened); }}>
                <div className="lang-btn-wrap">
                    <div className="lang-btn-name">{name}</div>
                </div>
                <div
                    className="lang-arrow"
                    style={{
                        backgroundImage: `url(${downArrow})`,
                        transform: isLangOpened && 'scale(1, -1)'
                    }}
                />
                <div className="option-wrapper" style={{ display: isLangOpened ? 'block' : 'none' }}>
                    {cultures.map(language => (
                        <div className="lang-elem-wrap" onClick={() => changeLang(language.code, language.currency_position)} key={language.code}>
                            <div className="lang-btn-name">{language.language_code}</div>
                            <div className={cn('lang-btn-indicator', language.code === actualLanguage && 'active-lang')} />
                        </div>
                    ))}
                </div>
            </div>
        </OutsideClick>
    );
};

export default LanguageDropdown;
