import { fetchWrapper } from './helpers';
import { messages } from '../constants/messages';

const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NIF_REGEXP = /^(X(-|\.)?0?\d{7}(-|\.)?[A-Z]|[A-Z](-|\.)?\d{7}(-|\.)?[0-9A-Z]|\d{8}(-|\.)?[A-Z])$/i;
const PHONE_REGEXP = /^[0-9\-+]{9,15}$/;

export function validateMandatory(value) {
    return { code: !value ? messages.MANDATORY_FIELD : '' };
}

export const fetchZip = async (code, country = 'ES') => {
    try {
        const result = await fetchWrapper({
            url: `https://zip-api.eu/api/v1/info/${country}-${code}`,
            method: 'GET',
            sideRequest: true
        });
        return { city: JSON.parse(result).place_name, code: '', state: JSON.parse(result).state };
    } catch (err) {
        return { code: messages.INVALID_FORMAT };
    }
};

export const validateZip = (code, country) => {
    const error = validateMandatory(code);
    if (error.code) {
        return error;
    }
    if (country) {
        return fetchZip(code, country);
    }
    return { code: '' };
};

export const validateNif = (value) => {
    const check = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const error = validateMandatory(value);
    if (error.code) {
        return error;
    }
    if (!NIF_REGEXP.test(value)) {
        return { code: messages.INVALID_FORMAT };
    }
    const dni = value.toUpperCase();
    let num;
    try {
        num = parseInt(dni.substring(0, dni.length - 1), 10);
    } catch (err) {
        return { code: messages.INVALID_FORMAT };
    }
    const ltr = dni.substring(dni.length - 1).toUpperCase();
    num %= 23;
    return { code: check.charAt(num) !== ltr ? messages.INVALID_FORMAT : '' };
};

export const validateEmail = (value) => {
    const error = validateMandatory(value);
    if (error.code) {
        return error;
    }
    return { code: !EMAIL_REGEXP.test(value.trim()) ? messages.INVALID_FORMAT : '' };
};

export function validatePhone(value) {
    const error = validateMandatory(value);
    if (error.code) {
        return error;
    }
    return { code: !PHONE_REGEXP.test(value.replace(/\s/g, '')) ? messages.INVALID_FORMAT : '' };
}
