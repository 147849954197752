import React from 'react';
import cn from 'classnames';

const Tabs = ({ tabs = [], onTabClick, activeTab }) => (<div className="merchants-tab-wrapper">
    {
        tabs.map(({ label }, k) => <span key={k} onClick={() => onTabClick(k)} className={cn('merchants-tab', { active: k === activeTab })}>{label}</span>)
    }
</div>);

export default Tabs;
