import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
// import { faMessage } from '@fortawesome/free-regular-svg-icons';
import Button from '../Button/Button';
import BackIcon from './BackIcon';
import backn from '../../../dist/images/BackN.svg';

import './Footer.scss';
import { messages } from '../../../constants/messages';
import { useBackgroundColor, useLanguage } from '../../../helpers/customHooks';

const Footer = ({
    showBack, showNext, onNavBack, onNextStep, nextText, disabled, children, className,
    submit = false, isHelp = false, openModal, showNextArrow = true, title = undefined
}) => {
    useLanguage();
    const backgroundColor = useBackgroundColor(0.85);
    return (
        <div
            className={classNames('footer-wrapper', {
                [className]: !!className
            })}
            style={{ backgroundColor }}>
            {isHelp && <div className="help-block" onClick={openModal}><FontAwesomeIcon icon={faMessage} className="help-btn" /><span>?</span></div>}
            {showBack && (
                <Button
                    onClick={onNavBack}
                    type="transparent"
                    className="back-footer-btn"
                >
                    <BackIcon />
                </Button>
            )}
            {children}
            {showNext && (
                <Button
                    className="footer-btn"
                    disabled={disabled}
                    onClick={disabled ? null : onNextStep}
                    size="medium"
                >
                    {(title && title) || submit ? 'OK' : (nextText || messages.NEXT)}
                    {!submit && showNextArrow && <img className="icon-next" alt="submit" src={backn} />}
                </Button>
            )}
        </div>
    );
};

Footer.propTypes = {
    onNavBack: PropTypes.func,
    onNextStep: PropTypes.func,
    showBack: PropTypes.bool,
    showNext: PropTypes.bool,
    disabled: PropTypes.bool
};
Footer.defaultProps = {
    onNavBack: () => null,
    onNextStep: () => null,
    showBack: true,
    showNext: true,
    disabled: false
};

export default Footer;
