import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { selectWhiteLabelingStyles } from '../../../store/selectors';

const Hint = ({ onClick, type }) => {
    const { whiteLabelingPrimaryColor } = useSelector(selectWhiteLabelingStyles);
    return (
        <div className="attribute-hint" onClick={onClick}>
            {!type ? <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>FB4669E2-834C-46C5-8131-969C09162CF8</title>
                <g id="LeverX-update-4" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Item-details.-Hint" transform="translate(-146.000000, -506.000000)">
                        <g id="Group" transform="translate(20.000000, 270.000000)">
                            <g id="Question-mark" transform="translate(127.000000, 237.000000)">
                                <g id="Check-mark/Selected" fill={whiteLabelingPrimaryColor}>
                                    <circle id="Oval" stroke="#FFFFFF" strokeLinejoin="round" cx="10" cy="10" r="10" />
                                    <circle id="Oval" cx="10" cy="10" r="8" />
                                </g>
                                <text id="?" fontFamily="Rubik-SemiBold, Rubik" fontSize="13" fontWeight="500" fill="#FFFFFF">
                                    <tspan x="6" y="15">?</tspan>
                                </text>
                            </g>
                        </g>
                    </g>
                </g>
            </svg> : <FontAwesomeIcon
                icon={faCircleInfo}
                style={{ color: whiteLabelingPrimaryColor }} />
            }
        </div>
    );
};

export default Hint;
