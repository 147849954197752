import React from 'react';
import { messages } from '../../constants/messages';

const WhiteLabelingSettings = ({ children }) => (
    <section className="white-labeling-settings">
        <header className="page-title white-labeling-settings__title">
            {messages.MY_WHITE_LABELS}
        </header>
        <main>
            { children }
        </main>
    </section>
);
export default WhiteLabelingSettings;
