import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';

import './Dropdown.scss';

const useStyles = makeStyles(theme => ({
    filled: {
        paddingTop: '10px'
    },
    selectMenu: {
        fontSize: 20,
        minWidth: '200px',
        borderRadius: 10,
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: theme.palette.main.light,
        '.dropdown-option': {
            fontSize: 20
        },
        '.MuiSelect-select': {
            padding: 0,
        }
    },
    root: {
        fontSize: 20
    },
}));

const useStylesLarge = makeStyles(theme => ({
    selectMenu: {
        fontSize: 20,
        minWidth: '200px',
        borderRadius: 10,
        backgroundColor: theme.palette.main.light,
        '.dropdown-option': {
            fontSize: 20,

            '.MuiSelect-select': {
                padding: 0,
            }
        },
        root: {
            fontSize: 20
        },
    }
}));

const useClasses = makeStyles(theme => ({
    large: {
        width: '100%',
        '& .MuiFormLabel-root': {
            fontSize: 20,
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.6)',
        },
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    formControl: {
        minWidth: 300,
        boxSizing: 'border-box',
        verticalAlign: 'center',
        padding: '0px',
        '.MuiInputBase-formControl:hover:before': {
            borderBottom: 'none'
        },
        '.MuiInputBase-formControl:hover': {
            backgroundColor: '#d3d3d3',
        },
    },
    light: {
        backgroundColor: '#F8F9FF',
    },
    dark: {
        backgroundColor: '#F8F9FF',
    },
    error: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.error.main}`,
        }
    }
}));

const menuStyles = makeStyles({
    list: {
        root: {
            fontSize: 20
        }
    }
});

const Dropdown = (
    {
        err = false,
        value,
        handleChange,
        name,
        options,
        color = 'light',
        native,
        className,
        label,
        large,
        renderValue,
        multiple,
        open,
        onOpen,
        onClose,
        required
    }) => {
    const styles = useStyles();
    const stylesLarge = useStylesLarge();
    const classes = useClasses();
    const menuClasses = menuStyles();

    return (
        <div className="dropdown">
            <FormControl variant="outlined" className={`${classes.formControl} ${large && classes.large} ${className}`}>
                {label ?
                    (
                        <>
                            <InputLabel>{label}</InputLabel>
                            <Select
                                required={required}
                                native={native}
                                value={value}
                                multiple={multiple}
                                onChange={handleChange}
                                name={name}
                                label={label}
                                className={`${classes[color]} ${err && classes.error}`}
                                classes={large && stylesLarge}
                                displayEmpty
                                renderValue={renderValue}
                                MenuProps={{
                                    classes: menuClasses
                                }}
                                open={open}
                                onOpen={onOpen}
                                onClose={onClose}
                                tabIndex="0"
                            >
                                {
                                    options.map(({ pk, value: optionValue }) => (
                                        <MenuItem
                                            className="dropdown-option"
                                            key={pk}
                                            value={pk}
                                        >
                                            {optionValue}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </>
                    )
                    :
                    (
                        <Select
                            required={required}
                            native={native}
                            value={value}
                            onChange={handleChange}
                            name={name}
                            className={`${classes[color]} ${err && classes.error}`}
                            classes={styles}
                            displayEmpty
                            MenuProps={{
                                classes: menuClasses
                            }}
                        >
                            {
                                options.map(({ pk, value: optionValue }) => (
                                    <MenuItem className="dropdown-option" key={pk} value={pk}>{optionValue}</MenuItem>
                                ))
                            }
                        </Select>
                    )}
                {err && <div className="dropdown-err">{err}</div>}
            </FormControl>
        </div>
    );
};
export default Dropdown;
