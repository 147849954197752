import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    selectCurrentGoodsId,
    selectInsuranceTypes,
    currency } from '../../../store/productInsurance/productInsurance.selector';
import {
    selectProfile,
} from '../../../store/common/common.selector';
import { messages } from '../../../constants/messages';
import Footer from '../../Common/Footer/Footer';
import Select from '../../Common/Select/Select';
import Spinner from '../../Common/SpinnerLoader/Spinner';
import Loader from '../../Common/Loader/Loader';

import './Coverage.scss';
import Header from '../../Header';
import { useLanguage } from '../../../helpers/customHooks';
import bicycle from '../../../dist/images/bicycle.png';
import eBike from '../../../dist/images/eBike.png';

import cross from '../../../dist/images/cross.svg';
import tick from '../../../dist/images/tick.svg';
import withTheme from '../../../hocs/withTheme';

import { checkCoverage } from '../../../helpers/amplitude';

import { getCoverageInsuranceCompanies, getCoverageInsuranceProducts, getCoverage } from '../../../store/productInsurance/productInsurance.actions';


const Coverage = ({ setOpen, onNext }) => {
    const currentGoodsId = useSelector(selectCurrentGoodsId);
    const iTypes = useSelector(selectInsuranceTypes);
    const currentGoodName = iTypes.find(({ id }) => id === currentGoodsId)?.name;
    const curr = useSelector(currency);
    const { lead_uuid } = useSelector(selectProfile);

    const lang = useLanguage();

    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');

    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');

    const [coverage, setCoverage] = useState({});
    const [deductible, setDeductible] = useState({});

    const [coverageAvailable, setCoverageAvailable] = useState(false);
    const [isTableShown, setIsTableShown] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isProductPending, setIsProductPending] = useState(false);

    useEffect(() => {
        getCoverageInsuranceCompanies()
            .then((response) => {
                setCompanies(() => response.map(({ pk, value }) => ({ value: pk, label: value })));
            });
    }, []);

    useEffect(() => {
        if (companies.length) {
            setSelectedCompany(companies[0]);
            setIsProductPending(true);
            getCoverageInsuranceProducts(companies[0].value)
                .then((response) => {
                    setCoverageAvailable(true);
                    setProducts(() =>
                        response.map(({ pk, value }) => ({ value: pk, label: value })));
                });
        }
    }, [companies]);

    useEffect(() => {
        if (products.length) {
            setSelectedProduct(products[0]);
            setIsProductPending(false);
            setIsLoading(false);
        }
    }, [products]);

    useEffect(() => {
        if (selectedProduct) {
            setIsLoading(true);
            getCoverage(selectedProduct.value, lang)
                .then((response) => {
                    const { risks, deductibles } = response.filter(({ insurance_uuid }) =>
                        insurance_uuid === selectedProduct.value)[0];
                    setCoverage(risks);
                    setDeductible(deductibles[0]);
                    setIsTableShown(true);
                    setIsLoading(false);
                });
        }
    }, [selectedProduct, lang]);

    const onSelectCompany = (e) => {
        if (e.value !== selectedCompany.value) {
            checkCoverage({ uuid: lead_uuid });
            setSelectedCompany(e);
            setIsTableShown(false);
            setIsProductPending(true);
            setSelectedProduct('');
            setProducts([]);
            getCoverageInsuranceProducts(e.value)
                .then((response) => {
                    setProducts(() =>
                        response.map(({ pk, value }) => ({ value: pk, label: value })));
                });
        }
    };

    const onSelectProduct = (e) => {
        if (e.value !== selectedProduct.value) {
            checkCoverage({ uuid: lead_uuid });
            setSelectedProduct(e);
        }
    };

    const onBackStep = () => setOpen(false);

    const image = {
        35: eBike,
        34: bicycle
    };

    console.log(isProductPending);

    return (
        <div className="coverage__wrapper content">
            {!coverageAvailable && <Loader />}
            <Header
                stepsTitle={messages.INSURANCE_COVERAGE}
                stepper
            />
            <main className="coverage">
                {currentGoodName && <div className="coverage__item">
                    {image[currentGoodsId] && <div className="item__img">
                        <img className="img" src={image[currentGoodsId]} alt="insurance logo" />
                    </div>}
                    <div className="coverage-product">
                        <div className="coverage-product__item-name">
                            {currentGoodName}
                        </div>
                        <div className="coverage-product__description">
                            {messages.COVERAGE_HOME_INSURANCE_DESCRIPTION}
                        </div>
                    </div>
                </div>
                }
                <Select
                    isCoveragePage
                    value={selectedCompany}
                    options={companies}
                    label={messages.INSURANCE_COMPANY_LABEL}
                    onChange={onSelectCompany} />

                <div className="coverage-product__select">
                    <Select
                        isCoveragePage
                        value={selectedProduct}
                        options={products}
                        label={messages.INSURANCE_PRODUCT_LABEL}
                        onChange={onSelectProduct}
                        isLoading={isProductPending}
                    />
                    {isProductPending && <Spinner />}
                </div>

                <div className="coverage__table">
                    <header className="table__title">
                        <div className="table__title-type">{messages.INCIDENT_TYPE}</div>
                        <div className="table__title-val">{messages.OUTSIDE_INSIDE}</div>
                    </header>
                    {
                        !isLoading ?
                            isTableShown && <><main className="table__content">
                                {
                                    Object.entries(coverage).map(([key, value], idx) => (
                                        <div key={idx} className="content__row">
                                            <div className="content__row-type">
                                                {key}
                                            </div>
                                            <div className="content__row-val">
                                                <img className="img" src={value ? tick : cross} alt="cross" />
                                            </div>
                                        </div>
                                    ))
                                }
                            </main>
                            </> : <Spinner />
                    }
                </div>
                {(!isLoading && isTableShown) ? <>
                    <div className="coverage__table">
                        <header className="table__title">
                            <div className="table__title-type">{messages.DETAILS}</div>
                            <div className="table__title-val" />
                        </header>
                        <main className="table__content">
                            <div className="content__row">
                                <div className="content__row-type">{messages.DEDUCTIBLE}</div>
                                <div className="content__row-val">{`${curr} ${deductible.deductible}`}</div>
                            </div>
                            <div className="content__row">
                                <div className="content__row-type">{messages.MAX_AMOUNT}</div>
                                <div className="content__row-val">{`${curr} ${deductible.max_amount}`}</div>
                            </div>
                        </main>
                    </div>
                    <div className="coverage__description">
                        <span>{messages.REMARKS}:</span>
                        {deductible.description}
                    </div>
                </>
                    : null}
            </main>
            <Footer
                onNavBack={onBackStep}
                showNext={!window.location.hash.includes('coverage')}
                onNextStep={onNext}
                nextText={messages.OPTIMALLY_INSURE}
            />
        </div>
    );
};
export default withTheme(Coverage);
