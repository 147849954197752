import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { messages } from '../../constants/messages';
import { selectActualLanguage, selectCurrencyPosition } from '../../store/selectors';
import InsuranceCondition from '../InsuranceOverview/InsuranceConditions/InsuranceCondition';

import './ProductDetails.scss';

const ProductDetails = (props) => {
    const actualLanguage = useSelector(selectActualLanguage);
    const [accepted, setAccepted] = useState(false);

    const {
        showDeductible = true,
        brandingName,
        productDescription,
        contractLengthUnit,
        contractLength,
        contractMinimumLength,
        contractMaximumLength,
        paymentScheme,
        /* deductible, */
        /* currency, */
        cover = [],
        nonCover,
        productSheet,
        isLogoVisible,
        logo,
        isPreContract = false,
        changeBtnStatus = null,
        insuredCapital,
        currencySymbol
    } = props;

    const units = {
        YEAR: messages.YEARS,
        YEARS: messages.YEARS,
        MONTH: messages.MONTHS,
        MONTHS: messages.MONTHS,
        WEEK: messages.WEEKS,
        WEEKS: messages.WEEKS,
    };

    const schemeList = {
        MONTHLY: messages.OVERVIEW_MONTHLY,
        WEEKLY: messages.OVERVIEW_WEEKLY,
        YEARLY: messages.OVERVIEW_YEARLY,
        QUARTERLY: messages.QUARTERLY,
        UPFRONT: messages.OVERVIEW_UPFRONT,
    };

    const unit = units[contractLengthUnit] || '';

    const onAccept = () => {
        setAccepted(!accepted);
    };

    useEffect(() => {
        if (changeBtnStatus) {
            changeBtnStatus(accepted);
        }
    }, [accepted]);

    const currencyPosition = useSelector(selectCurrencyPosition);

    return (
        <div className="insurance-main-info-wrap">
            <div className="insurance-main-info">
                {isLogoVisible && logo && (
                    <div className="insurance-main-info__logo">
                        <img className="insurance-logo" src={logo} alt="insurance logo" />
                    </div>
                )}
                <div className="insurance-main-info__header">
                    <div className="title">{brandingName}</div>
                    <div className="description">{productDescription}</div>
                </div>
            </div>
            <div className="insurance-main-info">
                <div className="title">{messages.GENERAL_CONDITIONS}</div>
                <div className="description general-conditions">
                    {!!+insuredCapital && <p>{messages.INSURED_CAPITAL}{currencyPosition === 'BEFORE' && currencySymbol}{insuredCapital}{currencyPosition !== 'BEFORE' && currencySymbol}</p>}
                    {contractLength && <p>{`${messages.LENGTH}${contractLength} ${unit}`}</p>}
                    {contractMinimumLength && <p>{`${messages.MINIMUM_LENGTH}${contractMinimumLength} ${unit}`}</p>}
                    {contractMaximumLength && <p>{`${messages.MAXIMUM_LENGTH}${contractMaximumLength} ${unit}`}</p>}
                    {paymentScheme && paymentScheme !== 'UPFRONT' && <p>{`${messages.CONTRACT_TERMINATION}${schemeList[paymentScheme]}`}</p>}
                    {/* {deductible &&
                    <p>{`${messages.DEDUCTIBLE}${deductible} ${currency}`}</p>} */}
                    {showDeductible && <p>{messages.WITHOUT_DEDUCTIBLE}</p>}
                </div>
            </div>
            <div className="coverage-list-wrap">
                <div className="title">{messages.COVERAGE_LIST}</div>
                <div className="coverage-list">
                    <div className="coverage-title">{messages.COVERAGE}</div>
                    {cover.map((el, index) =>
                        <InsuranceCondition
                            key={index}
                            status="coverage"
                            text={el}
                            actualLanguage={actualLanguage} />
                    )}
                </div>
                {!!nonCover.length && <div className="non-covered-list">
                    <div className="non-covered-title">{messages.NON_COVERED}</div>
                    {nonCover.map((el, index) =>
                        <InsuranceCondition
                            key={index}
                            status="non-coverage"
                            text={el}
                            actualLanguage={actualLanguage} />
                    )}
                </div>}
                {isPreContract && <div
                    className="siccess-read"
                    onClick={onAccept}>
                    <div className={cn('checkbox-off', { 'checkbox-on': accepted })}>
                        <div />
                    </div><div>{messages.PRE_CONTRACT_ACCEPT}</div></div>
                }
                {productSheet && <div className="download"><a href={productSheet} target="_blank" rel="noreferrer">{messages.DOWNLOAD_INSURANCE_PRODUCT}</a></div>}
            </div>
        </div>
    );
};

export default ProductDetails;
