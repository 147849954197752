const sortDataByIncrease = (data, sortBy) => data.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) {
        return -1;
    }
    if (a[sortBy] > b[sortBy]) {
        return 1;
    }
    return 0;
});

export const sortDataByDecrease = (data, sortBy) => sortDataByIncrease(data, sortBy).reverse();

export default sortDataByIncrease;
