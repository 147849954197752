import convert from 'color-convert';
import { PRIMARY_COLOR } from '../constants/constants';

export default function calculateColorWithOpacity(color = PRIMARY_COLOR, opacity = 0.3) {
    let _color = color.replace('#', '');
    _color = convert.hex.rgb(_color);
    _color = `rgba(${_color[0]},${_color[1]},${_color[2]}, ${opacity})`;
    return _color;
}

export const getInputBackground = color => calculateColorWithOpacity(color, 0.5);
