import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useContract, useLanguage } from '../../../helpers/customHooks';
import { messages } from '../../../constants/messages';
import { selectWhiteLabelingStyles } from '../../../store/selectors';
import { setupCustomStyles } from '../../../helpers/renderingHelpers';
import ConfirmationIcon from './ConfirmationIcon';
import Button from '../../Common/Button/Button';
import withTheme from '../../../hocs/withTheme';

import './Confirmation.scss';

const Confirmation = ({ title, showIcon }) => {
    const { id } = useParams();
    useContract(id);
    useLanguage();
    const history = useHistory();
    const whiteLabelingStyles = useSelector(selectWhiteLabelingStyles);
    setupCustomStyles(whiteLabelingStyles);

    const onNext = () => history.push('/purchases');

    return (
        <div className="confirmation">
            <div className="confirmation__title">{title}</div>
            {showIcon && <ConfirmationIcon className="confirmation__image" />}
            <Button
                onClick={onNext}
            >
                {messages.GOT_IT}
            </Button>
        </div>
    );
};

export default withTheme(Confirmation);
