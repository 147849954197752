const pl = {
CHOOSE_ITEM_TO_CHECK: "Please choose the item for which you want to check coverage",
INSURANCE_COMPANY_LABEL: 'Firma ubezpieczeniowa',
INSURANCE_PRODUCT_LABEL: 'Produkt ubezpieczeniowy',
    //steps
    STEP1: 'Dane',
    STEP2: 'Oferta',
    STEP3: 'Zamknięcie',
    CHOOSE_ITEM_TO_INSURE: 'Wybierz przedmiot, który chcesz ubezpieczyć.',
    // Insurance overview
    INSURANCE_OVERVIEW: 'Przegląd ubezpieczeń',
    COVERAGE: 'pokrywa:',
    NON_COVERED: 'Nieobjęte:',
    DOWNLOAD_INSURANCE_PRODUCT: 'Pobierz szczegółowy opis ubezpieczenia',
    DOWNLOAD_TERMS_AND_CONDITIONS: 'Zasady i warunki pobierania',
    PRODUCT_INSURANCE_STEP_ONE_TITLE: 'Tylko kilka kroków, aby zostać objętym ubezpieczeniem',
    PRODUCT_INSURANCE_STEP_TWO_TITLE: 'Odkryte produkty z paragonu',
    PRODUCT_INSURANCE_STEP_THREE_TITLE: 'Wybierz swoje ubezpieczenie:',
    PRODUCT_INSURANCE_STEP_TWO_HINT: 'Kategorie produktów',
    PRODUCT_INSURANCE_STEP_ONE_ONLINE: 'Powiedz nam, co chcesz chronić.',
    ENTER_MORE_DETAILS: 'Wprowadź więcej szczegółów na temat przedmiotu, który chcesz zabezpieczyć.',
    TAKE_RECEIPT_PHOTO: 'Proszę zrobić zdjęcie paragonu zakupu.',
    CHECK_RECEIPT: 'Proszę sprawdzić, czy paragon jest czytelny na zdjęciu.',
    CHECK_ITEM_PHOTO: 'Sprawdź, czy przedmiot jest wyraźny na zdjęciu.',
    TAKE_ITEM_PHOTO: 'Zrób zdjęcie przedmiotu, który chcesz zakryć.',
    BUTTON: 'Przycisk',
    MORE_DETAILS: 'Pokaż szczegóły',
    BACK: 'Z powrotem',
    SKIP_RECEIPT: 'Pokwitowanie odbioru',
    NEXT: 'Dalej',
    GENERAL_CONDITIONS: 'Warunki ogólne',
    LENGTH: 'Czas trwania: ',
    MINIMUM_LENGTH: 'Minimalny czas trwania umowy: ',
    MAXIMUM_LENGTH: 'Maksymalny czas trwania umowy: ',
    CONTRACT_TERMINATION: 'Rozwiązanie umowy: ',
    COVERAGE_LIST: 'Zakres umowy:',
    SEARCH: 'Szukaj',
    OTHER: 'Inny',
    MORE: 'Więcej …',
    CLOSE: 'Blisko',
    PHOTO: 'Zdjęcie',
    OR: 'Lub',
    OPTIONS: 'Opcje',
    SHOW_MORE: 'Pokaż więcej',
    DO_NOT_INSURE: 'Nie ubezpieczaj tego przedmiotu',
    NOT_INSURABLE: 'Ten przedmiot nie podlega ubezpieczeniu',
    SELECT_ITEM_TYPE: 'Proszę wybrać typ przedmiotu',
    ADD_UNRECOGNIZED_ITEM: 'Dodaj przedmiot',
    NAME: 'Nazwa',
    PLEASE_WAIT: 'Proszę czekać',
    RECOGNIZE_PROCESSING: 'Aplikacja przetwarza zdjęcie(a) w celu wydobycia istotnych informacji.',
    PRICE: 'Cena',
    CANCEL: 'Anuluj',
    OK: 'Ok',
    SAVE_AND_NEXT: 'Dalej',
    MY_NEW_ITEM: 'Mój nowy ukochany przedmiot',
    INSURANCE_COVERAGE: 'Zakres ubezpieczenia',
    OPTIMALLY_INSURE: 'Optymalnie ubezpieczaj',
    COVERAGE_HOME_INSURANCE_DESCRIPTION: 'Zakres ubezpieczenia domu',
    COVERAGE_DESCRIPTION: 'Poniższy przegląd zakresu ochrony ubezpieczeniowej jest uogólniony i oparty na typowym podstawowym polskim produkcie ubezpieczenia domu. Czy chcesz sprawdzić zakres swojego konkretnego produktu ubezpieczenia domu? Kliknij tutaj:',
    HOME_INSURANCE: 'Wybierz ubezpieczenie domu',
    INCIDENT_TYPE: 'Ryzyko',
    OUTSIDE_INSIDE: 'Zakres umowy:',

    // Inform Screen
    DEAR_CUSTOMER: 'Drogi kliencie,',
    TO_OFFER_YOU: 'Aby zaoferować Państwu najlepsze opcje ubezpieczenia, potrzebujemy więcej informacji na temat właśnie zakupionej rzeczy. Dostępne opcje:',
    /* OPTION_TAKE_A_PHOTO: 'Zrobić zdjęcie paragonu zakupu',
    OPTION_UPLOAD_FROM_GGALLERY: 'Wgraj zdjęcie paragonu zakupu', */
    OPTION_TAKE_A_PHOTO: 'Zrobić zdjęcie',
        OPTION_UPLOAD_FROM_GGALLERY: 'Wgraj zdjęcie',
    OPTION_ENTER_MANUALLY: 'Wprowadzanie danych ręcznie',
    OPTION_TAKE_A_PHOTO_DESCRIPTION: 'Dostęp do kamery może być wymagany.',
    OPTION_UPLOAD_FROM_GGALLERY_DESCRIPTION: 'Dostęp do zdjęć, mediów i plików może być wymagany.',

    // Empty State
    SORRY: 'Przepraszam',
    SORRY_MESSAGE: 'Przepraszamy, ale link do oferty nie jest już aktualny. Chętnie złożymy Państwu ofertę przy następnym zakupie.',
    DONE: 'Sporządzono',

    // Payment Methods
    PAYMENT_METHODS: 'Metody płatności',
    HOW_DO_YOU_WANT_TO_PAY: 'Jak chcesz zapłacić?',
    YOU_WILL_BE_SPENDING: 'Będziesz wydawał',
    FOR_BUYING_INSURANCE_POLICIES: 'za zakup polis ubezpieczeniowych',
    CONFIRM_YOUR_CARD_DETAILS: 'Proszę podać dane karty płatniczej.',
    HDRC_MONEY_BACK: 'Proszę podać dane karty płatniczej.',
    CARDHOLDER_NAME: 'Imię i nazwisko posiadacza karty',
    CARD_NUMBER: 'Numer karty',
    EXPIRY_DATE: 'Termin ważności',
    CVV: 'Kod bezpieczeństwa (CVV)',
    CONFIRM_AND_PAY: 'Potwierdź i kup',
    I_HAVE_SUCCESSFULLY_READ: 'Przeczytałem i zgadzam się z regulaminem.',
    ENTRY_A_FEW_MORE_DETAILS: 'Wprowadź kilka dodatkowych szczegółów o Sobie.',
    FIRST_NAME: 'Imię',
    FAMILY_NAME: 'Nazwisko rodowe',
    BIRTHDAY: 'Urodziny',
    EMAIL_ADDRESS: 'Adres e-mail',
    PHONE_NUMBERS: 'Numer telefonu',
    ADDRESS: 'Adres',
    ENTRY_HERE: 'Wejdź tutaj',
    MONTHLY: 'miesięcznie',
    WEEKLY: 'na tydzień',
    YEARLY: 'na rok',
    OVERVIEW_YEARLY: 'Rocznie',
    OVERVIEW_MONTHLY: 'Miesiąc',
    OVERVIEW_WEEKLY: 'Co tydzień',
    WEEKS: 'tygodnie',
    MONTHS: 'miesiące',
    YEARS: 'lata',
    WHAT_IS_NEXT: 'Co jest dalej:',
    INSURANCE_CERTIFICATE: (email) =>  `Certyfikat ubezpieczeniowy zostanie wygenerowany i wysłany na ${email}.`,
    YOU_CAN_DOWNLOAD: 'Dodatkowo, możesz pobrać twój certyfikat ubezpieczenia na następnej stronie.',
    DEDUCTIBLE: 'Odliczalny: ',

    // validation
    MANDATORY_FIELD: 'To pole jest obowiązkowe',
    INVALID_FORMAT: 'Wartość nieważna',
    PRICE_SHOULD_BE_LOWER: 'Cena powinna być niższa od ceny transakcyjnej',
    DATE_SHOULD_BE_PAST: 'Data powinna być w przeszłości',
    TWO_DECIMAL_PLACES: 'Upewnij się, że nie ma więcej niż 2 miejsca po przecinku.',

    // Congratulations!
    CONGRATULATIONS: 'Gratulacje!',
    GLAD_TO_INFORM_YOU: 'Miło nam poinformować, że udało się Państwu kupić ubezpieczenie. Twój produkt jest teraz bezpieczny.',

    YOU_WILL_RECEIVE: 'W ciągu najbliższych minut otrzymasz wiadomość e-mail z potwierdzeniem zakupu wraz z certyfikatem ubezpieczenia.',

    YOU_ARE_PROTECTED: 'Jesteś chroniony',
    ALREADY_PURCHASED: 'Miło nam poinformować Państwa, że wykupili już Państwo ubezpieczenie i że Państwa ukochany przedmiot jest już chroniony.',

    DOWNLOAD: 'Pobierz',
    YOU_CAN_DOWNLOAD_HERE: 'Tutaj możesz pobrać swój certyfikat ubezpieczeniowy:',
    BACK_TO_OVERVIEW: 'Powrót do podglądu',

    // contracts list
    YOUR_POLICY_PURCHASES: 'Twoje ubezpieczeń',
    PURCHASED_ON: 'Zakupiony dnia:',
    EXPIRING_ON: 'Wygasa w dniu',
    WHATS_COVERED: 'Objęte ubezpieczeniem jest',
    FILE_A_CLAIM: 'Złóż wniosek',
    REACTIVATE: 'Reaktywuj',

    // contract details
    DOWNLOAD_PURCHASE_RECEIPT: 'Pobierz dowód zakupu',
    INSURCANCE_CONTRACT: 'Umowa ubezpieczeniowa',
    ITEM_IS_PROTECTED_BY_INSURANCE: 'Przedmiot objęty ochroną ubezpieczeniową',
    INSURANCE_COVERAGE_START: 'Początek ochrony ubezpieczeniowej',
    INSURANCE_COVERAGE_UNTIL: 'Ochrona ubezpieczeniowa do',
    DOWNLOAD_PRODUCT_SHEET: 'Pobierz kartę produktu',
    DOWNLOAD_INSURANCE_CERTIFICATE: 'Pobierz certyfikat',
    CANCEL_CONTRACT: 'Anuluj ubezpieczenie',

    // claim form
    PROVIDE_INFO_FOR_CLAIM: 'Prosimy o podanie kilku dodatkowych informacji, zanim rozpatrzymy Państwa roszczenie',
    ATTACH_PHOTO_VIDEO: 'Prosimy o dołączenie, jeśli to istotne, zdjęć lub filmów pokazujących szkodę.',
    DESCRIBE_WHAT_HAPPEND: 'Proszę opisać, co się stało',
    ENTER_HERE: 'Wejdź tutaj',
    SUBMIT_CLAIM: 'Zgłoś roszczenie',
    PLEASE_ATTACH_FILES: 'Proszę załączyć pliki',
    THANKS_FOR_REQUEST: 'Dziękujemy za złożenie wniosku. Z przyjemnością rozpatrzymy je i udzielimy Ci odpowiedzi w ciągu najbliższych dni.',
    YOUR_INSURANCE_COVERS: 'Twoje ubezpieczenie obejmuje naprawę wyłącznie przez jednego z naszych certyfikowanych partnerów. Tutaj znajdziesz punkt naprawczy w swojej okolicy.',
    SHOW_PARTNER_NETWORK: 'Pokaż sieć partnerską',

    // confirmation
    YOUR_CONTRACT_HAS_BEEN_CANCELLED: 'Twoje ubezpieczenie zostało anulowane. Wkrótce otrzymasz wiadomość e-mail z potwierdzeniem.',
    GOT_IT: 'Otrzymałem go',
    THE_INSURANCE_WILL_BE_CANCELLED: 'Ubezpieczenie zostanie anulowane począwszy od',
    ARE_YOU_SURE_TO_CANCEL: 'Czy na pewno chcesz zrezygnować z ochrony swojego',
    RETURN: 'Zwrot',
    CONFIRM_REACTIVATION: 'Proszę potwierdzić, że chcesz reaktywować ubezpieczenie.',
    YOUR_CONTRACT_HAS_BEEN_REACTIVATED: 'Twoje ubezpieczenie zostało reaktywowane. Wkrótce otrzymasz potwierdzenie pocztą.',

    // contract statuses
    ACTIVE: 'aktywny',
    PENDING_CANCELLATION: 'anulowane',
    VOID: 'zakończony',

    BENEFICIARY_TITLE: 'Kto będzie objęty twoim ubezpieczeniem?',
    BENEFICIARY_QUESTION: 'Whom would you like to cover?',
    BENEFICIARY_OPTION1: 'ja',
    BENEFICIARY_OPTION2: 'ja i moja rodzina/gospodarstwo domowe',

}

export default pl;
