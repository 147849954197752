const da = {
CHOOSE_ITEM_TO_CHECK: "Please choose the item for which you want to check coverage",
    //steps
    STEP1: 'Data',
    STEP2: 'Tilbud',
    STEP3: 'Lukning',
    CHOOSE_ITEM_TO_INSURE: 'Vælg venligst den vare du gerne vil forsikre.',
    // Insurance overview
    INSURANCE_OVERVIEW: 'Forsikringsoversigt',
    COVERAGE: 'Overdækket:',
    NON_COVERED: 'Ikke dækket:',
    DOWNLOAD_INSURANCE_PRODUCT: 'Download den detaljerede forsikringsbeskrivelse',
    DOWNLOAD_TERMS_AND_CONDITIONS: 'Download vilkår og betingelser',
    PRODUCT_INSURANCE_STEP_ONE_TITLE: 'Bare et par trin for at blive dækket af forsikring',
    PRODUCT_INSURANCE_STEP_TWO_TITLE: 'Opdagede produkter fra kvitteringen',
    PRODUCT_INSURANCE_STEP_THREE_TITLE: 'Vælg din forsikring.',
    PRODUCT_INSURANCE_STEP_TWO_HINT: 'Tryk på det, du lige har købt og skal dækkes.',
    PRODUCT_INSURANCE_STEP_ONE_ONLINE: 'Fortæl os, hvad vil du beskytte.',
    ENTER_MORE_DETAILS: 'Indtast flere detaljer om den vare, du vil forsikre',
    TAKE_RECEIPT_PHOTO: 'Tag et billede af købskvitteringen.',
    CHECK_RECEIPT: 'Kontroller, om kvitteringen er tydelig på billedet.',
    CHECK_ITEM_PHOTO: 'Tjek venligst om varen er tydelig på billedet.',
    TAKE_ITEM_PHOTO: 'Tag venligst et billede af din vare for at blive dækket.',
    BUTTON: 'Knap',
    MORE_DETAILS: 'Vis detaljer',
    BACK: 'Tilbage',
    SKIP_RECEIPT: 'Spring kvittering over',
    NEXT: 'Næste',
    GENERAL_CONDITIONS: 'Generelle betingelser',
    LENGTH: 'Varighed:',
    MINIMUM_LENGTH: 'Minimum kontraktvarighed:',
    MAXIMUM_LENGTH: 'Maksimal kontraktvarighed:',
    CONTRACT_TERMINATION: 'Opsigelse af kontrakt:',
    COVERAGE_LIST: 'Dækning',
    SEARCH: 'Søg',
    OTHER: 'Andet',
    CLOSE: 'Luk',
    PHOTO: 'Foto',
    OR: 'Eller',
    OPTIONS: 'Indstillinger',
    SHOW_MORE: 'Vis mere',
    DO_NOT_INSURE: 'Forsikre ikke denne vare',
    NOT_INSURABLE: 'Denne vare kan ikke forsikres',
    SELECT_ITEM_TYPE: 'Vælg venligst varetype',
    ADD_UNRECOGNIZED_ITEM: 'Tilføj en vare',
    NAME: 'Navn',
    PLEASE_WAIT: 'Vent venligst',
    RECOGNIZE_PROCESSING: 'Programmet er ved at behandle billedet(erne) for at udtrække de relevante oplysninger.',
    PRICE: 'Pris',
    CANCEL: 'Annuller',
    OK: 'Ok',
    SAVE_AND_NEXT: 'Næste',
    MY_NEW_ITEM: 'Min nye elskede genstand',

    // Inform Screen
    DEAR_CUSTOMER: 'Kære kunde,',
    TO_OFFER_YOU: 'For at tilbyde dig de bedste forsikringsmuligheder har vi brug for flere oplysninger om den vare, du lige har købt. Du kan enten:',
    /* OPTION_TAKE_A_PHOTO: 'Tag et billede af købskvitteringen',
    OPTION_UPLOAD_FROM_GGALLERY: 'Upload et billede af købskvitteringen', */
    OPTION_TAKE_A_PHOTO: 'Tag et billede',
    OPTION_UPLOAD_FROM_GGALLERY: 'Upload et billede',
    OPTION_ENTER_MANUALLY: 'Indtast data manuelt',
    OPTION_TAKE_A_PHOTO_DESCRIPTION: 'Der kan anmodes om adgang til dit kamera.',
    OPTION_UPLOAD_FROM_GGALLERY_DESCRIPTION: 'Der kan anmodes om adgang til fotos, medier og filer.',

    // Empty State
    SORRY: 'Undskyld',
    SORRY_MESSAGE: 'Vi undskylder, men tilbudslinket er ikke gyldigt mere. Vi giver dig gerne et tilbud med dit næste køb.',
    DONE: 'Færdig',

    // Payment Methods
    PAYMENT_METHODS: 'betalingsmetoder',
    HOW_DO_YOU_WANT_TO_PAY: 'Hvordan vil du betale?',
    YOU_WILL_BE_SPENDING: 'Du vil bruge',
    FOR_BUYING_INSURANCE_POLICIES: 'til køb af forsikringspolicer',
    CONFIRM_YOUR_CARD_DETAILS: 'Indtast detaljerne på dit betalingskort.',
    HDRC_MONEY_BACK: 'Indtast detaljerne på dit betalingskort.',
    CARDHOLDER_NAME: 'Kortholders navn',
    CARD_NUMBER: 'Kortnummer',
    EXPIRY_DATE: 'Udløbsdato',
    CVV: 'Sikkerhedskode (CVV)',
    CONFIRM_AND_PAY: 'Bekræft og køb',
    I_HAVE_SUCCESSFULLY_READ: 'Jeg har læst og er enig i vilkårene og betingelserne.',
    ENTRY_A_FEW_MORE_DETAILS: 'Indtast et par flere detaljer om dig',
    FIRST_NAME: 'Fornavn',
    FAMILY_NAME: 'Familie navn',
    BIRTHDAY: 'Fødselsdag',
    EMAIL_ADDRESS: 'Email adresse',
    PHONE_NUMBERS: 'Telefonnummer',
    ADDRESS: 'Adresse',
    ENTRY_HERE: 'Gå ind her',
    MONTHLY: 'om måneden',
    WEEKLY: 'Per uge',
    YEARLY: 'Per år',
    OVERVIEW_YEARLY: 'Årligt',
    OVERVIEW_MONTHLY: 'Månedlig',
    OVERVIEW_WEEKLY: 'Ugentlig',
    WEEKS: 'uger',
    MONTHS: 'måneder',
    YEARS: 'flere år',
    WHAT_IS_NEXT: 'Hvad er det næste:',
    INSURANCE_CERTIFICATE: (email) =>  `Forsikringscertifikatet genereres og sendes til ${email}.`,

    DEDUCTIBLE: 'Fradragsberettiget:',


    MANDATORY_FIELD: 'Dette felt er obligatorisk',
    INVALID_FORMAT: 'ugyldig værdi',
    PRICE_SHOULD_BE_LOWER: 'Prisen skal være lavere end transaktionsprisen',
    DATE_SHOULD_BE_PAST: 'Dato skal være i fortiden',

    // Congratulations!
    CONGRATULATIONS: 'Tillykke!!',
    GLAD_TO_INFORM_YOU: 'Vi er glade for at kunne meddele dig, at du med succes købte dækningen. Dit produkt er nu sikkert.',

    YOU_WILL_RECEIVE: 'Du vil i de næste minutter modtage en bekræftelses-e-mail med dækningsattesten.',

    YOU_ARE_PROTECTED: 'Du er beskyttet',
    ALREADY_PURCHASED: 'Vi er glade for at kunne meddele dig, at du allerede har købt en forsikring, og at din elskede vare allerede er beskyttet.',

    DOWNLOAD: 'Hent',
    YOU_CAN_DOWNLOAD_HERE: 'Du kan downloade dit forsikringsbevis her:',
    BACK_TO_OVERVIEW: 'tilbage til oversigt',

    // contracts list
    YOUR_POLICY_PURCHASES: 'Dine forsikringer',
    PURCHASED_ON: 'Købt den',
    EXPIRING_ON: 'Udløber den',
    WHATS_COVERED: 'Dækket er',
    FILE_A_CLAIM: 'Indsend et krav',
    REACTIVATE: 'Genaktiver',

    // contract details
    DOWNLOAD_PURCHASE_RECEIPT: 'Download købskvittering',
    INSURCANCE_CONTRACT: 'Forsikringskontrakt',
    ITEM_IS_PROTECTED_BY_INSURANCE: 'Varen er beskyttet af forsikringen',
    INSURANCE_COVERAGE_START: 'Forsikringsdækning starter',

    DOWNLOAD_PRODUCT_SHEET: 'Download produktbladet',
    DOWNLOAD_INSURANCE_CERTIFICATE: 'Download certifikatet',
    CANCEL_CONTRACT: 'Annuller kontrakt',

    // claim form
    PROVIDE_INFO_FOR_CLAIM: 'Giv os lidt mere information, inden vi behandler dit krav',
    ATTACH_PHOTO_VIDEO: 'Vedhæft eventuelt fotos eller videoer, der viser skaden, hvis det er relevant',
    DESCRIBE_WHAT_HAPPEND: 'Beskriv venligst, hvad der er sket',
    ENTER_HERE: 'Gå ind her',
    SUBMIT_CLAIM: 'Indsend kravet',
    PLEASE_ATTACH_FILES: 'Vedhæft filer',
    THANKS_FOR_REQUEST: 'Tak for indsendelse af din ansøgning. Vi behandler den gerne og giver dig et svar i løbet af de næste dage.',
    YOUR_INSURANCE_COVERS: 'Din forsikring dækker kun reparation af en af vores certificerede partnere. Du kan her finde et værksted i din nærhed.',
    SHOW_PARTNER_NETWORK: 'Vis partnernetværk',

    // confirmation
    YOUR_CONTRACT_HAS_BEEN_CANCELLED: 'Din kontrakt er blevet annulleret. Du modtager snart en e-mail med bekræftelse',
    GOT_IT: 'Forstået',
    THE_INSURANCE_WILL_BE_CANCELLED: 'Forsikringen annulleres fra og med den',
    ARE_YOU_SURE_TO_CANCEL: 'Er du sikker på, at du ønsker at annullere beskyttelsen af din',
    RETURN: 'Vend tilbage',
    CONFIRM_REACTIVATION: 'Bekræft venligst, at du ønsker at genaktivere forsikringen.',
    YOUR_CONTRACT_HAS_BEEN_REACTIVATED: 'Din forsikring er blevet reaktiveret. Du vil snart modtage en bekræftelsesmail.',

    // contract statuses
    ACTIVE: 'aktiv',
    PENDING_CANCELLATION: 'annulleret',
    VOID: 'opsagt',

    BENEFICIARY_TITLE: 'Hvem skal være omfattet af din forsikring?',
    BENEFICIARY_QUESTION: 'Whom would you like to cover?',
    BENEFICIARY_OPTION1: 'Mig selv',
    BENEFICIARY_OPTION2: 'mig og min familie/husstand',
}

export default da
