import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MenuPopupState from '../Common/Menu/Menu';
import Color from './Color/Color';
import { deleteWhiteLabel, fetchWhiteLabels } from '../../../store/issuerApp/issuerApp.actions';

const WhiteLabelRow = ({ whiteLabel }) => {
    const {
        name,
        logo,
        uuid,
        ...colors
    } = whiteLabel;
    const history = useHistory();
    const dispatch = useDispatch();

    const handleEdit = () => {
        history.push(`/app/white-labeling/edit/${uuid}`);
    };
    const handleDelete = () => {
        dispatch(deleteWhiteLabel(uuid))
            .then(() => {
                dispatch(fetchWhiteLabels());
            });
    };

    return (
        <React.Fragment>
            <p className="content__name">
                {name}
            </p>
            <div className="content__logo">
                <img src={logo} alt={`${name} logo`} />
            </div>
            <div className="content__colors">
                {Object.keys(colors).map(key => (
                    <Color
                        key={`${uuid}-${key}`}
                        color={whiteLabel[key]} />
                ))}
            </div>
            <div>
                <MenuPopupState
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                />
            </div>
        </React.Fragment>
    );
};

export default WhiteLabelRow;
