import React from 'react';
import { messages } from '../../../../../constants/messages';
import connected from '../../../../../dist/images/whatsapp-connected.svg';

const Connected = () => <div className="whatsapp-wrapper">
    <p className="whatsapp-msg">{messages.WHATSAPP_ACCOUNT_CREATED}</p>
    <img className="whatsapp-img" src={connected} alt="connection" />
    <p className="whatsapp-msg">{messages.MOMENT_TO_CONFIGURE}</p>
    <p className="whatsapp-msg">{messages.WILL_BE_NOTIFIED}</p>
</div>;

export default Connected;
