import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Common/Button/Button';
import PaymentProducts from '../../../containers/PaymentProducts';
import Dropdown from '../../Common/Dropdown/Dropdown';
import { messages } from '../../../../constants/messages';
import { selectPaymentProducts, selectSettings } from '../../../../store/issuerApp/issuerApp.selector';

import './EditPaymentProducts.scss';
import ProductIdBox from '../../Common/ProductIdBox/ProductIdBox';
import Input from '../../Common/Input/Input';
import {
    fetchPaymentProducts,
    fetchPaymentSchemes,
    fetchPaymentSchemeSubtypes,
    fetchPaymentTypes, patchPaymentProduct, postPaymentProduct
} from '../../../../store/issuerApp/issuerApp.actions';

const paymentProductMapper = (
    {
        name,
        paymentType: payment_type,
        paymentScheme: payment_scheme,
        paymentSubscheme: payment_scheme_subtype,
    }) => ({
    name,
    payment_type,
    payment_scheme,
    payment_scheme_subtype,
    white_labeling: ''
});

const EditPaymentProducts = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const history = useHistory();
    const [data, setData] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [id, setId] = useState(null);
    const paymentProducts = useSelector(selectPaymentProducts);
    const [paymentTypes, setPaymentTypes] = useState(null);
    const [paymentSchemes, setPaymentSchemes] = useState(null);
    const [paymentSubschemes, setPaymentSubschemes] = useState(null);
    const [validationErrors, setValidationErrors] = useState({
        name: '',
        paymentType: '',
        paymentScheme: '',
        paymentSubscheme: '',
    });
    const settings = useSelector(selectSettings);

    useEffect(() => {
        Promise.all([
            fetchPaymentTypes(),
            fetchPaymentSchemes(),
            fetchPaymentSchemeSubtypes(),
        ])
            .then(([paymentTypesData, paymentSchemesData, paymentSchemeSubtypesData]) => {
                setPaymentTypes(paymentTypesData.results);
                setPaymentSchemes(paymentSchemesData.results);
                setPaymentSubschemes(paymentSchemeSubtypesData.results);
            });
    }, []);

    useEffect(() => {
        if (paymentSchemes
            && paymentSubschemes
            && paymentTypes) {
            if (!pathname.includes('create') && paymentProducts.length) {
                setIsEdit(true);
                const currentId = pathname.split('/').pop();
                setId(currentId);
                const {
                    product: name,
                    type,
                    scheme,
                    subscheme,
                } = paymentProducts
                    .find(({ uuid: productId }) => productId === currentId);
                setData({
                    name,
                    paymentType: type?.uuid || '',
                    paymentScheme: scheme?.uuid || '',
                    paymentSubscheme: subscheme?.uuid || '',
                });
            } else {
                setData({
                    name: '',
                    paymentType: '',
                    paymentScheme: '',
                    paymentSubscheme: '',
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentProducts, paymentTypes, paymentSchemes, paymentSubschemes]);

    const setError = (field, message) => {
        setValidationErrors(prev => ({
            ...prev,
            [field]: message,
        }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setError(name, '');
        setData({
            ...data,
            [name]: value,
        });
    };

    const validate = () => {
        for (let i = 0; i < Object.entries(data).length; i += 1) {
            const [name, value] = Object.entries(data)[i];
            if (!value) {
                setError(name, messages.EMPTY_FIELD_ERR);
                return false;
            }
        }
        return true;
    };

    const handleClose = () => history.goBack();

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationSucceed = validate();
        if (!validationSucceed) return;
        if (isEdit) {
            dispatch(patchPaymentProduct(paymentProductMapper(data), id))
                .then(() => dispatch(fetchPaymentProducts()))
                .then(() => handleClose());
        } else {
            const { default_white_labeling: { uuid } } = settings;
            const newPaymentProduct = paymentProductMapper(data);
            dispatch(postPaymentProduct({
                ...newPaymentProduct,
                white_labeling: uuid,
            }))
                .then(() => dispatch(fetchPaymentProducts()))
                .then(() => handleClose());
        }
    };

    return (
        <PaymentProducts>
            {data
            &&
            <form onSubmit={handleSubmit} className="edit-payment-products">
                <div className="container">
                    <div className="edit-payment-products__settings">
                        <div className="settings__item">
                            <Input
                                err={validationErrors.name}
                                name="name"
                                value={data.name}
                                handleChange={handleChange}
                                label={messages.NAME}
                            />
                        </div>
                        <div className="settings__item">
                            <Dropdown
                                err={validationErrors.paymentType}
                                value={data.paymentType}
                                handleChange={handleChange}
                                name="paymentType"
                                options={paymentTypes}
                                color="light"
                                label={messages.PAYMENT_TYPE}
                                large
                            />
                        </div>
                        <div className="settings__item">
                            <Dropdown
                                err={validationErrors.paymentScheme}
                                value={data.paymentScheme}
                                handleChange={handleChange}
                                name="paymentScheme"
                                options={paymentSchemes}
                                color="light"
                                label={messages.PAYMENT_SCHEME}
                                large
                            />
                        </div>
                        <div className="settings__item">
                            <Dropdown
                                err={validationErrors.paymentSubscheme}
                                value={data.paymentSubscheme}
                                handleChange={handleChange}
                                name="paymentSubscheme"
                                options={paymentSubschemes}
                                color="light"
                                label={messages.PAYMENT_SUBSCHEME}
                                large
                            />
                        </div>
                    </div>
                </div>
                {isEdit && <ProductIdBox id={id} />}
                <footer className="edit-payment-products__footer">
                    <Button
                        onClick={handleClose}
                        variant="text"
                    >
                        {messages.CANCEL}
                    </Button>
                    <Button
                        size="large"
                        type="submit"
                    >
                        {messages.SAVE}
                    </Button>
                </footer>
            </form>}
        </PaymentProducts>
    );
};
export default EditPaymentProducts;
