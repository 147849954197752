import React, { Profiler, useEffect, useState } from 'react';
import './Congratulations.scss';
import { useSelector } from 'react-redux';
import { messages } from '../../../constants/messages';
import { pageOnRender, downloadCertificate } from '../../../helpers/amplitude';
import Button from '../../Common/Button/Button';
import { selectPaymentStatus, selectWhiteLabelingStyles, selectMode, selectTags, selectProfile, selectCurrentInsuranceId, selectInsuranceProducts } from '../../../store/selectors';
import CongratulationsIcon from './CongratulationsIcon';
import { setupCustomStyles } from '../../../helpers/renderingHelpers';
import { useLanguage } from '../../../helpers/customHooks';
import withTheme from '../../../hocs/withTheme';
import qr from '../../../dist/images/alyyn_qr.png';
import history from '../../../helpers/history';

const Congratulations = () => {
    useLanguage();
    const paymentStatus = useSelector(selectPaymentStatus);
    const mode = useSelector(selectMode);
    const tags = useSelector(selectTags);
    const [injectedJSX, setInjectedJSX] = useState(null);
    const { lead_uuid, payment, success_message, certificate_url } = useSelector(selectProfile);
    const currentInsuranceId = useSelector(selectCurrentInsuranceId);
    const insuranceProducts = useSelector(selectInsuranceProducts);
    const insurance =
            insuranceProducts.find(({ uuid }) => uuid === currentInsuranceId) || {};

    const certificateUrl = useSelector(state => state.payment.certificateUrl) || certificate_url;

    useEffect(() => history.block((location, action) => (action === 'POP'/*  && location === '/payment' */)), []);

    const closeOpenedWindow = () => {
        const androidApp = window.AlyynAndroidInterface;
        if (androidApp) {
            if (mode === 'pull') {
                androidApp.moveToTab('Home');
                androidApp.resetInsurancePullToken();
                return;
            }
            androidApp.resetInsurancePullToken();
            androidApp.closeWindow();
        }
        // window.location.href = 'https://www.abanca.com';
    };

    useEffect(() => {
        debugger;
        if (tags && tags.length) {
            if (tags.indexOf('airline_insurance') > -1) {
                setInjectedJSX(<>
                    <div className="empty-state-sorry-title">Success</div>
                    <div className="empty-state-sorry-deccription">Your flight is registered!</div>
                </>);
                return;
            }

            if (tags.indexOf('airline_delay') > -1) {
                setInjectedJSX(<>
                    <div className="empty-state-sorry-title">Sorry for the delay of your Swiss flight!</div>
                    <div className="empty-state-sorry-deccription">To make your life easier, you can access VIP lounge in Terminal 3 of the Zurich Airport </div>
                </>);
                return;
            }

            if (tags.indexOf('cembra_voucher') > -1) {
                setInjectedJSX(<>
                    <div className="empty-state-sorry-title">Thank you for your trust in Cembra!</div>
                    <div className="empty-state-sorry-deccription">With our partners, we offer you, for the next 30 days, a 20% discount on all your purchases at Ikea, Conforama and Spar. Just present the QR code on the checkout.</div>
                </>);
                return;
            }
        }
        setInjectedJSX(<>
            <div className="empty-state-sorry-title">{paymentStatus ? paymentStatus.title : messages.CONGRATULATIONS}</div>
            <div className="empty-state-sorry-deccription">{paymentStatus ? paymentStatus.message : ((payment && success_message) || insurance.success_message)}</div>
            <div className="empty-state-sorry-deccription">{!paymentStatus && messages.YOU_WILL_RECEIVE}</div>
            {certificateUrl && <div className="download" onClick={() => downloadCertificate({ uuid: lead_uuid })}><a href={certificateUrl} target="_blank" rel="noreferrer">{messages[`${tags.indexOf('sanitas_student_insurance') === -1 ? 'YOU_CAN_DOWNLOAD_HERE' : 'YOU_CAN_DOWNLOAD_CONFIRMATION_HERE'}`]}</a></div>}
        </>);
    }, [tags, certificateUrl]);

    const whiteLabelingStyles = useSelector(selectWhiteLabelingStyles);
    setupCustomStyles(whiteLabelingStyles);

    return (
        <Profiler id="SuccessPayment" onRender={pageOnRender}>
            <div className="popup">
                <div className="congratulations-wrap">
                    {tags.indexOf('airline_delay') === -1 && tags.indexOf('cembra_voucher') === -1 && <CongratulationsIcon />}
                    {tags.indexOf('cembra_voucher') > -1 &&
                    <div className="icons-wrapper">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAwCAMAAAAcuhVsAAAApVBMVEX72RQAWKsAWKP/3wAAVKVtgYn/3QAAVqQAUa9tg4b/4QAAULD/2wsATakAVa0AT6jIukoAUKbAtFLs0iYyZpgATrFDbJXqzy4VXpwASrOlomwdYJtnfonz1CFugJCBj36UmHmennG6rWK7sVkpYaNVdJZyiIY5Z6CNk3sNW6Cup2e0qmRAap7HuVBKbptkfY/UwkHdxzpdeJOeomWxrV6OmW9Nc48H3MIXAAADzklEQVRYhcWY22KjIBCGbTBIEcHE2qw21hhrmlNrTLv7/o+2KidtEmN32/pfOYh8MDADYtwMI+OT9SmljKFSNlf1yFhZ+j1cSpHtB4Fv7bPHVbxIwu1ut1wud7ttmCzi1WO2H1WvbdS3A1e5lCE/YPvxYvtSRBBwwaZkWVS87BbjVxr4iF2ld3IpCvz9KnSjul1CjG4RUvchcsPVqx2gTvZFbsm08vBgVMArvBN+iTcOYT7qYJ/nUt/OkgJ+HtmCkyLMkH8efYZLffaYRv/DbLCjdM7OoU+4LMjeDfD/TMUGxnvmoytcm8YFgF8G5YJgFlO7g2vfJNEXDlWLgCi5sS9wGVoY4BugXMBYIHaOG+Te91FrspcHJ1zKUvwdHtYiEOJUpVHBRc/VYIFW3QfSaTcsLTW4j8UwTVIIvGfU5KJjFa1kfCs1n1agIld2Ub1359LOp4Sk+fxEecSxkW6qUozJ9i15CyGBR6S5KKv7iR8mppDvVlzPkbYzq8ZwL98HCTDI1DZP5HMucf1W8SSCifue/q58ysEVlz7xiMV31kjI4dyJtCclFyRMWGyNy7an0mzI4VywMlvFbIfXU3e6roYHn6jgBjPSgwtdWxo5NtpcS0pwDWvUkvWKvVWymtW+mAWci9ZiNXRyiWeKt+ZD7Z4m90GKc0nqfPRDUaYOgQFrVHMD0cduLrgTrrNMj7S55WikeNP4j6wrvzfjRm6IgopLj7gHN4olxTnwWWlw97gdRTPxZvNHgxuRjI+05KIEXueOQuk5cy262fTzPddzUXcJhvw7K3OVv1mqsxJMUMm1l324+ol5p1yxqnzOBaIdtgUb2aKZYc1d2r25Oi5kCxe55ODIyQEL1YIzI21uPz/PNdjZgk4/g5jXNW8x9lSdyUKtLO7nfutq9qRD0vG615VYxlZ2O77dKJeM1HGCr6t+ceQdfO3p+3beaMcRmcrFZJUZUn00YlPpaB5HPfMGjrXTa0+38sYvoTI/YzklfM51b3PhV5k3+uVJYjQ9XZoX8iSJxEfWppYeMJ8enSfLfaFPfiY6GOs1fWFfADtebD3zv5lCfTXhC1jvCz33QZxP9H6XgAv7IL7jtZyEz57ePCebqqSxD6p9fz6WysW+r+wyQoirzPH4WMC0Yap6xuHITwBHkU7hVrWau6S97/c857QONuTCOYfoCiJaG998POf8xLmumsiUfTjXDXaOHe7cfjPUf0pNHuS/rPb2IP+h9dIe5L9boAe4ZxDoIe5VNPsf75H2/3yPpNifuTfbfs29WYP+4/eEJx340XvRL9dfldFyzSE2olcAAAAASUVORK5CYII=" alt="ikea" />
                        <img src="https://www.spar.ch/fileadmin/user_upload/SPAR-Logo.png" alt="spar" />
                        <img src="https://api.conforama.ch/medias/logo2022.png?context=bWFzdGVyfGltYWdlc3wxMDA1MnxpbWFnZS9wbmd8aDAxL2hiNC85ODEwOTk5MTgxMzQyL2xvZ28yMDIyLnBuZ3wyOWY0M2EyMWUyMDY3ZTZmMGUyNDdhZGJjZjc4MWQ0YmYzZjJhMTM4NmFkODRjYWJiMDRiN2JmODYzZmM0NmVk" alt="confo" />
                    </div>}
                    <div className="congratulations-message-wrap">
                        {
                            injectedJSX
                        }
                        {(tags.indexOf('airline_delay') > -1 || tags.indexOf('cembra_voucher') > -1) && <img className="qr" src={qr} alt="qr" />}
                        {(tags && tags.indexOf('show_overview_btn') > -1) ? <Button
                            className="empty-state-sorry-sert-download"
                            onClick={closeOpenedWindow}>
                            {messages.BACK_TO_OVERVIEW}
                        </Button> : null}
                        {/* { status } */}
                    </div>
                </div>
            </div>
        </Profiler>
    );
};

export default withTheme(Congratulations);
