import React, { useState, useRef, useEffect, useCallback } from 'react';
import InputWrap from '../Common/Input/InputWrap';
import './datepicker.scss';
import { messages } from '../../constants/messages';

// const format = '__.__.____';
const initialCursor = { position: undefined };

const CustomDatePicker = ({ tag = false, date = undefined, label = 'Date',
    type = 'date', onChange = () => {}, validStatus = () => {}, disabled = false, icon = null, future = false }) => {
    const input = useRef();
    const [data, setData] = useState(date);
    const [cursor, setCursor] = useState(initialCursor);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState();
    const divider = type === 'date' ? '.' : ':';

    const changeCursorPosition = (pos) => {
        input.current.setSelectionRange(pos, pos);
        input.current.focus();
    };

    const setDividers = (arr, pos) => {
        if (arr.length >= pos) {
            arr.splice(pos, 0, divider);
            if (arr.length === pos + 1 && cursor.position === pos) {
                setCursor({ position: pos + 1 });
            }
        }
    };

    const setValue = (v) => {
        if (onChange) {
            onChange(v);
            return;
        }
        setData(v);
    };

    const calculateYearDiff = (d) => {
        const today = new Date();
        let diff = (today.getTime() - d.getTime()) / 1000;
        diff /= (60 * 60 * 24);
        return Math.abs(Math.floor(diff / 365.25));
    };

    const onDateValidate = () => {
        if (!data) {
            setError(messages.MANDATORY_FIELD);
            return;
        }

        const arr = data.split(divider);
        const config = {
            length: 10,
            date: new Date(`${arr[2]}-${arr[1]}-${arr[0]}`)
        };

        if (data.length < config.length) {
            setError(messages.INVALID_FORMAT);
            return;
        }

        if (data.length === config.length) {
            if (!new Date(config.date).getTime() ||
                (!future && new Date(config.date) > new Date())) {
                setError(messages.INVALID_FORMAT);
                return;
            }

            // TODO: to refactor as it was written only for one case (spain demo)
            // and now we have more
            if ((!tag || future) && new Date(config.date) <= new Date()) {
                setError(messages.FUTURE_DATE);
                return;
            }
            // tag is for demo spain

            if (tag && calculateYearDiff(new Date(config.date)) >= 80) {
                setError(messages.ABOVE_80);
                return;
            }
        }
        setError('');
    };

    const onDateChange = (value) => {
        // onDateValidate();
        if (error) {
            setStatus(null);
            setError(null);
        }
        if (!value) {
            setValue(value);
            return;
        }


        if (value.length <= 10) {
            const arr = value.replaceAll(divider, '').split('');

            setDividers(arr, 2);
            setDividers(arr, 5);

            setValue(`${arr.join('')}`);
        }
    };

    const onTimeChange = (value) => {
        if (value) {
            if (value.length <= 5) {
                const arr = value.replaceAll(divider, '').split('');
                setDividers(arr, 2);
                setData(`${arr.join('')}`);
            }
            return;
        }
        setData('');
    };


    const detectTyping = useCallback((e) => {
        const { target } = e;
        const { value, selectionStart, /* selectionEnd */ } = target;

        if (!e.code.includes('Digit')) {
            if (e.keyCode === 8) {
                if (value[selectionStart - 1] === divider) {
                    changeCursorPosition(selectionStart - 1);
                    e.stopPropagation();
                    e.preventDefault();
                    return;
                }

                setCursor({ position: selectionStart - 1 });
                return;
            }

            e.stopPropagation();
            e.preventDefault();
        } else {
            setCursor({
                position: selectionStart + (value[selectionStart + 1] === divider ? 2 : 1)
            });
        }
    }, []);

    useEffect(() => {
        if (cursor.position !== undefined) {
            changeCursorPosition(cursor.position);
            setCursor(initialCursor);
        }

        if (data !== undefined) {
            onDateValidate();
        }
    }, [data]);

    useEffect(() => {
        setData(date);
    }, [date]);

    useEffect(() => {
        input.current.addEventListener('keydown', detectTyping);
        return () => document.removeEventListener('keydown', detectTyping);
    }, [detectTyping]);

    useEffect(() => {
        validStatus(typeof error === 'string' ? error : (false || !data));
    }, [error]);

    /* const onTimeValidate = () => {
        if (!data) {
            setError('Field is required');
            return;
        }

        const arr = data.split(divider);
        const config = {
            length: 5,
            hour: arr[0],
            minute: arr[1]
        };

        if (data.length < config.length) {
            setError('Please enter valid date');
        }

        if (data.length === config.length && (config.hour > 23 || config.minute > 60)) {
            setError('Please enter valid date');
            return;
        }

        setError(null);
    }; */

    return <div className="date-picker">
        <InputWrap
            placeholder={type === 'date' ? messages.DATE_PLACEHOLDER : 'hh:mm'}
            ref={input}
            img={icon}
            lable={label && label}
            value={data}
            type="custom"
            error={status}
            onChange={type === 'date' ? v => onDateChange(v) : v => onTimeChange(v)}
            /* onBlur={type === 'date' ? onDateValidate : onTimeValidate} */
            onBlur={() => setStatus(error)}
            disabled={disabled} />
    </div>;
};

export default CustomDatePicker;
