import createReducer from '../createReducer';
import {
    TOGGLE_LOADING,
    SET_VERIFY_TOKEN,
    SET_ISSUER_PROFILE,
    SET_STYLES,
    SET_SETTINGS,
    SET_PAYMENT_PRODUCTS,
    SET_WHITE_LABELS,
} from './issuerApp.actionTypes';

const issuerApp = {
    loading: false,
    verifyToken: '',
    profile: null,
    settings: null,
    paymentProducts: [],
    whiteLabels: [],
    styles: {
        backgroundColor: '#f3eee5',
        clickableColor: '#d3d3d3',
        errorColor: '#ff1414',
        headerColor: '#d9d7d8',
        infoColor: '#c7c7c7',
        primaryColor: '#05b2ca',
        successColor: '#3ab800',
        textColor: '#05264D',
        warningColor: '#fff700',
    },
};


const toggleLoading = (state, { loading }) => ({ ...state, loading });

const setVerifyToken = (state, { verifyToken }) => ({ ...state, verifyToken });

const setIssuerProfile = (state, { issuerProfile }) => ({ ...state, issuerProfile });

const setSettings = (state, { settings }) => ({ ...state, settings });

const setStyles = (state, { styles }) => ({ ...state, styles });

const setPaymentProducts = (state, { paymentProducts }) => ({ ...state, paymentProducts });

const setWhiteLabels = (state, { whiteLabels }) => ({ ...state, whiteLabels });

const issuerAppReducer = createReducer(issuerApp, {
    [TOGGLE_LOADING]: toggleLoading,
    [SET_VERIFY_TOKEN]: setVerifyToken,
    [SET_ISSUER_PROFILE]: setIssuerProfile,
    [SET_SETTINGS]: setSettings,
    [SET_STYLES]: setStyles,
    [SET_PAYMENT_PRODUCTS]: setPaymentProducts,
    [SET_WHITE_LABELS]: setWhiteLabels,
});

export default issuerAppReducer;
