import React, { useEffect, useState, forwardRef } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import MomentUtils from '@date-io/moment';
import { messages } from '../../../constants/messages';
import calendar from '../../../dist/images/Calendar.svg';

import 'moment/locale/de';
import 'moment/locale/de-ch';
import 'moment/locale/en-gb';
import 'moment/locale/it';
import 'moment/locale/it-ch';
import 'moment/locale/hr';
import 'moment/locale/es';
import 'moment/locale/pl';
import 'moment/locale/fr-ch';
import 'moment/locale/fr';
import 'moment/locale/sv';
import 'moment/locale/fi';

import { selectActualLanguage, selectWhiteLabelingStyles } from '../../../store/selectors';
import './inputDatepicker.scss';

import { getInputBackground } from '../../../helpers/calculateColor';

// const BACKEND_FORMAT = 'yyyy-MM-DD';
const UI_FORMAT = 'DD.MM.yyyy';

/*
const getYearOffset = (year) => {
    const reminder = year % 3;
    if (reminder === 0) {
        return 3;
    }
    if (reminder === 1) {
        return 2;
    }
    return 1;
};
 */


const localeMap = {
    en: 'en-gb',
    'de-de': 'de',
    'es-es': 'es',
    'fi-fi': 'fi',
    'sv-sv': 'sv',
    'sv-fi': 'sv'
};

const InputDatepicker = ({ cn,
    onInputChange,
    disableFuture = false,
    icon,
    /* error, */
    label = false,
    dateValue,
    forwardedRef, validStatus = () => {}, future = false, tag = false }) => {
    const actualLanguage = useSelector(selectActualLanguage);
    const [startDate, setStartDate] = useState(null);
    const [isOpen, setOpen] = useState(false);
    const [error, setError] = useState(null);

    const calculateYearDiff = (d) => {
        const today = new Date();
        let diff = (today.getTime() - d.getTime()) / 1000;
        diff /= (60 * 60 * 24);
        return Math.abs(Math.floor(diff / 365.25));
    };

    const onValidate = (date) => {
        const today = moment();
        const data = moment(date, UI_FORMAT);
        if (date) {
            if (future && data <= today) {
                setError(messages.FUTURE_DATE);
                return;
            }
            // tag is for demo spain

            if (!future && today <= data) {
                setError(messages.INVALID_FORMAT);
                return;
            }

            if (tag && calculateYearDiff(new Date(date)) >= 80) {
                setError(messages.ABOVE_80);
                return;
            }

            setError('');
        }
    };

    useEffect(() => {
        validStatus(error === null ? !!dateValue : !error);
    }, [error]);

    useEffect(() => {
        if (dateValue) {
            setStartDate(moment(dateValue, UI_FORMAT));
            onValidate(dateValue);
        } else {
            validStatus(!!dateValue);
        }

        /* validStatus(!!dateValue); */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateValue]);

    useEffect(() => {
        const locale = localeMap[actualLanguage] || actualLanguage;
        moment.locale(locale);
    }, [actualLanguage]);
    const onClick = () => {
        if (!isOpen) {
            setOpen(true);
            console.log(open);
        }
    };

    const handleDateChange = (value) => {
        // setError('');
        if (!value || value.isValid()) {
            onInputChange(!value ? '' : value.format(UI_FORMAT));
            // setStartDate(value);
        }

        if (!value.isValid()) {
            setError(messages.INVALID_FORMAT);
        }

        // validStatus(value.isValid());
    };

    /* const initialDate = new Date();
    const initialYear = initialDate.getFullYear() - 25;
    initialDate.setFullYear(initialYear); */

    const locale = localeMap[actualLanguage] || actualLanguage;

    const onClose = () => {
        setOpen(false);
    };

    /* const currentYear = startDate && startDate.isValid()
        ? startDate.toDate().getFullYear() : initialYear;
    const offset = getYearOffset(currentYear); */

    // console.log(variables.primaryLightColor);

    const { whiteLabelingInputBackground } = useSelector(selectWhiteLabelingStyles);
    const backgroundColor = getInputBackground(whiteLabelingInputBackground);

    const materialTheme = createMuiTheme({
        overrides: {
            MuiInputBase: {
                root: {
                    backgroundColor,
                }
            }
        }
    });

    return (
        <>
            <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
                    <div className={classNames('input-datepicker-wrapper', !icon && 'without-icon', error && 'error-state', cn)}>
                        {icon && <img
                            alt="input"
                            src={icon && calendar}
                            className="input-icon"
                        />}
                        <div ref={forwardedRef}>
                            <KeyboardDatePicker
                                mask="__.__.____"
                                disableFuture={disableFuture}
                                disableToolbar
                                variant="inline"
                                openTo="year"
                                format={UI_FORMAT}
                                label={label}
                                views={['year', 'month', 'date']}
                                value={startDate}
                                onChange={handleDateChange}
                                allowKeyboardControl={false}
                                onClick={onClick}
                                autoOk
                                orientation="landscape"
                                /* initialFocusedDate={moment()} */
                                PopoverProps={{
                                    anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'center',
                                    },
                                    transformOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'center'
                                    },
                                    /* classes: {
                                        paper: `offset-${offset}`
                                    } */
                                }}
                                open={isOpen}
                                onClose={onClose}
                            />
                        </div>
                        {!!error && <p className="error-text">{error}</p> }
                    </div>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </>
    );
};

export default forwardRef(InputDatepicker);
