import React from 'react';
import { trigger_fields } from '../constants';

const renderWizard = (key, value, data) => {
    let render = null;

    switch (key) {
        case 'item_types':
            render = data[key].map(({ label }) => label).join(', ');
            break;
        default:
            render = Object.entries(value.fields)
                .map(([k, v]) => {
                    let res = null;
                    if (k === 'transaction_amount') {
                        res = Number(data.payment_max_amount) ?
                            `${data.payment_min_amount} - ${data.payment_max_amount} ${data.min_lead_amount_currency}` :
                            null;
                    } else if (k === 'distance') {
                        res = Number(data.distance_max) ?
                            `${data.distance_max - data.distance_min} ${v.unit}` :
                            null;
                    } else if (data[k]) {
                        if (Array.isArray(data[k])) {
                            res = data[k].length ? data[k].map(({ label }) => label).join(', ') : null;
                        } else {
                            res = data[k].label;
                        }
                    }

                    return res && <div style={{ marginBottom: 5 }}>
                        <span style={{ marginRight: 10, fontStyle: 'italic', fontWeight: 500 }}>{v.title}:</span>
                        <div style={{ display: 'inline-block' }}>
                            {res}
                        </div>
                    </div>;
                });
            break;
    }
    return <div key={key}>{render}</div>;
};

const TriggerReadMode = ({ data }) =>
    <div className="offer-management-card__section__trigger__body">{
        data.uuid ? Object.entries(trigger_fields)
            .map(([k, v]) =>
                renderWizard(k, v, data))
            : null}
    </div>;


export default TriggerReadMode;
