import React from 'react';
import SummaryCardDetail from './SummaryCardDetail';

// use useSelector to fetch styles and colors

// temp solution with colors
const colors = {
    leads: '#05264D',
    initial_offer_made: '#E0BD6F',
    offer_clicked: '#c7c7c7',
    purchased: '#05b2ca'
};

const DashboardSummaryCard = ({ fields, data }) => (<div className="dashboard__card multiple">
    {
        Object.keys(fields).map((f, i) =>
            <SummaryCardDetail key={i} title={fields[f].label} data={data[f]} color={colors[f]} />
        )
    }
</div>);

export default DashboardSummaryCard;
