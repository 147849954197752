import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import WhiteLabelingSettings from '../../../containers/WhiteLabelingSettings';
import Button from '../../Common/Button/Button';
import { messages } from '../../../../constants/messages';
import { selectStyles, selectWhiteLabels } from '../../../../store/issuerApp/issuerApp.selector';
import Input from '../../Common/Input/Input';
import Attachments from './Attachments/Attachments';
import Color from '../Color/Color';
import {
    deleteWhiteLabel,
    fetchWhiteLabels,
    postWhiteLabel,
    putWhiteLabel
} from '../../../../store/issuerApp/issuerApp.actions';

import './EditWhiteLabeling.scss';
import GoodsConditions from '../../../../components/ProductInsurance/GoodsConditions/GoodsConditions';
import { setMockData, updateInsurancesWhiteLabel } from '../../../../helpers/customHooks';
import { setupStylesForElement } from '../../../../helpers/renderingHelpers';

const colorNames = {
    backgroundColor: 'Background colour',
    headerBackgroundColor: 'Header background colour',
    inputFieldBackgroundColor: 'Input field background colour',
    primaryColor: 'Primary colour',
    primaryLightColor: 'Primary light colour',
    primaryOpacityColor: 'Primary opacity colour',
    titleColor: 'Title colour',
};

const whiteLabelMapper = (newData, oldLogo) => {
    const {
        backgroundColor: background_color,
        displayUnderwriterLogo: display_underwriter_logo,
        headerBackgroundColor: header_background_color,
        inputFieldBackgroundColor: input_field_background_color,
        name,
        logo,
        primaryColor: primary_color,
        primaryLightColor: primary_light_color,
        primaryOpacityColor: primary_opacity_color,
        titleColor: title_color,
    } = newData;
    if (JSON.stringify(logo[0]) === JSON.stringify(oldLogo)) {
        return ({
            background_color,
            display_underwriter_logo,
            header_background_color,
            input_field_background_color,
            name,
            primary_color,
            primary_light_color,
            primary_opacity_color,
            title_color,
        });
    }
    return ({
        background_color,
        display_underwriter_logo,
        header_background_color,
        input_field_background_color,
        logo,
        name,
        primary_color,
        primary_light_color,
        primary_opacity_color,
        title_color,
    });
};

const EditWhiteLabeling = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [data, setData] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [id, setId] = useState(null);
    const [initialLogo, setInitialLogo] = useState(null);
    const [colorPickerNum, setColorPickerNum] = useState();
    const whiteLabels = useSelector(selectWhiteLabels);
    const defaultWhiteLabels = useSelector(selectStyles);
    const [validationErrors, setValidationErrors] = useState({
        name: '',
        logo: '',
    });

    const previewRef = useRef(null);

    const setError = (field, message) => {
        setValidationErrors(prev => ({
            ...prev,
            [field]: message,
        }));
    };

    useEffect(() => {
        setMockData(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (data) {
            updateInsurancesWhiteLabel(dispatch, data);
            if (previewRef.current) {
                setupStylesForElement(previewRef.current, data);
            }
        }
    }, [dispatch, data]);

    useEffect(() => {
        if (!pathname.includes('create') && whiteLabels.length) {
            setIsEditMode(true);
            const currentId = pathname.split('/').pop();
            setId(currentId);
            const currentWhiteLabeling = whiteLabels.find(({ uuid }) => uuid === currentId);
            const { uuid, ...whiteLabel } = currentWhiteLabeling;
            const initLogo = {
                attachment: currentWhiteLabeling.logo,
                type: 'image/png',
                url: currentWhiteLabeling.logo,
            };
            setInitialLogo({ ...initLogo });
            setAttachments([initLogo]);
            setData({ ...whiteLabel });
        } else {
            const { textColor, backgroundColor, primaryColor, headerColor, } = defaultWhiteLabels;
            setData({
                logo: '',
                name: 'White label',
                backgroundColor,
                displayUnderwriterLogo: false,
                headerBackgroundColor: headerColor,
                primaryOpacityColor: '#ffffff',
                primaryLightColor: '#ffffff',
                inputFieldBackgroundColor: '#ffffff',
                primaryColor,
                titleColor: textColor,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [whiteLabels]);

    useEffect(() => {
        setData(prev => ({
            ...prev,
            logo: attachments,
        }));
    }, [attachments]);

    const handleClose = () => history.goBack();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setError(name, '');
        setData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleColorChange = (name, value) => {
        setData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleCheck = (event) => {
        const { name, checked } = event.target;
        setData(prev => ({
            ...prev,
            [name]: checked,
        }));
    };

    const addAttachment = (attachment) => {
        setError('logo', '');
        setAttachments(arr => ([...arr, attachment]));
    };

    const removeAttachment = (index) => {
        setAttachments((arr) => {
            arr.splice(index, 1);
            return [...arr];
        });
    };

    const handleDelete = () => {
        dispatch(deleteWhiteLabel(id))
            .then(() => {
                dispatch(fetchWhiteLabels());
                handleClose();
            });
    };

    const validate = () => {
        if (!data.name) {
            setError('name', messages.EMPTY_FIELD_ERR);
            return false;
        } else if (!data.logo.length) {
            setError('logo', messages.NO_LOGO_ERR);
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationSucceed = validate();
        if (!validationSucceed) return;
        if (isEditMode) {
            dispatch(putWhiteLabel(whiteLabelMapper(data, initialLogo), id))
                .then(() => dispatch(fetchWhiteLabels()))
                .then(() => handleClose());
        } else {
            dispatch(postWhiteLabel(whiteLabelMapper(data, initialLogo)))
                .then(() => dispatch(fetchWhiteLabels()))
                .then(() => handleClose());
        }
    };

    return (
        <WhiteLabelingSettings>
            <form onSubmit={handleSubmit}>
                {data && Object.keys(data).length && <main className="edit-white-labeling">
                    <section className="edit-white-labeling__section edit-white-labeling__section-view">
                        <div className="container">
                            <div className="section__content">
                                <div className="content__item content__item-name">
                                    <Input
                                        err={validationErrors.name}
                                        name="name"
                                        value={data.name}
                                        handleChange={handleChange}
                                    />
                                </div>
                                <div className="content__preview" ref={previewRef}>
                                    <GoodsConditions />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="edit-white-labeling__section edit-white-labeling__section-info">
                        <div className="container">
                            <div className="section__content">
                                <div className="section-info__logo">
                                    <div className="title content__item section-info__logo-title">{messages.LOGO}</div>
                                    <div className="content__item section-info__logo-img">
                                        <Attachments
                                            addAttachment={addAttachment}
                                            removeAttachment={removeAttachment}
                                            attachments={attachments}
                                        />
                                        {validationErrors.logo &&
                                        <div className="section-info__logo-err">{validationErrors.logo}</div>}
                                    </div>
                                    <div className="content__item">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={data.displayUnderwriterLogo}
                                                    onChange={handleCheck}
                                                    name="displayUnderwriterLogo"
                                                    color="primary"
                                                />
                                            }
                                            label={messages.SHOW_INSURER_LOGO}
                                        />
                                    </div>
                                </div>
                                <div className="section-info__colour-scheme">
                                    <div className="title content__item">{messages.COLOUR_SCHEME}</div>
                                    {(() => {
                                        const {
                                            name,
                                            logo,
                                            uuid,
                                            displayUnderwriterLogo,
                                            ...colors
                                        } = data;

                                        return Object.keys(colors).map((key, i) => {
                                            const isColorPickerDisplayed = i === colorPickerNum;
                                            return (
                                                <div
                                                    className="content__item color-scheme__item"
                                                    key={`${uuid}-${key}`}
                                                >
                                                    <Color
                                                        color={colors[key]}
                                                        colorPicker={isColorPickerDisplayed}
                                                        i={i}
                                                        handleEdit={j => setColorPickerNum(j)}
                                                        name={key}
                                                        handleColorChange={(propName, value) =>
                                                            handleColorChange(propName, value)}
                                                    />
                                                    <div className="color-scheme__item-name">{colorNames[key]}</div>
                                                </div>
                                            );
                                        });
                                    })()}
                                </div>
                            </div>
                        </div>
                    </section>
                </main>}
                <footer className="edit-white-labeling__footer">
                    <Button
                        onClick={handleClose}
                        variant="text"
                    >
                        {messages.CANCEL}
                    </Button>
                    {isEditMode &&
                    <Button
                        onClick={handleDelete}
                        variant="text"
                    >
                        {messages.DELETE}
                    </Button>
                    }
                    <Button
                        size="large"
                        type="submit"
                    >
                        {messages.SAVE}
                    </Button>
                </footer>
            </form>
        </WhiteLabelingSettings>
    );
};
export default EditWhiteLabeling;
