import React, { Profiler, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConditionSelection from '../../Common/ConditionSelection/ConditionSelection';
import { pageOnRender, previousPageNavigation,
    nextPageNavigation, seeInsuranceProductDetails,
    insuranceProductSelection, contactUs } from '../../../helpers/amplitude';
import { messages } from '../../../constants/messages';
import Header from '../../Header';
import { useLanguage } from '../../../helpers/customHooks';
import Footer from '../../Common/Footer/Footer';
import InsuranceOverview from '../../InsuranceOverview/InsuranceOverview';
import { saveInsuranceProduct, setStep, setCurrentInsuranceId, getInsuranceProducts, configurePostOfferFlow, setInsuranceProductUuid } from '../../../store/productInsurance/productInsurance.actions';
import {
    selectCurrentGoodsId,
    selectCurrentInsuranceId,
    selectInsuranceProducts,
    isInsuranceLogoVisible,
    selectProfile
} from '../../../store/selectors';
// import HintModal from '../HintModal/HintModal';

import { selectCurrentStepNumber, selectBreadcrumbs } from '../../../store/common/common.selector';
import { /* setTargetPhoto, */ getConfigurationAttributes, setCurrentStepNumber, getNonSpecificConfiguration } from '../../../store/common/common.actions';
import './GoodsConditions.scss';
import { setActualFooterScreen } from '../../../store/payment/payment.actions';

const GoodsConditions = () => {
    const lang = useLanguage();
    const dispatch = useDispatch();
    const history = useHistory();

    const [redirect, setRedirect] = useState(undefined);

    const currentGoodsId = useSelector(selectCurrentGoodsId);
    const currentInsuranceId = useSelector(selectCurrentInsuranceId);
    const insuranceProducts = useSelector(selectInsuranceProducts);

    const currentProduct = insuranceProducts
        .find(({ uuid }) => uuid === currentInsuranceId);

    const currentStepNumber = useSelector(selectCurrentStepNumber);
    const breadcrumbs = useSelector(selectBreadcrumbs);

    const { item_type_specific, lead_uuid, tags, support_phone_number } =
        useSelector(selectProfile);

    const [areDetailsOpened, setAreDetailsOpened] = useState(false);
    const [expandedInsurance, setExpandedInsurance] = useState(null);
    const [selectedInsuranceIndex, setSelectedInsuranceIndex] = useState(-1);

    const isLogoVisible = useSelector(isInsuranceLogoVisible);

    // const [hintModalState, setHintModalState] = useState({});
    const [isNext, setIsNext] = useState(false);

    const onShowHint = (/* { label, hint } */) => {
        contactUs({ uuid: lead_uuid });
        window.open(`https://wa.me/${support_phone_number}?text=`);
        /* setHintModalState({
            shown: true,
            label,
            hint
        }); */
    };

    // const onCloseHint = () => setHintModalState({ shown: false });


    const onBackStep = () => {
        const { step, mode } = breadcrumbs[currentStepNumber - 1];
        previousPageNavigation({ uuid: lead_uuid, page: step });
        if (mode) {
            history.push('/insurances/');
        }

        dispatch(setCurrentStepNumber(currentStepNumber - 1));
        dispatch(setStep(step));
    };

    useEffect(() => {
    // TODO: not to call if go back
        if (history.location.state && history.location.state.from === '/not-valid#empty') {
            const state = { ...history.location.state };
            delete state.from;
            history.replace({ ...history.location, state });

            onBackStep();
        }
        if (currentGoodsId) {
            dispatch(getInsuranceProducts(currentGoodsId))
                .then((res) => {
                    setRedirect(!res.length);
                });
        }
    }, []);

    useEffect(() => {
        if (insuranceProducts.length && selectedInsuranceIndex > -1) {
            setExpandedInsurance(insuranceProducts[selectedInsuranceIndex]);
        }
    }, [lang, insuranceProducts]);


    useEffect(() => {
        // that means that config was not set before
        if (!breadcrumbs.length) {
            if (tags.indexOf('helvetia_spain_life') > -1) {
                dispatch(getNonSpecificConfiguration(true));
            } else if (item_type_specific) {
                dispatch(getConfigurationAttributes(currentGoodsId));
            }
        }
    }, []);

    useEffect(() => {
        if (insuranceProducts.length) {
            if (!currentProduct) {
                dispatch(setCurrentInsuranceId(insuranceProducts[0].uuid));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insuranceProducts, currentInsuranceId]);

    useEffect(() => {
        if (redirect) {
            history.push('/not-valid#empty');
        }
    }, [redirect]);

    useEffect(() => {
        if (currentProduct && breadcrumbs.length) {
            insuranceProductSelection({ uuid: lead_uuid });
            dispatch(setInsuranceProductUuid(currentProduct.insurance_product_uuid));
        }
    }, [currentProduct, currentStepNumber]);

    useEffect(() => {
        if (breadcrumbs.filter(({ position }) => position === 2).length && isNext) {
            dispatch(saveInsuranceProduct())
                .then(() => {
                    const { step, payment } = breadcrumbs[currentStepNumber + 1];
                    nextPageNavigation({ uuid: lead_uuid, page: step });
                    if (payment) {
                        dispatch(setActualFooterScreen(step));
                        history.push('/payment');
                        // logEvent(EVENTS.ACTIVE_STEP, { page: 'Payment part' });
                    }
                    dispatch(setCurrentStepNumber(currentStepNumber + 1));
                    dispatch(setStep(step));
                });
        }
    }, [breadcrumbs, isNext]);

    const onNextStep = () => {
        if (!currentInsuranceId) {
            return;
        }

        if (tags && tags.indexOf('insurance_detailed_mandatory') > -1) {
            if (areDetailsOpened) {
                dispatch(configurePostOfferFlow());
                setIsNext(true);
                return;
            }
            setExpandedInsurance(insuranceProducts.find(({ uuid }) => uuid === currentInsuranceId));
            setAreDetailsOpened(true);
            return;
        }
        dispatch(configurePostOfferFlow());
        setIsNext(true);
    };

    const showMore = (key) => {
        seeInsuranceProductDetails({ uuid: lead_uuid });
        setAreDetailsOpened(true);
        setSelectedInsuranceIndex(key);
        setExpandedInsurance(insuranceProducts[key]);
    };

    if (!insuranceProducts.length || typeof redirect === 'object') {
        return null;
    }

    // condition for showing selection circle
    // !(tags.length && (tags.indexOf('helvetia_explanation') > -1
    // || tags.indexOf('Travel_Insurance_Flow') > -1))

    return typeof redirect === 'boolean' && !redirect && (
        areDetailsOpened ?
            <InsuranceOverview
                insuranceId={expandedInsurance.uuid}
                hide={() => { setAreDetailsOpened(false); setSelectedInsuranceIndex(-1); }}
                cover={expandedInsurance.cover}
                nonCover={expandedInsurance.not_covered}
                brandingName={expandedInsurance.branding_name}
                productDescription={expandedInsurance.product_description}
                contractLengthUnit={expandedInsurance.contract_length_unit}
                contractLength={expandedInsurance.contract_length}
                contractMinimumLength={expandedInsurance.contract_minimum_length}
                contractMaximumLength={expandedInsurance.contract_maximum_length}
                paymentScheme={expandedInsurance.payment_scheme}
                productSheet={expandedInsurance.product_fact_sheet}
                currency={expandedInsurance.currency}
                deductible={expandedInsurance.deductible}
                isLogoVisible={isLogoVisible}
                logo={expandedInsurance.underwriter_logo}
                onNext={onNextStep}
                offers={insuranceProducts}
                isHelp
                insuredCapital={expandedInsurance.insured_sum}
                currencySymbol={expandedInsurance.currency_symbol}
                showDeductible={!(tags && tags.indexOf('helvetia_spain_life') > -1)}
            /> :
            <Profiler id="ChooseInsuranceProduct" onRender={pageOnRender}>
                <Header
                    stepper
                    stepsTitle={messages.PRODUCT_INSURANCE_STEP_THREE_TITLE}
                    className="сonditions-header"
                />
                <div className="сonditions-wrap">
                    {insuranceProducts.map((insurance, key) => {
                        const isSelect = insurance.uuid === currentInsuranceId;
                        return (
                            <ConditionSelection
                                insurance={insurance}
                                isAmazon={tags.length && tags.indexOf('helvetia_amazon') > -1 && insurance.payment_scheme === 'YEARLY' && insurance.price >= 180}
                                isSelection={insuranceProducts.length > 1}
                                isExplanation={tags.length && tags.indexOf('helvetia_explanation') > -1}
                                isTravel={tags.length && tags.indexOf('Travel_Insurance_Flow') > -1}
                                showMore={tags && tags.indexOf('insurance_detailed_mandatory') === -1 ? () => showMore(key) : null}
                                key={insurance.uuid}
                                salesArguments={insurance.sales_arguments}
                                price={insurance.price}
                                currency={insurance.currency_symbol}
                                setCurrentInsuranceId={setCurrentInsuranceId}
                                insuranceId={insurance.uuid}
                                productInsuranceUuid={insurance.insurance_product_uuid}
                                currentInsuranceId={currentInsuranceId}
                                checked={!!isSelect}
                                brandingName={insurance.branding_name}
                                paymentScheme={insurance.payment_scheme}
                                insuredCapital={insurance.insured_sum}
                                logo={insurance.underwriter_logo}
                            />
                        );
                    })}
                </div>
                {/* <HintModal {...hintModalState}
                onClose={onCloseHint} type="help" offers={insuranceProducts} /> */}
                <Footer
                    openModal={onShowHint}
                    isHelp={support_phone_number}
                    onNavBack={onBackStep}
                    onNextStep={onNextStep}
                    disabled={!currentInsuranceId}
                />
            </Profiler>
    );
};

export default GoodsConditions;
