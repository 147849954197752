import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectIssuerProfile } from '../../store/issuerApp/issuerApp.selector';
import { onSetLanguage } from '../../constants/messages';
import { getIssuerProfile } from '../../store/issuerApp/issuerApp.actions';
import Menu from '../components/Broker/Menu/Menu';
import Details from '../components/Broker/Details/Details';

import { connect } from '../helpers/fetchHelper';

const BrokerPortalScreen = () => {
    const history = useHistory();
    const profile = useSelector(selectIssuerProfile);
    const dispatch = useDispatch();
    const [page, setPage] = useState(false);
    const [notification, setNotification] = useState(null);

    if (!profile) {
        dispatch(getIssuerProfile());
    }

    useEffect(() => {
        const ws = connect('notifications', () => { setNotification(true); });
        return () => {
            ws.close();
        };
    }, []);

    useEffect(() => {
        if (profile && profile.role) {
            onSetLanguage(profile.language || 'es');
            setPage(profile.role !== 'issuer');
            if (profile.role === 'issuer') {
                history.push('/login');
            }
        }
    }, [profile]);


    return page && <div className="screen-broker">
        <Menu profile={profile} notification={notification} />
        <Details
            connect={cb => connect('update_data', (event) => { cb(JSON.parse(event.data)); })}
            profile={profile}
            notification={notification}
        />
    </div>;
};

export default BrokerPortalScreen;
