export const selectActualLanguage = state => state.common.actualLanguage;
export const selectProfile = state => state.common.profile;
export const selectTransactionCountry = state => state.common.profile.transaction_country;
export const selectOfferState = state => state.common.profile
    && state.common.profile.state_of_offer;
export const currencyPosition = state => state.common.currencyPosition;
export const selectCurrencyPosition = state => state.common.currencyPosition;
export const selectCultures = (state) => {
    if (!state.common.profile) {
        return [];
    }
    return state.common.profile.cultures || [];
};
export const selectLoading = state => state.common.loading;
export const actualLanguage = state => state.common.actualLanguage;
export const whiteLabelingLogo = state => state.common.whiteLabelingLogo;
export const selectWhiteLabelingLogo = state => state.common.whiteLabelingLogo;
export const whiteLabelingTitleColor = state => state.common.whiteLabelingTitleColor;
export const selectWhiteLabelingTitleColor = state => state.common.whiteLabelingTitleColor;
export const whiteLabelingPrimaryColor = state => state.common.whiteLabelingPrimaryColor;
export const selectWhiteLabelingPrimaryColor = state => state.common.whiteLabelingPrimaryColor;
export const whiteLabelingPrimaryOpacityColor = state => (
    state.common.whiteLabelingPrimaryOpacityColor
);
export const selectWhiteLabelingPrimaryOpacityColor = state =>
    state.common.whiteLabelingPrimaryOpacityColor;
export const whiteLabelingPrimaryLightColor = state => (
    state.common.whiteLabelingPrimaryLightColor
);
export const selectWhiteLabelingPrimaryLightColor = state =>
    state.common.whiteLabelingPrimaryLightColor;
export const whiteLabelingBackgroundColor = state => (
    state.common.whiteLabelingBackgroundColor
);
export const selectWhiteLabelingBackgroundColor = state =>
    state.common.whiteLabelingBackgroundColor;
export const selectWhiteLabelingHeaderBackground = state =>
    state.common.whiteLabelingHeaderBackground;
export const selectWhiteLabelingInputBackground = state =>
    state.common.whiteLabelingInputBackground;
export const isInsuranceLogoVisible = state => state.common.isInsuranceLogoVisible;
export const currentDetailsOption = state => state.common.currentDetailsOption;
export const selectDetailsOption = state => state.common.currentDetailsOption;
export const selectTransactionPrice = state => state.common.profile
    && state.common.profile.transaction_payment_amount;

export const selectAppFlow = state => state.common.appFlow;

export const selectThemeReadiness = state => state.common.isThemeReady;
export const selectGoogleAPIReady = state => state.common.isGoogleAPIReady;
export const selectRedsysAPIReady = state => state.common.isRedsysAPIReady;
export const selectMinimalLeadAmount = state => state.common.minimalLeadAmount;
export const selectMode = state => state.common.mode;
export const selectCustomerPseudoId = state => state.common.pseudoId;
export const selectPullInsurance = state => state.common.pullInsurance;
// export const selectReceiptPhotoRequired = state => state.common.receiptPhotoRequired;
export const selectConfigAttributes = state => state.common.configAttributes;
export const selectReceiptPhotoPosition = state => state.common.receiptPhotoPosition;
export const selectItemPhotoPosition = state => state.common.itemPhotoPosition;
export const selectTargetPhoto = state => state.common.targetPhoto;

export const selectBeneficiaryAttributes = state => state.common.beneficiaryAttributes;
export const selectSkipBeneficiaryAttributes = state => state.common.skipBeneficiaryAttributes;
export const selectBeneficiaryPosition = state => state.common.beneficiaryPosition;

export const selectCurrentStepNumber = state => state.common.currentStepNumber;
export const selectBreadcrumbs = state => state.common.breadcrumbs;

export const selectExtensions = state => state.common.extensions;
export const selectBirthdays = state => state.common.birthdays;
export const selectIndex = state => state.common.index;

export const selectTags = state => state.common.tags;

export const selectHeaderOption = state => state.common.stickyHeader;

export const selectCustomerCountry = state => state.common.country;

export const selectWhiteLabelingStyles = state => ({
    whiteLabelingBackgroundColor: selectWhiteLabelingBackgroundColor(state),
    whiteLabelingPrimaryColor: selectWhiteLabelingPrimaryColor(state),
    whiteLabelingTitleColor: selectWhiteLabelingTitleColor(state),
    whiteLabelingPrimaryOpacityColor: selectWhiteLabelingPrimaryOpacityColor(state),
    whiteLabelingPrimaryLightColor: selectWhiteLabelingPrimaryLightColor(state),
    whiteLabelingHeaderBackground: selectWhiteLabelingHeaderBackground(state),
    whiteLabelingInputBackground: selectWhiteLabelingInputBackground(state)
});
