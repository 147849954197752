import React, { useState, useEffect } from 'react';

import Table from '../Common/Table/Table';
import FormItem from '../Common/FormItem/FormItem';
import Section from '../Common/Section/Section';

import { LeadReactionTitles, groupProfileSectionTitles } from '../../constants';

const GroupIdentificationTab = ({ data }) => {
    const [profileData, setProfileData] = useState({});

    useEffect(() => {
        setProfileData(data);
    }, [data]);

    return <>
        <div className="merchants-section_card">
            <Section label="Group Profile">
                {
                    groupProfileSectionTitles.map(({ value, label }, k) =>
                        <FormItem key={k} label={label}>
                            <p>{profileData[value]}</p>
                        </FormItem>)
                }
                <Section sub label="Insurance Offer by Merchant Group">
                    <Table
                        titles={LeadReactionTitles}
                        data={profileData.offered_products || []} />
                </Section>
            </Section>
        </div>
    </>;
};
export default GroupIdentificationTab;
