import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
// import Async from 'react-select/async';
import { staffAutocomplete, fetchContacts, saveContact, editContact, deleteContact, uploadContacts, fetchStaff, saveStaff, editStaff, deleteStaff } from '../../../../../store/issuerApp/issuerApp.actions';
import { contactsTableItemsMandatory, managementTableItems, contactsTableItems, required } from '../../../../constants';
import { messages } from '../../../../../constants/messages';
import Table from '../../Common/Table/Table';
import Button from './Button';
import Upload from './Upload';
import Popup from './Popup';
import '../details.scss';

const setup = {
    clients: {
        fetch: fetchContacts,
        save: saveContact,
        edit: editContact,
        delete: deleteContact,
        keys: contactsTableItemsMandatory,
        form: contactsTableItems
    },
    management: {
        fetch: fetchStaff,
        save: saveStaff,
        edit: editStaff,
        delete: deleteStaff,
        keys: managementTableItems,
        form: managementTableItems
    },
};
const UserDetails = ({ type, lang, active, user }) => {
    // const { location } = useHistory();
    const [data, setData] = useState(null);

    const [upload, setUpload] = useState(false);
    const [action, setAction] = useState({});
    const [beError, setBeError] = useState('');

    const [list, setList] = useState([]);

    const settings = setup[type];
    const [search, setSearch] = useState();
    const [error, setError] = useState(null);

    useEffect(() => {
        setAction({ type: undefined });
        setUpload(false);
        setData([]);
        setSearch({ customer: undefined, advisor: undefined });
        settings.fetch({})
            .then((r) => {
                setData(r);
            });
    }, [type]);

    const onAdd = () => {
        setAction({ type: 'add', obj: {}, initiator: type });
    };

    const onCancel = () => {
        setAction({ type: undefined });
    };

    const onEdit = (d) => {
        settings.edit(d)
            .then(() => {
                setData((prev) => {
                    const tmp = [...prev];
                    tmp[data.findIndex(({ uuid }) => uuid === action.obj.uuid)] = d;
                    return tmp;
                });
                setAction({ type: undefined });
            })
            .catch((err) => { setBeError(JSON.stringify(err)); });
    };

    const onSort = (key) => {
        setSearch(prev => ({ ...prev, sort: key, order: prev.sort === key && prev.order === '-' ? '+' : '-' }));
    };

    const onSave = (d) => {
        if (action.type === 'edit') {
            onEdit(d);
            return;
        }
        settings.save(d)
            .then((r) => {
                setData((prev) => {
                    const tmp = [...prev];
                    tmp.unshift(r);
                    return tmp;
                });
                setAction({ type: undefined });
            })
            .catch((err) => { setBeError(JSON.stringify(err)); });
    };

    const onDelete = async () => {
        await settings.delete(action.obj.uuid);
        setData((prev) => {
            const tmp = [...prev];
            tmp.splice(tmp.findIndex(({ uuid }) => uuid === action.obj.uuid), 1);
            return tmp;
        });
        setAction({ type: undefined });
    };

    const onUpload = (file, option, cb) => {
        uploadContacts(file, option)
            .then((warn) => {
                cb(warn);
                setSearch({ customer: undefined, advisor: undefined });
                settings.fetch({})
                    .then((r) => {
                        setData(r);
                        if (!warn) {
                            setTimeout(() => upload && setUpload(false), 3000);
                        }
                    });
            })
            .catch((e) => { setError(e); });
    };

    const handleUpload = () => {
        setUpload(true);
    };

    const scrollBottomCallback = (n, cb) => {
        fetchContacts({
            search: search.customer,
            users: search.advisor,
            offset: n,
            sort: search.sort,
            order: search.order
        })
            .then((r) => {
                setData(prev => [...prev, ...r]);
                cb();
            });
    };

    const loadStaff = async (v) => {
        // eslint-disable-next-line no-new
        let result = await staffAutocomplete(v);
        if (typeof search !== 'undefined') {
            result = result.filter(r => !search.split(',').find(s => s === r.uuid) && r);
        }
        result = result.map(c => ({ ...c, label: c.value, value: c.pk }));

        setList(result);
        return new Promise(resolve => resolve(result));
    };

    useEffect(() => {
        loadStaff();
    }, []);

    /* const onSelect = (v) => {
        setSearch(pr => ({ ...pr, advisor: v.map(({ pk }) => pk).join(',') }));
    }; */

    const onChange = (v) => {
        setSearch(pr => ({ ...pr, customer: v }));
    };

    useEffect(() => {
        if (search && (typeof search.customer !== 'undefined' || typeof search.advisor !== 'undefined' || typeof search.sort !== 'undefined')) {
            setup[type].fetch({
                search: search.customer,
                users: search.advisor,
                sort: search.sort,
                order: search.order
            })
                .then((r) => {
                    setData(r);
                });
        }
    }, [search]);

    const handleEdit = (d) => {
        setAction({ type: 'edit', obj: d, initiator: type });
    };

    useEffect(() => {
        if (!action.type) {
            setBeError('');
        }
    }, [action]);

    return <div className={`details-wrapper ${active && 'active'}`}><div className="contacts-wrapper">
        {upload && type === 'clients' && <Upload
            resetError={() => setError(false)}
            onClose={() => setUpload(false)}
            onSend={onUpload}
            error={error} />}

        {action.type && <Popup
            user={user}
            required={required[type]}
            list={list}
            lang={lang}
            resetBeError={() => setBeError('')}
            error={beError}
            type={type}
            fields={settings.form(messages)}
            action={action}
            onDelete={onDelete}
            onSave={onSave}
            onCancel={onCancel} />}
        <div className="contacts-wrapper_btn-set">
            {type === 'clients' && <div className="contacts-wrapper_btn-set_btn left">
                {/* <div className="contacts-wrapper_btn-set_search">
                    <Async
                        placeholder={messages.SEARCH_BY_USER}
                        classNamePrefix="async"
                        onChange={onSelect}
                        isMulti
                        isClearable
                        cacheOptions
                        defaultOptions
                        loadOptions={v => loadStaff(v)} />
                </div> */}
                <input placeholder={`${messages.SEARCH_CONTACT}`} className="search" value={search && typeof search.customer === 'string' ? search.customer : ''} onChange={e => onChange(e.target.value)} />
            </div>}

            <div className="contacts-wrapper_btn-set_btn">
                <Button text={messages[`ADD_${type === 'clients' ? 'CUSTOMER' : 'USER'}`]} onClick={onAdd} />
                {type === 'clients' && <Button text={messages.IMPORT_CSV} onClick={handleUpload} />}
            </div>
        </div>
        {data && <Table
            type={type}
            cn={`contacts-table ${type}`}
            isEditable
            onEdit={d => handleEdit(d)}
            onSort={onSort}
            data={data}
            sort={{ key: search.sort, order: search.order }}
            fields={Object.keys(settings.keys(messages))}
            scrollBottomCallback={scrollBottomCallback}
            header={Object.values(settings.keys(messages))} />
        }
    </div></div>;
};

export default UserDetails;
