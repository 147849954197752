import React, { useEffect, useState } from 'react';
import Async from 'react-select/async';
import { fetchLeads, staffAutocomplete } from '../../../../../store/issuerApp/issuerApp.actions';
import { trackTableItems } from '../../../../constants';
import { messages } from '../../../../../constants/messages';

import Table from '../../Common/Table/Table';
import '../details.scss';

const TrackingDetails = ({ active, reload }) => {
    const [data, setData] = useState(null);
    const [search, setSearch] = useState(undefined);

    useEffect(() => {
        fetchLeads({})
            .then((r) => {
                setData(r);
            });
    }, []);

    useEffect(() => {
        if (reload) {
            fetchLeads(search || {})
                .then((r) => {
                    setData(r);
                    reload();
                })
                .catch(() => { reload(); });
        }
    }, [reload]);

    // TODO: the same functions are in the UserDetails conponent
    // TODO: ideally to separate Async component
    const loadContacts = async (v) => {
        // eslint-disable-next-line no-new
        let result = await staffAutocomplete(v);
        if (typeof search !== 'undefined') {
            result = result.filter(r => !search.split(',').find(s => s === r.uuid) && r);
        }
        return new Promise((resolve => resolve(result.map(c => ({ ...c, value: c, label: `${c.value}` })))));
    };

    const onSelect = (v) => {
        setSearch(prev => ({
            ...prev,
            users: v.map(({ pk }) => pk).join(','),
        }));
    };

    const onSort = (key) => {
        setSearch(prev => ({
            ...prev,
            sort: key,
            order: (!prev && '-') || (prev.sort === key && prev.order === '-' ? '+' : '-')
        }));
    };

    useEffect(() => {
        if (typeof search !== 'undefined') {
            fetchLeads(search)
                .then((r) => {
                    setData(r);
                });
        }
    }, [search]);

    return data && <div className={`details-wrapper ${active && 'active'}`}>
        <Async
            placeholder={messages.SEARCH_BY_USER}
            classNamePrefix="async"
            onChange={onSelect}
            isMulti
            /* isClearable */
            /* cacheOptions */
            defaultOptions
            loadOptions={v => loadContacts(v)}
        />
        <Table
            onSort={onSort}
            isEditable={false}
            cn="tracking-table"
            data={data}
            sort={search ? { key: search.sort, order: search.order } : {}}
            fields={Object.keys(trackTableItems(messages))}
            header={Object.values(trackTableItems(messages))} /></div>;
};

export default TrackingDetails;
