import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { messages } from './constants/messages';
import AirlineFlightContainer from './containers/AirlineFlightContainer';
import CustomerJourneyContainer from './containers/CustomerJourneyContainer';
import ProductInsurance from './containers/ProductInsurance';
import ProductInsurancesList from './containers/ProductInsurancesList';
import Payment from './containers/Payment';
import NotValidState from './components/Common/NotValidState/NotValidState';
import Congratulations from './components/Payment/Congratulations/Congratulations';
import './styles/App.scss';
import { parseInsuranceLink, parseContractsLink } from './helpers/helpers';
import history from './helpers/history';
import { appStarted } from './helpers/amplitude';
import ContractsList from './components/ContractsList/ContractsList';
import ContractDetails from './components/ContractDetails/ContractDetails';
import ContractProductDetails from './components/ContractProductDetails/ContractProductDetails';
import ContractClaim from './components/ContractClaim/ContractClaim';
import ContractClaimSuccess from './components/ContractClaimSuccess/ContractClaimSuccess';
import Confirmation from './components/ContractClaim/Confirmation/Confirmation';
import withLocalization from './hocs/withLocalization';
import ForgotPassword from './issuer-application/components/ForgotPassword/ForgotPassword';
import Login from './issuer-application/components/Login/Login';
import ApplicationScreen from './issuer-application/containers/ApplicationScreen';
import Choice from './issuer-application/containers/Choice';
import BrokerPortalScreen from './issuer-application/containers/BrokerPortalScreen';
import PrivateRoute from './issuer-application/helpers/privateRoute';
import PasswordReset from './issuer-application/components/PasswordReset/PasswordReset';
import { connectProtectors } from './helpers/protectors';
import MerchantsScreen from './merchants/containers/MerchantsScreen';
import MerchantsList from './merchants/components/MerchantsList';
import MerchantProfile from './merchants/components/MerchantProfile/MerchantProfile';
import GroupProfile from './merchants/components/GroupProfile/GroupProfile';

const App = ({ store }) => {
    const {
        loadInsuranceProfile,
        loadContractsProfile,
        loadInvalidLink,
        verifyResetPassword,
        loadIssuerProfile,
        loadWhiteLabeling,
    } = connectProtectors(store);
    return (
        <Router history={history}>
            <Switch>
                <Route
                    path="/"
                    exact
                    render={() => {
                        loadInsuranceProfile();
                    }}
                />
                <Route
                    path="/login"
                    exact
                    render={() => {
                        loadWhiteLabeling();
                        return <Login />;
                    }}
                />
                <Route
                    path="/forgot-password"
                    exact
                    render={() => <ForgotPassword />}
                />
                <PrivateRoute
                    path="/app"
                    component={() =>
                    // loadIssuerProfile();
                        <ApplicationScreen />
                    }
                />
                <PrivateRoute
                    path="/broker-portal"
                    component={() =>
                        // loadIssuerProfile();
                        <BrokerPortalScreen />
                    }
                />
                <PrivateRoute
                    path="/choice"
                    component={() =>
                        // loadIssuerProfile();
                        <Choice />
                    }
                />
                <Route
                    path={['/merchant-groups']}
                    render={() => {
                        loadIssuerProfile();
                        return <Switch>
                            <Route
                                path="/merchant-groups"
                                exact
                                render={() => (
                                    <MerchantsScreen>
                                        <MerchantsList />
                                    </MerchantsScreen>
                                )} />
                            <Route
                                path="/merchant-groups/:id"
                                exact
                                render={props => (
                                    <MerchantsScreen>
                                        <GroupProfile pk={props.match.params.id} />
                                    </MerchantsScreen>
                                )} />
                            <Route
                                path="/merchant-groups/:id/merchants/:pk"
                                render={props => (
                                    <MerchantsScreen>
                                        <MerchantProfile pk={props.match.params.pk} />
                                    </MerchantsScreen>
                                )} />
                        </Switch>;
                    }
                    }
                />
                <Route
                    path="/password-reset"
                    exact
                    render={() => verifyResetPassword()}
                />
                <Route
                    path="/new-password"
                    exact
                    render={() => <PasswordReset />}
                />
                <Route
                    path="/not-valid"
                    // exact
                    render={() => {
                        loadInvalidLink();
                        return (<NotValidState />);
                    }}
                />
                {/* <Route
                    path="/:id"
                    render={(props) => {
                        if (props.match.url.indexOf('/i-')) {
                            debugger;
                        }
                    }}
                /> */}
                <Route
                    path="/seguros/:id"
                    exact
                    render={() => parseInsuranceLink()}
                />
                <Route
                    path="/contracts/:id"
                    exact
                    render={() => parseContractsLink()} />
                <Route
                    path={['/purchases']}
                    render={() => {
                        loadContractsProfile();
                        appStarted();
                        return (
                            <Switch>
                                <Route
                                    path="/purchases"
                                    exact
                                    render={() => (
                                        <ContractsList />
                                    )}
                                />
                                <Route
                                    path="/purchases/:id"
                                    exact
                                    render={() => (
                                        <ContractDetails />
                                    )}
                                />
                                <Route
                                    path="/purchases/:id/product"
                                    exact
                                    render={() => (
                                        <ContractProductDetails />
                                    )}
                                />
                                <Route
                                    path="/purchases/:id/claim"
                                    exact
                                    render={() => (
                                        <ContractClaim />
                                    )}
                                />
                                <Route
                                    path="/purchases/:id/success"
                                    exact
                                    render={() => (
                                        <ContractClaimSuccess />
                                    )}
                                />
                                <Route
                                    path="/purchases/:id/reactivation/success"
                                    exact
                                    render={() => (
                                        <Confirmation
                                            title={messages.YOUR_CONTRACT_HAS_BEEN_REACTIVATED}
                                        />
                                    )}
                                />
                                <Route
                                    path="/purchases/:id/cancellation/success"
                                    exact
                                    render={() => (
                                        <Confirmation
                                            title={messages.YOUR_CONTRACT_HAS_BEEN_CANCELLED}
                                            showIcon />
                                    )}
                                />
                            </Switch>
                        );
                    }}
                />
                <Route
                    path={['/insurances', '/store-purchase', '/payment', '/success', '/airline-flow', '/customer-journey']}
                    render={() => {
                        loadInsuranceProfile();
                        appStarted();
                        return (
                            <Switch>
                                <Route
                                    path="/airline-flow"
                                    exact
                                    render={() => (
                                        <AirlineFlightContainer />
                                    )} />
                                <Route
                                    path="/customer-journey"
                                    exact
                                    render={() => (
                                        <CustomerJourneyContainer />
                                    )} />
                                <Route
                                    path="/insurances"
                                    exact
                                    render={() => (
                                        <ProductInsurancesList />
                                    )}
                                />
                                <Route
                                    path="/store-purchase"
                                    exact
                                    render={() => <ProductInsurance />}
                                />
                                <Route
                                    path="/payment"
                                    exact
                                    render={() => (
                                        <Payment />
                                    )}
                                />
                                <Route
                                    path="/success"
                                    exact
                                    render={() => (
                                        <Congratulations />
                                    )}
                                />
                            </Switch>
                        );
                    }}
                />
            </Switch>
        </Router>
    );
};

export default withLocalization(App);
