export const SET_ACTUAL_FOOTER_SCREEN = 'SET_ACTUAL_FOOTER_SCREEN';

export const SET_CART_NUMBER = 'SET_CART_NUMBER';
export const SET_CART_EXPIRY_DATE = 'SET_CART_EXPIRY_DATE';
export const SET_CART_CVV = 'SET_CART_CVV';
export const SET_ERROR_POPUP = 'SET_ERROR_POPUP';
export const SET_VALIDATION_ERROR = 'SET_VALIDATION_ERROR';
export const SET_PAYMENT_VALIDATION = 'SET_PAYMENT_VALIDATION';
export const SET_CARD_VALID = 'SET_CARD_VALID';
export const SET_CERTIFICATE_URL = 'SET_CERTIFICATE_URL';
export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA';
export const SET_PAYMENT_INSURAMA_URL = 'SET_PAYMENT_INSURAMA_URL';
export const SET_PAYMENT_REDSYS_DATA = 'SET_PAYMENT_REDSYS_DATA';
export const SET_PAYMENT_INITIATION_ERROR = 'SET_PAYMENT_INITIATION_ERROR';
