// TODO to check do we need this file
export const API_KEY = 'AIzaSyDz3dMrDcpYbVHEb9ZfS5oac4K_tpB_OjQ';

export const getPlace = (places) => {
    if (!places || !places.length) {
        return '';
    }
    return places[0].formatted_address;
};

export const getNearByPlace = ({ position, language }) => {
    if (!position || !google || !google.maps) {
        return Promise.resolve();
    }
    const { coords: { latitude, longitude } } = position;
    const location = new google.maps.LatLng({ lat: latitude, lng: longitude });
    const geocoder = new google.maps.Geocoder();
    return new Promise((resolve, reject) => {
        geocoder.geocode({ location, region: language }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                return resolve(results);
            }
            return reject([]);
        });
    });
};
