/* eslint-disable import/prefer-default-export */
export const merchantsGroupData = [{
    group_name: 'Fielmann',
    category: 'Health products store / Optician and hearing aid',
    group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c60',
    country: 'Switzerland',
    domain: 'fielmann.ch',
    offered_products: [{
        insurance: 'Europaweiter Diebstahlschutz',
        items: ['Glasses']
    }, {
        insurance: 'Bruch',
        items: ['Glasses']
    }]
}, {
    group_name: 'Coop',
    category: 'Supermarket',
    group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c70',
    country: 'Switzerland',
    domain: 'fielmann.ch',
    offered_products: [{
        insurance: 'Europaweiter Diebstahlschutz',
        items: ['Glasses']
    }, {
        insurance: 'Bruch',
        items: ['Glasses']
    }]
}, {
    group_name: 'Migros',
    category: 'Supermarket',
    group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c78',
    country: 'Switzerland',
    domain: 'fielmann.ch',
    offered_products: [{
        insurance: 'Europaweiter Diebstahlschutz',
        items: ['Glasses']
    }, {
        insurance: 'Bruch',
        items: ['Glasses']
    }]
}, {
    group_name: 'Media Market',
    category: 'Electronics store',
    group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c26',
    country: 'Switzerland',
    domain: 'fielmann.ch',
    offered_products: [{
        insurance: 'Europaweiter Diebstahlschutz',
        items: ['Glasses']
    }, {
        insurance: 'Bruch',
        items: ['Glasses']
    }]
}];

export const merchantsData = [{
    group_name: 'Fielmann',
    group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c60',
    name: 'Fielmann',
    category: 'Health products store / Optician and hearing aid',
    city: 'Zürich',
    address: 'Bahnhofstrasse 83, 8001 Zürich',
    uuid: '71848286-f1c5-4e4c-a33b-e02aeb3a6c60'
}, {
    group_name: 'Fielmann',
    group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c60',
    name: 'Fielmann Oerlikon',
    category: 'Health products store / Optician and hearing aid',
    city: 'Zürich',
    address: 'Sternen Oerlikon, Schaffhauserstrasse 355, 8050 Zürich',
    uuid: '7e438946-6660-4582-885e-41c714a93e99'
}, {
    group_name: 'Fielmann',
    group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c60',
    name: 'Fielmann',
    category: 'Health products store / Optician and hearing aid',
    city: 'Thalwil',
    address: 'Gotthardstrasse 16b, 8800 Thalwil',
    uuid: '71848286-f1c5-4e4c-a33b-e02aeb3a6c62'
}, {
    group_name: 'Fielmann',
    group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c60',
    name: 'Fielmann',
    category: 'Health products store / Optician and hearing aid',
    city: 'Spreitenbach',
    address: 'Shopping-Center Shoppi Tivoli, 8957 Spreitenbach',
    uuid: '791848286-f1c5-4e4c-a33b-e02aeb3a6c63'
}, {
    group_name: 'Fielmann',
    group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c60',
    name: 'Fielmann',
    category: 'Health products store / Optician and hearing aid',
    city: 'Uster',
    address: 'Zürichstrasse 20 Illuster, 8610 Uster',
    uuid: '71848286-f1c5-4e4c-a33b-e02aeb3a6c64'
}, {
    group_name: 'Fielmann',
    group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c60',
    name: 'Fielmann',
    category: 'Health products store / Optician and hearing aid',
    city: 'Bülach',
    address: 'Bahnhofstrasse 11, 8180 Bülach',
    uuid: '71848286-f1c5-4e4c-a33b-e02aeb3a6c65'
}, {
    group_name: 'Fielmann',
    group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c60',
    name: 'Fielmann',
    category: 'Health products store / Optician and hearing aid',
    city: 'Baden',
    address: 'Weite G. 27, 5400 Baden',
    uuid: '71848286-f1c5-4e4c-a33b-e02aeb3a6c66'
}, {
    group_name: 'Fielmann',
    group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c60',
    name: 'Fielmann',
    category: 'Health products store / Optician and hearing aid',
    city: 'Rapperswil-Jona',
    address: 'Bahnhofstrasse 11, 8640 Rapperswil-Jona',
    uuid: '71848286-f1c5-4e4c-a33b-e02aeb3a6c67'
}, {
    group_name: 'independent store',
    group_uuid: null,
    name: 'Tomsbike',
    category: 'Sporting Goods',
    city: 'Wetzikon',
    address: 'Zürcherstrasse 27, 8620 Wetzikon',
    uuid: '8e433946-6660-4582-885e-41c714a93e23'
}, {
    group_name: 'independent store',
    group_uuid: null,
    name: 'Goldschmiede Schaub',
    category: 'Fashion store / Jewellery and watches store',
    city: 'Emmenbrücke',
    address: 'Luzernerstrasse 27, 6020 Emmenbrücke',
    uuid: '7f433946-6460-4582-985e-41c714a93ei9'
}, {
    group_name: 'Fust',
    group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c72',
    name: 'Fust',
    category: 'Household electronic store',
    city: 'Wallisellen',
    address: 'MP Glattzentrum, 8304 Wallisellen',
    uuid: '7f433946-6460-4582-985e-41c714a93ei9'
}, {
    group_name: 'Fust',
    group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c72',
    name: 'Dipl Ing. Fust',
    category: 'Household electronic store',
    city: 'Zurich',
    address: 'Badenerstrasse 109, 8004 Zürich',
    uuid: '7f433946-6460-4582-985e-41c714a93ei1'
}, {
    group_name: 'Fust',
    group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c72',
    name: 'Dipl Ing. Fust',
    category: 'Household electronic store',
    city: 'Dietikon',
    address: 'Riedstrasse 6, 8953 Dietikon',
    uuid: '7f433946-6460-4582-985e-41c714a93ei2'
}, {
    group_name: 'Fust',
    group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c72',
    name: 'Fust',
    category: 'Household electronic store',
    city: 'Dübendorf',
    address: 'Wilstrasse 2, 8600 Dübendorf',
    uuid: '7f433946-6460-4582-985e-41c714a93ei3'
}, {
    group_name: 'independent store',
    group_uuid: null,
    name: 'Leupi Bike GmbH',
    category: 'Sporting Good Store / Bicycle Store',
    city: 'Näfels',
    address: 'Bahnhofstrasse 25, 8752 Näfels',
    uuid: '7f433946-6460-4582-985e-41c714a93ei9'
}, {
    group_name: 'independent store',
    group_uuid: null,
    name: 'Vellokeller Sven Ehrler',
    category: 'Sporting Good Store / Bicycle Store',
    city: 'Lauerz',
    address: 'Seestrasse 1, 6424 Lauerz',
    uuid: '7f433946-6460-4582-985e-41c714a93ei9'
}, {
    group_name: 'independent store',
    group_uuid: null,
    name: 'bike & climb up',
    category: 'Sporting Good Store',
    city: 'Ibach SZ',
    address: 'Gotthardstrasse 62, 6438 Ibach SZ',
    uuid: '7f433946-6460-4582-985e-41c714a93ei9'
}];

export const merchantData = {
    '8e433946-6660-4582-885e-41c714a93e23': {
        name: 'Tomsbike',
        group_name: 'independent store',
        group_uuid: null,
        uuid: '8e433946-6660-4582-885e-41c714a93e23',
        category: 'Sporting Good Store / Bicycle Store',
        city: 'Wetzikon',
        address: 'Zürcherstrasse 27, 8620 Wetzikon',
        description: 'Established 2013, Tomsbike offers a wide range of classic bicycles and components',
        phone_number: '044 932 33 97',
        website: 'tomsbike.ch',
        facebook_profile: 'www.facebook.com/tomsbike',
        currency: 'CHF',
        tags: ['Premium', 'Bike Lovers'],
        payment_identification: [{
            payment_amount: 300,
            trigger: 'Deposit E-Bike'
        }],
        merchant_identification: [{
            payment_scheme: 'Visa',
            merchant_descriptor: 'Tomsbike',
            merchant_name: 'Tomsbike',
            merchant_id: '044 932 33 97',
            merchant_address: 'Zürcherstrasse 11, 8670 Wetzikon'
        }, {
            payment_scheme: 'Master',
            merchant_descriptor: 'Velo Tom',
            merchant_name: 'Tomsbike Premium Bike Seller',
            merchant_id: '932 33 97 044',
            merchant_address: 'Zürcherstrasse 11, 8670 Wetzikon'
        }],
        pos_identification: {
            pos: true,
            offered_products: [{
                insurance: 'Theft Europe', items: ['Bicycle', 'E-Bike']
            }, {
                insurance: 'Comprehensive Europe', items: ['Bicycle', 'E-Bike']
            }, {
                insurance: '24h- Pannenhilfe', items: ['Bicycle', 'E-Bike']
            }]
        },
        items_sold: [{
            name: 'Bicycle',
            percents_of_total_turnover: 90,
            statistic: [5, 5, 10, 20, 30, 40, 40, 30, 20, 5, 5, 20],
            min_price: 800,
            max_price: 5000
        }, {
            name: 'E-Bike',
            percents_of_total_turnover: 5,
            statistic: [5, 5, 5, 10, 5, 5, 5, 5, 5, 5, 5, 5],
            min_price: 1200,
            max_price: 9800
        }],
        ibans: ['CH93 0076 2011 6238 5295 7', 'CH60 0076 0000 1111 5295 6']
    },
    '7f433946-6460-4582-985e-41c714a93ei9': {
        name: 'Goldschmiede Schaub',
        group_name: 'independent store',
        group_uuid: null,
        uuid: '7f433946-6460-4582-985e-41c714a93ei9',
        category: 'Fashion store / Jewellery and watches store',
        city: 'Emmenbrücke',
        address: 'Luzernerstrasse 27, 6020 Emmenbrücke',
        description: 'Specialist in personalised Jewelery',
        phone_number: '041 731 55 88',
        website: 'www.schaub-goldschmiede.ch/',
        facebook_profile: '-',
        currency: 'CHF',
        tags: ['Premium', 'Craft'],
        payment_identification: [{
            payment_amount: 700,
            trigger: 'Polish Ring'
        }, {
            payment_amount: 50,
            trigger: 'Battery Change Watch'
        }],
        merchant_identification: [{
            payment_scheme: 'Visa',
            merchant_descriptor: 'Schaub',
            merchant_name: 'Schaub',
            merchant_id: '88 041 731 55',
            merchant_address: 'Luzernerstrasse 27, 6020 Emmenbrücke'
        }, {
            payment_scheme: 'Master',
            merchant_descriptor: 'Goldschmiede Scha',
            merchant_name: 'Schaub',
            merchant_id: '041 731 55 88',
            merchant_address: 'Luzernerstrasse 27, 6020 Emmenbrücke'
        }],
        pos_identification: {
            pos: false,
            offered_products: []
        },
        items_sold: [{
            name: 'Jewellery',
            percents_of_total_turnover: 70,
            statistic: [5, 5, 10, 10, 20, 10, 10, 5, 20, 20, 35, 40],
            min_price: 2000,
            max_price: 15000
        }, {
            name: 'Watches',
            percents_of_total_turnover: 20,
            statistic: [5, 5, 5, 10, 5, 5, 5, 5, 5, 5, 15, 20],
            min_price: 3000,
            max_price: 25000
        }],
        ibans: ['CH90 0076 2011 6238 5295 1', 'CH81 0076 0000 1111 5295 7']
    },
    '7e438946-6660-4582-885e-41c714a93e99': {
        name: 'Fielmann Oerlikon',
        group_name: 'Fielmann',
        group_uuid: '91848286-f1c5-4e4c-a33b-e02aeb3a6c60',
        uuid: '7e438946-6660-4582-885e-41c714a93e99',
        category: 'Health products store / Optician and hearing aid',
        city: 'Zürich',
        address: 'Sternen Oerlikon, Schaffhauserstrasse 355, 8050 Zürich',
        description: 'Large Low price Glasses Retailer',
        phone_number: '044 932 33 97',
        website: 'fielmann.ch',
        facebook_profile: '-',
        currency: 'CHF',
        tags: ['Low Price', 'Large Retailer'],
        payment_identification: [{
            payment_amount: 20,
            trigger: 'Adjust Glasses'
        }],
        merchant_identification: [{
            payment_scheme: 'Visa',
            merchant_descriptor: 'Fielmann Oerlikon',
            merchant_name: 'Brille Fielmann Oerlikion',
            merchant_id: '88 041 731 55',
            merchant_address: 'Sternen Oerlikon, Schaffhauserstrasse 355, 8050 Zürich'
        }, {
            payment_scheme: 'Master',
            merchant_descriptor: 'Fielmann Oerlikon',
            merchant_name: 'Brille Fielmann Oerlikion',
            merchant_id: '041 731 55 88',
            merchant_address: 'Sternen Oerlikon, Schaffhauserstrasse 355, 8050 Zürich'
        }],
        pos_identification: {
            pos: true,
            offered_products: [{
                insurance: 'Worldwide Allrisk', items: ['Glasses']
            }]
        },
        items_sold: [{
            name: 'Glasses',
            percents_of_total_turnover: 97,
            statistic: [10, 20, 20, 20, 20, 20, 10, 40, 20, 20, 20, 20],
            min_price: 100,
            max_price: 2500
        }],
        ibans: ['CH03 0076 2011 6238 5295 1', 'CH71 0076 0000 1111 5295 9']
    }
};
