import React, { useState, useEffect } from 'react';
import InputWrap from '../../Common/Input/InputWrap';
import CustomDatePicker from '../../AirlineInsurance/CustomDatePicker';
import CheckBoxSet from './CheckBoxSet';
import { messages } from '../../../constants/messages';
import { validateNif } from '../../../helpers/validators';

// insured_dob_1,insured_first_name_1, insured_last_name_1, female_1, Sickness
const Card = ({ data, handleDataChange, title,
    dateDisabled = true/* , setValidationStatus */ }) => {
    const [error, setError] = useState({});
    const [cardData, setCardData] = useState([]);

    useEffect(() => { setCardData(data); }, [data]);

    const onValidate = (field) => {
        const { value } = cardData.filter(({ key }) => key === field)[0];
        setError((prev) => {
            if (field.includes('tax')) {
                return { ...prev, [field]: validateNif(value).code };
            }
            return { ...prev, [field]: !value ? messages.MANDATORY_FIELD : '' };
        });
    };

    const onDataChange = (value, key) => {
        setError(prev => ({ ...prev, [key]: null }));
        handleDataChange(value, key);
    };

    return <div className="discovered-card-wrap">
        <p className="discovered-card-wrap_title">{title}</p>
        {
            cardData.map((item, i) =>
                <div className="discovered-element-wrap" key={i}>
                    <div className="discovered-input-wrap">
                        <div className="discovered-input-title">
                            <div className="discovered-input-lable">{item.label}</div>
                        </div>
                        {item.key.includes('dob') ?
                            <CustomDatePicker
                                tag
                                disabled={dateDisabled}
                                label=""
                                onChange={v => onDataChange(v, item.key)}
                                date={item.value} /> :
                            ((!item.key.includes('female') && <InputWrap
                                className="discovered-input"
                                type="text"
                                value={item.value}
                                onChange={v => onDataChange(v, item.key)}
                                onBlur={() => onValidate(item.key)}
                                error={error[item.key]}
                            />) ||
                            <CheckBoxSet
                                labels={[messages.FEMALE, messages.MALE]}
                                onChange={v => handleDataChange(v, item.key)}
                                data={item.value} />)}
                    </div>
                </div>)
        }
    </div>;
};


export default Card;
