import React, { useEffect, useRef } from 'react';
import { ChromePicker } from 'mp-react-color';

import './Color.scss';

const Color = ({ color, size = 'small', colorPicker, handleEdit, i, name, handleColorChange }) => {
    const colorPickerArea = useRef();
    const circle = useRef();

    const handleClickOutside = (event) => {
        if (colorPickerArea.current && !colorPickerArea.current.contains(event.target)
            && circle.current && !circle.current.contains(event.target)) {
            handleEdit(null);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    });

    return (
        <>
            <div
                ref={circle}
                onClick={() => handleEdit(i)}
                style={{ backgroundColor: color }}
                className={`color-circle color-circle-${size} `}
            >
                {
                    colorPicker &&
                    <div
                        ref={colorPickerArea}
                        style={{
                            position: 'absolute',
                            bottom: '100%',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            marginBottom: 10,
                            zIndex: 2,
                        }}
                    >
                        <ChromePicker
                            useScreenCaptureAPI
                            rootElement={document.querySelector('#root')}
                            color={color}
                            onChangeComplete={({ hex }) => handleColorChange(name, hex)}
                        />
                    </div>
                }
            </div>
        </>
    );
};

export default Color;
