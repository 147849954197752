import React from 'react';
import cn from 'classnames';
import { CONTRACT_STATUSES } from '../../../constants/constants';
import './Chips.scss';

const Chips = ({ text, type }) => (
    <div className={cn(
        'custom-chips', {
            'custom-chips--active': type === CONTRACT_STATUSES.ACTIVE,
            'custom-chips--cancelled': type === CONTRACT_STATUSES.PENDING_CANCELLATION,
            'custom-chips--terminated': type === CONTRACT_STATUSES.VOID
        })}
    >
        {text}
    </div>
);

export default Chips;
