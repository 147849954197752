import React from 'react';

const OfferReadMode = ({ data }) => (
    <div className="offer-management-card__section__segments__offer">
        <div style={{ wordBreak: 'break-word' }}>
            {
                data.offer_template.template.length > 80 ?
                    `${data.offer_template.template.substring(0, 80)}...` : data.offer_template.template
            }
        </div>
        <div>
            {
                data.offered_products.map(({ label }) => (
                    <p className="offer-management-card__section__segments__offer__service">{label}</p>))
            }
        </div>
        <div>
            <div>
                Delay: {`${data.delay}h`}
            </div>
            <div>
                Reminder: {data.reminder.label}
            </div>
            <div>
                Coverage check: {data.coverage_check_enabled.label}
            </div>
            <div>
                Channel: {data.channel.label}
            </div>
            <div>
                Item specific offer: {data.item_type_specific.label}
            </div>
        </div>
        <div>
            {
                data.allocation
            }%
        </div>
    </div>
);

export default OfferReadMode;
