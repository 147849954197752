import React, { useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Table from '../Common/Table/Table';
import FormItem from '../Common/FormItem/FormItem';
import Section from '../Common/Section/Section';
import { profileSectionTitles, itemsSoldSectionTitles, paymentIdentificationTitles, months, colors } from '../../constants';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    scales: {
        y: {
            display: false,
        },
        x: {
            grid: {
                display: false
            }
        }
    },
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
        },
    },
};

const ProfileAndProductTab = ({ data }) => {
    const [profileData, setProfileData] = useState({});
    const [dataSets, setDataSets] = useState([]);

    useEffect(() => {
        if (data.uuid) {
            let { items_sold } = data;
            const sets = [];
            items_sold = items_sold.map((item, k) => {
                sets[k] = { label: item.name, data: item.statistic, backgroundColor: colors[k] };
                return { ...item,
                    price: `${item.min_price} - ${item.max_price}`,
                    percents_of_total_turnover: `${item.percents_of_total_turnover}%` };
            });

            setProfileData(() => ({ ...data, items_sold }));
            setDataSets(sets);
        }
    }, [data]);

    return <>
        <div className="merchants-section_card">
            <Section label="Profile">
                {
                    profileSectionTitles.map(({ value, label }, k) =>
                        <FormItem key={k} label={label}>
                            <p>{profileData[value]}</p>
                        </FormItem>)
                }
            </Section>
        </div>

        <div className="merchants-section_card">
            <Section label="Items Statistics">
                <Section label="Items Sold" sub>
                    <Table
                        titles={itemsSoldSectionTitles}
                        data={profileData.items_sold || []}
                        currency={profileData.currency} />
                </Section>

                <Section label="Annual Sales" sub>
                    <div style={{ width: 400 }}>
                        <Bar options={options} data={{ labels: months, datasets: dataSets }} />
                    </div>
                </Section>
            </Section>
        </div>

        <div className="merchants-section_card">
            <Section label="Further Information" />
            <FormItem label="Tags">
                <p>{profileData.tags && profileData.tags.join(', ')}</p>
            </FormItem>
            <Section label="Specific Payment Identification" sub>
                <Table
                    titles={paymentIdentificationTitles}
                    data={profileData.payment_identification || []}
                    currency={profileData.currency} />
            </Section>
        </div>

    </>;
};
export default ProfileAndProductTab;
