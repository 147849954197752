import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';


function useOutsideClick(ref, setOpen) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}


function OutsideClick(props) {
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, props.setOpen);
    return <div className="outside-click-wrapper" ref={wrapperRef}>{props.children}</div>;
}

OutsideClick.propTypes = {
    children: PropTypes.element.isRequired
};

export default OutsideClick;
