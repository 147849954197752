import React from 'react';
import Table from '../Common/Table/Table';
import FormItem from '../Common/FormItem/FormItem';

const LeadReactionTab = ({ data, titles }) => {
    const { offered_products, pos } = data;
    return <>
        <FormItem label="Offers insurance at PoS">
            <p>{pos ? 'Yes' : 'No'}</p>
        </FormItem>

        <Table data={offered_products} titles={titles} />
    </>;
};

export default LeadReactionTab;
