import history from '../../helpers/history';

const backendAPI = process.env.REACT_APP_API_URL;
const wsAPI = process.env.WS_API_URL;

export function serializeURLParams(data) {
    let params = '';
    return Object.keys(data).map((k) => {
        params = `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`;
        return params;
    }).join('&');
}

export function fetchWrapper(
    { url, method = 'GET', body = null, headers = {}, ignoreAuth = false, /* file = false */ }, notStringify = false
) {
    const requestParams = {
        method,
        headers
    };
    let urlParams = '';
    if (method !== 'GET' && body) {
        if (notStringify) {
            requestParams.body = body;
            /* if (file) {
                headers['Content-Type'] =
                'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW';
            } */
        } else {
            requestParams.body = JSON.stringify(body);
            headers['Content-Type'] = 'application/json';
        }
    } else if (method === 'GET' && body && Object.keys(body).length) {
        urlParams = '?';
        urlParams += serializeURLParams(body);
    }
    const authToken = localStorage.getItem('iss_token');
    if (authToken && !ignoreAuth) {
        headers.Authorization = `JWT ${authToken}`;
    }
    return fetch(`${backendAPI}${url + urlParams}`, requestParams)
        .then((response) => {
            if (!response) {
                return Promise.reject(null);
            }
            if (response.status >= 200 && response.status < 400) {
                try {
                    return response.json()
                        .catch(() => Promise.resolve({}));
                } catch (err) {
                    return Promise.resolve({});
                }
            }
            if (response.status === 401 || response.status === 403) {
                history.push('/login');
            }
            return response.json()
                .then(err => Promise.reject(err));
        }).catch((error) => {
            if (error.status === 401 || error.status === 403) {
                history.push('/login');
            }
            return Promise.reject(error);
        });
}

export const parseError = (error, predicate, message = '') => {
    if (typeof error === 'string') {
        return message ? `${message}, ${predicate ? `${predicate}:` : ''} ${error}` : error;
    }
    if (Array.isArray(error) && typeof error[0] === 'object') {
        const text = error.map(obj => parseError(obj, '', message));
        return message ? `${message}, ${text}` : text;
    }
    if (Array.isArray(error)) {
        const text = `${predicate ? `${predicate}:` : ''} ${error.join(',')}`;
        return message ? `${message}, ${text}` : text;
    }
    if (typeof error === 'object') {
        const text = Object.keys(error)
            .map(key => parseError(error[key], key, message))
            .join(', ');
        return text;
    }
    return message;
};

export function connect(channel, onMessageCb = () => {}, onCloseCb = () => {}) {
    const ws = new WebSocket(
        `${wsAPI}/ws/${channel}/`
    );

    ws.onopen = () => {
    };

    ws.onmessage = (event) => {
        onMessageCb(event);
    };

    ws.onerror = () => {
        // setTimeout(() => { connect(channel); }, 5000);
    };

    ws.onclose = () => {
        onCloseCb();
        // setTimeout(() => { connect(channel); }, 5000);
    };

    return ws;
}
