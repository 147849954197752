import React from 'react';
import { useSelector } from 'react-redux';
import { selectWhiteLabelingStyles } from '../../../store/selectors';

const BackIcon = () => {
    const { whiteLabelingPrimaryColor } = useSelector(selectWhiteLabelingStyles);
    return (
        <div className="footer-btn-back">
            <svg width="18px" height="16px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>Back</title>
                <g id="Logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                    <g id="Style_guide" transform="translate(-385.000000, -1474.000000)" stroke={whiteLabelingPrimaryColor} strokeWidth="1.5">
                        <g id="Back" transform="translate(385.000000, 1475.000000)">
                            <polyline id="Path-4" transform="translate(3.893800, 6.913595) rotate(-270.000000) translate(-3.893800, -6.913595) " points="-3 4 3.89379983 9.82718993 10.7875997 4" />
                            <line x1="0.980204864" y1="7" x2="17.1178003" y2="7" id="Path-12" />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default BackIcon;
