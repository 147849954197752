/* eslint-disable camelcase */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useContract } from '../../helpers/customHooks';
import Footer from '../Common/Footer/Footer';
import ProductDetails from '../ProductDetails/ProductDetails';
import withTheme from '../../hocs/withTheme';

import './ContractProductDetails.scss';

const ContractProductDetails = () => {
    const { id } = useParams();
    const contract = useContract(id);

    const history = useHistory();
    if (!contract) {
        return null;
    }

    const {
        product_information: {
            branding_name,
            product_description,
            contract_length_unit,
            contract_length,
            contract_minimum_length,
            contract_maximum_length,
            payment_scheme,
            deductible,
            currency,
            cover,
            not_covered,
            product_fact_sheet,
            product_sheet
        }
    } = contract;

    const navToContractDetails = () => {
        history.push(`/purchases/${id}`);
    };

    return (
        <div className="contract-product-details-wrapper">
            <ProductDetails
                brandingName={branding_name}
                productDescription={product_description}
                contractLengthUnit={contract_length_unit}
                contractLength={contract_length}
                contractMinimumLength={contract_minimum_length}
                contractMaximumLength={contract_maximum_length}
                paymentScheme={payment_scheme}
                deductible={deductible}
                currency={currency}
                cover={cover}
                nonCover={not_covered}
                productSheet={product_fact_sheet || product_sheet}
            />
            <Footer
                onNavBack={navToContractDetails}
                showNext={false}
            />
        </div>
    );
};

export default withTheme(ContractProductDetails);
