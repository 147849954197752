import React from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { selectStyles } from '../../../../store/issuerApp/issuerApp.selector';
import calculateColor from '../../../../helpers/calculateColor';

const OfferManagementSelect = ({ value, options,
    isMulti, isCustomized, menuPlacement, isSearchable = false,
    onChange }) => {
    const { primaryColor } = useSelector(selectStyles);
    const styles = {
        indicatorSeparator: () => ({
            width: 0,
        }),
        valueContainer: () => ({
            paddingLeft: 0,
            display: 'flex',
            flexWrap: `${isCustomized ? 'no' : ''}wrap`,
            alignItems: 'center',
            width: 'calc(100% - 41px)'
        }),
        control: () => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: 10,
            backgroundColor: '#ffffff',
            width: '100%',
        }),
        dropdownIndicator: provided => ({
            ...provided,
            color: primaryColor,
            paddingRight: 0
        }),
        multiValue: provided => ({
            ...provided,
            backgroundColor: calculateColor(primaryColor, 0.15),
            borderRadius: 10,
            maxWidth: 110
        }),
        multiValueLabel: provided => ({
            ...provided,
            padding: '5px 10px !important',
            fontSize: 14,
            whiteSpace: 'initial',
            wordWrap: 'break-word'
        }),
        option: provided => ({
            ...provided,
            padding: 15,
        }),
        singleValue: provided => ({
            ...provided,
            whiteSpace: 'normal'
        }),
    };

    const cStyles = {
        control: provided => ({
            ...provided,
            backgroundColor: `${calculateColor(primaryColor, 0.15)} !important`,
            boxSizing: 'border-box',
            paddingLeft: 5,
            border: 'none'
        }),
        dropdownIndicator: provided => ({
            ...provided,
            color: primaryColor,
        })
    };

    const fStyles = (isCustomized && { ...styles, ...cStyles }) || styles;

    return (
        <Select
            styles={fStyles}
            menuPlacement={menuPlacement}
            maxMenuHeight={150}
            value={value}
            options={options}
            isMulti={isMulti}
            isClearable={false}
            isSearchable={isSearchable}
            onChange={e => onChange(e)} />);
};

export default OfferManagementSelect;
