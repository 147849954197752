import React, { Profiler, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { pageOnRender, previousPageNavigation, nextPageNavigation, changeAttribute } from '../../../helpers/amplitude';

import { selectInsuranceProductUuid, selectCustomerAttributes, selectCustomerData, selectItemTypeAttributes } from '../../../store/selectors';
import { selectCurrentStepNumber, selectBreadcrumbs, selectProfile, selectTags } from '../../../store/common/common.selector';
import { setCustomerData, savePersonalDetails, setActualFooterScreen } from '../../../store/payment/payment.actions';
import { setStep, setItemTypeAttributeValue } from '../../../store/productInsurance/productInsurance.actions';
import { setCurrentStepNumber } from '../../../store/common/common.actions';

import { messages } from '../../../constants/messages';
import settings, { config } from './settings';

import Identification from './Identification';
import Autocomplete from './Address/Autocomplete';
import Input from './Input';
import InputDatepicker from '../../Common/InputDatepicker/InputDatepicker';
import Select from './Select';
import Header from '../../Header';
import Footer from '../../Common/Footer/Footer';

import './styles.scss';

const CustomerAttributes = ({ serverError }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { lead_uuid } = useSelector(selectProfile);

    const [validStatus, setValidStatus] = useState(null);
    const { identification, attributes, address, country } = settings(messages);

    const productId = useSelector(selectInsuranceProductUuid);
    const customerAttributes = useSelector(selectCustomerAttributes);
    const tags = useSelector(selectTags);
    const currentStepNumber = useSelector(selectCurrentStepNumber);
    const breadcrumbs = useSelector(selectBreadcrumbs);
    const customerData = useSelector(selectCustomerData);

    const itemAttributes = useSelector(selectItemTypeAttributes);
    const birthday = itemAttributes.filter(({ key }) => key.includes('insured_dob_1'))[0];

    useEffect(() => {
        if (birthday && tags && tags.indexOf('helvetia_spain_life') > -1) {
            dispatch(setCustomerData({ key: 'birthday', value: birthday.value }));
        }
    }, [birthday]);

    let filteredAttributes = customerAttributes.filter((attr) => {
        if (attr.attribute !== 'iban_number') {
            if (!breadcrumbs[currentStepNumber].position) {
                return attr.position === 'PRE_OFFER';
            }

            return attr.position === 'POST_OFFER' && attr.insurance_products.indexOf(productId) > -1;
        }
        return null;
    });

    /* if (tags.indexOf('sanitas_student_insurance') > -1) { */
    if (filteredAttributes.findIndex(({ attribute }) => attribute === 'options_selector') > -1) {
        const keys = Object.keys(identification);
        filteredAttributes = filteredAttributes.filter(attr => keys.indexOf(attr.attribute) === -1);
    }

    useEffect(() => {
        const attr = filteredAttributes.filter(({ attribute }) => attribute === 'address')[0];
        if (attr) {
            const data = customerData.address.split(';');
            const f = !attr.activation_tag ? [...address, country] : address;
            f.map(({ attribute }, i) => {
                dispatch(setCustomerData({ value: data[i] || '', key: attribute }));
                /*  customerData[[attribute]] = data[i] || '';  */
                return i;
            });
        }
    }, [customerData.address]);

    useEffect(() => {
        if (filteredAttributes.findIndex(({ attribute }) => attribute === 'birthday') > -1 && customerData.birthday) {
            dispatch(setCustomerData({ value: customerData.birthday.split('-').reverse().join('.'), key: 'birthday' }));
        }
    }, [customerData.birthday]);

    const errorCallback = (isValid) => {
        setValidStatus(prev => ({ ...prev, ...isValid }));
    };

    const handleInputChange = (value, key) => {
        const { position } = breadcrumbs[currentStepNumber];
        changeAttribute({ uuid: lead_uuid, attribute: key, position });
        dispatch(setCustomerData({ key, value }));
    };

    const onNavBack = () => {
        const { step } = breadcrumbs[currentStepNumber - 1];
        previousPageNavigation({ page: step, uuid: lead_uuid });
        dispatch(setCurrentStepNumber(currentStepNumber - 1));
        dispatch(setStep(step));
    };

    const onNextStep = async () => {
        const { step, payment } = breadcrumbs[currentStepNumber + 1];
        const isValid = !Object.values(validStatus).some(st => !st);
        if (isValid) {
            if (itemAttributes.filter(({ key }) => key.includes('insured')).length) {
                const insured = ['insured_dob', 'insured_first_name', 'insured_last_name', 'insured_tax_id'];
                const customer = ['birthday', 'first_name', 'last_name', 'tax_number'];
                insured.map((k, i) => {
                    if (filteredAttributes
                        .findIndex(({ attribute }) => customer[i] === attribute) > -1) {
                        dispatch(setItemTypeAttributeValue({ key: `${k}_1`, value: customerData[customer[i]] ? customerData[customer[i]] : '' }));
                    }
                    return i;
                });
            }
            if (customerData.street) {
                const attr = filteredAttributes.filter(({ attribute }) => attribute === 'address')[0];
                const f = !attr.activation_tag ? [...address, country] : address;
                customerData.address = f.map(({ attribute }) => customerData[[attribute]]).join(';');
                customerData.address_json = f
                    .reduce((prev, curr) =>
                        ({ ...prev, [curr.attribute]: customerData[curr.attribute] }),
                    {});
            }
            if (customerData.options_selector) {
                Object.keys(identification)
                    .filter(l => l !== customerData.options_selector)
                    .map(f => delete customerData[[f]]);
            }

            await dispatch(savePersonalDetails());
            nextPageNavigation({ page: step, uuid: lead_uuid });
            if (payment) {
                dispatch(setActualFooterScreen(step));
                history.push('/payment');
                // logEvent(EVENTS.ACTIVE_STEP, { page: 'Payment part' });
            }
            dispatch(setStep(step));
            dispatch(setCurrentStepNumber(currentStepNumber + 1));
        }
    };

    return (
        <Profiler id="UserPaymentDetails" onRender={pageOnRender}>
            <Header
                stepsTitle={messages.ENTRY_A_FEW_MORE_DETAILS}
                className="user-details-header"
            />
            <div className="details-about-you-wrap main">
                {serverError && <div className="be-error">{serverError}</div>}
                {filteredAttributes.map(({ attribute, activation_tag }, i) => {
                    if (attribute === 'birthday') {
                        return (tags && tags.indexOf('helvetia_spain_life') > -1 ?
                            <Input
                                attribute={attribute}
                                disabled
                                data={birthday && birthday.value}
                                label={attributes[attribute] || ''} /> :
                            <InputDatepicker
                                key={i}
                                label={attributes[attribute] || ''}
                                dateValue={customerData[attribute]}
                                icon={false}
                                validStatus={(s) => { errorCallback({ [attribute]: s }); }}
                                onInputChange={(value) => {
                                    handleInputChange(value, attribute);
                                }} />

                        );
                    }
                    if (attribute === 'gender') {
                        return (
                            <Select
                                label={messages.SEX}
                                attribute={attribute}
                                config={config[attribute]}
                                errorCallback={errorCallback}
                                value={customerData[attribute]}
                                handleInputChange={handleInputChange}
                                options={messages.GENDER} />
                        );
                    }
                    if (attribute === 'options_selector') {
                        return <Identification
                            tag={activation_tag}
                            handleInputChange={handleInputChange}
                            labels={identification}
                            msgs={messages}
                            data={customerData}
                            type={customerData[attribute]}
                            errorCallback={errorCallback} />;
                    }
                    if (attribute === 'address') {
                        return <Autocomplete
                            label={attributes[attribute]}
                            key={i}
                            activationTag={activation_tag}
                            placeholder={messages.SEARCH}
                            errorCallback={errorCallback}
                            data={customerData}
                            handleInputChange={handleInputChange}
                            fields={!activation_tag ? [...address, country] : address} />;
                    }
                    return <Input
                        config={config[attribute]}
                        attribute={attribute}
                        key={i}
                        errorCallback={errorCallback}
                        handleInputChange={handleInputChange}
                        data={customerData[attribute] || ''}
                        label={attributes[attribute] || ''} />;
                })}
            </div>
            <Footer
                showBack={!!currentStepNumber}
                onNavBack={onNavBack}
                onNextStep={onNextStep}
                disabled={!validStatus || Object.values(validStatus).some(st => !st)}
            />
        </Profiler>
    );
};

export default CustomerAttributes;
