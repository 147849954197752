import React, { Profiler, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Webcam from 'react-webcam';
import { pageOnRender } from '../../../helpers/amplitude';
import Loader from '../../Common/Loader/Loader';
import Header from '../../Header';
import { messages } from '../../../constants/messages';

import { setStep, uploadPhoto } from '../../../store/productInsurance/productInsurance.actions';
import { /* selectTargetPhoto, */ selectCurrentStepNumber, selectBreadcrumbs } from '../../../store/common/common.selector';
import { setCurrentStepNumber } from '../../../store/common/common.actions';

import './TakePhoto.scss';
import { useLanguage } from '../../../helpers/customHooks';

const cameraFormat = () => {
    if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/)) {
        return 'environment';
    } else {
        return 'user';
    }
};

const TakePhoto = () => {
    useLanguage();
    const dispatch = useDispatch();
    const webcamRef = React.useRef(null);
    const [loading, setLoading] = useState(true);

    const currentStepNumber = useSelector(selectCurrentStepNumber);
    const breadcrumbs = useSelector(selectBreadcrumbs);
    const targetPhoto = breadcrumbs[currentStepNumber].target;

    const capture = React.useCallback(
        () => {
            const photo = webcamRef.current.getScreenshot();
            dispatch(uploadPhoto({
                photo, type: 'jpg'
            }, targetPhoto));

            const { step } = breadcrumbs[currentStepNumber + 1];
            dispatch(setCurrentStepNumber(currentStepNumber + 1));
            dispatch(setStep(step));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [webcamRef]
    );

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000);
    }, []);

    const videoConstraints = {
        facingMode: cameraFormat()
    };

    return (
        <Profiler id="TakeReceiptPhoto" onRender={pageOnRender}>
            <>
                <Header
                    stepper
                    stepsTitle={targetPhoto === 'receipt_photo' ? messages.TAKE_RECEIPT_PHOTO : messages.TAKE_ITEM_PHOTO}
                    className="take-photo-header"
                />
                <div className="take-photo-wrap">
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpg"
                        videoConstraints={videoConstraints}
                        forceScreenshotSourceSize="true"
                    />
                    <div className="screenshot-wrap">
                        <button className="take-photo-wrap-screenshot" onClick={capture} />
                    </div>
                </div>
                {loading && <Loader />}
            </>
        </Profiler>
    );
};

export default TakePhoto;
