import React from 'react';
import {
    Drawer, List,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../Common/Logo/Logo';
import ListSection from './ListSection/ListSection';
import ListSectionItem from './ListSectionItem/ListSectionItem';
import UserName from './UserName/UserName';
import { messages } from '../../../constants/messages';

import './SideBar.scss';

const drawerWidth = 300;

const useStyles = makeStyles({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    list: {},
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#F8F9FF',
    },
});

const sideBarData = [
    {
        title: messages.USAGE_ANALYSIS,
        items: [
            {
                name: messages.DASHBOARD,
                to: '/app?tab=dashboard',
            },
            {
                name: messages.ANALYTICS,
                to: '/app?tab=analytics',
            },
        ]
    },
    {
        title: messages.SETTINGS,
        items: [
            {
                name: messages.GENERAL_SETTINGS,
                to: '/app?tab=general-settings',
            },
            {
                name: messages.PAYMENT_PRODUCTS,
                to: '/app?tab=payment-products',
            },
            {
                name: messages.WHITE_LABELING,
                to: '/app?tab=white-labeling',
            },
        ]
    },
    {
        title: messages.ACCOUNT_ADMIN,
        items: [
            {
                name: messages.USERS_MANAGEMENT,
                to: '/app?tab=users-management',
            },
            {
                name: messages.INTEGRATION_SETTING,
                to: '/app?tab=integration-setting',
            },
        ]
    },

];

const SideBar = () => {
    const classes = useStyles();

    return (
        <div className="sidebar">
            <nav className={classes.drawer}>
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    <List className={classes.list}>
                        <header className="sidebar__header">
                            <Logo />
                        </header>
                        <div className="sidebar__username-section">
                            <UserName />
                        </div>
                        {sideBarData.map(({ title, items }, i) => (
                            <ListSection key={i} title={title}>
                                {
                                    items.map(({ name, to }, j) => (<ListSectionItem
                                        key={j}
                                        name={name}
                                        to={to}
                                    />))
                                }
                            </ListSection>
                        ))}
                    </List>
                </Drawer>
            </nav>
        </div>
    );
};
export default SideBar;
