import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { messages } from '../../../../../constants/messages';
import Loader from '../../Common/Loader/Loader';
import Success from '../../Common/Success/Success';
import Warning from '../../Common/Warning/Warning';
import Error from '../../Common/Error/Error';

const UploadForm = ({ error, name, onDelete, onSend = () => {}, resetError }) => {
    const [active, setActive] = useState(false);
    const [success, setSuccess] = useState(false);
    const [warning, setWarning] = useState(false);
    const handleDelete = () => {
        onDelete();
        resetError();
        setWarning(false);
    };

    const handleClick = () => {
        setActive(true);
        onSend((warn) => {
            if (warn) {
                setWarning(warn);
                return;
            }
            setSuccess(true);
        });
    };

    return <div>
        <div className="list-upload_row">
            <p>{name}</p>
            <FontAwesomeIcon className="list-upload_delete" icon={faTrash} onClick={handleDelete} /></div>
        {(error && <Error text={error} />) || null}
        {(success && <Success text={messages.CSV_UPLOADED} small />) || null}
        {warning ? <Warning title={messages.WARNING} text={warning} /> : <button className={`details-wrapper_customer-details_form_send ${!error && active && 'active'}`} onClick={handleClick}>
            {!error && active && <Loader />}
            <span>{messages.UPLOAD}</span>
            <FontAwesomeIcon icon={faUpload} />
        </button>}
    </div>;
};

export default UploadForm;
