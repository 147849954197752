import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import './Input.scss';

const useStyles = makeStyles(theme => ({
    input: {
        width: '100%',
        '& .MuiFormLabel-root': {
            fontSize: 20,
        },
        '& .MuiSvgIcon-root': {
            fill: 'gray',
        },
        '& .MuiInputBase-root': {
            fontSize: 20,
        },
        '& .MuiIconButton-edgeEnd': {
            marginRight: '-23px',
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.6)',
        },
    },
    error: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.error.main}`,
        }
    }
}));

const Input = (
    {
        err,
        name,
        type = 'text',
        value,
        handleChange,
        variant = 'outlined',
        label,
        required,
        className,
        isPassword
    }) => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => { setShowPassword(prev => !prev); };

    return (
        <div className="input">
            <TextField
                required={required}
                name={name}
                type={isPassword && showPassword ? 'text' : type}
                value={value}
                onChange={handleChange}
                className={`${classes.input} ${err && classes.error} ${className}`}
                variant={variant}
                label={label}
                tabIndex="0"
                InputProps={isPassword && { // <-- This is where the toggle button is added.
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShowPassword}
                                edge="end"
                                /* onMouseDown={handleShowPassword} */
                            />
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </InputAdornment>
                    )
                }}
            />
            {!!err && <div className="input-err">{err}</div>}
        </div>
    );
};
export default Input;
