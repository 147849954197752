export const data = {
    offer_ins_at_pos: {
        value: false, label: 'No'
    },
    tags: [{
        value: 'Premium', label: 'Premium'
    }],
    /* target_demographics: [
        { value: 'Young adult (f)', label: 'Young adult (f)' },
        { value: 'Adult (m)', label: 'Adult (m)' },
        { value: 'Hippster', label: 'Hippster' }
    ],
    merchant_group: [
        { value: 'Coop', label: 'Coop' },
        { value: 'Carrefour', label: 'Carrefour' },
        { value: 'Auchan', label: 'Auchan' },
    ], */
    /*  merchant_country: [{ value: 'Germany', label: 'Germany' },
        { value: 'France', label: 'France' },
        { value: 'UK', label: 'UK' }
    ],
    same_country: {
        value: true, label: 'Yes'
    },
    merchant_category: [
        { value: 'Furniture', label: 'Furniture' },
        { value: 'Health', label: 'Health' },
    ] *//* ,
    distance_min: 0,
    distance_max: 100 */
};

export default data;
