import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartPulse, faTooth, faPlane, faHandHoldingHand, faSection, faCheck } from '@fortawesome/free-solid-svg-icons';
import { stringToJsx } from '../../../helpers/wizardHelpers';
import Hint from '../ItemAttributes/Hint';

const sections = {
    medical: faHeartPulse,
    dental: faTooth,
    legal: faSection,
    travel: faPlane,
    funeral: faHandHoldingHand
};

const ExplanationItem = ({ data, section, onClick }) => <div className="explanation-item">
    <FontAwesomeIcon icon={sections[section]} className="explanation-item_icon" />
    <div className="explanation-item_description">
        <p className="explanation-item_description_title">{data.title}</p>
        <div className="explanation-item_description_list">{
            data.points.map((item, k) => <div key={k} className="explanation-item_description_list_item">
                <FontAwesomeIcon icon={faCheck} className="explanation-item_icon check" />
                {stringToJsx(item)}
                {data.hints && data.hints[k] && <Hint type="eye" onClick={() => onClick({ label: '', hint: data.hints[k] }, true)} />}
            </div>)
        }
        </div>
    </div>
</div>;

export default ExplanationItem;
