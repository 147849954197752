import React from 'react';

const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const SummaryCardDetail = ({ title, data, color }) => <div>
    {
        data.conversion && <>
            <span className="dashboard__card__title" style={{ textAlign: 'left' }}>Conversion</span>
            <span>Total:&nbsp;{data.conversion.total}%</span>
            <span>Previous:&nbsp;{data.conversion.previous}%</span>
            </>
    }
    <div
        className="dashboard__card__leads__number"
        style={{
            height: `calc(170px * ${(data.conversion && data.conversion.total / 100) || 1})`,
            backgroundColor: color,
        }}>
        <span>{numberWithCommas(data.amount)}</span>
    </div>
    <span className="dashboard__card__title" style={{ height: 35 }}>{title}</span>
</div>;

export default SummaryCardDetail;
