import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { messages } from '../../constants/messages';
import backn from '../../dist/images/BackN.svg';
import { useContract, useLanguage } from '../../helpers/customHooks';
import { validateMandatory } from '../../helpers/validationHelpers';
import { selectContractsLoading } from '../../store/selectors';
import Button from '../Common/Button/Button';
import Footer from '../Common/Footer/Footer';
import Header from '../Header';
import Attachments from './Attachments/Attachments';
import withTheme from '../../hocs/withTheme';

import './ContractClaim.scss';
import { attachFile, submitClaim } from '../../store/contract/contract.actions';
import Loader from '../Common/Loader/Loader';

const validateAttachments = attachments => (!attachments.length ? messages.PLEASE_ATTACH_FILES : '');

const ContractClaim = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const onNavBack = () => {
        history.goBack();
    };
    useLanguage();
    const [description, setDescription] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [error, setError] = useState({});
    const { id } = useParams();
    const contract = useContract(id);
    const loading = useSelector(selectContractsLoading);

    const addAttachment = (attachment) => {
        dispatch(attachFile(attachment))
            .then((response) => {
                setAttachments(arr => ([...arr, response]));
                setError(err => ({ ...err, attachments: '' }));
            });
    };

    const removeAttachment = (index) => {
        setAttachments((arr) => {
            arr.splice(index, 1);
            return [...arr];
        });
    };
    if (!contract) {
        return null;
    }

    const onChangeDescription = (event) => {
        setError(err => ({ ...err, description: '' }));
        setDescription(event.target.value);
    };

    const validate = () => {
        const descriptionError = validateMandatory(description);
        const attachmentsError = validateAttachments(attachments);
        setError({
            description: descriptionError,
            attachments: attachmentsError
        });
        return !descriptionError && !attachmentsError;
    };

    const submit = () => {
        if (validate()) {
            const payload = {
                contract: contract.uuid,
                description,
                attachments: attachments.map(file => file.uuid)
            };
            dispatch(submitClaim(payload))
                .then(() => history.push(`/purchases/${contract.uuid}/success`));
        }
    };

    return (
        <div className="contract-claim-form-wrapper content">
            <Header
                showLanguage={false}
                stepsTitle={messages.PROVIDE_INFO_FOR_CLAIM} />
            <div className="contract-claim-form">
                <Attachments
                    addAttachment={addAttachment}
                    removeAttachment={removeAttachment}
                    attachments={attachments}
                />
                {!!error.attachments && <p className="error-message">{error.attachments}</p>}
                <div className="claim-description">
                    <p className="claim-description-title">{messages.DESCRIBE_WHAT_HAPPEND}</p>
                    <textarea
                        value={description}
                        onChange={onChangeDescription}
                        placeholder={messages.ENTER_HERE}
                        className={classNames('claim-description-textarea', {
                            error: !!error.description
                        })}
                    />
                    {!!error.description && <p className="error-message">{error.description}</p>}
                </div>
            </div>
            <Footer
                onNavBack={onNavBack}
                showNext={false}
            >
                <Button
                    className="submit-claim-button"
                    onClick={submit}>
                    {messages.SUBMIT_CLAIM}
                    <img className="submit-claim-button-icon" alt="submit" src={backn} />
                </Button>
            </Footer>
            { loading && <Loader /> }
        </div>
    );
};

export default withTheme(ContractClaim);
