import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../Header';
import Footer from '../../Common/Footer/Footer';
import ExplanationItem from './ExplanationItem';
import HintModal from '../HintModal/HintModal';
import { messages } from '../../../constants/messages';

import { selectCurrentStepNumber, selectBreadcrumbs, selectProfile } from '../../../store/common/common.selector';
import { setCurrentStepNumber } from '../../../store/common/common.actions';
import { setStep } from '../../../store/productInsurance/productInsurance.actions';

const HelvetiaExplanation = () => {
    const dispatch = useDispatch();
    const currentStepNumber = useSelector(selectCurrentStepNumber);
    const breadcrumbs = useSelector(selectBreadcrumbs);
    const [hintModalState, setHintModalState] = useState({});
    const { tags, support_phone_number } = useSelector(selectProfile);

    const onNextStep = () => {
        const { step } = breadcrumbs[currentStepNumber + 1];
        dispatch(setCurrentStepNumber(currentStepNumber + 1));
        dispatch(setStep(step));
    };

    const onShowHint = ({ label, hint }, isExplanation) => {
        if (!isExplanation) {
            window.open(`https://wa.me/${support_phone_number}?text=`);
            return;
        }
        setHintModalState({
            shown: true,
            label,
            hint,
            type: isExplanation ? '' : 'help'
        });
    };

    const onCloseHint = () => setHintModalState({ shown: false });

    return <div className="explanation-screen">
        <Header stepper={false} />
        <div className="assist-block">
            <div className="assist-block_text">
                <p className="assist-block_text_assist">{messages.ASSIST_YOU}</p>
                <p>{messages.CAN_FOCUS}</p>
            </div>
            <div className="assist-block_price">
                <div>
                    <p>{messages.FROM}</p>
                    <p className="assist-block_price_value">€ 3</p>
                    <p>{messages.MONTHLY}</p>
                </div>
            </div>
        </div>
        {
            Object.entries(messages.HELVETIA_EXPLANATION)
                .map(([key, value]) => <ExplanationItem
                    data={value}
                    key={key}
                    section={key}
                    onClick={onShowHint} />)
        }
        <HintModal {...hintModalState} onClose={onCloseHint} type={hintModalState.type} />
        <Footer
            openModal={onShowHint}
            isHelp={tags && tags.indexOf('helvetia_support') > -1}
            showNextArrow={false}
            showBack={false}
            onNextStep={onNextStep}
            nextText={messages.GET_ASSISTANCE} />
    </div>;
};

export default HelvetiaExplanation;
