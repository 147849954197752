import calculateColorWithOpacity, { getInputBackground } from './calculateColor';

const POSITION_BEFORE = 'BEFORE';

// eslint-disable-next-line import/prefer-default-export
export const renderPrice = (price = 0, currency, currencyPosition) => {
    if (currencyPosition === POSITION_BEFORE) {
        return `${currency} ${price}`;
    }
    return `${price} ${currency}`;
};

export const setupCustomStyles = ({
    whiteLabelingPrimaryColor, whiteLabelingTitleColor, whiteLabelingBackgroundColor,
    whiteLabelingPrimaryOpacityColor, whiteLabelingPrimaryLightColor,
    whiteLabelingHeaderBackground, whiteLabelingInputBackground
}) => {
    const root = document.documentElement;
    root.style.setProperty('--colour_titles', whiteLabelingTitleColor);
    root.style.setProperty('--primary', whiteLabelingPrimaryColor);
    root.style.setProperty('--bgcolor', whiteLabelingBackgroundColor);
    root.style.setProperty('--primary-opacity', whiteLabelingPrimaryOpacityColor);
    root.style.setProperty('--primary-light', whiteLabelingPrimaryLightColor);
    root.style.setProperty('--primary-light-opacity', calculateColorWithOpacity(whiteLabelingPrimaryLightColor, 0.5));
    root.style.setProperty('--header-bgcolor', whiteLabelingHeaderBackground);
    root.style.setProperty('--input-bgcolor', getInputBackground(whiteLabelingInputBackground));
};

export const setupStylesForElement = (element, {
    primaryColor, titleColor, backgroundColor,
    primaryOpacityColor, primaryLightColor,
    headerBackgroundColor, inputFieldBackgroundColor
}) => {
    element.style.setProperty('--colour_titles', titleColor);
    element.style.setProperty('--primary', primaryColor);
    element.style.setProperty('--bgcolor', backgroundColor);
    element.style.setProperty('--primary-opacity', primaryOpacityColor);
    element.style.setProperty('--primary-light', primaryLightColor);
    element.style.setProperty('--primary-light-opacity', calculateColorWithOpacity(primaryLightColor, 0.5));
    element.style.setProperty('--header-bgcolor', headerBackgroundColor);
    element.style.setProperty('--input-bgcolor', getInputBackground(inputFieldBackgroundColor));
};

export const detectMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem));
};

export const getCardHolderName = ({ first_name, last_name }) => {
    if (!first_name && !last_name) {
        return '';
    }
    if (first_name && last_name) {
        return `${first_name} ${last_name}`;
    }
    return first_name || last_name;
};
