import React, { useEffect, useState } from 'react';
import '../styles/LoginScreen.scss';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';
import Logo from '../components/Common/Logo/Logo';
import { selectStyles } from '../../store/issuerApp/issuerApp.selector';
import setupCustomStyles from '../helpers/renderingHelpers';
import createTheme from '../helpers/theme';
import { messages } from '../../constants/messages';

const LoginScreen = ({ children, policy = false }) => {
    const [theme, setTheme] = useState(null);
    const styles = useSelector(selectStyles);

    useEffect(() => {
        setupCustomStyles(styles);
        setTheme(createTheme(styles));
    }, [styles]);

    return (
        theme && (
            <ThemeProvider theme={theme}>
                <div className="login-screen-wrapper">
                    <div className="login-container">
                        <header className="login__header">
                            <Logo src="https://stalyyndemo.blob.core.windows.net/public-media/branding/partner-portal-logo/ALYYN_LOGO__icon_full_color_white_bgr-quad.png" />
                            {policy && <><p>{messages.BE_AWARE_PASSWORD_POLICY}</p>
                                <ul>{messages.AT_LEAST.map((m, i) => <li key={i}>{m}</li>)}</ul></>}
                        </header>
                        <header className="login__content">{children}</header>
                    </div>
                </div>
            </ThemeProvider>
        )
    );
};

export default LoginScreen;
