import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MOCK_DATA, OFFER_STATES, STEPS } from '../constants/constants';
import { setActualLanguage, setInsuranceLogoVisible, setProfile, setWhiteLabelingBackgroundColor, setWhiteLabelingHeaderBackground, setWhiteLabelingInputBackground, setWhiteLabelingLogo, setWhiteLabelingPrimaryColor, setWhiteLabelingPrimaryLightColor, setWhiteLabelingPrimaryOpacityColor, setWhiteLabelingTitleColor } from '../store/common/common.actions';
import { setCurrentCurrency, setInsuranceProducts, setStep } from '../store/productInsurance/productInsurance.actions';
import { selectActualLanguage, selectContracts, selectCurrentGoodsId, selectInsuranceTypes, selectOfferState, selectWhiteLabelingStyles, selectPullInsurance } from '../store/selectors';
import calculateColorWithOpacity from './calculateColor';

export const useLanguage = () => {
    const actualLanguage = useSelector(selectActualLanguage);
    useEffect(() => {}, [actualLanguage]);
    return actualLanguage;
};

const getContractById = (contracts, id) => contracts
    .find(contract => contract.uuid === id);

export const useContract = (id) => {
    const contracts = useSelector(selectContracts);

    const contract = getContractById(contracts, id);
    const history = useHistory();

    useEffect(() => {
        if (!contracts.length || !contract) {
            history.push('/purchases');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contracts]);

    return contract;
};

export const usePrimaryLightColor = (opacity = 1) => {
    const { whiteLabelingPrimaryLightColor } = useSelector(selectWhiteLabelingStyles);
    const color = calculateColorWithOpacity(whiteLabelingPrimaryLightColor, opacity);
    return color;
};

export const useBackgroundColor = (opacity = 1) => {
    const { whiteLabelingBackgroundColor } = useSelector(selectWhiteLabelingStyles);
    const color = calculateColorWithOpacity(whiteLabelingBackgroundColor, opacity);
    return color;
};

export const usePrimaryColor = (opacity = 1) => {
    const { whiteLabelingPrimaryColor } = useSelector(selectWhiteLabelingStyles);
    const color = calculateColorWithOpacity(whiteLabelingPrimaryColor, opacity);
    return color;
};

export const useTextColor = (opacity = 1) => {
    const { whiteLabelingTitleColor } = useSelector(selectWhiteLabelingStyles);
    const color = calculateColorWithOpacity(whiteLabelingTitleColor, opacity);
    return color;
};

export const useSelectedItemType = (isPull) => {
    const insuranceTypes = useSelector(selectInsuranceTypes);
    const selectedItemType = useSelector(selectCurrentGoodsId);
    const offerState = useSelector(selectOfferState);
    const pullInsurance = useSelector(selectPullInsurance);
    if (!insuranceTypes || !insuranceTypes.length) {
        return {};
    }
    if (offerState === OFFER_STATES.QUALIFIED) {
        return insuranceTypes.find(type => type.id === selectedItemType) || {};
    }
    if (isPull) {
        return pullInsurance.item_types[0];
    }
    return insuranceTypes.reduce((acc, group) => {
        const itemType = group.item_types.find(type => type.id === selectedItemType);
        if (itemType) {
            return itemType;
        }
        return acc;
    }, {});
};

export const useOfferState = () => {
    const offerState = useSelector(selectOfferState);
    const isQualified = offerState === OFFER_STATES.QUALIFIED;
    return isQualified;
};

export const setMockData = (dispatch) => {
    const { insuranceProducts, cultures } = MOCK_DATA;
    dispatch(setProfile({
        cultures
    }));
    dispatch(setInsuranceProducts(insuranceProducts));
    dispatch(setCurrentCurrency(insuranceProducts[0].currency));
    dispatch(setActualLanguage(cultures[2].code));
    dispatch(setStep(STEPS.INSURANCE_PRODUCT_SELECTION));
};

export const updateInsurancesWhiteLabel = (dispatch, data) => {
    const {
        logo,
        backgroundColor,
        displayUnderwriterLogo,
        headerBackgroundColor,
        primaryOpacityColor,
        primaryLightColor,
        inputFieldBackgroundColor,
        primaryColor,
        titleColor
    } = data;
    const logoUrl = logo?.length ? logo[0].attachment : '';
    dispatch(setWhiteLabelingLogo(logoUrl));
    dispatch(setWhiteLabelingBackgroundColor(backgroundColor));
    dispatch(setWhiteLabelingHeaderBackground(headerBackgroundColor));
    dispatch(setWhiteLabelingInputBackground(inputFieldBackgroundColor));
    dispatch(setInsuranceLogoVisible(displayUnderwriterLogo));
    dispatch(setWhiteLabelingPrimaryColor(primaryColor));
    dispatch(setWhiteLabelingPrimaryLightColor(primaryLightColor));
    dispatch(setWhiteLabelingPrimaryOpacityColor(primaryOpacityColor));
    dispatch(setWhiteLabelingTitleColor(titleColor));
};
