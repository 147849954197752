import React from 'react';
import { useSelector } from 'react-redux';
import './Logo.scss';
import { styles } from '../../../../store/merchants/merchants.selector';

const Logo = () => {
    const { logo } = useSelector(styles);
    return (
        <div className="logo">
            <div className="logo__wrapper">
                {logo && <img className="logo__icon" src={logo} alt="" />}
            </div>
            <div className="logo__title">alyyn</div>
        </div>
    );
};

export default Logo;
