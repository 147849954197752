import React, { useState, useEffect } from 'react';
import { trigger_fields } from './constants';
import TriggerFragment from './OfferManagementTrigger/TriggerFragment';

const TriggerTable = ({ data, onDelete, onSave, itemTypeOptions, isAdding, onCancelAdding }) => {
    const [triggerData, setTriggerData] = useState({});
    const [editMode, setEditMode] = useState(false);

    const handleMouseEvent = (eType) => {
        setEditMode(eType === 'over');
    };

    const onCancel = () => {
    // if true that means that it is adding mode
        if (isAdding && onCancelAdding) {
            onCancelAdding();
            return;
        }
        setEditMode(false);
    };

    useEffect(() => {
        setTriggerData(data);
    }, [data]);

    const onTriggerSave = (nData) => {
        onSave(nData)
            .then(() => onCancel());
    };

    const onTriggerDelete = () => {
        setEditMode(false);
        onDelete();
    };

    return <div className="offer-management-card__section__trigger">
        <div className="offer-management-card__section__trigger__header">
            {
                Object.entries(trigger_fields)
                    .map(([key, value]) => <span key={key}>{value.title}</span>)
            }
        </div>
        <TriggerFragment
            data={triggerData}
            isActive={isAdding || editMode}
            onDelete={isAdding ? null : onTriggerDelete}
            onCancel={onCancel}
            onSave={(nData) => { onTriggerSave(nData); }}
            handleMouseOver={
                () => handleMouseEvent('over')}
            itemTypeOptions={itemTypeOptions} />
    </div>;
};

export default TriggerTable;
