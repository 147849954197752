import React from 'react';
import OfferReadMode from './OfferReadMode';
import OfferEditMode from './OfferEditMode';

const OfferFragment = ({
    data, handleFormDataChange,
    isActive, onDelete, isBlurred, showDelete = true
}) => (
    <>
        {
            !isActive ?
                <OfferReadMode data={data} /> :
                <OfferEditMode
                    data={data}
                    showDelete={showDelete}
                    handleFormDataChange={handleFormDataChange}
                    isBlurred={isBlurred}
                    onDelete={onDelete} />
        }
    </>
);

export default OfferFragment;
