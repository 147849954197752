const sv = {
CHOOSE_ITEM_TO_CHECK: "Please choose the item for which you want to check coverage",
INSURANCE_COMPANY_LABEL: 'Insurance Company',
INSURANCE_PRODUCT_LABEL: 'Insurance Product',
    //steps
    STEP1: 'Data',
    STEP2: 'Erbjudande',
    STEP3: 'Stängning',
    CHOOSE_ITEM_TO_INSURE: 'Vänligen välj den vara du vill försäkra.',
    // Insurance overview
    INSURANCE_OVERVIEW: 'Försäkringsöversikt',
    COVERAGE: 'Täckt:',
    NON_COVERED: 'Inte täckt:',
    DOWNLOAD_INSURANCE_PRODUCT: 'Ladda ner den detaljerade försäkringsbeskrivningen',
    DOWNLOAD_TERMS_AND_CONDITIONS: 'Ladda ner villkor',
    PRODUCT_INSURANCE_STEP_ONE_TITLE: 'Bara några steg för att täckas med försäkring',
    PRODUCT_INSURANCE_STEP_TWO_TITLE: 'Upptäckta produkter från kvittot',
    PRODUCT_INSURANCE_STEP_THREE_TITLE: 'Välj din försäkring.',
    PRODUCT_INSURANCE_STEP_TWO_HINT: 'Tryck på vad du just har köpt och måste täckas.',
    PRODUCT_INSURANCE_STEP_ONE_ONLINE: 'Berätta för oss, vad vill du skydda?',
    ENTER_MORE_DETAILS: 'Ange mer information om det föremål du vill försäkra',
    TAKE_RECEIPT_PHOTO: 'Ta ett foto av inköpskvittot.',
    CHECK_RECEIPT: 'Kontrollera om kvittot är tydligt på bilden.',
    CHECK_ITEM_PHOTO: 'Kontrollera om föremålet är tydligt på bilden.',
    TAKE_ITEM_PHOTO: 'Ta ett foto av ditt föremål för att täckas.',
    BUTTON: 'Knapp',
    MORE_DETAILS: 'Visa detaljer',
    BACK: 'Tillbaka',
    SKIP_RECEIPT: 'Hoppa över kvittot',
    NEXT: 'Nästa',
    GENERAL_CONDITIONS: 'Allmänna villkor',
    LENGTH: 'Varaktighet:',
    MINIMUM_LENGTH: 'Minsta kontraktstid:',
    MAXIMUM_LENGTH: 'Maximal varaktighet:',
    CONTRACT_TERMINATION: 'Uppsägning av avtalet:',
    COVERAGE_LIST: 'Rapportering',
    SEARCH: 'Sök',
    OTHER: 'Övrig',
    CLOSE: 'Stäng',
    PHOTO: 'Foto',
    OR: 'Eller',
    OPTIONS: 'Alternativ',
    SHOW_MORE: 'Visa mer',
    DO_NOT_INSURE: 'Försäkra inte detta föremål',
    NOT_INSURABLE: 'Denna artikel är inte försäkrad',
    SELECT_ITEM_TYPE: 'Välj typ av artikel',
    ADD_UNRECOGNIZED_ITEM: 'Lägg till en artikel',
    NAME: 'Namn',
    PLEASE_WAIT: 'Vänligen vänta',
    RECOGNIZE_PROCESSING: 'Programmet bearbetar fotot/bilderna för att få fram relevant information.',
    PRICE: 'Pris',
    CANCEL: 'Avbryt',
    OK: 'Ok',
    SAVE_AND_NEXT: 'Nästa',
    MY_NEW_ITEM: 'Mitt nya älskade föremål',

    // Inform Screen
    DEAR_CUSTOMER: 'Kära kund,',
    TO_OFFER_YOU: 'För att kunna erbjuda dig de bästa försäkringsalternativen behöver vi mer information om den artikel du just köpt. Du kan antingen:',
    /* OPTION_TAKE_A_PHOTO: 'Ta ett foto av inköpskvittot',
    OPTION_UPLOAD_FROM_GGALLERY: 'Ladda upp ett foto av inköpskvittot', */
    OPTION_TAKE_A_PHOTO: 'Ta ett foto',
    OPTION_UPLOAD_FROM_GGALLERY: 'Ladda upp ett foto',
    OPTION_ENTER_MANUALLY: 'Ange data manuellt',
    OPTION_TAKE_A_PHOTO_DESCRIPTION: 'Åtkomst till din kamera kan begäras.',
    OPTION_UPLOAD_FROM_GGALLERY_DESCRIPTION: 'Åtkomst till foton, media och filer kan begäras.',

    // Empty State
    SORRY: 'Förlåt',
    SORRY_MESSAGE: 'Vi ber om ursäkt men erbjudandelänken är inte giltig längre. Vi ger dig gärna ett erbjudande med ditt nästa köp.',
    DONE: 'Gjort',

    // Payment Methods
    PAYMENT_METHODS: 'Betalningsmetoder',
    HOW_DO_YOU_WANT_TO_PAY: 'Hur vill du betala?',
    YOU_WILL_BE_SPENDING: 'Du kommer att spendera',
    FOR_BUYING_INSURANCE_POLICIES: 'för att köpa försäkringar',
    CONFIRM_YOUR_CARD_DETAILS: 'Ange detaljerna på ditt betalkort.',
    HDRC_MONEY_BACK: 'Ange detaljerna på ditt betalkort.',
    CARDHOLDER_NAME: 'Kortinnehavarens namn',
    CARD_NUMBER: 'Kortnummer',
    EXPIRY_DATE: 'Utgångsdatum',
    CVV: 'Säkerhetskod (CVV)',
    CONFIRM_AND_PAY: 'Bekräfta och köp',
    I_HAVE_SUCCESSFULLY_READ: 'Jag har läst och godkänner villkoren.',
    ENTRY_A_FEW_MORE_DETAILS: 'Ange några fler detaljer om dig',
    FIRST_NAME: 'Förnamn',
    FAMILY_NAME: 'Efternamn',
    BIRTHDAY: 'Födelsedag',
    EMAIL_ADDRESS: 'E-postadress',
    PHONE_NUMBERS: 'Telefonnummer',
    ADDRESS: 'Adress',
    ENTRY_HERE: 'Gå in här',
    MONTHLY: 'per månad',
    WEEKLY: 'per vecka',
    YEARLY: 'per år',
    OVERVIEW_YEARLY: 'Årlig',
    OVERVIEW_MONTHLY: 'Månatlig',
    OVERVIEW_WEEKLY: 'Varje vecka',
    WEEKS: 'Veckor',
    MONTHS: 'månader',
    YEARS: 'år',
    WHAT_IS_NEXT: 'Vad är nästa:',
    INSURANCE_CERTIFICATE: (email) =>  `Försäkringsintyget genereras och skickas till ${email}.`,

    DEDUCTIBLE: 'Avdragsgill:',


    MANDATORY_FIELD: 'Detta fält är obligatoriskt',
    INVALID_FORMAT: 'ogiltigt värde',
    PRICE_SHOULD_BE_LOWER: 'Priset ska vara lägre än transaktionspriset',
    DATE_SHOULD_BE_PAST: 'Datum bör vara i det förflutna',

    // Congratulations!
    CONGRATULATIONS: 'Grattis !!',
    GLAD_TO_INFORM_YOU: 'Vi är glada att kunna meddela att du framgångsrikt köpte täckningen. Din produkt är nu säker.',

    YOU_WILL_RECEIVE: 'Du kommer under de närmaste minuterna att få ett bekräftelsemeddelande med täckningsintyget.',

    YOU_ARE_PROTECTED: 'Du är skyddad',
    ALREADY_PURCHASED: 'Vi är glada att kunna meddela att du redan har köpt en försäkring och att din älskade artikel redan är skyddad.',

    DOWNLOAD: 'ladda ner',
    YOU_CAN_DOWNLOAD_HERE: 'Du kan ladda ner ditt försäkringsbevis här:',
    BACK_TO_OVERVIEW: 'Tillbaka till översikten',

    // contracts list
    YOUR_POLICY_PURCHASES: 'Dina försäkringar',
    PURCHASED_ON: 'Köpt den',
    EXPIRING_ON: 'Går ut',
    WHATS_COVERED: 'Täckt är',
    FILE_A_CLAIM: 'Ansökan',
    REACTIVATE: 'Återaktivera',

    // contract details
    DOWNLOAD_PURCHASE_RECEIPT: 'Ladda ner kvitto',
    INSURCANCE_CONTRACT: 'Försäkringsavtal',
    ITEM_IS_PROTECTED_BY_INSURANCE: 'Föremål skyddas av försäkringen',
    INSURANCE_COVERAGE_START: 'Försäkringsskyddet startar',
    INSURANCE_COVERAGE_UNTIL: 'Försäkringsskydd fram till',
    DOWNLOAD_PRODUCT_SHEET: 'Ladda ner produktbladet',
    DOWNLOAD_INSURANCE_CERTIFICATE: 'Ladda ner intyget',
    CANCEL_CONTRACT: 'Avbryt kontrakt',

    // claim form
    PROVIDE_INFO_FOR_CLAIM: 'Vänligen ge oss lite mer information innan vi behandlar ditt anspråk',
    ATTACH_PHOTO_VIDEO: 'Bifoga, om det är relevant, foton eller videor som visar skadan.',
    DESCRIBE_WHAT_HAPPEND: 'Beskriv vad som hände',
    ENTER_HERE: 'Gå in här',
    SUBMIT_CLAIM: 'Skicka ansökan',
    PLEASE_ATTACH_FILES: 'Vänligen bifoga filer',
    THANKS_FOR_REQUEST: 'Tack för att du har skickat in din ansökan. Vi behandlar den gärna och ger dig ett svar inom de närmaste dagarna.',
    YOUR_INSURANCE_COVERS: 'Din försäkring täcker endast reparation av en av våra certifierade partner. Du kan här hitta en reparationsverkstad i din närhet.',
    SHOW_PARTNER_NETWORK: 'Visa partnernätverket',

    // confirmation
    YOUR_CONTRACT_HAS_BEEN_CANCELLED: 'Ditt kontrakt har annullerats. Du kommer snart att få ett bekräftelsemeddelande',
    GOT_IT: 'Jag fattar',
    THE_INSURANCE_WILL_BE_CANCELLED: 'Försäkringen kommer att annulleras från och med',
    ARE_YOU_SURE_TO_CANCEL: 'Är du säker på att du vill säga upp skyddet för din',
    RETURN: 'Lämna tillbaka',
    CONFIRM_REACTIVATION: 'Bekräfta att du vill återaktivera försäkringen.',
    YOUR_CONTRACT_HAS_BEEN_REACTIVATED: 'Din försäkring har återaktiverats. Du kommer snart att få ett bekräftelsemail.',

    // contract statuses
    ACTIVE: 'aktiv',
    PENDING_CANCELLATION: 'avbokad',
    VOID: 'avslutat',

    BENEFICIARY_TITLE: 'Vem ska ingå i din försäkring?',
    BENEFICIARY_QUESTION: 'Whom would you like to cover?',
    BENEFICIARY_OPTION1: 'jag själv',
    BENEFICIARY_OPTION2: 'jag och min familj/hushåll',
};

export default sv;
