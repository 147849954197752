import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
    fetchOfferTemplate,
    fetchPaymentProductOfferTemplate,
    fetchAvailableContries,
    fetchLanguages,
    fetchTransactionTypes,
    fetchPoSEnvironments,
    fetchOfferedServices,
    fetchItemTypes,
    deleteOffering,
    updateOffering,
    addNewOffering,
    updateOfferingGroup,
    addOfferingGroup,
    deleteOfferingGroup
} from '../../../store/issuerApp/issuerApp.actions';
import './OfferManagement.scss';
import TriggerTable from './TriggerTable';
import SegmentsTable from './SegmentsTable';
import CustomizedSwitch from '../Common/Switch/Switch';
import Popup from '../Common/Popup/Popup';
import { messages } from '../../../constants/messages';
import { segment_fields, offer_fields, trigger_fields } from './constants';
import { data as mockData } from './data';
import AddSegmentButton from './OfferManagementCommon/AddSegmentButton';
import Button from '../Common/Button/Button';
import { triggerMapper, excludeItems, spliceInCopy, initialTrigger } from '../../helpers/offerManagementHelpers';

let indexOfTriggerToDelete = -1;

const OfferManagement = ({ productSettings = false, setChecked, checked }) => {
    const { pathname } = useLocation();
    const productId = (productSettings && pathname.split('/').pop()) || undefined;

    const [selectedCategory, setSelectedCategory] = useState('');
    const [offerings, setOfferings] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const [isAddGroup, setIsAddGroup] = useState(false);

    const buildStructure = (options, v) => ({
        label: options.find(({ value }) => value === v).label,
        value: v
    });

    const formatAllocations = (allocations) => {
    // offered_products
        Object.entries(offer_fields)
            .map(([key, value]) => {
            // mockData
                if (key === 'delay') {
                    allocations.delay = 0;
                }
                if (key === 'channel') {
                    allocations.channel = 'App';
                }
                // mockData
                if (key === 'reminder' || key === 'coverage_check_enabled' || key === 'channel' || key === 'item_type_specific') {
                    allocations[key] = buildStructure(value.options, allocations[key]);
                }

                if (key === 'offered_products' && allocations[key].length) {
                    allocations[key] = allocations[key].map(a => buildStructure(value.options, a));
                }
                return key;
            });
    };

    const formatDemographics = (demographics) => {
        Object.entries(segment_fields)
            .map(([key, segment]) => {
                if (segment.options) {
                    if (key === 'culture') {
                        demographics[key] = buildStructure(segment.options, demographics[key]);
                        return key;
                    }

                    demographics[key] =
                    Array.isArray(demographics[key]) &&
                        demographics[key].map(v => buildStructure(segment.options, v));
                }
                return segment;
            });
        demographics.template_allocations.map(v => formatAllocations(v));
    };

    const formatTrigger = (offer) => {
        Object.entries(trigger_fields.transaction.fields)
            .map(([k, v]) => {
                if (v.options) {
                    offer[k] = Array.isArray(offer[k]) ?
                        offer[k].map(r => buildStructure(v.options, r))
                        :
                        buildStructure(v.options, offer[k]);
                }
                return k;
            });

        offer.item_types = offer.item_types.map(({ name, id }) => ({ value: id, label: name }));
        return offer;
    };

    const formatOfferings = offers => offers.map((offer) => {
        formatTrigger(offer).demographics
            .map((data, i) => ({ ...offer.demographics[i], ...formatDemographics(data) }));
        return { ...offer, ...mockData };
    });

    const fetchData = useCallback(() => {
        const fetchDataHelper = productSettings ?
            fetchPaymentProductOfferTemplate : fetchOfferTemplate;
        fetchDataHelper(productId)
            .then((result) => {
                const fOfferings = formatOfferings(result);
                setOfferings(fOfferings);
            });
    });

    useEffect(() => {
        if (checked) {
            fetchData();
        }
    }, [checked]);

    useEffect(() => {
        Promise.all([
            fetchAvailableContries(),
            fetchLanguages(),
            fetchTransactionTypes(),
            fetchPoSEnvironments(),
            fetchOfferedServices(),
            fetchItemTypes()
        ])
            .then((response) => {
            // TODO below
                segment_fields.customer_countries.options = response[0].results
                    .map(({ pk, value }) =>
                        ({ label: value, value: pk }));
                segment_fields.culture.options = response[1].results
                    .map(({ pk, value }) =>
                        ({ label: value, value: pk }));
                trigger_fields.transaction.fields.transaction_types.options =
                        response[2].results
                            .map(({ pk, value }) =>
                                ({ label: value, value: pk }));
                trigger_fields.transaction.fields.pos_environments.options =
                    response[3].results
                        .map(({ pk, value }) => ({ label: value, value: pk }));
                offer_fields.offered_products.options = response[4].results
                    .map(({ pk, value }) =>
                        ({ label: value, value: pk }));
                trigger_fields.item_types.options = response[5].results
                    .map(({ pk, value }) =>
                        ({ label: value, value: +pk }));
                // TODO above

                fetchData();
            });
    }, []);

    const resetPopupState = () => {
        indexOfTriggerToDelete = -1;
        setIsOpen(false);
    };

    const proceedDelete = () => {
        deleteOfferingGroup(offerings[indexOfTriggerToDelete].uuid, productId)
            .then(() => {
                setOfferings((prev) => {
                    const temp = [...prev];
                    temp.splice(indexOfTriggerToDelete, 1);
                    return temp;
                });
                resetPopupState();
            });
    };

    const onTriggerDelete = (index) => {
        indexOfTriggerToDelete = index;
        setIsOpen(true);
    };

    // TODO: to move to helpers
    // triggerMapping does the same partially
    const resetTriggerFields = (full, attributes) => {
        const sources = ['transaction', 'merchant'];
        let res = { ...full };
        sources.map(s => Object.keys(trigger_fields[s].fields)
            .map((f) => {
                if (f === 'transaction_amount') {
                    const { payment_min_amount = 0.00,
                        payment_max_amount = 0.00 } = attributes[f] || {};
                    res = { ...res, payment_min_amount, payment_max_amount };
                } else if (f === 'distance') {
                    const { distance_min = 0,
                        distance_max = 0 } = attributes[f] || {};
                    res = { ...res, distance_min, distance_max };
                } else {
                    res[f] = !attributes[f] ? [] : attributes[f];
                }
                return f;
            })
        );
        return { ...res, item_types: attributes.item_types };
    };

    const onTriggerSave = async (newData, index) => {
        const body = resetTriggerFields(offerings[index], newData);

        await updateOfferingGroup(offerings[index].uuid,
            { ...triggerMapper(body), payment_product: productId });

        setOfferings((prev) => {
            const temp = [...prev];

            temp[index] = { ...temp[index], ...body };
            return temp;
        });

        return Promise.resolve();
    };

    const onNewTriggerSave = async (nData) => {
        const { uuid } = await addOfferingGroup(
            { ...triggerMapper(nData), payment_product: productId });

        setIsAddGroup(false);

        setOfferings((prev) => {
            const group = { ...nData, ...nData.transaction_amount, uuid, demographics: [] };
            const temp = [...prev];
            temp.unshift(group);
            return temp;
        });
    };

    const onAddGroup = () => {
        setIsAddGroup(true);
    };

    return (
        <div className="offer-management container">
            <header className="offer-management__header">
                <div className="offer-management__header-title">{messages.OFFER_MANAGEMENT}</div>
                {
                    productSettings ?
                        <>
                            <CustomizedSwitch
                                checked={checked}
                                handleChange={setChecked}
                                label={messages.USE_GENERAL_SETTINGS}
                                name="isOfferingSectionOpen" />
                        </> : null
                }
            </header>
            {!(productSettings && !checked) && <>
                <div className="btn-wrapper" style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                    <Button
                        size="small"
                        onClick={onAddGroup}
                        variant="contained"
                        type="button">
                        Add a new offer group
                    </Button>
                </div>
                {
                /* TODO: dublicated!!! */
                    isAddGroup ?
                        <div className="offer-management-card">
                            <div className="offer-management-card__title">
                                <span>Offer for</span>
                            </div>
                            <div className="offer-management-card__section">
                                <div className="offer-management-card__section__title">
                                    <span>Trigger</span>
                                </div>
                                <div className="offer-management-card__section__table">
                                    <TriggerTable
                                        data={initialTrigger}
                                        isAdding
                                        onSave={newData => onNewTriggerSave(newData)}
                                        onCancelAdding={() => setIsAddGroup(false)}
                                        itemTypeOptions={
                                            excludeItems(
                                                trigger_fields.item_types,
                                                offerings)
                                        } />
                                </div>
                            </div>
                        </div> : null
                }
                {
                    offerings.map((item, key) =>
                        <div className="offer-management-card" key={key}>
                            <div className="offer-management-card__title">
                                <span>Offer for</span>
                            </div>
                            <div className="offer-management-card__section">
                                <div className="offer-management-card__section__title">
                                    <span>Trigger</span>
                                </div>
                                <div className="offer-management-card__section__table">
                                    <TriggerTable
                                        data={item}
                                        onDelete={() => onTriggerDelete(key)}
                                        onSave={newData => onTriggerSave(newData, key)}
                                        itemTypeOptions={
                                            excludeItems(
                                                trigger_fields.item_types,
                                                spliceInCopy(offerings, key))
                                        } />
                                </div>
                                <div className="offer-management-card__section__title">
                                    <span>Segments & Strategies</span>
                                    <AddSegmentButton
                                        handleEdit={() => { setSelectedCategory(item.uuid); }} />
                                </div>
                                <div className="offer-management-card__section__table">
                                    <SegmentsTable
                                        data={item.demographics}
                                        saveSegment={(body, id) => updateOffering(body, id)}
                                        saveStrategy={(body, id) => updateOffering(body, id)}
                                        productId={productId}
                                        deleteOffer={id => deleteOffering(id)}
                                        addOffer={addNewOffering}
                                        onCategoryCancel={() => setSelectedCategory(false)}
                                        isCategory={item.uuid === selectedCategory}
                                        categoryUuid={item.uuid} />
                                </div>
                            </div>
                        </div>)
                }
                <Popup
                    style={{ backgroundColor: '#ffffff !important;' }}
                    open={isOpen}
                    className="popup-custom-class">
                    <p>Do you really want to delete group?</p>
                    <div>
                        <Button variant="text" onClick={resetPopupState}>No</Button>
                        <Button type="submit" onClick={proceedDelete}>Yes</Button>
                    </div>
                </Popup>
            </>}
        </div>
    );
};

export default OfferManagement;
