import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router';
import { fetchMerchantsGroups, fetchMerchants } from '../../store/merchants/merchants.actions';
import MerchantsSkeleton from './MerchantsSkeleton';
import Table from './Common/Table/Table';
import { merchantGroupTitles, merchantTitles, merchantListTabs } from '../constants';

const MerchantsList = () => {
    const titles = [merchantGroupTitles, merchantTitles];

    // const history = useHistory();

    const [merchantsData, setMerchantsData] = useState([]);
    const [merchantsGroupsData, setMerchantsGroupsData] = useState([]);
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        setMerchantsGroupsData(() => fetchMerchantsGroups());
        setMerchantsData(() => fetchMerchants());
    }, []);

    const handleTabClick = (index) => {
        setActiveTab(index);
        // history.push(`/merchant-groups?tab=${!activeTab ? 'merchant' : 'group'}`);
    };

    return <MerchantsSkeleton nav={activeTab ? 'merchant' : 'group'} title="Merchants" tabs={merchantListTabs} onTabClick={index => handleTabClick(index)} activeTab={activeTab}>
        <Table titles={titles[activeTab]} data={activeTab ? merchantsData : merchantsGroupsData} />
    </MerchantsSkeleton>;
};

export default MerchantsList;
