/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { messages } from '../../../constants/messages';

import './CancelConfirmation.scss';
import Button from '../../Common/Button/Button';
import { selectActualLanguage, selectWhiteLabelingStyles } from '../../../store/selectors';
import { setupCustomStyles } from '../../../helpers/renderingHelpers';
import withTheme from '../../../hocs/withTheme';

// eslint-disable-next-line camelcase

const getCancellationDate = (actualLanguage, date) => moment(date).locale(actualLanguage).format('ll');

const CancelConfirmation = ({ cancellationDate, itemType, onReturn, onCancel }) => {
    const actualLanguage = useSelector(selectActualLanguage);
    const whiteLabelingStyles = useSelector(selectWhiteLabelingStyles);
    setupCustomStyles(whiteLabelingStyles);
    const validString = getCancellationDate(actualLanguage, cancellationDate);

    const itemTypeName = <span className="bold">{itemType}</span>;
    const valideUntilDate = <span className="bold valid-until">{validString}</span>;

    return (
        <div className="cancel-confirmation-wrapper">
            <p className="confirmation-title">{messages.THE_INSURANCE_WILL_BE_CANCELLED}{valideUntilDate}</p>
            <p className="confirmation-title">{messages.ARE_YOU_SURE_TO_CANCEL}{itemTypeName}?</p>
            <Button onClick={onCancel} type="default">
                {messages.CANCEL_CONTRACT}
            </Button>
            <Button onClick={onReturn}>
                {messages.RETURN}
            </Button>
        </div>
    );
};

export default withTheme(CancelConfirmation);
