import React from 'react';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import './ConditionSelection.scss';
import { messages } from '../../../constants/messages';
import { isInsuranceLogoVisible, selectCurrencyPosition } from '../../../store/selectors';
import { setCurrentInsuranceId } from '../../../store/productInsurance/productInsurance.actions';
import List from './List';
import amazon from '../../../dist/images/Amazon.png';
// import pdf from '../../../dist/docs/Bases-Promoción-Decesos.pdf';

const ConditionSelection = (props) => {
    const {
        price, currency,
        insuranceId,
        checked, brandingName,
        salesArguments, isAmazon,
        paymentScheme, logo, showMore, isExplanation, isSelection, isTravel = false, insuredCapital
    } = props;
    const dispatch = useDispatch();
    const isLogoVisible = useSelector(isInsuranceLogoVisible);
    const currencyPosition = useSelector(selectCurrencyPosition);

    const clickOnSelect = () => {
        dispatch(setCurrentInsuranceId(insuranceId));
    };

    const splitPrice = price.split('.');

    const schemeList = {
        QUARTERLY: messages.QUARTERLY,
        MONTHLY: messages.MONTHLY,
        WEEKLY: messages.WEEKLY,
        YEARLY: messages.YEARLY,
        UPFRONT: ''
    };

    return (
        <>
            <div
                className={cn('condition-selection', { 'active-condition': checked })}
                onClick={() => { clickOnSelect(); }}>
                <div className="condition-selection-main">
                    {isSelection && <div className={cn('checkbox-off', { 'checkbox-on': checked })}>
                        <div />
                    </div>}
                    <div className="main-part">
                        <div className="text-wrap">
                            <div className="text-wrap-label">{brandingName}</div>
                            <div
                                className="text-wrap-description"
                                style={{ maxHeight: '44px' }}>
                                {salesArguments}
                            </div>
                            {(isExplanation && <List isTravel={isTravel} />) || null}
                            {showMore && <div
                                onClick={showMore}
                                className="details">{messages.MORE_DETAILS}
                            </div>}
                            {
                                !+insuredCapital ? null : <div className="capital">
                                    {messages.INSURED_CAPITAL}{currencyPosition === 'BEFORE' && currency}{insuredCapital}{currencyPosition !== 'BEFORE' && currency}
                                </div>
                            }
                        </div>
                        <div className="price-wrap">
                            {currencyPosition === 'BEFORE' ?
                                <div className="price price-space">
                                    {currency}
                                    <div className="price-wrapper price-before">
                                        <div className="price-whole">{splitPrice[0]}</div>
                                        <div className="price-coin">{splitPrice[1]}</div>
                                    </div>
                                </div>
                                :
                                <div className="price price-space">
                                    <div className="price-wrapper price-after">
                                        <div className="price-whole">{splitPrice[0]}</div>
                                        <div className="price-coin">{splitPrice[1]}</div>
                                    </div>
                                    {currency}
                                </div>
                            }
                            <div className="payment-scheme">
                                {schemeList[paymentScheme]}
                            </div>
                            {isLogoVisible && logo && (
                                <img className="insurance-logo" src={logo} alt="insurance logo" />
                            )}
                        </div>
                    </div>
                </div>
                {isAmazon ? <><div className="amazon">
                    <p>{messages.AMAZON_RETURN}</p>
                    <img src={amazon} alt="" />
                </div>
                    {/* <div className="download"><a href={pdf} target="_blank" rel="noreferrer">
                    {messages.DETAILS_DOWNLOAD}
                    </a></div> */}
                </> : null}
            </div>
        </>
    );
};

ConditionSelection.defaultProps = {
    brandingName: 'label',
    salesArguments: 'description',
    price: '0'
};

ConditionSelection.propTypes = {
    brandingName: PropTypes.string,
    salesArguments: PropTypes.string,
    price: PropTypes.string
};

export default ConditionSelection;
