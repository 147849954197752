import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { selectIssuerProfile } from '../../../../../store/issuerApp/issuerApp.selector';
import { fetchInboxUrl } from '../../../../../store/issuerApp/issuerApp.actions';

const lng = {
    'es-es': 'es',
    es: 'es',
    en: 'en',
    'en-us': 'en'
};

const Ready = () => {
    const [settings, setSettings] = useState();
    const { language } = useSelector(selectIssuerProfile);

    useEffect(() => {
        fetchInboxUrl()
            .then((res) => {
                setSettings(res);
            });
    }, []);

    const navigateAway = () => {
        window.open(`${settings.inbox_url}/main/?lng=${lng[language]}&idt=${settings.access_token}&hide_logo=1&maximized=1&bg_color=ffffff`);
    };

    return (settings && <>
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="whatsapp-away" onClick={navigateAway} />
        <iframe className="get-chat" src={`${settings.inbox_url}/main/?lng=${lng[language]}&idt=${settings.access_token}&hide_logo=1&maximized=1&bg_color=ffffff`} title="WhatsApp Inbox" />
    </>) || null;
};

export default Ready;
