export const actualFooterScreen = state => state.payment.actualFooterScreen;
export const selectActualFooterScreen = state => state.payment.actualFooterScreen;
export const cardNumber = state => state.payment.card_number;
export const cardExpiryDate = state => state.payment.card_expiry_date;
export const cardCvv = state => state.payment.card_cvv;
export const errorPopup = state => state.payment.errorPopup;
export const selectValidationError = state => state.payment.validationError;
export const paymentValid = state => state.payment.paymentValid;
export const selectPaymentValid = state => state.payment.paymentValid;
export const cardValid = state => state.payment.cardValid;
export const selectCardValid = state => state.payment.cardValid;
export const certificateUrl = state => state.payment.certificateUrl;
export const selectCustomerData = state => state.payment.customerData;
export const selectRedsysData = state => state.payment.paymentRedsysData;
export const selectPaymentInsuramaUrl = state => state.payment.paymentInsuramaUrl;
export const selectPaymentInitiationError = state => state.payment.paymentInitiationError;
export const selectCustomerEmail = state => (state.payment.customerData
    ? state.payment.customerData.email : '');
