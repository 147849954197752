/* eslint-disable import/prefer-default-export */
import calculateColorWithOpacity from '../../helpers/calculateColor';

export const stylesMapper = ((
    {
        background_color: backgroundColor,
        clickable_color: clickableColor,
        error_color: errorColor,
        header_color: headerColor,
        info_color: infoColor,
        logo,
        primary_color: primaryColor,
        success_color: successColor,
        text_color: textColor,
        warning_color: warningColor,
    }) => ({
    backgroundColor,
    clickableColor,
    errorColor,
    headerColor,
    infoColor,
    logo,
    primaryColor,
    successColor,
    textColor,
    warningColor,
}));

export const fillInEmptyValues = (styles, initialStyles) => {
    const formatedStyles = stylesMapper(styles);
    const entries = Object.entries(formatedStyles).map(([key, value]) =>
        [key, value || initialStyles[key]]
    );
    return Object.fromEntries(entries);
};

export const setupCustomStyles = (
    {
        backgroundColor,
        clickableColor,
        errorColor,
        headerColor,
        infoColor,
        primaryColor,
        successColor,
        textColor,
        warningColor,
    }) => {
    const root = document.documentElement;
    root.style.setProperty('--background-color', backgroundColor);
    root.style.setProperty('--clickable-color', clickableColor);
    root.style.setProperty('--error-color', errorColor);
    root.style.setProperty('--header-color', headerColor);
    root.style.setProperty('--info-color', infoColor);
    root.style.setProperty('--primary-color', primaryColor);
    root.style.setProperty('--primary-color-opacity', calculateColorWithOpacity(primaryColor, 0.15));
    root.style.setProperty('--success-color', successColor);
    root.style.setProperty('--text-color', textColor);
    root.style.setProperty('--warning-color', warningColor);
};
