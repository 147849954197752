import React from 'react';
import SegmentReadMode from './SegmentReadMode';
import SegmentEditMode from './SegmentEditMode';

const SegmentFragment = ({ data, handleMouseOver,
    onDelete, onSave, onCancel, isActive }) =>
    (
        <div
            className="offer-management-card__section__segments__segment"
            onClick={e => handleMouseOver(e)}>
            {
                !isActive ?
                    <SegmentReadMode data={data} /> :
                    <SegmentEditMode
                        data={data}
                        onCancel={e => onCancel(e)}
                        onDelete={onDelete}
                        onSave={onSave} />
            }
        </div>
    );

export default SegmentFragment;
