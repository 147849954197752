import React from 'react';
import CheckBoxSet from './CheckBox/CheckBoxSet';
import InputDatepicker from '../Common/InputDatepicker/InputDatepicker';

import { messages } from '../../constants/messages';

const FamilyStatus = ({ data, onChange }) => <>
    <InputDatepicker
        label={messages.WERE_BORN}
        icon
        disableFuture
        dateValue={data.dob}
        onInputChange={(value) => {
            onChange(value, 'dob');
        }} />
    <CheckBoxSet title={messages.YOU_ARE} data={data} field="family_status" onChange={onChange} statuses={messages.FAMILY_STATUSES} />
</>;

export default FamilyStatus;
