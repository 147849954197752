import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import Input from '../../Common/Input/Input';
import Loader from '../../Common/Loader/Loader';
import { messages } from '../../../../../constants/messages';
import { PHONE_REGEXP } from '../../../../helpers/validators';

const Form = ({ data, onSubmit }) => {
    const [values, setValues] = useState({});
    const [error, setError] = useState('');
    const [active, setActive] = useState(false);

    useEffect(() => {
        setValues(data);
    }, [data]);

    const onClick = () => {
        if (PHONE_REGEXP.test(values.phone_number)) {
            if (!active) {
                onSubmit(values);
                setActive(true);
            }
            return;
        }
        setError(messages.INVALID_FORMAT);
    };

    const onChange = (v, k) => { setError(''); setValues(prev => ({ ...prev, [k]: v })); };

    return <div className="details-wrapper_customer-details_form">
        <Input
            value={values.first_name}
            label={messages.FIRST_NAME}
        /* onChange={v => onChange(v, 'first_name')} */ />
        <Input
            value={values.last_name}
            label={messages.FAMILY_NAME}
        /* onChange={v => onChange(v, 'last_name')} */ />
        <Input
            error={error}
            value={values.phone_number}
            label={messages.PHONE_NUMBERS}
            type="text"
            keyRestrictions
            onChange={v => onChange(v, 'phone_number')} />
        <button className={`details-wrapper_customer-details_form_send ${active && 'active'}`} onClick={onClick}>
            {active && <Loader />}
            <span>{messages.SEND}</span>
            <FontAwesomeIcon icon={faPaperPlane} />
        </button>
    </div>;
};

export default Form;
