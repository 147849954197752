import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { messages } from '../../../../constants/messages';

import './RequestSent.scss';
import RequestSentIcon from './RequestSentIcon';

const useStyles = makeStyles({
    NavBackBtn: {
        color: '#F8F9FF',
        textTransform: 'capitalize',
        padding: '15px 30px',
        borderRadius: 10,
    }
});

const RequestSent = ({ onNavBack }) => {
    const classes = useStyles();
    return (
        <div className="request-sent">
            <div className="request-sent__img">
                <RequestSentIcon />
            </div>
            <div className="request-sent__header">{messages.PASSWORD_RESET_EMAIL}</div>
            <div className="request-sent__message">
                {messages.EMAIL_SENT}
            </div>
            <Button
                className={classes.NavBackBtn}
                onClick={() => onNavBack(false)}
                color="primary"
                variant="contained"
                type="button">
                {messages.BACK}
            </Button>
        </div>
    );
};
export default RequestSent;
