import React from 'react';
import './expander.scss';

const Expander = ({ onClick, label }) => {
    return (
        <div
            className="expander"
            onClick={onClick}
        >
            {label}
        </div>
    );
};

export default Expander;
