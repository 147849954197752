/* eslint-disable import/prefer-default-export */
import {
    SET_STYLES
} from './merchants.actionTypes';

import { fetchWrapper } from '../../merchants/helpers/fetchHelper';
import { fillInEmptyValues } from '../../merchants/helpers/buildStyles';

import { merchantsGroupData, merchantsData, merchantData } from '../../merchants/data';

export const setStyles = styles => ({ type: SET_STYLES, styles });

export const fetchMerchantsGroups = () => merchantsGroupData;

export const fetchMerchants = () => merchantsData;

export const fetchMerchantData = id => merchantData[id];

export const fetchGroupMerchants = uuid =>
    merchantsData.filter(({ group_uuid }) => group_uuid === uuid);

export const fetchGroupData = pk => merchantsGroupData.find(({ group_uuid }) => pk === group_uuid);

export const fetchActiveWhiteLabeling = () => (dispatch, getState) => {
    const initialStyles = getState().styles;
    return fetchWrapper({
        url: '/api/v1/partner-portal/active-white-labeling/',
        method: 'GET',
        ignoreAuth: true
    })
        .then((styles) => {
            dispatch(setStyles(fillInEmptyValues(styles, initialStyles)));
        });
};
