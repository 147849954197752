import React, { useEffect, useState } from 'react';
import Card from './Card';

import { messages } from '../../../constants/messages';

const Recommendations = ({ data, showDetails }) => {
    const [values, setValues] = useState(null);

    const initial_recommendations = [{
        title: messages.HEALTH_RELATED,
        necessary: [{
            title: messages.MANDATORY_HEALTH_INSURANCE
        }],
        recommended: [],
        worth_considering: [],
        makes_rarely_sense: [{
            title: messages.COMPLEMENTARY_HEALTH_INSURANCE,
            description: messages.COMPLEMENTARY_HEALTH_INSURANCE_DESCRIPTION
        }]
    }, {
        title: messages.LIFE_RELATED,
        necessary: [],
        recommended: [],
        worth_considering: [],
        makes_rarely_sense: [{
            title: messages.PAYMENT_PROTECTION_INSURANCE,
            description: messages.PAYMENT_PROTECTION_INSURANCE_DESCRIPTION
        }]
    }, {
        title: messages.LEGAL_RELATED,
        necessary: [{
            title: messages.PRIVATE_LIABILITY_INSURANCE,
            description: messages.PRIVATE_LIABILITY_INSURANCE_DESCRIPTION
        }],
        recommended: [],
        worth_considering: [],
        makes_rarely_sense: []
    }, {
        title: messages.PROPERTY_RELATED,
        necessary: [{
            title: messages.PRIVATE_LIABILITY_INSURANCE,
            description: messages.PRIVATE_LIABILITY_INSURANCE_DESCRIPTION
        }],
        recommended: [{
            title: messages.ASSISTANCE_INSURANCE
        }],
        worth_considering: [],
        makes_rarely_sense: [{
            title: messages.PASSENGER_INSURANCE,
            description: messages.PASSENGER_INSURANCE_DESCRIPTION
        }, {
            title: messages.SMARTPHONE_INSURANCE
        }, {
            title: messages.WARRANTY_EXTENSION_INSURANCE
        }]
    }];

    useEffect(() => {
        const recommendations = initial_recommendations;
        // health
        if (data.job_status === 'retired' || data.job_status === 'student' || data.job_status === 'job_seeker' || data.job_status === 'freelancer') {
            recommendations[0].necessary.push({
                title: messages.MANDATORY_ACCIDENT_INSURANCE,
                description: messages.MANDATORY_ACCIDENT_INSURANCE_DESCRIPTION
            });
            if (data.health && (data.health.indexOf('experienced_only') > -1 || data.health.indexOf('private_clinics') > -1)) {
                recommendations[0].worth_considering.push({
                    title: messages.PRIVATE_ACCIDENT_INSURANCE,
                    description: messages.PRIVATE_ACCIDENT_INSURANCE_DESCRIPTION
                });
            }
        }
        if (data.losses && data.losses <= 2500) {
            recommendations[0].recommended.push({
                title: messages.DENTAL_INSURANCE
            });
        }
        if (data.losses && data.care && data.losses <= 10000 && data.care.indexOf('kids') > -1 && data.kids_above === 'yes') {
            recommendations[0].recommended.push({
                title: messages.KIDS_DENTAL_INSURANCE,
                description: messages.KIDS_DENTAL_INSURANCE_DESCRIPTION
            });
        }
        if (data.job_status === 'freelancer') {
            recommendations[0].recommended.push({
                title: messages.DAILY_BENEFITS_INSURANCE,
                description: messages.DAILY_BENEFITS_INSURANCE_DESCRIPTION
            });
        }
        if (data.health && (data.health.indexOf('experienced_only') > -1 || data.health.indexOf('private_clinics') > -1)) {
            recommendations[0].worth_considering.push({
                title: messages.PRIVATE_HOSPITAL_INSURANCE,
                description: messages.PRIVATE_HOSPITAL_INSURANCE_DESCRIPTION
            });
        }

        // payment and life
        if ((data.job_status === 'employed' || data.job_status === 'freelancer' || data.job_status === 'entrepreneur' || data.job_status === 'job_seeker') && data.income !== 'below') {
            recommendations[1].recommended.push({
                title: messages.WORK_INSURANCE,
                description: messages.WORK_INSURANCE_DESCRIPTION
            });
        }
        if (data.care && data.mortgage_status === 'yes' && data.care.indexOf('kids') > -1 && data.care.indexOf('partner') > -1) {
            recommendations[1].recommended.push({
                title: messages.DEATH_INSURANCE,
                description: messages.DEATH_INSURANCE_DESCRIPTION
            });
        }
        if (data.job_status === 'entrepreneur' || data.job_status === 'freelancer') {
            recommendations[1].worth_considering = [{
                title: messages.A_POLICY,
                description: messages.A_POLICY_DESCRIPTION
            }, {
                title: messages.WHOLE_LIFE_INSURANCE,
                description: messages.WHOLE_LIFE_INSURANCE_DESCRIPTION
            }];
        }
        if (data.job_status !== 'entrepreneur' && data.job_status !== 'freelancer') {
            recommendations[1].makes_rarely_sense.push({
                title: messages.WHOLE_LIFE_INSURANCE,
                description: messages.WHOLE_LIFE_INSURANCE_DESCRIPTION_SHORT

            });
        }

        // legal
        if (data.job_status === 'entrepreneur' || data.job_status === 'freelancer') {
            recommendations[2].necessary.push({
                title: messages.BUSINESS_INSURANCE,
                description: messages.BUSINESS_INSURANCE_DESCRIPTION
            });
        }
        if (data.property && (data.job_status === 'employed' || data.property.indexOf('car') > -1 || data.apartment === 'rented_home')) {
            recommendations[2].recommended.push({
                title: messages.LEGAL_INSURANCE,
                description: messages.LEGAL_INSURANCE_DESCRIPTION
            });
        }

        // property
        if (data.property && data.property.indexOf('car') > -1) {
            recommendations[3].necessary.push({
                title: messages.CAR_INSURANCE,
            });
        }
        if (data.property && data.property.indexOf('motorbike') > -1) {
            recommendations[3].necessary.push({
                title: messages.MOTO_INSURANCE,
            });
        }
        if (data.property && data.property.indexOf('boat') > -1) {
            recommendations[3].necessary.push({
                title: messages.BOAT_INSURANCE,
            });
        }
        if (data.apartment === 'owned_apartment' || data.apartment === 'owned_home') {
            recommendations[3].necessary.push({
                title: messages.BUILDING_INSURANCE,
            });
        }
        if (data.property && data.property.indexOf('car') > -1) {
            recommendations[3].recommended.push({
                title: messages.HALF_CAR_INSURANCE,
                description: messages.HALF_CAR_INSURANCE_DESCRIPTION
            });
        }
        if (data.household_status === 'luxury' || data.household_status === 'normal') {
            recommendations[3].recommended.push({
                title: messages.HOUSEHOLD_INSURANCE
            });
        }
        if (data.care && (data.care.indexOf('dogs') > -1 || data.care.indexOf('cats') > -1 || data.care.indexOf('horses') > -1)) {
            recommendations[3].worth_considering.push({
                title: messages.PET_INSURANCE,
                description: messages.PET_INSURANCE_DESCRIPTION
            });
        }
        if (data.travel_status === 'more' && data.losses && data.losses > 2500) {
            recommendations[3].worth_considering.push({
                title: messages.TRAVEL_INSURANCE
            });
        }
        if (data.losses && data.losses <= 1000) {
            if (data.property.indexOf('bicycle') > -1) {
                recommendations[3].worth_considering.push({
                    title: messages.BICYCLE_INSURANCE
                });
            }

            recommendations[3].worth_considering.push({
                title: messages.ITEM_RELATED_INSURANCE,
                description: messages.ITEM_RELATED_INSURANCE_DESCRIPTION
            });
        }
        setValues(recommendations);
    }, [data]);

    return values && values.map((v, i) => <Card data={v} key={i} showDetails={showDetails} />);
};

export default Recommendations;
