const itCH = {
REDIRECT: 'Como próximo paso, se abrirá una nueva pestaña y serás redirigido al sitio web de Insurama. Insurama es la compañía de seguros que ofrece seguros para teléfonos móviles. Para asegurar la protección de tu teléfono móvil, te pedimos que continúes ingresando los detalles de pago.',
TRAVEL: {
    cancellation: {
        description: 'Annullamento del viaggio'
    },
    hospitalization: {
        description: 'Ricovero ospedaliero'
    },
    repatriation: {
        description: 'Rimpatrio'
    },
    accident: {
        description: 'Incidente'
    },
    wallet_theft: {
        description: 'Furto di portafogli e contanti'
    },
    device_theft: {
        description: 'Furto di dispositivi mobili e gioielli'
    }
},
FUTURE_DATE: 'Please enter future date',
TRAVEL_PRE_SCREEN_TITLE: 'Vostro viaggio',
    HELVETIA_EXPLANATION: {
        medical: {title: 'Medicina', points: [
        'Linea diretta di informazioni mediche 24 ore su 24', 'Secondo parere medico internazionale', '1 consultazione gratuita di qualsiasi specialità medica per persona/anno', 'Fornisce, a prezzi preferenziali, gli specialisti e le risorse diagnostiche di oltre 30 specialità con medici convenzionati'
        ], description: 'Medicina'},
        dental: {title: 'Odontoiatria', points: [
        'Una pulizia dentale gratuita all\'anno per persona/anno', 'Accesso a un\'ampia rete odontoiatrica a prezzi preferenziali'
        ], description: 'Odontoiatria'},
        legal: {title: 'Legale', points: [
                'Assistenza telefonica gratuita per qualsiasi questione personale dell\'assicurato, relativa a questioni familiari, abitazione, lavoro, questioni penali, sinistri, ecc.', 'Prima consulenza gratuita in ufficio'
                ], description: 'Legale'},
        travel: {title: 'Viaggi in tutto il mondo', points: [
        'Assistenza medica in caso di infortunio o malattia', 'Evacuazione medica o rimpatrio di persone ferite o malate'
        ], description: 'Viaggi in tutto il mondo'},
        funeral: {title: 'Morte', points: [
        'Servizio completo di sepoltura', 'Accompagnamento della salma, spese di viaggio e di soggiorno', 'Libera scelta del luogo di sepoltura in Italia'
        ], description: 'Morte'}
     },

    FROM: 'a partire da',
    ASSIST_YOU: 'Vi sosteniamo',
    CAN_FOCUS: 'Così potrete concentrarvi sulla vostra famiglia',

    GET_ASSISTANCE: 'Ottenere l\'assistenza della famiglia',
    TAX_NUMBER: 'Coddice Fiscale',
    CANNOT_PROCEED: 'Unfortunately we cannot proceed online',
    YES: 'Sì',
    NO: 'No',
    MALE: 'Maschio',
    FEMALE: 'Femmina',
    INSURED_PERSON: 'Persona assicurata',
    HELVETIA_POST_TITLE: 'Introdurre dettagli sulla persona assicurata:',
    BENEFICIARY_POSTAL_TITLE: 'Calcolo e contratto dell\'assicurazione in caso di morte:',
    POSTAL_CODE: 'Codice postale',
    INSURED: 'Inserire le date di nascita di tutte le persone da includere nell\'assicurazione',
    ABOVE_80: 'urtroppo possiamo includere solo persone di età inferiore a 80 anni',
    ADD_MORE: 'Aggiungere un\'altra persona',
    MESSAGE: "Messaggio",
    CONTACT_ME_Q: "Avete domande?",
    CONTACT_ME_A: "Saremo lieti di contattarvi.",
    SEND: "Inviare",
    CARD_INFORMATION: 'Informazioni sulla carta',
    REMARKS: "Osservazioni",
    MAX_AMOUNT: "Importo massimo",
    DETAILS: "Dettagli",
    FLIGHT_DETAILS: "Inserisci qui i dettagli del tuo volo:",
    CHOOSE_ITEM_TO_CHECK: "Scegliere l'articolo per il quale si desidera verificare la copertura",
    INSURANCE_COMPANY_LABEL: 'Compagnia di assicurazione',
    INSURANCE_PRODUCT_LABEL: 'Prodotto assicurativo',
    //steps
    STEP1: 'Dati',
    STEP2: 'Offerta',
    STEP3: 'Conclusione',
    CHOOSE_ITEM_TO_INSURE: 'Si prega di scegliere l\'oggetto che si desidera assicurare:',
    // Insurance overview
    INSURANCE_OVERVIEW: 'Sommario dell\'assicurazione:',
    COVERAGE: 'Rischi assicurati:',
    NON_COVERED: 'Rischi non assicurati:',
    DOWNLOAD_INSURANCE_PRODUCT: 'Scaricare il prodotto assicurativo',
    DOWNLOAD_TERMS_AND_CONDITIONS: 'Scarica Termini e condizioni',
    PRODUCT_INSURANCE_STEP_ONE_TITLE: 'Bastano pochi passaggi per ottenere la copertura assicurativa',
    PRODUCT_INSURANCE_STEP_TWO_TITLE: 'Prodotti rilevati dalla ricevuta',
    PRODUCT_INSURANCE_STEP_THREE_TITLE: 'Seleziona la tua assicurazione:',
    PRODUCT_INSURANCE_STEP_TWO_HINT: 'Tocca ciò che hai appena acquistato e deve essere coperto.',
    PRODUCT_INSURANCE_STEP_ONE_ONLINE: 'Dicci cosa vuoi proteggere.',
    ENTER_MORE_DETAILS: 'Immettere ulteriori dettagli sull\'elemento che si desidera garantire:',
    TAKE_RECEIPT_PHOTO: 'Si prega di scattare una foto della ricevuta di acquisto:',
    CHECK_RECEIPT: 'Si prega di controllare se la ricevuta è chiara nella foto.',
    CHECK_ITEM_PHOTO: 'Si prega di verificare se l\'articolo è chiaro nell\'immagine.',
    TAKE_ITEM_PHOTO: 'Si prega di scattare una foto del tuo articolo da coprire.',
    BUTTON: 'Pulsante',
    MORE_DETAILS: 'Mostra dettagli',
    BACK: 'Indietro',
    SKIP_RECEIPT: 'Salta ricevuta',
    NEXT: 'Il prossimo',
    GENERAL_CONDITIONS: 'Condizioni generali',
    LENGTH: 'Durata:',
    MINIMUM_LENGTH: 'Durata minima del contratto:',
    MAXIMUM_LENGTH: 'Durata massima del contratto:',
    CONTRACT_TERMINATION: 'Termine del contratto:',
    COVERAGE_LIST: 'Copertura',
    SEARCH: 'Ricerca',
    OTHER: 'Altro',
    CLOSE: 'Chiudere',
    PHOTO: 'Foto',
    OR: 'O',
    OPTIONS: 'Opzioni',
    SHOW_MORE: 'Mostra di più',
    DO_NOT_INSURE: 'Non assicurare questo articolo',
    PLEASE_WAIT: 'Attendere prego',
    RECOGNIZE_PROCESSING: 'L\'applicazione sta elaborando le foto per estrarre le informazioni rilevanti.',
    PRICE: 'Prezzo',
    CANCEL: 'Annulla',
    OK: 'Ok',
    SAVE_AND_NEXT: 'Avanti',

    // Inform Screen
    DEAR_CUSTOMER: 'Caro cliente,',
    TO_OFFER_YOU: 'per offrirti le migliori opzioni assicurative, abbiamo bisogno di maggiori informazioni sull\'articolo appena acquistato. Puoi:',
   /*  OPTION_TAKE_A_PHOTO: 'Scatta una foto della ricevuta di acquisto',
    OPTION_UPLOAD_FROM_GGALLERY: 'Carica una foto della ricevuta di acquisto', */
    OPTION_TAKE_A_PHOTO: 'Scatta una foto',
    OPTION_UPLOAD_FROM_GGALLERY: 'Carica una foto',
    OPTION_ENTER_MANUALLY: 'Immettere i dati manualmente',
    OPTION_TAKE_A_PHOTO_DESCRIPTION: 'Potrebbe essere richiesto l\'accesso alla fotocamera',
    OPTION_UPLOAD_FROM_GGALLERY_DESCRIPTION: 'Potrebbe essere richiesto l \'accesso a foto, media e file',

    // Empty State
    SORRY: 'scusate',
    SORRY_MESSAGE: 'Ci scusiamo ma il link dell\'offerta non è più valido. Saremo lieti di farti un\'offerta con il tuo prossimo acquisto',
    DONE: 'Fatto',

    // Payment Methods
    PAYMENT_METHODS: 'Modalità di pagamento',
    HOW_DO_YOU_WANT_TO_PAY: 'Come vuoi pagare?',
    YOU_WILL_BE_SPENDING: 'Spenderai',
    FOR_BUYING_INSURANCE_POLICIES: 'per l\'acquisto di polizze assicurative',
    CONFIRM_YOUR_CARD_DETAILS: 'Inserisci i dettagli della tua carta di pagamento.',
    HDRC_MONEY_BACK: 'Inserisci i dettagli della tua carta di pagamento.',
    CARDHOLDER_NAME: 'Nome del titolare',
    CARD_NUMBER: 'Numero di carta',
    EXPIRY_DATE: 'Data di scadenza',
    CVV: 'Codice di sicurezza (CVV)',
    CONFIRM_AND_PAY: 'Conferma e acquista',
    I_HAVE_SUCCESSFULLY_READ: 'Ho letto e accetto i termini e le condizioni.',
    READ_THE_FOLLOWING: 'Ho letto e accettato quanto segue:',
    TERMS_AND_CONDITIONS: 'Termini e condizioni',
    DATA_PRIVACY: 'Norme sulla privacy dei dati',
    ENTRY_A_FEW_MORE_DETAILS: 'Inserisci qualche dettaglio in più su di te:',
    FIRST_NAME: 'Nome di battesimo',
    FAMILY_NAME: 'Cognome',
    BIRTHDAY: 'Compleanno',
    EMAIL_ADDRESS: 'Indirizzo email',
    PHONE_NUMBERS: 'Numero di telefono',
    ADDRESS: 'Indirizzo',
    ENTRY_HERE: 'Entra qui',
    MONTHLY: 'al mese',
    WEEKLY: 'a settimana',
    YEARLY: 'per anno',
    QUARTERLY: 'Trimestrale',
    OVERVIEW_YEARLY: 'Annuale',
    OVERVIEW_MONTHLY: 'Monthy',
    OVERVIEW_WEEKLY: 'settimanalmente',
    WEEKS: 'settimane',
    MONTHS: 'mesi',
    YEARS: 'anni',
    WHAT_IS_NEXT: 'Cosa c\'è dopo:',
    INSURANCE_CERTIFICATE: (email) =>  `Il certificato di assicurazione verrà generato e inviato a ${email}`,
    YOU_CAN_DOWNLOAD: 'Inoltre, puoi scaricare il tuo certificato di assicurazione nella pagina successiva',
    DEDUCTIBLE: 'Franchigia:',

    // validation
    MANDATORY_FIELD: 'Questo campo è obbligatorio',
    INVALID_FORMAT: 'valore non valido',
    PRICE_SHOULD_BE_LOWER: 'Il prezzo dovrebbe essere inferiore al prezzo della transazione',
    DATE_SHOULD_BE_PAST: 'La data dovrebbe essere nel passato',

    // Congratulations!
    CONGRATULATIONS: 'Congratulazioni!!',
    GLAD_TO_INFORM_YOU: 'Siamo lieti di informarti che hai acquistato con successo la copertura. Il tuo prodotto è ora protetto.',
    YOU_WILL_RECEIVE: 'Riceverai nei prossimi minuti una mail di conferma con il certificato di copertura.',
    YOU_ARE_PROTECTED: 'Sei protetto',
    ALREADY_PURCHASED: 'Siamo lieti di informarti che hai già acquistato un\'assicurazione e che il tuo amato oggetto è già protetto.',
    DOWNLOAD: 'Scarica',
    YOU_CAN_DOWNLOAD_HERE: 'Puoi scaricare il tuo certificato di assicurazione qui',
    BACK_TO_OVERVIEW: 'torna alla panoramica',

    // contracts list
    YOUR_POLICY_PURCHASES: 'La tua assicurazione',
    PURCHASED_ON: 'Acquistato su',
    EXPIRING_ON: 'In scadenza il',
    WHATS_COVERED: 'Cosa è coperto',
    FILE_A_CLAIM: 'Presentare un reclamo',

    // contract details
    DOWNLOAD_PURCHASE_RECEIPT: 'Scarica la ricevuta d\'acquisto',
    INSURCANCE_CONTRACT: 'Contratto di assicurazione',
    ITEM_IS_PROTECTED_BY_INSURANCE: 'L\'articolo è protetto dall\'assicurazione',
    INSURANCE_COVERAGE_START: 'Inizio della copertura assicurativa',
    INSURANCE_COVERAGE_UNTIL: 'Copertura assicurativa fino a',
    DOWNLOAD_PRODUCT_SHEET: 'Scarica la scheda prodotto',
    DOWNLOAD_INSURANCE_CERTIFICATE: 'Scarica il certificato di assicurazione',
    CANCEL_CONTRACT: 'Annullare il contratto',

    // claim form
    PROVIDE_INFO_FOR_CLAIM: 'Vi preghiamo di fornirci alcune informazioni in più, prima di elaborare il vostro reclamo',
    ATTACH_PHOTO_VIDEO: 'Allegare foto/video',
    DESCRIBE_WHAT_HAPPEND: 'Si prega di descrivere ciò che è successo',
    ENTER_HERE: 'Entra qui',
    SUBMIT_CLAIM: 'Presentare il reclamo',
    PLEASE_ATTACH_FILES: 'Si prega di allegare i file',
    THANKS_FOR_REQUEST: 'Grazie per aver inviato la tua richiesta. Siamo felici di elaborarlo e fornirti una risposta nei prossimi giorni.',

    // confirmation
    YOUR_CONTRACT_HAS_BEEN_CANCELLED: 'Il suo contratto è stato cancellato. Riceverai presto una mail di conferma',
    GOT_IT: 'Ricevuto',
    THE_INSURANCE_WILL_BE_CANCELLED: 'L\'assicurazione sarà annullata a partire da',
    ARE_YOU_SURE_TO_CANCEL: 'Sei sicuro di voler annullare la protezione del tuo',
    RETURN: 'Ritorno',

    // contract statuses
    ACTIVE: 'attivo',
    PENDING_CANCELLATION: 'annullato',
    VOID: 'terminato',

    BENEFICIARY_TITLE: 'Chi deve essere incluso nella tua assicurazione?',
    BENEFICIARY_QUESTION: 'Whom would you like to cover?',
    BENEFICIARY_OPTION1: 'me stessa',
    BENEFICIARY_OPTION2: 'io e la mia famiglia/famiglia',
}

export default itCH;
