import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faDownload } from '@fortawesome/free-solid-svg-icons';
import Track from '../../Details/Track/Track';
import { messages } from '../../../../../constants/messages';


const roles = {
    advisor: messages.ADVISOR,
    team_manager: messages.TEAM_MANAGER,
};

const Row = ({ data,
    onEdit, isEditable = true }) => {
    const handleEdit = () => {
        onEdit();
    };

    const renderWizard = (key, value) => {
        if (typeof value === 'object') {
            return (key === 'tracking' && <Track data={value} />) || roles[value[0].role];
        }
        if (key === 'initiated_contact_at' && value) {
            return <FontAwesomeIcon icon={faCheckDouble} />;
        }
        if (key === 'certificate' && value) {
            return <a href={value} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faDownload} /></a>;
        }
        return value;
    };

    return <div className="table_row" onClick={isEditable ? handleEdit : null}>
        {
            Object.entries(data).map(([key, value]) => <div className="table_column" key={key}>
                {
                    renderWizard(key, value)
                }
            </div>)
        }
    </div>;
};

export default Row;
