import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './ListMarker.scss';

const ListMarker = ({ size, className, type }) => (
    <div className={classNames('list-marker', size, className, type)} />
);

ListMarker.propTypes = {
    size: PropTypes.oneOf(['large', 'small']),
    className: PropTypes.string,
    type: PropTypes.oneOf(['primary'])
};
ListMarker.defaultProps = {
    size: 'small',
    className: '',
    type: 'primary'
};

export default ListMarker;
