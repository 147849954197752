import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getIssuerProfile } from '../../store/issuerApp/issuerApp.actions';

const Choice = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch(getIssuerProfile())
            .then((role) => {
                history.push(role === 'issuer' ? '/app?tab=general-settings' : '/broker-portal?tab=offer');
            });
    }, []);
    return <div />;
};

export default Choice;
