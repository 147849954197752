import createReducer from '../createReducer';
import {
    SET_STYLES,
} from './merchants.actionTypes';

const merchants = {
    styles: {
        backgroundColor: '#f3eee5',
        clickableColor: '#d3d3d3',
        errorColor: '#ff1414',
        headerColor: '#d9d7d8',
        infoColor: '#c7c7c7',
        primaryColor: '#05b2ca',
        successColor: '#3ab800',
        textColor: '#05264D',
        warningColor: '#fff700',
    },
};

const setStyles = (state, { styles }) => ({ ...state, styles });

const merchantsReducer = createReducer(merchants, {
    [SET_STYLES]: setStyles,
});

export default merchantsReducer;
