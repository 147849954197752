import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setStep, uploadPhoto/* , getInsuranceProducts */ } from '../store/productInsurance/productInsurance.actions';
import ReceiptPhoto from '../components/ProductInsurance/ReceiptPhoto/ReceiptPhoto';
import GoodsConditions from '../components/ProductInsurance/GoodsConditions/GoodsConditions';
import InformScreen from '../components/ProductInsurance/InformScreen/InformScreen';
import BeneficiaryAttributes from '../components/ProductInsurance/BeneficiaryAttributes/BeneficiaryAttributes';
import HelvetiaPreAttributes from '../components/ProductInsurance/HelvetiaAttributes/HelvetiaPreAttributes';
import HelvetiaPostAttributes from '../components/ProductInsurance/HelvetiaAttributes/HelvetiaPostAttributes';
import HelvetiaExplanation from '../components/ProductInsurance/HelvetiaAttributes/HelvetiaExplanation';
import TravelPreAttributes from '../components/ProductInsurance/TravelInsurance/TravelPreAttributes';
/* import TravelPostAttributes
from '../components/ProductInsurance/TravelInsurance/TravelPostAttributes'; */
import CustomerAttributes from '../components/ProductInsurance/CustomerAttributes/CustomerAttributes';
import InsureeScreen from '../components/ProductInsurance/InsureeScreen/InsureeScreen';
import LanguageSelection from '../components/ProductInsurance/LanguageSelection';
import {
    selectProfile,
    selectLoading,
    selectStep, selectPhotos, /* selectCurrentGoodsId, */
    selectWhiteLabelingStyles,
} from '../store/selectors';
import Spinner from '../components/Common/Spinner/Spinner';
import ImageDropZone from '../helpers/ImageDropZone';
import ItemAttributes from '../components/ProductInsurance/ItemAttributes/ItemAttributes';
import { STEPS } from '../constants/constants';
import TakePhoto from '../components/ProductInsurance/TakePhoto/TakePhoto';
import withTheme from '../hocs/withTheme';
import ProductsList from '../components/ProductInsurance/ProductsList/ProductsList';
import { setupCustomStyles } from '../helpers/renderingHelpers';

import '../styles/ProductInsurance.scss';
import UnqualifiedItemTypes from '../components/ProductInsurance/UnqualifiedItemTypes/UnqualifiedItemTypes';

import { selectTargetPhoto } from '../store/common/common.selector';
import { selectPaymentInitiationError } from '../store/payment/payment.selector';

const ProductInsurance = () => {
    const profile = useSelector(selectProfile);
    const dropzone = useRef(null);
    const dispatch = useDispatch();
    const step = useSelector(selectStep);
    // const currentGoodsId = useSelector(selectCurrentGoodsId);
    const loading = useSelector(selectLoading);
    const photos = useSelector(selectPhotos);
    const whiteLabelingStyles = useSelector(selectWhiteLabelingStyles);
    setupCustomStyles(whiteLabelingStyles);
    const targetPhoto = useSelector(selectTargetPhoto);
    const serverError = useSelector(selectPaymentInitiationError);

    useEffect(() => {
        if (photos.length) {
            photos.forEach((picture) => {
                const img = new Image();
                img.src = picture.attachment;
            });
        }
    }, [photos]);

    const uploadNewPhoto = (photo, type) => {
        dispatch(uploadPhoto({
            photo, type
        }))
            .then(() => dispatch(setStep(STEPS.REVIEW_PHOTO)));
    };

    const openDropZone = () => {
        dropzone.current.open();
    };

    const renderWizard = (currentStep) => {
        switch (currentStep) {
            case STEPS.LANGUAGE_SELECT:
                return <LanguageSelection />;
            case STEPS.TAKE_OR_UPLOAD_PHOTO:
                return (
                    <InformScreen photo={targetPhoto} />
                );
            case STEPS.TAKE_PHOTO:
                return (
                    <TakePhoto />
                );
            case STEPS.REVIEW_PHOTO:
                return (
                    <ReceiptPhoto
                        photo={targetPhoto}
                        openDropZone={openDropZone} />
                );
            case STEPS.ITEM_TYPE_DETAILS:
                return (
                    <ItemAttributes serverError={serverError} />
                );
            case STEPS.INSURANCE_PRODUCT_SELECTION:
                return (
                    <GoodsConditions />
                );
            case STEPS.PRODUCTS_LIST:
                return (
                    <ProductsList />
                );
            case STEPS.BENEFICIARY_SCREEN:
                return (<BeneficiaryAttributes />);
            case STEPS.HELVETIA_PRE_DETAILS:
                return (<HelvetiaPreAttributes />);
            case STEPS.HELVETIA_EXPLANATION:
                return (<HelvetiaExplanation />);
            case STEPS.HELVETIA_POST_DETAILS:
                return (<HelvetiaPostAttributes />);
            case STEPS.TRAVEL_INSURANCE_PRE:
                return (<TravelPreAttributes />);
            case STEPS.TRAVEL_INSURANCE_POST:
                return (<InsureeScreen travel />);
                /* return (<TravelPostAttributes />); */
            case STEPS.UNQUALIFIED_ITEMS_SELECTION:
                return (
                    <UnqualifiedItemTypes />
                );
            case STEPS.USER_DETAILS:
                return (<CustomerAttributes serverError={serverError} />);
            case STEPS.POST_INSUREE:
                return (<InsureeScreen />);
            default:
                return null;
        }
    };

    return (
        <div className="product-insurance content">
            {profile && renderWizard(step)}
            {loading && (
                <Spinner />
            )}
            <ImageDropZone
                accept=".jpg,.png,.jpeg"
                nodeRef={node => dropzone.current = node}
                onSubmit={uploadNewPhoto}
            />
        </div>
    );
};

export default withTheme(ProductInsurance);
