import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLanguage } from '../../../helpers/customHooks';

import Header from '../../Header';
import Footer from '../../Common/Footer/Footer';

import Card from './Card';

import { setCurrentStepNumber } from '../../../store/common/common.actions';
import { setActualFooterScreen } from '../../../store/payment/payment.actions';
import { setStep, saveItemTypeAttrubutes, setItemTypeAttributeValue } from '../../../store/productInsurance/productInsurance.actions';
import { selectCurrentStepNumber, selectBreadcrumbs, selectBeneficiaryAttributes } from '../../../store/common/common.selector';
import { selectItemTypeAttributes } from '../../../store/selectors';

import { messages } from '../../../constants/messages';

const initial = ['insured_first_name', 'insured_last_name', 'insured_dob'];

const InsureeScreen = ({ travel }) => {
    useLanguage();
    const dispatch = useDispatch();
    const history = useHistory();
    const [validStatus, setValidStatus] = useState(null);
    /* const [keys, setKeys] = useState([]); */

    const attributes = useSelector(selectItemTypeAttributes).filter(attr => attr.position === 'POST_OFFER');
    const beneficiaryAttributesNumberOfPeople = useSelector(selectBeneficiaryAttributes)
        .reduce((sum, { value, key }) => {
            if (key !== 'for_whom') {
                return sum + +value;
            }
            return sum;
        }, 0);

    const keys = !travel ? [...initial, 'insured_tax_id'] : initial;

    const currentStepNumber = useSelector(selectCurrentStepNumber);
    const breadcrumbs = useSelector(selectBreadcrumbs);

    const handleDataChange = (value, key) => {
        dispatch(setItemTypeAttributeValue({ key, value }));
    };

    const onBackStep = () => {
        const { step } = breadcrumbs[currentStepNumber - 1];
        dispatch(setCurrentStepNumber(currentStepNumber - 1));
        dispatch(setStep(step));
    };

    const onNextStep = () => {
        const isValid = !Object.values(validStatus).some(st => !st);
        if (isValid) {
            dispatch(saveItemTypeAttrubutes())
                .then(() => {
                    const { step, payment } = breadcrumbs[currentStepNumber + 1];
                    if (payment) {
                        dispatch(setActualFooterScreen(step));
                        history.push('/payment');
                        // logEvent(EVENTS.ACTIVE_STEP, { page: 'Payment part' });
                    }
                    dispatch(setCurrentStepNumber(currentStepNumber + 1));
                    dispatch(setStep(step));
                });
        }
    };

    if (!attributes.length) {
        return null;
    }

    const errorCallback = (isValid) => {
        setValidStatus(prev => ({ ...prev, ...isValid }));
    };

    return <>
        <Header
            stepper
            stepsTitle={messages.HELVETIA_POST_TITLE} />
        <div className="beneficiary-postal-wrap">
            {
                keys.length && attributes.map((attr, i) =>
                    (i < beneficiaryAttributesNumberOfPeople ? <Card
                        errorCallback={errorCallback}
                        dateDisabled={false}
                        title={`${messages.INSURED_PERSON} ${i + 1}`}
                        data={keys.map(k => attributes.filter(({ key }) => key === `${k}_${i + 1}`)[0])}
                        key={i + 1}
                        handleDataChange={(v, k) => handleDataChange(v, k)}
                    /> : null))
            }
        </div>
        <Footer
            showBack
            nextText={messages.SAVE_AND_NEXT}
            onNextStep={onNextStep}
            onNavBack={onBackStep}
            disabled={!validStatus || Object.values(validStatus).some(st => !st)}
        />
    </>;
};

export default InsureeScreen;
