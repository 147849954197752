const fr = {
//customer journey
HEALTH_RELATED: 'Assurances santé',
MANDATORY_HEALTH_INSURANCE: 'Assurance maladie obligatoire',
COMPLEMENTARY_HEALTH_INSURANCE: 'Assurance maladie complémentaire',
COMPLEMENTARY_HEALTH_INSURANCE_DESCRIPTION: 'Dans la plupart des cas, les coûts d\'une assurance maladie complémentaire dépassent ses avantages',
LIFE_RELATED: 'Assurances liées à la vie et aux paiements',
PAYMENT_PROTECTION_INSURANCE: 'Assurance protection des paiements',
PAYMENT_PROTECTION_INSURANCE_DESCRIPTION: 'Une assurance vie à risque offrirait une protection supérieure à un coût moindre',
LEGAL_RELATED: 'Assurances liées à la responsabilité et au droit',
PRIVATE_LIABILITY_INSURANCE: 'Assurance responsabilité civile privée',
PRIVATE_LIABILITY_INSURANCE_DESCRIPTION: 'L\'assurance responsabilité civile privée est l\'une des assurances les plus importantes. Elle n\'est pas obligatoire, mais nécessaire si vous voulez protéger votre existence financière',
PROPERTY_RELATED: 'Assurance liée à la propriété et à la richesse',
ASSISTANCE_INSURANCE: 'Assurance assistance',
PASSENGER_INSURANCE: 'Assurance passagers',
PASSENGER_INSURANCE_DESCRIPTION: 'Une assurance passagers n\'a de sens que pour les passagers qui ne résident pas en Suisse ou dans un pays de l\'UE',
SMARTPHONE_INSURANCE: 'Assurance pour smartphones',
WARRANTY_EXTENSION_INSURANCE: 'Extension de garantie',
MANDATORY_ACCIDENT_INSURANCE: 'Assurance accidents obligatoire',
MANDATORY_ACCIDENT_INSURANCE_DESCRIPTION: 'Les traitements pour les blessures dues à un accident ne sont pas couverts par l\'assurance maladie, mais par l\'assurance accidents',
PRIVATE_ACCIDENT_INSURANCE: 'Assurance accidents privée ou semi-privée',
PRIVATE_ACCIDENT_INSURANCE_DESCRIPTION: 'Les patients avec une assurance semi-privée et privée sont généralement traités par des médecins expérimentés même en cas de besoins médicaux moins graves. Note: En général, les patients avec une maladie ou blessure critique sont toujours traités par des médecins expérimentés.',
DENTAL_INSURANCE: 'Assurance dentaire',
KIDS_DENTAL_INSURANCE: 'Assurance dentaire pour enfants',
KIDS_DENTAL_INSURANCE_DESCRIPTION: 'Les corrections dentaires pour enfants peuvent rapidement coûter plus de CHF 15\'000',
DAILY_BENEFITS_INSURANCE: 'Assurance indemnités journalières',
DAILY_BENEFITS_INSURANCE_DESCRIPTION: 'L\'assurance indemnités journalières verse à l\'assuré une somme d\'argent prédéfinie. Ceci est particulièrement bénéfique pour les travailleurs indépendants qui dépendent de leurs revenus',
PRIVATE_HOSPITAL_INSURANCE: 'Assurance hôpital privée ou semi-privée',
PRIVATE_HOSPITAL_INSURANCE_DESCRIPTION: 'Les patients avec une assurance semi-privée et privée sont généralement traités par des médecins expérimentés même en cas de besoins médicaux moins graves. Note: En général, les patients avec une maladie ou blessure critique sont toujours traités par des médecins expérimentés',
WORK_INSURANCE: 'Assurance contre l\'incapacité de travail',
WORK_INSURANCE_DESCRIPTION: 'L\'incapacité de travailler en raison de maladies ou de problèmes psychologiques n\'est pas bien couverte par les assurances obligatoires. Typiquement, l\'incapacité de travailler en raison d\'une maladie entraîne une perte de revenus de 40%',
DEATH_INSURANCE: 'Assurance décès à risque',
DEATH_INSURANCE_DESCRIPTION: 'L\'assurance à risque verse une somme forfaitaire et/ou une rente en cas de décès. Cela permet le paiement de dettes en suspens et/ou assure que le partenaire et les enfants sont financièrement protégés.',
A_POLICY: 'Police d\'assurance 3a',
A_POLICY_DESCRIPTION: 'En général, les produits bancaires sont le meilleur choix pour accumuler de la richesse. Cependant, dans certaines conditions, les assurances-vie, par exemple pour les entrepreneurs et les travailleurs indépendants, offrent des avantages importants en tant que véhicules d\'épargne',
WHOLE_LIFE_INSURANCE: 'Assurance vie entière / épargne',
WHOLE_LIFE_INSURANCE_DESCRIPTION: 'En général, les produits bancaires sont le meilleur choix pour accumuler de la richesse. Cependant, dans certaines conditions, les assurances-vie, par exemple pour les entrepreneurs et les travailleurs indépendants, offrent des avantages importants en tant que véhicules d\'épargne',
BUSINESS_INSURANCE: 'Assurance responsabilité civile professionnelle',
BUSINESS_INSURANCE_DESCRIPTION: 'Selon votre profession (par exemple, médecin, architecte, etc.), vous avez besoin d\'une assurance responsabilité civile professionnelle',
LEGAL_INSURANCE: 'Assurance protection juridique',
LEGAL_INSURANCE_DESCRIPTION: 'L\'assurance protection juridique couvre les frais juridiques si vous devez vous défendre en relation avec votre emploi, votre logement loué ou en relation avec la mobilité',
CAR_INSURANCE: 'Assurance responsabilité civile automobile',
MOTO_INSURANCE: 'Assurance responsabilité civile moto',
BOAT_INSURANCE: 'Assurance responsabilité civile bateau',
BUILDING_INSURANCE: 'Assurance bâtiment',
HALF_CAR_INSURANCE: 'Assurance auto tous risques ou partielle',
HALF_CAR_INSURANCE_DESCRIPTION: 'Une assurance tous risques est recommandée si vous avez besoin d\'une voiture pour un usage quotidien ou si votre voiture actuelle a moins de 5 ans',
HOUSEHOLD_INSURANCE: 'Assurance ménage',
PET_INSURANCE: 'Assurance pour animaux de compagnie',
PET_INSURANCE_DESCRIPTION: 'Les traitements pour animaux de compagnie deviennent de plus en plus sophistiqués et coûteux. Si tu ne veux pas choisir entre dépenser CHF 15\'000 ou euthanasier ton animal de compagnie, alors une assurance pour animaux de compagnie pourrait être la bonne pour toi',
TRAVEL_INSURANCE: 'Assurance voyage',
BICYCLE_INSURANCE: 'Assurance vélo',
ITEM_RELATED_INSURANCE: 'Assurance liée à l\'objet (par exemple, bijoux, instrument de musique)',
ITEM_RELATED_INSURANCE_DESCRIPTION: 'Seulement pour les objets dont tu as vraiment besoin et dont la valeur dépasse 2\'500 CHF',
STEP_TITLES: ['Parle-nous de toi', 'Comprends mieux ton contexte', 'Découvre tes recommandations'],
WERE_BORN: 'Tu es né le',
YOU_ARE: 'Tu es',
FAMILY_STATUSES: [
     { key: 'single', name: 'célibataire' },
     { key: 'married', name: 'marié' },
     { key: 'divorced', name: 'divorcé/veuf' }],
JOB_STATUSES: [
     { key: 'employed', name: 'employé' },
     { key: 'freelancer', name: 'indépendant' },
     { key: 'entrepreneur', name: 'entrepreneur' },
     { key: 'job_seeker', name: 'chercheur d\'emploi' },
     { key: 'student', name: 'étudiant' },
     { key: 'retired', name: 'retraité' }],
YEARLY_INCOME_IS: 'Ton revenu annuel est',
INCOME_STATUSES: [
    { key: 'below', name: 'moins de CHF 25’725' },
    { key: 'between', name: 'entre CHF 25’725 et CHF 88’200' },
    { key: 'above', name: 'plus de CHF 88’200' }],
YOU_TRAVEL: 'Tu voyages',
TRAVEL_STATUSES: [
    { key: 'rarely', name: 'rarement' },
    { key: 'once_per_year', name: 'une fois par an' },
    { key: 'more', name: 'deux fois par an ou plus' }],
YOU_LIVE_IN: 'Tu habites à',
APARTMENT_OPTIONS: [
    { key: 'rented_room', name: 'Colocation/résidence étudiante' },
    { key: 'rented_home', name: 'Appartement loué' },
    { key: 'owned_apartment', name: 'Appartement en propriété' },
    { key: 'owned_home', name: 'Maison en propriété' }],
HOUSEHOLD: 'L\'aménagement de ta maison est',
HOUSEHOLD_STATUSES: [
    { key: 'spartanic', name: 'spartiate' },
    { key: 'normal', name: 'normal' },
    { key: 'luxury', name: 'luxueux' }],
CARE_FOR: 'Tu vis avec ou tu es responsable de',
CARE_LIST: [
    { key: 'partner', name: 'partenaire' },
    { key: 'kids', name: 'enfant(s)' },
    { key: 'dogs', name: 'chien(s)' },
    { key: 'cats', name: 'chat(s)' },
    { key: 'horses', name: 'cheval(aux)' }],
KIDS_ABOVE_6: 'As-tu des enfants de moins de 6 ans',
KIDS_ABOVE_OPTIONS: [{ key: 'yes', name: 'Oui' }, { key: 'no', name: 'Non' }],
IS_LOAN: 'As-tu une hypothèque ou un prêt en cours?',
LOAN_OPTIONS: [
   { key: 'yes', name: 'Oui' },
   { key: 'no', name: 'Non' }],
YOU_OWN: 'Tu possèdes',
PROPERTY_LIST: [
   { key: 'car', name: 'voiture(s)' },
   { key: 'motorbike', name: 'moto(s)' },
   { key: 'camper', name: 'camping-car(s)' },
   { key: 'boat', name: 'bateau(x)' },
   { key: 'bicycle', name: 'vélo(s)/e-bike(s)' },
   { key: 'scooter', name: 'trottinette(s) électrique(s)' }],
LOSSES: 'À tout moment, tu es capable et prêt à supporter des pertes inattendues jusqu\'à',
LOSSES_LIST: [
   { key: 500, name: 'CHF 500' },
   { key: 1000, name: 'CHF 1’000' },
   { key: 2500, name: 'CHF 2’500' },
   { key: 5000, name: 'CHF 5’000' },
   { key: 100000, name: 'CHF 10’000 ou plus' }],
HEALTH: 'Quand il s\'agit de ta santé',
HEALTH_EXPERIENCE: [
   { key: 'standard_coverage', name: 'Tu es satisfait de la norme légale' },
   { key: 'experienced_only', name: 'Tu souhaites être traité uniquement par des médecins expérimentés' },
   { key: 'private_clinics', name: 'Tu souhaites avoir accès à des cliniques privées' },
   { key: 'alternative_treatment', name: 'Tu souhaites avoir accès à un traitement médical complémentaire/alternatif' }],
INFORMATION_POINTS: ['Ce que tu verras ensuite ne sont pas des recommandations. Nous allons simplement montrer les assurances typiques à des fins d\'orientation, qui sont recommandées aux personnes qui ont répondu de manière similaire au questionnaire', 'Pour une recommandation d\'assurance personnalisée, nous te conseillons de prendre un rendez-vous de consultation personnelle.'],
NECESSARY: 'Nécessaire',
RECOMMENDED: 'Recommandé',
WORTH_CONSIDERING: 'À considérer',
MAKES_RARELY_SENSE: 'A rarement du sens',
STEPS: ['À propos de moi', 'Mon contexte', 'Assurances'],
WHOLE_LIFE_INSURANCE_DESCRIPTION_SHORT : "D'une manière générale, les produits bancaires constituent le meilleur choix pour constituer un patrimoine",
REDIRECT: 'Como próximo paso, se abrirá una nueva pestaña y serás redirigido al sitio web de Insurama. Insurama es la compañía de seguros que ofrece seguros para teléfonos móviles. Para asegurar la protección de tu teléfono móvil, te pedimos que continúes ingresando los detalles de pago.',
TRAVEL: {
    cancellation: {
        description: 'Annulation de voyage'
    },
    hospitalization: {
        description: 'Hospitalisation'
    },
    repatriation: {
        description: 'Rapatriement'
    },
    accident: {
        description: 'Accident'
    },
    wallet_theft: {
        description: 'Vol de portefeuille et d\'argent liquide'
    },
    device_theft: {
        description: 'Vol d\'appareils mobiles et de bijoux'
    }
},
FUTURE_DATE: 'Please enter future date',
TRAVEL_PRE_SCREEN_TITLE: 'Votre voyage',
//explaination page for assistance
HELVETIA_EXPLANATION: {
medical: {title: 'Medical', points: [
'Hotline médicale et appels vidéo 24h/24', 'Deuxième avis international', 'Réseau médical exclusif'
], description: 'Assistance médicale'},
dental: {title: 'Dental', points: [
'1 contrôle et nettoyage dentaire gratuit par personne et par an', 'Réseau d\'accès exclusif aux dentistes'
], description: 'Assistance dentaire'},
legal: {title: 'Legal', points: [
'1ère consultation gratuite et 25% de réduction pour tout suivi'
], description: 'Assistance juridique'},
travel: {title: 'Travel', points: [
'Assistance en cas d\'accident, de maladie et d\'autres événements imprévus', 'Prolongeable jusqu\'à 6 ou 12 mois'
], description: 'Assistance voyage'},
funeral: {title: 'Funeral', points: [
'Inhumation Capital 3\'700 €', 'Fourniture du service funéraire : cercueil, corbillard, services religieux, chapelle, etc.', 'Crémation et autres services complémentaires'
], description: "Services funéraires"}
},
ASSIST_YOU: 'Nous vous assistons !',
CAN_FOCUS: 'Pour que vous et votre famille puissiez profiter pleinement de ce moment !',
GET_ASSISTANCE: 'Pour mon offre personnelle',
INSURED_HINT: 'Ajoutez tous les membres de votre famille qui vivent dans votre foyer et qui ont moins de 80 ans.',
IBAN_HINT: 'L\'IBAN est destiné à un paiement ultérieur. La carte ne sera utilisée que pour le premier paiement.',
DATE_PLACEHOLDER: 'jj.mm.aaaa',
TAX_NUMBER: 'Code fiscale',
CANNOT_PROCEED: 'Veuillez appeler votre conseiller. Dans votre situation, le contrat d\'assurance ne peut pas être proposé en ligne. ',
YES: 'Oui',
NO: 'Non',
MALE: 'Homme',
FEMALE: 'Femme',
INSURED_PERSON: 'Personne assurée',
HELVETIA_POST_TITLE: 'Indiquez les coordonnées de la personne assurée :',
BENEFICIARY_POSTAL_TITLE: 'Calculez et contractez votre assurance :',
POSTAL_CODE: 'Code postal',
INSURED: 'Veuillez indiquer la date d\'anniversaire de tous les membres de votre famille qui seront protégés par l\'assurance',
ABOVE_80: 'Malheureusement, nous ne pouvons assurer que les personnes âgées de moins de 80 ans.',
ADD_MORE: 'Ajouter plus',
MESSAGE: 'Message',
CONTACT_ME_Q: 'Vous avez des questions ?',
CONTACT_ME_A: 'Faites-le nous savoir et nous vous rappellerons.',
SEND: 'Envoyer',
CARD_INFORMATION: 'Informations sur la carte',
REMARKS: 'Remarques',
MAX_AMOUNT: 'Montant maximum',
DETAILS: 'Détails',
FLIGHT_DETAILS: 'Veuillez indiquer ici les détails de votre vol :',
WITHOUT_DEDUCTIBLE: 'Sans franchise',
CHOOSE_ITEM_TO_CHECK: 'Veuillez choisir l\'élément pour lequel vous souhaitez vérifier la couverture :',
INSURANCE_COMPANY_LABEL: 'Compagnie d\'assurance',
INSURANCE_PRODUCT_LABEL: 'Produit d\'assurance',
//steps
STEP1: 'Données',
STEP2: 'Offre',
STEP3: 'Achat',
CHOOSE_ITEM_TO_INSURE: 'Veuillez choisir l\'objet que vous souhaitez assurer :',
// Insurance overview
INSURANCE_OVERVIEW: 'Aperçu de l\'assurance :',
COVERAGE: 'Risques assurés :',
NON_COVERED: 'Risques non assurés :',
DOWNLOAD_INSURANCE_PRODUCT: 'Téléchargez la description détaillée de l\'assurance',
DOWNLOAD_TERMS_AND_CONDITIONS: 'Télécharger la description du produit et les conditions générales',
PRODUCT_INSURANCE_STEP_ONE_TITLE: 'Protégez vôtre achat et ayez la tranquillité d\'esprit avec seulement quelques clics.',
PRODUCT_INSURANCE_STEP_TWO_TITLE: 'Quel object voulez-vous protégez ?',
PRODUCT_INSURANCE_STEP_THREE_TITLE: 'Sélectionnez votre assurance :',
PRODUCT_INSURANCE_STEP_TWO_HINT: 'Catégories de produits',
PRODUCT_INSURANCE_STEP_ONE_ONLINE: 'Dites-nous ce que vous voulez protéger.',
ENTER_MORE_DETAILS: 'Entrez plus de détails sur l\'objet que vous souhaitez assurer :',
TAKE_RECEIPT_PHOTO: 'Veuillez prendre une photo du reçu d\'achat :',
CHECK_RECEIPT: 'Veuillez vérifier si le reçu est clair sur la photo :',
CHECK_ITEM_PHOTO: 'Veuillez vérifier si l\'article est clair sur la photo :',
TAKE_ITEM_PHOTO: 'Veuillez prendre une photo de l\'article à assurer :',
BUTTON: 'Boutton',
MORE_DETAILS: 'Plus de détails',
BACK: 'Retour',
SKIP_RECEIPT: 'Sauter l\'étape',
NEXT: 'Suivant',
FROM: 'De',
GENERAL_CONDITIONS: 'Conditions générales',
LENGTH: 'Durée : ',
MINIMUM_LENGTH: 'Durée minimum : ',
MAXIMUM_LENGTH: 'Durée maximum : ',
CONTRACT_TERMINATION: 'Annulation du contrat : ',
COVERAGE_LIST: 'Couverture',
SEARCH: 'Chercher',
OTHER: 'Autre',
MORE: 'Plus...',
CLOSE: 'Fermer',
PHOTO: 'Photo',
OR: 'Ou',
OPTIONS: 'Options',
SHOW_MORE: 'Afficher plus',
DO_NOT_INSURE: 'N\'assurez pas cet article',
NOT_INSURABLE: 'Cet article n\'est pas assurable',
SELECT_ITEM_TYPE: 'Veuillez sélectionner le type d\'article',
ADD_UNRECOGNIZED_ITEM: 'Ajouter un article',
NAME: 'Nom',
PLEASE_WAIT: 'Veuillez patienter',
RECOGNIZE_PROCESSING: 'L\'application traite la ou les photos pour en extraire les informations pertinentes.',
PRICE: 'Prix',
CANCEL: 'Annuler',
OK: 'Ok',
SAVE_AND_NEXT: 'Suivant',
MY_NEW_ITEM: 'Mon nouvel article préféré',
INSURANCE_COVERAGE: 'Couverture d\'assurance :',
OPTIMALLY_INSURE: 'Assurer de manière optimale',
COVERAGE_HOME_INSURANCE_DESCRIPTION: 'Couverture dans l\'assurance habitation',
COVERAGE_DESCRIPTION: 'L\'aperçu de la couverture ci-dessous est général et se base sur un produit d\'assurance habitation suisse de base typique. Voulez-vous vérifier la couverture de votre produit d\'assurance habitation spécifique ? Cliquez ici : ',
HOME_INSURANCE: 'Sélectionner l\'assurance habitation',
INCIDENT_TYPE: 'Risque',
OUTSIDE_INSIDE: 'Couverture',
THEFT: 'Vol',
BURGLARY: 'Cambriolage',
BREAKAGE: 'Bris de glace',
ROADSIDE_ASSISTANCE: 'Assistance routière',
VISION_STRENGTH: 'Changement de l\'intensité de la vision',
// Inform Screen
DEAR_CUSTOMER: 'Chère cliente, cher client,',
TO_OFFER_YOU: 'pour vous offrir les meilleures options d\'assurance, nous avons besoin de plus d\'informations sur l\'article que vous venez d\'acheter. Vous pouvez :',
//OPTION_TAKE_A_PHOTO: 'Take a photo of the purchase receipt',
//OPTION_UPLOAD_FROM_GGALLERY: 'Upload a photo of the purchase receipt',
OPTION_TAKE_A_PHOTO: 'Prendre une photo',
OPTION_UPLOAD_FROM_GGALLERY: 'Télécharger une photo',
OPTION_ENTER_MANUALLY: 'Saisir manuellement',
OPTION_TAKE_A_PHOTO_DESCRIPTION: 'L\'accès à votre caméra peut vous être demandé.',
OPTION_UPLOAD_FROM_GGALLERY_DESCRIPTION: 'L\'accès aux photos, médias et fichiers peut vous être demandé.',
// Empty State
SORRY: 'Désolé',
SORRY_MESSAGE: 'Nous sommes désolés mais le lien a expiré. Nous serions heureux de vous faire une offre lors de votre prochain achat.',
DONE: 'Fermer',
// Payment Methods
PAYMENT_METHODS: 'Méthodes de paiement',
HOW_DO_YOU_WANT_TO_PAY: 'Quel moyen de paiement voulez-vous utilisé ?',
YOU_WILL_BE_SPENDING: '********',
FOR_BUYING_INSURANCE_POLICIES: '********',
CONFIRM_YOUR_CARD_DETAILS: 'Veuillez saisir les données de votre carte de paiement :',
HDRC_MONEY_BACK: 'Veuillez saisir les données de votre carte de paiement.',
CARDHOLDER_NAME: 'Nom du porteur de la carte',
CARD_NUMBER: 'Numéro de la carte bancaire',
EXPIRY_DATE: 'Date d\'expiration',
CVV: 'Code de vérification (CVC)',
CONFIRM_AND_PAY: 'Confirmer et acheter',
I_HAVE_SUCCESSFULLY_READ: 'J\'ai lu et j\'accepte toutes les conditions générales.',
READ_THE_FOLLOWING: 'J\'ai lu et accepté les conditions suivantes:',
TERMS_AND_CONDITIONS: 'Conditions générales',
DATA_PRIVACY: 'Règles de confidentialité des données',
ENTRY_A_FEW_MORE_DETAILS: 'Saisissez quelques informations supplémentaires vous concernant :',
FIRST_NAME: 'Prénom',
FAMILY_NAME: 'Nom',
BIRTHDAY: 'Date de naissance',
EMAIL_ADDRESS: 'Adresse e-mail',
PHONE_NUMBERS: 'Téléphone',
ADDRESS: 'Adresse',
ENTRY_HERE: 'Saisir ici',
MONTHLY: 'Par mois',
WEEKLY: 'Par semaine',
YEARLY: 'Par an',
QUARTERLY: 'Trimestriel',
OVERVIEW_YEARLY: 'Annuel',
OVERVIEW_MONTHLY: 'Mensuel',
OVERVIEW_WEEKLY: 'Hebdomadaire',
WEEKS: 'semaines',
MONTHS: 'mois',
YEARS: 'ans',
WHAT_IS_NEXT: 'Prochaine étappe:',
INSURANCE_CERTIFICATE: (email) =>  `Votre certificat d\'assurance vous vous sera créé et envoyé à ${email}.`,
YOU_CAN_DOWNLOAD: 'En plus, vous pouvez télécharger votre certificat d\'assurance à la page suivante',
DEDUCTIBLE: 'Franchise: ',
// validation
MANDATORY_FIELD: 'Ce champ est obligatoire',
INVALID_FORMAT: 'Valeur invalide',
PRICE_SHOULD_BE_LOWER: 'Le prix doit être inférieur à la valeur de la transaction',
DATE_SHOULD_BE_PAST: 'La date doit être antérieure',
TWO_DECIMAL_PLACES: 'Assurez-vous qu\'il n\'y a pas plus de 2 décimales.',
// Congratulations!
CONGRATULATIONS: 'Félicitations!!',
GLAD_TO_INFORM_YOU: 'Nous sommes heureux de vous informer que vous avez souscrit avec succès à cette assurance. Votre achat est maintenant protégé.',
YOU_WILL_RECEIVE: 'Vous recevrez dans les prochaines minutes un E-mail de confirmation avec le certificat d\'assurance',
YOU_ARE_PROTECTED: 'Vous êtes protégé',
ALREADY_PURCHASED: 'Nous avons le plaisir de vous informer que vous avez déjà souscrit une assurance et que votre objet préféré est déjà protégé.',
DOWNLOAD: 'Télécharger',
YOU_CAN_DOWNLOAD_HERE: 'Vous pouvez télécharger votre certificat d\'assurance ici :',
BACK_TO_OVERVIEW: 'retour à l\'aperçu',
// contracts list
YOUR_POLICY_PURCHASES: 'Vos assurances',
PURCHASED_ON: 'Acheté le',
EXPIRING_ON: 'Expirant le',
WHATS_COVERED: 'Ce qu\'il faut faire',
FILE_A_CLAIM: 'faire une reclamation',
REACTIVATE: 'Réactiver',
// contract details
DOWNLOAD_PURCHASE_RECEIPT: 'Télécharger preuve d\'achat',
INSURCANCE_CONTRACT: 'Contrat d\'assurance',
ITEM_IS_PROTECTED_BY_INSURANCE: 'L\'article est protégé par l\'assurance',
INSURANCE_COVERAGE_START: 'Début de la couverture d\'assurance',
INSURANCE_COVERAGE_UNTIL: 'Couverture d\'assurance jusqu\'à',
DOWNLOAD_PRODUCT_SHEET: 'Télécharger la fiche produit',
DOWNLOAD_INSURANCE_CERTIFICATE: 'Télécharger le certificat',
CANCEL_CONTRACT: 'Annuler l\'assurance',
// claim form
PROVIDE_INFO_FOR_CLAIM: 'Veuillez nous fournir quelques informations supplémentaires avant que nous traitions votre demande',
ATTACH_PHOTO_VIDEO: 'Veuillez joindre, le cas échéant, des photos ou des vidéos montrant les dommages.',
DESCRIBE_WHAT_HAPPEND: 'Veuillez décrire ce qui s\'est passé',
ENTER_HERE: 'Saisir ici',
SUBMIT_CLAIM: 'Faire un réclamation',
PLEASE_ATTACH_FILES: 'Veuillez joindre des fichiers',
THANKS_FOR_REQUEST: 'Merci d\'avoir soumis votre demande. Nous sommes heureux de la traiter et de vous fournir une réponse dans les prochains jours.',
YOUR_INSURANCE_COVERS: 'Votre assurance couvre la réparation par l\'un de nos partenaires certifiés uniquement. Vous pouvez trouver ici un atelier de réparation à proximité de chez vous.',
SHOW_PARTNER_NETWORK: 'Afficher le réseau de partenaires',
// confirmation
YOUR_CONTRACT_HAS_BEEN_CANCELLED: 'Votre assurance a été annulée. Vous recevrez bientôt un courriel de confirmation',
GOT_IT: 'Compris',
THE_INSURANCE_WILL_BE_CANCELLED: 'L\'assurance sera annulée à partir de',
ARE_YOU_SURE_TO_CANCEL: 'Êtes-vous sûr de vouloir annuler la protection de votre',
RETURN: 'Retour',
CONFIRM_REACTIVATION: 'Veuillez confirmer que vous souhaitez réactiver l\'assurance.',
YOUR_CONTRACT_HAS_BEEN_REACTIVATED: 'Votre assurance a été réactivée. Vous recevrez bientôt un courrier de confirmation.',
// contract statuses
ACTIVE: 'actif',
PENDING_CANCELLATION: 'annulé',
VOID: 'résilié',
// issuer application
// login
PASSWORD: 'Mot de passe',
FORGOT_PASSWORD: 'Oublié le mot de passe ?',
LOGIN: 'Connexion',
NO_ACCOUNT_ERR: 'Adresse e-mail ou mot de passe invalide',
NO_EMAIL_ERR: 'Adresse e-mail invalide',
MISMATCH_ERR: 'Les mots de passe doivent correspondre',
//forgot password
FORGOT_YOUR_PASSWORD: 'Vous avez oublié votre mot de passe ?',
ENTER_THE_EMAIL: 'Veuillez saisir l\'adresse électronique associée à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.',
RESET_PASSWORD: 'Réinitialiser le mot de passe',
PASSWORD_RESET_EMAIL: 'L\'e-mail de réinitialisation du mot de passe a été envoyé',
EMAIL_SENT: 'Si l\'adresse électronique saisie correspond à un utilisateur enregistré actif, le système a déjà envoyé un courrier électronique à l\'adresse saisie.',
REPEAT_PASSWORD: 'Répéter le mot de passe',
RESET: 'Réinitialiser',
PASSWORD_RESET: 'Réinitialisation du mot de passe',
//sidebar
//sections
USAGE_ANALYSIS: 'Analyse de l\'utilisation',
SETTINGS: 'Paramètres',
ACCOUNT_ADMIN: 'Compte Admin',
//links
DASHBOARD: 'Tableau de bord',
ANALYTICS: 'Analyse',
GENERAL_SETTINGS: 'Paramètres généraux',
PAYMENT_PRODUCTS: 'Produits de paiement',
WHITE_LABELING: 'Marque blanche',
USERS_MANAGEMENT: 'Gestion des utilisateurs',
INTEGRATION_SETTING: 'Paramètres d\'intégration',
//general settings
OFFERING_TEXT_ON_PURCHASE: 'Offre',
ADD_AN_OFFERING: '+ Ajouter un nouveau groupe d\'articles',
INSURANCE_CATALOGUE: 'Catalogue d\'assurance',
INSURANCE_TYPE: 'Type d\'assurance',
INSURANCE_PRODUCT: 'Produit d\'assurance',
OTHER_SETTINGS: 'Autres paramètres',
CI_CD_SETTING: 'Paramètres CI/CD',
ADD_OFFER_TEXT: '+ Ajouter le texte de l\'offre',
EDIT: 'Modifier',
DELETE: 'Supprimer',
SAVE: 'Sauvegarder',
OFFERING: 'Offre',
OFFER_TEXT: 'Texte de l\'offre',
TRAFFIC_ALLOCATION: 'Attribution du trafic',
ALLOCATION: 'Attribution',
TEMPLATE_TEXT: 'Texte du modèle',
SUM_ERR: 'Le résumé de l\'allocation pour le modèle d\'allocation doit être égal à 100%.',
MAPPING_EXISTS: 'L\'offre dans la langue sélectionnée existe déjà',
OFFERING_ERR: 'Le texte de l\'offre ne doit pas être dupliqué',
EMPTY_FIELD_ERR: 'Veuillez remplir ce champ',
LANGUAGE: 'Langue',
GENDERS: 'Homme',
GROUP_ITEMS: 'Groupe d\'articles',
MIN_AGE_VS_MAX_AGE: 'L\'âge maximum doit être supérieur à l\'âge minimum',
// white labels
MY_WHITE_LABELS: 'Mes étiquettes blanches',
WHITE_LABELS: 'Marques blanches',
ADD_WHITE_LABEL: '+ Ajouter un label blanc',
LOGO: 'Logo',
COLOUR_SCHEME: 'Schéma de couleurs',
SHOW_INSURER_LOGO: 'Afficher le logo de l\'assureur',
NO_LOGO_ERR: 'Le logo est obligatoire',
//payment products
ADD_A_PAYMENT_PRODUCT: '+ Ajouter un produit de paiement',
PAYMENT_PRODUCT: 'Produit de paiement',
PAYMENT_TYPE: 'Type de paiement',
PAYMENT_SCHEME: 'Schéma de paiement',
PAYMENT_SUBSCHEME: 'Sous-régime de paiement',
PAYMENT_PRODUCTS_PAGE: 'Produits de paiement',
PAYMENT_PRODUCT_SETTINGS: 'Paramètres des produits de paiement',
EDIT_PAYMENT_PRODUCT: 'Modifier un produit de paiement',
CREATE_PAYMENT_PRODUCT: 'Créer un produit de paiement',
PRODUCT_ID: 'Identifiant du produit de paiement',
USE_GENERAL_SETTINGS: 'Utiliser les paramètres généraux',
OFFER_MANAGEMENT: 'Gestion des offres',
PORTFOLIO_OVERVIEW: 'Aperçu du portefeuille',
PORTFOLIO_DEVELOPMENT: 'Développement du portefeuille',
PORTFOLIO_LEADS: 'Développement des prospects et des offres',
BENEFICIARY_TITLE: 'Qui doit être couvert par votre assurance ?',
BENEFICIARY_QUESTION: 'Qui souhaitez-vous couvrir ?',
BENEFICIARY_OPTION1: 'moi-même',
BENEFICIARY_OPTION2: 'moi et ma famille/ménage'
}

export default fr;
