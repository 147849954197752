import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    button: {
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        borderRadius: 5,
        textTransform: 'none',
        height: 44,
        fontSize: 16,
        fontWeight: 'normal',
        justifyContent: 'flex-start',
    },
    contained: {
        color: theme.palette.main.light,
        backgroundColor: theme.palette.primary.main,
        boxShadow: '3px 3px 8px 0 rgba(0,0,0,0.21)',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    text: {
        color: theme.palette.primary.main,
    },
    small: {
        minWidth: 150,
        padding: '14px',
        '&.outlined': {
            minWidth: 100,
        }
    },
    large: {
        minWidth: 250,
        padding: '14px 50px',
    },
}));

const CustomButton = (
    {
        children,
        onClick,
        size = 'small',
        type = 'button',
        variant = 'contained',
        className = null
    }) => {
    const classes = useStyles();
    return (
        <Button
            className={`${classes.button} ${classes[variant]} ${classes[size]} ${className}`}
            onClick={onClick}
            variant={variant}
            type={type}
        >
            {children}
        </Button>
    );
};


export default CustomButton;
