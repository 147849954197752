import React, { useState, useEffect, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements
} from '@stripe/react-stripe-js';
import { changeAttribute, paymentInitiation } from '../../../../helpers/amplitude';
import {
    selectWhiteLabelingTitleColor
} from '../../../../store/selectors';
import {
    submitPayment,
    submitSecurePayment
} from '../../../../store/payment/payment.actions';
/* import {
    getPaymentStatusFromProfile
} from '../../../../store/common/common.actions'; */

const StripeForm = React.forwardRef(({
    uuid,
    label,
    errorCallback,
    paymentCallback
}, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const whiteLabelingTitleColor = useSelector(selectWhiteLabelingTitleColor);

    const [paymentSettings, setPaymentSettings] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        errorCallback({
            cardCvc: false,
            cardExpiry: false,
            cardNumber: false });
    }, []);

    const createOptions = (config = {}) => ({
        style: {
            base: {
                fontSize: '16px',
                color: whiteLabelingTitleColor,
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
                fontWeight: '500',
            },
            invalid: {
                color: '#c23d4b',
            },
        },
        ...config
    });

    const handleSubmit = async () => {
        debugger;
        if (!stripe || !elements) {
            return;
        }
        const cardElement = elements.getElement('cardNumber');
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });
        // dispatch(getPaymentStatusFromProfile());
        if (!error) {
            setPaymentSettings({ loading: true, id: paymentMethod.id, error });
        }
    };

    useEffect(() => {
        if (paymentSettings.loading) {
            debugger;
            paymentInitiation({ uuid });
            try {
                dispatch(submitPayment(paymentSettings.id))
                    .then(() => {
                        paymentCallback();
                    })
                    .catch((err) => {
                        // eslint-disable-next-line camelcase
                        if (err.error === 'manual_action_required') {
                            stripe.handleCardAction(
                                err.payment_intent_client_secret
                            )
                                .then((response) => {
                                    if (response.error) {
                                        setErrorMessage(response.error.message);
                                    } else {
                                        dispatch(submitSecurePayment(
                                            response.paymentIntent.payment_method,
                                            response.paymentIntent.id
                                        )).then(() => {
                                            paymentCallback();
                                        });
                                    }
                                });
                        } else {
                            setErrorMessage(err.error);
                        }
                        setPaymentSettings(prev => ({ ...prev, loading: false }));
                        return Promise.reject(paymentSettings.error);
                    });
            } catch (err) {
                setPaymentSettings({ loading: false });
            }
        }
    }, [paymentSettings.loading]);

    useImperativeHandle(ref, () => ({ handleSubmit }));

    const handleCardChange = ({ error, elementType, complete }) => {
        changeAttribute({ uuid, attribute: elementType, position: 2 });
        const cardData = {};
        cardData[elementType] = complete;
        errorCallback(cardData);
        setErrorMessage((error && error.message) || '');
    };

    return <div ref={ref}>
        <p className="card-info-title">{label}</p>
        <div className="stripe-form">
            <div className="split-form">
                <CardNumberElement
                    options={{ placeholder: '1234 1234 1234 1234', ...createOptions({ showIcon: true }) }}
                    onChange={handleCardChange}
                    className="card-number"
                />
            </div>
            <div className="split-form extea-card-info">
                <CardExpiryElement
                    options={createOptions()}
                    onChange={handleCardChange}
                    placeholder="MM/YY"
                    className="expiration-date"
                />
                <CardCvcElement
                    options={createOptions()}
                    onChange={handleCardChange}
                    placeholder=""
                    className="cvc" />
            </div>
        </div>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <div className="error" role="alert">{errorMessage}</div>
        {/* {(tags.length && tags.indexOf('iban_requested') > -1) ?
        } */}
    </div>;
});

export default StripeForm;
