import React from 'react';
import {
    useHistory
} from 'react-router-dom';

import Tabs from './Common/Tabs/Tabs';

const MerchantsSkeleton = ({ children, title = '', subtitle = '', tabs = [], onTabClick = () => {}, activeTab = 0, nav, separate, parentLink }) => {
    const history = useHistory();

    const showParentProfile = (e) => {
        e.stopPropagation();
        if (parentLink) {
            history.push(parentLink);
        }
    };

    return <>
        <header>{title}</header>
        {
            subtitle ? <p className="merchants-subtitle" onClick={e => showParentProfile(e)}>(
                {subtitle}
                )</p> : null
        }
        <div className="merchants-section">
            {
                tabs.length && <Tabs tabs={tabs} onTabClick={onTabClick} activeTab={activeTab} />
            }
            {
                separate ?
                    React.cloneElement(children, { nav }) :
                    <div className="merchants-section_card">{React.cloneElement(children, { nav })}</div>
            }

        </div>
    </>;
};

export default MerchantsSkeleton;
