import React, { useState, useEffect } from 'react';
import InputWrap from '../../../Common/Input/InputWrap';
import { changeAttribute } from '../../../../helpers/amplitude';

const CardHolder = ({ label, holder, uuid }) => {
    const [cardHolder, setCardHolder] = useState();

    useEffect(() => setCardHolder(holder || ''), []);

    const handleHolderChange = (h) => {
        changeAttribute({ uuid, attribute: 'card_name', position: 2 });
        setCardHolder(h);
    };

    return <InputWrap
        className="cardholder-name"
        type="text"
        value={cardHolder}
        onChange={handleHolderChange}
        lable={label} />;
};

export default CardHolder;
