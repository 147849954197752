const data = {
    insurance_contracts: { amount: 62434 },
    annualized_premiums: {
        currency: 'CHF',
        total: 2400434,
        percentage: 4,
        amount: 323444
    },
    new_contracts: {
        amount: 2344,
        percentage: 12
    },
    canceled_contracts: {
        amount: 344,
        percentage: 3
    },
    leads: { amount: 434349 },
    initial_offer_made: {
        conversion: {
            total: 60,
            previous: 30
        },
        amount: 122124
    },
    offer_clicked: {
        conversion: {
            total: 40,
            previous: 20
        },
        amount: 47940
    },
    purchased: {
        conversion: {
            total: 10,
            previous: 20
        },
        amount: 7612
    }
};

export default data;
