import React, { useState, useEffect } from 'react';

import InputWrap from '../../../Common/Input/InputWrap';

// import returnAddressComponent from './helpers';

const AddressForm = ({ fields, data, handleInputChange, errorCallback, country }) => {
    const [error, setError] = useState({});
    const [initial, setInitial] = useState(true);

    useEffect(() => {
        if (initial) {
            const err = {};
            fields.map(async ({ attribute, validator }) => {
                if (validator) {
                    err[attribute] = await validator(data[attribute]).code;
                }
                return attribute;
            });
            setError(err);
        }
    }, [data.address, initial]);

    const handleChange = async (v, { attribute, validator = { code: '' } }) => {
        if (initial) {
            setInitial(false);
        }
        handleInputChange(v, attribute);
        const { city, code, state } = validator ? await validator(v, country) : validator;
        if (attribute === 'zip' && city && state) {
            handleInputChange(city, 'city');
            handleInputChange(state, 'administrative_area_level_2');
        }
        setError(prev => ({ ...prev, city: attribute === 'zip' && city ? '' : prev.city, [attribute]: code }));
    };

    useEffect(() => {
        const keys = Object.keys(error);
        if (!keys.length) {
            return;
        }
        const status = keys
            .reduce((prev, curr) =>
                ({ ...prev, [curr]: !error[curr] }),
            {});
        errorCallback(status);
    }, [error]);

    return <div className="address-form">
        {
            fields.map((item, key) => <InputWrap
                key={key}
                className="discovered-input"
                containerClass={item.attribute}
                type={item.type}
                lable={item.label}
                value={data[item.attribute]}
                placeholder={item.placeholder}
                error={error[item.attribute]}
                onChange={(v) => { handleChange(v, item); }} />)
        }
    </div>;
};
export default AddressForm;
