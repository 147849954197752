import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
// import { handleUserClick } from './helpers/amplitude';
import rootReducer from './store/rootReducer';

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

// set correct value for mobile screens
const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

Sentry.init({
    dsn: 'https://37fc4dfde6cd4de6ba6c4014299487a0@o1267642.ingest.sentry.io/6718969',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
});

/* window.onload = () => {
    document.addEventListener('click', handleUserClick);
}; */

const app = (
    <Provider store={store}>
        <App store={store} />
    </Provider>
);

render(
    app,
    document.getElementById('root')
);
