import React from 'react';

import Button from '../Common/Button/Button';
import { usePrimaryColor, useLanguage } from '../../helpers/customHooks';
import { messages } from '../../constants/messages';
import CheckBoxSet from '../ProductInsurance/HelvetiaAttributes/CheckBoxSet';
import './modal.scss';

const Modal = ({ shown, data, onCheck, onClose }) => {
    useLanguage();
    const backgroundColor = usePrimaryColor(0.2);
    if (!shown) {
        return null;
    }
    return (
        <div className="modal_wrapper" style={{ backgroundColor }}>
            <div className="modal_wrapper_body">
                <div>
                    {
                        messages.CONSENT_OPTIONS.map((m, i) =>
                            <div key={i}>
                                <p>{m}</p>
                                <CheckBoxSet
                                    onChange={(v) => { onCheck(i, v); }}
                                    data={data[i]}
                                    labels={[messages.YES, messages.NO]} />
                            </div>
                        )
                    }
                    <Button
                        disabled={data.some(v => v === undefined)}
                        className="modal_wrapper_body_btn"
                        onClick={data.every(v => v !== undefined) && onClose}>
                        {messages.NEXT}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
