const generateArray = (min, max) =>
    [...Array(max - min + 1).keys()].map((_, i) => i + min);

export const offer_fields = {
    offer_text: {
        title: 'Offer text',
    },
    offered_products: {
        title: 'Offered services',
        options: []
    },
    delay: {
        title: 'Delay',
        unit: 'h'
    },
    reminder: {
        title: 'Reminder',
        options: [{
            value: true, label: 'Yes'
        }, {
            value: false, label: 'No'
        }]
    },
    channel: {
        title: 'Channel',
        options: [{
            value: 'App', label: 'App'
        }, {
            value: 'SMS', label: 'SMS'
        }, {
            value: 'Whatsapp', label: 'Whatsapp'
        }, {
            value: 'Email', label: 'Email'
        }, {
            value: 'Service Center', label: 'Service Center'
        }]
    },
    coverage_check_enabled: {
        title: 'Coverage check',
        options: [{
            value: true, label: 'Yes'
        }, {
            value: false, label: 'No'
        }]
    },
    item_type_specific: {
        title: 'Item specific offer',
        options: [{
            value: true, label: 'Yes'
        }, {
            value: false, label: 'No'
        }]
    },
    allocation: {
        title: 'Traffic allocation',
        unit: '%'
    }
};

export const segment_fields = {
    culture: {
        title: 'Language',
        isMandatory: true,
        options: []
    },
    customer_countries: {
        title: 'Country',
        isMandatory: true,
        options: []
    },
    customer_regions: {
        title: 'Region',
        isMandatory: false
    },
    customer_types: {
        title: 'Customer type',
        isMandatory: false,
        options: [{
            label: 'Private', value: 'PRIVATE'
        }, {
            label: 'Business', value: 'BUSINESS'
        }]
    },
    customer_education_levels: {
        title: 'Education level',
        isMandatory: false,
        options: [{
            label: 'Undefined', value: 'UNDEFINED'
        }, {
            label: 'Primary school degree', value: 'PRIMARY_SCHOOL_DEGREE'
        }, {
            label: 'High school degree', value: 'HIGH_SCHOOL_DEGREE'
        }, {
            label: 'Professional training', value: 'PROFESSIONAL_TRAINING'
        }, {
            label: 'Advanced professional training', value: 'ADVANCED_PROFESSIONAL_TRAINING'
        }, {
            label: 'Academic degree', value: 'ACADEMIC_DEGREE'
        }, {
            label: 'Post-Academic degree', value: 'POST_ACADEMIC_DEGREE'
        }]
    },
    customer_financial_scores: {
        title: 'Income level',
        isMandatory: false,
        options: generateArray(1, 10).map(i => ({ label: i, value: i }))
    },
    customer_employment_types: {
        title: 'Employment type',
        isMandatory: false,
        options: [{
            label: 'Undefined', value: 'UNDEFINED'
        }, {
            label: 'Jobless', value: 'JOBLESS'
        }, {
            label: 'Student', value: 'STUDENT'
        }, {
            label: 'Self-employed', value: 'SELF_EMPLOYED'
        }, {
            label: 'Temporary employment contract', value: 'TEMPORARY_CONTRACT'
        }, {
            label: 'Seasonal employee', value: 'SEASONAL'
        }, {
            label: 'Regular employment contract', value: 'REGULAR_CONTRACT'
        }]
    },
    customer_housing_situations: {
        title: 'Housing situation',
        isMandatory: false,
        options: [{
            label: 'Undefined', value: 'UNDEFINED'
        }, {
            label: 'Renter', value: 'RENTER'
        }, {
            label: 'Owner', value: 'OWNER'
        }]
    },
    address_since: { title: 'Same address since', add: '(in months)', isMandatory: false, unit: 'months' },
    age_group_codes: { title: 'Age', isMandatory: false, unit: 'years' },
    genders: {
        title: 'Gender',
        isMandatory: false,
        options: [{
            value: 'MALE', label: 'Male'
        }, {
            value: 'FEMALE', label: 'Female'
        }, {
            value: 'OTHER', label: 'Other'
        }]
    },
    customer_professional_activities: {
        title: 'Professional activity',
        isMandatory: false,
        options: [{
            label: 'Construction',
            value: 'CONSTRUCTION'
        }, {
            label: 'Public administration',
            value: 'PUBLIC_ADMINISTRATION'
        }, {
            label: 'Domestic or personal services',
            value: 'DOMESTIC_OR_PERSONAL_SERVICES'
        }, {
            label: 'Health / Veterinary services',
            value: 'HEALTH_VETERINARY_SERVICES'
        }, {
            label: 'Non-productive activity',
            value: 'NON_PRODUCTIVE_ACTIVITY'
        }, {
            label: 'Agriculture and hunting',
            value: 'AGRICULTURE_AND_HUNTING'
        }, {
            label: 'Food and tobacco',
            value: 'FOOD_AND_TOBACCO'
        }, {
            label: 'Trade / Hotels and restaurants',
            value: 'TRADE_HOTELS_AND_RESTAURANTS',
        }, {
            label: 'Education',
            value: 'EDUCATION'
        }, {
            label: 'Machinery / Engineering / Mechanics',
            value: 'MACHINERY_ENGINEERING_MECHANICS',
        }, {
            label: 'IT / Services',
            value: 'IT_SERVICES',
        }, {
            label: 'Land transport',
            value: 'LAND_TRANSPORT'
        }, {
            label: 'Fishing',
            value: 'FISHING',
        }, {
            label: 'Textile / Wood / Furniture',
            value: 'TEXTILE_WOOD_FURNITURE',
        }, {
            label: 'Metallurgy / Iron and steel',
            value: 'METALLURGY_IRON_AND_STEEL',
        }, {
            label: 'Energy and water',
            value: 'ENERGY_AND_WATER',
        }, {
            label: 'Electrical and electronics manufacturing',
            value: 'ELECTRICAL_AND_ELECTRONICS_MANUFACTURING'
        }, {
            label: 'Banking / Finance / Insurance',
            value: 'BANKING_FINANCE_INSURANCE'
        }, {
            label: 'Financial and business services',
            value: 'FINANCIAL_AND_BUSINESS_SERVICES'
        }, {
            label: 'Leather / Footwear / Apparel',
            value: 'LEATHER_FOOTWEAR_APPAREL'
        }, {
            label: 'Military / Police',
            value: 'MILITARY_POLICE'
        }, {
            label: 'Shipping / Waterway / Rail Transport',
            value: 'SHIPPING_WATERWAY_RAIL_TRANSPORT'
        }, {
            label: 'Mining',
            value: 'MINING'
        }, {
            label: 'Paper and printing',
            value: 'PAPER_AND_PRINTING'
        }, {
            label: 'Vehicle repair',
            value: 'VEHICLE_REPAIR'
        }, {
            label: 'Entertainment / Sports',
            value: 'Entertainment / Sports'
        }, {
            label: 'Air transport',
            value: 'AIR_TRANSPORT'
        }, {
            label: 'Furniture/real estate rental',
            value: 'FURNITURE_REAL_ESTATE_RENTAL'
        }, {
            label: 'Communication (Post - CTNE)',
            value: 'COMMUNICATION_POST_CTNE'
        }, {
            label: 'Press / Radio / TV',
            value: 'PRESS_RADIO_TV'
        }, {
            label: 'Travel agencies',
            value: 'TRAVEL_AGENCIES'
        }, {
            label: 'Diplomatic / International organisation',
            value: 'DIPLOMATIC_INTERNATIONAL_ORGANISATION'
        }]
    },
    customer_martial_statuses: {
        title: 'Martial status',
        isMandatory: false,
        options: [{
            label: 'Undefined', value: 'UNDEFINED'
        }, {
            label: 'Single', value: 'SINGLE'
        }, {
            label: 'Married', value: 'MARRIED'
        }, {
            label: 'Divorced', value: 'DIVORCED'
        }, {
            label: 'Widowed', value: 'WINDOWED'
        }]
    },
    customer_segments: {
        title: 'Customer segment',
        options: [{
            label: 'A', value: 'A'
        }, {
            label: 'B', value: 'B'
        }, {
            label: 'C', value: 'C'
        }, {
            label: 'D', value: 'D'
        }, {
            label: 'E', value: 'E'
        }, {
            label: 'F', value: 'F'
        }, {
            label: 'G', value: 'G'
        }]
    }
};

export const trigger_fields = {
    transaction: {
        title: 'Transaction',
        fields: {
            transaction_category: {
                title: 'Category',
                isMandatory: true,
                options: [{ label: 'Payment', value: 'PAYMENT' }]
            },
            transaction_types: {
                title: 'Type',
                options: []
            },
            pos_environments: {
                title: 'PoS environment',
                options: []
            },
            transaction_amount: {
                title: 'Transaction amount'
            }
        }
    },
    merchant: {
        title: 'Merchant',
        fields: {
            offer_ins_at_pos: {
                title: 'Offer insurance at PoS',
                options: [{
                    value: true, label: 'Yes'
                }, {
                    value: false, label: 'No'
                }]
            },
            tags: {
                title: 'Tags',
                options: [
                    { value: 'Online shop', label: 'Online shop' },
                    { value: 'Premium', label: 'Premium' },
                    { value: 'Discount', label: 'Discount' },
                    { value: 'Large_chain', label: 'Large_chain' },
                    { value: 'Individual', label: 'Individual' },
                    { value: 'CH-CrossBorderLocation', label: 'CH-CrossBorderLocation' },
                    { value: 'Sustainable', label: 'Sustainable' }
                ]
            },
            target_demographics: {
                title: 'Target demographics',
                options: [
                    { value: 'Teenager (m)', label: 'Teenager (m)' },
                    { value: 'Teenager (f)', label: 'Teenager (f)' },
                    { value: 'Young adult (m)', label: 'Young adult (m)' },
                    { value: 'Young adult (f)', label: 'Young adult (f)' },
                    { value: 'Adult (m)', label: 'Adult (m)' },
                    { value: 'Adult (f)', label: 'Adult (f)' },
                    { value: 'Senior (m)', label: 'Senior (m)' },
                    { value: 'Senior (f)', label: 'Senior (f)' },
                    { value: 'Urban & trendy', label: 'Urban & trendy' },
                    { value: 'Hippster', label: 'Hippster' }
                ]
            },
            merchant_group: {
                title: 'Merchant group',
                options: [
                    { value: 'Ikea', label: 'Ikea' },
                    { value: 'McDonald', label: 'McDonald' },
                    { value: 'Shell', label: 'Shell' },
                    { value: 'Metro', label: 'Metro' },
                    { value: 'Migros', label: 'Migros' },
                    { value: 'Coop', label: 'Coop' },
                    { value: 'Carrefour', label: 'Carrefour' },
                    { value: 'Auchan', label: 'Auchan' },
                    { value: 'Louis Vuitton', label: 'Louis Vuitton' },
                    { value: 'Dior', label: 'Dior' },
                    { value: 'LHMV', label: 'LHMV' },
                    { value: 'Herz', label: 'Herz' },
                    { value: 'Europcar', label: 'Europcar' },
                    { value: 'Avis', label: 'Avis' }
                ]
            },
            merchant_country: {
                title: 'Merchant country',
                options: [
                    { value: 'Switzerland', label: 'Switzerland' },
                    { value: 'Italy', label: 'Italy' },
                    { value: 'Germany', label: 'Germany' },
                    { value: 'France', label: 'France' },
                    { value: 'UK', label: 'UK' },
                ]
            },
            same_country: {
                title: 'Same country as customer',
                options: [{
                    value: true, label: 'Yes'
                }, {
                    value: false, label: 'No'
                }]
            },
            /* distance: {
                title: 'Distance between',
                unit: 'km'
            }, */
            merchant_category: {
                title: 'Merchant category',
                options: [
                    { value: 'Sporting goods', label: 'Sporting goods' },
                    { value: 'Sporting goods\\bicycle', label: 'Sporting goods\\bicycle' },
                    { value: 'Furniture', label: 'Furniture' },
                    { value: 'Health', label: 'Health' },
                    { value: 'Health\\Optician', label: 'Health\\Optician' },
                    { value: 'Fashion', label: 'Fashion' },
                    { value: 'Fashion\\jewellery', label: 'Fashion\\jewellery' },
                ]
            }
        } },
    item_types: {
        title: 'Item type(s)',
        field: 'item_types',
        options: []
    }
};

export default offer_fields;
