import React from 'react';
import {
    ListItem, ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    sectionTitleText: {
        cursor: 'default',
        flexDirection: 'column',
        alignItems: 'start',
        fontWeight: 'bold',
        fontSize: 20,
        color: theme.palette.main.contrastText,
    },
}));

const ListSection = ({ title, children }) => {
    const classes = useStyles();

    return (
        <div className="sidebar__section">
            <ListItem>
                <ListItemText
                    primary={title}
                    classes={{
                        primary: classes.sectionTitleText,
                    }}
                />
            </ListItem>
            {children}
        </div>
    );
};
export default ListSection;
