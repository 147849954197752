import React, { useEffect, useState, forwardRef } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import moment from 'moment';
import classNames from 'classnames';
import MomentUtils from '@date-io/moment';
import { messages } from '../../../../../constants/messages';

import 'moment/locale/de';
import 'moment/locale/de-ch';
import 'moment/locale/en-gb';
import 'moment/locale/it';
import 'moment/locale/it-ch';
import 'moment/locale/hr';
import 'moment/locale/es';
import 'moment/locale/pl';
import 'moment/locale/fr-ch';
import 'moment/locale/fr';
import 'moment/locale/sv';
import 'moment/locale/fi';

import './inputDatepicker.scss';

const BACKEND_FORMAT = 'yyyy-MM-DD';
const UI_FORMAT = 'DD.MM.yyyy';
const localeMap = {
    es: 'es',
    en: 'en',
    'de-de': 'de',
    'es-es': 'es',
    'fi-fi': 'fi',
    'sv-sv': 'sv',
    'sv-fi': 'sv'
};

const InputDatepicker = ({ lang, cn,
    onInputChange,
    label = false,
    dateValue,
    validStatus = null }) => {
    const [startDate, setStartDate] = useState(null);
    const [isOpen, setOpen] = useState(false);
    const [error, setError] = useState(null);

    const onValidate = (date) => {
        const today = moment();
        const data = moment(date, UI_FORMAT);
        if (date) {
            setError(today <= data ? messages.INVALID_FORMAT : '');
        }
    };

    useEffect(() => {
        validStatus(!error);
    }, [error]);

    useEffect(() => {
        onValidate(dateValue);
        if (dateValue) {
            const momentDate = moment(dateValue, BACKEND_FORMAT);
            setStartDate(momentDate);
        }
        validStatus(!!dateValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateValue]);

    useEffect(() => {
        const locale = localeMap[lang] || lang;
        moment.locale(locale);
    }, [lang]);

    const onClick = () => {
        if (!isOpen) {
            setOpen(true);
            console.log(open);
        }
    };

    const handleDateChange = (value) => {
        setError('');
        if (!value) {
            setStartDate(value);
            onInputChange('');
            return;
        }

        if (value.isValid()) {
            onInputChange(value.format(BACKEND_FORMAT));
            setStartDate(value);
        }

        if (!value.isValid()) {
            setError(messages.INVALID_FORMAT);
        }

        validStatus(value.isValid());
    };

    const locale = localeMap[lang] || lang;

    const onClose = () => {
        setOpen(false);
    };

    const materialTheme = createMuiTheme({
        overrides: {
            MuiPickersDay: {
                daySelected: {
                    backgroundColor: '#E0BD6F',
                },
            },
            MuiTypography: {
                colorPrimary: {
                    color: '#E0BD6F !important'
                } }
        }
    });

    return (
        <>
            <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
                    <div className={classNames('input-datepicker-wrapper broker', error && 'error-state', cn)}>
                        <div>
                            <KeyboardDatePicker
                                mask="__.__.____"
                                disableFuture={false}
                                disableToolbar
                                variant="inline"
                                openTo="year"
                                format={UI_FORMAT}
                                label={label}
                                views={['year', 'month', 'date']}
                                value={startDate}
                                onChange={handleDateChange}
                                allowKeyboardControl={false}
                                onClick={onClick}
                                autoOk
                                orientation="landscape"
                                /* initialFocusedDate={moment()} */
                                PopoverProps={{
                                    anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'center',
                                    },
                                    transformOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'center'
                                    },
                                    /* classes: {
                                        paper: `offset-${offset}`
                                    } */
                                }}
                                open={isOpen}
                                onClose={onClose}
                            />
                        </div>
                        {!!error && <p className="error-text">{error}</p> }
                    </div>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </>
    );
};

export default forwardRef(InputDatepicker);
