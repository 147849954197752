// TODO: to delete comments
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../Header';
import Footer from '../../Common/Footer/Footer';
import CheckBoxField from '../../Common/Checkbox/CheckBoxField';
import Expander from '../../Common/Expander/Expander';
import {
    setStep,
    setCurrentGoodsId,
    saveItemType
} from '../../../store/productInsurance/productInsurance.actions';
// import { STEPS } from '../../../constants/constants';
import { /* setTargetPhoto, */ setCurrentStepNumber } from '../../../store/common/common.actions';
import { useLanguage } from '../../../helpers/customHooks';
import {
    selectCurrentProduct,
    selectCurrentGoodsId,
    // selectProfile,
    selectMode,
    selectInsuranceTypes,
    selectInsuranceCategories,
    selectCurrentStepNumber, selectBreadcrumbs, selectProfile,
} from '../../../store/selectors';
import { messages } from '../../../constants/messages';
import './ProductsList.scss';
import UnqualifiedItemTypes from '../UnqualifiedItemTypes/UnqualifiedItemTypes';
import Coverage from '../Coverage/Coverage';
import { itemTypeSelection, nextPageNavigation, previousPageNavigation } from '../../../helpers/amplitude';

const ProductsList = () => {
    const dispatch = useDispatch();
    const [openMore, setOpenMore] = useState(false);
    const currentProduct = useSelector(selectCurrentProduct);
    const mode = useSelector(selectMode);
    const currentGoodsId = useSelector(selectCurrentGoodsId);
    useLanguage();
    const iTypes = useSelector(selectInsuranceTypes);
    const [itemTypes, setItemTypes] = useState(iTypes);
    const categories = useSelector(selectInsuranceCategories);
    const areCategories = categories.reduce((res, category) => res + category.item_types.length, 0);
    const { lead_uuid } = useSelector(selectProfile);

    const currentStepNumber = useSelector(selectCurrentStepNumber);
    const breadcrumbs = useSelector(selectBreadcrumbs);

    useEffect(() => {
        setItemTypes(iTypes);
    }, [iTypes, currentGoodsId]);

    const onSelectItemType = (id) => {
        itemTypeSelection(lead_uuid);
        dispatch(setCurrentGoodsId(id));
    };

    const saveAndNavigateNext = () => {
        dispatch(saveItemType())
            .then((flow) => {
                const { step } = flow[1];
                dispatch(setCurrentStepNumber(1));
                nextPageNavigation({ page: step, uuid: lead_uuid });
                dispatch(setStep(step));
            });
    };

    const onNextStep = () => {
        if (isNaN(currentProduct) || !currentGoodsId) {
            return;
        }
        if (breadcrumbs.length && breadcrumbs[currentStepNumber].coverage) {
            const { step } = breadcrumbs[currentStepNumber + 1];
            dispatch(setCurrentStepNumber(currentStepNumber + 1));
            nextPageNavigation({ page: step, uuid: lead_uuid });
            dispatch(setStep(step));
            return;
        }
        saveAndNavigateNext();
    };

    const handleOpenMore = () => setOpenMore(true);

    const handleCloseMore = () => setOpenMore(false);

    const onBack = () => {
        const { step } = breadcrumbs[currentStepNumber - 1];
        dispatch(setCurrentStepNumber(currentStepNumber - 1));
        previousPageNavigation({ page: step, uuid: lead_uuid });
        dispatch(setStep(step));
    };

    return (
        breadcrumbs.length && breadcrumbs[currentStepNumber].coverage ?
            <Coverage
                setOpen={onBack}
                onNext={onNextStep}
            />
            :
            <>
                <Header
                    stepper
                    stepsTitle={window.location.hash.includes('coverage') ? messages.CHOOSE_ITEM_TO_CHECK : messages.CHOOSE_ITEM_TO_INSURE}
                />
                <div className="products-list">
                    {itemTypes.map(type => <CheckBoxField
                        key={type.id}
                        label={type.name}
                        onClick={() => { onSelectItemType(type.id); }}
                        id={type.id}
                        checked={currentGoodsId === type.id}
                    />)}
                    {areCategories ? <Expander
                        onClick={handleOpenMore}
                        label={messages.MORE}
                    /> : null}
                </div>
                <Footer
                    showBack={false}
                    onNextStep={onNextStep}
                    nextText={messages.SAVE_AND_NEXT}
                    disabled={mode !== 'pull' && (isNaN(currentGoodsId) || !currentGoodsId)}
                />
                <UnqualifiedItemTypes
                    isOpen={openMore}
                    close={handleCloseMore}
                />
            </>
    );
};
export default ProductsList;
