import React from 'react';
import cn from 'classnames';
import './section.scss';

const Section = ({ label, children, sub = false }) => <div className="merchant-section">
    <div className={cn('merchant-section_label', { 'merchant-section_label_subsection-label': sub })}>
        {label}
    </div>
    {children}
</div>;

export default Section;
