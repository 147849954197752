import React from 'react';
import { useSelector } from 'react-redux';
// import bankLogo from '../../../../dist/images/Logo.svg';
import './Logo.scss';
import { selectStyles } from '../../../../store/issuerApp/issuerApp.selector';

const Logo = ({ src = '' }) => {
    const { logo } = useSelector(selectStyles);
    return (
        <div className="logo">
            {/* <img className="logo__icon" src={logo || bankLogo} alt="" /> */}
            <div className="logo__wrapper">
                {(src || logo) && <img className="logo__icon" src={src || logo} alt="" />}
            </div>
            <div className="logo__title">alyyn</div>
        </div>
    );
};

export default Logo;
