import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import OfferDetails from './Offer/OfferDetails';
import UserDetails from './Contacts/UserDetails';
import Contracts from './Contracts/Contracts';
import WhatsappDetails from './WhatsApp/WhatsappDetails';
import TrackingDetails from './Track/TrackingDetails';
import Notifications from './Notifications';

import './details.scss';

const Details = ({ profile, notification, connect }) => {
    const { search } = useLocation();
    const [active, setActive] = useState(null);
    const [model, setModel] = useState(undefined);

    useEffect(() => {
        setActive(search);
    }, [search]);

    useEffect(() => {
        let ws;

        setTimeout(() => {
            ws = connect(async (data) => {
                setModel(data.model);
            });
        }, 500);

        return () => {
            ws.close();
        };
    }, []);

    const reloadCallback = () => {
        setModel(undefined);
    };

    return <div className="tabs-wrapper">
        <OfferDetails role={profile.role} active={active === '?tab=offer'} mobile />
        <TrackingDetails active={active === '?tab=track'} reload={model === 'Lead' ? reloadCallback : null} />
        <UserDetails lang={profile.language} user={profile.email} type="clients" active={active === '?tab=clients'} />
        <WhatsappDetails role={profile.role} active={active === '?tab=whatsapp'} mobile />
        {profile.role === 'team_manager' && <UserDetails type="management" active={active === '?tab=management'} />}
        <Contracts active={active === '?tab=contracts'} reload={model === 'Contract' ? reloadCallback : null} />
        <Notifications reload={notification} active={active === '?tab=notifications'} />
    </div>;
};

export default Details;
