import React from 'react';
import { useSelector } from 'react-redux';
import { selectIssuerProfile } from '../../../../store/issuerApp/issuerApp.selector';


import './UserName.scss';

const UserName = () => {
    const profile = useSelector(selectIssuerProfile);
    if (!profile) {
        return null;
    }
    const name = `${profile.first_name} ${profile.last_name}`;
    return (
        <div className="username-section">
            <div className="username-section__name">{name}</div>
            <div className="username-section__role">Admin</div>
        </div>
    );
};

export default UserName;
