const amplitude = require('amplitude-js');

// const key = process.env.AMP_KEY;

let isAppStarted = false;

amplitude.getInstance().init('08742c8b34dee59f4e3d154ef8f800e1');


export const EVENTS = {
    APP_STARTED: 'APP_STARTED',
    NAVIGATION_TO: 'NAVIGATION_TO',
    TOKEN_VERIFIED: 'TOKEN_VERIFIED',
    PAGE_LOADED: 'PAGE_LOADED',
    USER_CLICK: 'USER_CLICK',
    ITEM_TYPE_SELECT: 'ITEM_TYPE_SELECT',
    NEXT_PAGE: 'NEXT_PAGE',
    PREVIOUS_PAGE: 'PREVIOUS_PAGE',
    CHANGE_ATTRIBUTE: 'CHANGE_ATTRIBUTE',
    CHECK_COVERAGE: 'CHECK_COVERAGE',
    BENEFICIARY_SELECT: 'BENEFICIARY_SELECT',
    INSURANCE_PRODUCT_SELECT: 'INSURANCE_PRODUCT_SELECT',
    SEE_INSURANCE_PRODUCT_DETAILS: 'SEE_INSURANCE_PRODUCT_DETAILS',
    CONTACT_US: 'CONTACT_US',
    PAYMENT_INITIATION: 'PAYMENT_INITIATION',
    DOWNLOAD_CERTIFICATE: 'DOWNLOAD_CERTIFICATE',
    ASK_FOR_HELP: 'ASK_FOR_HELP'
};

export const setUserId = (userId) => {
    // added user property
    const userUuid = new amplitude.Identify().set('Lead UUID', userId);
    amplitude.getInstance().identify(userUuid);
};

export const logEvent = (event, data = {}) => {
    // added event property
    const eventProperties = { ...data };
    amplitude.getInstance().logEvent(event, eventProperties);
};

export const appStarted = () => {
    if (!isAppStarted) {
        logEvent(EVENTS.APP_STARTED);
        isAppStarted = true;
    }
};

export const checkCoverage = (data) => {
    logEvent(EVENTS.CHECK_COVERAGE, data);
};

export const itemTypeSelection = (uuid) => {
    logEvent(EVENTS.ITEM_TYPE_SELECT, { uuid });
};

export const downloadCertificate = (data) => {
    logEvent(EVENTS.DOWNLOAD_CERTIFICATE, data);
};

export const insuranceProductSelection = (data) => {
    logEvent(EVENTS.INSURANCE_PRODUCT_SELECT, data);
};

export const seeInsuranceProductDetails = (data) => {
    logEvent(EVENTS.SEE_INSURANCE_PRODUCT_DETAILS, data);
};

export const contactUs = (data) => {
    logEvent(EVENTS.CONTACT_US, data);
};

export const paymentInitiation = (data) => {
    logEvent(EVENTS.PAYMENT_INITIATION, data);
};

export const beneficiarySelection = (data) => {
    logEvent(EVENTS.BENEFICIARY_SELECT, data);
};


export const askForHelp = (data) => {
    logEvent(EVENTS.ASK_FOR_HELP, data);
};

export const nextPageNavigation = (data) => {
    logEvent(EVENTS.NEXT_PAGE, data);
};

export const previousPageNavigation = (data) => {
    logEvent(EVENTS.PREVIOUS_PAGE, data);
};

export const changeAttribute = (data) => {
    logEvent(EVENTS.CHANGE_ATTRIBUTE, data);
};

export const tokenVerified = () => {
    logEvent(EVENTS.TOKEN_VERIFIED);
};

export const pageOnRender = (pageId, phase, loadingTime) => {
    if (phase === 'mount') {
        logEvent(EVENTS.PAGE_LOADED, { page: pageId, loadingTime });
    }
};

export const handleUserClick = (event) => {
    const { target, clientX, clientY } = event;
    const data = {
        element: target.outerHTML,
        clientX,
        clientY
    };
    logEvent(EVENTS.USER_CLICK, data);
};
