// TODO: this component (the same for some other parts) is just a set
// of the copies of the code parts as it is made 'quick and dirty' way
// TODO: to refactor, ideally to rewrite
import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';

import { useLanguage } from '../../../helpers/customHooks';
import { messages } from '../../../constants/messages';

import Header from '../../Header';
// import InputWrap from '../../Common/Input/InputWrap';
import Footer from '../../Common/Footer/Footer';
import CheckBoxSet from '../HelvetiaAttributes/CheckBoxSet';
import InputDatepicker from '../../Common/InputDatepicker/InputDatepicker';

import '../ItemAttributes/ItemAttributes.scss';
import './styles.scss';

import { API_KEY } from '../../../helpers/googleMapAPI';

import { setCurrentStepNumber } from '../../../store/common/common.actions';
import { selectItemTypeAttributes, selectWhiteLabelingStyles } from '../../../store/selectors';
import { selectCurrentStepNumber, selectBreadcrumbs } from '../../../store/common/common.selector';
import { saveItemTypeAttrubutes, setItemTypeAttributeValue, setStep } from '../../../store/productInsurance/productInsurance.actions';

import { getInputBackground } from '../../../helpers/calculateColor';


const TravelPreOffer = () => {
    useLanguage();
    const dispatch = useDispatch();
    const itemAttributes = useSelector(selectItemTypeAttributes).filter(attr => attr.position === 'PRE_OFFER');
    const currentStepNumber = useSelector(selectCurrentStepNumber);
    const breadcrumbs = useSelector(selectBreadcrumbs);

    const {
        whiteLabelingInputBackground,
        whiteLabelingTitleColor
    } = useSelector(selectWhiteLabelingStyles);

    const handleChange = (value, key, autocomplete) => {
        let v = value;
        if (autocomplete) {
            v = value.label;
        }
        dispatch(setItemTypeAttributeValue({ key, value: v }));
    };


    const renderWizard = (attr) => {
        switch (attr.type) {
            case 'date':
                return <InputDatepicker
                    dateValue={attr.value}
                    icon={false}
                    future
                    label={attr.label}
                    /* validStatus={(s) => { errorCallback({ [key]: s }); }} */
                    onInputChange={(v) => {
                        handleChange(v, attr.key);
                    }} />;
            case 'boolean':
                return <div className="discovered-element-wrap">
                    <div className="discovered-input-wrap">
                        <div className="discovered-input-title">
                            <div className="discovered-input-lable">{attr.label}</div>
                        </div>
                    </div>
                    <CheckBoxSet
                        onChange={v => handleChange(v, attr.key)}
                        data={attr.value}
                        labels={[messages.YES, messages.NO]}
                    />
                </div>;
            default:
                return <div className="discovered-element-wrap input">
                    <div className="discovered-input-wrap">
                        <div className="discovered-input-title">
                            <div className="discovered-input-lable">{attr.label}</div>
                        </div>
                        <GooglePlacesAutocomplete
                            apiKey={API_KEY}
                            selectProps={{
                                placeholder: '',
                                styles: {
                                    indicatorsContainer: () => ({
                                        display: 'none'
                                    }),
                                    input: provided => ({
                                        ...provided,
                                        padding: 0,
                                        margin: 0,
                                    }),
                                    placeholder: () => ({
                                        display: 'none'
                                    }),
                                    container: provided => ({
                                        ...provided,
                                        boxShadow: '2px 4px 11px 0 rgb(0 0 0 / 8%) !important',
                                        borderRadius: 10,
                                    }),
                                    valueContainer: provided => ({
                                        ...provided,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        height: '100%',
                                        fontSize: 16,
                                        borderRadius: 10
                                    }),
                                    menu: provided => ({
                                        ...provided,
                                        fontSize: 16,
                                        color: whiteLabelingTitleColor
                                    }),
                                    control: provided => ({
                                        ...provided,
                                        height: 46,
                                        border: 'none !important',
                                        borderRadius: 10,
                                        color: whiteLabelingTitleColor,
                                        backgroundColor:
                                            getInputBackground(whiteLabelingInputBackground),
                                    })
                                },
                                options: { types: ['cities'] },
                                value: { label: attr.value, value: { descripion: attr.value } },
                                onChange: (value) => { handleChange(value, attr.key, true); }
                            }} />
                        {/* <InputWrap
                            className="discovered-input"
                            type="text"
                            value={attr.value}
                            onBlur={onCodeValidate}
                                                   error={error}
                        /> */}
                    </div>
                </div>;
        }
    };

    const onNextStep = () => {
        dispatch(saveItemTypeAttrubutes())
            .then(() => {
                const { step } = breadcrumbs[currentStepNumber + 1];
                dispatch(setCurrentStepNumber(currentStepNumber + 1));
                dispatch(setStep(step));
            })
            .catch(() => {
            });
    };

    return <div className="travel-wrapper">
        <Header
            stepper
            stepsTitle={messages.TRAVEL_PRE_SCREEN_TITLE} />
        {
            itemAttributes.map(attr => renderWizard(attr))
        }
        <Footer
            showBack={false}
            nextText={messages.SAVE_AND_NEXT}
            onNextStep={onNextStep}
            /* disabled={!Object.values(isValid).every(item => item)} */
        />
    </div>;
};

export default TravelPreOffer;
