import React from 'react';
import { useSelector } from 'react-redux';
import { messages } from '../../../../constants/messages';
import WhiteLabelRow from '../WhiteLabelRow';
import { selectWhiteLabels } from '../../../../store/issuerApp/issuerApp.selector';

import './WhiteLabelingTable.scss';

const WhiteLabelingTable = () => {
    const whiteLabels = useSelector(selectWhiteLabels);

    return (
        <div className="table white-labeling-table">
            <header className="table__header white-labeling-table__header">
                <div>{messages.WHITE_LABELS}</div>
                <div className="header__logo">{messages.LOGO}</div>
                <div>{messages.COLOUR_SCHEME}</div>
            </header>
            <main className="table__content white-labeling-table__content">
                {whiteLabels.map(whiteLabel => (
                    <WhiteLabelRow
                        whiteLabel={whiteLabel}
                        key={whiteLabel.uuid}
                    />
                ))}
            </main>
        </div>
    );
};
export default WhiteLabelingTable;
