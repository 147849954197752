import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import {
    selectWhiteLabelingPrimaryColor,
} from '../../../../store/selectors';
import calculateColorWithOpacity from '../../../../helpers/calculateColor';
import { saveConsentStatuses
} from '../../../../store/payment/payment.actions';

import Modal from '../../Modal';

const CheckBox = ({ doc = {}, border, only = false, errorCallback, isDetailedDataPrivacy }) => {
    const dispatch = useDispatch();
    const whiteLabelingPrimaryColor = useSelector(selectWhiteLabelingPrimaryColor);
    const styles = border || only ? { borderBottomColor: calculateColorWithOpacity(whiteLabelingPrimaryColor, 0.3) } : { borderBottom: 'none' };

    const [modalState, setModalState] = useState({});
    const [consent, setConsent] = useState(Array(3).fill(undefined));
    const [checked, setChecked] = useState(null);

    const onSummaryCheck = (index, value) => setConsent((prev) => {
        const t = [...prev];
        t[index] = value;
        return t;
    });

    const onCloseModal = () => {
        dispatch(saveConsentStatuses(consent));
        setModalState({ shown: false });
    };

    useEffect(() => {
        if (typeof modalState.shown === 'boolean' && !modalState.shown) {
            setChecked(true);
        }
    }, [modalState.shown]);

    const onToggle = () => {
        if (doc.key === 'data_privacy_sheet' && isDetailedDataPrivacy) {
            setModalState({ shown: true });
            return;
        }
        setChecked(prev => !prev);
    };

    useEffect(() => {
        if (typeof checked === 'boolean') {
            errorCallback({ [[doc.key]]: checked });
        }
    }, [checked]);

    return <>
        <div
            style={styles}
            className="siccess-read"
            onClick={onToggle}>
            <div className={cn('checkbox-off', { 'checkbox-on': checked })}>
                <div />
            </div>
            <div>
                {(only && only) || null}
                <div className="download">
                    <a href={doc.doc} target="_blank" rel="noreferrer" onClick={(e) => { e.stopPropagation(); }}>{doc.text}</a>
                </div>
            </div>
        </div>
        {doc.key === 'data_privacy_sheet' && isDetailedDataPrivacy && <Modal
            {...modalState}
            data={consent}
            onCheck={onSummaryCheck}
            onClose={onCloseModal} />}
    </>;
};

export default CheckBox;
