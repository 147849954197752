import React from 'react';
import { useSelector } from 'react-redux';
import { selectWhiteLabelingStyles } from '../../../store/selectors';

const ConfirmationIcon = () => {
    const {
        whiteLabelingPrimaryColor,
        whiteLabelingPrimaryLightColor
    } = useSelector(selectWhiteLabelingStyles);
    return (
        <div className="confirmation-image">
            <svg xmlns="http://www.w3.org/2000/svg" width="221" height="155" viewBox="0 0 221 155">
                <g fill="none" fillRule="evenodd" transform="translate(0 1)">
                    <rect width="107" height="139" x="58" y="13" stroke={whiteLabelingPrimaryColor} strokeLinejoin="round" strokeWidth="1.7" rx="14" />
                    <rect width="85" height="111" x="70" y="27" fill={whiteLabelingPrimaryLightColor} rx="9" />
                    <path fill={whiteLabelingPrimaryColor} fillRule="nonzero" d="M139.268657,23.7462687 C138.820896,17.1791045 131.432836,16.5074627 131.432836,16.5074627 C131.432836,16.5074627 121.283582,16.7313433 119.343284,9.94029851 C117.402985,3.14925373 111.507463,3.74626866 111.507463,3.74626866 C111.507463,3.74626866 105.61194,3.14925373 103.671642,9.94029851 C101.731343,16.7313433 91.5820896,16.5074627 91.5820896,16.5074627 C91.5820896,16.5074627 84.1940299,17.1791045 83.7462687,23.7462687 C83.2985075,30.3134328 86.0597015,30.1641791 86.0597015,30.1641791 L109.791045,30.3134328 L113.223881,30.3134328 L136.955224,30.1641791 C136.955224,30.1641791 139.791045,30.3134328 139.268657,23.7462687 Z" />
                    <path fill={whiteLabelingPrimaryColor} opacity=".6" fillRule="nonzero" d="M133.5 98.7313433L91.5 98.7313433C90.1 98.7313433 89 97.9104478 89 96.8656716L89 96.8656716C89 95.8208955 90.1 95 91.5 95L133.5 95C134.9 95 136 95.8208955 136 96.8656716L136 96.8656716C136 97.9104478 134.9 98.7313433 133.5 98.7313433zM125.492537 103.835821L100.41791 103.835821C99.6716418 103.835821 99 103.238806 99 102.41791L99 102.41791C99 101.671642 99.5970149 101 100.41791 101L125.492537 101C126.238806 101 126.910448 101.597015 126.910448 102.41791L126.910448 102.41791C126.910448 103.164179 126.238806 103.835821 125.492537 103.835821z" />
                    <g stroke={whiteLabelingPrimaryColor} opacity=".2" transform="translate(178)">
                        <path d="M31.6774194 8.77419355C31.483871 8.77419355 31.2258065 8.77419355 31.0322581 8.83870968 29.9354839 6.4516129 27.5483871 4.77419355 24.7096774 4.77419355 21.4193548 4.77419355 18.6451613 7.09677419 17.9354839 10.1935484 15.6129032 10.2580645 13.7419355 12.1935484 13.7419355 14.516129 13.7419355 16.9032258 15.6774194 18.9032258 18.1290323 18.9032258L31.6129032 18.9032258C34.3870968 18.9032258 36.6451613 16.6451613 36.6451613 13.8709677 36.7096774 11.0322581 34.4516129 8.77419355 31.6774194 8.77419355zM31.0322581 8.83870968C31.0322581 8.83870968 28.7096774 9.16129032 27.7419355 11.0967742" />
                        <path d="M36.516129 14.0645161C39.2903226 14.0645161 41.5483871 11.8064516 41.5483871 9.03225806 41.5483871 6.25806452 39.2903226 4 36.516129 4 36.3225806 4 36.0645161 4 35.8709677 4.06451613 34.7741935 1.67741935 32.3870968-2.93098879e-14 29.5483871-2.93098879e-14 26.2580645-2.93098879e-14 23.5483871 2 22.9032258 5.09677419M35.8709677 4C35.8709677 4 33.5483871 4.32258065 32.5806452 6.25806452" />
                        <line x1="12.258" y1="18.903" y2="18.903" />
                    </g>
                    <g stroke={whiteLabelingPrimaryColor} opacity=".1" transform="translate(0 83)">
                        <path d="M26.9258065 7.45806452C26.7612903 7.45806452 26.5419355 7.45806452 26.3774194 7.51290323 25.4451613 5.48387097 23.416129 4.05806452 21.0032258 4.05806452 18.2064516 4.05806452 15.8483871 6.03225806 15.2451613 8.66451613 13.2709677 8.71935484 11.6806452 10.3645161 11.6806452 12.3387097 11.6806452 14.3677419 13.3258065 16.0677419 15.4096774 16.0677419L26.8709677 16.0677419C29.2290323 16.0677419 31.1483871 14.1483871 31.1483871 11.7903226 31.2032258 9.37741935 29.283871 7.45806452 26.9258065 7.45806452zM26.3774194 7.51290323C26.3774194 7.51290323 24.4032258 7.78709677 23.5806452 9.43225806" />
                        <path d="M31.0387097 11.9548387C33.3967742 11.9548387 35.316129 10.0354839 35.316129 7.67741935 35.316129 5.31935484 33.3967742 3.4 31.0387097 3.4 30.8741935 3.4 30.6548387 3.4 30.4903226 3.45483871 29.5580645 1.42580645 27.5290323 2.13162821e-14 25.116129 2.13162821e-14 22.3193548 2.13162821e-14 20.016129 1.7 19.4677419 4.33225806M30.4903226 3.4C30.4903226 3.4 28.516129 3.67419355 27.6935484 5.31935484" />
                        <line x1="10.419" y1="16.068" y2="16.068" />
                    </g>
                    <g stroke={whiteLabelingPrimaryColor} strokeLinecap="round" strokeWidth="1.7" transform="translate(30 151)">
                        <line x1=".646" x2="150.774" y1="1.087" y2="1.413" />
                        <line x1="156.146" x2="166.146" y1="1.587" y2="1.577" />
                        <line x1="170.146" x2="174.146" y1="1.596" y2="1.592" />
                    </g>
                    <g transform="translate(98 57)">
                        <circle cx="15" cy="15" r="15" fill={whiteLabelingPrimaryColor} opacity=".6" />
                        <line x1="10.5" x2="19.5" y1="10.5" y2="19.5" stroke={whiteLabelingPrimaryLightColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                        <line x1="10.5" x2="19.5" y1="10.5" y2="19.5" stroke={whiteLabelingPrimaryLightColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" transform="rotate(-90 15 15)" />
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default ConfirmationIcon;
