import React from 'react';
import Button from '../../Common/Button/Button';
import { messages } from '../../../constants/messages';
import './Reactivation.scss';

const Reactivation = ({ onReactivate, onBack }) => {
    return (
        <div className="reactivate-confirmation">
            <div className="reactivate-confirmation__title">
                {messages.CONFIRM_REACTIVATION}
            </div>
            <Button onClick={onReactivate}>
                {messages.REACTIVATE}
            </Button>
            <Button
                onClick={onBack}
                type="default"
            >
                {messages.BACK}
            </Button>
        </div>
    );
};

export default Reactivation;
