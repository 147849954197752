import React from 'react';
import Table from '../Common/Table/Table';
import FormItem from '../Common/FormItem/FormItem';
import Section from '../Common/Section/Section';

const MerchantIdentificationTab = ({ data, titles }) => {
    const { merchant_identification, uuid, group_uuid, ibans } = data;
    return <>
        <FormItem label="Merchant UUID">
            <p>{uuid}</p>
        </FormItem>
        <FormItem label="Group UUID">
            <p>{(!group_uuid && 'no group') || group_uuid }</p>
        </FormItem>
        <FormItem label="List IBAN" multi>
            {ibans.map((iban, k) => <p className="iban" key={k}>{iban}</p>)}
        </FormItem>
        <Section label="Merchant Identification" sub>
            <Table data={merchant_identification} titles={titles} />
        </Section>
    </>;
};

export default MerchantIdentificationTab;
