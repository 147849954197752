import React, { useState, useEffect } from 'react';
import { trigger_fields } from '../constants';
import OfferManagementSelect from '../OfferManagementCommon/OfferManagementSelect';
import EditItem from '../OfferManagementCommon/EditItem';
import Footer from '../OfferManagementCommon/Footer';

const TriggerEditMode = ({ data, onDelete, onCancel, onSave, itemTypeOptions }) => {
    const [formData, setFormData] = useState({});
    const [transactionSegments, setTransactionSegments] = useState([]);
    const [merchantSegments, setMerchantSegments] = useState([]);

    const handleFormDataChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleInputFormDataChange = (field, value, border) => {
        setFormData(prev => ({
            ...prev,
            [field]: {
                ...prev[[field]],
                [border]: value
            }
        }));
    };

    const handleSegmentationDelete = (field, type) => {
        const func = type === 'transaction' ? setTransactionSegments : setMerchantSegments;
        setFormData((prev) => {
            const temp = { ...prev };
            delete temp[field];
            return temp;
        });
        func((prev) => {
            prev.push({ label: trigger_fields[type].fields[field].title, value: field });
            return prev;
        });
    };

    const handleSegmentationSelect = (e, type) => {
        const func = type === 'transaction' ? setTransactionSegments : setMerchantSegments;
        setFormData(prev => ({ ...prev, [e.value]: [] }));
        func(prev => prev.filter(s => s.value !== e.value));
    };

    useEffect(() => {
        const tSegments = []; const
            fData = {}; const mSegments = [];
        Object.entries(trigger_fields)
            .map(([key, value]) => {
                switch (key) {
                    case 'item_types':
                        fData[key] = data[key];
                        break;
                    default:
                        Object.entries(value.fields)
                            .map(([field, v]) => {
                                if ((Array.isArray(data[field]) && data[field].length) ||
                                (!Array.isArray(data[field]) && typeof data[field] === 'object')) {
                                    fData[field] = data[field];
                                } else if (field === 'transaction_amount') {
                                    if (Number(data.payment_max_amount)) {
                                        const { payment_min_amount, payment_max_amount } = data;
                                        fData[field] = {
                                            payment_max_amount,
                                            payment_min_amount
                                        };
                                    } else {
                                        tSegments.push({ label: v.title, value: field });
                                    }
                                } else if (field === 'distance') {
                                    const { distance_min, distance_max } = data;
                                    fData[field] = {
                                        distance_min,
                                        distance_max
                                    };
                                } else {
                                    (key === 'transaction' ? tSegments : mSegments)
                                        .push({ label: v.title, value: field });
                                }
                                return field;
                            });
                        break;
                }
                return key;
            });

        setFormData({ ...fData, min_lead_amount_currency: data.min_lead_amount_currency });
        setTransactionSegments(tSegments);
        setMerchantSegments(mSegments);
    }, [data]);


    const renderWizard = (key, value) => {
        let render = null;

        switch (key) {
            case 'item_types':
                render = <div className="offer-management-card__section__segments__form__item"><OfferManagementSelect
                    value={formData[key]}
                    options={itemTypeOptions}
                    onChange={e => handleFormDataChange(key, e)}
                    isMulti /></div>;
                break;
            default:
                render =
                    <>{Object.keys(value.fields)
                        .map((k, i) =>
                            (Object.prototype.hasOwnProperty.call(formData, k) ? <EditItem
                                fields={value.fields}
                                prop={k}
                                item={formData}
                                index={i}
                                type={key}
                                onDelete={handleSegmentationDelete}
                                onSelectChange={handleFormDataChange}
                                onInputChange={handleInputFormDataChange} /> : null)
                        )}
                        <div className="offer-management-card__section__segments__form__item">
                            <span style={{ fontWeight: 500, display: 'inline-block', marginBottom: 10 }}>
                                Add a new attribute
                            </span>
                            <OfferManagementSelect
                                menuPlacement="top"
                                value=""
                                isCustomized
                                options={key === 'transaction' ? transactionSegments : merchantSegments}
                                onChange={e => handleSegmentationSelect(e, key)} />
                        </div></>;
                break;
        }
        return <div key={key} style={{ width: '95%' }}>{render}</div>;
    };

    return <>
        <div className="offer-management-card__section__trigger__body">{
            Object.entries(trigger_fields)
                .map(([k, v]) =>
                    renderWizard(k, v)
                )}
        </div>
        <Footer onCancel={onCancel} onSave={() => { onSave(formData); }} onDelete={onDelete} action={!onDelete && 'add'} />
    </>;
};

export default TriggerEditMode;
