import React, { useState, useEffect } from 'react';
import OfferFragment from './OfferFragment';
import Footer from '../OfferManagementCommon/Footer';
import { sortArray, initialStrategy } from '../../../helpers/offerManagementHelpers';

const StrategyBlock = ({ data, onEdit, activeItems,
    onSave, onCancel, isActive, culture, showDelete = true, onChange }) => {
    const [formData, setFormData] = useState([]);
    const [isFooter, setIsFooter] = useState(false);
    const [strategiesToDelete, setStrategiesToDelete] = useState([]);

    useEffect(() => {
        setFormData([...data]);
    }, [data]);

    useEffect(() => {
        if (!showDelete) {
            onChange(formData);
        }
    }, [formData]);

    const resetState = () => {
        setIsFooter(false);
        setStrategiesToDelete([]);
    };

    const handleFormDataChange = (value, field, i) => {
        setFormData((prevState) => {
            prevState[i] = { ...prevState[i],
                [field]: value,
                offer_template: {
                    ...prevState[i].offer_template,
                    [field]: value } };
            return [...prevState];
        });
    };

    const onStrategyDelete = (i) => {
        setStrategiesToDelete((prevState) => {
            prevState.push(i);
            return prevState;
        });
    };

    const onStrategyEdit = () => {
        onEdit(formData);
        setIsFooter(true);
    };

    const onStrategySave = () => {
        const fData = [...formData];
        sortArray(strategiesToDelete)
            .map(s => fData.splice(s, 1));

        onSave(fData)
            .then(() => {
                resetState();
            });
    };

    const onStrategyCancel = () => {
        resetState();
        setFormData([...data]);
        onCancel();
    };

    const onStrategyAdd = () => {
        setFormData((prev) => {
            initialStrategy.offer_template.culture = culture.value;
            prev.push(initialStrategy);
            return [...prev];
        });
    };

    return <div
        className="offer-management-card__section__segments__offer__wrapper"
        onClick={onEdit ? onStrategyEdit : null}>
        {
            formData.map((offer, i) => <OfferFragment
                key={offer.uuid}
                data={offer}
                showDelete={showDelete}
                isBlurred={strategiesToDelete.indexOf(i) > -1}
                isActive={isActive || activeItems.indexOf(offer.uuid) > -1 || !offer.uuid}
                handleFormDataChange={(value, field) => handleFormDataChange(value, field, i)}
                onDelete={() => onStrategyDelete(i)} />

            )
        }

        {
            isFooter ?
                <Footer onCancel={onStrategyCancel} onSave={onStrategySave} onAdd={onStrategyAdd} action="add" /> : null
        }
    </div>;
};

export default StrategyBlock;
