import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { selectActualLanguage } from '../../../store/selectors';

const ContractDates = ({ startDateLabel, coverageStartDate, validUntilLabel, validUntil }) => {
    const actualLanguage = useSelector(selectActualLanguage).toLowerCase();
    const startDate = coverageStartDate ? moment(coverageStartDate) : null;
    const startDateString = startDate ? startDate.locale(actualLanguage).format('ll') : '';
    const validDate = validUntil ? moment(validUntil) : null;
    const validString = validDate ? validDate.locale(actualLanguage).format('ll') : '';
    return (
        <>
            {startDateString && <p className="date-title">{`${startDateLabel}: ${startDateString}`}</p>}
            {validString && <p className="date-title">{`${validUntilLabel}: ${validString}`}</p>}
        </>
    );
};

ContractDates.propTypes = {
    coverageStartDate: PropTypes.string,
    validUntil: PropTypes.string,
    startDateLabel: PropTypes.string.isRequired,
    validUntilLabel: PropTypes.string.isRequired,
};
ContractDates.defaultProps = {
    coverageStartDate: '',
    validUntil: '',
};

export default ContractDates;
