import React from 'react';
import './input.scss';

const Input = ({ disabled, required, error, label, value = '', type = 'text', onChange, keyRestrictions }) => {
    const checkKey = (e) => {
        const charCode = (e.which) ? e.which : e.keyCode;
        if ((charCode > 31 && charCode !== 43 && (charCode < 48 || charCode > 57))) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    return <>
        <p className="input-label">
            {label}
            <span>{required && '*'}</span>
        </p>
        <input
            disabled={disabled}
            type={type}
            value={value}
            onKeyPress={keyRestrictions ? e => checkKey(e) : null}
            className={`input-field ${error && 'error'}`}
            onChange={({ target }) => onChange(target.value)} />
        {error && <p className="input-field_error">{error}</p>}
    </>;
};

export default Input;
