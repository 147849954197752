import profile from '../../../dist/images/Profile.svg';
import card from '../../../dist/images/id-card.svg';
import group from '../../../dist/images/Group.svg';
import emailimg from '../../../dist/images/Email.svg';
import phone from '../../../dist/images/Phone.svg';
import { validateNif, validateMandatory, validateEmail, validatePhone, validateZip } from '../../../helpers/validators';

export const labels = messages => ({
    identification: {
        passport_id: messages.PASSPORT_ID,
        foreigner_id: messages.FOREIGNER_ID,
        national_id: messages.NATIONAL_ID,
        tax_number: messages.TAX_NUMBER_ID,
    },
    attributes: {
        first_name: messages.FIRST_NAME,
        last_name: messages.FAMILY_NAME,
        phone_number: messages.PHONE_NUMBERS,
        email: messages.EMAIL_ADDRESS,
        birthday: messages.BIRTHDAY,
        address: messages.ADDRESS,
        tax_number: messages.TAX_NUMBER,
        nationality: messages.NATIONALITY,
    },
    address: [
        { attribute: 'street', placeholder: messages.STREET, label: '', type: 'text', validator: validateMandatory },
        { attribute: 'number', placeholder: messages.NUMBER, label: '', type: 'text', validator: validateMandatory },
        { attribute: 'room', placeholder: messages.ROOM, label: '', type: 'text' },
        { attribute: 'zip', placeholder: messages.POSTAL_CODE, label: '', type: 'text', validator: validateZip },
        { attribute: 'city', placeholder: messages.CITY, label: '', type: 'text', validator: validateMandatory }],
    country: { attribute: 'country', placeholder: messages.COUNTRY, label: '', type: 'text', validator: validateMandatory }
});

export const config = {
    first_name: {
        icon: profile,
        validator: validateMandatory
    },
    last_name: {
        icon: group,
        validator: validateMandatory
    },
    phone_number: {
        icon: phone,
        validator: validatePhone,
        type: 'number'
    },
    email: {
        icon: emailimg,
        validator: validateEmail
    },
    tax_number: {
        validator: validateNif,
        icon: card
    },
    gender: {
        validator: validateMandatory
    },
    nationality: {
        validator: validateMandatory
    }
};

export default labels;
