import { combineReducers } from 'redux';
import common from './common/common.reducer';
import productInsurance from './productInsurance/productInsurance.reducer';
import payment from './payment/payment.reducer';
import contract from './contract/contract.reducer';
import issuerApp from './issuerApp/issuerApp.reducer';
import merchants from './merchants/merchants.reducer';

const rootReducer = combineReducers({
    common,
    productInsurance,
    payment,
    contract,
    issuerApp,
    merchants
});

export default rootReducer;
