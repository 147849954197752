import React from 'react';
import {
    Drawer, List,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ListSection from './ListSection/ListSection';
import ListSectionItem from './ListSectionItem/ListSectionItem';
import Logo from '../Common/Logo/Logo';
import UserName from './UserName/UserName';

import './SideBar.scss';

const drawerWidth = 300;

const useStyles = makeStyles({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    list: {},
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#F8F9FF',
    },
});

const sideBarData = [
    {
        title: 'Merchants Database',
        items: [
            {
                name: 'Merchants',
                to: '/merchant-groups',
            }
        ]
    },
    {
        title: 'Account Admin',
        items: [
            {
                name: 'Users Management',
                to: '/',
            },
            {
                name: 'Integration Settings',
                to: '/'
            },
        ]
    }
];

const SideBar = () => {
    const classes = useStyles();

    return (
        <div className="sidebar">
            <nav className={classes.drawer}>
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    <List className={classes.list}>
                        <header className="sidebar__header">
                            <Logo />
                        </header>
                        <div className="sidebar__username-section">
                            <UserName />
                        </div>
                        {sideBarData.map(({ title, items }, i) => (
                            <ListSection key={i} title={title}>
                                {
                                    items.map(({ name, to }, j) => (<ListSectionItem
                                        key={j}
                                        name={name}
                                        to={to}
                                    />))
                                }
                            </ListSection>
                        ))}
                    </List>
                </Drawer>
            </nav>
        </div>
    );
};
export default SideBar;
