import React, { useEffect, useState } from 'react';
import CheckBox from './CheckBox';

const CheckBoxSet = ({ product, isHelvetia, errorCallback, titles, isDetailedDataPrivacy,
    isSanitas }) => {
    const [sheets, setSheets] = useState([]);

    useEffect(() => {
        if (product) {
            const {
                product_sheet,
                consent_sheet,
                issuer_data_privacy_sheet,
                issuer_terms_n_conditions_sheet,
                issuer_payment_consent_sheet,
                data_privacy_sheet,
                obj = {} } = product;

            const arr = [{
                doc: issuer_terms_n_conditions_sheet, text: titles.ISSUER_TERMS_AND_CONDITIONS, key: 'issuer_terms_n_conditions_sheet'
            }, {
                doc: product_sheet, text: titles[!isHelvetia ? 'DOWNLOAD_TERMS_AND_CONDITIONS' : 'TERMS_AND_CONDITIONS'], key: 'product_sheet'
            }, {
                doc: data_privacy_sheet, text: titles.DATA_PRIVACY, key: 'data_privacy_sheet'
            }, {
                doc: issuer_payment_consent_sheet, text: titles.ISSUER_PAYMENT_CONSENT, key: 'issuer_payment_consent_sheet'
            }, {
                doc: consent_sheet, text: titles.CONSENT, key: 'consent_sheet'
            }];

            if (!isSanitas) {
                arr.push({
                    doc: issuer_data_privacy_sheet, text: titles.ISSUER_DATA_PRIVACY, key: 'issuer_data_privacy_sheet'
                });
            }

            setSheets(arr.filter((o) => {
                if (o.doc) {
                    obj[o.key] = false;
                }
                return o.doc && o;
            }));

            errorCallback(obj);
        }
    }, [product]);

    if (!sheets.length) {
        return null;
    }

    return sheets.length > 1 ?
        <div className="check-box-group">
            {titles.READ_THE_FOLLOWING} {
                sheets.map((sheet, i) =>
                    <CheckBox
                        isDetailedDataPrivacy={isDetailedDataPrivacy}
                        errorCallback={errorCallback}
                        doc={sheet}
                        border={i === sheets.length - 1} />)
            }
        </div> : <CheckBox
            errorCallback={errorCallback}
            doc={sheets[0]}
            only={titles.I_HAVE_SUCCESSFULLY_READ}
            border />;
};

export default CheckBoxSet;
