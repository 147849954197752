/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import { messages } from '../../../constants/messages';
import { renderPrice } from '../../../helpers/renderingHelpers';
import { selectCurrencyPosition, isInsuranceLogoVisible } from '../../../store/selectors';

import './Contract.scss';
import Button from '../../Common/Button/Button';
import ListMarker from '../../Common/ListMarker/ListMarker';
import ContractDates from './ContractDates';
import Chips from '../../Common/Chips/Chips';
import { CONTRACT_STATUSES } from '../../../constants/constants';

const Coverage = ({ cover }) => {
    if (!cover || !cover.length) {
        return null;
    }
    return (
        <div className="coverage">
            <p>{messages.WHATS_COVERED}</p>
            { cover.map((text, index) => (
                <div key={index} className="coverage-item">
                    <ListMarker />
                    <p>{text}</p>
                </div>
            ))}
        </div>
    );
};


const Contract = ({ contract, onMoreDetailsClick, onClaimClick }) => {
    const {
        product_information: {
            branding_name,
            price,
            currency_symbol,
            cover,
            underwriter_logo: logo,
            payment_scheme
        },
        coverage_start_date,
        valid_until,
        status
    } = contract;

    const name = contract.item_type && contract.item_type.name;

    const currencyPosition = useSelector(selectCurrencyPosition);
    const isLogoVisible = useSelector(isInsuranceLogoVisible);

    const schemeList = {
        QUARTERLY: messages.QUARTERLY,
        MONTHLY: messages.MONTHLY,
        WEEKLY: messages.WEEKLY,
        YEARLY: messages.YEARLY,
        UPFRONT: ''
    };

    return (
        <div className="contract-wrapper">
            <div className="contract-wrapper__header">
                <div className="contract-wrapper__title">
                    <p className="brand-title">{ name }</p>
                    <p className="model-title">{ branding_name }</p>
                </div>
                <div>
                    <p className="price">{renderPrice(price, currency_symbol, currencyPosition)}</p>
                    {payment_scheme && <p className="frequency">{schemeList[payment_scheme]}</p> }
                    {isLogoVisible && logo && (
                        <img className="contract-wrapper__logo" src={logo} alt="contract logo" />
                    )}
                </div>
            </div>
            <Chips text={messages[status]} type={CONTRACT_STATUSES[status]} />

            <ContractDates
                coverageStartDate={coverage_start_date}
                validUntil={valid_until}
                startDateLabel={messages.PURCHASED_ON}
                validUntilLabel={messages.EXPIRING_ON}
            />
            <Coverage cover={cover} />
            <div className="contract-actions">
                <p
                    onClick={onMoreDetailsClick}
                    className="more-details">
                    {messages.MORE_DETAILS}
                </p>
                <Button
                    onClick={onClaimClick}
                    className="file-claim-btn"
                    size="small"
                >
                    {messages.FILE_A_CLAIM}
                </Button>
            </div>
        </div>
    );
};

export default Contract;
