import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './CheckBoxField.scss';
import { useLanguage } from '../../../helpers/customHooks';

const CheckBoxField = (props) => {
    const {
        label,
        onClick,
        className,
        disabled,
        checked,
        children,
        mode
    } = props;
    useLanguage();
    return (
        <div
            onClick={onClick}
            className={cn('checkbox-field', className, {
                disabled,
                checked
            })}>
            <div className="counter-nmae-part">
                {mode !== 'pull' && <div className={cn('checkbox-off', { 'checkbox-on': checked })}>
                    <div />
                </div>}
                <div className="checkbox-field-content">
                    <div className={`checkbox-field-label ${mode === 'pull' && 'without-checkbox'}`}>{label}</div>
                    {children}
                </div>
            </div>

        </div>
    );
};

CheckBoxField.defaultProps = {
    className: '',
    disabled: false,
    onClick: null,
    children: null,
};

CheckBoxField.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
};

export default CheckBoxField;
