import React, { useState } from 'react';
import Dropzone from 'react-dropzone-newest';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import UploadForm from './UploadForm';
import { messages } from '../../../../../constants/messages';
import { delimiters } from '../../../../constants';
import csv from '../../../../../dist/docs/csv_template.csv';
import pdf from '../../../../../dist/docs/Batch_Upload_ES.pdf';

const Upload = ({ onClose, onSend, error, resetError }) => {
    const options = delimiters(messages);
    const [files, setFiles] = useState([]);
    const [check, setCheck] = useState(1);

    const [delimiter, setDelimiter] = useState(options[0]);

    const onDrop = (f) => {
        setFiles(f);
    };

    const handleCheck = (v) => {
        setCheck(v);
    };

    const onDelete = () => setFiles([]);

    const handleClose = () => {
        resetError();
        onClose();
    };

    const onChange = v => setDelimiter(v);

    return <div className="upload-wrapper">
        <div className="upload-wrapper_form">
            <div className="list-upload_row">
                <p style={{ margin: 0 }} />
                <FontAwesomeIcon className="list-upload_delete" icon={faClose} onClick={handleClose} />
            </div>
            <div className="upload-wrapper_form_step">
                <p>{messages.STEP_1}</p>
                <span>{messages.DOWNLOAD_THE_FOLLOWING}</span>
                <a target="_blank" href={csv} rel="noreferrer">{messages.CSV_TEMPLATE}</a>
            </div>
            <div className="upload-wrapper_form_step">
                <p>{messages.STEP_2}</p>
                <span>{messages.FILL_DATA}</span>
                <a target="_blank" href={pdf} rel="noreferrer">{messages.PDF_DESCRIPTION}</a>
                <div className="warning-wrapper">
                    <FontAwesomeIcon icon={faTriangleExclamation} className="warning" />
                    <div>{messages.PHONE_SHOULD_BE_INTERNATIONAL}</div>
                </div>
            </div>
            <div className="upload-wrapper_form_step">
                <p>{messages.STEP_3}</p>
                <p>{messages.SELECT_AND_UPLOAD}</p>
                <div className="upload-wrapper_form_step_check">
                    <p>{messages.EXISTING_CONTACTS_SHALL_BE}</p>
                    <div className="upload-wrapper_form_step_check_btns">
                        <div>
                            <input type="radio" id="ch-1" name="check" value="1" onClick={() => handleCheck(1)} checked={check === 1} />
                            <label htmlFor="ch-1">{messages.OVERWRITTEN}</label>
                        </div>
                        <div>
                            <input type="radio" id="ch-2" name="check" value="0" onClick={() => handleCheck(0)} checked={!check} />
                            <label htmlFor="ch-2">{messages.SKIPPED}</label>
                        </div>
                    </div>
                </div>
                <p className="input-label">{messages.DELIMITER}</p>
                <Select
                    onChange={v => onChange(v)}
                    classNamePrefix="async-offer"
                    className="select"
                    value={delimiter}
                    options={options}
                />
                {!files.length ? <Dropzone
                    /* accept={{ 'application/vnd.ms-excel': ['.csv'], 'text/plain': ['.csv'] }} */
                    onDrop={acceptedFiles => onDrop(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                        <div className="list-upload" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>{messages.SELECT_FILE}</p>
                        </div>
                    )}
                </Dropzone> : <UploadForm
                    resetError={resetError}
                    error={error}
                    name={files[0].name}
                    onSend={cb => onSend(files[0], { check, delimiter: delimiter.value }, cb)}
                    onDelete={onDelete} />}
            </div>
        </div></div>;
};

export default Upload;
