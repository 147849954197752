import { messages } from '../constants/messages';
// eslint-disable-next-line import/no-cycle
import { NIF_REGEXP } from '../constants/constants';

export function validateMandatory(value) {
    let error = '';
    if (!value) {
        error = messages.MANDATORY_FIELD;
    }
    return error;
}

export function validateInteger(value) {
    const error = validateMandatory(value);
    if (error) {
        return error;
    }
    return value > 0 ? '' : messages.MUST_BE_POSITIVE;
}

export function validateBoolean(value) {
    return validateMandatory(value);
    // return typeof value === 'boolean';
}

export function validateBirthday(data) {
    let error = validateMandatory(data);
    if (error) {
        return error;
    }
    const arr = data.split('.');
    const config = {
        length: 10,
        date: new Date(`${arr[2]}-${arr[1]}-${arr[0]}`)
    };

    if (data.length < config.length) {
        error = messages.INVALID_FORMAT;
    }

    if (data.length === config.length) {
        if (!new Date(config.date).getTime() || new Date(config.date) > new Date()) {
            error = messages.INVALID_FORMAT;
        }
    }
    return error;
}

export function validateEmail(value) {
    let emailError = '';
    emailError = validateMandatory(value);
    if (emailError) {
        return emailError;
    }
    const number = value.trim();
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailPattern.test(number)) {
        emailError = messages.INVALID_FORMAT;
    }
    return emailError;
}

export function validatePhone(value) {
    let phoneError = '';
    phoneError = validateMandatory(value);
    if (phoneError) {
        return phoneError;
    }
    const phonePattern = /^[0-9\-+]{9,15}$/;
    const number = value.replace(/\s/g, '');
    if (!phonePattern.test(number)) {
        phoneError = messages.INVALID_FORMAT;
    }
    return phoneError;
}

export function validateNumber(value) {
    const error = validateMandatory(value);
    if (error) {
        return error;
    }
    const numberPattern = /^(?:\d*[.,])?\d+$/;
    if (!numberPattern.test(value)) {
        return messages.INVALID_FORMAT;
    }
    // eslint-disable-next-line no-unused-vars
    const [priceInt, priceDec] = value.toString().split('.');
    if (priceDec) {
        if (priceDec.length > 2) {
            return messages.TWO_DECIMAL_PLACES;
        }
    }
    return '';
}

export const validateItemPrice = (value, transactionPrice) => {
    const numberError = validateNumber(value);
    if (numberError) {
        return numberError;
    }
    if (transactionPrice && parseFloat(value) > parseFloat(transactionPrice)) {
        return messages.PRICE_SHOULD_BE_LOWER;
    }
    return '';
};

export const validateNif = (value) => {
    const error = validateMandatory(value);
    if (error) {
        return error;
    }
    if (!NIF_REGEXP.test(value)) {
        return messages.INVALID_FORMAT;
    }

    return '';
};

export const validateDate = (value) => {
    const error = validateMandatory(value);
    if (error) {
        return error;
    }
    const date = new Date(value);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    if (date.getTime() >= Date.now()) {
        return messages.DATE_SHOULD_BE_PAST;
    }
    return '';
};

export const itemValidators = {
    text: validateMandatory,
    price: validateItemPrice,
    integer: validateInteger,
    boolean: validateBoolean
};
