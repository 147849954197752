import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { messages } from '../../../../constants/messages';
import { getCounter, resetCounter } from '../../../../store/issuerApp/issuerApp.actions';
import sound from '../../../../dist/sounds/mixkit-bell-notification-933.wav';

import Profile from './Profile';
import Items from './List';
import './menu.scss';

const Menu = ({ profile, notification }) => {
    const { search } = useLocation();
    const [menu, setMenu] = useState(false);
    const [counter, setCounter] = useState(0);

    const audio = new Audio(sound);

    const toggleMenu = () => {
        setMenu(prev => !prev);
    };

    const handleResetCounter = () => {
        resetCounter()
            .then(() => {
                setCounter(0);
            });
    };

    useEffect(() => {
        getCounter()
            .then((res) => {
                setCounter(res);
            });
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            getCounter()
                .then((res) => {
                    setCounter((prev) => {
                        if (prev !== res && res) {
                            audio.play();
                        }
                        return res;
                    }
                    );
                });
        }, 10000);

        return () => clearInterval(timer);
    });

    useEffect(() => {
        setMenu(false);
    }, [search]);

    return <>
        <div className={`menu-wrapper_small ${menu && 'closed'}`}>
            <FontAwesomeIcon icon={faBars} onClick={toggleMenu} />
        </div>
        <div className={`menu-wrapper ${menu && 'open'}`}>
            {/* <div className="details-logo">
        <Logo src="https://stalyyndemo.blob.core.windows.net/public-media/branding/partner-portal-logo/ALYYN_LOGO__icon_full_color_white_bgr-quad.png" />
    </div> */}
            <Profile
                name={profile.first_name}
                surname={profile.last_name}
                notification={notification} />
            <Items role={profile.role} counter={counter} resetCounter={handleResetCounter} />
            <a href="mailto:service@alyyn.eu" className="menu-wrapper_help">
                {messages.NEED_HELP}
                <span style={{ textDecoration: 'underline' }}>&nbsp;{messages.WRITE_EMAIL}</span>
            </a>
        </div></>;
};

export default Menu;
