import React, { useState } from 'react';
import CustomDatePicker from './CustomDatePicker';
import CheckBoxField from '../Common/Checkbox/CheckBoxField';
import Select from '../Common/Select/Select';
import Footer from '../Common/Footer/Footer';
import history from '../../helpers/history';

const AirlineFlightForm = ({ airlines, from, to }) => {
    const [checked, setChecked] = useState(true);

    const [data, setData] = useState({
        airline: airlines[0],
        from: from[1],
        to: '',
        date: '',
        time: '',
        rDate: '',
        rTime: ''
    });

    const onSelect = (field, value) => {
        setData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const onNext = () => {
        history.push('/success');
    };

    const toggleReturnFlight = () => {
        setChecked(!checked);
    };

    return <>
        <Select
            value={data.airline}
            options={airlines}
            label="Airline"
            onChange={v => onSelect('airline', v)}
            isLoading={false}
        />
        <Select
            value={data.from}
            options={from}
            label="From"
            onChange={v => onSelect('from', v)}
            isLoading={false}
        />
        <Select
            value={data.to}
            options={to}
            label="To"
            onChange={v => onSelect('to', v)}
            isLoading={false}
        />

        <div className="airline-insurance_body_time-date-picker">
            <CustomDatePicker label="Date" type="date" onChange={v => onSelect('date', v)} />
            <CustomDatePicker label="Time" type="time" onChange={v => onSelect('time', v)} />
        </div>
        <div className="airline-insurance_body_return-date">
            <CheckBoxField onClick={() => toggleReturnFlight()} checked={checked} label="Return Flight" />
        </div>
        {checked && <div className="airline-insurance_body_time-date-picker">
            <CustomDatePicker label="Date" type="date" onChange={v => onSelect('rDate', v)} />
            <CustomDatePicker label="Time" type="time" onChange={v => onSelect('rTime', v)} />
        </div>}
        <Footer showBack={false} submit onNextStep={onNext} />
    </>;
};

export default AirlineFlightForm;
