import React from 'react';
import add from '../../../../dist/images/add.svg';
import add_hover from '../../../../dist/images/add_hover.svg';

const AddSegmentButton = ({ handleEdit }) => (
    <div className="offer-management_plus-btn" onClick={handleEdit}>
        <img
            src={add}
            alt="edit"
            onMouseOver={e => e.currentTarget.src = add_hover}
            onMouseOut={e => e.currentTarget.src = add}
        />
    </div>
);
export default AddSegmentButton;
