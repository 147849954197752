import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { messages } from '../../../constants/messages';
import { usePrimaryColor, useLanguage } from '../../../helpers/customHooks';
import { askForHelp } from '../../../helpers/amplitude';

import Button from '../../Common/Button/Button';
import OutsideClick from '../../LanguageOption/OutsideClick';
import InputWrap from '../../Common/Input/InputWrap';

import { submitMessage } from '../../../store/productInsurance/productInsurance.actions';
import { selectProfile } from '../../../store/common/common.selector';

import './HintModal.scss';

const HintModal = ({ onClose, shown, label, hint, type = 'hint', offers }) => {
    useLanguage();

    const text = useRef();

    useEffect(() => {
        if (text && text.current) {
            text.current.innerHTML = hint;
        }
    }, [hint, text]);

    const [value, setValue] = useState({ international_phone_number: '', message: '' });
    const { lead_uuid } = useSelector(selectProfile);

    const backgroundColor = usePrimaryColor(0.2);
    const onSend = () => {
        askForHelp({ uuid: lead_uuid });
        submitMessage({ ...value,
            insurance_product_names: offers ?
                offers.map(({ branding_name }) => branding_name).join(',') :
                'helvetia'
        })
            .then(() => onClose())
            .catch(() => onClose());
    };

    if (!shown) {
        return null;
    }

    const onChange = (v, field) => {
        setValue(prev => ({ ...prev, [field]: v }));
    };

    return (
        <div className="hint-modal-wrapper" style={{ backgroundColor }}>
            <OutsideClick setOpen={onClose}>
                <div className="hint-modal">
                    <FontAwesomeIcon icon={faXmark} className="close-btn" onClick={onClose} />
                    {type !== 'help' ? <><p className="hint-name">{label}</p>
                        <p ref={text} className="hint-text" /></> : <>
                            <p className="contact-me-title question">{messages.CONTACT_ME_Q}</p>
                            <p className="contact-me-title">{messages.CONTACT_ME_A}</p>
                            <div>
                                <InputWrap
                                    placeholder={messages.PHONE_NUMBERS}
                                    value={value.international_phone_number}
                                    className="discovered-input"
                                    type="number"
                                    onChange={v => onChange(v, 'international_phone_number')} />
                                <textarea
                                    placeholder={messages.MESSAGE}
                                    value={value.message}
                                    className="discovered-input textarea"
                                    onChange={({ target }) => onChange(target.value, 'message')} />
                            </div>
                        </>}
                    {type === 'help' && <Button className="close-button" onClick={onSend}>
                        {messages.SEND}
                    </Button>}
                </div>
            </OutsideClick>
        </div>
    );
};

export default HintModal;
