import React, { useEffect, useState } from 'react';

import AddressForm from './AddressForm';
import Google from './Google';

const Autocomplete = ({
    errorCallback, handleInputChange, data, fields, placeholder, activationTag, label }) => {
    const [form, setForm] = useState(false);
    useEffect(() => {
        const status = {};
        fields.map((item, key) => {
            if (item.validator) {
                status[item.attribute] = !form ? form : !!data[item.attribute];
            }
            return key;
        });
        errorCallback(status);
    }, [form]);

    useEffect(() => {
        if (data.address) {
            setForm(true);
        }
    }, [data.address]);

    return <>
        <p className="input-lable">{label}</p>
        {
            form ?
                <AddressForm
                    country={activationTag.toUpperCase()}
                    data={data}
                    fields={fields}
                    errorCallback={errorCallback}
                    handleInputChange={handleInputChange} /> :
                <Google
                    activationTag={activationTag}
                    data={data}
                    showForm={() => setForm(true)}
                    handleInputChange={handleInputChange}
                    placeholder={placeholder} />
        }
    </>;
};

export default Autocomplete;
