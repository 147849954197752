import createReducer from '../createReducer';
import { SET_AVAILABLE_ITEMS, SET_ERROR, SET_CURRENT_GOODS_ID, SET_CURRENT_INSURANCE_ID, SET_INSURANCE_TYPES, SET_INSURANCE_PRODUCTS, SET_CURRENT_COUNTER_VALUE, SET_PAID_STATUS, SET_SINGLE_ITEM_TYPE, SET_ALREADY_PAID, SET_CURRENT_CURRENCY, SET_IS_ITEM_TYPE_SCREEN, SET_CURRENT_CURRENCY_CODE, SET_ITEM_TYPE_ATTRIBUTES, SET_ITEM_TYPE_ATTRIBUTE_VALUE, SET_CUSTOMER_ATTRIBUTES, SET_STEP, SET_INSURANCE_CATEGORIES, SET_PHOTOS, SET_RESET_INDEX, SET_CURRENT_CATEGORY, SET_RECOGNISED_DATA, SET_CURRENT_PRODUCT, SET_UPDATE_PRODUCT, SET_UNRECOGNIZED_ITEM, SET_INSURANCES_LIST, SET_INSURANCE_PRODUCT_ID } from './productInsurance.actionTypes';

const productInsurance = {
    insuranceProductUuid: null,
    availableItems: [],
    step: 1,
    photos: { item_photo: [], receipt_photo: [] },
    err: false,
    insuranceTypes: [],
    insuranceCategories: [],
    insuranceProducts: [],
    currentGoodsId: null,
    currentInsuranceId: null,
    currentCounterValue: {
        id: null,
        name: null,
        value: 1
    },
    paymenStatus: [],
    singleItemType: false,
    alreadyPaid: false,
    currency: null,
    currencyCode: '',
    isItemTypeScreen: true,
    itemTypeAttributes: [],
    customerAttributes: [],
    resetPhotoIndex: true,
    currentCategory: null,
    recognisedData: [],
    recognizeLoading: false,
    currentProduct: null,
    insurancesList: [],
};

function setAvailableItems(newState, { data }) {
    return {
        ...newState,
        availableItems: data
    };
}

function setInsuranceProductUuid(newState, { uuid }) {
    return { ...newState, insuranceProductUuid: uuid };
}

function setStep(newState, { step }) {
    return { ...newState, step };
}

function setPhotos(newState, { photos }) {
    const itemPhotos = []; const
        receiptPhotos = [];
    photos.map((photo) => {
        const { attachment_type } = photo;
        if (attachment_type) {
            (attachment_type === 'RECEIPT_PICTURE' ? receiptPhotos : itemPhotos).push(photo);
        }
        return photo;
    });

    return {
        ...newState,
        photos: { item_photo: itemPhotos, receipt_photo: receiptPhotos },
    };
}

function setError(newState, { error }) {
    return {
        ...newState,
        err: error
    };
}

function setCurrentGoodsId(newState, { uuid }) {
    return {
        ...newState,
        currentGoodsId: uuid,
        currentCounterValue: {
            ...newState.currentCounterValue,
            id: uuid,
            value: 1,
        }

    };
}

function setCurrentCategory(newState, { uuid }) {
    return {
        ...newState,
        currentCategory: uuid
    };
}

function setCurrentInsuranceId(newState, { uuid }) {
    return {
        ...newState,
        currentInsuranceId: uuid
    };
}

function setInsuranceTypes(newState, { data }) {
    const res = data.length ? data : [...newState.insuranceTypes, data];

    return {
        ...newState,
        insuranceTypes: res
    };
}

function setInsuranceCategories(newState, { data }) {
    return {
        ...newState,
        insuranceCategories: data
    };
}

function setInsuranceProducts(newState, { data }) {
    return {
        ...newState,
        insuranceProducts: data
    };
}

function setCurrentCounterValue(newState, { value }) {
    return {
        ...newState,
        currentCounterValue: {
            ...newState.currentCounterValue,
            value
        }
    };
}

function setPaidStatus(newState, { status }) {
    return {
        ...newState,
        paymenStatus: status
    };
}

function setAlreadyPaid(newState, { status }) {
    return {
        ...newState,
        alreadyPaid: status
    };
}


function setSingleItemType(newState, { status }) {
    return {
        ...newState,
        singleItemType: status
    };
}

function setCurrentCurrency(newState, { actualCurrency }) {
    return {
        ...newState,
        currency: actualCurrency
    };
}

function setCurrentCurrencyCode(newState, { actualCurrency }) {
    return {
        ...newState,
        currencyCode: actualCurrency
    };
}

function setIsItemTypeScreen(newState, { screen }) {
    return {
        ...newState,
        isItemTypeScreen: screen
    };
}

function setItemTypeAttibutes(newState, { itemTypeAttributes }) {
    return {
        ...newState,
        itemTypeAttributes
    };
}

function setItemTypeAttributeValue(newState, { data }) {
    const { key, value } = data;
    return {
        ...newState,
        itemTypeAttributes: newState.itemTypeAttributes.map((attribute) => {
            if (attribute.key === key) {
                return { ...attribute, value };
            }
            return attribute;
        })
    };
}

function setCustomerAttributes(newState, { customerAttributes }) {
    return {
        ...newState,
        customerAttributes
    };
}

function setResetIndex(newState, { toggleShow }) {
    return {
        ...newState,
        resetPhotoIndex: toggleShow
    };
}

function setRecognisedData(newState, { data }) {
    return {
        ...newState,
        recognisedData: data
    };
}

function setCurrentProduct(newState, { data }) {
    return {
        ...newState,
        currentProduct: data
    };
}

function setUpdateProduct(newState, { data }) {
    const { index, price, text } = data;
    return {
        ...newState,
        recognisedData: newState.recognisedData.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    price,
                    description: text,
                };
            }
            return item;
        })
    };
}

function setUnrecognizedItem(newState, { data }) {
    const { price, text } = data;
    return {
        ...newState,
        recognisedData: [
            ...newState.recognisedData,
            {
                description: text,
                price,
            }
        ]
    };
}

function setInsurancesList(newState, { data }) {
    return {
        ...newState,
        insurancesList: data
    };
}

const productInsuranceReducer = createReducer(productInsurance, {
    [SET_AVAILABLE_ITEMS]: setAvailableItems,
    [SET_STEP]: setStep,
    [SET_PHOTOS]: setPhotos,
    [SET_ERROR]: setError,
    [SET_CURRENT_GOODS_ID]: setCurrentGoodsId,
    [SET_CURRENT_INSURANCE_ID]: setCurrentInsuranceId,
    [SET_INSURANCE_TYPES]: setInsuranceTypes,
    [SET_INSURANCE_CATEGORIES]: setInsuranceCategories,
    [SET_INSURANCE_PRODUCTS]: setInsuranceProducts,
    [SET_ITEM_TYPE_ATTRIBUTE_VALUE]: setItemTypeAttributeValue,
    [SET_CURRENT_COUNTER_VALUE]: setCurrentCounterValue,
    [SET_PAID_STATUS]: setPaidStatus,
    [SET_SINGLE_ITEM_TYPE]: setSingleItemType,
    [SET_ALREADY_PAID]: setAlreadyPaid,
    [SET_CURRENT_CURRENCY]: setCurrentCurrency,
    [SET_CURRENT_CURRENCY_CODE]: setCurrentCurrencyCode,
    [SET_IS_ITEM_TYPE_SCREEN]: setIsItemTypeScreen,
    [SET_ITEM_TYPE_ATTRIBUTES]: setItemTypeAttibutes,
    [SET_CUSTOMER_ATTRIBUTES]: setCustomerAttributes,
    [SET_RESET_INDEX]: setResetIndex,
    [SET_CURRENT_CATEGORY]: setCurrentCategory,
    [SET_RECOGNISED_DATA]: setRecognisedData,
    [SET_CURRENT_PRODUCT]: setCurrentProduct,
    [SET_UPDATE_PRODUCT]: setUpdateProduct,
    [SET_UNRECOGNIZED_ITEM]: setUnrecognizedItem,
    [SET_INSURANCES_LIST]: setInsurancesList,
    [SET_INSURANCE_PRODUCT_ID]: setInsuranceProductUuid
});
export default productInsuranceReducer;
