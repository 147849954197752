import React from 'react';
import Select from 'react-select';
import './Dashboard.scss';
import { messages } from '../../../constants/messages';
import DashboardCard from './DashboardCard';
import DashboardSummaryCard from './DashboardSummaryCard';
import data from './data';
import calculateColor from '../../../helpers/calculateColor';


const fields = {
    overview: {
        header: messages.PORTFOLIO_OVERVIEW,
        props: {
            insurance_contracts: { label: 'Insurance contracts' },
            annualized_premiums: { label: 'Annualized premiums', total: true }
        }

    },
    development: {
        header: messages.PORTFOLIO_DEVELOPMENT,
        props: {
            new_contracts: { label: 'New contracts' },
            canceled_contracts: { label: 'Canceled contracts' },
            annualized_premiums: { label: 'Annualized premiums' }
        }
    },
    leads: {
        header: messages.PORTFOLIO_LEADS,
        props: {
            leads: { label: 'Leads' },
            initial_offer_made: { label: 'Initial offer made' },
            offer_clicked: { label: 'Offer clicked' },
            purchased: { label: 'Purchased' }
        }
    }
};

// temp solution
const primaryColor = '#05b2ca';

const cStyles = {
    indicatorSeparator: () => ({
        width: 0,
    }),
    indicatorContainer: () => ({
        paddingRight: 10
    }),
    control: () => ({
        display: 'flex',
        width: 160,
        border: 'none',
        // temp solution with color
        backgroundColor: calculateColor(primaryColor),
        padding: 0,
        borderRadius: 0
    }),
    valueContainer: provided => ({
        ...provided,
        paddingRight: 0
    }),
    dropdownIndicator: provided => ({
        ...provided,
        color: primaryColor,
    })
};

const Dashboard = () => (<div className="dashboard">
    {
        Object.entries(fields).map(([f, v], j) => (
            <section className="dashboard__section" key={j}>
                <header className="dashboard__section__title">
                    <>
                        {v.header}
                        {
                            j > 0 && <>
                                <span style={{ marginLeft: 5 }}>&#40;last&nbsp;</span>
                                <div style={{ display: 'inline-block' }}>
                                    <Select
                                        styles={cStyles}
                                        value={{ label: '30 days', value: 0 }}
                                        options={[{ label: '30 days', value: 0 }, { label: '60 days', value: 1 }]}
                                        isSearchable={false}
                                        isClearable={false} />
                                </div>
                                <span>&#41;</span>
                                </>
                        }
                    </>
                </header>
                <main className="dashboard__section__main">
                    {
                        (f !== 'leads' && Object.entries(fields[f].props).map(([prop, value], i) =>
                            <DashboardCard
                                key={i}
                                title={value.label}
                                preview={value.total}
                                data={data[prop]} />)) ||
                                <DashboardSummaryCard fields={fields[f].props} data={data} />
                    }
                </main>
            </section>))
    }
</div>);

export default Dashboard;
