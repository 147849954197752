import React from 'react';
import { segment_fields as fields } from '../constants';

const renderWizard = (key, value, data) => {
    let render = null;
    switch (key) {
        case 'age_group_codes':
            render = data[key].length ?
                `${Math.min(...data[key]) * 10} - ${(Math.max(...data[key]) + 1) * 10 - 1} ${value.unit}` :
                null;
            break;
        case 'address_since':
            render = data.customer_address_since_max ? `${data.customer_address_since_min} - ${data.customer_address_since_max} ${value.unit}` : null;
            break;
        default:
            if (Array.isArray(data[key])) {
                render = data[key].length ? data[key].map(d => d.label || d).join(', ') : null;
                break;
            }
            render = data[key].label;
    }
    return render && <>
        <span style={{ marginRight: 10, fontStyle: 'italic', fontWeight: 500 }}>{value.title}:</span>
        {render}
    </>;
};

const SegmentReadMode = ({ data }) => (Object.entries(fields).map(([key, value], i) => (
    /* Object.prototype.hasOwnProperty.call(data, key) && */
    <div key={i} style={{ marginBottom: 10, marginRight: 10 }}>
        {renderWizard(key, value, data)}
    </div>)
));

export default SegmentReadMode;
