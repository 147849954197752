const nl = {
CHOOSE_ITEM_TO_CHECK: "Please choose the item for which you want to check coverage",
INSURANCE_COMPANY_LABEL: 'Insurance Company',
INSURANCE_PRODUCT_LABEL: 'Insurance Product',
    //steps
    STEP1: 'Gegevens',
    STEP2: 'Bieden',
    STEP3: 'Sluiting',
    CHOOSE_ITEM_TO_INSURE: 'Kies het artikel dat u wilt verzekeren.',
    // Insurance overview
    INSURANCE_OVERVIEW: 'Overzicht verzekeringen',
    COVERAGE: 'Gedekt:',
    NON_COVERED: 'Niet gedekt:',
    DOWNLOAD_INSURANCE_PRODUCT: 'Download de gedetailleerde verzekeringsomschrijving',
    DOWNLOAD_TERMS_AND_CONDITIONS: 'Voorwaarden downloaden',
    PRODUCT_INSURANCE_STEP_ONE_TITLE: 'Slechts een paar stappen om gedekt te zijn met de verzekering',
    PRODUCT_INSURANCE_STEP_TWO_TITLE: 'Ontdek producten van de kassabon',
    PRODUCT_INSURANCE_STEP_THREE_TITLE: 'Selecteer uw verzekering.',
    PRODUCT_INSURANCE_STEP_TWO_HINT: 'Tik op wat u net hebt gekocht en moet worden gedekt.',
    PRODUCT_INSURANCE_STEP_ONE_ONLINE: 'Vertel ons, wat u wilt beschermen.',
    ENTER_MORE_DETAILS: 'Voer meer details in over het artikel dat u wilt verzekeren',
    TAKE_RECEIPT_PHOTO: 'Maak een foto van het aankoopbewijs.',
    CHECK_RECEIPT: 'Controleer of de kassabon duidelijk op de foto staat.',
    CHECK_ITEM_PHOTO: 'Controleer of het item duidelijk is op de foto.',
    TAKE_ITEM_PHOTO: 'Maak een foto van het te bedekken item.',
    BUTTON: 'Button',
    MORE_DETAILS: 'Details tonen',
    BACK: 'Terug',
    SKIP_RECEIPT: 'Ontvangstbewijs overslaan',
    NEXT: 'Volgende',
    GENERAL_CONDITIONS: 'Algemene voorwaarden',
    LENGTH: 'Duur: ',
    MINIMUM_LENGTH: 'Minimale contractduur: ',
    MAXIMUM_LENGTH: 'Maximale contractduur: ',
    CONTRACT_TERMINATION: 'Beëindiging contract: ',
    COVERAGE_LIST: 'Dekking',
    SEARCH: 'Zoeke',
    OTHER: 'Andere',
    CLOSE: 'Sluiten',
    PHOTO: 'Foto',
    OR: 'Of',
    OPTIONS: 'Opties',
    SHOW_MORE: 'Toon meer',
    DO_NOT_INSURE: 'Dit artikel niet verzekeren',
    NOT_INSURABLE: 'Dit artikel is niet verzekerbaar',
    SELECT_ITEM_TYPE: 'Selecteer het type item',
    ADD_UNRECOGNIZED_ITEM: 'Een item toevoegen',
    NAME: 'Naam',
    PLEASE_WAIT: 'Even geduld a.u.b.',
    RECOGNIZE_PROCESSING: 'De toepassing verwerkt de foto(\'s) om de relevante informatie te extraheren.',
    PRICE: 'Prijs',
    CANCEL: 'Annuleren',
    OK: 'Ok',
    SAVE_AND_NEXT: 'Volgende',
    MY_NEW_ITEM: 'Mijn nieuwe geliefde item',

    // Inform Screen
    DEAR_CUSTOMER: 'Geachte klant,',
    TO_OFFER_YOU: 'om u de beste verzekeringsopties te kunnen bieden, hebben we meer informatie nodig over het artikel dat u zojuist heeft gekocht. U kunt ofwel:',
    /* OPTION_TAKE_A_PHOTO: 'Een foto van de aankoopbon maken',
    OPTION_UPLOAD_FROM_GGALLERY: 'Een foto van de aankoopbon uploaden', */
    OPTION_TAKE_A_PHOTO: 'Een foto maken',
    OPTION_UPLOAD_FROM_GGALLERY: 'Een foto uploaden',
    OPTION_ENTER_MANUALLY: 'Gegevens handmatig invoeren',
    OPTION_TAKE_A_PHOTO_DESCRIPTION: 'Toegang tot uw camera kan worden gevraagd.',
    OPTION_UPLOAD_FROM_GGALLERY_DESCRIPTION: 'Toegang tot foto\'s, media en bestanden kan worden gevraagd.',

    // Empty State
    SORRY: 'Sorry',
    SORRY_MESSAGE: 'Onze excuses, maar de aanbiedingslink is niet meer geldig. We doen u graag een aanbieding bij uw volgende aankoop.',
    DONE: 'Gedaan',

    // Payment Methods
    PAYMENT_METHODS: 'Betaling',
    HOW_DO_YOU_WANT_TO_PAY: 'Hoe wilt u betalen?',
    YOU_WILL_BE_SPENDING: 'U zult uitgeven',
    FOR_BUYING_INSURANCE_POLICIES: 'voor het kopen van verzekeringen',
    CONFIRM_YOUR_CARD_DETAILS: 'Vul de gegevens van uw betaalkaart in.',
    HDRC_MONEY_BACK: 'Vul de gegevens van uw betaalkaart in.',
    CARDHOLDER_NAME: 'Naam kaarthouder',
    CARD_NUMBER: 'Kaartnummer',
    EXPIRY_DATE: 'Vervaldatum',
    CVV: 'Beveiligingscode (CVV)',
    CONFIRM_AND_PAY: 'Bevestigen & kopen',
    I_HAVE_SUCCESSFULLY_READ: 'Ik heb de algemene voorwaarden gelezen en ga ermee akkoord.',
    ENTRY_A_FEW_MORE_DETAILS: 'Voer nog een paar gegevens over u in',
    FIRST_NAME: 'Voornaam',
    FAMILY_NAME: 'Achternaam',
    BIRTHDAY: 'Verjaardag',
    EMAIL_ADDRESS: 'E-mail adres',
    PHONE_NUMBERS: 'Telefoon',
    ADDRESS: 'Adres',
    ENTRY_HERE: 'Vul hier in',
    MONTHLY: 'per maand',
    WEEKLY: 'per week',
    YEARLY: 'per jaar',
    OVERVIEW_YEARLY: 'Jaarlijks',
    OVERVIEW_MONTHLY: 'Maandelijks',
    OVERVIEW_WEEKLY: 'Wekelijks',
    WEEKS: 'weken',
    MONTHS: 'maanden',
    YEARS: 'jaren',
    WHAT_IS_NEXT: 'Wat is het volgende:',
    INSURANCE_CERTIFICATE: (email) =>  `Het verzekeringscertificaat zal worden gegenereerd en verzonden naar ${email}.`,

    DEDUCTIBLE: 'Eigen risico: ',


    MANDATORY_FIELD: 'Dit veld is verplicht',
    INVALID_FORMAT: 'Ongeldige waarde',
    PRICE_SHOULD_BE_LOWER: 'Prijs moet lager zijn dan de transactieprijs',
    DATE_SHOULD_BE_PAST: 'De datum moet in het verleden liggen',

    // Congratulations!
    CONGRATULATIONS: 'Gefeliciteerd!',
    GLAD_TO_INFORM_YOU: 'We zijn blij om u te informeren dat u met succes de dekking hebt gekocht. Uw product is nu beveiligd.',

    YOU_WILL_RECEIVE: 'U ontvangt in de komende minuten een bevestigingsmail met het dekkingscertificaat.',

    YOU_ARE_PROTECTED: 'U bent beschermd',
    ALREADY_PURCHASED: 'Wij zijn blij u te kunnen meedelen dat u reeds een verzekering heeft afgesloten en dat uw geliefde artikel reeds beschermd is.',

    DOWNLOAD: 'downloaden',
    YOU_CAN_DOWNLOAD_HERE: 'U kunt hier uw verzekeringsbewijs downloaden:',
    BACK_TO_OVERVIEW: 'terug naar overzicht',

    // contracts list
    YOUR_POLICY_PURCHASES: 'Uw verzekeringen',
    PURCHASED_ON: 'Aangeschaft op',
    EXPIRING_ON: 'Verloopt op',
    WHATS_COVERED: 'Gedekt is',
    FILE_A_CLAIM: 'Een schade indienen',
    REACTIVATE: 'Reactiveren',

    // contract details
    DOWNLOAD_PURCHASE_RECEIPT: 'Download aankoopbon',
    INSURCANCE_CONTRACT: 'Verzekeringscontract',
    ITEM_IS_PROTECTED_BY_INSURANCE: 'Het voorwerp wordt door de verzekering beschermd',
    INSURANCE_COVERAGE_START: 'Aanvang verzekeringsdekking',
    INSURANCE_COVERAGE_UNTIL: 'Verzekeringsdekking tot',
    DOWNLOAD_PRODUCT_SHEET: 'Download de productfiche',
    DOWNLOAD_INSURANCE_CERTIFICATE: 'Het certificaat downloaden',
    CANCEL_CONTRACT: 'Contract annuleren',

    // claim form
    PROVIDE_INFO_FOR_CLAIM: 'Gelieve ons te voorzien van meer informatie, voordat we uw claim verwerken',
    ATTACH_PHOTO_VIDEO: 'Voeg, indien relevant, foto\'s of video\'s bij die de schade tonen',
    DESCRIBE_WHAT_HAPPEND: 'Beschrijf wat er is gebeurd',
    ENTER_HERE: 'Vul hier in',
    SUBMIT_CLAIM: 'Dien de claim in',
    PLEASE_ATTACH_FILES: 'Bestanden bijvoegen',
    THANKS_FOR_REQUEST: 'Bedankt voor het indienen van uw claim. We zijn blij om het te verwerken en u een antwoord te geven in de komende dagen.',
    YOUR_INSURANCE_COVERS: 'Uw verzekering dekt alleen de reparatie door een van onze gecertificeerde partners. U kunt hier een reparatiewerkplaats bij u in de buurt vinden.',
    SHOW_PARTNER_NETWORK: 'Partnernetwerk tonen',

    // confirmation
    YOUR_CONTRACT_HAS_BEEN_CANCELLED: 'Uw contract is geannuleerd. U ontvangt binnenkort een bevestiging per e-mail',
    GOT_IT: 'Ik heb hem gekregen.',
    THE_INSURANCE_WILL_BE_CANCELLED: 'De verzekering wordt geannuleerd met ingang van',
    ARE_YOU_SURE_TO_CANCEL: 'Weet u zeker dat u de bescherming van uw',
    RETURN: 'Terug',
    CONFIRM_REACTIVATION: 'Gelieve te bevestigen dat u de verzekering opnieuw wilt activeren.',
    YOUR_CONTRACT_HAS_BEEN_REACTIVATED: 'Uw verzekering is gereactiveerd. U ontvangt binnenkort een bevestigingsmail.',

    // contract statuses
    ACTIVE: 'actief',
    PENDING_CANCELLATION: 'geannuleerd',
    VOID: 'beëindigd',

    BENEFICIARY_TITLE: 'Wie vallen er in uw verzekering?',
    BENEFICIARY_QUESTION: 'Whom would you like to cover?',
    BENEFICIARY_OPTION1: 'mezelf',
    BENEFICIARY_OPTION2: 'ik en mijn familie/huishouden',
}

export default nl;
