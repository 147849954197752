import React from 'react';

const MultiSelect = ({ data, field, title, statuses, selectCallback }) => <>
    <p className="input-lable">{title}</p>
    {statuses.map(({ name, key }) => <div className="checkbox" key={key}>
        <input type="checkbox" className="multi-select" id={key} name={key} checked={data[field] && data[field].indexOf(key) > -1} onChange={() => selectCallback(key, field)} />
        <label htmlFor={key}>{name}</label>
    </div>)}
</>;

export default MultiSelect;
