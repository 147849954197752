import React, { useCallback, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
// import { useSelector } from 'react-redux';

// import { usePlacesWidget } from 'react-google-autocomplete';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import API_KEY from './settings';

// import { selectWhiteLabelingStyles } from '../../../../store/selectors';
import returnAddressComponent from './helpers';

const Google = ({ handleInputChange, showForm, activationTag }) => {
    const [initialized, setInitialized] = useState(false);
    const [menu, setMenu] = useState(null);
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: API_KEY
    });

    const geoCallback = ({ address_components }) => {
        const zip = returnAddressComponent(address_components, 'postal_code');
        const street = returnAddressComponent(address_components, 'route');
        let city = returnAddressComponent(address_components, 'locality');
        const area = returnAddressComponent(address_components, 'administrative_area_level_2') || returnAddressComponent(address_components, 'administrative_area_level_1');
        if (!city) {
            city = area;
        }
        const country = returnAddressComponent(address_components, 'country');
        handleInputChange(area, 'administrative_area_level_2');
        handleInputChange(`${street};;;${zip};${city}${!activationTag ? `;${country}` : ''}`, 'address');
    };

    const handleChange = (v) => {
        placesService.getDetails({
            placeId: v,
        }, (res) => { geoCallback(res); });
    };

    const handleClick = useCallback(({ target }) => {
        if (!target.className.includes('autocomplete') && initialized) {
        // outside click is detected
            showForm();
        }
    }, [initialized]);

    const handleFocus = useCallback(() => {
        if (document.activeElement && document.activeElement.id && document.activeElement.id === 'autocomplete') {
            setInitialized(true);
        }
    }, []);

    const handlePress = useCallback((e) => {
        if (e.keyCode === 13) {
            handleClick(e);
        }
    }, [initialized]);

    useEffect(() => {
        window.addEventListener('click', handleClick);
        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [handleClick]);

    useEffect(() => {
        window.addEventListener('focusin', handleFocus);
        return () => {
            window.removeEventListener('focusin', handleFocus);
        };
    }, [handleFocus]);

    useEffect(() => {
        window.addEventListener('keydown', handlePress);
        return () => {
            window.removeEventListener('keydown', handlePress);
        };
    }, [handlePress]);

    return <><div className="input-field-container ">
        <div className="form-group input-wrap">
            <input
                onChange={(evt) => {
                    if (evt.target.value.length > 4) {
                        let par = { input: evt.target.value, types: ['locality', 'route', 'postal_code'] };
                        if (activationTag) {
                            par = { ...par,
                                region: activationTag,
                                componentRestrictions: {
                                    country: activationTag
                                } };
                        }
                        getPlacePredictions(par);
                        setMenu(true);
                    } else {
                        setMenu(false);
                    }
                }}
                loading={isPlacePredictionsLoading}
                className="discovered-input autocomplete-container"
                id="autocomplete"
            />
        </div>
        {
            (menu && placePredictions.length && <div className="autocomplete-menu">
                <div className="autocomplete-wrapper">
                    {placePredictions.map(item =>
                        <div className="autocomplete-item" onClick={() => handleChange(item.place_id)}>
                            <FontAwesomeIcon className="autocomplete-icon" icon={faLocationDot} />
                            <p className="autocomplete-item_text">{item.description}</p>
                        </div>)}
                </div>
            </div>) || null
        }
    </div>

    </>;
};

export default Google;
