import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../Header';
import Footer from '../Common/Footer/Footer';
import CheckBoxField from '../Common/Checkbox/CheckBoxField';

import { messages } from '../../constants/messages';
import { selectCultures, selectActualLanguage, selectBreadcrumbs, selectCurrentStepNumber } from '../../store/common/common.selector';
import { setActualLanguage, setCurrencyPosition, setCurrentStepNumber } from '../../store/common/common.actions';
import { setStep } from '../../store/productInsurance/productInsurance.actions';

const LanguageSelection = () => {
    const dispatch = useDispatch();

    const cultures = useSelector(selectCultures);
    const lang = useSelector(selectActualLanguage);
    const currentStepNumber = useSelector(selectCurrentStepNumber);
    const breadcrumbs = useSelector(selectBreadcrumbs);

    const selectLanguage = (activeLang, position) => {
        dispatch(setActualLanguage(activeLang, true));
        dispatch(setCurrencyPosition(position));
    };

    const onNextStep = () => {
        const { step } = breadcrumbs[currentStepNumber + 1];
        dispatch(setCurrentStepNumber(currentStepNumber + 1));
        dispatch(setStep(step));
    };

    return <>
        <Header
            showLanguage
            stepper={false}
            stepsTitle={messages.SELECT_LANGUAGE} />
        {cultures.map(({ english_name, currency_position, uuid, code }) => <CheckBoxField
            key={uuid}
            label={english_name}
            onClick={() => selectLanguage(code, currency_position)}
            id={code}
            checked={lang === code}
        />)}
        <Footer
            showBack={false}
            onNextStep={onNextStep}
            nextText="NEXT"
            disabled={!lang}
        />
    </>;
};

export default LanguageSelection;
