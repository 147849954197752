import React from 'react';

export const generateArray = (min, max) =>
    [...Array(max - min + 1).keys()].map((_, i) => i + min);

export const isActiveStep = (stepper, step) => stepper.steps.indexOf(step) > -1;

export const getFileName = (header) => {
    const str = 'filename=';
    const startPos = header.indexOf(str);
    const name = header.slice(startPos).replace(str, '');
    return name;
};

export const automaticallyDownloadFile = (link, fileName) => {
    const element = document.createElement('a');
    element.setAttribute('href', link);
    element.setAttribute('download', fileName);
    document.body.appendChild(element);
    element.click();
    if (element.parentNode) {
        element.parentNode.removeChild(element);
    }
};

export const stringToJsx = (str, char = '*') => {
    const bold = str.substring(str.indexOf(char) + 1, str.lastIndexOf(char));
    const arr = str.split(char);

    return <p style={{ margin: 0 }}>{arr.map((item) => {
        if (item === bold) {
            return <b>{bold}</b>;
        }
        return <span>{item}</span>;
    })}</p>;
};
