import {
    TOGGLE_LOADING,
    HANDLE_LOADING_STATE,
    SET_LANGUAGE,
    SET_CURRENCY_POSITION,
    SET_PROFILE,
    SET_WHITE_LABELING_LOGO,
    SET_WHITE_LABELING_TITLE_COLOR,
    SET_WHITE_LABELING_PRIMARY_COLOR,
    SET_WHITE_LABELING_PRIMARY_OPACITY_COLOR,
    SET_WHITE_LABELING_PRIMARY_LIGHT_COLOR,
    SET_WHITE_LABELING_BACKGROUND_COLOR,
    SET_CURRENT_DETAILS_OPTION,
    SET_APP_FLOW,
    SET_WHITE_LABELING_HEADER_BACKGROUND,
    SET_WHITE_LABELING_INPUT_BACKGROUND,
    SET_INSURANCE_LOGO_VISIBLE,
    SET_THEME_READY,
    SET_GOOGLE_API_READY,
    SET_MINIMAL_LEAD_AMOUNT,
    SET_MODE,
    SET_CUSTOMER_PSEUDO_ID,
    SET_PULL_INSURANCE,
    /* SET_RECEIPT_PHOTO_REQUIRED, */
    SET_CONFIG_ATTRIBUTES,
    SET_TARGET_PHOTO,
    SET_BENEFICIARY_ATTRIBUTES,
    SET_SKIP_BENEFICIARY_ATTRIBUTES,
    SET_BREADCRUMBS,
    SET_CURRENT_STEP_NUMBER,
    SET_BENEFICIARY_ATTRIBUTE_VALUE,
    SET_ACCEPTABLE_FILES_EXTENSIONS,
    SET_TAGS,
    SET_BENEFICIARY_AND_POSTAL_CODE,
    SET_PROFILE_LOADING_STARTED,
    SET_STICKY_HEADER,
    SET_COUNTRY,
    SET_REDSYS_API_READY
} from './common.actionTypes';
import { onSetLanguage } from '../../constants/messages';
import { fetchWrapper } from '../../helpers/helpers';
// eslint-disable-next-line import/no-cycle
import {
    setStep, setCurrentGoodsId, setCurrentInsuranceId,
    getInsuranceTypes, getInsuranceProducts,
    setPaidStatus, setCurrentCurrency, setCurrentCurrencyCode, setItemTypeAttibutes,
    saveItemType, setCustomerAttributes, getInsuranceCategories, setPhotos, setRecognisedData,
    getInsurances, buildConfiguration
} from '../productInsurance/productInsurance.actions';
// eslint-disable-next-line import/no-cycle
import { setCertificateUrl } from '../payment/payment.actions';
import { selectCurrentGoodsId, selectInsuranceCategories, selectInsuranceTypes } from '../productInsurance/productInsurance.selector';
import { getItemsCount, isItemTypeSelected/* , mapItemTypeAttributes */ } from '../../helpers/dataHelpers';
import { setUserId } from '../../helpers/amplitude';
import { selectAppFlow, selectOfferState, selectCustomerPseudoId, selectActualLanguage, selectBeneficiaryAttributes, selectMode } from './common.selector';
import { APP_FLOWS, DETAILS_OPTIONS, OFFER_STATES, STEPS } from '../../constants/constants';

const setCustomerCountry = data => ({
    type: SET_COUNTRY, data
});
export const setBirthdaysAndPostalCode = data => ({ type: SET_BENEFICIARY_AND_POSTAL_CODE, data });
const setTags = tags =>
    ({ type: SET_TAGS, tags });

const setAcceptableFilesExtensions = extensions =>
    ({ type: SET_ACCEPTABLE_FILES_EXTENSIONS, extensions });

export const setProfileLoadingStarted = flag => ({ type: SET_PROFILE_LOADING_STARTED, flag });

export const setCurrentStepNumber = data => ({ type: SET_CURRENT_STEP_NUMBER, data });
export const setBreadcrumbs = data => ({ type: SET_BREADCRUMBS, data });

export const setBeneficiaryAttributeValue = data =>
    ({ type: SET_BENEFICIARY_ATTRIBUTE_VALUE, data });
export const setSkipBeneficiaryAttributes = flag =>
    ({ type: SET_SKIP_BENEFICIARY_ATTRIBUTES, flag });
export const setTargetPhoto = target => ({ type: SET_TARGET_PHOTO, target });

export const setConfigAttributes = data => ({ type: SET_CONFIG_ATTRIBUTES, data });
export const setBeneficiaryAttributes = data => ({ type: SET_BENEFICIARY_ATTRIBUTES, data });

export const setPullInsurance = ins => ({ type: SET_PULL_INSURANCE, ins });
export const setMode = mode => ({ type: SET_MODE, mode });
export const setCustomerPseudoId = id => ({ type: SET_CUSTOMER_PSEUDO_ID, id });

export const setAppFlow = data => ({ type: SET_APP_FLOW, data });
export const toggleLoading = data => ({ type: TOGGLE_LOADING, data });
export const handleLoadingState = data => ({ type: HANDLE_LOADING_STATE, data });
export const setLanguage = data => ({ type: SET_LANGUAGE, data });
export const setCurrencyPosition = data => ({ type: SET_CURRENCY_POSITION, data });
export const setWhiteLabelingLogo = data => ({ type: SET_WHITE_LABELING_LOGO, data });
export const setWhiteLabelingTitleColor = data => ({ type: SET_WHITE_LABELING_TITLE_COLOR, data });
export const setCurrentDetailsOption = option => ({ type: SET_CURRENT_DETAILS_OPTION, option });
export const setWhiteLabelingPrimaryColor = data => ({
    type: SET_WHITE_LABELING_PRIMARY_COLOR, data
});
export const setWhiteLabelingPrimaryOpacityColor = data => (
    { type: SET_WHITE_LABELING_PRIMARY_OPACITY_COLOR, data }
);
export const setWhiteLabelingPrimaryLightColor = data => (
    { type: SET_WHITE_LABELING_PRIMARY_LIGHT_COLOR, data }
);
export const setWhiteLabelingBackgroundColor = data => (
    { type: SET_WHITE_LABELING_BACKGROUND_COLOR, data }
);
export const setWhiteLabelingHeaderBackground = data => ({
    type: SET_WHITE_LABELING_HEADER_BACKGROUND, data
});
export const setWhiteLabelingInputBackground = data => ({
    type: SET_WHITE_LABELING_INPUT_BACKGROUND, data
});
export const setInsuranceLogoVisible = data => ({
    type: SET_INSURANCE_LOGO_VISIBLE, data
});
export const setThemeReady = data => ({
    type: SET_THEME_READY, data
});

export const setStickyHeader = data => ({
    type: SET_STICKY_HEADER, data
});

export const setMinimalLeadAmount = data => ({
    type: SET_MINIMAL_LEAD_AMOUNT, data
});

// TODO to move to productInsurances folder in the future
export const saveBeneficiaryAttributes = flag => (dispatch, getState) => {
    const attributes = selectBeneficiaryAttributes(getState());
    const actualLanguage = selectActualLanguage(getState());
    const body = {
        beneficiary_attributes: flag ? [attributes[0]] :
            attributes.map(({ key, type, value, label }) =>
                ({ key, type, value, label }))
    };

    return fetchWrapper({
        url: '/api/v1/customers/personality/',
        method: 'POST',
        body,
        headers: {
            'accept-language': actualLanguage
        }
    });
};

export const pullInit = () => (dispatch, getState) => {
    const id = selectCustomerPseudoId(getState());
    fetchWrapper({
        url: '/api/v1/pull_init/',
        method: 'POST',
        body: { customer_pseudo_id: id, external_id: id }
    })
        .then(({ token }) => {
            // localStorage.clear();
            localStorage.setItem('access_token', token);
            dispatch(getInsurances('pull'));
        });
};

export const getNonSpecificConfiguration = isFlowStarted => (dispatch, getState) => {
    const actualLanguage = selectActualLanguage(getState());
    return fetchWrapper({
        url: '/api/v1/customers/item-types/none_specific_configuration/',
        method: 'GET',
        headers: {
            'accept-language': actualLanguage
        }
    })
        .then((response) => {
            const flow = dispatch(buildConfiguration(response));
            let index = 0;
            if (isFlowStarted) {
                index = flow.findIndex(({ step }) => step === STEPS.INSURANCE_PRODUCT_SELECTION);
            }
            dispatch(setCurrentStepNumber(index));
            dispatch(setStep(flow[index].step));
        });
};

export const getConfigurationAttributes = id => (dispatch, getState) => {
    const actualLanguage = selectActualLanguage(getState());
    return fetchWrapper({
        url: `/api/v1/customers/item-types/${id}/configuration/`,
        method: 'GET',
        headers: {
            'accept-language': actualLanguage
        }
    })
        .then((response) => {
            dispatch(buildConfiguration(response, true));
        });
};

function setActualLanguage(language, isReloadData) {
    return (dispatch, getState) => {
        localStorage.setItem('lang', language);
        dispatch(setLanguage(language));
        onSetLanguage(language);
        if (isReloadData) {
            const appFlow = selectAppFlow(getState());
            if (appFlow === APP_FLOWS.INSURANCE) {
                dispatch(saveItemType(true));
                dispatch(getInsuranceTypes());
                dispatch(getInsuranceCategories());
                const id = selectCurrentGoodsId(getState());
                if (id) {
                    dispatch(getInsuranceProducts(id));
                }
            }
        }
    };
}


export const toggleLoaderState = loadingState => (
    dispatch =>
        dispatch(handleLoadingState(loadingState))
);

export const setProfile = data => ({ type: SET_PROFILE, data });

export const handleWhiteLabeling = profile => (dispatch) => {
    dispatch(setThemeReady(false));
    if (profile.white_labeling_settings) {
        if (profile.white_labeling_settings.logo) {
            dispatch(setWhiteLabelingLogo(profile.white_labeling_settings.logo));
        }
        if (profile.white_labeling_settings.title_color) {
            dispatch(setWhiteLabelingTitleColor(profile.white_labeling_settings.title_color));
        }
        if (profile.white_labeling_settings.primary_color) {
            dispatch(
                setWhiteLabelingPrimaryColor(profile.white_labeling_settings.primary_color)
            );
        }
        if (profile.white_labeling_settings.primary_opacity_color) {
            dispatch(
                setWhiteLabelingPrimaryOpacityColor(
                    profile.white_labeling_settings.primary_opacity_color
                )
            );
        }
        if (profile.white_labeling_settings.primary_light_color) {
            dispatch(
                setWhiteLabelingPrimaryLightColor(
                    profile.white_labeling_settings.primary_light_color
                )
            );
        }
        if (profile.white_labeling_settings.background_color) {
            dispatch(
                setWhiteLabelingBackgroundColor(
                    profile.white_labeling_settings.background_color
                )
            );
        }
        if (profile.white_labeling_settings.header_background_color) {
            dispatch(
                setWhiteLabelingHeaderBackground(
                    profile.white_labeling_settings.header_background_color
                )
            );
        }
        if (profile.white_labeling_settings.input_field_background_color) {
            dispatch(
                setWhiteLabelingInputBackground(
                    profile.white_labeling_settings.input_field_background_color
                )
            );
        }
        dispatch(setInsuranceLogoVisible(profile.white_labeling_settings.display_underwriter_logo));
        dispatch(setThemeReady(true));
    }
};

export const handleInvalidLink = () => dispatch => dispatch(setThemeReady(true));

const handleAcceptableFileExtensions = profile => (dispatch) => {
    const { document_file_types, photo_file_types } = profile;
    const extensions = [...document_file_types.map(e => `.${e}`), ...photo_file_types.map(e => `.${e}`)].join(',');
    dispatch(setAcceptableFilesExtensions(extensions));
};

export const handleProfile = profile =>
    // eslint-disable-next-line consistent-return
    (dispatch) => {
        dispatch(handleWhiteLabeling(profile));
        // let step = STEPS.PRODUCTS_LIST;

        dispatch(handleAcceptableFileExtensions(profile));

        const { photos = [] } = profile;
        if (photos.length) {
            dispatch(setPhotos(photos));
            // TODO to ask for what
            /* if (profile.photos_recognised_data?.length) {
                step = STEPS.PRODUCTS_LIST;
            } */
        }
        dispatch(setCurrentDetailsOption(DETAILS_OPTIONS.CAMERA));
        if (profile.item_type) {
            dispatch(setCurrentGoodsId(profile.item_type.id));
            // dispatch(getInsuranceProducts(profile.item_type.id));
        }
        if (profile.tags) {
            dispatch(setTags(profile.tags));
        }
        if (profile.item_attributes && profile.item_attributes.length) {
            dispatch(setItemTypeAttibutes(profile.item_attributes));
        }
        if (profile.item_type && profile.beneficiary_attributes &&
        profile.beneficiary_attributes.length) {
            dispatch(setBeneficiaryAttributes({
                beneficiaryAttributes: profile.beneficiary_attributes
            }));
        }
        if (profile.customer_attributes) {
            /* const ids = ['passport_id', 'foreigner_id', 'national_id', 'tax_number'];
            const num = ids.filter(id => profile[id])[0];
            debugger;
            console.log(num); */

            const labels = ['first_name', 'last_name', 'phone_number', 'email', 'birthday', 'address', 'tax_number', 'nationality', 'gender', 'passport_id', 'foreigner_id', 'national_id'];
            dispatch(setCustomerAttributes(labels
                .map(label => ({ value: profile[label], attribute: label }))
            ));
        }
        if (profile.insurance_product) {
            // step = STEPS.INSURANCE_PRODUCT_SELECTION;
            dispatch(setCurrentInsuranceId(profile.insurance_product.uuid));
        }
        if (profile.lead_uuid) {
            setUserId(profile.lead_uuid);
        }
        if (profile.transaction_currency_symbol) {
            dispatch(setCurrentCurrency(profile.transaction_currency_symbol));
        }
        if (profile.transaction_currency) {
            dispatch(setCurrentCurrencyCode(profile.transaction_currency));
        }
        if (profile.certificate_url) {
            dispatch(setCertificateUrl(profile.certificate_url));
        }
        if (profile.customer_country) {
            dispatch(setCustomerCountry(profile.customer_country));
        }
        /* if (profile.available_item_attributes) {
            const attributes = mapItemTypeAttributes(profile);
            dispatch(setItemTypeAttibutes(attributes));
        } */
        if (profile.payment && profile.payment.status === 'paid') {
            dispatch(setPaidStatus(profile.final_messages));
        }
    };

export const handleItemTypeSelection = (profile = {}) =>
    (dispatch, getState) => {
        const offerState = selectOfferState(getState());
        const isQualified = offerState === OFFER_STATES.QUALIFIED;
        // TODO
        const items = isQualified
            ? selectInsuranceTypes(getState())
            : selectInsuranceCategories(getState());

        if (!items) {
            return profile;
        }

        const itemsCount = getItemsCount(items, isQualified);
        if (itemsCount === 1) {
            const itemType = isQualified ? items[0] : items[0].item_types[0];
            dispatch(setCurrentGoodsId(itemType.id));
            if (profile.item_type_specific) {
                dispatch(saveItemType());
            }

            return { ...profile, item_type: itemType };
        }
        return profile;
    };

export const getProfile = () => (dispatch) => {
    dispatch(toggleLoading(true));
    return fetchWrapper({
        url: '/api/v1/customers/profile/',
        method: 'GET',
        headers: {
            'accept-language': localStorage.getItem('lang') || 'en-US'
        }
    })
        .then((profile) => {
        // dispatch(setProfileLoadingStarted(false));
            const { cultures } = profile;
            const defaultLanguage = localStorage.getItem('lang');
            let culture = cultures.find(cult => cult.code === defaultLanguage);
            if (!culture) {
                culture = cultures[0];
            }
            dispatch(setActualLanguage(culture.code));
            dispatch(setRecognisedData(profile.photos_recognised_data || []));
            dispatch(setProfile(profile));
            dispatch(setMinimalLeadAmount(profile.minimal_lead_amount));
            dispatch(handleWhiteLabeling(profile));
            dispatch(setCustomerPseudoId(
                profile.lead_customer_pseudo_id ||
                            profile.lead_uuid ||
                            profile.uuid ||
                            Date.now()
            ));
            const currencyPosition = cultures[0].currency_position;
            dispatch(setCurrencyPosition(currencyPosition));
            return profile;
        });
};

export const handleInsuranceProfile = profile => (dispatch, getState) => {
    const mode = selectMode(getState());
    if (mode === 'pull') {
        return null;
    }
    const data = {
        payment: !!(profile.payment && profile.payment.status === 'paid'),
        photos: !!profile.photos.length,
        selectedItem: !!profile.item_type || !!profile.insurance_product,
        item_type_specific: profile.item_type_specific,
        only_coverage: profile.only_coverage,
        insurance: Boolean(profile.insurance_product),
        tags: profile.tags
    };

    return Promise.all([dispatch(getInsuranceTypes()), dispatch(getInsuranceCategories())])
        .then(() => {
            const response =
                isItemTypeSelected(data) ? profile : dispatch(handleItemTypeSelection(profile));
            dispatch(handleProfile(response));
            return Promise.resolve(data);
        });
};


export const getPaymentStatusFromProfile = () => dispatch => fetchWrapper({
    url: '/api/v1/customers/profile/',
    method: 'GET'
})
    .then((profile) => {
        if (profile.payment && profile.payment.status === 'paid') {
            dispatch(setPaidStatus(profile.final_messages));
        }
    });

export const setGoogleAPIReady = data => ({
    type: SET_GOOGLE_API_READY, data
});

export const setRedsysAPIReady = data => ({
    type: SET_REDSYS_API_READY, data
});

export { setActualLanguage };
