import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLanguage } from '../../../helpers/customHooks';

import Header from '../../Header';
import Footer from '../../Common/Footer/Footer';
import CheckBoxSet from './CheckBoxSet';
import Card from './Card';
import Hint from '../ItemAttributes/Hint';
import HintModal from '../HintModal/HintModal';

import { messages } from '../../../constants/messages';
import { NIF_REGEXP } from '../../../constants/constants';

import { setCurrentStepNumber } from '../../../store/common/common.actions';
import { setStep, saveItemTypeAttrubutes, setItemTypeAttributeValue } from '../../../store/productInsurance/productInsurance.actions';
import { setActualFooterScreen } from '../../../store/payment/payment.actions';
import { selectCurrentStepNumber, selectBreadcrumbs, selectProfile } from '../../../store/common/common.selector';
import { selectItemTypeAttributes } from '../../../store/selectors';
import { previousPageNavigation, nextPageNavigation, changeAttribute } from '../../../helpers/amplitude';

import '../ItemAttributes/ItemAttributes.scss';
import './styles.scss';

const keys = ['insured_dob', 'insured_first_name', 'insured_last_name', 'insured_tax_id', 'female'];

const HelvetiaPostAttributes = () => {
    useLanguage();
    const { lead_uuid } = useSelector(selectProfile);
    const [hintModalState, setHintModalState] = useState({});
    const history = useHistory();
    const dispatch = useDispatch();
    const attributes = useSelector(selectItemTypeAttributes).filter(attr => attr.position === 'POST_OFFER');
    const currentStepNumber = useSelector(selectCurrentStepNumber);
    const breadcrumbs = useSelector(selectBreadcrumbs);
    // const [error, setError] = useState(null);
    const [isValid, setIsValid] = useState({});

    const dateAttributes = attributes.filter(({ key, value }) => key.includes('insured_dob') && value).sort();
    const sickness = attributes.filter(({ key }) => key === 'Sickness')[0];

    const onShowHint = ({ hint }) => {
        setHintModalState({
            shown: true,
            hint
        });
    };

    const handleDataChange = (value, key) => {
        setIsValid((prev) => {
            let v = value;
            if (key === sickness.key && value === 'true') {
                v = '';
            }
            if (key.includes('insured_tax_id') && value && !NIF_REGEXP.test(value)) {
                v = '';
            }
            return { ...prev, [key]: v };
        });
        changeAttribute({ uuid: lead_uuid, attribute: key, position: 2 });
        dispatch(setItemTypeAttributeValue({ key, value }));
        if (key === sickness.key && value === 'true') {
            onShowHint({ hint: messages.CANNOT_PROCEED });
        }
    };

    const onBackStep = () => {
        const { step } = breadcrumbs[currentStepNumber - 1];
        previousPageNavigation({ uuid: lead_uuid, page: step });
        dispatch(setCurrentStepNumber(currentStepNumber - 1));
        dispatch(setStep(step));
    };

    const extractErrors = (i) => {
        let obj = {};
        keys.map(k => attributes.map(({ key, value }) => {
            if (key === `${k}_${i + 1}`) {
                obj = { ...obj, [key]: value };
            }
            return key;
        }));
        return obj;
    };

    useEffect(() => {
        setIsValid(() => {
            let obj = { Sickness: sickness.value === 'false' };
            for (let i = 0; i < dateAttributes.length; i += 1) {
                obj = { ...obj, ...extractErrors(i) };
            }
            return obj;
        });
    }, []);

    const onCloseHint = () => setHintModalState({ shown: false });

    const onNextStep = () => {
        if (Object.values(isValid).every(item => item)) {
            dispatch(saveItemTypeAttrubutes())
                .then(() => {
                    const { step, payment } = breadcrumbs[currentStepNumber + 1];
                    nextPageNavigation({ uuid: lead_uuid, page: step });
                    if (payment) {
                        dispatch(setActualFooterScreen(step));
                        history.push('/payment');
                        // logEvent(EVENTS.ACTIVE_STEP, { page: 'Payment part' });
                    }
                    dispatch(setCurrentStepNumber(currentStepNumber + 1));
                    dispatch(setStep(step));
                });
        }
    };

    return <>
        <Header
            stepper
            stepsTitle={messages.HELVETIA_POST_TITLE} />
        <div className="beneficiary-postal-wrap">
            {
                dateAttributes.map((attr, i) =>
                    <Card
                        title={`${messages.INSURED_PERSON} ${i + 1}`}
                        data={keys.map(k => attributes.filter(({ key }) => key === `${k}_${i + 1}`)[0])}
                        key={i + 1}
                        handleDataChange={(v, k) => handleDataChange(v, k)}
                        // setValidationStatus={setValidationStatus}
                    />)
            }
            <div className="discovered-element-wrap">
                <div className="discovered-input-wrap">
                    <div className="discovered-input-title">
                        <div className="discovered-input-lable">{sickness.label}</div>
                        {sickness.hint && <Hint
                            onClick={() => onShowHint(sickness)}
                        />}
                    </div>
                </div>
                <CheckBoxSet
                    labels={[messages.YES, messages.NO]}
                    onChange={v => handleDataChange(v, sickness.key)}
                    data={sickness.value} />
            </div>
        </div>
        <HintModal {...hintModalState} onClose={onCloseHint} />
        <Footer
            showBack
            nextText={messages.SAVE_AND_NEXT}
            onNextStep={onNextStep}
            disabled={!Object.values(isValid).every(item => item)}
            onNavBack={onBackStep}
        />
    </>;
};

export default HelvetiaPostAttributes;
