const pt = {
CHOOSE_ITEM_TO_CHECK: "Please choose the item for which you want to check coverage",
INSURANCE_COMPANY_LABEL: 'Insurance Company',
INSURANCE_PRODUCT_LABEL: 'Insurance Product',
    //steps
    STEP1: 'Dadas',
    STEP2: 'Oferta',
    STEP3: 'Fecho',
    CHOOSE_ITEM_TO_INSURE: 'Por favor, escolha o item que você gostaria de segurar.',
    // Insurance overview
    INSURANCE_OVERVIEW: 'Resumo dos seguros',
    COVERAGE: 'Coberto:',
    NON_COVERED: 'Não coberto:',
    DOWNLOAD_INSURANCE_PRODUCT: 'Descarregar a descrição detalhada do seguro',
    DOWNLOAD_TERMS_AND_CONDITIONS: 'Descarregar termos & condições',
    PRODUCT_INSURANCE_STEP_ONE_TITLE: 'Apenas alguns passos para ficar coberto com Seguro',
    PRODUCT_INSURANCE_STEP_TWO_TITLE: 'Descubra os produtos a partir do recibo',
    PRODUCT_INSURANCE_STEP_THREE_TITLE: 'Seleccione o seu seguro.',
    PRODUCT_INSURANCE_STEP_TWO_HINT: 'Toque no que acabou de comprar e precisa de ser coberto.',
    PRODUCT_INSURANCE_STEP_ONE_ONLINE: 'Diga-nos, o que quer proteger.',
    ENTER_MORE_DETAILS: 'Introduza mais detalhes sobre o item que pretende segurar',
    TAKE_RECEIPT_PHOTO: 'Por favor, tire uma fotografia do recibo de compra.',
    CHECK_RECEIPT: 'Favor verificar se o recibo está claro na foto.',
    CHECK_ITEM_PHOTO: 'Por favor, verifique se o item está claro na imagem.',
    TAKE_ITEM_PHOTO: 'Por favor, tire uma foto do seu item a ser coberto.',
    BUTTON: 'Botão',
    MORE_DETAILS: 'Mostrar Detalhes',
    BACK: 'Voltar',
    SKIP_RECEIPT: 'Ignorar recibo',
    NEXT: 'Próximo',
    GENERAL_CONDITIONS: 'Condições gerais',
    LENGTH: 'Duração: ',
    MINIMUM_LENGTH: 'Duração mínima do contrato: ',
    MAXIMUM_LENGTH: 'Duração máxima do contrato: ',
    CONTRACT_TERMINATION: 'Rescisão do contrato: ',
    COVERAGE_LIST: 'Cobertura:',
    SEARCH: 'Procurar',
    OTHER: 'Outro',
    CLOSE: 'Fechar',
    PHOTO: 'Foto',
    OR: 'Ou',
    OPTIONS: 'Opções',
    SHOW_MORE: 'Mostrar mais',
    DO_NOT_INSURE: 'Não segurar este item',
    NOT_INSURABLE: 'Este item não é segurável',
    SELECT_ITEM_TYPE: 'Por favor seleccione o tipo de item',
    ADD_UNRECOGNIZED_ITEM: 'Adicionar um artigo',
    NAME: 'Nome',
    PLEASE_WAIT: 'Por favor aguarde',
    RECOGNIZE_PROCESSING: 'O pedido está a processar a(s) fotografia(s) para extrair a informação relevante.',
    PRICE: 'Preço',
    CANCEL: 'Cancelar',
    OK: 'Ok',
    SAVE_AND_NEXT: 'Próximo',
    MY_NEW_ITEM: 'O meu novo artigo amado',

    // Inform Screen
    DEAR_CUSTOMER: 'Estimado cliente,',
    TO_OFFER_YOU: 'para lhe oferecer as melhores opções de seguro, precisamos de mais informações sobre o artigo que acabou de adquirir. Pode fazer qualquer uma delas:',
    /* OPTION_TAKE_A_PHOTO: 'Tirar uma fotografia do recibo de compra',
    OPTION_UPLOAD_FROM_GGALLERY: 'Carregar uma foto do recibo de compra', */
    OPTION_TAKE_A_PHOTO: 'Tirar uma foto',
    OPTION_UPLOAD_FROM_GGALLERY: 'Carregar uma foto',
    OPTION_ENTER_MANUALLY: 'Introduzir dados manualmente',
    OPTION_TAKE_A_PHOTO_DESCRIPTION: 'O acesso à sua máquina fotográfica pode ser solicitado',
    OPTION_UPLOAD_FROM_GGALLERY_DESCRIPTION: 'O acesso a fotografias, meios de comunicação e ficheiros pode ser solicitado.',

    // Empty State
    SORRY: 'Desculpe',
    SORRY_MESSAGE: 'Pedimos desculpa, mas o link da oferta já não é válido. Teremos todo o prazer em fazer-lhe uma oferta com a sua próxima compra.',
    DONE: 'Feito',

    // Payment Methods
    PAYMENT_METHODS: 'Formas de pagamento',
    HOW_DO_YOU_WANT_TO_PAY: 'Como é que quer pagar?',
    YOU_WILL_BE_SPENDING: 'Gastará',
    FOR_BUYING_INSURANCE_POLICIES: 'para a compra de apólices de seguro',
    CONFIRM_YOUR_CARD_DETAILS: 'Por favor, introduza os detalhes do seu cartão de pagamento.',
    HDRC_MONEY_BACK: 'Por favor, introduza os detalhes do seu cartão de pagamento.',
    CARDHOLDER_NAME: 'Nome do titular do cartão',
    CARD_NUMBER: 'Número do cartão',
    EXPIRY_DATE: 'Data de expiração',
    CVV: 'Código de segurança (CVV)',
    CONFIRM_AND_PAY: 'Confirmar e comprar',
    I_HAVE_SUCCESSFULLY_READ: 'Li e estou de acordo com os termos e condições.',
    ENTRY_A_FEW_MORE_DETAILS: 'Introduza mais alguns detalhes sobre si',
    FIRST_NAME: 'Primeiro nome',
    FAMILY_NAME: 'Nome de família',
    BIRTHDAY: 'Aniversário',
    EMAIL_ADDRESS: 'Endereço de correio electrónico',
    PHONE_NUMBERS: 'Número de telefone',
    ADDRESS: 'Endereço',
    ENTRY_HERE: 'Entre aqui',
    MONTHLY: 'por mês',
    WEEKLY: 'por semana',
    YEARLY: 'por ano',
    OVERVIEW_YEARLY: 'Anualmente',
    OVERVIEW_MONTHLY: 'Monthy',
    OVERVIEW_WEEKLY: 'Semanalmente',
    WEEKS: 'semanas',
    MONTHS: 'meses',
    YEARS: 'anos',
    WHAT_IS_NEXT: 'O que se segue:',
    INSURANCE_CERTIFICATE: (email) =>  `O certificado de seguro será gerado e enviado para ${email}.`,

    DEDUCTIBLE: 'Dedutível: ',


    MANDATORY_FIELD: 'Este campo é obrigatório',
    INVALID_FORMAT: 'Valor inválido',
    PRICE_SHOULD_BE_LOWER: 'O preço deve ser inferior ao preço da transacção',
    DATE_SHOULD_BE_PAST: 'A data deve estar no passado',

    // Congratulations!
    CONGRATULATIONS: 'Parabéns!!',
    GLAD_TO_INFORM_YOU: 'Temos o prazer de o informar que adquiriu com sucesso a cobertura. O seu produto está agora seguro.',

    YOU_WILL_RECEIVE: 'Receberá nos próximos minutos um e-mail de confirmação com o certificado de cobertura.',

    YOU_ARE_PROTECTED: 'Está protegido',
    ALREADY_PURCHASED: 'Temos o prazer de o informar que já comprou um seguro e que o seu artigo amado já está protegido.',

    DOWNLOAD: 'descarregar',
    YOU_CAN_DOWNLOAD_HERE: 'Pode descarregar o seu certificado de seguro aqui:',
    BACK_TO_OVERVIEW: 'voltar à vista geral',

    // contracts list
    YOUR_POLICY_PURCHASES: 'Os seus seguros',
    PURCHASED_ON: 'Comprado em',
    EXPIRING_ON: 'A expirar em',
    WHATS_COVERED: 'Coberto é',
    FILE_A_CLAIM: 'Apresentar uma reclamação',
    REACTIVATE: 'Reativar',

    // contract details
    DOWNLOAD_PURCHASE_RECEIPT: 'Descarregar recibo de compra',
    INSURCANCE_CONTRACT: 'Contrato de seguro',
    ITEM_IS_PROTECTED_BY_INSURANCE: 'O artigo é protegido pelo seguro',
    INSURANCE_COVERAGE_START: 'Início da cobertura de seguro',
    INSURANCE_COVERAGE_UNTIL: 'Cobertura de seguro até',
    DOWNLOAD_PRODUCT_SHEET: 'Descarregar a ficha do produto',
    DOWNLOAD_INSURANCE_CERTIFICATE: 'Descarregar o certificado',
    CANCEL_CONTRACT: 'Cancelar Contrato',

    // claim form
    PROVIDE_INFO_FOR_CLAIM: 'Por favor, forneça-nos mais algumas informações, antes de processarmos a sua reclamação',
    ATTACH_PHOTO_VIDEO: 'Anexar, se relevante, fotografias ou vídeos mostrando os danos',
    DESCRIBE_WHAT_HAPPEND: 'Por favor descreva o que aconteceu',
    ENTER_HERE: 'Entre aqui',
    SUBMIT_CLAIM: 'Submeter a reclamação',
    PLEASE_ATTACH_FILES: 'Favor anexar ficheiros',
    THANKS_FOR_REQUEST: 'Obrigado por submeter a sua reclamação. Teremos todo o prazer em processá-la e dar-lhe uma resposta nos próximos dias.',
    YOUR_INSURANCE_COVERS: 'O seu seguro cobre a reparação apenas por um dos nossos parceiros certificados. Pode encontrar aqui uma oficina de reparação na sua proximidade.',
    SHOW_PARTNER_NETWORK: 'Mostrar rede de parceiros',

    // confirmation
    YOUR_CONTRACT_HAS_BEEN_CANCELLED: 'O seu contrato foi cancelado. Receberá em breve um e-mail de confirmação',
    GOT_IT: 'Já o tenho',
    THE_INSURANCE_WILL_BE_CANCELLED: 'O seguro será cancelado a partir de',
    ARE_YOU_SURE_TO_CANCEL: 'Tem a certeza de que pretende cancelar a protecção do seu',
    RETURN: 'Regresso',
    CONFIRM_REACTIVATION: 'Por favor, confirme que pretende reactivar o seguro.',
    YOUR_CONTRACT_HAS_BEEN_REACTIVATED: 'O seu seguro foi reactivado. Receberá em breve um correio de confirmação.',

    // contract statuses
    ACTIVE: 'activo',
    PENDING_CANCELLATION: 'cancelado',
    VOID: 'terminado',

    BENEFICIARY_TITLE: 'Quem deve ser incluído no seu seguro?',
    BENEFICIARY_QUESTION: 'Whom would you like to cover?',
    BENEFICIARY_OPTION1: 'Eu mesmo',
    BENEFICIARY_OPTION2: 'eu e minha família/casa',
}

export default pt;
