import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { messages } from '../../constants/messages';

import '../styles/PaymentProducts.scss';

const PaymentProducts = ({ children }) => {
    const location = useLocation();
    const { pathname, search } = location;
    const [isDisplayed, setIsDisplayed] = useState();

    const pageSwitch = (path) => {
        switch (true) {
            case path.includes('settings'):
                return messages.PAYMENT_PRODUCT_SETTINGS;
            case path.includes('create'):
                return messages.CREATE_PAYMENT_PRODUCT;
            default:
                return messages.EDIT_PAYMENT_PRODUCT;
        }
    };

    useEffect(() => {
        setIsDisplayed(`${pathname}${search}` !== '/app?tab=payment-products');
    }, [location]);

    return (
        <section className="payment-products">
            <header className="page-title payment-products__title">
                {messages.PAYMENT_PRODUCTS}
            </header>
            <main className="payment-products__content">
                {
                    isDisplayed
                    &&
                    <div className="content__stepper">
                        <Link to="/app?tab=payment-products">
                            {messages.PAYMENT_PRODUCTS}
                        </Link>
                        <span className="stepper__dot" />
                        {pageSwitch(pathname)}
                    </div>
                }
                {children}
            </main>
        </section>
    );
};
export default PaymentProducts;
