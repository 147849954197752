import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';
import SideBar from '../components/SideBar/SideBar';
import createTheme from '../helpers/theme';
import { setupCustomStyles } from '../helpers/buildStyles';
import { fetchActiveWhiteLabeling } from '../../store/merchants/merchants.actions';
import { styles } from '../../store/merchants/merchants.selector';
import '../styles/merchants.scss';

const MerchantsScreen = ({ children }) => {
    const [theme, setTheme] = useState(null);
    const dispatch = useDispatch();
    const cStyles = useSelector(styles);

    useEffect(() => {
        dispatch(fetchActiveWhiteLabeling());
    }, []);

    useEffect(() => {
        setupCustomStyles(cStyles);
        setTheme(createTheme(cStyles));
    }, [cStyles]);

    return theme &&
    <ThemeProvider theme={theme}>
        <div className="merchants-wrapper">
            <SideBar />
            <main className="merchants-main">
                {children}
            </main>
        </div>
    </ThemeProvider>;
};

export default MerchantsScreen;
