import React, { useEffect, useState, useRef } from 'react';
import { messages } from '../../../../../constants/messages';
import Header from './Header';
import Row from './Row';
import './table.scss';

const Table = ({ cn = '',
    data,
    type,
    fields,
    header,
    onEdit,
    onSort,
    sort,
    isEditable = false,
    scrollBottomCallback }) => {
    const [values, setValues] = useState([]);
    const el = useRef(null);
    const [blocked, setBlocked] = useState(null);

    useEffect(() => {
        if (el.current && !(data.length >= el.current.children.length)) {
            el.current.scrollTo(0, 0);
        }
        setValues(data.map(d => fields.reduce((acc, f) => ({ ...acc, [f]: d[f] === null ? '' : d[f] }), {})));
    }, [data, el.current]);

    const onScroll = () => {
        const element = el.current;
        if (!blocked &&
            Math.round(0.7 * element.scrollHeight - element.clientHeight - element.scrollTop) < 1) {
            setBlocked(true);
        }
    };

    useEffect(() => {
        if (blocked) {
            scrollBottomCallback(el.current.children.length, () => { setBlocked(false); });
        }
    }, [blocked]);

    return values.length ? <div className="table-container"><div className={`${cn} table-wrapper`}>
        <Header titles={header} onSort={onSort} sort={sort} />
        <div ref={el} className="table-wrapper_body" onScroll={type === 'clients' ? onScroll : null}>
            {
                (values.length && values.map((v, i) => <Row
                    onEdit={() => onEdit(data[i])}
                    data={v}
                    key={i}
                    isEditable={isEditable} />)) || null
            }
        </div>
    </div></div> : <div className="no-result">{messages.NO_RESULT}</div>;
};

export default Table;
