const es = {
/* SELECT_LANGUAGE: 'Selecciona tu idioma', */
list_employment_sector: ['Agricultura', 'Construcción/Inmobiliaria', 'Comercio', 'Ingeniería/Consultoría', 'Finanzas y seguros', 'Salud', 'Hostelería/Gastronomía', 'Minería'],
list_employment_status: ['Empleado', 'Empleado - Puesto directivo', 'Pensionista', 'Autónomo', 'Buscador de empleo', 'Estudiante'],
CUSTOMER_BATCH_UPLOAD: 'Carga de lotes de clientes',
DATE: 'Fecha',
TITLE: 'Título',
SUMMARY: 'Resumen',
INSURED_CAPITAL: 'Capital asegurado: ',
//customer journey
HEALTH_RELATED: 'Seguros de salud',
MANDATORY_HEALTH_INSURANCE: 'Seguro de salud obligatorio',
COMPLEMENTARY_HEALTH_INSURANCE: 'Seguro de salud complementario',
COMPLEMENTARY_HEALTH_INSURANCE_DESCRIPTION: 'En la mayoría de los casos, los costos de un seguro de salud complementario superan sus beneficios',
LIFE_RELATED: 'Seguros relacionados con la vida y los pagos',
PAYMENT_PROTECTION_INSURANCE: 'Seguro de protección de pagos',
PAYMENT_PROTECTION_INSURANCE_DESCRIPTION: 'Un seguro de vida de riesgo proporcionaría una protección superior a un costo menor',
LEGAL_RELATED: 'Seguros relacionados con responsabilidad y derecho',
PRIVATE_LIABILITY_INSURANCE: 'Seguro de responsabilidad civil privada',
PRIVATE_LIABILITY_INSURANCE_DESCRIPTION: 'El seguro de responsabilidad civil privada es uno de los seguros más importantes. No es obligatorio, pero es necesario si quieres proteger tu existencia financiera',
PROPERTY_RELATED: 'Seguro relacionado con la propiedad y la riqueza',
ASSISTANCE_INSURANCE: 'Seguro de asistencia',
PASSENGER_INSURANCE: 'Seguro de pasajeros',
PASSENGER_INSURANCE_DESCRIPTION: 'Un seguro de pasajeros solo tiene sentido para los pasajeros que no residen en Suiza o en un país de la UE',
SMARTPHONE_INSURANCE: 'Seguro para smartphones',
WARRANTY_EXTENSION_INSURANCE: 'Extensión de garantía',
MANDATORY_ACCIDENT_INSURANCE: 'Seguro de accidentes obligatorio',
MANDATORY_ACCIDENT_INSURANCE_DESCRIPTION: 'Los tratamientos por lesiones debido a un accidente no están cubiertos por el seguro de salud, sino por el seguro de accidentes',
PRIVATE_ACCIDENT_INSURANCE: 'Seguro de accidentes privado o semi-privado',
PRIVATE_ACCIDENT_INSURANCE_DESCRIPTION: 'Los pacientes con un seguro semi-privado y privado suelen ser tratados por médicos experimentados incluso en caso de necesidades médicas menos graves. Nota: En general, los pacientes con una enfermedad o lesión crítica siempre son tratados por médicos experimentados.',
DENTAL_INSURANCE: 'Seguro dental',
KIDS_DENTAL_INSURANCE: 'Seguro dental para niños',
KIDS_DENTAL_INSURANCE_DESCRIPTION: 'Las correcciones dentales para niños pueden costar rápidamente más de CHF 15\'000',
DAILY_BENEFITS_INSURANCE: 'Seguro de indemnización diaria',
DAILY_BENEFITS_INSURANCE_DESCRIPTION: 'El seguro de indemnización diaria paga al asegurado una cantidad de dinero predefinida. Esto es especialmente beneficioso para los trabajadores autónomos que dependen de sus ingresos',
PRIVATE_HOSPITAL_INSURANCE: 'Seguro de hospital privado o semi-privado',
PRIVATE_HOSPITAL_INSURANCE_DESCRIPTION: 'Los pacientes con un seguro semi-privado y privado suelen ser tratados por médicos experimentados incluso en caso de necesidades médicas menos graves. Nota: En general, los pacientes con una enfermedad o lesión crítica siempre son tratados por médicos experimentados',
WORK_INSURANCE: 'Seguro contra la incapacidad para trabajar',
WORK_INSURANCE_DESCRIPTION: 'La incapacidad para trabajar debido a enfermedades o problemas psicológicos no está bien cubierta por los seguros obligatorios. Típicamente, la incapacidad para trabajar debido a una enfermedad conduce a una pérdida de ingresos del 40%',
DEATH_INSURANCE: 'Seguro de vida de riesgo',
DEATH_INSURANCE_DESCRIPTION: 'El seguro de riesgo paga una suma global y/o una pensión en caso de fallecimiento. Esto permite el pago de deudas pendientes y/o asegura que la pareja y los niños estén financieramente protegidos.',
A_POLICY: 'Póliza de seguro 3a',
A_POLICY_DESCRIPTION: 'En general, los productos bancarios son la mejor opción para acumular riqueza. Sin embargo, bajo ciertas condiciones, los seguros de vida, por ejemplo, para empresarios y autónomos, ofrecen ventajas importantes como vehículos de ahorro',
WHOLE_LIFE_INSURANCE: 'Seguro de vida entera / ahorro',
WHOLE_LIFE_INSURANCE_DESCRIPTION: 'En general, los productos bancarios son la mejor opción para acumular riqueza. Sin embargo, bajo ciertas condiciones, los seguros de vida, por ejemplo, para empresarios y autónomos, ofrecen ventajas importantes como vehículos de ahorro',
BUSINESS_INSURANCE: 'Seguro de responsabilidad civil empresarial',
BUSINESS_INSURANCE_DESCRIPTION: 'Dependiendo de tu profesión (por ejemplo, médico, arquitecto, etc.) necesitas un seguro de responsabilidad civil empresarial',
LEGAL_INSURANCE: 'Seguro de protección legal',
LEGAL_INSURANCE_DESCRIPTION: 'El seguro de protección legal cubre los costos legales si necesitas defenderte en relación con tu empleo, tu vivienda alquilada o en relación con la movilidad',
CAR_INSURANCE: 'Seguro de responsabilidad civil del automóvil',
MOTO_INSURANCE: 'Seguro de responsabilidad civil de la motocicleta',
BOAT_INSURANCE: 'Seguro de responsabilidad civil del barco',
BUILDING_INSURANCE: 'Seguro de edificio',
HALF_CAR_INSURANCE: 'Seguro de automóvil a todo riesgo o parcial',
HALF_CAR_INSURANCE_DESCRIPTION: 'Se recomienda un seguro a todo riesgo si necesitas un coche para uso diario o si tu coche actual tiene menos de 5 años',
HOUSEHOLD_INSURANCE: 'Seguro del hogar',
PET_INSURANCE: 'Seguro para mascotas',
PET_INSURANCE_DESCRIPTION: 'Los tratamientos para mascotas se están volviendo cada vez más sofisticados y caros. Si no quieres elegir entre gastar CHF 15\'000 o sacrificar a tu mascota, entonces un seguro para mascotas podría ser lo correcto para ti',
TRAVEL_INSURANCE: 'Seguro de viaje',
BICYCLE_INSURANCE: 'Seguro de bicicleta',
ITEM_RELATED_INSURANCE: 'Seguro relacionado con el artículo (por ejemplo, joyas, instrumento musical)',
ITEM_RELATED_INSURANCE_DESCRIPTION: 'Solo para los artículos que realmente necesitas y cuyo valor supera los 2\'500 CHF',
STEP_TITLES: ['Cuéntanos sobre ti', 'Permítenos entender mejor tu contexto', 'Descubre tus recomendaciones'],
WERE_BORN: 'Naciste el',
YOU_ARE: 'Eres',
FAMILY_STATUSES: [
     { key: 'single', name: 'soltero' },
     { key: 'married', name: 'casado' },
     { key: 'divorced', name: 'divorciado/viudo' }],
JOB_STATUSES: [
     { key: 'employed', name: 'empleado' },
     { key: 'freelancer', name: 'autónomo' },
     { key: 'entrepreneur', name: 'empresario' },
     { key: 'job_seeker', name: 'buscador de empleo' },
     { key: 'student', name: 'estudiante' },
     { key: 'retired', name: 'jubilado' }],
YEARLY_INCOME_IS: 'Tu ingreso anual es',
INCOME_STATUSES: [
    { key: 'below', name: 'menos de CHF 25’725' },
    { key: 'between', name: 'entre CHF 25’725 y CHF 88’200' },
    { key: 'above', name: 'más de CHF 88’200' }],
YOU_TRAVEL: 'Viajas',
TRAVEL_STATUSES: [
    { key: 'rarely', name: 'raramente' },
    { key: 'once_per_year', name: 'una vez al año' },
    { key: 'more', name: 'dos veces al año o más' }],
YOU_LIVE_IN: 'Vives en',
APARTMENT_OPTIONS: [
    { key: 'rented_room', name: 'Piso compartido/Residencia de estudiantes' },
    { key: 'rented_home', name: 'Piso alquilado' },
    { key: 'owned_apartment', name: 'Piso propio' },
    { key: 'owned_home', name: 'Casa propia' }],
HOUSEHOLD: 'El mobiliario de tu casa es',
HOUSEHOLD_STATUSES: [
    { key: 'spartanic', name: 'espartano' },
    { key: 'normal', name: 'normal' },
    { key: 'luxury', name: 'lujoso' }],
CARE_FOR: 'Vives con o eres responsable de',
CARE_LIST: [
    { key: 'partner', name: 'pareja' },
    { key: 'kids', name: 'niño(s)' },
    { key: 'dogs', name: 'perro(s)' },
    { key: 'cats', name: 'gato(s)' },
    { key: 'horses', name: 'caballo(s)' }],
KIDS_ABOVE_6: '¿Tienes niños menores de 6 años?',
KIDS_ABOVE_OPTIONS: [{ key: 'yes', name: 'Sí' }, { key: 'no', name: 'No' }],
IS_LOAN: '¿Tienes una hipoteca o un préstamo pendiente?',
LOAN_OPTIONS: [
   { key: 'yes', name: 'Sí' },
   { key: 'no', name: 'No' }],
YOU_OWN: 'Posees',
PROPERTY_LIST: [
   { key: 'car', name: 'coche(s)' },
   { key: 'motorbike', name: 'moto(s)' },
   { key: 'camper', name: 'autocaravana(s)' },
   { key: 'boat', name: 'barco(s)' },
   { key: 'bicycle', name: 'bicicleta(s)/e-bike(s)' },
   { key: 'scooter', name: 'scooter(s) eléctrico(s)' }],
LOSSES: 'En cualquier momento estás dispuesto y puedes soportar pérdidas inesperadas de hasta',
LOSSES_LIST: [
   { key: 500, name: 'CHF 500' },
   { key: 1000, name: 'CHF 1’000' },
   { key: 2500, name: 'CHF 2’500' },
   { key: 5000, name: 'CHF 5’000' },
   { key: 100000, name: 'CHF 10’000 o más' }],
HEALTH: 'Cuando se trata de tu salud',
HEALTH_EXPERIENCE: [
   { key: 'standard_coverage', name: 'Estás satisfecho con el estándar legal' },
   { key: 'experienced_only', name: 'Solo quieres ser tratado por médicos experimentados' },
   { key: 'private_clinics', name: 'Quieres tener acceso a clínicas privadas' },
   { key: 'alternative_treatment', name: 'Quieres tener acceso a tratamiento médico complementario/alternativo' }],
INFORMATION_POINTS: ['Lo que verás a continuación no son recomendaciones. Solo mostraremos los seguros típicos con fines de orientación, que se recomiendan a las personas que han respondido al cuestionario de manera similar', 'Para una recomendación de seguro personalizada, te recomendamos que solicites una cita de asesoramiento personal.'],
NECESSARY: 'Necesario',
RECOMMENDED: 'Recomendado',
WORTH_CONSIDERING: 'Vale la pena considerar',
MAKES_RARELY_SENSE: 'Rara vez tiene sentido',
STEPS: ['Sobre mí', 'Mi contexto', 'Seguros'],
WHOLE_LIFE_INSURANCE_DESCRIPTION_SHORT: "En general, los productos bancarios son la mejor opción para crear patrimonio",
//////////
// Broker translation
WARNING: 'El documento CSV se ha cargado correctamente. Había advertencias para una o varias entradas.',
PRODUCT: 'Producto',
INSURANCE: 'Seguro',
CONTRACT_DATE: 'Fecha del contrato',
PREMIUM: 'Prima',
BEFORE_USING: 'Antes de utilizar el portal Alyyn, su cuenta debe estar conectada a WhatsApp.',
PLEASE_INFORM: 'Por favor, informe a su jefe de equipo de que necesita conectarse primero a una cuenta de WhatsApp.',
TO_CREATE_AND_CONNECT: 'Para crear y conectar una cuenta de WhatsApp a Alyyn por favor asegúrese de que:',
POINTS: ['Su empresa tiene una página de Facebook y usted es el administrador de esa página', 'Tienes la tarjeta SIM proporcionada por Alyyn y puedes recibir SMS con ella'],
CREATE_WHATSAPP_ACCOUNT: 'Crear una cuenta de WhatsApp',
WHATSAPP_ACCOUNT_CREATED: 'La cuenta de WhatsApp se ha creado correctamente y se ha conectado a su portal.',
MOMENT_TO_CONFIGURE: 'Necesitamos un momento para configurar todo para tu cuenta.',
WILL_BE_NOTIFIED: 'Se le notificará tan pronto como esto se hace',
// Broker menu items' titles
CLIENTS: 'Clientes',
WHATSAPP: 'WhatsApp',
NEW_DIGITAL_OFFER: 'Nueva oferta digital',
OFFER_TRACKING: 'Seguimiento de oferta',
USER_MANAGEMENT: 'Gestión de usuarios',
CONTRACTS: 'Contratos',
// End for Broker menu items' titles
// Profile
MANAGE_PROFILE: 'Gestionar perfil',
LOG_OUT: 'Cerrar sesión',
// End for Profile
STEP_1: 'Paso 1:',
STEP_2: 'Paso 2:',
STEP_3: 'Paso 3:',
SELECT_ADVISOR: 'Seleccione...',
SELECT_INSURANCE: 'Seleccione el producto de seguro que desea ofrecer.',
SELECT_CONTACT: 'Seleccione al cliente o introduzca los datos del cliente.',
SEARCH_BY_USER: 'Buscar por un miembro del equipo',
SEARCH_CONTACT: 'Buscar un cliente',
OFFER_CONGRATS: 'La oferta ha sido enviada al cliente. Siempre puede seguir el estado actual en la pantalla de seguimiento de ofertas.',
NEED_HELP: '¿Necesitas ayuda?',
WRITE_EMAIL: 'Escríbenos un correo electrónico',
SELECT_FILE: 'Haga clic y seleccione o arrastre y suelte un archivo aquí',
UPLOAD: 'Subir un archivo',
DELIMITER: 'Delimitador',
SEMICOLON: 'Punto y coma',
COMMA: 'Coma',
IMPORT_A_LIST: 'Importar una lista de clientes',
EXISTING_CONTACTS_SHALL_BE: 'Los contactos existentes se',
OVERWRITTEN: 'Sobrescritos con nuevos dato',
SKIPPED: 'Omitidos',
DOWNLOAD_THE_FOLLOWING: 'Descarga lo siguiente',
CSV_TEMPLATE: 'archivo de plantilla CSV',
FILL_DATA: 'Complete el archivo con los datos de su cliente. Puede encontrar una descripción detallada sobre el formato de datos válido en este',
PDF_DESCRIPTION: 'documento de descripción detallada',
SELECT_AND_UPLOAD: 'Seleccione y cargue el documento',
CSV_UPLOADED: 'El archivo CSV se cargó correctamente',
NO_RESULT: 'Sin resultados',
CUSTOMER_DETAILS: 'Datos del cliente',
USER_DETAILS: 'Detalles del usuario',
// Tracking page
PRODUCT_OFFERED: 'Producto ofrecido',
TRACKING: 'Seguimiento',
// End for Tracking page
// Contacts page
IMPORT_CSV: 'Importar una lista de clientes',
ADD_CUSTOMER: 'Agregar un cliente',
ADD_USER: 'Agregar un(a) miembro',
INSURANCE_CONTRACTS: 'Contratos de seguro',
IS_ISSUER_STAFF: 'Estado del emisor',
IS_ACTIVE: 'Activo',
EXTERNAL_ID: 'Identificación externa',
ROLE: 'Función',
ADVISOR: 'Asesor',
TEAM_MANAGER: 'Jefe de equipo',
// End for Contacts page
// Statuses
LINK_CLICKED: 'Enlace pulsado',
NOT_SENT: 'No enviado',
PENDING: 'Pendiente',
FAILED: 'Fallido',
CREATED: 'Creado',
DELIVERED: 'Entregado',
READ: 'Leer',
SENT: 'Mensaje enviado',
SUBMITTED: 'Enviado',
OPENED: 'Enlace abierto',
LAST_LOGIN: 'Última actividad del cliente',
PRE_OFFER: 'Formulario de preoferta completado',
VIEWED: 'Productos vistos',
POST_OFFER: 'Formulario de postoferta completado',
SELECTED: 'Producto seleccionado',
PROFILE: 'Perfil completado',
PURCHASED: 'Comprado',
// End for Statuses
SAVE: 'Guardar',
//End
CONSENT: 'Autorización de domiciliación bancaria a la aseguradora para el pago de las primas subsiguientes',
CONSENT_OPTIONS: [
'Deseo recibir comunicaciones comerciales, publicitarias, promocionales y para la realización de encuestas, incluso por correo electrónico u otros medios de comunicación equivalentes.',
'Acepto que Helvetia Seguros complete mi perfil mediante la utilización de fuentes externas, con la finalidad de realizarme ofrecimientos comerciales de sus productos.',
'Acepto que Helvetia Seguros contacte conmigo una vez haya expirado la validez del proyecto o resuelto el contrato de seguro para ofrecerme nuevas condiciones u otros productos.'
],
I_HAVE_SUCCESSFULLY_READ_INSURAMA: 'Acepto los términos y condicones y solo los tratamientos de datos imprescindibles para el mantenimiento de la relación contractual y recibir el “Documento de información sobre el producto de seguro” via web.',
DETAILS_DOWNLOAD: 'Descargar detalles.',
TRY_AGAIN: 'Ha ocurrido un error. Por favor, inténtelo de nuevo.',
LAST_STEP: 'Últimos pasos',
NEXT_STEPS: {title: 'Los siguientes pasos son:', steps: ['El primer pago debe ser realizado con su tarjeta bancaria. Por lo tanto, en el siguiente paso será redirigido a una página del Santander. Los pagos siguientes se realizarán mediante cargo a la cuenta bancaria que haya proporcionado previamente.',
'Después de realizar el pago con la tarjeta, será redirigido de nuevo a este flujo de clientes. Confirmaremos que usted y su familia están cubiertos por el Plan de Asistencia Familiar.',
'Puede descargar la póliza de seguro directamente en la última página, y se la enviaremos en unos minutos por correo electrónico.']
},
PRE_CONTRACT_ACCEPT: 'He leído y acepto el documento de información precontractual',
AMAZON_RETURN: 'Le ofrecemos un vale de Amazon con un valor de 10 euros.',
REDIRECT: 'Después de aceptar los términos y condiciones y hacer clic en "Siguiente", se abrirá una nueva pestaña y será redirigido al sitio web de Insurama. Insurama, como agencia de suscripción de Helvetia Global Solutions Ltd, es quien ofrece seguros para teléfonos móviles. Para garantizar la protección de su teléfono móvil, le solicitamos que continúe ingresando sus datos de pago.',
TRAVEL: {
  cancellation: {
    description: 'Anulación de viaje'
  },
  hospitalization: {
    description: 'Hospitalización'
  },
  repatriation: {
    description: 'Repatriación'
  },
  accident: {
    description: 'Accidente'
  },
  wallet_theft: {
    description: 'Robo de cartera y dinero en efectivo'
  },
  device_theft: {
    description: 'Robo de dispositivos móviles y joyas'
  }
},
FUTURE_DATE: 'Por favor, introduzca una fecha futura',
TRAVEL_PRE_SCREEN_TITLE: 'Su viaje',
HELVETIA_EXPLANATION: {
medical: {title: 'Asistencia Médica', points: [
'Una consulta de cualquiera de las especialidades médicas concertadas por asegurado', 'Acceso, a precios preferentes, a médicos especialistas', 'Línea médica de información telefónica 24 horas *gratuita*', 'Segunda opinión médica internacional *gratuita*'
], hints: ['Se incluye cualquier especialidad en el cuadro médico (excepto servicios especiales). Por ejemplo: <br>- Ginecología<br>- Dermatología<br>- Medicina Estética<br>- Psicología<br>- Cardiología<br>- Homeopatía<br>- Acupuntura<br><br>Para ver la lista completa, consulte las condiciones generales.', null, 'Mejoramos los servicios y ampliamos las especialidades. Especialidades actuales:<br>- Medicina General (sin límite de consultas): en medicina general, ampliamos los servicios a los fines de semana. Sábados y domingos de 9 a 18h<br>- Pediatría sin límite de consultas<br>- Psicología (máximo 5 sesiones anuales por asegurado)', null], description: 'Asistencia médica'},
dental: {title: 'Asistencia Dental', points: [
'Una limpieza dental *gratuita* por asegurado/año', 'Una radiografía y una extracción simple *gratuita* por asegurado/año', 'Acceso a una amplia red dental a precios preferentes'
], description: 'Asistencia dental'},
legal: {title: 'Asistencia Jurídica y Legal', points: ['Asistencia telefónica *gratuita* referida a cualquier tema personal del asegurado', 'Primera consulta en despacho *gratuita*'
], hints: [null, 'relativa a cuestiones de su familia, vivienda, trabajo, asuntos penales, reclamaciones', null], description: 'Asistencia jurídica y legal'},
travel: {title: 'Asistencia en Viaje Mundial', points: [
'Asistencia en viaje mundial y repatriación', 'Asistencia médica en caso de accidente o enfermedad durante el viaje al extranjero', 'Anticipo de efectivo en el extranjero y fianzas de hospitalización', 'Repatriación de heridos y enfermos'
], description: 'Asistencia en viaje mundial'},
funeral: {title: 'Asistencia en Caso de Fallecimiento', points: [
'Servicio de sepelio completo. Acompañamiento de restos mortales', 'Gastos de desplazamiento y estancia', 'Libre elección del lugar de inhumación en España'
], description: 'Asistencia en caso de fallecimiento'}
},
ASSIST_YOU: '¡Le asistimos!',
CAN_FOCUS: '¡Para que usted y su familia puedan vivir plenamente el momento!',
GET_ASSISTANCE: 'A mi oferta personal',
INSURED_HINT: 'Añade a todos los miembros de su familia que vivan en su casa y tengan menos de 80 años.',
IBAN_HINT: 'El IBAN es para pagos posteriores. La tarjeta se utilizará únicamente para el primer pago.',
DATE_PLACEHOLDER: 'dd.mm.aaaa',
TAX_NUMBER: 'NIF',
NATIONALITY: 'Nacionalidad',
PASSPORT_ID: 'Passporte',
    FOREIGNER_ID: 'NIE',
    NATIONAL_ID: 'Documento Nacional de Identidad (sólo para ciudadanos de la UE)',
    TAX_NUMBER_ID: 'NIF',
    IDENTIFICATION_METHOD: 'Identificación',
NUMBER: 'Número',
CANNOT_PROCEED: 'Llame a su asesor. En su situación, el contrato de seguro no puede ofrecerse en línea.',
YES: 'Sí',
NO: 'No',
GENDER: [{value: 'MALE', label: 'Hombre'}, {value: 'FEMALE', label: 'Mujer'}, {value: 'OTHER', label: 'Otros'}],
SEX: 'Género',
MALE: 'Hombre',
FEMALE: 'Mujer',
INSURED_PERSON: 'Persona asegurada',
HELVETIA_POST_TITLE: 'Introduzca los datos de la persona asegurada:',
BENEFICIARY_POSTAL_TITLE: 'Calcule y contrate su seguro:',
POSTAL_CODE: 'Código postal',
INSURED: 'Introduzca la fecha de nacimiento de todos los miembros de su familia que estarán protegidos por el seguro. Introduzca su fecha de nacimiento en el primer campo',
ABOVE_80: 'Lamentablemente, solo podemos asegurar a personas menores de 80 años',
ADD_MORE: 'Añadir más',
MESSAGE: 'Mensaje',
CONTACT_ME_Q: '¿Tiene alguna pregunta?',
CONTACT_ME_A: 'Háganoslo saber y le llamaremos.',
SEND: 'Enviar',
CARD_INFORMATION: 'Información de la tarjeta',
REMARKS: 'Observaciones',
MAX_AMOUNT: 'Importe máximo',
DETAILS: 'Detalles',
FLIGHT_DETAILS: 'Introduzca aquí los datos de su vuelo:',
WITHOUT_DEDUCTIBLE: 'Sin franquicia',
CHOOSE_ITEM_TO_CHECK: 'Por favor, elija el elemento para el que desea comprobar la cobertura:',
INSURANCE_COMPANY_LABEL: 'Compañía aseguradora',
INSURANCE_PRODUCT_LABEL: 'Producto de seguro',
//steps
STEP1: 'Datos',
STEP2: 'Oferta',
STEP3: 'Comprar',
CHOOSE_ITEM_TO_INSURE: 'Por favor, elija el artículo que desea asegurar:',
// Insurance overview
NO_COVERAGE: 'No podemos ofrecerle cobertura de seguro.',
INSURANCE_OVERVIEW: 'Visión general del seguro:',
COVERAGE: 'Cubierto:',
NON_COVERED: 'No cubierto:',
DOWNLOAD_INSURANCE_PRODUCT: 'Descargue la descripción detallada del seguro',
DOWNLOAD_TERMS_AND_CONDITIONS: 'Descargar Términos y Condiciones',
PRODUCT_INSURANCE_STEP_ONE_TITLE: 'Sólo unos pocos pasos para contar con un Seguro',
PRODUCT_INSURANCE_STEP_TWO_TITLE: 'Productos descubiertos del recibo',
PRODUCT_INSURANCE_STEP_THREE_TITLE: 'Seleccione su seguro:',
PRODUCT_INSURANCE_STEP_TWO_HINT: 'Categorías de productos',
PRODUCT_INSURANCE_STEP_ONE_ONLINE: 'Díganos qué quiere proteger.',
ENTER_MORE_DETAILS: 'Introduzca más detalles sobre el artículo que desea asegurar:',
KNOW_BETTER: 'Por favor, permítanos conocerle mejor',
TAKE_RECEIPT_PHOTO: 'Haga una foto del recibo de compra:',
CHECK_RECEIPT: 'Por favor, compruebe si el recibo es claro en la foto:',
CHECK_ITEM_PHOTO: 'Compruebe si el artículo se ve bien en la foto:',
TAKE_ITEM_PHOTO: 'Haga una foto del artículo que desea asegurar:',
BUTTON: 'Botón',
MORE_DETAILS: 'Mostrar detalles',
BACK: 'Volver',
SKIP_RECEIPT: 'Saltar el recibo',
NEXT: 'Siguiente',
FROM: 'Desde',
GENERAL_CONDITIONS: 'Condiciones generales',
LENGTH: 'Duración: ',
MINIMUM_LENGTH: 'Duración mínima del contrato: ',
MAXIMUM_LENGTH: 'Duración máxima del contrato: ',
CONTRACT_TERMINATION: 'Forma de pago del contrato: ',
COVERAGE_LIST: 'Cobertura',
SEARCH: 'Buscar',
OTHER: 'Otro',
MORE: 'Más...',
CLOSE: 'Cerrar',
PHOTO: 'Foto',
OR: 'O',
OPTIONS: 'Opciones',
SHOW_MORE: 'Mostrar más',
DO_NOT_INSURE: 'No asegurar este artículo',
NOT_INSURABLE: 'Este artículo no es asegurable',
SELECT_ITEM_TYPE: 'Por favor, seleccione el tipo de artículo',
ADD_UNRECOGNIZED_ITEM: 'Añadir un artículo',
NAME: 'Nombre',
PLEASE_WAIT: 'Por favor, espere',
RECOGNIZE_PROCESSING: 'La aplicación está procesando la(s) foto(s) para extraer la información relevante.',
PRICE: 'Precio',
CANCEL: 'Cancelar',
OK: 'Ok',
SAVE_AND_NEXT: 'Siguiente',
MY_NEW_ITEM: 'Mi nuevo y querido artículo',
INSURANCE_COVERAGE: 'Cobertura del seguro:',
OPTIMALLY_INSURE: 'Asegurar de forma óptima',
COVERAGE_HOME_INSURANCE_DESCRIPTION: 'Cobertura en el Seguro de Hogar',
COVERAGE_DESCRIPTION: 'El siguiente resumen de coberturas es general y se basa en un típico producto básico suizo de seguro de hogar. ¿Desea comprobar la cobertura de su seguro de hogar específico? Haga clic aquí: ',
HOME_INSURANCE: 'Seleccionar seguro de hogar',
INCIDENT_TYPE: 'Riesgo',
OUTSIDE_INSIDE: 'Cobertura',
THEFT: 'Hurto',
BURGLARY: 'Robo',
BREAKAGE: 'Rotura',
ROADSIDE_ASSISTANCE: 'Asistencia en carretera',
VISION_STRENGTH: 'Cambio en la fuerza de la visión',
// Pantalla de Información
DEAR_CUSTOMER: 'Estimado cliente,',
TO_OFFER_YOU: 'para ofrecerle los mejores seguros, necesitamos más información acerca del artículo que acaba de adquirir. Para ello puede:',
//OPTION_TAKE_A_PHOTO: 'Take a photo of the purchase receipt',
//OPTION_UPLOAD_FROM_GGALLERY: 'Upload a photo of the purchase receipt',
OPTION_TAKE_A_PHOTO: 'Hacer una foto',
OPTION_UPLOAD_FROM_GGALLERY: 'Subir una foto',
OPTION_ENTER_MANUALLY: 'Introducir los datos manualmente',
OPTION_TAKE_A_PHOTO_DESCRIPTION: 'Puede requerirse acceso a su cámara.',
OPTION_UPLOAD_FROM_GGALLERY_DESCRIPTION: 'Puede requerirse acceso a fotos, medios y archivos.',
// Estado Vacío
SORRY: 'Lo sentimos',
SORRY_MESSAGE: 'Lo sentimos, pero el enlace de la oferta ya no es válido. Estaremos encantados de hacerle una oferta con su próxima compra.',
DONE: 'Hecho',
// Payment Methods
PAYMENT_METHODS: 'Métodos de pago',
HOW_DO_YOU_WANT_TO_PAY: '¿Cómo prefieres pagar?',
YOU_WILL_BE_SPENDING: 'Estás a punto de gastar',
FOR_BUYING_INSURANCE_POLICIES: 'para la compra de pólizas de seguro',
CONFIRM_YOUR_CARD_DETAILS: 'Por favor, introduzca los datos de su tarjeta de pago',
HDRC_MONEY_BACK: 'Por favor, introduzca los datos de su tarjeta de pago',
CARDHOLDER_NAME: 'Nombre del titular de la tarjeta',
CARD_NUMBER: 'Número de tarjeta',
EXPIRY_DATE: 'Fecha de vencimiento',
CVV: 'Código de seguridad (CVV)',
CONFIRM_AND_PAY: 'Confirmar y pagar',
I_HAVE_SUCCESSFULLY_READ: 'He leído y acepto los términos y condiciones.',
READ_THE_FOLLOWING: 'He leído y acepto lo siguiente:',
TERMS_AND_CONDITIONS: 'Condiciones del producto de seguro',
DATA_PRIVACY: 'Autorización para que la aseguradora trate los datos',
ISSUER_DATA_PRIVACY: 'Autorización para que el corredor procese los datos',
ISSUER_TERMS_AND_CONDITIONS: 'Condiciones del corridor',
ISSUER_PAYMENT_CONSENT: 'Autorización de domiciliación bancaria al corredor para el pago de las primas posteriores',
ENTRY_A_FEW_MORE_DETAILS: 'Ingresa algunos detalles adicionales sobre ti:',
FIRST_NAME: 'Nombre',
FAMILY_NAME: 'Apellidos',
BIRTHDAY: 'Fecha de nacimiento',
EMAIL_ADDRESS: 'Dirección de correo electrónico',
PHONE_NUMBERS: 'Número de teléfono',
ADDRESS: 'Dirección',
CITY: 'Ciudad',
    STREET: 'Calle y número',
    ROOM: 'Piso y puerta',
    COUNTRY: 'País',
ENTRY_HERE: 'Ingresa aquí',
MONTHLY: 'por mes',
WEEKLY: 'por semana',
YEARLY: 'por año',
QUARTERLY: 'Trimestral',
OVERVIEW_YEARLY: 'Anualmente',
OVERVIEW_MONTHLY: 'Mensualmente',
OVERVIEW_WEEKLY: 'Semanalmente',
WEEKS: 'semanas',
MONTHS: 'meses',
YEARS: 'años',
WHAT_IS_NEXT: '¿Qué sigue?',
INSURANCE_CERTIFICATE: (email) =>  `El certificado de seguro se generará y enviará a ${email}.`,
INSURANCE_CONFIRMATION: (email) => `El confirmación de compra y pago su generará y enviará a ${email}`,
YOU_CAN_DOWNLOAD: 'También puedes descargar su certificado de seguro en la siguiente página',
YOU_CAN_DOWNLOAD_CONFIRMATION: 'También puedes descargar su confirmación de compra y pago en la siguiente página',
DEDUCTIBLE: 'Franquicia: ',
// validation
MUST_BE_POSITIVE: 'El valor debe ser positivo',
UP_TO: 'Hasta 30 caracteres',
PHONE_SHOULD_BE_INTERNATIONAL: 'El número de teléfono debe tener formato internacional (p. ej. +346xxxxxxxxx)',
MANDATORY_FIELD: 'Este campo es obligatorio',
INVALID_FORMAT: 'Formato no válido',
PRICE_SHOULD_BE_LOWER: 'El precio debe ser menor que el valor de la transacción',
DATE_SHOULD_BE_PAST: 'La fecha debe ser anterior',
TWO_DECIMAL_PLACES: 'Asegúrate de tener como máximo 2 decimales',
// Congratulations!
CONGRATULATIONS: '¡Felicidades!',
GLAD_TO_INFORM_YOU: 'Nos complace informarte que has adquirido con éxito la cobertura. Su producto ahora está asegurado.',
YOU_WILL_RECEIVE: 'Recibirás en los próximos minutos un correo electrónico de confirmación con el certificado de cobertura.',
YOU_ARE_PROTECTED: 'Estás protegido',
ALREADY_PURCHASED: 'Nos complace informarte que ya has adquirido un seguro y su valioso artículo está protegido.',
DOWNLOAD: 'Descargar',
YOU_CAN_DOWNLOAD_HERE: 'Puedes descargar su certificado de seguro aquí:',
YOU_CAN_DOWNLOAD_CONFIRMATION_HERE: ' Pudes descardgar su confirmación de compra y pago aquí:',
BACK_TO_OVERVIEW: 'Volver a la vista general',
// contracts list
YOUR_POLICY_PURCHASES: 'Su pólizas de seguro',
PURCHASED_ON: 'Comprado el',
EXPIRING_ON: 'Caduca el',
WHATS_COVERED: 'Cobertura',
FILE_A_CLAIM: 'Presentar un reclamo',
REACTIVATE: 'Reactivar',
// contract details
DOWNLOAD_PURCHASE_RECEIPT: 'Descargar el recibo de compra',
INSURCANCE_CONTRACT: 'Contrato de seguro',
ITEM_IS_PROTECTED_BY_INSURANCE: 'El artículo está protegido por el seguro',
INSURANCE_COVERAGE_START: 'Inicio de cobertura del seguro',
INSURANCE_COVERAGE_UNTIL: 'Cobertura del seguro hasta',
DOWNLOAD_PRODUCT_SHEET: 'Descargar ficha de producto',
DOWNLOAD_INSURANCE_CERTIFICATE: 'Descargar certificado de seguro',
CANCEL_CONTRACT: 'Cancelar contrato de seguro',
// claim form
PROVIDE_INFO_FOR_CLAIM: 'Proporcione información para el reclamo',
ATTACH_PHOTO_VIDEO: 'Adjunte, si corresponde, fotos o videos que muestren los daños.',
DESCRIBE_WHAT_HAPPEND: 'Describa lo sucedido',
ENTER_HERE: 'Ingrese aquí',
SUBMIT_CLAIM: 'Enviar reclamo',
PLEASE_ATTACH_FILES: 'Adjunte archivos, por favor',
THANKS_FOR_REQUEST: 'Gracias por enviar su solicitud. Estaremos encantados de procesarla y darle una respuesta en los próximos días.',
YOUR_INSURANCE_COVERS: 'Su seguro cubre la reparación únicamente a través de nuestros socios certificados. Puede encontrar un taller de reparación en su área aquí.',
SHOW_PARTNER_NETWORK: 'Mostrar red de socios',
// confirmation
YOUR_CONTRACT_HAS_BEEN_CANCELLED: 'Su contrato de seguro ha sido cancelado. Recibirá un correo electrónico de confirmación en breve.',
GOT_IT: 'Recibido',
THE_INSURANCE_WILL_BE_CANCELLED: 'El seguro será cancelado a partir de',
ARE_YOU_SURE_TO_CANCEL: '¿Está seguro de que desea cancelar la protección para su',
RETURN: 'Devolución',
CONFIRM_REACTIVATION: 'Confirme que desea reactivar el seguro.',
YOUR_CONTRACT_HAS_BEEN_REACTIVATED: 'Su contrato ha sido reactivado. Recibirá un correo electrónico de confirmación en breve.',
// contract statuses
ACTIVE: 'activo',
PENDING_CANCELLATION: 'pendiente de cancelación',
VOID: 'anulado',
// issuer application
// login
PASSWORD_POLICY: 'La contraseña no cumple los criterios dados',
BE_AWARE_PASSWORD_POLICY: 'Tenga en cuenta que la contraseña debe cumplir los siguientes criterios:',
AT_LEAST: ['Al menos 8 caracteres', 'Al menos 1 minúscula', 'Al menos 1 mayúscula', 'Al menos 1 número', 'Al menos 1 carácter especial'],
PASSWORD: 'Contraseña',
FORGOT_PASSWORD: '¿Olvidó su contraseña?',
LOGIN: 'Iniciar sesión',
NO_ACCOUNT_ERR: 'Dirección de correo electrónico o contraseña no válidos',
NO_EMAIL_ERR: 'Dirección de correo electrónico no válida',
MISMATCH_ERR: 'Las contraseñas deben coincidir',
//forgot password
FORGOT_YOUR_PASSWORD: '¿Olvidaste su contraseña?',
ENTER_THE_EMAIL: 'Ingrese la dirección de correo electrónico asociada a su cuenta y le enviaremos un enlace por correo electrónico para restablecer su contraseña.',
RESET_PASSWORD: 'Restablecer contraseña',
PASSWORD_RESET_EMAIL: 'Se ha enviado el correo electrónico de restablecimiento de contraseña',
EMAIL_SENT: 'Si la dirección de correo electrónico ingresada corresponde a un usuario registrado activo, el sistema ya ha enviado un correo electrónico a la dirección ingresada.',
REPEAT_PASSWORD: 'Repetir contraseña',
RESET: 'Restablecer',
PASSWORD_RESET: 'Restablecimiento de contraseña',
//sidebar
//sections
USAGE_ANALYSIS: 'Análisis de uso',
SETTINGS: 'Configuración',
ACCOUNT_ADMIN: 'Administrador de cuenta',
//links
DASHBOARD: 'Tablero de control',
ANALYTICS: 'Análisis',
GENERAL_SETTINGS: 'Configuración general',
PAYMENT_PRODUCTS: 'Productos de pago',
WHITE_LABELING: 'Marca blanca',
USERS_MANAGEMENT: 'Gestión de usuarios',
INTEGRATION_SETTING: 'Configuración de integración',
//general settings
OFFERING_TEXT_ON_PURCHASE: 'Texto de oferta en la compra',
ADD_AN_OFFERING: '+ Agregar una nueva oferta',
INSURANCE_CATALOGUE: 'Catálogo de seguros',
INSURANCE_TYPE: 'Tipo de seguro',
INSURANCE_PRODUCT: 'Producto de seguro',
OTHER_SETTINGS: 'Otros ajustes',
CI_CD_SETTING: 'Configuración CI/CD',
ADD_OFFER_TEXT: '+ Agregar texto de oferta',
EDIT: 'Editar',
DELETE: 'Eliminar',
OFFERING: 'Oferta',
OFFER_TEXT: 'Texto de la oferta',
TRAFFIC_ALLOCATION: 'Asignación de tráfico',
ALLOCATION: 'Asignación',
TEMPLATE_TEXT: 'Texto de plantilla',
SUM_ERR: 'El resumen de asignación para la plantilla debe sumar 100%',
MAPPING_EXISTS: 'Ya existe una oferta con el idioma seleccionado',
OFFERING_ERR: 'El texto de la oferta no debe duplicarse',
EMPTY_FIELD_ERR: 'Este campo no puede estar vacío',
LANGUAGE: 'Idioma',
GENDERS: 'Género',
GROUP_ITEMS: 'Grupo de artículos',
MIN_AGE_VS_MAX_AGE: 'La edad máxima debe ser mayor que la edad mínima',
// white labels
MY_WHITE_LABELS: 'Mis marcas blancas',
WHITE_LABELS: 'Marcas blancas',
ADD_WHITE_LABEL: '+ Añadir una marca blanca',
LOGO: 'Logotipo',
COLOUR_SCHEME: 'Esquema de colores',
SHOW_INSURER_LOGO: 'Mostrar logotipo de la aseguradora',
NO_LOGO_ERR: 'El logotipo es obligatorio',
//payment products
ADD_A_PAYMENT_PRODUCT: '+ Agregar un producto de pago',
PAYMENT_PRODUCT: 'Producto de pago',
PAYMENT_TYPE: 'Tipo de pago',
PAYMENT_SCHEME: 'Esquema de pago',
PAYMENT_SUBSCHEME: 'Subesquema de pago',
PAYMENT_PRODUCTS_PAGE: 'Productos de pago',
PAYMENT_PRODUCT_SETTINGS: 'Configuración del producto de pago',
EDIT_PAYMENT_PRODUCT: 'Editar producto de pago',
CREATE_PAYMENT_PRODUCT: 'Crear producto de pago',
PRODUCT_ID: 'ID del producto de pago',
USE_GENERAL_SETTINGS: 'Usar la configuración general',
OFFER_MANAGEMENT: 'Gestión de ofertas',
PORTFOLIO_OVERVIEW: 'Resumen de cartera',
PORTFOLIO_DEVELOPMENT: 'Desarrollo de cartera',
PORTFOLIO_LEADS: 'Generación de oportunidades y ofertas',
BENEFICIARY_TITLE: '¿A quién incluirá su seguro?',
BENEFICIARY_QUESTION: '¿A quién le gustaría cubrir?',
BENEFICIARY_OPTION1: 'A mí mismo',
BENEFICIARY_OPTION2: 'A mí y a mi familia/hogar'
}

export default es;
