import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectWhiteLabelingStyles, selectProfile, selectCurrentStepNumber } from '../../store/selectors';
import { setupCustomStyles } from '../../helpers/renderingHelpers';
import { setCurrentStepNumber } from '../../store/common/common.actions';

import Header from '../Header';
import Footer from '../Common/Footer/Footer';
import FamilyStatus from './FamilyStatus';
import JobStatus from './JobStatus';
import Income from './Income';
import Travel from './Travel';
import ApartmentStatus from './ApartmentStatus';
import Household from './Household';
import MortgageAndLoan from './MortgageAndLoan';
import CareFor from './CareFor';
import Properties from './Properties';
import Losses from './Losses';
import HealthExperience from './HealthExperience';
import Information from './Information';
import Recommendations from './Recommendations/Recommendations';
import HintModal from '../ProductInsurance/HintModal/HintModal';

import { messages } from '../../constants/messages';

import './styles.scss';

const steps = ['DOB_AND_FAMILY_STATUS', 'JOB_STATUS', 'YEARLY_INCOME', 'TRAVEL_INFO', 'CARE_FOR', 'APARTMENT_STATUS', 'HOUSEHOLD_STATUS', 'PROPERTIES', 'MORTGAGE_STATUS', 'LOSSES_UP_TO', 'HEALTH_EXPERIENCE', 'INFORMATION', 'RECOMMENDATIONS'];

const CustomerJourney = () => {
    const dispatch = useDispatch();

    const [hintModalState, setHintModalState] = useState({});
    const whiteLabelingStyles = useSelector(selectWhiteLabelingStyles);
    const index = useSelector(selectCurrentStepNumber);
    const profile = useSelector(selectProfile);
    useEffect(() => {
        setupCustomStyles(whiteLabelingStyles);
    }, [whiteLabelingStyles]);

    const [step, setStep] = useState(0);
    const [data, setData] = useState({});

    const handleChange = (v, k) => {
        setData(prev => ({
            ...prev,
            [k]: v
        }));
    };

    const handleSelect = (v, k) => {
        setData((prev) => {
            let tmp = prev[k] || [];
            if (tmp.length && tmp.indexOf(v) > -1) {
                tmp = tmp.filter(i => i !== v);
            } else {
                tmp.push(v);
            }
            return {
                ...prev,
                [k]: tmp
            };
        });
    };

    const onShowHint = (hint) => {
        setHintModalState({
            shown: true,
            label: null,
            hint
        });
    };

    const onCloseHint = () => setHintModalState({ shown: false });

    const renderWizard = () => {
        switch (steps[step]) {
            case 'DOB_AND_FAMILY_STATUS':
                return <FamilyStatus data={data} onChange={handleChange} />;
            case 'JOB_STATUS':
                return <JobStatus data={data} onChange={handleChange} />;
            case 'YEARLY_INCOME':
                return <Income data={data} onChange={handleChange} />;
            case 'TRAVEL_INFO':
                return <Travel data={data} onChange={handleChange} />;
            case 'CARE_FOR': return <CareFor data={data} onSelect={handleSelect} onChange={handleChange} />;
            case 'APARTMENT_STATUS':
                return <ApartmentStatus data={data} onChange={handleChange} />;
            case 'HOUSEHOLD_STATUS':
                return <Household data={data} onChange={handleChange} />;
            case 'PROPERTIES':
                return <Properties data={data} onSelect={handleSelect} />;
            case 'MORTGAGE_STATUS':
                return <MortgageAndLoan data={data} onChange={handleChange} />;
            case 'LOSSES_UP_TO':
                return <Losses data={data} onChange={handleChange} />;
            case 'HEALTH_EXPERIENCE':
                return <HealthExperience data={data} onSelect={handleSelect} />;
            case 'INFORMATION':
                return <Information />;
            case 'RECOMMENDATIONS':
                return <Recommendations data={data} showDetails={onShowHint} />;
            default: return null;
        }
    };

    useEffect(() => {
        if (steps[step] === 'INFORMATION') {
            dispatch(setCurrentStepNumber(2));
        }
        if (steps[step] === 'APARTMENT_STATUS' || steps[step] === 'HEALTH_EXPERIENCE') {
            dispatch(setCurrentStepNumber(1));
        }
        if (steps[step] === 'CARE_FOR') {
            dispatch(setCurrentStepNumber(0));
        }
    }, [step]);

    return profile && <div className="screen-container customer-journey">
        <div className="screen">
            <Header
                steps={messages.STEPS}
                stepsTitle={messages.STEP_TITLES[index]} />
            <div className="content customer-journey">
                {renderWizard(step)}
            </div>
            <HintModal {...hintModalState} onClose={onCloseHint} />
            <Footer
                showBack={!!step}
                showNext={step < steps.length - 1}
                onNavBack={() => setStep(prev => prev - 1)}
                onNextStep={() => setStep(prev => prev + 1)} />
        </div>
    </div>;
};

export default CustomerJourney;
