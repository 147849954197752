const deCH = {
//customer journey
HEALTH_RELATED: 'Krankenversicherungen',
MANDATORY_HEALTH_INSURANCE: 'Pflicht-Krankenversicherung',
COMPLEMENTARY_HEALTH_INSURANCE: 'Zusatzkrankenversicherung',
COMPLEMENTARY_HEALTH_INSURANCE_DESCRIPTION: 'In den meisten Fällen übersteigen die Kosten für eine Zusatzkrankenversicherung deren Nutzen',
LIFE_RELATED: 'Lebens- und Zahlungsbezogene Versicherung',
PAYMENT_PROTECTION_INSURANCE: 'Zahlungsschutzversicherung',
PAYMENT_PROTECTION_INSURANCE_DESCRIPTION: 'Eine Risikolebensversicherung würde einen überlegenen Schutz zu geringeren Kosten bieten',
LEGAL_RELATED: 'Haft- und Rechts-bezogene Versicherungen',
PRIVATE_LIABILITY_INSURANCE: 'Private Haftpflichtversicherung',
PRIVATE_LIABILITY_INSURANCE_DESCRIPTION: 'Die private Haftpflichtversicherung ist eines der wichtigsten Versicherungen. Sie ist nicht obligatorisch, jedoch notwendig, wenn du dein finanzielle Existenz schützen möchtest',
PROPERTY_RELATED: 'Eigentums- und Vermögen-bezogene Versicherung',
ASSISTANCE_INSURANCE: 'Assistance-Versicherung',
PASSENGER_INSURANCE: 'Mitfahrerversicherung',
PASSENGER_INSURANCE_DESCRIPTION: 'Eine Mitfahrerversicherung macht nur Sinn für Passagiere, die nicht in der Schweiz bzw. in einem EU-Land wohnhaft sind',
SMARTPHONE_INSURANCE: 'Smartphone-Versicherung',
WARRANTY_EXTENSION_INSURANCE: 'Garantieverlängerung',
MANDATORY_ACCIDENT_INSURANCE: 'Pflicht-Unfallversicherung',
MANDATORY_ACCIDENT_INSURANCE_DESCRIPTION: 'Behandlungen von Verletzungen infolge eines Unfalls werden nicht von der Krankenversicherung, sondern von der Unfallversicherung gedeckt',
PRIVATE_ACCIDENT_INSURANCE: 'Halbprivate oder Private Unfallversicherung',
PRIVATE_ACCIDENT_INSURANCE_DESCRIPTION: 'Patienten mit einer halbprivaten und privaten Versicherung werden in der Regel auch bei weniger schwerwiegenden medizinischen Bedürfnissen von erfahrenen Ärzten behandelt. Hinweis: Im Allgemeinen werden Patienten mit einer kritischen Krankheit oder Verletzung immer von erfahrenen Ärzten behandelt.',
DENTAL_INSURANCE: 'Zahnversicherung',
KIDS_DENTAL_INSURANCE: 'Zahnversicherung für Kinder',
KIDS_DENTAL_INSURANCE_DESCRIPTION: 'Zahnkorrekturen für Kinder können schnell über CHF 15\'000 kosten',
DAILY_BENEFITS_INSURANCE: 'Tagegeldversicherung',
DAILY_BENEFITS_INSURANCE_DESCRIPTION: 'Die Tagegeldversicherung zahlt dem Versicherungsnehmer einen vordefinierten Geldbetrag. Dies ist besonders vorteilhaft für Selbständige, die auf ihr Einkommen angewiesen sind',
PRIVATE_HOSPITAL_INSURANCE: 'Halbprivate oder Private Krankenhausversicherung',
PRIVATE_HOSPITAL_INSURANCE_DESCRIPTION: 'Patienten mit einer halbprivaten und privaten Versicherung werden in der Regel auch bei weniger schwerwiegenden medizinischen Bedürfnissen von erfahrenen Ärzten behandelt. Hinweis: Im Allgemeinen werden Patienten mit einer kritischen Krankheit oder Verletzung immer von erfahrenen Ärzten behandelt.',
WORK_INSURANCE: 'Versicherung gegen Arbeitsunfähigkeit',
WORK_INSURANCE_DESCRIPTION: 'Arbeitsunfähigkeit aufgrund von Krankheiten oder psychischen Problemen ist nicht gut durch die obligatorischen Versicheruungen abgedeckt. Typischerweise führt eine Arbeitsunfähigkeit aufgrund von Krankheit zu einem Einkommensverlust von 40%', DEATH_INSURANCE: 'Risikotodesversicherung',
DEATH_INSURANCE_DESCRIPTION: 'Die Risikoversicherung zahlt im Todesfall eine Pauschalsumme und/oder eine Rente. Dies ermöglicht die Bezahlung von ausstehenden Schulden und/oder stellt sicher, dass der Partner und die Kinder finanziell abgesichert sind.',
A_POLICY: '3a Versicherungspolice',
A_POLICY_DESCRIPTION: 'Im Allgemeinen sind Bankprodukte die beste Wahl, um Vermögen aufzubauen. Unter bestimmten Bedingungen bieten jedoch Lebensversicherungen, z.B. für Unternehmer und Selbständige, wichtige Vorteile als Sparvehikel',
WHOLE_LIFE_INSURANCE: 'Ganzlebens-/Sparversicherung', WHOLE_LIFE_INSURANCE_DESCRIPTION: 'Im Allgemeinen sind Bankprodukte die beste Wahl, um Vermögen aufzubauen. Unter bestimmten Bedingungen bieten jedoch Lebensversicherungen, z.B. für Unternehmer und Selbständige, wichtige Vorteile als Sparvehikel',
BUSINESS_INSURANCE: 'Betriebshaftpflichtversicherung',
 BUSINESS_INSURANCE_DESCRIPTION: 'Je nach Beruf (z.B. Arzt, Architekt, etc.) benötigst du eine Betriebshaftpflichtversicherung',
 LEGAL_INSURANCE: 'Rechtsschutzversicherung',
 LEGAL_INSURANCE_DESCRIPTION: 'Die Rechtsschutzversicherung deckt die Rechtskosten ab, falls du dich in Bezug auf deine Beschäftigung, deine gemietete Wohnung oder im Zusammenhang mit der Mobilität verteidigen möchtest',
CAR_INSURANCE: 'Kfz-Haftpflichtversicherung',
MOTO_INSURANCE: 'Motorrad-Haftpflichtversicherung',
BOAT_INSURANCE: 'Boots-Haftpflichtversicherung',
BUILDING_INSURANCE: 'Gebäudeversicherung',
HALF_CAR_INSURANCE: 'Voll- oder Teilkasko-Auto-Versicherung',
HALF_CAR_INSURANCE_DESCRIPTION: 'Eine Vollkaskoversicherung wird empfohlen, wenn du ein Auto für den täglichen Gebrauch benötigst oder dein aktuelles Auto weniger als 5 Jahre alt ist',
HOUSEHOLD_INSURANCE: 'Hausratversicherung',
PET_INSURANCE: 'Haustierversicherung',
PET_INSURANCE_DESCRIPTION: 'Haustierbehandlungen werden immer ausgefeilter und teurer. Wenn du nicht zwischen dem Ausgeben von CHF 15\'000 oder der Einschläferung deines Haustieres wählen möchtest, dann könnte eine Haustierversicherung das Richtige für dich sein',
TRAVEL_INSURANCE: 'Reiseversicherung',
BICYCLE_INSURANCE: 'Fahrradversicherung',
ITEM_RELATED_INSURANCE: 'Gegenstandsbezogene Versicherung (z.B. Schmuck, Musikinstrument)',
ITEM_RELATED_INSURANCE_DESCRIPTION: 'Nur für die Gegenstände, die du wirklich benötigst und deren Wert über 2\'500 CHF liegt',
STEP_TITLES: ['Erzähl uns dich', 'Lass uns deinen Kontext besser verstehen', 'Entdecke deine Empfehlungen'],
WERE_BORN: 'Du bist geboren am',
YOU_ARE: 'Du bist',
FAMILY_STATUSES: [
     { key: 'single', name: 'Single' },
     { key: 'married', name: 'Verheiratet' },
     { key: 'divorced', name: 'Geschieden/verwitwet' }],
JOB_STATUSES: [
     { key: 'employed', name: 'Angestellt' },
     { key: 'freelancer', name: 'Selbstständig/Freiberufler' },
     { key: 'entrepreneur', name: 'Unternehmer' },
     { key: 'job_seeker', name: 'Arbeitssuchender' },
     { key: 'student', name: 'Student' },
     { key: 'retired', name: 'Rentner' }],
YEARLY_INCOME_IS: 'Dein jährliches Einkommen beträgt',
INCOME_STATUSES: [
    { key: 'below', name: 'Unter CHF 25\'725' },
    { key: 'between', name: 'Zwischen CHF 25\'725 und CHF 88\'200' },
    { key: 'above', name: 'Mehr als CHF 88\'200' }],
YOU_TRAVEL: 'Du reist',
TRAVEL_STATUSES: [
    { key: 'rarely', name: 'Selten' },
    { key: 'once_per_year', name: 'Einmal im Jahr' },
    { key: 'more', name: 'Zweimal im Jahr oder mehr' }],
YOU_LIVE_IN: 'Du lebst in',
APARTMENT_OPTIONS: [
    { key: 'rented_room', name: 'Gemietetes Zimmer (Gemeinschaftswohnung)' },
    { key: 'rented_home', name: 'Mietwohnung' },
    { key: 'owned_apartment', name: 'Eigentumswohnung' },
    { key: 'owned_home', name: 'Eigentumshaus' }],
HOUSEHOLD: 'Die Einrichtung in deinem Zuhause ist',
HOUSEHOLD_STATUSES: [
    { key: 'spartanic', name: 'Spartanisch' },
    { key: 'normal', name: 'Normal' },
    { key: 'luxury', name: 'Luxus' }],
CARE_FOR: 'Du lebt mit bzw. trägst Verantwortung für',
CARE_LIST: [
    { key: 'partner', name: 'Partner' },
    { key: 'kids', name: 'Kind(er)' },
    { key: 'dogs', name: 'Hund(e)' },
    { key: 'cats', name: 'Katze(n)' },
    { key: 'horses', name: 'Pferd(e)' }],
KIDS_ABOVE_6: 'Hast du Kinder unter 6 Jahren',
KIDS_ABOVE_OPTIONS: [{ key: 'yes', name: 'Ja' }, { key: 'no', name: 'Nein' }],
IS_LOAN: 'Hast du eine ausstehende Hypothek oder einen Kredit?',
LOAN_OPTIONS: [
   { key: 'yes', name: 'Ja' },
   { key: 'no', name: 'Nein' }],
YOU_OWN: 'Du besitzt',
PROPERTY_LIST: [
   { key: 'car', name: 'Auto(s)' },
   { key: 'motorbike', name: 'Motorrad/Motorräder' },
   { key: 'camper', name: 'Wohnmobil(e)' },
   { key: 'boat', name: 'Boot(e)' },
   { key: 'bicycle', name: 'Fahrrad/E-Bike(s)' },
   { key: 'scooter', name: 'E-Scooter(s)' }],
LOSSES: 'Jederzeit bist du in der Lage und bereit, unerwartete Verluste von bis zu zu tragen',
 LOSSES_LIST: [
   { key: 500, name: 'CHF 500' },
   { key: 1000, name: 'CHF 1\'000' },
   { key: 2500, name: 'CHF 2\'500' },
   { key: 5000, name: 'CHF 5\'000' },
   { key: 100000, name: 'CHF 10\'000 oder mehr' }],
HEALTH: 'Wenn es um deine Gesundheit geht',
HEALTH_EXPERIENCE: [
   { key: 'standard_coverage', name: 'Du bist zufrieden mit dem Standard' },
   { key: 'experienced_only', name: 'Du möchtest nur von erfahrenen Ärzten behandelt werden' },
   { key: 'private_clinics', name: 'Du möchtest Zugang zu Privatkliniken haben' },
   { key: 'alternative_treatment', name: 'Du möchtest Zugang zu komplementärer/alternativer medizinischer Behandlung haben' }],
INFORMATION_POINTS: ['Was du als nächstes sehen wirst, sind keine Empfehlungen. Wir werden nur zum Zwecke einer Orientierubg die typischen Versicherungen zeigen, die Personen empfohlen werden, die ähnlich auf den Fragebogen geantwortet haben', 'Für eine personalisierte Versicherungsempfehlung empfehlen wir dir, einen persönlichen Beratungstermin zu vereinbaren'],
NECESSARY: 'Notwendig',
RECOMMENDED: 'Empfohlen',
WORTH_CONSIDERING: 'In Betracht ziehen',
MAKES_RARELY_SENSE: 'Macht wenig Sinn',
STEPS: ['Persönliche Informationen', 'Kontextinformationen', 'Empfehlungen'],
////////
REDIRECT: 'Como próximo paso, se abrirá una nueva pestaña y serás redirigido al sitio web de Insurama. Insurama es la compañía de seguros que ofrece seguros para teléfonos móviles. Para asegurar la protección de tu teléfono móvil, te pedimos que continúes ingresando los detalles de pago.',
TRAVEL: {
    cancellation: {
        description: 'Stornierung von Reisen'
    },
    hospitalization: {
        description: 'Krakenhausaufenthalt'
    },
    repatriation: {
        description: 'Rücktransport'
    },
    accident: {
        description: 'Unfall'
    },
    wallet_theft: {
        description: 'Diebstahl von Brieftaschen und Bargeld'
    },
    device_theft: {
        description: 'Diebstahl von mobilen Geräten und Schmuck'
    }
},
FUTURE_DATE: 'Please enter future date',
TRAVEL_PRE_SCREEN_TITLE: 'Ihre Reise',
FROM: 'Ab',
ASSIST_YOU: 'Wir untersützen dich!',
CAN_FOCUS: 'Damit du dich auf deine Familie konzentrieren kannst',
HELVETIA_EXPLANATION: {
medical: {title: 'Medizinisches', points: [
'24-Stunden-Hotline für medizinische Informationen', 'Internationale ärztliche Zweitmeinung', '1 kostenlose Konsultation eines beliebigen medizinischen Fachgebiets pro Person und Jahr', 'Bietet zu Vorzugspreisen Fachärzte und diagnostische Ressourcen mit Ärzten in mehr als 30 Fachgebieten'
], description: 'Medizinisches'},
dental: {title: 'Zahnmedizin', points: [
'Eine kostenlose Zahnreinigung pro Jahr pro Person/pro Jahr', 'Zugang zu einem breiten zahnärztlichen Netzwerk zu Vorzugspreisen'
], description: 'Zahnmedizin'},
legal: {title: 'Rechtliche und juristische Hilfe', points: [
'Wohnung, Arbeit, Strafsachen, Ansprüche usw.', 'Kostenlose Erstberatung in der Geschäftsstelle'
], description: 'Rechtliche und juristische Hilfe'},
travel: {title: 'Weltweite Reisen', points: [
'Medizinische Hilfe bei Unfall oder Krankheit', 'Medizinische Evakuierung oder Repatriierung von Verletzten und Kranken'
], description: 'Weltweite Reisen'},
funeral: {title: 'Todesfall', points: [
'Vollständiger Bestattungsservice', 'Begleitung der sterblichen Überreste, Reise- und Unterhaltskosten', 'Freie Wahl des Bestattungsortes in Spanien'
], description: 'Todesfall'}
},
GET_ASSISTANCE: 'Family Assistance kaufen',
TAX_NUMBER: 'NIF',
CANNOT_PROCEED: 'Unfortunately we cannot proceed online',
YES: 'Yes',
NO: 'No',
MALE: 'Male',
FEMALE: 'Female',
INSURED_PERSON: 'Versicherte Person',
HELVETIA_POST_TITLE: 'Geben die Daten zu den versicherten Personen ein',
BENEFICIARY_POSTAL_TITLE: 'Calculate and contract your death insurance:',
POSTAL_CODE: 'Postal Code',
INSURED: 'Bitte geben Sie das Geburtsdatum aller Mitglieder Ihrer Familie an, die durch die Versicherung geschützt werden sollen',
ABOVE_80: 'Unfortunately, we can only include people who are younger than 80 years old',
ADD_MORE: 'Add more',
MESSAGE: "Nachricht",
CONTACT_ME_Q: "Haben Sie Fragen?",
CONTACT_ME_A: "Gerne kontaktieren wir Sie.",
SEND: "Senden",
CARD_INFORMATION: 'Karteninformationen',
REMARKS: "Bemerkungen",
MAX_AMOUNT: "Maximalbetrag",
DETAILS: "Einzelheiten",
FLIGHT_DETAILS: "Bitte geben Sie hier Ihre Flugdaten ein:",
    WITHOUT_DEDUCTIBLE: "Ohne Selbstbehalt",
    CHOOSE_ITEM_TO_CHECK: "Wofür möchtest du einen Deckungs-Check machen?",
    INSURANCE_COMPANY_LABEL: 'Versicherungsunternehmen',
    INSURANCE_PRODUCT_LABEL: 'Versicherungsprodukt',
    //steps
    STEP1: 'Angaben',
    STEP2: 'Angebot',
    STEP3: 'Abschluss',
    CHOOSE_ITEM_TO_INSURE: 'Bitte wählen Sie den Gegenstand, den Sie versichern möchten:',
    // Insurance overview
    INSURANCE_OVERVIEW: 'Versicherungsübersicht:',
    COVERAGE: 'Versicherte Risiken:',
    NON_COVERED: 'NICHT versicherte Risiken:',
    DOWNLOAD_INSURANCE_PRODUCT: 'Detaillierte Versicherungsbeschreibung herunterladen',
    DOWNLOAD_TERMS_AND_CONDITIONS: 'Produktbeschreibung und Vertragsbedingungen herunterladen',
    PRODUCT_INSURANCE_STEP_ONE_TITLE: 'In ein paar Schritten zu ihrer Versicherung',
    PRODUCT_INSURANCE_STEP_TWO_TITLE: 'Was soll geschützt werden?',
    PRODUCT_INSURANCE_STEP_THREE_TITLE: 'Wählen Sie Ihre Versicherung:',
    PRODUCT_INSURANCE_STEP_TWO_HINT: 'Produkt-Kategorien',
    PRODUCT_INSURANCE_STEP_ONE_ONLINE: 'Wählen Sie das Produkt, welches Sie gerade gekauft haben und versichert haben wollen.',
    ENTER_MORE_DETAILS: 'Details zum gekauften Objekt eingeben:',
    TAKE_RECEIPT_PHOTO: 'Bitte fotografieren Sie den Kaufbeleg:',
    CHECK_RECEIPT: 'Bitte prüfen Sie, ob der Beleg auf dem Foto deutlich zu erkennen ist:',
    CHECK_ITEM_PHOTO: 'Bitte überprüfen Sie, ob der Artikel auf dem Bild klar ist:',
    TAKE_ITEM_PHOTO: 'Bitte machen Sie ein Foto von Ihrem abzudeckenden Artikel:',
    BUTTON: 'Knopf',
    MORE_DETAILS: 'Detail anzeigen',
    BACK: 'Zurück',
    SKIP_RECEIPT: 'Schritt Überspringen',
    NEXT: 'Weiter',
    GENERAL_CONDITIONS: 'Allgemeine Bedingungen.',
    LENGTH: 'Dauer: ',
    MINIMUM_LENGTH: 'Minimale Vertragsdauer: ',
    MAXIMUM_LENGTH: 'Maximale Vertragsdauer: ',
    CONTRACT_TERMINATION: 'Vertragskündigung: ',
    COVERAGE_LIST: 'Deckung',
    SEARCH: 'Suche',
    OTHER: 'Andere',
    MORE: 'Mehr...',
    CLOSE: 'Schliessen',
    PHOTO: 'Foto',
    OR: 'Oder',
    OPTIONS: 'Möglichkeiten',
    SHOW_MORE: 'Mehr anzeigen',
    DO_NOT_INSURE: 'Diesen Artikel nicht versichern',
    NOT_INSURABLE: 'Dieser Artikel ist nicht versicherbar',
    SELECT_ITEM_TYPE: 'Bitte wählen Sie den Artikeltyp',
    ADD_UNRECOGNIZED_ITEM: 'Einen Artikel hinzufügen',
    NAME: 'Name',
    PLEASE_WAIT: 'Bitte warten Sie',
    RECOGNIZE_PROCESSING: 'Die Anwendung verarbeitet das Foto/die Fotos, um die relevanten Informationen zu extrahieren.',
    PRICE: 'Preis',
    CANCEL: 'Abbrechen',
    OK: 'Ok',
    SAVE_AND_NEXT: 'Weiter',
    MY_NEW_ITEM: 'Mein neuer Lieblingsartikel',
    INSURANCE_COVERAGE: 'Versicherungsschutz:',
    OPTIMALLY_INSURE: 'Optimal versichern',
    COVERAGE_HOME_INSURANCE_DESCRIPTION: 'Deckung in Hausratsversicherung',
    COVERAGE_DESCRIPTION: 'Die folgende verallgemeinerte Deckungsübersicht basiert auf einer typischen Schweizer Hausratversicherung. Wollen Sie die Deckung Ihres spezifischen Hausratversicherung-Produktes prüfen? Klicken Sie hier: Versicherung wählen',
    HOME_INSURANCE: 'Hausratversicherung wählen',
    INCIDENT_TYPE: 'Risiko',
    OUTSIDE_INSIDE: 'Deckung',
    THEFT: 'Diebstahl',
    BURGLARY: 'Einbruch',
    BREAKAGE: 'Bruch',
    ROADSIDE_ASSISTANCE: 'Pannenhilfe im Straßenverkehr',
    VISION_STRENGTH: 'Veränderung der Sehstärke',

    // Inform Screen
    DEAR_CUSTOMER: 'Sehr geehrte/r Kund/in,',
    TO_OFFER_YOU: 'um Ihnen die besten Versicherungsoptionen anbieten zu können, benötigen wir mehr Informationen über den Artikel, den Sie gerade gekauft haben. Sie können entweder:',
    /* OPTION_TAKE_A_PHOTO: 'Ein Foto vom Kaufbeleg aufnehmen',
    OPTION_UPLOAD_FROM_GGALLERY: 'Ein Foto vom Kaufbeleg hochladen', */
    OPTION_TAKE_A_PHOTO: 'Ein Foto aufnehmen',
    OPTION_UPLOAD_FROM_GGALLERY: 'Ein Foto hochladen',
    OPTION_ENTER_MANUALLY: 'Die Daten manuell eingeben',
    OPTION_TAKE_A_PHOTO_DESCRIPTION: 'Der Zugriff auf Ihre Kamera kann angefordert werden.',
    OPTION_UPLOAD_FROM_GGALLERY_DESCRIPTION: 'Der Zugriff auf Ihre Fotos, Medien und Dateien kann angefordert werden,',

    // Empty State
    SORRY: 'Entschuldigung',
    SORRY_MESSAGE: 'Leider ist der Link nicht mehr gültig (Kauf liegt zu weit zurück). Wir würden uns freuen, ihnen beim nächsten Kauf ein Angebot machen zu können.',
    DONE: 'Schliessen',

    // Payment Methods
    PAYMENT_METHODS: 'Zahlungsmethoden',
    HOW_DO_YOU_WANT_TO_PAY: 'Welche Zahlungsart bevorzugen Sie?',
    YOU_WILL_BE_SPENDING: '********',
    FOR_BUYING_INSURANCE_POLICIES: '********',
    CONFIRM_YOUR_CARD_DETAILS: 'Geben Sie die Daten zu Ihrer Bankkarte ein:',
    HDRC_MONEY_BACK: 'Geben Sie die Daten zu Ihrer Bankkarte ein.',
    CARDHOLDER_NAME: 'Name des Karteninhabers',
    CARD_NUMBER: 'Kartennummer',
    EXPIRY_DATE: 'Verfallsdatum',
    CVV: 'Kartenprüfnummer (CVV)',
    CONFIRM_AND_PAY: 'Bestätigen & kaufen',
    I_HAVE_SUCCESSFULLY_READ: 'Ich habe die allgemeinen Vertragsbedingungen gelesen und ich bin damit einvestanden.',
    READ_THE_FOLLOWING: 'Ich habe das Folgende gelesen und akzeptiert:',
    TERMS_AND_CONDITIONS: 'Vertragsbedingungen und Bestimmungen',
    DATA_PRIVACY: 'Regeln zum Datenschutz',
    ENTRY_A_FEW_MORE_DETAILS: 'Zur Erstellung Ihrer persönlichen Police, geben Sie bitte Ihre Daten ein:',
    FIRST_NAME: 'Vorname',
    FAMILY_NAME: 'Nachname',
    BIRTHDAY: 'Geburtsdatum',
    EMAIL_ADDRESS: 'Email Adresse',
    PHONE_NUMBERS: 'Telefonnummer',
    ADDRESS: 'Adresse',
    ENTRY_HERE: 'Hier eingeben',
    MONTHLY: 'Pro Monat',
    WEEKLY: 'Pro Woche',
    YEARLY: 'Pro Jahr',
    QUARTERLY: 'Pro Quartal',
    OVERVIEW_YEARLY: 'Jährlich',
    OVERVIEW_MONTHLY: 'Monatlich',
    OVERVIEW_WEEKLY: 'Wöchentlich',
    MONTHS: 'Monate',
    WEEKS: 'Wochen',

    YEARS: 'Jahre',
    WHAT_IS_NEXT: 'Was kommt als nächstes:',
    INSURANCE_CERTIFICATE: (email) =>  `Wir generieren Ihre Versicherungspolice und wir senden sie an ${email}.`,
    YOU_CAN_DOWNLOAD: 'Zusätzlich, Sie können auf der nächsten Seite Ihren Versicherungspolice herunterladen',
    DEDUCTIBLE: 'Selbstbehalt: ',

    // validation
    MANDATORY_FIELD: 'Das Feld ist obligatorisch',
    INVALID_FORMAT: 'Ungültiger Wert',
    PRICE_SHOULD_BE_LOWER: 'Preis sollte niedriger sein als der Transaktionswert',
    DATE_SHOULD_BE_PAST: 'Datum sollte in der Vergangenheit liegen',
    TWO_DECIMAL_PLACES: 'Stellen Sie sicher, dass es nicht mehr als 2 Dezimalstellen gibt',

    // Congratulations!
    CONGRATULATIONS: 'Herzlichen Glückwunsch!!',
    GLAD_TO_INFORM_YOU: 'Gerne bestätigen wir Ihnen, dass Sie erfolgreich die Versicherung abgeschlossen haben, und dass Ihr Produkt nun geschützt ist.',

    YOU_WILL_RECEIVE: 'Sie erhalten in Kürze ein Bestätigungsemail mit dem Versicherungspolice im Anhang.',

    YOU_ARE_PROTECTED: 'Sie sind geschützt',
    ALREADY_PURCHASED: 'Wir freuen uns, Ihnen mitteilen zu können, dass Sie bereits eine Versicherung abgeschlossen haben und dass Ihr Lieblingsstück bereit geschützt ist.',

    DOWNLOAD: 'herunterladen',
    YOU_CAN_DOWNLOAD_HERE: 'Hier können Sie Ihren Versicherungspolice herunterladen:',
    BACK_TO_OVERVIEW: 'zurück zur Übersicht',

    // contracts list
    YOUR_POLICY_PURCHASES: 'Ihre Versicherungen',
    PURCHASED_ON: 'Abgeschlossen am',
    EXPIRING_ON: 'Abgelaufen am',
    WHATS_COVERED: 'Abgedeckt ist',
    FILE_A_CLAIM: 'Schaden melden',
    REACTIVATE: 'Reaktivieren',

    // contract details
    DOWNLOAD_PURCHASE_RECEIPT: 'Kaufbeleg herunterladen',
    INSURCANCE_CONTRACT: 'Versicherungspolice',
    ITEM_IS_PROTECTED_BY_INSURANCE: 'Der Gegenstand ist durch die Versicherung geschützt',
    INSURANCE_COVERAGE_START: 'Beginn des Versicherungsschutzes',
    INSURANCE_COVERAGE_UNTIL: 'Versicherungsschutz bis',
    DOWNLOAD_PRODUCT_SHEET: 'Produktbeschreibung herunterladen',
    DOWNLOAD_INSURANCE_CERTIFICATE: 'Laden Sie das Zertifikat herunter',
    CANCEL_CONTRACT: 'Versicherung kündigen',

    // claim form
    PROVIDE_INFO_FOR_CLAIM: 'Bitte geben Sie uns einige weitere Informationen, bevor wir Ihren Anspruch bearbeiten',
    ATTACH_PHOTO_VIDEO: 'Bitte fügen Sie, falls relevant, Fotos oder Videos bei, die den Schaden zeigen.',
    DESCRIBE_WHAT_HAPPEND: 'Bitte beschreiben Sie, was passiert ist',
    ENTER_HERE: 'Hier eingeben',
    SUBMIT_CLAIM: 'Schaden melden',
    PLEASE_ATTACH_FILES: 'Bitte Dateien anhängen',
    THANKS_FOR_REQUEST: 'Vielen Dank für das Einreichen Ihres Antrags. Wir werden ihn gerne bearbeiten und Ihnen in den nächsten Tagen eine Antwort zukommen lassen.',
    YOUR_INSURANCE_COVERS: 'Ihre Versicherung übernimmt nur die Reparatur durch einen unserer zertifizierten Partner. Sie können hier eine Reparaturwerkstatt in Ihrer Nähe finden.',
    SHOW_PARTNER_NETWORK: 'Partnernetzwerk anzeigen',

    // confirmation
    YOUR_CONTRACT_HAS_BEEN_CANCELLED: 'Ihre Versicherung wurde gekündigt. Sie werden in Kürze eine Bestätigungs-E-Mail erhalten.',
    GOT_IT: 'Klar!',
    THE_INSURANCE_WILL_BE_CANCELLED: 'Die Versicherung wird gekündigt ab',
    ARE_YOU_SURE_TO_CANCEL: 'Sind Sie sicher, dass Sie den Schutz vom xxxx zu kündigen',
    RETURN: 'Zurück',
    CONFIRM_REACTIVATION: 'Bitte bestätigen Sie, dass Sie die Versicherung reaktivieren möchten.',
    YOUR_CONTRACT_HAS_BEEN_REACTIVATED: 'Ihre Versicherung wurde reaktiviert. Sie erhalten in Kürze eine Bestätigungsmail.',

    // contract statuses
    ACTIVE: 'aktiv',
    PENDING_CANCELLATION: 'gekündigt',
    VOID: 'annuliert',

    BENEFICIARY_TITLE: 'Wer soll in Ihre Versicherung aufgenommen werden?',
    BENEFICIARY_QUESTION: 'Whom would you like to cover?',
    BENEFICIARY_OPTION1: 'ich selbst',
    BENEFICIARY_OPTION2: 'mich und meine Familie/Haushalt',
}

export default deCH;
