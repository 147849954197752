import React, { useEffect, useState } from 'react';
import { fetchStatuses } from '../../../../../store/issuerApp/issuerApp.actions';
import Unconnected from './Unconnected';
import Connected from './Connected';
import Ready from './Ready';
// import whatsapp from '../../../../../dist/images/whatsapp.png';
import './whatsapp.scss';

const WhatsappDetails = ({ role, active, mobile }) => {
    const [status, setStatus] = useState();

    useEffect(() => {
        fetchStatuses()
            .then((res) => {
                setStatus(res.length ? res[0].status : 'UNCONNECTED');
            });
    }, []);

    const callback = () => {
        setStatus('CONNECTED');
    };

    const renderWizard = (st) => {
        switch (st) {
            case 'READY':
                return <Ready />;
            case 'UNCONNECTED':
                return <Unconnected callback={callback} role={role} />;
            default: return <Connected />;
        }
    };

    return <div className={`details-wrapper ${active && 'active'} ${mobile && 'mobile'}`}>
        {(status && renderWizard(status)) || null}
    </div>;
};

export default WhatsappDetails;
