// eslint-disable-next-line import/no-cycle
import { segment_fields, trigger_fields } from '../components/OfferManagement/constants';

export const returnOptionStructure = (val, label) =>
    ({ label: label || val, value: val });

export const generateAgeOptions = (len, isMax) =>
    [...Array(len).keys()].map((_, i) => returnOptionStructure(i, `${!isMax ? i * 10 : i * 10 + 9} years`));

export const generateArray = (min, max) =>
    [...Array(max - min + 1).keys()].map((_, i) => i + min);

export const capitalizeString = str =>
    (str.charAt(0).toUpperCase() + str.slice(1).toLowerCase());

export const generateGenderOptions = genders =>
    genders.map(g => returnOptionStructure(g));

export const errObjInitial = {
    item_types: '',
    culture: '',
    allocations: '',
    minAge: '',
    maxAge: '',
    gender: ''
};

export const initialObject = {
    culture: {},
    minAge: {},
    maxAge: {},
    gender: {},
    allocations: []
};

export const groupByKey = (items, key) => items.reduce((result, item) => ({
    ...result,
    [item[key]]: [
        ...(result[item[key]] || []),
        item,
    ],
}), {});

export const prepareAllocations = (allocations, culture) => allocations
    .map((a) => {
        const res = {
            ...a,
            allocation: +a.allocation,
            reminder: a.reminder.value,
            coverage_check_enabled: a.coverage_check_enabled.value,
            offered_products: a.offered_products.map(({ value }) => value),
            item_type_specific: a.item_type_specific.value
        };

        if (culture) {
            res.offer_template = {
                template: a.offer_template.template || a.template,
                culture
            };
        }

        return res;
    });

// types = popupState.offering.item_types
export const offeringMapper = (data, action) => {
    const res = { ...data };
    const { culture, template_allocations } = { ...res };
    Object.keys(segment_fields)
        .map((key) => {
            if (res[key] && (key !== 'culture' && segment_fields[key].options)) {
                res[key] = res[key].map(({ value }) => value);
            }
            return key;
        });

    return {
        ...res,
        culture: culture.value,
        template_allocations: prepareAllocations(template_allocations, action === 'add' && culture.value),
    };
};

export const func = (func1, func2, cond) => (cond ? func1 : func2);

export const sortInAlphabeticalOrder = source => source.sort((a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
});

export const listOfItemTypesPk = (items) => {
    const ids = items.reduce((listOfIds, item) => {
        const itemIds = item.item_types.map(({ value }) => +value);
        listOfIds.push(...itemIds);
        return listOfIds;
    }, []);

    return ids.filter((item, i) => ids.indexOf(item) === i);
};

export const excludeItems = (source, helper) => {
    const idsUsed = listOfItemTypesPk(helper);

    return sortInAlphabeticalOrder(source.options
        .filter(({ value }) => idsUsed.indexOf(value) === -1));
};

export const spliceInCopy = (source, i) => {
    const copy = [...source];
    copy.splice(i, 1);
    return copy;
};

export const returnAgeStructure = age => `${Math.min(...age) * 10}-${Math.max(...age) * 10 + 9} years`;

export const triggerMapper = (data) => {
    let body = {};
    body.item_types = data.item_types.map(({ value }) => value);
    Object.keys(trigger_fields.transaction.fields)
        .map((k) => {
            switch (k) {
                case 'transaction_category':
                    body[k] = data[k].value;
                    break;
                case 'transaction_amount':
                    const { payment_min_amount = 0.00, payment_max_amount = 0.00 } =
                     data[k] ? data[k] : data;
                    body = {
                        ...body,
                        payment_max_amount,
                        payment_min_amount
                    };
                    break;
                default:
                    body[k] =
                    (Array.isArray(data[k]) && data[k].map(({ value }) => value)) || [];
                    break;
            }
            return k;
        });
    return body;
};

// decreasing order
export const sortArray = (arr) => {
    arr.sort((a, b) => b - a);
    return arr;
};

export const initialStrategy = {
    allocation: 100,
    offer_template: {
        template: ''
    },
    offered_products: [],
    reminder: { label: 'Yes', value: true },
    coverage_check_enabled: { label: 'Yes', value: true },
    delay: 0,
    channel: { label: 'App', value: 'App' }
};

export const initialTrigger = {
    transaction_category: { value: 'PAYMENT', label: 'Payment' }
};

export const initialSegment = { culture: '', customer_countries: [''], template_allocations: [{ ...initialStrategy }] };
