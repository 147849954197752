export const stylesMapper = ((
    {
        background_color: backgroundColor,
        clickable_color: clickableColor,
        error_color: errorColor,
        header_color: headerColor,
        info_color: infoColor,
        logo,
        primary_color: primaryColor,
        success_color: successColor,
        text_color: textColor,
        warning_color: warningColor,
    }) => ({
    backgroundColor,
    clickableColor,
    errorColor,
    headerColor,
    infoColor,
    logo,
    primaryColor,
    successColor,
    textColor,
    warningColor,
}));

export const fillInEmptyValues = (styles, initialStyles) => {
    const formatedStyles = stylesMapper(styles);
    const entries = Object.entries(formatedStyles).map(([key, value]) =>
        [key, value || initialStyles[key]]
    );
    return Object.fromEntries(entries);
};

export const whiteLabelsMapper = whiteLabels => whiteLabels.map((
    {
        background_color: backgroundColor,
        display_underwriter_logo: displayUnderwriterLogo,
        header_background_color: headerBackgroundColor,
        input_field_background_color: inputFieldBackgroundColor,
        logo,
        name,
        primary_color: primaryColor,
        primary_light_color: primaryLightColor,
        primary_opacity_color: primaryOpacityColor,
        title_color: titleColor,
        uuid,
    }) => ({
    backgroundColor,
    displayUnderwriterLogo,
    headerBackgroundColor,
    inputFieldBackgroundColor,
    logo,
    name,
    primaryColor,
    primaryLightColor,
    primaryOpacityColor,
    titleColor,
    uuid,
}));

export const paymentProductsMapper = paymentProducts => paymentProducts.map((
    {
        brand_usage: isOtherSettingsOpen,
        insurance_mapping_usage: isInsuranceCatalogueOpen,
        offer_template_usage: isOfferingSectionOpen,
        name: product,
        payment_scheme: scheme,
        payment_scheme_subtype: subscheme,
        payment_type: type,
        white_labeling: whiteLabeling,
        uuid
    }) => {
    const isDefaultSettings = value => value !== 'issuer_default';
    return ({
        isOtherSettingsOpen: isDefaultSettings(isOtherSettingsOpen),
        isInsuranceCatalogueOpen: isDefaultSettings(isInsuranceCatalogueOpen),
        isOfferingSectionOpen: isDefaultSettings(isOfferingSectionOpen),
        product,
        scheme,
        subscheme,
        whiteLabeling,
        type,
        uuid
    });
});
