import React from 'react';
import './InsuranceCondition.scss';
import yes from '../../../dist/images/Yes.svg';
import no from '../../../dist/images/No.svg';


const InsuranceCondition = ({ status, text }) => (
    <div className="insurance-condition-wrap">
        <img className="status" alt="coverage" src={status === 'coverage' ? yes : no} />
        <div className="text">{text}</div>
    </div>
);

export default InsuranceCondition;
