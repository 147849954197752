import React, { useCallback } from 'react';
import ReactDropZone from 'react-dropzone';

const ImageDropZone = ({ onSubmit, accept, nodeRef, children }) => {
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const url = URL.createObjectURL(file);
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
            // Do whatever you want with the file contents
                const binaryStr = reader.result;
                onSubmit(binaryStr, file.type, url);
            };
            reader.readAsDataURL(file);
        });
    }, []);
    return (
        <ReactDropZone
            accept={accept}
            onDrop={onDrop}
            multiple={false}
            ref={nodeRef}
            className="drop-zone"
        >
            {children}
        </ReactDropZone>
    );
};

export default ImageDropZone;
