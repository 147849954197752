import React, { Profiler } from 'react';
import { useHistory } from 'react-router-dom';
import './ContractClaimSuccess.scss';
import { useSelector } from 'react-redux';
import { useLanguage } from '../../helpers/customHooks';
import { messages } from '../../constants/messages';
import { pageOnRender } from '../../helpers/amplitude';
import { selectWhiteLabelingStyles } from '../../store/selectors';
import { setupCustomStyles } from '../../helpers/renderingHelpers';
import BlankIcon from './BlankIcon/BlankIcon';
import Button from '../Common/Button/Button';
import withTheme from '../../hocs/withTheme';

const ContractClaimSuccess = () => {
    useLanguage();
    const history = useHistory();
    const whiteLabelingStyles = useSelector(selectWhiteLabelingStyles);
    setupCustomStyles(whiteLabelingStyles);

    const onClick = () => {
        history.push('/purchases');
    };

    return (
        <Profiler id="SuccessPayment" onRender={pageOnRender}>
            <div className="popup">
                <div className="congratulations-wrap">
                    <div className="congratulations-title">{messages.THANKS_FOR_REQUEST}</div>
                    <BlankIcon />
                    <Button className="congratulations-btn" onClick={onClick}>
                        {messages.GOT_IT}
                    </Button>
                </div>
            </div>
        </Profiler>
    );
};

export default withTheme(ContractClaimSuccess);
