import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { statusesTrace } from '../../../../constants';
import { messages } from '../../../../../constants/messages';

const Track = ({ data }) => {
    const [expanded, setExpanded] = useState(false);

    // prev smart render statuses function
    /* const renderStatuses = () => {
        const keys = Object.keys(statusesTrace(messages));
        let i = 0;
        const render = [];
        while (data[keys[i]] !== null && i < 9) {
            const date = new Date(data[keys[i]]);
            const text = statusesTrace(messages)[keys[i]].text;
            render.push(
                <div key={i} className={expanded ? 'expanded' : ''}>
                    {text} <div className="date">{
                        (date !== 'Invalid Date' && !isNaN(date)) ?
                            date.toLocaleString('de') :
                            data[keys[i]]
                    }</div>
                </div>
            );
            i += 1;
        }
        render.push(<div key={i} className={expanded ? 'expanded' : ''}>
        {messages[data.lead_message_status]}
        </div>);

        return {
        arr: render.reverse(),
        color: statusesTrace(messages)[keys[i - 1]] ?
        statusesTrace(messages)[keys[i - 1]].color : ''
        };
    }; */

    // current simple render statuses function
    const renderStatuses = () => {
        const render = [];
        let color = null;
        Object.entries(statusesTrace(messages)).map(([k, v], i) => {
            const date = new Date(data[k]);
            if (data[k] && date !== 'Invalid Date' && !isNaN(date)) {
                color = v.color;
                render.push(
                    <div key={i} className={expanded ? 'expanded' : ''}>
                        {v.text} <div className="date">{
                            date.toLocaleString('de')
                        }</div>
                    </div>
                );
            }
            return i;
        });
        /* render.push(<div className={expanded ? 'expanded' : ''}>
        {messages[data.lead_message_status]}
        </div>); */
        return { arr: render.reverse(), color };
    };

    const expandStatuses = () => {
        setExpanded(prev => !prev);
    };

    const render = renderStatuses();
    return <div className="track-column">
        <span className="point" style={{ backgroundColor: render.color }} />
        {(render.arr.length && <div onClick={expandStatuses}>
            <FontAwesomeIcon className={expanded ? 'up' : 'down'} icon={faChevronDown} />
            <div className="track-column_status">{render.arr}</div>
        </div>) || null}

    </div>;
};

export default Track;
