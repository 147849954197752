import history from '../../helpers/history';

const backendAPI = process.env.REACT_APP_API_URL;

export function serializeURLParams(data) {
    let params = '';
    return Object.keys(data).map((k) => {
        params = `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`;
        return params;
    }).join('&');
}

export function fetchWrapper(
    { url, method = 'GET', body = null, headers = {}, ignoreAuth = false }, notStringify = false
) {
    const requestParams = {
        method,
        headers
    };
    let urlParams = '';
    if (method !== 'GET' && body) {
        if (notStringify) {
            requestParams.body = body;
        } else {
            requestParams.body = JSON.stringify(body);
            headers['Content-Type'] = 'application/json';
        }
    } else if (method === 'GET' && body && Object.keys(body).length) {
        urlParams = '?';
        urlParams += serializeURLParams(body);
    }
    const authToken = localStorage.getItem('access_token');
    if (authToken && !ignoreAuth) {
        headers.Authorization = `JWT ${authToken}`;
    }
    return fetch(`${backendAPI}${url + urlParams}`, requestParams)
        .then((response) => {
            if (!response) {
                return Promise.reject(null);
            }
            if (response.status >= 200 && response.status < 400) {
                try {
                    return response.json()
                        .catch(() => Promise.resolve({}));
                } catch (err) {
                    return Promise.resolve({});
                }
            }
            if (response.status === 401 || response.status === 403) {
                history.push('/login');
            }
            return response.json()
                .then(err => Promise.reject(err));
        }).catch((error) => {
            if (error.status === 401 || error.status === 403) {
                history.push('/login');
            }
            return Promise.reject(error);
        });
}

export const parseError = (error, predicate, message = '') => {
    if (typeof error === 'string') {
        return message ? `${message}, ${predicate ? `${predicate}:` : ''} ${error}` : error;
    }
    if (Array.isArray(error) && typeof error[0] === 'object') {
        const text = error.map(obj => parseError(obj, '', message));
        return message ? `${message}, ${text}` : text;
    }
    if (Array.isArray(error)) {
        const text = `${predicate ? `${predicate}:` : ''} ${error.join(',')}`;
        return message ? `${message}, ${text}` : text;
    }
    if (typeof error === 'object') {
        const text = Object.keys(error)
            .map(key => parseError(error[key], key, message))
            .join(', ');
        return text;
    }
    return message;
};
