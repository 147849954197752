import React, { useRef, useImperativeHandle } from 'react';
import {
    Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_KEY } from '../../../../constants/constants';
import Price from './Price';
import CardHolder from './CardHolder';
import StripeForm from './StripeForm';
import Points from './Points';
import Iban from '../Iban/Iban';
import CheckBoxSet from '../CheckBoxSet/CheckBoxSet';
import './stripe.scss';

const stripePromise = loadStripe(STRIPE_KEY);

const Stripe = React.forwardRef(({
    cardHolder,
    uuid,
    product,
    titles,
    errorCallback,
    paymentCallback,
    isIban,
    email,
    isHelvetia,
    isDetailedDataPrivacy,
    isSanitas
}, ref) => {
    const child = useRef();

    const handleSubmit = () => { child.current.handleSubmit(); };

    useImperativeHandle(ref, () => ({ handleSubmit }));

    return <Elements stripe={stripePromise}>
        <form className="card-form-wrapper">
            <Price product={product} />
            <CardHolder label={titles.CARDHOLDER_NAME} holder={cardHolder} />
            {isIban && <Iban errorCallback={errorCallback} />}
            <StripeForm
                ref={child}
                uuid={uuid}
                label={titles.CARD_INFORMATION}
                errorCallback={errorCallback}
                paymentCallback={paymentCallback} />
            <CheckBoxSet
                isSanitas={isSanitas}
                product={product}
                isHelvetia={isHelvetia}
                isDetailedDataPrivacy={isDetailedDataPrivacy}
                titles={titles}
                errorCallback={errorCallback} />
            <Points email={email} titles={titles} isSanitas={isSanitas} />
        </form>
    </Elements>;
});

export default Stripe;
