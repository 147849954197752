const menuItems = messages => ([{
    key: 'clients',
    title: messages.CLIENTS
}, {
    key: 'whatsapp',
    title: messages.WHATSAPP
}, {
    key: 'offer',
    title: messages.NEW_DIGITAL_OFFER
}, {
    key: 'track',
    title: messages.OFFER_TRACKING
}, {
    key: 'contracts',
    title: messages.CONTRACTS
}, {
    key: 'management',
    title: messages.USER_MANAGEMENT
}]);

export const trackTableItems = messages => ({
    first_name: { title: messages.FIRST_NAME },
    last_name: { title: messages.FAMILY_NAME },
    phone_number: { title: messages.PHONE_NUMBERS },
    proposed_option_name: { title: messages.PRODUCT_OFFERED },
    tracking: { title: messages.TRACKING, sort: 'updated_at' },
});

export const statusesTrace = messages => ({
    /* lead_message_created_at: { text: messages.CREATED, color: '#05b5cc' }, */
    lead_message_sent_at: { text: messages.SENT, color: '#e225e2' },
    lead_message_delivered_at: { text: messages.DELIVERED, color: '#f68818' },
    lead_message_read_at: { text: messages.READ, color: '#185ef6' },
    first_login_at: { text: messages.LINK_CLICKED, color: '#185ef6' },
    last_login: { text: messages.LAST_LOGIN, color: '#185ef6' },
    /* filled_pre_offer_at: { text: messages.PRE_OFFER, color: '#f68818' }, */
    insurance_offers_viewed_at: { text: messages.VIEWED, color: '#f68818' },
    selected_offer_at: { text: messages.SELECTED, color: '#e225e2' },
    /* filled_post_offer_at: { text: messages.POST_OFFER, color: '#05b5cc' }, */
    /* profile_filled_at: { text: messages.PROFILE, color: '#05b5cc' }, */
    contract_exists: { text: messages.PURCHASED, color: '#3ab800' },
});

export const contactsTableItemsMandatory = messages => ({
    first_name: { title: messages.FIRST_NAME, sort: 'first_name' },
    last_name: { title: messages.FAMILY_NAME, sort: 'last_name' },
    phone_number: { title: messages.PHONE_NUMBERS, sort: 'phone_number' },
    initiated_contact_at: { title: '' }
});

export const contactsTableItems = messages => ({
    first_name: { title: messages.FIRST_NAME, sort: 'first_name' },
    last_name: { title: messages.FAMILY_NAME, sort: 'last_name' },
    phone_number: { title: messages.PHONE_NUMBERS },
    // gender: messages.GENDER,
    tax_number: { title: messages.TAX_NUMBER },
    birthday: { title: messages.BIRTHDAY },
    email: { title: messages.EMAIL_ADDRESS },
    address: { title: messages.ADDRESS },
    advisor: { title: messages.ADVISOR },
});

export const roles = messages => [{ label: messages.ADVISOR, value: 'advisor' },
    { label: messages.TEAM_MANAGER, value: 'team_manager' }];

export const managementTableItems = messages => ({
    first_name: { title: messages.FIRST_NAME },
    last_name: { title: messages.FAMILY_NAME },
    phone_number: { title: messages.PHONE_NUMBERS },
    email: { title: messages.EMAIL_ADDRESS },
    /* role: messages.ROLE, */
    external_id: { title: messages.EXTERNAL_ID },
    userpartnerrole_set: { title: messages.ROLE },
    // is_issuer_staff: messages.IS_ISSUER_STAFF,
    // is_active: messages.IS_ACTIVE
});

export const contractsTableItems = messages => ({
    insurance_product_name: { title: messages.PRODUCT },
    underwriter_name: { title: messages.INSURANCE },
    first_name: { title: messages.FIRST_NAME },
    last_name: { title: messages.FAMILY_NAME },
    premium: { title: messages.PREMIUM },
    coverage_start_date: { title: messages.CONTRACT_DATE },
    certificate: { title: '' }
});

export const required = {
    clients: ['first_name', 'last_name', 'phone_number'],
    management: ['first_name', 'last_name', 'email'],
};

export const notificationsTableItems = messages => ({
    created_at: { title: messages.DATE },
    name: { title: messages.TITLE },
    info: { title: messages.SUMMARY }
});

export const delimiters = messages => [{ value: ';', label: messages.SEMICOLON }, { value: ',', label: messages.COMMA }];

export default menuItems;
