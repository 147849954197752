import React, { useRef, useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import OfferManagementSelect from '../OfferManagementCommon/OfferManagementSelect';
import EditItem from '../OfferManagementCommon/EditItem';
import Footer from '../OfferManagementCommon/Footer';
import { segment_fields as fields } from '../constants';


const SegmentEditMode = ({ data = {}, onDelete, onSave, onCancel, isFooter = true, onChange }) => {
    const [formData, setFormData] = useState();
    // const { primaryColor } = useSelector(selectStyles);
    const [segments, setSegments] = useState();

    useEffect(() => {
        const fSegments = []; const
            fData = {};
        Object.entries(fields)
            .map(([key, value]) => {
                if (key === 'address_since' && data.customer_address_since_max > 0) {
                    const { customer_address_since_max, customer_address_since_min } = data;
                    fData[key] = {
                        customer_address_since_max,
                        customer_address_since_min
                    };
                } else if ((Array.isArray(data[key]) && data[key].length) || key === 'culture') {
                    if (key === 'customer_regions') {
                        fData[key] = data[key].join(', ');
                    } else if (key === 'age_group_codes') {
                        fData[key] = {
                            min_age: Math.min(...data[key]) * 10,
                            max_age: Math.max(...data[key]) * 10 + 9
                        };
                    } else {
                        fData[key] = data[key];
                    }
                } else {
                    fSegments.push({ label: value.title, value: key });
                }
                return key;
            });
        setFormData(fData);
        setSegments(fSegments);
    }, [data]);


    const ref = useRef(null);

    const handleFormDataChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleInputFormDataChange = (field, value, border) => {
        setFormData((prev) => {
            const res = border ? {
                ...prev,
                [field]: {
                    ...prev[[field]],
                    [border]: +value
                } } : {
                ...prev,
                [field]: value
            };

            return res;
        });
    };

    useEffect(() => {
        if (!isFooter) {
            onChange(formData);
        }
    }, [formData]);

    const handleSegmentationSelect = (e) => {
        setFormData(prev => ({ ...prev, [e.value]: [] }));
        setSegments(prev => prev.filter(s => s.value !== e.value));
    };

    const handleSegmentationDelete = (field) => {
        setSegments((prev) => {
            prev.push({ label: fields[field].title, value: field });
            return prev;
        });

        setFormData((prev) => {
            delete prev[field];
            return { ...prev };
        });
    };

    return (
        (formData && <form className="offer-management-card__section__segments__form">
            <main ref={ref}>
                {
                    Object.keys(formData).map((key, i) =>
                        <EditItem
                            key={i}
                            fields={fields}
                            prop={key}
                            item={formData}
                            index={i}
                            onDelete={handleSegmentationDelete}
                            onSelectChange={handleFormDataChange}
                            onInputChange={handleInputFormDataChange} />)
                }
                <div className="offer-management-card__section__segments__form__item">
                    <span style={{ fontWeight: 500, display: 'inline-block', marginBottom: 10 }}>
                        Add a new segmentation
                    </span>
                    <OfferManagementSelect
                        menuPlacement="top"
                        value=""
                        isCustomized
                        options={segments}
                        onChange={e => handleSegmentationSelect(e)} />
                </div>
            </main>
            {
                isFooter && <Footer
                    onCancel={e => onCancel(e)}
                    onDelete={onDelete}
                    onSave={() => onSave(formData)} />
            }
        </form>) || null
    );
};

export default SegmentEditMode;
