import lap from '../dist/images/laptop.svg';
import machine from '../dist/images/machine.svg';
import profile from '../dist/images/Profile.svg';
import calendar from '../dist/images/Calendar.svg';
import card from '../dist/images/id-card.svg';
import group from '../dist/images/Group.svg';
import location from '../dist/images/Location.svg';
import emailimg from '../dist/images/Email.svg';
import phone from '../dist/images/Phone.svg';
import {
    validateDate,
    validateEmail,
    validateMandatory,
    validatePhone,
    validateBirthday,
    validateNif,
    validateNationality
} from '../helpers/validationHelpers';
import { messages } from './messages';

export const steps = {
    Symptoms: 0,
    Time: 1,
    Code: 2,
    Info: 3,
    Rewie: 4,
};

export const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const IBAN_REGEXP = /^ES[0-9]{2}[0-9]{20}$/i
export const NIF_REGEXP = /^(X(-|\.)?0?\d{7}(-|\.)?[A-Z]|[A-Z](-|\.)?\d{7}(-|\.)?[0-9A-Z]|\d{8}(-|\.)?[A-Z])$/i

export const PRIMARY_COLOR = '#00008F'
export const BG_COLOR = '#F8F9FF'

export const STEPS_CLASSES = {
    1: 'first-step',
    2: 'second-step',
    3: 'third-step'
}

export const STEPS = {
    LANGUAGE_SELECT: 'LANGUAGE_SELECT',
    TAKE_OR_UPLOAD_PHOTO: 'TAKE_OR_UPLOAD_PHOTO',
    TAKE_PHOTO: 'TAKE_PHOTO',
    REVIEW_PHOTO: 'REVIEW_PHOTO',
    QUALIFIED_ITEMS_SELECTION: 'QUALIFIED_ITEMS_SELECTION',
    UNQUALIFIED_ITEMS_SELECTION: 'UNQUALIFIED_ITEMS_SELECTION',
    ITEM_TYPE_DETAILS: 'ITEM_TYPE_DETAILS',
    INSURANCE_PRODUCT_SELECTION: 'INSURANCE_PRODUCT_SELECTION',
    USER_DETAILS: 'USER_DETAILS',
    CARD_DETAILS: 'CARD_DETAILS',
    PRODUCTS_LIST: 'PRODUCTS_LIST',
    PRE_BENEFICIARY_SCREEN: 'PRE_BENEFICIARY_SCREEN',
    POST_BENEFICIARY_SCREEN: 'POST_BENEFICIARY_SCREEN',
    BENEFICIARY_SCREEN: 'BENEFICIARY_SCREEN',
    INSURANCE_PRODUCTS_LIST: 'INSURANCE_PRODUCTS_LIST',
    HELVETIA_PRE_DETAILS: 'HELVETIA_PRE_DETAILS',
    HELVETIA_POST_DETAILS: 'HELVETIA_POST_DETAILS',
    HELVETIA_EXPLANATION: 'HELVETIA_EXPLANATION',
    TRAVEL_INSURANCE_PRE: 'TRAVEL_INSURANCE_PRE',
    POST_INSUREE: 'POST_INSUREE'
}

/* export const STEPPS = [
    {
        id: 1, steps: [STEPS.TAKE_RECEIPT, STEPS.REVIEW_PHOTO]
    },
    {
        id: 2, steps: [STEPS.ITEM_TYPE_DETAILS, STEPS.PRODUCTS_LIST]
    },
    {
        id: 3, steps: [STEPS.INSURANCE_PRODUCT_SELECTION]
    }
]

export const SINGLE_ITEM_STEPPS = [
    {
        id: 1, steps: [STEPS.TAKE_RECEIPT, STEPS.REVIEW_PHOTO]
    },
    {
        id: 3, steps: [STEPS.INSURANCE_PRODUCT_SELECTION]
    },
] */

export const STEPPS = [
    [STEPS.BENEFICIARY_AND_POSTAL_CODE_SCREEN, STEPS.PRODUCTS_LIST, STEPS.TAKE_OR_UPLOAD_PHOTO, STEPS.TAKE_PHOTO, STEPS.REVIEW_PHOTO, STEPS.ITEM_TYPE_DETAILS, STEPS.PRE_BENEFICIARY_SCREEN],
    [STEPS.INSURANCE_PRODUCT_SELECTION],
    [STEPS.USER_DETAILS, STEPS.CARD_DETAILS, STEPS.TAKE_OR_UPLOAD_PHOTO, STEPS.TAKE_PHOTO, STEPS.REVIEW_PHOTO, STEPS.POST_BENEFICIARY_SCREEN]
]

export const APP_FLOWS = {
    INSURANCE: 'INSURANCE',
    CONTRACTS: 'CONTRACTS'
}

export const attributesConfig = {
    first_name: {
        icon: profile,
        validator: validateMandatory
    },
    last_name: {
        icon: group,
        validator: validateMandatory
    },
    phone_number: {
        icon: phone,
        validator: validatePhone
    },
    email: {
        icon: emailimg,
        validator: validateEmail
    },
    birthday: {
        icon: calendar,
        //validator: validateBirthday,
        type: 'date'
    },
    address: {
        icon: location,
        validator: validateMandatory,
        type: 'address'
    },
    street: {
        validator: validateMandatory,
    },
    city: {
        validator: validateMandatory,
    },
    zip: {
        validator: validateMandatory,
    },
    room: {
        validator: null
    },
    tax_number: {
        validator: validateNif,
        icon: card
    },
    nationality: {
    validator: validateMandatory
    }
};

// TODO to delete
export const addressAttributes = [
{ attribute: 'street', placeholder: 'Calle y número', label: "Dirección", type: 'text' },
{ attribute: 'room', placeholder: "Piso y puerta", label: '', type: 'text' },
{ attribute: 'zip', placeholder: 'Cód. Pos.', label: "", type: 'number' },
{ attribute: 'city', placeholder: 'Ciudad', label: "", type: "text" }];

export const OFFER_STATES = {
    UNQUALIFIED: 'UNQUALIFIED_OFFER',
    QUALIFIED: 'QUALIFIED_OFFER'
};

export const DETAILS_OPTIONS = {
    CAMERA: 'camera',
    GALLERY: 'gallery',
    MANUALLY: 'manually'
}

export const CONTRACT_STATUSES = {
    ACTIVE: 'ACTIVE',
    PENDING_CANCELLATION: 'PENDING_CANCELLATION',
    VOID: 'VOID'
};

export const STRIPE_KEY = 'pk_test_51HHD98E1YGeNwbPjyhdLI8K1oCeJDKzikQJnJOgT75l3y0a6CfR7uWUlBSBJe30NuuTja1AajG8jTBnJ7PUt3PtT00dMhsXFSa';

export const MOCK_DATA = {
    insuranceProducts: [
        {
            "uuid": "1",
            "branding_name": "Best E-Bike All Risk",
            "sales_arguments": "Your bicycle is optimally insured",
            "price": "7.80",
            "currency": "CHF",
            "currency_symbol": "CHF",
            "payment_scheme": "MONTHLY",
            "contract_length_type": "FLEXIBLE",
            "contract_length": null,
            "underwriter_logo": "https://alyyndev.blob.core.windows.net/public-media/underwriter/logo/Best_Insurance.jpg"
        },
        {
            "uuid": "2",
            "branding_name": "E-Bike warranty extension",
            "sales_arguments": "Your warranty is extended by 2 years",
            "price": "4.20",
            "currency": "CHF",
            "currency_symbol": "CHF",
            "payment_scheme": "MONTHLY",
            "underwriter_logo": "https://alyyndev.blob.core.windows.net/public-media/underwriter/logo/Best_Insurance.jpg"
        }
    ],
    cultures: [{
        "uuid": "abe8a004-b30d-4db2-9b66-59796b33beb0",
        "code": "en",
        "english_name": "English",
        "language_code": "EN",
        "icon": "https://alyyndev.blob.core.windows.net/media/Culture/abe8a004-b30d-4db2-9b66-59796b33beb0/icon/united-kingdom-flag-button-square-large.png?se=2021-06-24T08%3A39%3A34Z&sp=r&sv=2019-02-02&sr=b&sig=0pTVrgKj8RsFluB7uF6VLh2RiMihqOy5QyQ90ghKPsU%3D",
        "currency_position": "BEFORE"
    }, {
        "uuid": "5eb11ebc-fda4-4ea7-853e-f91d2ea5600d",
        "code": "de-ch",
        "english_name": "German (Switzerland)",
        "language_code": "DE",
        "icon": "https://alyyndev.blob.core.windows.net/media/Culture/5eb11ebc-fda4-4ea7-853e-f91d2ea5600d/icon/germany-flag-button-square-large.png?se=2021-06-24T08%3A39%3A34Z&sp=r&sv=2019-02-02&sr=b&sig=VcOUaWlQCjA7RPA/snzUbHCyb8RHn9GsYrpxLG5vRzI%3D",
        "currency_position": "AFTER"
    }]
}

export const POSITIONS = {
    10: 'PRE_OFFER',
    100: 'POST_OFFER',
    999: 'DISABLED'
}
