import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { selectBeneficiaryAttributes, selectSkipBeneficiaryAttributes, selectBreadcrumbs, selectCurrentStepNumber, selectProfile } from '../../../store/common/common.selector';
import { useLanguage } from '../../../helpers/customHooks';
import { setStep } from '../../../store/productInsurance/productInsurance.actions';
import Header from '../../Header';
import { messages } from '../../../constants/messages';
import Footer from '../../Common/Footer/Footer';
import CheckBoxField from '../../Common/Checkbox/CheckBoxField';
import InputWrap from '../../Common/Input/InputWrap';
import { setCurrentStepNumber, setSkipBeneficiaryAttributes, setBeneficiaryAttributeValue, saveBeneficiaryAttributes } from '../../../store/common/common.actions';
import { previousPageNavigation, nextPageNavigation, changeAttribute, beneficiarySelection } from '../../../helpers/amplitude';

import { setActualFooterScreen } from '../../../store/payment/payment.actions';
import './BeneficiaryAttributes.scss';
import '../ItemAttributes/ItemAttributes.scss';

const BeneficiaryAttributes = () => {
    useLanguage();

    const options = [messages.BENEFICIARY_OPTION1, messages.BENEFICIARY_OPTION2];
    const [error, setError] = useState({});

    const history = useHistory();
    const dispatch = useDispatch();

    const attributes = useSelector(selectBeneficiaryAttributes);
    const skipBeneficiaryAttributes = useSelector(selectSkipBeneficiaryAttributes);
    const currentStepNumber = useSelector(selectCurrentStepNumber);
    const breadcrumbs = useSelector(selectBreadcrumbs);
    const refs = useRef([]);
    const { lead_uuid } = useSelector(selectProfile);

    const onBackStep = () => {
        const { step } = breadcrumbs[currentStepNumber - 1];
        previousPageNavigation({ uuid: lead_uuid, page: step });
        dispatch(setCurrentStepNumber(currentStepNumber - 1));
        dispatch(setStep(step));
    };

    const validate = (source) => {
        let isValid = true;
        let sum = 0;
        attributes.map(({ value, key }) => {
            if (key !== 'for_whom') {
                sum += +value;
                if (!value.length || +value < 0) {
                    if (!source) {
                        setError(prev => ({ ...prev, [key]: messages.MANDATORY_FIELD }));
                    }
                    isValid = !!(value.length && +value >= 0);
                }
            }
            return value;
        });
        return isValid && sum > 0;
    };

    const navigateNext = () => {
        const { step, payment } = breadcrumbs[currentStepNumber + 1];
        nextPageNavigation({ uuid: lead_uuid, page: step });
        if (payment) {
            dispatch(setActualFooterScreen(step));
            history.push('/payment');
            // logEvent(EVENTS.ACTIVE_STEP, { page: 'Payment part' });
        }
        dispatch(setStep(step));
        dispatch(setCurrentStepNumber(currentStepNumber + 1));
    };

    const onNextStep = () => {
        if (!skipBeneficiaryAttributes) {
            if (validate()) {
                dispatch(saveBeneficiaryAttributes())
                    .then(() => {
                        navigateNext();
                    });
            }
            return;
        }
        dispatch(saveBeneficiaryAttributes('reset'))
            .then(() => {
                navigateNext();
            });
    };

    const onBeneficiaryCoverageChange = (value) => {
        const { position } = breadcrumbs[currentStepNumber];
        beneficiarySelection({ uuid: lead_uuid, attribute: value ? 'all' : 'me', position });
        dispatch(setBeneficiaryAttributeValue({ key: 'for_whom', value: value ? 'all' : 'me' }));
        dispatch(setSkipBeneficiaryAttributes(!value));
    };

    const onChangeAttributeValue = (value, key) => {
        const { position } = breadcrumbs[currentStepNumber];
        changeAttribute({ uuid: lead_uuid, attribute: key, position });
        dispatch(setBeneficiaryAttributeValue({ key, value }));
        setError({ ...error, [key]: '' });
    };

    const movetoNextInput = (event) => {
        const { keyCode, target } = event;
        if (keyCode === 13) {
            event.preventDefault();
            const index = refs.current.indexOf(target);
            if (index > -1 && index + 1 < refs.current.length) {
                const el = refs.current[index + 1];
                el.focus();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('keyup', movetoNextInput);
        return () => document.removeEventListener('keyup', movetoNextInput);
    }, []);

    return (<div className="beneficiary-wrapper">
        <Header
            stepper
            stepsTitle={messages.BENEFICIARY_TITLE}
            className="beneficiary-header" />
        <div className="beneficiary-body-wrapper main">
            <div className="beneficiary-question">

                {
                    options.map((item, key) => <CheckBoxField
                        key={key}
                        label={item}
                        onClick={() => { onBeneficiaryCoverageChange(key); }}
                        id={key}
                        checked={+skipBeneficiaryAttributes !== key} />)
                }

            </div>

            {
                !skipBeneficiaryAttributes &&
                <div className="discovered-element-wrap">
                    {
                        attributes.map((attr, i) => <div className={cn('discovered-input-wrap', { hidden: !i })} key={attr.key}>
                            <div className="discovered-input-title">
                                <div className="discovered-input-lable">{attr.label}</div>
                            </div>
                            <InputWrap
                                className="discovered-input"
                                type="number"
                                value={attr.value}
                                onChange={value => onChangeAttributeValue(value, attr.key)}
                                error={error[attr.key]}
                                ref={(element) => {
                                    refs.current[i] = element;
                                }}
                            />
                        </div>)
                    }
                </div>
            }
        </div>

        <Footer
            showBack={!!currentStepNumber}
            onNavBack={onBackStep}
            onNextStep={onNextStep}
            disabled={!skipBeneficiaryAttributes && !validate('footer')} />
    </div>);
};

export default BeneficiaryAttributes;
