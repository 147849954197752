import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
import Async from 'react-select/async';
import { fetchServicesOptions, fetchContacts, sendOffer, fetchStatuses } from '../../../../../store/issuerApp/issuerApp.actions';
import OfferCard from './OfferCard';
import Form from './Form';
import Unconnected from '../WhatsApp/Unconnected';
import Connected from '../WhatsApp/Connected';
import Success from '../../Common/Success/Success';
import Error from '../../Common/Error/Error';
import { messages } from '../../../../../constants/messages';
import { detectMobile } from '../../../../helpers/renderingHelpers';


const OfferDetails = ({ role, active, mobile }) => {
    const [data, setData] = useState([]);
    // const [contacts, setContacts] = useState([]);
    const [congrats, setCongrats] = useState(false);
    const [error, setError] = useState(false);
    const [value, setValue] = useState('');
    const [selected, setSelected] = useState(-1);
    const [status, setStatus] = useState();
    const [suggestions, setSuggestions] = useState(0);

    useEffect(() => {
    // fetch WABA status
        fetchStatuses()
            .then((res) => { setStatus(res.length ? res[0].status : 'UNCONNECTED'); });
    }, []);

    useEffect(() => {
        if (status && status === 'READY') {
            fetchServicesOptions()
                .then((r) => {
                    setData(r.sort((a, b) => a.order - b.order));
                });
        }
    }, [status]);

    const onClick = (i) => {
        setSelected(prev => (prev === i ? -1 : i));
    };

    /* useEffect(() => {
    if(active && detectMobile()) {

    }
    }, [active]) */

    const onSelect = (v) => {
        setValue(v);
    };

    const loadContacts = async (v) => {
        // eslint-disable-next-line no-new
        const result = await fetchContacts({ search: v, limit: 100000 });
        setSuggestions(result.length);
        return new Promise((resolve => resolve(result
            .sort((a, b) => ((a.first_name.toUpperCase() > b.first_name.toUpperCase() && 1) ||
                    (b.first_name.toUpperCase() > a.first_name.toUpperCase() ? -1 : 0)))
            .map(c => ({ ...c, value: c, label: `${c.first_name} ${c.last_name}` })))));
    };

    /* const loadOptions = (v) => {
        if (v.length > 2) {
            return loadContacts(v);
        }
        setSuggestions(0);
        return Promise.resolve([]);
    }; */

    const showCongrats = () => {
        setSelected(-1);
        setValue('');
        setCongrats(true);
        setTimeout(() => setCongrats(false), 3000);
    };

    const showError = (err) => {
        setSelected(-1);
        setValue('');
        setError(err);
        setTimeout(() => setError(false), 5000);
    };

    const onSubmit = async (c) => {
        delete c.label;
        delete c.value;
        try {
            await sendOffer(data[selected], c);
            showCongrats();
        } catch (e) {
            showError(e.detail);
        }
    };

    const callback = () => {
        setStatus('CONNECTED');
    };

    const renderWizard = (st) => {
        switch (st) {
            case 'UNCONNECTED':
                return <Unconnected callback={callback} role={role} />;
            default: return <Connected />;
        }
    };

    return <div className={`details-wrapper ${active && 'active'} ${mobile && 'mobile'}`}>{status === 'READY' ? <div className="offer-wrapper">
        <p className="details-wrapper_step-title">{messages.STEP_1}</p>
        <p className="details-wrapper_step-description">{messages.SELECT_INSURANCE}</p>
        {(data.length && <div className="offer-card_set">
            {
                data.map(({ logo, name }, i) =>
                    <OfferCard
                        onClick={() => onClick(i)}
                        active={i === selected}
                        hide={selected > -1 && detectMobile() && i !== selected}
                        icon={logo}
                        text={name}
                        key={i} />)
            }
        </div>) || null}
        {
            (selected > -1 && <>
                <p className="details-wrapper_step-title">{messages.STEP_2}</p>
                <p className="details-wrapper_step-description">{messages.SELECT_CONTACT}</p>
                <div className="details-wrapper_customer-details">
                    <div className="details-wrapper_customer-details_search"><Async
                        noOptionsMessage={() => !suggestions && null}
                        placeholder={messages.SEARCH_CONTACT}
                        classNamePrefix="async-offer"
                        onChange={onSelect}
                        isMulti={false}
                        isClearable
                        cacheOptions
                        /* defaultOptions */
                        loadOptions={v => loadContacts(v)}
                    /></div>
                    {
                        (value && <Form data={value} onSubmit={onSubmit} />) || null
                    }
                </div>
                </>) || null
        }
        {
            congrats && <Success text={messages.OFFER_CONGRATS} />
        }
        {
            error && <Error text={error} />
        }
    </div> : (status && renderWizard(status)) || null}</div>;
};

export default OfferDetails;
