import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { messages } from '../../../../../constants/messages';
import MenuPopupState from '../../../Common/Menu/Menu';
import { selectPaymentProducts } from '../../../../../store/issuerApp/issuerApp.selector';
import { deletePaymentProduct, fetchPaymentProducts } from '../../../../../store/issuerApp/issuerApp.actions';

import './PaymentProductsTable.scss';

const PaymentProductsTable = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const paymentProducts = useSelector(selectPaymentProducts);

    const handleDelete = (id) => {
        dispatch(deletePaymentProduct(id))
            .then(() => dispatch(fetchPaymentProducts()));
    };

    return (
        <div className="table payment-products-table">
            <header className="table__header payment-products-table__header">
                <div>{messages.PAYMENT_PRODUCT}</div>
                <div>{messages.PAYMENT_TYPE}</div>
                <div>{messages.PAYMENT_SCHEME}</div>
                <div>{messages.PAYMENT_SUBSCHEME}</div>
            </header>
            <main className="table__content payment-products-table__content">
                {
                    paymentProducts.map((
                        {
                            uuid,
                            product,
                            type,
                            scheme,
                            subscheme
                        },
                        i
                    ) => (
                        <React.Fragment key={i}>
                            <div>{product}</div>
                            <div>{type?.name || ''}</div>
                            <div>{scheme?.name || ''}</div>
                            <div>{subscheme?.name || ''}</div>
                            <div>
                                <MenuPopupState
                                    handleEdit={() => history.push(`/app/payment-products/edit/${uuid}`)}
                                    handleDelete={() => handleDelete(uuid)}
                                    handleSettings={() => history.push(`/app/payment-products/settings/${uuid}`)}
                                />
                            </div>
                        </React.Fragment>
                    ))
                }
            </main>
        </div>
    );
};
export default PaymentProductsTable;
