import React, { useEffect, useState } from 'react';
import ReactSelect from '../../Common/Select/Select';

const Select = ({ errorCallback, attribute, value, handleInputChange, options, config, label }) => {
    const [error, setError] = useState(null);

    useEffect(() => {
        errorCallback({ [attribute]: !!value });
    }, [value]);

    const handleChange = (v) => {
        setError(config.validator(v).code);
        handleInputChange(v, attribute);
    };

    useEffect(() => {
        if (error !== null) {
            errorCallback({ [attribute]: !error });
        }
    }, [error]);

    return <>
        <p className="input-lable">{label}</p>
        <ReactSelect
            errorCallback={errorCallback}
            value={{
                value,
                label: (value && options
                    .filter(o =>
                        o.value === value)[0].label) || ''
            }}
            options={options}
            onChange={e => handleChange(e.value, attribute)} />
    </>;
};

export default Select;
