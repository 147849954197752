import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { messages } from '../../../../constants/messages';

const useClasses = makeStyles(theme => ({
    iconButton: {
        padding: 0,
        color: theme.palette.main.contrastText,
    }
}));

const MenuPopupState = ({ handleEdit, handleDelete, handleSettings }) => {
    const classes = useClasses();

    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {popupState => (
                <React.Fragment>
                    <IconButton className={classes.iconButton} variant="contained" {...bindTrigger(popupState)}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem
                            onClick={() => {
                                handleEdit();
                                popupState.close();
                            }}
                        >
                            {messages.EDIT}
                        </MenuItem>
                        {handleSettings && <MenuItem
                            onClick={() => {
                                handleSettings();
                                popupState.close();
                            }}
                        >
                            {messages.SETTINGS}
                        </MenuItem>}
                        <MenuItem
                            onClick={() => {
                                handleDelete();
                                popupState.close();
                            }}
                        >
                            {messages.DELETE}
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    );
};
export default MenuPopupState;
