import React from 'react';
import { useSelector } from 'react-redux';
import del from '../../../../dist/images/delete.svg';
import calculateColor from '../../../../helpers/calculateColor';
import { selectStyles } from '../../../../store/issuerApp/issuerApp.selector';
import OfferManagementSelect from './OfferManagementSelect';
import { sortInAlphabeticalOrder } from '../../../helpers/offerManagementHelpers';

const EditItem = ({ type, fields, prop, item = {}, index,
    onInputChange, onSelectChange, onDelete }) => {
    const { primaryColor } = useSelector(selectStyles);
    const inputLabels = ['Min', 'Max'];
    const mapping = {
        address_since: ['customer_address_since_min', 'customer_address_since_max'],
        transaction_amount: ['payment_min_amount', 'payment_max_amount'],
        distance: ['distance_min', 'distance_max'],
        age_group_codes: ['min_age', 'max_age']
    };

    if ((!Object.keys(item).length && typeof item[prop] !== 'undefined') || prop === 'uuid') {
        return null;
    }


    return <div key={index} className="offer-management-card__section__segments__form__item">
        <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between', marginBottom: 10, alignItems: 'top' }}>
            <span style={{ fontWeight: 500 }}>
                {fields[prop].title}
                {fields[prop].unit && ` (in ${fields[prop].unit})`}
                {prop === 'transaction_amount' && ` (${item.min_lead_amount_currency})`}:</span>
            {!fields[prop].isMandatory && <img
                style={{ width: 15, height: 15, cursor: 'pointer' }}
                alt="del"
                src={del}
                onClick={() => onDelete(prop, type)} />}
        </div>
        {
            fields[prop].options ?
                <OfferManagementSelect
                    value={item[prop] || ''}
                    options={sortInAlphabeticalOrder(fields[prop].options)}
                    isMulti={!(prop === 'culture' || prop === 'transaction_category' || prop === 'offer_ins_at_pos' || prop === 'same_country')}
                    isCustomized={prop === 'culture' || prop === 'transaction_category' || prop === 'offer_ins_at_pos' || prop === 'same_country'}
                    onChange={e => onSelectChange(prop, e)} />
                :
                ((prop === 'customer_regions' && <input
                    value={item[prop] || ''}
                    onChange={e => onInputChange(prop, e.target.value)}
                    style={{
                        backgroundColor: calculateColor(primaryColor, 0.15),
                        width: '100%'
                    }}
                    className="offer-management-card__section__segments__form__item__input"
                />) || <div
                    className="offer-management-card__section__segments__form__item__non-select">
                    {
                        inputLabels.map((label, i) => <React.Fragment key={i}>
                            <span style={{ color: primaryColor }}>
                                {label}
                            </span>
                            <input
                                value={item[prop][mapping[prop][i]]}
                                onChange={e =>
                                    onInputChange(prop, e.target.value, mapping[prop][i])}
                                style={{
                                    backgroundColor: calculateColor(primaryColor, 0.15)
                                }}
                                className="offer-management-card__section__segments__form__item__input"
                            /></React.Fragment>
                        )}
                </div>)
        }
    </div>;
};

export default EditItem;
