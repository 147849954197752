const SET_AVAILABLE_ITEMS = 'SET_AVAILABLE_ITEMS';
const SET_STEP = 'SET_STEP';
const SET_PHOTOS = 'SET_PHOTOS';
const SET_ERROR = 'SET_ERROR';
const SET_CURRENT_GOODS_ID = 'SET_CURRENT_GOODS_ID';
const SET_CURRENT_INSURANCE_ID = 'SET_CURRENT_INSURANCE_ID';
const SET_INSURANCE_TYPES = 'SET_INSURANCE_TYPES';
const SET_INSURANCE_CATEGORIES = 'SET_INSURANCE_CATEGORIES';
const SET_INSURANCE_PRODUCTS = 'SET_INSURANCE_PRODUCTS';
const SET_ITEM_TYPE_ATTRIBUTE_VALUE = 'SET_ITEM_TYPE_ATTRIBUTE_VALUE';
const SET_CURRENT_COUNTER_VALUE = 'SET_CURRENT_COUNTER_VALUE';
const SET_PAID_STATUS = 'SET_PAID_STATUS';
const SET_SINGLE_ITEM_TYPE = 'SET_SINGLE_ITEM_TYPE';
const SET_ALREADY_PAID = 'SET_ALREADY_PAID';
const SET_CURRENT_CURRENCY = 'SET_CURRENT_CURRENCY';
const SET_CURRENT_CURRENCY_CODE = 'SET_CURRENT_CURRENCY_CODE';
const SET_IS_ITEM_TYPE_SCREEN = 'SET_IS_ITEM_TYPE_SCREEN';
const SET_ITEM_TYPE_ATTRIBUTES = 'SET_ITEM_TYPE_ATTRIBUTES';
const SET_CUSTOMER_ATTRIBUTES = 'SET_CUSTOMER_ATTRIBUTES';
const SET_RESET_INDEX = 'SET_RESET_INDEX';
const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY';
const SET_RECOGNISED_DATA = 'SET_RECOGNISED_DATA';
const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT';
const SET_UPDATE_PRODUCT = 'SET_UPDATE_PRODUCT';
const SET_UNRECOGNIZED_ITEM = 'SET_UNRECOGNIZED_ITEM';
const SET_INSURANCES_LIST = 'SET_INSURANCES_LIST';
const SET_INSURANCE_PRODUCT_ID = 'SET_INSURANCE_PRODUCT_ID';

export {
    SET_AVAILABLE_ITEMS,
    SET_STEP,
    SET_PHOTOS,
    SET_ERROR,
    SET_CURRENT_GOODS_ID,
    SET_CURRENT_INSURANCE_ID,
    SET_INSURANCE_TYPES,
    SET_INSURANCE_CATEGORIES,
    SET_INSURANCE_PRODUCTS,
    SET_ITEM_TYPE_ATTRIBUTE_VALUE,
    SET_CURRENT_COUNTER_VALUE,
    SET_PAID_STATUS,
    SET_SINGLE_ITEM_TYPE,
    SET_ALREADY_PAID,
    SET_CURRENT_CURRENCY,
    SET_IS_ITEM_TYPE_SCREEN,
    SET_CURRENT_CURRENCY_CODE,
    SET_ITEM_TYPE_ATTRIBUTES,
    SET_CUSTOMER_ATTRIBUTES,
    SET_RESET_INDEX,
    SET_CURRENT_CATEGORY,
    SET_RECOGNISED_DATA,
    SET_CURRENT_PRODUCT,
    SET_UPDATE_PRODUCT,
    SET_UNRECOGNIZED_ITEM,
    SET_INSURANCES_LIST,
    SET_INSURANCE_PRODUCT_ID
};
