/* eslint-disable prefer-const,no-nested-ternary */
import { OFFER_STATES } from '../constants/constants';

/* eslint-disable camelcase */
export const dataURItoBlob = (dataURI, filename) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
    }

    // // write the ArrayBuffer to a blob, and you're done
    return new File([ab], filename, { type: mimeString });
};

export const isItemTypeSelected = ({ item_type }) => !!item_type;

export const mapItemTypeAttributes = ({ available_item_attributes, item_attributes }) => {
    if (!available_item_attributes || !available_item_attributes.length) {
        return [];
    }

    if (!item_attributes) {
        return available_item_attributes;
    }
    return available_item_attributes.map((availAttr) => {
        const attribute = item_attributes.find(attr => attr.key === availAttr.key);
        const value = availAttr.value || (attribute && attribute.value) || '';
        return {
            ...availAttr,
            value
        };
    });
};

export const getFileExtension = type => type.replace(/(image\/|video\/|application\/)/, '');

export const getTypesCount = items => items.length;

export const getCategoriesCount = items => items.reduce((acc, group) => {
    let sum = acc;
    sum += group.item_types.length;
    return sum;
}, 0);

export const getItemsCount = (items, isQualified) => (isQualified
    ? getTypesCount(items) : getCategoriesCount(items));

export const isQualifiedOffer = offerState => offerState === OFFER_STATES.QUALIFIED;

export const formatPrice = (price) => {
    if (price) {
        let [priceInt, priceDec] = price.toString().split('.');
        priceDec = priceDec ? (priceDec.length === 1 ? `${priceDec}0` : priceDec) : '00';
        return (`${priceInt}.${priceDec}`);
    }
    return '';
};
