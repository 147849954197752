import React from 'react';
import {
    Link,
    ListItem, ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    listItem: {
        boxSizing: 'border-box',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,

            '& div': {
                '& span': {
                    color: theme.palette.primary.main,
                },
            },
        },
    },
    listItemText: {
        color: theme.palette.main.contrastText,
        fontSize: 20,
    },
    listItemActive: {
        backgroundColor: theme.palette.primary.light,
        borderRight: `15px solid ${theme.palette.primary.main}`,

        '& div': {
            '& span': {
                color: theme.palette.primary.main,
            },
        },
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    }
}));

const ListSectionItem = ({ name, to }) => {
    const classes = useStyles();

    const isActive = name === 'Merchants';

    return (
        <Link component={RouterLink} to={to} className={classes.link}>
            <ListItem button className={`${classes.listItem} ${isActive ? classes.listItemActive : ''}`}>
                <ListItemText
                    primary={name}
                    classes={{
                        primary: classes.listItemText,
                    }}
                />
            </ListItem>
        </Link>
    );
};
export default ListSectionItem;
