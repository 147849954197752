import React from 'react';
import Dashboard from '../components/Dashboard/Dashboard';

const DashboardContainer = () => (
    <section>
        <main>
            <Dashboard />
        </main>
    </section>
);

export default DashboardContainer;
