import { messages } from '../../constants/messages';

const NIF_REGEXP = /^(X(-|\.)?0?\d{7}(-|\.)?[A-Z]|[A-Z](-|\.)?\d{7}(-|\.)?[0-9A-Z]|\d{8}(-|\.)?[A-Z])$/i;
// eslint-disable-next-line
export const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_REGEXP = /^[+]{1}[0-9]{5,16}$/i;

export const PASSWORD_POLICY = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!<>@£€%$ %^&*-]).{8,20}$/;

const validateMandatory = (value) => {
    if (!value) {
        return messages.MANDATORY_FIELD;
    }
    return '';
};

const validateName = (value) => {
    const error = validateMandatory(value);
    if (!error && value.length > 30) {
        return messages.UP_TO;
    }
    return error || '';
};

const validatePhoneNumber = (value, isRequired = false) => {
    let error = '';
    if (isRequired) {
        error = validateMandatory(value);
        if (!error && !PHONE_REGEXP.test(value)) {
            return messages.PHONE_SHOULD_BE_INTERNATIONAL;
        }
    }
    return error || '';
};


const validateFormat = (value, type, isRequired = false) => {
    if (isRequired) {
        const error = validateMandatory(value);
        if (error) {
            return error;
        }
    } else if (!value) {
        return '';
    }
    const regexp = type === 'email' ? EMAIL_REGEXP : NIF_REGEXP;
    if (!regexp.test(value)) {
        return messages.INVALID_FORMAT;
    }
    return '';
};

const clientsValidator = data => ({
    first_name: validateName(data.first_name),
    last_name: validateName(data.last_name),
    phone_number: validatePhoneNumber(data.phone_number, true),
    tax_number: validateFormat(data.tax_number, 'nif'),
    email: validateFormat(data.email, 'email')
});

export const usersValidator = data => ({
    first_name: validateName(data.first_name),
    last_name: validateName(data.last_name),
    phone_number: validatePhoneNumber(data.phone_number),
    email: validateFormat(data.email, 'email', true)
});

export default clientsValidator;
