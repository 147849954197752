import React from 'react';
import TriggerReadMode from './TriggerReadMode';
import TriggerEditMode from './TriggerEditMode';

const TriggerFragment = ({ data, handleMouseOver, /* handleMouseOut, */
    onDelete, onCancel, onSave, isActive, itemTypeOptions = [] }) =>
    (<div
        onClick={handleMouseOver}>
        {
            !isActive ?
                <TriggerReadMode data={data} /> :
                <TriggerEditMode
                    data={data}
                    onDelete={onDelete}
                    onCancel={onCancel}
                    onSave={onSave}
                    itemTypeOptions={itemTypeOptions} />
        }
    </div>);

export default TriggerFragment;
