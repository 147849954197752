import React from 'react';
import MultiSelect from './CheckBox/MultiSelect';
import CheckBoxSet from './CheckBox/CheckBoxSet';

import { messages } from '../../constants/messages';

const CareFor = ({ data, onSelect, onChange }) => <><MultiSelect title={messages.CARE_FOR} data={data} field="care" selectCallback={onSelect} statuses={messages.CARE_LIST} />
    {data.care && data.care.indexOf('kids') > -1 && <CheckBoxSet title={messages.KIDS_ABOVE_6} data={data} field="kids_above" onChange={onChange} statuses={messages.KIDS_ABOVE_OPTIONS} />}
</>;
export default CareFor;
