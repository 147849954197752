import React, { useState, useEffect } from 'react';

import InputWrap from '../../Common/Input/InputWrap';
// import CheckBoxField from '../../Common/Checkbox/CheckBoxField';
import Select from '../../Common/Select/Select';

import { validateNif } from '../../../helpers/validators';

const Identification = ({ handleInputChange, labels, msgs, errorCallback, data, tag }) => {
    const [idError, setIdError] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [selectedIdType, setSelectedIdType] = useState(undefined);

    const options = Object.keys(labels).map(k => ({ value: k, label: labels[k] }));

    useEffect(() => {
        const id = Object.keys(data).filter(k => (k.includes('id') || k.includes('tax')) && data[[k]])[0];
        if (id) {
            setSelectedIdType(id);
            setIdNumber(data[[id]]);
        } else {
            setSelectedIdType(!tag ? 'tax_number' : tag);
        }
    }, []);

    useEffect(() => {
        errorCallback({ identification: selectedIdType !== 'tax_number' ? !!idNumber : !validateNif(idNumber).code });
    }, [idNumber]);

    useEffect(() => {
        if (idError) {
            setIdError('');
        }
        handleInputChange(idNumber, selectedIdType);
    }, [idNumber]);

    useEffect(() => {
        handleInputChange(selectedIdType, 'options_selector');
    }, [selectedIdType]);

    const handleIdError = (v) => {
        if (selectedIdType === 'tax_number') {
            setIdError(validateNif(v).code);
            return;
        }
        if (!v) {
            setIdError(msgs.MANDATORY_FIELD);
        }
    };

    const handleChange = (v) => {
        setIdNumber(v);
    };

    return <>
        <p className="input-lable">{msgs.IDENTIFICATION_METHOD}</p>
        <div className="identification-wrapper">
            {/* {Object.keys(labels).map((k, i) => <CheckBoxField
            key={i}
            label={labels[[k]]}
            onClick={() => setSelectedIdType(k)}
            id={k}
            checked={k === selectedIdType} />)} */}
            <Select
                standalone={false}
                options={options}
                value={{ value: selectedIdType, label: labels[selectedIdType] }}
                onChange={(e) => { setIdNumber(''); setSelectedIdType(e.value); }} />
            <InputWrap
                className="discovered-input"
                type="text"
                value={idNumber}
                onChange={(v) => { handleChange(v); }}
                error={idError}
                /* lable={msgs.NUMBER} */
                onBlur={e => handleIdError(e.target.value)}
            />
        </div>
    </>;
};

export default Identification;
