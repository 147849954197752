import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import LoginScreen from '../../containers/LoginScreen';
import { messages } from '../../../constants/messages';
import { PASSWORD_POLICY } from '../../helpers/validators';

import './PasswordReset.scss';
import { setNewPassword } from '../../../store/issuerApp/issuerApp.actions';
import Input from '../Common/Input/Input';

const useStyles = makeStyles({
    resetInput: {
        width: '100%',
    },
    resetBtn: {
        color: '#F8F9FF',
        textTransform: 'capitalize',
        padding: '15px',
        borderRadius: 10,
        width: '100%',
    }
});

const PasswordReset = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [data, setData] = useState({
        password: '',
        repeatPassword: '',
    });
    const [validationErrors, setValidationErrors] = useState({
        password: '',
        repeatPassword: '',
    });
    const [mismatchErr, setMismatchErr] = useState(null);

    const setError = (field, message) => {
        setValidationErrors(prev => ({
            ...prev,
            [field]: message,
        }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setError(name, '');
        setMismatchErr('');
        setData({
            ...data,
            [name]: value,
        });
    };

    const validate = () => {
        for (let i = 0; i < Object.entries(data).length; i += 1) {
            const [name, value] = Object.entries(data)[i];
            if (!value) {
                setError(name, messages.EMPTY_FIELD_ERR);
                return false;
            }
            if (name === 'password' && value && !PASSWORD_POLICY.test(value)) {
                setError(name, messages.PASSWORD_POLICY);
                return false;
            }
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationSucceed = validate();
        if (!validationSucceed) return;
        const { password, repeatPassword } = data;
        if (password !== repeatPassword) {
            setMismatchErr(messages.MISMATCH_ERR);
            return;
        }
        dispatch(setNewPassword({
            password
        }))
            .then(() => history.push('/app?tab=general-settings'))
            .catch(() => history.push('/login'));
    };

    return (
        <LoginScreen policy>
            <form onSubmit={handleSubmit} className="password-reset">
                <div className="password-reset__header">{messages.PASSWORD_RESET}</div>
                <div className="password-reset__inputs">
                    <Input
                        isPassword
                        label={messages.PASSWORD}
                        type="password"
                        variant="outlined"
                        name="password"
                        value={data.password}
                        handleChange={handleChange}
                        className={classes.resetInput}
                        err={validationErrors.password}
                    />
                    <Input
                        isPassword
                        label={messages.REPEAT_PASSWORD}
                        type="password"
                        variant="outlined"
                        name="repeatPassword"
                        value={data.repeatPassword}
                        handleChange={handleChange}
                        className={classes.resetInput}
                        err={validationErrors.repeatPassword}
                    />
                    <div className="password-reset__inputs-err">{mismatchErr}</div>
                </div>
                <Button
                    color="primary"
                    className={classes.resetBtn}
                    variant="contained"
                    type="submit"
                >
                    {messages.RESET}
                </Button>
            </form>
        </LoginScreen>
    );
};
export default PasswordReset;
