import React from 'react';
import { messages } from '../../constants/messages';
import OfferManagement from '../components/OfferManagement/OfferManagement';
import OtherSettings from '../components/GeneralSettings/OtherSettings/OtherSettings';

const OfferManagementContainer = () => (
    <section className="general-settings">
        <header className="page-title general-settings__title">
            {messages.GENERAL_SETTINGS}
        </header>
        <main>
            <OfferManagement />
            <OtherSettings />
        </main>
    </section>
);
export default OfferManagementContainer;
