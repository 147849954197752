import { selectActualLanguage } from '../common/common.selector';

export const selectActualStep = state => state.productInsurance.step;
export const selectStep = state => state.productInsurance.step;
export const selectPhotos = state => state.productInsurance.photos;
export const error = state => state.productInsurance.err;
export const insuranceProducts = state => state.productInsurance.insuranceProducts;
export const selectInsuranceProducts = state => state.productInsurance.insuranceProducts;
export const selectInsuranceTypes = state => state.productInsurance.insuranceTypes;
export const selectInsuranceCategories = state => state.productInsurance.insuranceCategories;
export const selectCurrentGoodsId = state => state.productInsurance.currentGoodsId;
export const currentInsuranceId = state => state.productInsurance.currentInsuranceId;
export const selectCurrentInsuranceId = state => state.productInsurance.currentInsuranceId;
export const currentCounterValue = state => state.productInsurance.currentCounterValue;
export const currentGoodsId = state => state.productInsurance.currentGoodsId;
export const selectPaymentStatus = (state) => {
    const language = selectActualLanguage(state);
    const status = state.productInsurance.paymenStatus || [];
    return status.find(message => message.culture_code === language);
};
export const singleItemType = state => state.productInsurance.singleItemType;
export const selectSingleItemType = state => state.productInsurance.singleItemType;
export const alreadyPaid = state => state.productInsurance.alreadyPaid;
export const selectAlreadyPaid = state => state.productInsurance.alreadyPaid;
export const currency = state => state.productInsurance.currency;
export const selectCurrency = state => state.productInsurance.currency;
export const selectCurrencyCode = state => state.productInsurance.currencyCode;
export const isItemTypeScreen = state => state.productInsurance.isItemTypeScreen;
export const selectIsItemTypeScreen = state => state.productInsurance.isItemTypeScreen;
export const selectItemTypeAttributes = state => state.productInsurance.itemTypeAttributes;
export const selectCustomerAttributes = state => state.productInsurance.customerAttributes;
export const resetPhotoIndex = state => state.productInsurance.resetPhotoIndex;
export const selectCurrentCategory = state => state.productInsurance.currentCategory;
export const selectRecognisedData = state => state.productInsurance.recognisedData;
export const selectRecognizeLoading = state => state.productInsurance.recognizeLoading;
export const selectCurrentProduct = state => state.productInsurance.currentProduct;
export const selectInsurancesList = state => state.productInsurance.insurancesList;
export const selectInsuranceProductUuid = state => state.productInsurance.insuranceProductUuid;
