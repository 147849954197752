import React from 'react';
import { useSelector } from 'react-redux';
import { selectWhiteLabelingStyles } from '../../../store/selectors';

const BlankIcon = () => {
    const whiteLabelingStyles = useSelector(selectWhiteLabelingStyles);
    const primaryColor = whiteLabelingStyles.whiteLabelingPrimaryColor;
    const bgColor = whiteLabelingStyles.whiteLabelingBackgroundColor;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="221" height="155" viewBox="0 0 221 155">
            <g fill="none" fillRule="evenodd" transform="translate(0 1)">
                <rect width="107" height="139" x="58" y="13" stroke={primaryColor} strokeLinejoin="round" strokeWidth="1.7" rx="14" />
                <rect width="85" height="111" x="70" y="27" fill={primaryColor} fillOpacity="0.3" rx="9" />
                <path fill={primaryColor} fillRule="nonzero" d="M139.268657,23.7462687 C138.820896,17.1791045 131.432836,16.5074627 131.432836,16.5074627 C131.432836,16.5074627 121.283582,16.7313433 119.343284,9.94029851 C117.402985,3.14925373 111.507463,3.74626866 111.507463,3.74626866 C111.507463,3.74626866 105.61194,3.14925373 103.671642,9.94029851 C101.731343,16.7313433 91.5820896,16.5074627 91.5820896,16.5074627 C91.5820896,16.5074627 84.1940299,17.1791045 83.7462687,23.7462687 C83.2985075,30.3134328 86.0597015,30.1641791 86.0597015,30.1641791 L109.791045,30.3134328 L113.223881,30.3134328 L136.955224,30.1641791 C136.955224,30.1641791 139.791045,30.3134328 139.268657,23.7462687 Z" />
                <path fill={bgColor} fillRule="nonzero" d="M131.507463 45.9104478L91.5820896 45.9104478C89.641791 45.9104478 88.1492537 44.3432836 88.1492537 42.4776119L88.1492537 42.4776119C88.1492537 40.5373134 89.7164179 39.0447761 91.5820896 39.0447761L131.507463 39.0447761C133.447761 39.0447761 134.940299 40.6119403 134.940299 42.4776119L134.940299 42.4776119C134.940299 44.4179104 133.373134 45.9104478 131.507463 45.9104478zM125.910448 60.5373134L94.5671642 60.5373134C93.5223881 60.5373134 92.7014925 59.7164179 92.7014925 58.6716418L92.7014925 58.6716418C92.7014925 57.6268657 93.5223881 56.8059701 94.5671642 56.8059701L125.910448 56.8059701C126.955224 56.8059701 127.776119 57.6268657 127.776119 58.6716418L127.776119 58.6716418C127.776119 59.7164179 126.955224 60.5373134 125.910448 60.5373134zM119.19403 65.0149254L94.119403 65.0149254C93.3731343 65.0149254 92.7014925 64.4179104 92.7014925 63.5970149L92.7014925 63.5970149C92.7014925 62.8507463 93.2985075 62.1791045 94.119403 62.1791045L119.19403 62.1791045C119.940299 62.1791045 120.61194 62.7761194 120.61194 63.5970149L120.61194 63.5970149C120.61194 64.4179104 119.940299 65.0149254 119.19403 65.0149254z" />
                <circle cx="83.746" cy="61.209" r="5.373" fill={bgColor} fillRule="nonzero" />
                <path fill={bgColor} filRrule="nonzero" d="M125.910448 80.1641791L94.5671642 80.1641791C93.5223881 80.1641791 92.7014925 79.3432836 92.7014925 78.2985075L92.7014925 78.2985075C92.7014925 77.2537313 93.5223881 76.4328358 94.5671642 76.4328358L125.910448 76.4328358C126.955224 76.4328358 127.776119 77.2537313 127.776119 78.2985075L127.776119 78.2985075C127.776119 79.3432836 126.955224 80.1641791 125.910448 80.1641791zM126.208955 107.552239L94.1940299 107.552239C93.3731343 107.552239 92.7014925 106.880597 92.7014925 106.059701L92.7014925 106.059701C92.7014925 105.238806 93.3731343 104.567164 94.1940299 104.567164L126.208955 104.567164C127.029851 104.567164 127.701493 105.238806 127.701493 106.059701L127.701493 106.059701C127.776119 106.880597 127.104478 107.552239 126.208955 107.552239zM110.835821 113.074627L93.8208955 113.074627C93.2238806 113.074627 92.7014925 112.552239 92.7014925 111.955224L92.7014925 111.208955C92.7014925 110.61194 93.2238806 110.089552 93.8208955 110.089552L110.910448 110.089552C111.507463 110.089552 112.029851 110.61194 112.029851 111.208955L112.029851 111.955224C111.955224 112.552239 111.507463 113.074627 110.835821 113.074627zM119.19403 84.641791L94.119403 84.641791C93.3731343 84.641791 92.7014925 84.0447761 92.7014925 83.2238806L92.7014925 83.2238806C92.7014925 82.4776119 93.2985075 81.8059701 94.119403 81.8059701L119.19403 81.8059701C119.940299 81.8059701 120.61194 82.4029851 120.61194 83.2238806L120.61194 83.2238806C120.61194 83.9701493 119.940299 84.641791 119.19403 84.641791z" />
                <circle cx="83.746" cy="80.761" r="5.373" fill={bgColor} fillRule="nonzero" />
                <path stroke={bgColor} d="M88.6716418,114.865672 L80.761194,114.865672 C79.7164179,114.865672 78.8208955,113.970149 78.8208955,112.925373 L78.8208955,105.014925 C78.8208955,103.970149 79.7164179,103.074627 80.761194,103.074627 L88.6716418,103.074627 C89.7164179,103.074627 90.6119403,103.970149 90.6119403,105.014925 L90.6119403,112.925373 C90.6119403,113.970149 89.7164179,114.865672 88.6716418,114.865672 Z" />
                <path fill="#50B808" fillRule="nonzero" d="M94.75,101.75 C94.75,101.75 88.75,105.1875 85.3125,110.8125 L81,106 C81,106 82.1875,105.6875 82.8125,106 C83.4375,106.3125 85.0625,107.3125 85.0625,107.3125 C85.0625,107.3125 87.75,103.25 90.6875,101.6875 C93.625,100.125 94.75,101.75 94.75,101.75 Z" />
                <path fill="#FFF" stroke={primaryColor} strokeLinejoin="round" strokeWidth="1.7" d="M127.776119,118.895522 C127.776119,118.895522 133.298507,104.19403 134.865672,102.776119 L171.432836,68.4477612 C172.925373,67.0298507 175.313433,67.1044776 176.80597,68.5970149 L181.955224,74.0447761 C183.373134,75.5373134 183.298507,77.9253731 181.80597,79.4179104 L145.238806,113.746269 C143.597015,115.164179 127.776119,118.895522 127.776119,118.895522 Z" />
                <line x1="134.567" x2="144.791" y1="103.149" y2="114.045" stroke={primaryColor} strokeLinejoin="round" strokeWidth="1.7" />
                <line x1="166.881" x2="142.552" y1="79.045" y2="101.881" stroke={primaryColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" />
                <line x1="168.149" x2="178.448" y1="71.433" y2="82.403" stroke={primaryColor} strokeLinejoin="round" strokeWidth="1.7" />
                <g stroke={primaryColor} opacity=".2" transform="translate(178)">
                    <path d="M31.6774194 8.77419355C31.483871 8.77419355 31.2258065 8.77419355 31.0322581 8.83870968 29.9354839 6.4516129 27.5483871 4.77419355 24.7096774 4.77419355 21.4193548 4.77419355 18.6451613 7.09677419 17.9354839 10.1935484 15.6129032 10.2580645 13.7419355 12.1935484 13.7419355 14.516129 13.7419355 16.9032258 15.6774194 18.9032258 18.1290323 18.9032258L31.6129032 18.9032258C34.3870968 18.9032258 36.6451613 16.6451613 36.6451613 13.8709677 36.7096774 11.0322581 34.4516129 8.77419355 31.6774194 8.77419355zM31.0322581 8.83870968C31.0322581 8.83870968 28.7096774 9.16129032 27.7419355 11.0967742" />
                    <path d="M36.516129 14.0645161C39.2903226 14.0645161 41.5483871 11.8064516 41.5483871 9.03225806 41.5483871 6.25806452 39.2903226 4 36.516129 4 36.3225806 4 36.0645161 4 35.8709677 4.06451613 34.7741935 1.67741935 32.3870968-2.93098879e-14 29.5483871-2.93098879e-14 26.2580645-2.93098879e-14 23.5483871 2 22.9032258 5.09677419M35.8709677 4C35.8709677 4 33.5483871 4.32258065 32.5806452 6.25806452" />
                    <line x1="12.258" y1="18.903" y2="18.903" />
                </g>
                <g stroke={primaryColor} opacity=".1" transform="translate(0 83)">
                    <path d="M26.9258065 7.45806452C26.7612903 7.45806452 26.5419355 7.45806452 26.3774194 7.51290323 25.4451613 5.48387097 23.416129 4.05806452 21.0032258 4.05806452 18.2064516 4.05806452 15.8483871 6.03225806 15.2451613 8.66451613 13.2709677 8.71935484 11.6806452 10.3645161 11.6806452 12.3387097 11.6806452 14.3677419 13.3258065 16.0677419 15.4096774 16.0677419L26.8709677 16.0677419C29.2290323 16.0677419 31.1483871 14.1483871 31.1483871 11.7903226 31.2032258 9.37741935 29.283871 7.45806452 26.9258065 7.45806452zM26.3774194 7.51290323C26.3774194 7.51290323 24.4032258 7.78709677 23.5806452 9.43225806" />
                    <path d="M31.0387097 11.9548387C33.3967742 11.9548387 35.316129 10.0354839 35.316129 7.67741935 35.316129 5.31935484 33.3967742 3.4 31.0387097 3.4 30.8741935 3.4 30.6548387 3.4 30.4903226 3.45483871 29.5580645 1.42580645 27.5290323 2.13162821e-14 25.116129 2.13162821e-14 22.3193548 2.13162821e-14 20.016129 1.7 19.4677419 4.33225806M30.4903226 3.4C30.4903226 3.4 28.516129 3.67419355 27.6935484 5.31935484" />
                    <line x1="10.419" y1="16.068" y2="16.068" />
                </g>
                <g stroke={primaryColor} strokeLinecap="round" strokeWidth="1.7" transform="translate(30 151)">
                    <line x1=".646" x2="150.774" y1="1.087" y2="1.413" />
                    <line x1="156.146" x2="166.146" y1="1.587" y2="1.577" />
                    <line x1="170.146" x2="174.146" y1="1.596" y2="1.592" />
                </g>
            </g>
        </svg>
    );
};

export default BlankIcon;
