import React from 'react';
import { FormControlLabel, Switch, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const CustomSwitch = withStyles(theme => ({
    root: {
        width: 42,
        height: 25,
        padding: 0,
        margin: theme.spacing(1),
        overflow: 'visible',
        fontSize: 24,

        ' & .MuiFormControlLabel-label': {
            fontSize: 24,
        }
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            '& + $track': {
                backgroundColor: theme.palette.primary.light,
                border: `1px solid ${theme.palette.main.border}`,
                opacity: 1,
            },
        },
        '&$focusVisible $thumb': {
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.main.border,
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const useClasses = makeStyles({
    switchLabel: {
        '& .MuiFormControlLabel-label': {
            fontSize: 24,
        },
    },
});


const CustomizedSwitch = ({ checked, handleChange, label, name }) => {
    const classes = useClasses();

    return (
        <>
            <FormControlLabel
                control={
                    <CustomSwitch
                        checked={checked}
                        onChange={handleChange}
                        name={name}
                        color="primary"
                    />
                }
                labelPlacement="start"
                label={label}
                className={classes.switchLabel}
            />
        </>
    );
};
export default CustomizedSwitch;
