import React from 'react';
import CheckBox from './CheckBox';

const CheckBoxSet = ({ data, field, onChange, title, statuses }) => <>
    <p className="input-lable">{title}</p>
    {statuses.map(({ name, key }) => <CheckBox
        label={name}
        key={key}
        checked={data[field] === key}
        toggleCallback={() => onChange(key, field)} />)}
</>;

export default CheckBoxSet;
