import React from 'react';

const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const DashboardCard = ({ title, data, preview }) => (
    <div className="dashboard__card single">
        <span className="dashboard__card__title">{title}</span>
        <div className="dashboard__card__body">
            {
                (!preview && (data.percentage ?
                    <div className="dashboard__card__body__details">
                        <span className="dashboard__card__body__details__main">
                            {`${data.percentage > 0 ? '+' : ''}${data.percentage}%`}
                        </span>
                        <span className="dashboard__card__body__details__additional">
                            {numberWithCommas(data.amount)}
                            {data.currency &&
                            <span style={{ marginLeft: 5 }}>{data.currency}</span>}
                        </span>
                    </div> :
                    <span className="dashboard__card__body__details__main">{numberWithCommas(data.amount)}</span>)) ||
                    <div className="dashboard__card__body__details">
                        <span className="dashboard__card__body__details__main">{numberWithCommas(data.total)}</span>
                        <span className="dashboard__card__body__details__additional">{data.currency}</span>
                    </div>
            }

        </div>
    </div>
);

export default DashboardCard;
