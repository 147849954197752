import React, { forwardRef } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import { Fade } from '@material-ui/core';
import calculateColorWithOpacity from '../../../../helpers/calculateColor';

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 700,
        backgroundColor: theme.palette.main.light,
        boxShadow: theme.shadows[5],
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.main.contrastText,
        height: '200px',
        overflowY: 'auto',
        outline: 'none',
        padding: 20,
        boxSizing: 'border-box',
    },
    backdrop: {
        backgroundColor: calculateColorWithOpacity(theme.palette.main.main, 0.65),
    },

}));

const Popup = forwardRef(({ open, children, className }, ref) => {
    const classes = useStyles();

    return (
        <Modal
            ref={ref}
            open={open}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                classes: {
                    root: classes.backdrop,
                }
            }}

        >
            <Fade in={open}>
                <div className={`${classes.paper} ${className}`}>
                    {children}
                </div>
            </Fade>
        </Modal>
    );
});
export default Popup;
