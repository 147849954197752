import React, { useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history from '../helpers/history';
import { setStickyHeader } from '../store/common/common.actions';
import { selectThemeReadiness, selectCurrentStepNumber } from '../store/common/common.selector';
import { detectMobile } from '../helpers/renderingHelpers';

const withTheme = Component => (props) => {
    const isReady = useSelector(selectThemeReadiness);
    const currentStepNumber = useSelector(selectCurrentStepNumber);
    const screen = useRef();

    const dispatch = useDispatch();

    const detectScroll = useCallback(({ target }) => {
        const { scrollTop } = target;
        const header = document.getElementsByClassName('header-wrapper')[0];

        if (header) {
            dispatch(setStickyHeader(scrollTop >= header.lastChild.clientHeight / 2));
        }
    });

    useEffect(() => {
        const el = screen.current;

        if (detectMobile() && el) {
            el.addEventListener('scroll', detectScroll, { passive: true });
        }
        return () => { if (el) { el.removeEventListener('scroll', detectScroll); } };
    }, [detectScroll, screen]);

    useEffect(() => {
        const loc = localStorage.getItem('location');
        if (loc && isReady) {
            history.push(loc);
            localStorage.removeItem('location');
        }
    }, [isReady]);

    useEffect(() => {
        dispatch(setStickyHeader(false));
    }, []);

    useEffect(() => {
        if (screen.current) {
            screen.current.scrollTo(0, 0);
        }
    }, [currentStepNumber, screen.current]);

    if (!isReady) {
        return (<></>);
    }

    return (
        <div className="screen-container">
            <div className="screen" ref={screen}>
                <Component {...props} />
            </div>
        </div>
    );
};

export default withTheme;
