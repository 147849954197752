import React from 'react';
// import CheckBox from './CheckBoxSet/CheckBox';
import CheckBoxSet from './CheckBoxSet/CheckBoxSet';

const Insurama = ({ titles, errorCallback, product, isSanitas }) => <div style={{ padding: '0 20px' }}>
    <p>{titles.REDIRECT}</p>
    <CheckBoxSet
        isSanitas={isSanitas}
        product={product}
        titles={titles}
        errorCallback={errorCallback} />
    {/*
        <CheckBox
            errorCallback={errorCallback}
            doc={{ key: 'product_sheet',
            doc: product.product_sheet, text: titles.DOWNLOAD_TERMS_AND_CONDITIONS }}
            only={titles.I_HAVE_SUCCESSFULLY_READ_INSURAMA} /> */}
</div>;

export default Insurama;
