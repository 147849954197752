import React, { useState, useEffect } from 'react';
import './table.scss';
import { useHistory } from 'react-router';
import cn from 'classnames';
import sortDataByIncrease, { sortDataByDecrease } from '../../../helpers/funcHelpers';
import small_arrow from '../../../../dist/images/small_arrow.svg';

const sort = {
    sortBy: null,
    increase: null
};

const Table = (props) => {
    const { titles, data, nav, currency } = props;
    const history = useHistory();

    const [tData, setTData] = useState([]);
    const [sorting, setSorting] = useState(sort);

    useEffect(() => {
        const { increase, sortBy } = sorting;
        let sData = null;
        if (sortBy) {
            sData = increase ? sortDataByIncrease(data, sortBy) : sortDataByDecrease(data, sortBy);
        }
        setTData(sData || data);
    }, [data]);

    const createLink = (groupId, merchantId) => {
        let link = nav && `/merchant-groups/${groupId}`;
        if (nav === 'merchant') {
            link = `${link}/merchants/${merchantId}`;
        }
        return link;
    };

    const handleItemClick = (item) => {
        const { group_uuid, uuid } = item;
        history.push(createLink(group_uuid, uuid));
    };

    const toogleSorting = (field) => {
        setSorting((prev) => {
            let state = { ...prev };
            if (state.sortBy === field) {
                state.increase = !state.increase;
            } else {
                state = {
                    sortBy: field,
                    increase: true
                };
            }
            setTData(state.increase ?
                sortDataByIncrease(tData, field) : sortDataByDecrease(tData, field));
            return state;
        });
    };

    /*  ? */
    return <table>
        <thead>
            <tr>
                {
                    titles.map((title, k) =>
                        <th key={k} onClick={() => toogleSorting(title.value)}>
                            <div>
                                <span>{`${title.label} ${title.currency ? ` (${currency})` : ''}`}</span>
                                {
                                    (sorting.sortBy === title.value && sorting.increase !== null) ?
                                        <div
                                            className={cn('sort-arrow',
                                                { up: sorting.increase,
                                                    down: !sorting.increase
                                                })}
                                            style={{ backgroundImage: `url(${small_arrow}` }} /> :
                                        <div className="sort-arrow" />
                                }
                            </div>


                        </th>)
                }
            </tr>
        </thead>
        {
            tData.length ? <tbody>
                {
                    tData.map((item, k) => <tr key={k} onClick={() => handleItemClick(item)}>
                        {
                            titles.map(({ value }, i) => <td key={i}>
                                {
                                    Array.isArray(item[value]) ? item[value].join('; ') : item[value]
                                }
                            </td>)
                        }
                    </tr>)
                }
            </tbody> : null
        }
    </table>;
};

export default Table;
