import React from 'react';
import { useSelector } from 'react-redux';
import Header from '../Header';
import AirlineFlightForm from './AirlineFlightForm';
import './airlineInsurance.scss';
import { selectWhiteLabelingStyles } from '../../store/selectors';
import { setupCustomStyles } from '../../helpers/renderingHelpers';
import { messages } from '../../constants/messages';

const airlines = ['SWISS', 'Lufthansa', 'Easyjet',
    'Adria Airways',
    'Aegean Airlines',
    'Aer Lingus',
    'Aero República',
    'Aeroflot',
    'Aerolineas Argentinas',
    'Aerolineas Galapagos S.A. Aerogal',
    'Aeromexico',
    'Afriqiyah Airways',
    'Aigle Azur',
    'Air Algérie',
    'Air Arabia',
    'Air Astana',
    'Air Austral',
    'Air Baltic',
    'Air Berlin',
    'Air Botswana',
    'Air Caledonie',
    'Air Canada',
    'Air China Limited',
    'Air Corsica',
    'Air Europa',
    'Air France',
    'Air India',
    'Air Koryo',
    'Air Macau',
    'Air Madagascar',
    'Air Malta',
    'Air Mauritius',
    'Air Moldova',
    'Air Namibia',
    'Air New Zealand',
    'Air Niugini',
    'Air Nostrum',
    'Air One S.p.A.',
    'Air SERBIA a.d. Beograd',
    'Air Seychelles',
    'Air Tahiti',
    'Air Tahiti Nui',
    'Air Transat',
    'Air Uganda',
    'Air Vanuatu',
    'AirBridgeCargo Airlines',
    'Aircalin',
    'Airlink',
    'Alaska Airlines',
    'Alitalia',
    'All Nippon Airways',
    'AlMasria Universal Airlines',
    'ALS',
    'American Airlines',
    'Arik Air',
    'Arkia Israeli Airlines',
    'Asia Pacific Airlines',
    'Asiana',
    'Atlas Air',
    'Atlasjet Airlines',
    'Austral',
    'Austrian',
    'AVIANCA',
    'Avianca Brasil',
    'Azerbaijan Airlines',
    'B&H Airlines',
    'Bangkok Air',
    'Belavia - Belarusian Airlines',
    'BH AIR',
    'Biman',
    'Binter Canarias',
    'Blue Panorama',
    'Blue1',
    'bmi Regional',
    'Boliviana de Aviación - BoA',
    'British Airways',
    'Brussels Airlines',
    'Bulgaria air',
    'C.A.L. Cargo Airlines',
    'Cargojet Airways',
    'Cargolux S.A.',
    'Caribbean Airlines',
    'Carpatair',
    'Cathay Pacific',
    'China Airlines',
    'China Cargo Airlines',
    'China Eastern',
    'China Postal Airlines',
    'China Southern Airlines',
    'CityJet',
    'Comair',
    'Condor',
    'COPA Airlines',
    'Corendon Airlines',
    'Corsair International',
    'Croatia Airlines',
    'Cubana',
    'Cyprus Airways',
    'Czech Airlines j.s.c',
    'Delta Air Lines',
    'Donavia',
    'Dragonair',
    'Egyptair',
    'EL AL',
    'Emirates',
    'Estonian Air',
    'Ethiopian Airlines',
    'Etihad Airways',
    'Euroatlantic Airways',
    'Eurowings',
    'Fiji Airways',
    'Finnair',
    'flybe',
    'Freebird Airlines',
    'Garuda',
    'Georgian Airways',
    'Germania',
    'Gulf Air',
    'Hahn Air',
    'Hainan Airlines',
    'Hawaiian Airlines',
    'Hi Fly',
    'Hong Kong Airlines',
    'Hong Kong Express Airways',
    'IBERIA',
    'Icelandair',
    'InselAir',
    'Interair',
    'InterSky',
    'Iran Air',
    'Iran Aseman Airlines',
    'Israir',
    'Japan Airlines',
    'Jazeera Airways',
    'Jet Airways',
    'Jet Lite (India) Limited',
    'JetBlue',
    'Jordan Aviation',
    'JSC Aircompany Yakutia',
    'JSC Nordavia-RA',
    'Juneyao Airlines',
    'Kenya Airways',
    'Kish Air',
    'KLM',
    'Korean Air',
    'Kuwait Airways',
    'LACSA',
    'LAM',
    'Lan Airlines',
    'Lan Argentina',
    'Lan Cargo',
    'Lan Perú',
    'LanEcuador',
    'LIAT Airlines',
    'Libyan Airlines',
    'LOT Polish Airlines',
    'Lufthansa Cargo',
    'Lufthansa CityLine',
    'Luxair',
    'Mahan Air',
    'Malaysia Airlines',
    'Malmö Aviation',
    'Martinair Cargo',
    'MAS AIR',
    'MEA',
    'Meridiana fly',
    'MIAT',
    'Montenegro Airlines',
    'Nesma Airlines',
    'NIKI',
    'Nile Air',
    'Nouvelair',
    'Olympic Air',
    'Oman Air',
    'Onur Air',
    'PAL',
    'Pegasus Airlines',
    'PGA-Portugália Airlines',
    'PIA',
    'Precision Air',
    'PrivatAir',
    'Qantas',
    'Qatar Airways',
    'Rossiya Airlines',
    'Royal Air Maroc',
    'Royal Brunei',
    'Royal Jordanian',
    'SAA',
    'Safair',
    'Safi Airways',
    'SAS',
    'SATA Air Açores',
    'SATA Internacional',
    'Saudi Arabian Airlines',
    'Shandong Airlines',
    'Shanghai Airlines',
    'Shenzhen Airlines',
    'SIA',
    'Siberia Airlines',
    'Sichuan Airlines',
    'Silkair',
    'South African Express Airways',
    'SriLankan',
    'Sudan Airways',
    'SunExpress',
    'Surinam Airways',
    'Syrianair',
    'TAAG - Angola Airlines',
    'TACA',
    'TACA Peru',
    'TACV Cabo Verde Airlines',
    'TAM - Transportes Aéreos del Mercosur Sociedad Anónima',
    'TAM Linhas Aéreas',
    'TAME - Linea Aérea del Ecuador',
    'TAP Portugal',
    'TAROM',
    'Tassili Airlines',
    'Thai Airways International',
    'THY - Turkish Airlines',
    'Tianjin Airlines',
    'TNT Airways S.A.',
    'Transaero',
    'TransAsia Airways',
    'TUIfly',
    'Tunisair',
    'Ukraine International Airlines',
    'United Airlines',
    'UPS Airlines',
    'US Airways',
    'UTair',
    'Uzbekistan Airways',
    'Vietnam Airlines',
    'Virgin Atlantic',
    'Virgin Australia',
    'VLM Airlines',
    'Volaris',
    'Volga-Dnepr Airlines',
    'VRG Linhas Aéreas S.A. - Grupo GOL',
    'White coloured by you',
    'Wideroe',
    'Xiamen Airlines',
    'Yemenia',
    'Silk Way Airlines',
    'Silk Way West Airlines Limited'
];

const from = [
    'Geneva (GVA)',
    'Zurich (ZRH)',
    'Innsbruck (INN)',
    'Vienna (VIE)',
    'Brussels (BRU)',
    'Split (SPU)',
    'Zagreb (ZAG)',
    'Prague (PRG)',
    'Copenhagen (CPH)',
    'Helsinki (HEL)',
    'Chambery (CMF)',
    'Lyon (LYS)',
    'Nantes (NTE)',
    'Nice (NCE)',
    'Paris (CDG)',
    'Paris-Orly (ORY)',
    'Berlin (BER)',
    'Cologne (CGN)',
    'Dresden (DRS)',
    'Duesseldorf (DUS)',
    'Frankfurt (FRA)',
    'Hamburg (HAM)',
    'Hannover (HAJ)',
    'Munich (MUC)',
    'Stuttgart (STR)',
    'Budapest (BUD)',
    'Dublin (DUB)',
    'Tel Aviv (TLV)',
    'Bologna (BLQ)',
    'Genova (GOA)',
    'Milan (MXP)',
    'Rome (FCO)',
    'Venice (VCE)',
    'Pristina (PRN)',
    'Vilnius (VNO)',
    'Luxembourg (LUX)',
    'Amsterdam (AMS)',
    'Eindhoven (EIN)',
    'Rotterdam (RTM)',
    'Ålesund (AES)',
    'Bergen (BGO)',
    'Bodø (BOO)',
    'Kristiansund (KSU)',
    'Molde (MOL)',
    'Svalbard (LYR)',
    'Tromsø (TOS)',
    'Krakow (KRK)',
    'Warsaw (WAW)',
    'Funchal (FNC)',
    'Lisbon (LIS)',
    'Ljubljana (LJU)',
    'Alicante (ALC)',
    'Barcelona (BCN)',
    'Bilbao (BIO)',
    'Fuerteventura (FUE)',
    'Gerona (GRO)',
    'Granada (GRX)',
    'Ibiza (IBZ)',
    'La Palma (SPC)',
    'Lanzarote (ACE)',
    'Madrid (MAD)',
    'Malaga (AGP)',
    'Palma Mallorca (PMI)',
    'Tenerife (TFN)',
    'Valencia (VLC)',
    'Ängelholm (AGH)',
    'Gothenburg (GOT)',
    'Stockholm (ARN)',
    'Visby (VBY)',
    'Istanbul (IST)',
    'Izmir (ADB)',
    'Aberdeen (ABZ)',
    'Belfast (BHD)',
    'Belfast (BFS)',
    'Birmingham (BHX)',
    'Bristol (BRS)',
    'Edinburgh (EDI)',
    'Glasgow (GLA)',
    'Liverpool (LPL)',
    'London (LCY)',
    'London (LHR)',
    'Manchester (MAN)',
    'Southampton (SOU)'
];

const to = [
    'Milan (MXP)',
    'Rome (FCO)',
    'Innsbruck (INN)',
    'Vienna (VIE)',
    'Brussels (BRU)',
    'Split (SPU)',
    'Zagreb (ZAG)',
    'Prague (PRG)',
    'Copenhagen (CPH)',
    'Helsinki (HEL)',
    'Chambery (CMF)',
    'Lyon (LYS)',
    'Nantes (NTE)',
    'Nice (NCE)',
    'Paris (CDG)',
    'Paris-Orly (ORY)',
    'Berlin (BER)',
    'Cologne (CGN)',
    'Dresden (DRS)',
    'Duesseldorf (DUS)',
    'Frankfurt (FRA)',
    'Hamburg (HAM)',
    'Hannover (HAJ)',
    'Munich (MUC)',
    'Stuttgart (STR)',
    'Budapest (BUD)',
    'Dublin (DUB)',
    'Tel Aviv (TLV)',
    'Bologna (BLQ)',
    'Genova (GOA)',
    'Venice (VCE)',
    'Pristina (PRN)',
    'Vilnius (VNO)',
    'Luxembourg (LUX)',
    'Amsterdam (AMS)',
    'Eindhoven (EIN)',
    'Rotterdam (RTM)',
    'Ålesund (AES)',
    'Bergen (BGO)',
    'Bodø (BOO)',
    'Kristiansund (KSU)',
    'Molde (MOL)',
    'Svalbard (LYR)',
    'Tromsø (TOS)',
    'Krakow (KRK)',
    'Warsaw (WAW)',
    'Funchal (FNC)',
    'Lisbon (LIS)',
    'Ljubljana (LJU)',
    'Alicante (ALC)',
    'Barcelona (BCN)',
    'Bilbao (BIO)',
    'Fuerteventura (FUE)',
    'Gerona (GRO)',
    'Granada (GRX)',
    'Ibiza (IBZ)',
    'La Palma (SPC)',
    'Lanzarote (ACE)',
    'Madrid (MAD)',
    'Malaga (AGP)',
    'Palma Mallorca (PMI)',
    'Tenerife (TFN)',
    'Valencia (VLC)',
    'Ängelholm (AGH)',
    'Gothenburg (GOT)',
    'Stockholm (ARN)',
    'Visby (VBY)',
    'Istanbul (IST)',
    'Izmir (ADB)',
    'Aberdeen (ABZ)',
    'Belfast (BHD)',
    'Belfast (BFS)',
    'Birmingham (BHX)',
    'Bristol (BRS)',
    'Edinburgh (EDI)',
    'Glasgow (GLA)',
    'Liverpool (LPL)',
    'London (LCY)',
    'London (LHR)',
    'Manchester (MAN)',
    'Southampton (SOU)'
];

const AirlineFlightScreen = () => {
    const whiteLabelingStyles = useSelector(selectWhiteLabelingStyles);
    setupCustomStyles(whiteLabelingStyles);
    return <div className="screen-container">
        <div className="screen">
            <div className="airline-insurance_wrapper content">
                <Header stepper={false} showLanguage={false} stepsTitle={messages.FLIGHT_DETAILS} />
                <div className="airline-insurance_body main">
                    <AirlineFlightForm
                        airlines={airlines.map(item => ({ label: item, value: item }))}
                        from={from.map(item => ({ label: item, value: item }))}
                        to={to.map(item => ({ label: item, value: item }))}
                    />
                </div>
            </div>
        </div>
    </div>;
};

export default AirlineFlightScreen;
