import { APP_FLOWS, STEPS } from '../constants/constants';
import {
    getProfile,
    handleInsuranceProfile,
    handleWhiteLabeling,
    setAppFlow,
    handleInvalidLink,
    setMode,
    getNonSpecificConfiguration,
    setProfileLoadingStarted
    // pullInit
} from '../store/common/common.actions';
import {
    fetchActiveWhiteLabeling,
    getIssuerProfile,
    setVerifyToken,
    verifyPasswordToken
} from '../store/issuerApp/issuerApp.actions';
import {
    setStep, getInsurances
} from '../store/productInsurance/productInsurance.actions';

import history from './history';

let store = null;

const loadInsuranceProfile = () => {
    if (store.getState().common.profile || store.getState().common.profileLoadingStarted) {
        return Promise.resolve();
    }

    store.dispatch(setProfileLoadingStarted(true));
    const { pathname, search } = window.location;

    if (pathname === '/insurances/') {
        const token = search.split('?token=')[1];
        localStorage.setItem('mode', 'pull');
        if (token) {
            localStorage.removeItem('access_token');
            localStorage.setItem('access_token', token);
        }
    }

    return store.dispatch(getProfile())
        .then(profile => store.dispatch(handleInsuranceProfile(profile)))
        .then((result) => {
            let query = '';
            if (pathname.includes('insurances')) {
                store.dispatch(setMode('pull'));
                history.push('/insurances');
                store.dispatch(getInsurances('pull'));
                return;
            }

            store.dispatch(setMode('push'));
            if (result.payment) {
                history.push('/success');
                return;
            }

            if (result.only_coverage) {
                query = '#coverage';
            }
            let step;

            if (result.tags && result.tags.indexOf('customer_insurance_check') > -1) {
                history.push('/customer-journey');
                return;
            }

            if (result.item_type_specific) {
                step = STEPS.PRODUCTS_LIST;
                if (result.insurance) {
                    step = STEPS.INSURANCE_PRODUCT_SELECTION;
                }
            } else {
                // step = STEPS.BENEFICIARY_AND_POSTAL_CODE_SCREEN;
                store.dispatch(getNonSpecificConfiguration(result.insurance));
            }

            if (result.tags) {
                if ((result.tags.indexOf('helvetia_spain_life') > -1 || result.tags.indexOf('Travel_Insurance_Flow') > -1
                || result.tags.indexOf('Multi_Travel_Insurance_Flow') > -1 || result.tags.indexOf('postoffer_ insuree_data') > -1)
                && result.item_type_specific) {
                    // if item_type_specific is false non specific config was already received
                    store.dispatch(getNonSpecificConfiguration(result.insurance));
                }
                if (result.tags.indexOf('airline_insurance') > -1) {
                    history.push('/airline-flow');
                    return;
                }
                if (result.tags.indexOf('airline_delay') > -1 || result.tags.indexOf('cembra_voucher') > -1) {
                    history.push('/success');
                    return;
                }
            }
            store.dispatch(setStep(step));
            history.push(`/store-purchase${query}`);
        });
};

const loadContractsProfile = () => {
    store.dispatch(setAppFlow(APP_FLOWS.CONTRACTS));
    store.dispatch(getProfile())
        .then(profile => store.dispatch(handleWhiteLabeling(profile)));
};

const loadInvalidLink = () => store.dispatch(handleInvalidLink());

const loadIssuerProfile = () => {
    if (store.getState().issuerApp.issuerProfile) {
        return Promise.resolve();
    }
    return store.dispatch(getIssuerProfile());
};

const loadWhiteLabeling = () => store.dispatch(fetchActiveWhiteLabeling());

const verifyResetPassword = () => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token');
    if (token) {
        verifyPasswordToken({ token })
            .then(() => {
                store.dispatch(setVerifyToken(token));
                history.push('/new-password');
            })
            .catch(() => history.push('/login'));
    } else {
        history.push('/login');
    }
};

// eslint-disable-next-line import/prefer-default-export
export const connectProtectors = (originalStore) => {
    store = originalStore;

    return {
        loadInvalidLink,
        loadInsuranceProfile,
        loadContractsProfile,
        verifyResetPassword,
        loadIssuerProfile,
        loadWhiteLabeling,
    };
};
