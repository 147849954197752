import React /* { useEffect, useState } */ from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faFileShield, faClipboardList, faListCheck, faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import menuItems from '../../../constants';
import { messages } from '../../../../constants/messages';
import Counter from './Counter';

const settings = {
    whatsapp: faWhatsapp,
    clients: faUsers,
    offer: faFileShield,
    track: faClipboardList,
    contracts: faFileSignature,
    management: faListCheck
};

const Items = ({ role, counter, resetCounter }) => {
    const history = useHistory();
    const { search } = useLocation();

    /*  const [notification, setNotification] = useState(0);

    useEffect(() => {
        debugger;
        setNotification(counter);
    }, [counter]); */

    const onMenuItemChange = (key) => {
        if (key === 'whatsapp') {
            resetCounter();
        }
        history.replace(`/broker-portal?tab=${key}`);
    };

    return <div className="menu-list">
        {menuItems(messages).filter(i => (role !== 'team_manager' ? i.key !== 'management' : i)).map(({ key, title }) => <div
            key={key}
            className={`menu-list_item ${search.includes(key) && 'active'} ${(key === 'whatsapp' || key === 'offer') && 'mobile'}`}
            onClick={() => onMenuItemChange(key)}>
            <span className="menu-list_item_icon-wrapper"><FontAwesomeIcon icon={settings[[key]]} className="menu-list_item_icon" /></span>
            <span>{title}{key === 'whatsapp' && counter ? <Counter n={counter} /> : null}</span>
        </div>)}
    </div>;
};

export default Items;
