import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './button.scss';

const Button = (props) => {
    const {
        as: Component,
        children,
        className,
        onClick,
        type,
        size,
        disabled
    } = props;

    return (
        <Component
            className={cn('button', className, type, size, { disabled })}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </Component>
    );
};

Button.propTypes = {
    as: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'default', 'transparent']),
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]).isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large'])
};

Button.defaultProps = {
    as: 'div',
    type: 'primary',
    className: '',
    onClick: null,
    disabled: false,
    size: 'medium'
};

export default Button;
