/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { messages } from '../../constants/messages';
import { useContract, useLanguage } from '../../helpers/customHooks';
import { renderPrice } from '../../helpers/renderingHelpers';
import { automaticallyDownloadFile, getFileName } from '../../helpers/wizardHelpers';
import { cancelContract, getReceipt, reactivateContract } from '../../store/contract/contract.actions';
import {
    selectContractsLoading,
    selectCurrencyPosition,
    isInsuranceLogoVisible,
} from '../../store/selectors';
import Button from '../Common/Button/Button';
import Header from '../Header';
import DropdownButton from '../Common/DropdownButton/DropdownButton';
import Footer from '../Common/Footer/Footer';
import Loader from '../Common/Loader/Loader';
import ContractDates from '../ContractsList/Contract/ContractDates';
import CancelConfirmation from './CancelConfirmation/CancelConfirmation';
import Reactivation from './Reactivation/Reactivation';
import Chips from '../Common/Chips/Chips';
import { CONTRACT_STATUSES } from '../../constants/constants';
import withTheme from '../../hocs/withTheme';

import './ContractDetails.scss';

const ContractDetails = () => {
    const { id } = useParams();
    const contract = useContract(id);
    useLanguage();
    const loading = useSelector(selectContractsLoading);
    const currencyPosition = useSelector(selectCurrencyPosition);
    const history = useHistory();
    const dispatch = useDispatch();
    const isLogoVisible = useSelector(isInsuranceLogoVisible);
    const [showConfirmation, toggleConfirmation] = useState(false);
    const [showReactivation, toggleReactivation] = useState(false);
    if (!contract) {
        return null;
    }
    const {
        uuid,
        product_information: {
            branding_name,
            price,
            currency_symbol,
            product_fact_sheet,
            product_sheet,
            underwriter_logo: logo,
            payment_scheme
        },
        is_receipt_uploaded,
        coverage_start_date,
        valid_until,
        nearest_possible_cancellation_date,
        status,
        item_type: {
            name
        }
    } = contract;

    const navToContractsList = () => {
        history.push('/purchases');
    };

    const onClaimClick = () => {
        history.push(`/purchases/${uuid}/claim`);
    };

    const onCancelClick = () => {
        toggleConfirmation(true);
    };

    const onReactivateClick = () => {
        toggleReactivation(true);
    };

    const onReactivateConfirm = () => {
        dispatch(reactivateContract(uuid))
            .then(() => {
                toggleReactivation(false);
                history.push(`/purchases/${uuid}/reactivation/success`);
            });
    };

    const onCancelContract = () => {
        dispatch(cancelContract(uuid))
            .then(() => {
                toggleConfirmation(false);
                history.push(`/purchases/${uuid}/cancellation/success`);
            });
    };

    const downloadReceipt = () => {
        dispatch(getReceipt({ uuid }))
            .then(({ blob, type, filename }) => {
                const byteArray = new Uint8Array(blob);
                const fileUrl = window.URL.createObjectURL(
                    new Blob([byteArray], { type }),
                );
                automaticallyDownloadFile(fileUrl, getFileName(filename));
            });
    };

    const schemeList = {
        QUARTERLY: messages.QUARTERLY,
        MONTHLY: messages.MONTHLY,
        WEEKLY: messages.WEEKLY,
        YEARLY: messages.YEARLY,
        UPFRONT: ''
    };

    return (
        <div className="contract-details-wrapper content">
            <Header className="hidden" stepper={false} />
            <div className="contract-details">
                <div className="contract-details-wrapper__header">

                    <div className="contract-details-wrapper__title">
                        <p className="brand-title">{name}</p>
                        <p className="model-title">{branding_name}</p>
                    </div>
                    <div>
                        <p className="price">{renderPrice(price, currency_symbol, currencyPosition)}</p>
                        {payment_scheme && <p className="frequency">{schemeList[payment_scheme]}</p> }
                        {isLogoVisible && logo && (
                            <img className="contract-details-wrapper__logo" src={logo} alt="contract logo" />
                        )}
                    </div>
                </div>
                <Chips text={messages[status]} type={CONTRACT_STATUSES[status]} />

                <ContractDates
                    coverageStartDate={coverage_start_date}
                    validUntil={valid_until}
                    startDateLabel={messages.PURCHASED_ON}
                    validUntilLabel={messages.EXPIRING_ON}
                />
                {is_receipt_uploaded && <a className="contract-link" onClick={downloadReceipt} rel="noreferrer">{messages.DOWNLOAD_PURCHASE_RECEIPT}</a>}
                <p className="insurance-contract">{messages.INSURCANCE_CONTRACT}</p>
                <p className="item-protected-by">{messages.ITEM_IS_PROTECTED_BY_INSURANCE}:</p>
                <p className="item-protected-by">{branding_name}</p>
                <ContractDates
                    coverageStartDate={coverage_start_date}
                    validUntil={nearest_possible_cancellation_date}
                    startDateLabel={messages.INSURANCE_COVERAGE_START}
                    validUntilLabel={messages.INSURANCE_COVERAGE_UNTIL}
                />
                {!!product_fact_sheet && <a className="contract-link" target="_blank" rel="noreferrer" href={product_fact_sheet}>{messages.DOWNLOAD_PRODUCT_SHEET}</a>}
                {!!contract && <a className="contract-link" target="_blank" rel="noreferrer" href={contract.certificate}>{messages.DOWNLOAD_INSURANCE_CERTIFICATE}</a>}
                {loading && <Loader />}
                {!!product_sheet && <a className="contract-link" target="_blank" rel="noreferrer" href={product_sheet}>{messages.DOWNLOAD_TERMS_AND_CONDITIONS}</a>}
                {status === CONTRACT_STATUSES.PENDING_CANCELLATION && (
                    <Button
                        type="primary"
                        className="contract-details__reactivate"
                        onClick={onReactivateClick}
                    >
                        {messages.REACTIVATE}
                    </Button>
                )}
            </div>
            <Footer
                onNavBack={navToContractsList}
                showNext={false}
            >
                <>
                    {status === CONTRACT_STATUSES.ACTIVE && (
                        <DropdownButton>
                            <Button
                                type="default"
                                onClick={onCancelClick}
                            >
                                {messages.CANCEL_CONTRACT}
                            </Button>
                        </DropdownButton>
                    )}
                    <Button className="file-claim-button" onClick={onClaimClick}>
                        {messages.FILE_A_CLAIM}
                    </Button>
                </>
            </Footer>
            { showConfirmation && <CancelConfirmation
                itemType={name}
                onReturn={() => toggleConfirmation(false)}
                onCancel={() => onCancelContract()}
                cancellationDate={nearest_possible_cancellation_date}
            />}
            {showReactivation && (
                <Reactivation
                    onBack={() => toggleReactivation(false)}
                    onReactivate={onReactivateConfirm}
                />
            )}
        </div>
    );
};

export default withTheme(ContractDetails);
