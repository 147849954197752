            import LocalizedStrings from 'react-localization';
import en from '../dist/localization/en';
import fr from '../dist/localization/fr';
import frCH from '../dist/localization/fr-ch';
import de from '../dist/localization/de';
import deCH from '../dist/localization/de-ch';
import it from '../dist/localization/it';
import itCH from '../dist/localization/it-ch';
import hr from '../dist/localization/hr';
import es from '../dist/localization/es';
import pl from '../dist/localization/pl';
import fi from '../dist/localization/fi';
import sv from '../dist/localization/sv';
import svFI from '../dist/localization/sv-fi';
import da from '../dist/localization/da';
import nl from '../dist/localization/nl';
import pt from '../dist/localization/pt';

export const messages = new LocalizedStrings({
    en,
    fr,
    ['fr-ch']: frCH,
    de,
    ['de-ch']: deCH,
    ['de-de']: de,
    it,
    ['it-ch']: itCH,
    hr,
    es,
    ['es-es']: es,
    fi,
    ['fi-fi']: fi,
    pl,
    sv,
    ['sv-sv']: sv,
    ['sv-fi']: svFI,
    da,
    nl,
    pt
})

export const onSetLanguage = (language) => {
    messages.setLanguage(language);
}
