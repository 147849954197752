import React from 'react';
import { useSelector } from 'react-redux';
import { selectStyles } from '../../../../../store/issuerApp/issuerApp.selector';

const GalleryIcon = () => {
    const { primaryColor } = useSelector(selectStyles);
    return (
        <div className="gallery-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="32" viewBox="0 0 40 32">
                <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                    <path stroke={primaryColor} strokeWidth="1.3" d="M34.1875,29.4375 L3.75,29.4375 C1.6875,29.4375 0,27.75 0,25.6875 L0,3.75 C0,1.6875 1.6875,0 3.75,0 L34.1875,0 C36.25,0 37.9375,1.6875 37.9375,3.75 L37.9375,25.75 C37.875,27.75 36.25,29.4375 34.1875,29.4375 Z" />
                    <polyline stroke={primaryColor} strokeWidth="1.3" points="3.25 29.438 23.688 12.125 37.875 24.625" />
                    <polyline stroke={primaryColor} strokeWidth="1.3" points="0 19.375 11.938 9.438 18.938 16.125" />
                    <circle cx="30" cy="7" r="3" fill={primaryColor} />
                </g>
            </svg>
        </div>
    );
};

export default GalleryIcon;
