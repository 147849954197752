import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LoginScreen from '../../containers/LoginScreen';
import { messages } from '../../../constants/messages';
import RequestSent from './RequestSent/RequestSent';
import { resetPassword } from '../../../store/issuerApp/issuerApp.actions';
import Input from '../Common/Input/Input';

import './ForgotPassword.scss';

const useStyles = makeStyles({
    loginInput: {
        width: '100%',
    },
    loginSubmitBtn: {
        color: '#F8F9FF',
        textTransform: 'capitalize',
        padding: '15px',
        borderRadius: 10,
        marginTop: 15,
    }
});

const ForgotPassword = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState({
        email: '',
    });
    const [validationErrors, setValidationErrors] = useState({
        email: '',
    });
    const [isRequestSent, setIsRequestSent] = useState(false);

    const setError = (field, message) => {
        setValidationErrors(prev => ({
            ...prev,
            [field]: message,
        }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setError(name, '');
        setData({
            ...data,
            [name]: value,
        });
    };

    const validate = () => {
        for (let i = 0; i < Object.entries(data).length; i += 1) {
            const [name, value] = Object.entries(data)[i];
            if (!value) {
                setError(name, messages.EMPTY_FIELD_ERR);
                return false;
            }
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationSucceed = validate();
        if (!validationSucceed) return;
        dispatch(resetPassword(data))
            .then(() => setIsRequestSent(true))
            .catch(() => setError('email', messages.NO_EMAIL_ERR));
    };

    const onNavBack = () => {
        setIsRequestSent(false);
        history.push('/login');
    };

    return (
        <LoginScreen>
            {isRequestSent
                ? <RequestSent onNavBack={onNavBack} />
                : (<form onSubmit={handleSubmit} className="login__forgot-password">
                    <div className="forgot-password__header">{messages.FORGOT_YOUR_PASSWORD}</div>
                    <div className="forgot-password__message">
                        {messages.ENTER_THE_EMAIL}
                    </div>
                    <Input
                        label={messages.EMAIL_ADDRESS}
                        type="email"
                        variant="outlined"
                        name="email"
                        value={data.email}
                        handleChange={handleChange}
                        className={classes.loginInput}
                        err={validationErrors.email}
                    />
                    <Button
                        className={classes.loginSubmitBtn}
                        variant="contained"
                        type="submit"
                        color="primary"
                    >{messages.RESET_PASSWORD}</Button>
                    <button
                        className="forgot-password__back-btn"
                        onClick={onNavBack}
                    >
                        {messages.BACK}
                    </button>
                </form>)
            }
        </LoginScreen>
    );
};
export default ForgotPassword;
