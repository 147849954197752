import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';

const Header = ({ titles, onSort, sort = {} }) => <div className="table-header table_row">
    {
        titles.map((t, i) =>
            <div className="table_column" key={i} onClick={t.sort ? () => onSort(t.sort) : null}>
                {t.title}
                {t.sort && t.sort === sort.key && <FontAwesomeIcon className={sort.order === '-' ? 'up' : 'down'} icon={faSortDown} />}
            </div>)
    }
</div>;

export default Header;
