import React, { Profiler, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { messages } from '../../constants/messages';
import { /* EVENTS, logEvent,  */pageOnRender, contactUs, previousPageNavigation } from '../../helpers/amplitude';
import Header from '../Header';
import ProductDetails from '../ProductDetails/ProductDetails';
import Footer from '../Common/Footer/Footer';
import { saveInsuranceProduct, setCurrentInsuranceId } from '../../store/productInsurance/productInsurance.actions';

import './InsuranceOverview.scss';
import { useLanguage } from '../../helpers/customHooks';
import { setActualFooterScreen } from '../../store/payment/payment.actions';
import { selectProfile } from '../../store/common/common.selector';
import { STEPS } from '../../constants/constants';
import withTheme from '../../hocs/withTheme';
// import HintModal from '../ProductInsurance/HintModal/HintModal';

const InsuranceOverview = ({
    hide, cover, nonCover, brandingName,
    productDescription, currency,
    contractLength, contractLengthUnit, contractMinimumLength,
    paymentScheme, insuranceId, productSheet, deductible,
    contractMaximumLength, isLogoVisible, logo, onNext,
    isHelp = false, /* offers, */ showDeductible,
    insuredCapital, currencySymbol
}) => {
    useLanguage();
    const dispatch = useDispatch();
    const history = useHistory();
    // const [hintModalState, setHintModalState] = useState({});
    const { lead_uuid, /* support_email, */ tags = [], support_phone_number } =
        useSelector(selectProfile);
    const [disabled, setDisabled] = useState(tags && tags.indexOf('helvetia_spain_life') > -1);
    const onShowHint = (/* { label, hint } */) => {
        contactUs({ uuid: lead_uuid });
        window.open(`https://wa.me/${support_phone_number}?text=`);
        /* setHintModalState({
            shown: true,
            label,
            hint
        }); */
    };

    // const onCloseHint = () => setHintModalState({ shown: false });
    const onNextStep = () => {
        if (onNext) {
            onNext();
            return;
        }
        dispatch(setCurrentInsuranceId(insuranceId));
        dispatch(saveInsuranceProduct())
            .then((attributes) => {
                const step = attributes.length ? STEPS.USER_DETAILS : STEPS.CARD_DETAILS;
                dispatch(setActualFooterScreen(step));
                history.push('/payment');
                // logEvent(EVENTS.ACTIVE_STEP, { page: 'Payment part' });
            });
    };

    const onNavBack = () => {
        previousPageNavigation({ uuid: lead_uuid, page: 'INSURANCE_PRODUCT_SELECTION' });
        hide();
    };

    return (
        <Profiler id="InsuranceDetails" onRender={pageOnRender}>

            <div className="insurance-overview-wrap content">
                <Header
                    stepsTitle={messages.INSURANCE_OVERVIEW} />
                <ProductDetails
                    showDeductible={showDeductible}
                    brandingName={brandingName}
                    productDescription={productDescription}
                    contractLengthUnit={contractLengthUnit}
                    contractLength={contractLength}
                    contractMinimumLength={contractMinimumLength}
                    contractMaximumLength={contractMaximumLength}
                    paymentScheme={paymentScheme}
                    deductible={deductible}
                    currency={currency}
                    cover={cover}
                    nonCover={nonCover}
                    productSheet={productSheet}
                    isLogoVisible={isLogoVisible}
                    logo={logo}
                    insuredCapital={insuredCapital}
                    currencySymbol={currencySymbol}
                    isPreContract={tags && tags.indexOf('insurance_detailed_mandatory') > -1}
                    changeBtnStatus={tags && tags.indexOf('insurance_detailed_mandatory') > -1 ? status => setDisabled(!status) : null}
                />
                {/* {isHelp && <HintModal {...hintModalState}
                onClose={onCloseHint} type="help" offers={offers} />} */}
                <Footer
                    openModal={isHelp && onShowHint}
                    isHelp={support_phone_number}
                    onNavBack={onNavBack}
                    onNextStep={onNextStep}
                    disabled={disabled}
                />
            </div>
        </Profiler>
    );
};

export default withTheme(InsuranceOverview);
