const fi = {
CHOOSE_ITEM_TO_CHECK: "Please choose the item for which you want to check coverage",
INSURANCE_COMPANY_LABEL: 'Insurance Company',
INSURANCE_PRODUCT_LABEL: 'Insurance Product',
    //steps
    STEP1: 'Tiedot',
    STEP2: 'Tarjous',
    STEP3: 'Päättäminen',
    CHOOSE_ITEM_TO_INSURE: 'Ole hyvä ja valitse tuote, jonka haluat vakuuttaa.',
    // Insurance overview
    INSURANCE_OVERVIEW: 'Vakuutustiedot',
    COVERAGE: 'Katettu:',
    NON_COVERED: 'Ei kuulu:',
    DOWNLOAD_INSURANCE_PRODUCT: 'Lataa yksityiskohtainen vakuutuskuvaus',
    DOWNLOAD_TERMS_AND_CONDITIONS: 'Lataa käyttöehdot',
    PRODUCT_INSURANCE_STEP_ONE_TITLE: 'Vain muutama askel vakuutuksen kattamiseksi',
    PRODUCT_INSURANCE_STEP_TWO_TITLE: 'Löytyi tuotteita kuitista',
    PRODUCT_INSURANCE_STEP_THREE_TITLE: 'Valitse vakuutuksesi.',
    PRODUCT_INSURANCE_STEP_TWO_HINT: 'Napauta mitä olet juuri ostanut ja on katettava.',
    PRODUCT_INSURANCE_STEP_ONE_ONLINE: 'Kerro meille, mitä haluat suojata.',
    ENTER_MORE_DETAILS: 'Anna lisätietoja vakuutettavasta tuotteesta',
    TAKE_RECEIPT_PHOTO: 'Ota valokuva ostokuitista.',
    CHECK_RECEIPT: 'Tarkista, onko valokuvassa kuitti selvä.',
    CHECK_ITEM_PHOTO: 'Tarkista, näkyykö kuvassa selkeä kohde.',
    TAKE_ITEM_PHOTO: 'Ota kuva suojattavasta tuotteestasi.',
    BUTTON: 'Painike',
    MORE_DETAILS: 'Näytä yksityiskohdat',
    BACK: 'Takaisin',
    SKIP_RECEIPT: 'Ohita kuitti',
    NEXT: 'Seuraava',
    GENERAL_CONDITIONS: 'Yleiset ehdot',
    LENGTH: 'Kesto:',
    MINIMUM_LENGTH: 'Sopimuksen vähimmäiskesto:',
    MAXIMUM_LENGTH: 'Sopimuksen enimmäiskesto:',
    CONTRACT_TERMINATION: 'Sopimuksen päättyminen:',
    COVERAGE_LIST: 'Kattavuus',
    SEARCH: 'Hae',
    OTHER: 'Muu',
    CLOSE: 'Sulje',
    PHOTO: 'Kuva',
    OR: 'Tai',
    OPTIONS: 'Vaihtoehdot',
    SHOW_MORE: 'Näytä lisää',
    DO_NOT_INSURE: 'Älä vakuuta tätä tuotetta',
    NOT_INSURABLE: 'Tämä tuote ei ole vakuutuskelpoinen',
    SELECT_ITEM_TYPE: 'Valitse tuotteen tyyppi',
    ADD_UNRECOGNIZED_ITEM: 'Lisää tuote',
    NAME: 'Nimi',
    PLEASE_WAIT: 'Ole hyvä ja odota',
    RECOGNIZE_PROCESSING: 'Sovellus käsittelee valokuvaa (valokuvia) poimiakseen olennaiset tiedot.',
    PRICE: 'Hinta',
    CANCEL: 'Peruuta',
    OK: 'Ok',
    SAVE_AND_NEXT: 'Seuraava',
    MY_NEW_ITEM: 'Uusi rakas esineeni',

    // Inform Screen
    DEAR_CUSTOMER: 'Hyvä asiakas,',
    TO_OFFER_YOU: 'jotta voimme tarjota sinulle parhaat vakuutusvaihtoehdot, tarvitsemme lisätietoja juuri ostamastasi tuotteesta. Voit joko:',
    /* OPTION_TAKE_A_PHOTO: 'Ota valokuva ostokuitista',
    OPTION_UPLOAD_FROM_GGALLERY: 'Lähetä valokuva ostokuitista', */
    OPTION_TAKE_A_PHOTO: 'Ottaa valokuva',
    OPTION_UPLOAD_FROM_GGALLERY: 'Lataa kuva',
    OPTION_ENTER_MANUALLY: 'Syötä tiedot manuaalisesti',
    OPTION_TAKE_A_PHOTO_DESCRIPTION: 'Kameran käyttöoikeutta voidaan pyytää.',
    OPTION_UPLOAD_FROM_GGALLERY_DESCRIPTION: 'Pääsy valokuviin, medioihin ja tiedostoihin voidaan pyytää.',

    // Empty State
    SORRY: 'Anteeksi',
    SORRY_MESSAGE: 'Pahoittelemme, mutta tarjouslinkki ei ole enää voimassa. Teemme mielellämme tarjouksen seuraavalla ostoksellasi.',
    DONE: 'Tehty',

    // Payment Methods
    PAYMENT_METHODS: 'maksutavat',
    HOW_DO_YOU_WANT_TO_PAY: 'Miten haluat maksaa?',
    YOU_WILL_BE_SPENDING: 'Tulet käyttämään rahaa',
    FOR_BUYING_INSURANCE_POLICIES: 'vakuutusten ostamiseen',
    CONFIRM_YOUR_CARD_DETAILS: 'Anna maksukorttisi tiedot.',
    HDRC_MONEY_BACK: 'Anna maksukorttisi tiedot.',
    CARDHOLDER_NAME: 'Kortinhaltijan nimi',
    CARD_NUMBER: 'Kortin numero',
    EXPIRY_DATE: 'Päättymispäivä',
    CVV: 'Turvakoodi (CVV)',
    CONFIRM_AND_PAY: 'Vahvista ja osta',
    I_HAVE_SUCCESSFULLY_READ: 'Olen lukenut ehdot ja hyväksyn ne.',
    ENTRY_A_FEW_MORE_DETAILS: 'Anna muutama lisätietoja sinusta',
    FIRST_NAME: 'Etunimi',
    FAMILY_NAME: 'Sukunimi',
    BIRTHDAY: 'Syntymäpäivä',
    EMAIL_ADDRESS: 'Sähköpostiosoite',
    PHONE_NUMBERS: 'Puhelinnumero',
    ADDRESS: 'Osoite',
    ENTRY_HERE: 'Käy sisään tästä',
    MONTHLY: 'kuukaudessa',
    WEEKLY: 'viikossa',
    YEARLY: 'vuodessa',
    OVERVIEW_YEARLY: 'Vuosittain',
    OVERVIEW_MONTHLY: 'Kuukausi',
    OVERVIEW_WEEKLY: 'Viikoittain',
    WEEKS: 'viikkoa',
    MONTHS: 'kuukaudet',
    YEARS: 'vuotta',
    WHAT_IS_NEXT: 'Mitä on seuraavaksi:',
    INSURANCE_CERTIFICATE: (email) =>  `Vakuutustodistus luodaan ja lähetetään osoitteeseen ${email}.`,

    DEDUCTIBLE: 'Omavastuu:',


    MANDATORY_FIELD: 'Tämä kenttä on pakollinen',
    INVALID_FORMAT: 'arvo ei ole sopiva',
    PRICE_SHOULD_BE_LOWER: 'Hinnan tulisi olla alempi kuin kauppahinta',
    DATE_SHOULD_BE_PAST: 'Päivämäärän tulisi olla menneisyydessä',

    // Congratulations!
    CONGRATULATIONS: 'Onnittelut !!',
    GLAD_TO_INFORM_YOU: 'Olemme iloisia voidessamme ilmoittaa sinulle, että olet ostanut kattavuuden. Tuotteesi on nyt turvallinen.',

    YOU_WILL_RECEIVE: 'Saat muutamassa minuutissa vahvistussähköpostin, joka sisältää kattavuustodistuksen.',

    YOU_ARE_PROTECTED: 'Olet suojattu',
    ALREADY_PURCHASED: 'Meillä on ilo ilmoittaa sinulle, että olet jo ostanut vakuutuksen ja että rakas esineesi on jo suojattu.',

    DOWNLOAD: 'ladata',
    YOU_CAN_DOWNLOAD_HERE: 'Voit ladata vakuutustodistuksen täältä:',
    BACK_TO_OVERVIEW: 'takaisin yleiskatsaukseen',

    // contracts list
    YOUR_POLICY_PURCHASES: 'Vakuutuksesi',
    PURCHASED_ON: 'Ostettu',
    EXPIRING_ON: 'Vanhenee',
    WHATS_COVERED: 'Katettu on',
    FILE_A_CLAIM: 'Tee vaatimus',
    REACTIVATE: 'Aktivoi uudelleen',

    // contract details
    DOWNLOAD_PURCHASE_RECEIPT: 'Lataa ostokuitti',
    INSURCANCE_CONTRACT: 'Vakuutussopimus',
    ITEM_IS_PROTECTED_BY_INSURANCE: 'Tuote on suojattu vakuutuksella',
    INSURANCE_COVERAGE_START: 'Vakuutusturva alkaa',
    INSURANCE_COVERAGE_UNTIL: 'Vakuutusturva vuoteen',
    DOWNLOAD_PRODUCT_SHEET: 'Lataa tuoteseloste',
    DOWNLOAD_INSURANCE_CERTIFICATE: 'Lataa todistus',
    CANCEL_CONTRACT: 'Peruuta sopimus',

    // claim form
    PROVIDE_INFO_FOR_CLAIM: 'Anna meille lisätietoja, ennen kuin käsittelemme vaatimuksesi',
    ATTACH_PHOTO_VIDEO: 'Liitä mukaan tarvittaessa valokuvia tai videoita, joista vahinko käy ilmi.',
    DESCRIBE_WHAT_HAPPEND: 'Kuvaile, mitä tapahtui',
    ENTER_HERE: 'Käy sisään tästä',
    SUBMIT_CLAIM: 'Lähetä vaatimus',
    PLEASE_ATTACH_FILES: 'Liitä tiedostot',
    THANKS_FOR_REQUEST: 'Kiitos hakemuksen jättämisestä. Käsittelemme sen mielellämme ja annamme sinulle vastauksen lähipäivinä.',
    YOUR_INSURANCE_COVERS: 'Vakuutuksesi kattaa vain sertifioidun kumppanimme suorittaman korjauksen. Löydät täältä korjaamon lähistöltäsi.',
    SHOW_PARTNER_NETWORK: 'Näytä kumppaniverkosto',

    // confirmation
    YOUR_CONTRACT_HAS_BEEN_CANCELLED: 'Sopimuksesi on peruutettu. Saat pian vahvistussähköpostin',
    GOT_IT: 'Sain sen',
    THE_INSURANCE_WILL_BE_CANCELLED: 'Vakuutus peruutetaan',
    ARE_YOU_SURE_TO_CANCEL: 'Oletko varma, että haluat peruuttaa suojan sinun',
    RETURN: 'Palata',
    CONFIRM_REACTIVATION: 'Vahvista, että haluat aktivoida vakuutuksen uudelleen.',
    YOUR_CONTRACT_HAS_BEEN_REACTIVATED: 'Vakuutuksesi on aktivoitu uudelleen. Saat pian vahvistuspostin.',

    // contract statuses
    ACTIVE: 'aktiivinen',
    PENDING_CANCELLATION: 'peruutettu',
    VOID: 'terminated',

    BENEFICIARY_TITLE: 'Ketkä kuuluvat vakuutuksiisi?',
    BENEFICIARY_QUESTION: 'Whom would you like to cover?',
    BENEFICIARY_OPTION1: 'itse',
    BENEFICIARY_OPTION2: 'minä ja perheeni/kotitaloni',
}

export default fi;
