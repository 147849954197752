import createReducer from '../createReducer';
import {
    SET_ACTUAL_FOOTER_SCREEN, SET_CART_NUMBER,
    SET_CART_EXPIRY_DATE, SET_CART_CVV, SET_ERROR_POPUP, SET_VALIDATION_ERROR,
    SET_PAYMENT_VALIDATION, SET_CARD_VALID, SET_CERTIFICATE_URL, SET_CUSTOMER_DATA,
    SET_PAYMENT_INSURAMA_URL, SET_PAYMENT_REDSYS_DATA, SET_PAYMENT_INITIATION_ERROR
} from './payment.actionTypes';
import { STEPS } from '../../constants/constants';

const payment = {
    actualFooterScreen: STEPS.USER_DETAILS,
    card_number: '',
    card_expiry_date: '',
    card_cvv: '',
    errorPopup: null,
    validationError: {},
    paymentValid: false,
    cardValid: {
        cardNumber: false,
        cardExpiry: false,
        cardCvc: false
    },
    certificateUrl: null,
    customerData: {},
    paymentInsuramaUrl: '',
    paymentRedsysData: {},
    paymentInitiationError: false
};

function setPaymentInitiationError(state, { error }) {
    return {
        ...state,
        paymentInitiationError: error
    };
}

function setRedsysData(newState, { data }) {
    return {
        ...newState,
        paymentRedsysData: data
    };
}

function setInsuramaUrl(newState, { url }) {
    return {
        ...newState,
        paymentInsuramaUrl: url
    };
}

function setActualFooterScreen(newState, { data }) {
    return {
        ...newState,
        actualFooterScreen: data
    };
}
function setСardNumber(newState, { cardNumber }) {
    return {
        ...newState,
        card_number: cardNumber
    };
}
function setСardExpiryDate(newState, { cardExpiryDate }) {
    return {
        ...newState,
        card_expiry_date: cardExpiryDate
    };
}
function setСardCvv(newState, { cardCvv }) {
    return {
        ...newState,
        card_cvv: cardCvv
    };
}
function setErrorPopup(newState, { message }) {
    return {
        ...newState,
        errorPopup: message
    };
}
function setValidationError(newState, { error }) {
    return {
        ...newState,
        validationError: {
            ...newState.validationError,
            ...error
        }
    };
}
function setPaymentValid(newState, { status }) {
    return {
        ...newState,
        paymentValid: status
    };
}

function setCardValid(newState, { status }) {
    return {
        ...newState,
        cardValid: status
    };
}

function setCertificateUrl(newState, { url }) {
    return {
        ...newState,
        certificateUrl: url
    };
}

function setCustomerData(newState, { key, value }) {
    const { customerData } = newState;
    return {
        ...newState,
        customerData: {
            ...customerData,
            [key]: value
        }
    };
}

const paymentReducer = createReducer(payment, {
    [SET_ACTUAL_FOOTER_SCREEN]: setActualFooterScreen,
    [SET_CART_NUMBER]: setСardNumber,
    [SET_CART_EXPIRY_DATE]: setСardExpiryDate,
    [SET_CART_CVV]: setСardCvv,
    [SET_ERROR_POPUP]: setErrorPopup,
    [SET_VALIDATION_ERROR]: setValidationError,
    [SET_PAYMENT_VALIDATION]: setPaymentValid,
    [SET_CARD_VALID]: setCardValid,
    [SET_CERTIFICATE_URL]: setCertificateUrl,
    [SET_CUSTOMER_DATA]: setCustomerData,
    [SET_PAYMENT_INSURAMA_URL]: setInsuramaUrl,
    [SET_PAYMENT_REDSYS_DATA]: setRedsysData,
    [SET_PAYMENT_INITIATION_ERROR]: setPaymentInitiationError
});

export default paymentReducer;
