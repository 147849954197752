const en = {
SELECT_LANGUAGE: 'Select your language',
list_employment_sector: ['Agriculture', 'Building/Real Estate', 'Commerce/Retail', 'Engineering/Consulting', 'Finance & Insurance', 'Health', 'Hospitality/Gastronomy', 'Mining'],
list_employment_status: ['Employed', 'Employed – Management position', 'Pensioner', 'Self-Employed', 'Job seeker', 'Student'],
CUSTOMER_BATCH_UPLOAD: 'Customer batch upload',
DATE: 'Date',
TITLE: 'Title',
SUMMARY: 'Summary',
INSURED_CAPITAL: 'Insured capital: ',
//customer journey
///////
HEALTH_RELATED: 'Health Related Insurance',
MANDATORY_HEALTH_INSURANCE: 'Mandatory Health Insurance',
COMPLEMENTARY_HEALTH_INSURANCE: 'Complementary health insurance',
COMPLEMENTARY_HEALTH_INSURANCE_DESCRIPTION: 'In most cases the costs to subscribe to a complementary health insurance exceeds its benefits',
LIFE_RELATED: 'Life & payment related insurance',
PAYMENT_PROTECTION_INSURANCE: 'Payment Protection Insurance',
PAYMENT_PROTECTION_INSURANCE_DESCRIPTION: 'A risk life insurance would provide a superior coverage at lower costs',
LEGAL_RELATED: 'Liability & legal related related insurance',
PRIVATE_LIABILITY_INSURANCE: 'Private liability insurance',
PRIVATE_LIABILITY_INSURANCE_DESCRIPTION: 'Private liability insurance is one of the most insurance products. It is not mandatory, however  necessary if you want to protect your wealth',
PROPERTY_RELATED: 'Property related insurance',
ASSISTANCE_INSURANCE: 'Assistance insurance',
PASSENGER_INSURANCE: 'Passenger insurance',
PASSENGER_INSURANCE_DESCRIPTION: 'Passenger insurance makes only sense for passengers not residing in Switzerland',
SMARTPHONE_INSURANCE: 'Smartphone insurance',
WARRANTY_EXTENSION_INSURANCE: 'Warranty extension insurance',
MANDATORY_ACCIDENT_INSURANCE: 'Mandatory Accident Insurance',
MANDATORY_ACCIDENT_INSURANCE_DESCRIPTION: 'Treatements of injuries resulting from an accident are not covered by the health insurance, but by the accident insurance',
PRIVATE_ACCIDENT_INSURANCE: 'Half private or Private accident insurance',
PRIVATE_ACCIDENT_INSURANCE_DESCRIPTION: 'Patients with a private and  half private insurances will  be in general treated by expierenced doctors also for more benine medical needs. Note: In general, if a patient has a critical illness or injury, they will be always treated by experienced doctors.',
DENTAL_INSURANCE: 'Dental Insurance',
KIDS_DENTAL_INSURANCE: 'Dental Insurance for kid',
KIDS_DENTAL_INSURANCE_DESCRIPTION: 'Dental corrections for kids can easily cost over CHF 15\'000',
DAILY_BENEFITS_INSURANCE: 'Daily benefits insurance',
DAILY_BENEFITS_INSURANCE_DESCRIPTION: 'Daily benefits insurance pays the insurance holder a pre-defined amount of money. This is especially beneficial for self-employed, who rely in their income',
PRIVATE_HOSPITAL_INSURANCE: 'Half private or Private hospital insurance',
PRIVATE_HOSPITAL_INSURANCE_DESCRIPTION: 'Patients with a private and  half private insurances will  be in general treated by expierenced doctors also for more benine medical needs. Note: In general, if a patient has a critical illness or injury, they will be always treated by experienced doctors.',
WORK_INSURANCE: 'Incapacity to Work Insurance',
WORK_INSURANCE_DESCRIPTION: 'Incapacity to work due to sickness or psychological problems is not well covered by the two first pillars. Typically an incapacity to work due to sickness leads to an earning  short fall of 40% earnings.',
DEATH_INSURANCE: 'Risk Death Insurance',
DEATH_INSURANCE_DESCRIPTION: 'The risk insurance pays a lumbsum and/or a rent in case of death. This allow to pay for outstanding debts or/and ensure that the partner and kids have financial security.',
A_POLICY: '3a insurance policy',
A_POLICY_DESCRIPTION: 'Generally banking  products are the best choice to accumulate wealth. However for specific conditions, e.g. for Enterpreneuer s and Self-employed life insurance provide as a saving vehicle important  advantages',
WHOLE_LIFE_INSURANCE: 'Whole Life/saving insurance',
WHOLE_LIFE_INSURANCE_DESCRIPTION: 'Generally banking  products are the best choice to accumulate wealth. However for specific conditions, e.g. for Enterpreneuer s and Self-employed life insurance provide as a saving vehicle important  advantages',
WHOLE_LIFE_INSURANCE_DESCRIPTION_SHORT: 'Generally banking  products are the best choice to accumulate wealth',
BUSINESS_INSURANCE: 'Business liability insurance',
BUSINESS_INSURANCE_DESCRIPTION: 'Depending on your job (e.g. Doctor, architect, etc.), you business liability insurance is needed',
LEGAL_INSURANCE: 'Legal insurance',
LEGAL_INSURANCE_DESCRIPTION: 'Legal insurance covers the legal costs in case you want  to defend your self in relation to your employment, your rented aparatement or in relation of traffic',
CAR_INSURANCE: 'Car liability insurance',
MOTO_INSURANCE: 'Motorcycle liability insurance',
BOAT_INSURANCE: 'Boat liability insurance',
BUILDING_INSURANCE: 'Building insurance',
HALF_CAR_INSURANCE: 'Full or half comprehensive car insurance',
HALF_CAR_INSURANCE_DESCRIPTION: 'Full comprehensive insurance is recommended either when you need a car for your daily life, or your current car is less than 5 years old',
HOUSEHOLD_INSURANCE: 'Household insurance',
PET_INSURANCE: 'Pet insurance',
PET_INSURANCE_DESCRIPTION: 'Pet treatments are getting more sophisticated and costly. If you don\'t want to choose between spending CHF 15\'000 or letting you pet euthanized, than a pet insurance could be the right thing for you',
TRAVEL_INSURANCE: 'Travel insurance',
BICYCLE_INSURANCE: 'Bicycle insurance',
ITEM_RELATED_INSURANCE: 'Item related insurance (e.g jewellery, music instrument)',
ITEM_RELATED_INSURANCE_DESCRIPTION: 'Only for the items which you really need and where the value of the item is above 2\'500 CHF',
////////

STEP_TITLES: ['Let us get acquainted', 'Let us discuss your context', 'Discover recommendations'],
WERE_BORN: 'You are born at',
YOU_ARE: 'You are',
FAMILY_STATUSES: [{
    key: 'single', name: 'Single'
}, {
    key: 'married', name: 'Married'
}, {
    key: 'divorced', name: 'Divorced/widowed'
}],
JOB_STATUSES: [{
    key: 'employed', name: 'Employed'
}, {
    key: 'freelancer', name: 'Self-Employed/Freelancer'
}, {
    key: 'entrepreneur', name: 'Entrepreneur'
}, {
    key: 'job_seeker', name: 'Job seeker'
}, {
    key: 'student', name: 'Student'
}, {
    key: 'retired', name: 'Retired'
}],
YEARLY_INCOME_IS: 'Your yearly income is',
INCOME_STATUSES: [{
    key: 'below', name: 'Below CHF 25’725'
}, {
    key: 'between', name: 'Between CHF 25’725 and CHF 88’200'
}, {
    key: 'above', name: 'More than CHF 88’200'
}],
YOU_TRAVEL: 'You travel',
TRAVEL_STATUSES: [{
     key: 'rarely', name: 'Rarely'
}, {
     key: 'once_per_year', name: 'Once a year'
}, {
     key: 'more', name: 'Twice a year or more'
}],
YOU_LIVE_IN: 'You live in',
APARTMENT_OPTIONS: [{
     key: 'rented_room', name: 'Rented room (shared home)'
}, {
     key: 'rented_home', name: 'Rented home'
}, {
     key: 'owned_apartment', name: 'Owned apartment'
}, {
    key: 'owned_home', name: 'Owned home'
}],
HOUSEHOLD: 'Household',
HOUSEHOLD_STATUSES: [{
     key: 'spartanic', name: 'Spartanic'
}, {
     key: 'normal', name: 'Normal'
}, {
     key: 'luxury', name: 'Luxury'
}],
CARE_FOR: 'You care for',
CARE_LIST: [{
    key: 'partner', name: 'Partner'
}, {
    key: 'kids', name: 'Kid(s)'
}, {
    key: 'dogs', name: 'Dog(s)'
}, {
    key: 'cats', name: 'Cat(s)'
}, {
    key: 'horses', name: 'Horse(s)'
}],
KIDS_ABOVE_6: 'Do you have kids younger than 6 years',
KIDS_ABOVE_OPTIONS: [{
    key: 'yes', name: 'Yes'
}, {
    key: 'no', name: 'No'
}],
IS_LOAN: 'Do you have outstanding mortgage or loan?',
LOAN_OPTIONS: [{
    key: 'yes', name: 'Yes'
}, {
    key: 'no', name: 'No'
}],
YOU_OWN: 'You own',
PROPERTY_LIST: [{
    key: 'car', name: 'Car'
}, {
    key: 'motorbike', name: 'Motorbike'
}, {
    key: 'camper', name: 'Camper'
}, {
    key: 'boat', name: 'Boat'
}, {
    key: 'bicycle', name: 'Bicycle/E-bikes'
}, {
    key: 'scooter', name: 'E-Scooter'
}],
LOSSES: 'At any moment, you are able and willing to bear losses of up to',
LOSSES_LIST: [{
    key: 500, name: 'CHF 500'
}, {
    key: 1000, name: 'CHF 1’000'
}, {
    key: 2500, name: 'CHF 2’500'
}, {
    key: 5000, name: 'CHF 5’000'
}, {
    key: 100000, name: 'CHF 10’000 and more'
}],
HEALTH: 'When it comes to your health',
HEALTH_EXPERIENCE: [{
   key: 'standard_coverage', name: 'You are happy with the standard coverage'
}, {
   key: 'experienced_only', name: 'You want to be treated by experienced doctors only'
}, {
   key: 'private_clinics', name: 'You want access to private clinics'
}, {
   key: 'alternative_treatment', name: 'You want access to complementary/alternative medicine treatment'
}],
INFORMATION_POINTS: ['What you will see next are not a recommendation. We will be only showing, for the sake of a first information, the typical insurance recomanded to persons who responded similarly to the questionaire',
'For an insurance recommendation we need to have a session'],
NECESSARY: 'Necessary',
RECOMMENDED: 'Recommended',
WORTH_CONSIDERING: 'Worth considering',
MAKES_RARELY_SENSE: 'Makes rarely sense',
STEPS: ['Personal info', 'Context info', 'Recommendations'],
// Broker portal translations
WARNING: 'The CSV document was uploaded successfully. There were warnings for one or multiple entries.',
PRODUCT: 'Product',
INSURANCE: 'Insurance',
CONTRACT_DATE: 'Contract date',
PREMIUM: 'Premium',
BEFORE_USING: 'Before using the Alyyn portal your account needs to be connected to WhatsApp first.',
PLEASE_INFORM: 'Please inform your team leader that she/he needs to connect to a WhatsApp account first.',
TO_CREATE_AND_CONNECT: 'To create and connect a WhatsApp Account to Alyyn please mae sure that:',
POINTS: ['Your business has a Facebook page and you are an admin of that page', 'You have the SIM card provided you by Alyyn and you can receive SMS with it'],
CREATE_WHATSAPP_ACCOUNT: 'Create a WhatsApp Account',
WHATSAPP_ACCOUNT_CREATED: 'The WhatsApp Account was successfully created and connected with your portal.',
MOMENT_TO_CONFIGURE: 'We need a moment to configure everything for your account.',
WILL_BE_NOTIFIED: 'You will be notified as soon as this is done',
// Broker menu items' titles
CLIENTS: 'Clients',
WHATSAPP: 'WhatsApp',
NEW_DIGITAL_OFFER: 'New digital offer',
OFFER_TRACKING: 'Offer tracking',
USER_MANAGEMENT: 'User management',
CONTRACTS: 'Contracts',
// End for Broker menu items' titles
// Profile
MANAGE_PROFILE: 'Manage profile',
LOG_OUT: 'Log out',
// End for Profile
STEP_1: 'Step 1:',
STEP_2: 'Step 2:',
STEP_3: 'Step 3:',
SELECT_ADVISOR: 'Select...',
SELECT_INSURANCE: 'Select insurance product you want to offer.',
SELECT_CONTACT: 'Select customer or enter customer data.',
SEARCH_BY_USER: 'Search by a team member',
SEARCH_CONTACT: 'Search for a customer',
OFFER_CONGRATS: 'The offer was sent to the customer. You can always track the current status on the offer tracking screen.',
NEED_HELP: 'Need help?',
WRITE_EMAIL: 'Write us an email',
SELECT_FILE: 'Click and select or drag and drop a file here',
UPLOAD: 'Upload',
DELIMITER: 'Delimiter',
SEMICOLON: 'Semicolon',
COMMA: 'Comma',
IMPORT_A_LIST: 'Import a list of customers',
EXISTING_CONTACTS_SHALL_BE: 'Existing contacts shall be',
OVERWRITTEN: 'Overwritten with new data',
SKIPPED: 'Skipped',
DOWNLOAD_THE_FOLLOWING: 'Download the following',
CSV_TEMPLATE: 'CSV template file',
FILL_DATA: 'Fill the file with your customer data. A detailed description about valid data format can be found in this',
PDF_DESCRIPTION: 'detailed description document',
SELECT_AND_UPLOAD: 'Select and upload the document',
CSV_UPLOADED: 'CSV file was uploaded successfully',
NO_RESULT: 'No results',
CUSTOMER_DETAILS: 'Customer details',
USER_DETAILS: 'User details',
// Tracking page
PRODUCT_OFFERED: 'Product offered',
TRACKING: 'Tracking',
// End for Tracking page
// Contacts page
IMPORT_CSV: 'Import a list of customers',
ADD_CUSTOMER: 'Add a customer',
ADD_USER: 'Add a user',
INSURANCE_CONTRACTS: 'Insurance contracts',
IS_ISSUER_STAFF: 'Issuer status',
IS_ACTIVE: 'Active',
EXTERNAL_ID: 'External ID',
ROLE: 'Role',
ADVISOR: 'Advisor',
TEAM_MANAGER: 'Team leader',
// End for Contacts page
// Statuses
LINK_CLICKED: 'Link clicked',
NOT_SENT: 'Not sent',
PENDING: 'Pending',
FAILED: 'Failed',
CREATED: 'Created',
DELIVERED: 'Delivered',
READ: 'Read',
SENT: 'Sent Message',
SUBMITTED: 'Submitted',
OPENED: 'Opened Link',
LAST_LOGIN: 'Last customer activity',
PRE_OFFER: 'Filled pre-offer',
VIEWED: 'Viewed Products',
POST_OFFER: 'Filled post-offer',
SELECTED: 'Selected product',
PROFILE: 'Profile filled',
PURCHASED: 'Purchased',
// End for Statuses
SAVE: 'Save',
// End for Broker portal translations
CONSENT: 'Direct debit authorization to the insurance company for the payment of subsequent premiums',
CONSENT_OPTIONS: [
'Deseo recibir comunicaciones comerciales, publicitarias, promocionales y para la realización de encuestas, incluso por correo electrónico u otros medios de comunicación equivalentes.',
'Acepto que Helvetia Seguros complete mi perfil mediante la utilización de fuentes externas, con la finalidad de realizarme ofrecimientos comerciales de sus productos.',
'Acepto que Helvetia Seguros contacte conmigo una vez haya expirado la validez del proyecto o resuelto el contrato de seguro para ofrecerme nuevas condiciones u otros productos.'
],
I_HAVE_SUCCESSFULLY_READ_INSURAMA: 'Acepto los términos y condicones y solo los tratamientos de datos imprescindibles para el mantenimiento de la relación contractual y recibir el “Documento de información sobre el producto de seguro” via web.',
DETAILS_DOWNLOAD:'Ver detalles.',
TRY_AGAIN: 'Algo salió mal. Por favor, vuelva a intentarlo',
LAST_STEP: 'Últimos pasos',
NEXT_STEPS: {title: 'Los siguientes pasos son:', steps: ['El primer pago lo tienes que hacer con tu tarjeta bancaria. Por eso, en el siguiente paso serás redirigido a una página del Santander. Los siguientes pagos se harán con cargo a la cuenta bancaria que hayas facilitado anteriormente.',
'Después del pago con la tarjeta, serás redirigido de nuevo a este flujo de clientes. Le confirmaremos que usted y su familia están cubiertos por el Plan de Asistencia Familiar.',
'Al cabo de unos días recibirá la póliza de seguro por correo.']
},
PRE_CONTRACT_ACCEPT: 'He leído y acepto el documento de información precontractual',
AMAZON_RETURN: 'Le ofrecemos un vale de Amazon con un valor de 10 euros.',
REDIRECT: 'Después de aceptar los términos y condiciones y hacer clic en "Siguiente", se abrirá una nueva pestaña y será redirigido al sitio web de Insurama. Insurama, como agencia de suscripción de Helvetia Global Solutions Ltd, es quien ofrece seguros para teléfonos móviles. Para garantizar la protección de su teléfono móvil, le solicitamos que continúe ingresando sus datos de pago.',
TRAVEL: {
    cancellation: {
        description: 'Travel cancellation'
    },
    hospitalization: {
        description: 'Hospitalisation'
    },
    repatriation: {
        description: 'Repatriation'
    },
    accident: {
        description: 'Accident'
    },
    wallet_theft: {
        description: 'Theft of wallet & cash'
    },
    device_theft: {
        description: 'Theft of mobile devices & jewellery'
    }
},
FUTURE_DATE: 'Please enter future date',
TRAVEL_PRE_SCREEN_TITLE: 'Your trip',
    //explaination page for assistance
HELVETIA_EXPLANATION: {
medical: {title: 'Medical', points: [
'24h medical hotline and video calls', 'International 2nd opinion', 'Exclusive medical network'
], description: 'Medical Assistance'},
dental: {title: 'Dental', points: [
'1 free dental checkup and cleaning per person and year', 'Exclusive access network of dentists'
], description: 'Dental Assistance'},
legal: {title: 'Legal', points: [
'1st free consultation and 25% discount for any follow ups'
], description: 'Legal Assistance'},
travel: {title: 'Travel', points: [
'Assistance in case of accident, illness and other unforeseen events', 'Extendable up to 6 or 12 months'
], description: 'Travel Assistance'},
funeral: {title: 'Funeral', points: [
'Burial Capital 3’700 €', 'Provision of the burial service: coffin, hearse, religious services, chapel, etc.', 'Cremation and other complementary services'
], description: 'Funeral Services'}
},
    ASSIST_YOU: 'We assist you!',
    CAN_FOCUS: 'So you and your family can enjoy the moment fully!',
    GET_ASSISTANCE: 'To my personal offer',
    INSURED_HINT: 'Add all family members, who lives in your household and are below 80 years old.',
    IBAN_HINT: 'The IBAN number is for subsequent payment. The card will be used only for the first payment',
    DATE_PLACEHOLDER: 'dd.mm.yyyy',
    TAX_NUMBER: 'NIF',
    NATIONALITY: 'Nationality',
    PASSPORT_ID: 'Passport number',
    FOREIGNER_ID: 'Foreigner ID number',
    NATIONAL_ID: 'National personal ID (for EU citizens only)',
    TAX_NUMBER_ID: 'Social/Tax ID (NIF)',
    IDENTIFICATION_METHOD: 'Identification',
    NUMBER: 'Number',
    CANNOT_PROCEED: 'Unfortunately we cannot proceed online. Please call your advisor',
    YES: 'Yes',
    NO: 'No',
    GENDER: [{value: 'MALE', label: 'Male'}, {value: 'FEMALE', label: 'Female'}, {value: 'OTHER', label: 'Other'}],
    SEX: 'Gender',
    MALE: 'Male',
    FEMALE: 'Female',
    INSURED_PERSON: 'Insured person',
    HELVETIA_POST_TITLE: 'Enter details about the insured person:',
    BENEFICIARY_POSTAL_TITLE: 'Calculate and contract your insurance:',
    POSTAL_CODE: 'Postal Code',
    INSURED: 'Please enter the birthday of all members of your family, who shall be protected by the insurance. Please enter your birthday first',
    ABOVE_80: 'Unfortunately, we can only insure people who are younger than 80 years old',
    ADD_MORE: 'Add more',
    MESSAGE: 'Message',
    CONTACT_ME_Q: 'Do you have any questions?',
    CONTACT_ME_A: 'Let us know, and we will call you back.',
    SEND: 'Send',
    CARD_INFORMATION: 'Card Information',
    REMARKS: 'Remarks',
    MAX_AMOUNT: 'Maximum Amount',
    DETAILS: 'Details',
    FLIGHT_DETAILS: 'Please enter here your flight details:',
    WITHOUT_DEDUCTIBLE: 'Without deductible',
    CHOOSE_ITEM_TO_CHECK: 'Please choose the item for which you want to check coverage:',
    INSURANCE_COMPANY_LABEL: 'Insurance Company',
    INSURANCE_PRODUCT_LABEL: 'Insurance Product',

    //steps
    STEP1: 'Data',
    STEP2: 'Offer',
    STEP3: 'Buy',
    CHOOSE_ITEM_TO_INSURE: 'Please choose the item you would like to insure:',
    // Insurance overview
    NO_COVERAGE: 'We can not offer you insurance coverage.',
    INSURANCE_OVERVIEW: 'Insurance overview:',
    COVERAGE: 'Covered:',
    NON_COVERED: 'Not covered:',
    DOWNLOAD_INSURANCE_PRODUCT: 'Download the detailed insurance description',
    DOWNLOAD_TERMS_AND_CONDITIONS: 'Download terms & conditions',
    PRODUCT_INSURANCE_STEP_ONE_TITLE: 'Just a few steps to get covered with Insurance',
    PRODUCT_INSURANCE_STEP_TWO_TITLE: 'Discovered products from the receipt',
    PRODUCT_INSURANCE_STEP_THREE_TITLE: 'Select your insurance:',
    PRODUCT_INSURANCE_STEP_TWO_HINT: 'Product Categories',
    PRODUCT_INSURANCE_STEP_ONE_ONLINE: 'Tell us, what do you want to protect.',
    ENTER_MORE_DETAILS: 'Enter more details about the item you want to insure:',
    KNOW_BETTER: 'Please, let us know you better',
    TAKE_RECEIPT_PHOTO: 'Please take a photo of the purchase receipt:',
    CHECK_RECEIPT: 'Please check if the receipt is clear in the photo:',
    CHECK_ITEM_PHOTO: 'Please check if the item is clear in the picture:',
    TAKE_ITEM_PHOTO: 'Please take a photo of your item to be covered:',
    BUTTON: 'Button',
    MORE_DETAILS: 'Show Details',
    BACK: 'Back',
    SKIP_RECEIPT: 'Skip receipt',
    NEXT: 'Next',
    FROM: 'From',
    GENERAL_CONDITIONS: 'General conditions',
    LENGTH: 'Duration: ',
    MINIMUM_LENGTH: 'Minimum contract duration: ',
    MAXIMUM_LENGTH: 'Maximum contract duration: ',
    CONTRACT_TERMINATION: 'Contract termination: ',
    COVERAGE_LIST: 'Coverage',
    SEARCH: 'Search',
    OTHER: 'Other',
    MORE: 'More...',
    CLOSE: 'Close',
    PHOTO: 'Photo',
    OR: 'Or',
    OPTIONS: 'Options',
    SHOW_MORE: 'Show more',
    DO_NOT_INSURE: 'Do not insure this item',
    NOT_INSURABLE: 'This item is not insurable',
    SELECT_ITEM_TYPE: 'Please select the item type',
    ADD_UNRECOGNIZED_ITEM: 'Add an item',
    NAME: 'Name',
    PLEASE_WAIT: 'Please wait',
    RECOGNIZE_PROCESSING: 'The application is processing the photo(s) to  extract the relevant information.',
    PRICE: 'Price',
    CANCEL: 'Cancel',
    OK: 'Ok',
    SAVE_AND_NEXT: 'Next',
    MY_NEW_ITEM: 'My new item',
    INSURANCE_COVERAGE: 'Insurance coverage:',
    OPTIMALLY_INSURE: 'Optimally insure',
    COVERAGE_HOME_INSURANCE_DESCRIPTION: 'Coverage in Home Insurance',
    COVERAGE_DESCRIPTION: 'The following coverage overview is generalized and is based on a typical basic Swiss home insurance product. Do you want to check the coverage of your specific home insurance product? Click here: ',
    HOME_INSURANCE: 'Select home insurance',
    INCIDENT_TYPE: 'Risk',
    OUTSIDE_INSIDE: 'Coverage',
    THEFT: 'Theft',
    BURGLARY: 'Burglary',
    BREAKAGE: 'Breakage',
    ROADSIDE_ASSISTANCE: 'Roadside Assistance',
    VISION_STRENGTH: 'Change in vision strength',

    // Inform Screen
    DEAR_CUSTOMER: 'Dear customer,',
    TO_OFFER_YOU: 'to offer you the best insurance options, we need more information about the item you just purchased. You can either:',
    //OPTION_TAKE_A_PHOTO: 'Take a photo of the purchase receipt',
    //OPTION_UPLOAD_FROM_GGALLERY: 'Upload a photo of the purchase receipt',
    OPTION_TAKE_A_PHOTO: 'Take a photo',
    OPTION_UPLOAD_FROM_GGALLERY: 'Upload a photo',
    OPTION_ENTER_MANUALLY: 'Enter data manually',
    OPTION_TAKE_A_PHOTO_DESCRIPTION: 'Access to your camera may be requested.',
    OPTION_UPLOAD_FROM_GGALLERY_DESCRIPTION: 'Access to photos, medias and files may be requested.',

    // Empty State
    SORRY: 'Sorry',
    SORRY_MESSAGE: 'We Apologise but the offer link is not valid any more. We would be happy to make you an offer with your next purchase.',
    DONE: 'Done',

    // Payment Methods
    PAYMENT_METHODS: 'Payment Methods',
    HOW_DO_YOU_WANT_TO_PAY: 'How do you want to pay?',
    YOU_WILL_BE_SPENDING: 'You will be spending',
    FOR_BUYING_INSURANCE_POLICIES: 'for buying insurance policies',
    CONFIRM_YOUR_CARD_DETAILS: 'Please enter the details of your payment card:',
    HDRC_MONEY_BACK: 'Please enter the details of your payment card.',
    CARDHOLDER_NAME: 'Cardholder name',
    CARD_NUMBER: 'Card number',
    EXPIRY_DATE: 'Expiry date',
    CVV: 'Security code (CVV)',
    CONFIRM_AND_PAY: 'Confirm & buy',
    I_HAVE_SUCCESSFULLY_READ: 'I have read and I agree with the terms and conditions.',
    READ_THE_FOLLOWING: 'I have read and accepted the following:',
    TERMS_AND_CONDITIONS: 'Terms & conditions of the insurance product',
    DATA_PRIVACY: 'Authorization for the insurer to process the data',
    ISSUER_DATA_PRIVACY: 'Authorization for the broker to process the data',
    ISSUER_TERMS_AND_CONDITIONS: 'Terms & conditions of the broker',
    ISSUER_PAYMENT_CONSENT: 'Direct debit authorization to the broker for the payment of subsequent premiums',
    ENTRY_A_FEW_MORE_DETAILS: 'Enter a few more details about you:',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    FAMILY_NAME: 'Family name',
    BIRTHDAY: 'Birthday',
    EMAIL_ADDRESS: 'E-mail address',
    PHONE_NUMBERS: 'Phone number',
    ADDRESS: 'Address',
    CITY: 'City',
    STREET: 'Street',
    ROOM: 'Room',
    COUNTRY: 'Country',
    ENTRY_HERE: 'Enter here',
    MONTHLY: 'per month',
    WEEKLY: 'per week',
    YEARLY: 'per year',
    QUARTERLY: 'Quarterly',
    OVERVIEW_YEARLY: 'Yearly',
    OVERVIEW_MONTHLY: 'Monthy',
    OVERVIEW_WEEKLY: 'Weekly',
    WEEKS: 'weeks',
    MONTHS: 'months',
    YEARS: 'years',
    WHAT_IS_NEXT: 'What is next:',
    INSURANCE_CERTIFICATE: (email) => `The insurance certificate will be generated and sent to ${email}`,
    INSURANCE_CONFIRMATION: (email) => `Your purchase and payment confirmation will be generated and sent to ${email}`,
    YOU_CAN_DOWNLOAD: 'Additionally, you can download your insurance certificate on the next page',
    YOU_CAN_DOWNLOAD_CONFIRMATION: 'You can also download your purchase and payment confirmation at the following page',
    DEDUCTIBLE: 'Deductible: ',

    // validation
    MUST_BE_POSITIVE: 'The value should be positive',
    UP_TO: 'Up to 30 characters',
    PHONE_SHOULD_BE_INTERNATIONAL: 'El número de teléfono debe tener formato internacional (p. ej. +346xxxxxxxxx)',
    MANDATORY_FIELD: 'This field is mandatory',
    INVALID_FORMAT: 'Invalid value',
    PRICE_SHOULD_BE_LOWER: 'Price should be lower than the transaction price',
    DATE_SHOULD_BE_PAST: 'Date should be in the past',
    TWO_DECIMAL_PLACES: 'Ensure that there are no more than 2 decimal places',

    // Congratulations!
    CONGRATULATIONS: 'Congratulations!!',
    GLAD_TO_INFORM_YOU: 'We are glad to inform you that you successfully purchased the coverage. Your product is now secure.',

    YOU_WILL_RECEIVE: 'You will receive in the next minutes a confirmation email with the coverage certificate.',

    YOU_ARE_PROTECTED: 'You are protected',
    ALREADY_PURCHASED: 'We are glad to inform you that you have already purchased an insurance and that your beloved item is already protected.',

    DOWNLOAD: 'download',
    YOU_CAN_DOWNLOAD_HERE: 'You can download your insurance certificate here:',
    YOU_CAN_DOWNLOAD_CONFIRMATION_HERE: 'Please download the confirmation of the purchase and payment here:',
    BACK_TO_OVERVIEW: 'back to overview',

    // contracts list
    YOUR_POLICY_PURCHASES: 'Your insurances',
    PURCHASED_ON: 'Purchased on',
    EXPIRING_ON: 'Expiring on',
    WHATS_COVERED: 'What\'s covered',
    FILE_A_CLAIM: 'File a claim',
    REACTIVATE: 'Reactivate',

    // contract details
    DOWNLOAD_PURCHASE_RECEIPT: 'Download purchase receipt',
    INSURCANCE_CONTRACT: 'Insurance contract',
    ITEM_IS_PROTECTED_BY_INSURANCE: 'Item is protected by the insurance',
    INSURANCE_COVERAGE_START: 'Insurance coverage start',
    INSURANCE_COVERAGE_UNTIL: 'Insurance coverage until',
    DOWNLOAD_PRODUCT_SHEET: 'Download the product sheet',
    DOWNLOAD_INSURANCE_CERTIFICATE: 'Download the certificate',
    CANCEL_CONTRACT: 'Cancel Insurance',

    // claim form
    PROVIDE_INFO_FOR_CLAIM: 'Kindly provide us with some more info, before we process your claim',
    ATTACH_PHOTO_VIDEO: 'Please attach, if relevant, photos or videos showing the damage.',
    DESCRIBE_WHAT_HAPPEND: 'Please describe what happened',
    ENTER_HERE: 'Enter here',
    SUBMIT_CLAIM: 'Submit the claim',
    PLEASE_ATTACH_FILES: 'Please attach files',
    THANKS_FOR_REQUEST: 'Thanks for submitting your claim. We are happy to process it and provide you an answer in the next days.',
    YOUR_INSURANCE_COVERS: 'Your insurance covers the repair by one of our certified partners only. You can find here a repair shop in your vicinity.',
    SHOW_PARTNER_NETWORK: 'Show partner network',

    // confirmation
    YOUR_CONTRACT_HAS_BEEN_CANCELLED: 'Your insurance has been cancelled. You will receive a confirmation email soon',
    GOT_IT: 'Got it',
    THE_INSURANCE_WILL_BE_CANCELLED: 'The insurance will be cancelled starting from',
    ARE_YOU_SURE_TO_CANCEL: 'Are you sure you want to cancel the protection of your',
    RETURN: 'Return',
    CONFIRM_REACTIVATION: 'Please confirm that you want to reactivate the insurance.',
    YOUR_CONTRACT_HAS_BEEN_REACTIVATED: 'Your insurance has been reactivated. You will receive a confirmation mail soon.',

    // contract statuses
    ACTIVE: 'active',
    PENDING_CANCELLATION: 'cancelled',
    VOID: 'terminated',

    // issuer application
    // login
    PASSWORD_POLICY: 'The password doesn\'t fulfill the given criteria',
    BE_AWARE_PASSWORD_POLICY: 'Please be aware that the password needs to fulfill the following criteria:',
    AT_LEAST: ['At least 8 characters', 'At least 1 small letter', 'At least 1 capital letter', 'At least 1 number', 'At least 1 special character'],
    PASSWORD: 'Password',
    FORGOT_PASSWORD: 'Forgot Password?',
    LOGIN: 'Login',
    NO_ACCOUNT_ERR: 'Invalid e-mail address or password',
    NO_EMAIL_ERR: 'Invalid e-mail address',
    MISMATCH_ERR: 'Passwords should match',

    //forgot password
    FORGOT_YOUR_PASSWORD: 'Forgot your Password?',
    ENTER_THE_EMAIL: 'Please enter the e-mail address assotiated with your account and we will email you a link to reset your password',
    RESET_PASSWORD: 'Reset Password',
    PASSWORD_RESET_EMAIL: 'Password reset e-mail was sent',
    EMAIL_SENT: 'If the e-mail entered corresponds to an active registed user, than the system has already sent an e-mail to the entered address',
    REPEAT_PASSWORD: 'Repeat password',
    RESET: 'Reset',
    PASSWORD_RESET: 'Password reset',

    //sidebar
    //sections
    USAGE_ANALYSIS: 'Usage Analysis',
    SETTINGS: 'Settings',
    ACCOUNT_ADMIN: 'Account Admin',
    //links
    DASHBOARD: 'Dashboard',
    ANALYTICS: 'Analytics',

    GENERAL_SETTINGS: 'General Settings',
    PAYMENT_PRODUCTS: 'Payment Products',
    WHITE_LABELING: 'White Labeling',

    USERS_MANAGEMENT: 'Users Management',
    INTEGRATION_SETTING: 'Integration Setting',

    //general settings
    OFFERING_TEXT_ON_PURCHASE: 'Offering',
    ADD_AN_OFFERING: '+ Add a new item group',
    INSURANCE_CATALOGUE: 'Insurance catalogue',
    INSURANCE_TYPE: 'Insurance type',
    INSURANCE_PRODUCT: 'Insurance product',
    OTHER_SETTINGS: 'Other settings',
    CI_CD_SETTING: 'CI/CD setting',
    ADD_OFFER_TEXT: '+ Add offer text',
    EDIT: 'Edit',
    DELETE: 'Delete',
    OFFERING: 'Offer',
    OFFER_TEXT: 'Offer Text',
    TRAFFIC_ALLOCATION: 'Traffic Allocation',
    ALLOCATION: 'Allocation',
    TEMPLATE_TEXT: 'Template text',
    SUM_ERR: 'Summary of allocation for Template Allocations should be equal 100%',
    MAPPING_EXISTS: 'Offering with the selected language already exists',
    OFFERING_ERR: 'Offer Text should not be duplicated',
    EMPTY_FIELD_ERR: 'Please fill out this field',
    LANGUAGE: 'Language',
    GENDERS: ['Male', 'Female', 'All'],
    GROUP_ITEMS: 'Item Group',
    MIN_AGE_VS_MAX_AGE: 'Max age should be greater than min age',

    // white labels
    MY_WHITE_LABELS: 'My white labels',
    WHITE_LABELS: 'White labels',
    ADD_WHITE_LABEL: '+ Add a white label',
    LOGO: 'Logo',
    COLOUR_SCHEME: 'Colour Scheme',
    SHOW_INSURER_LOGO: 'Show insurer logo',
    NO_LOGO_ERR: 'Logo is mandatory',

    //payment products
    ADD_A_PAYMENT_PRODUCT: '+ Add a payment product',
    PAYMENT_PRODUCT: 'Payment product',
    PAYMENT_TYPE: 'Payment type',
    PAYMENT_SCHEME: 'Payment scheme',
    PAYMENT_SUBSCHEME: 'Payment subscheme',
    PAYMENT_PRODUCTS_PAGE: 'Payment products',
    PAYMENT_PRODUCT_SETTINGS: 'Payment product settings',
    EDIT_PAYMENT_PRODUCT: 'Edit a payment product',
    CREATE_PAYMENT_PRODUCT: 'Create a payment product',
    PRODUCT_ID: 'Payment product id',
    USE_GENERAL_SETTINGS: 'Use general settings',

    OFFER_MANAGEMENT: 'Offer Management',

    PORTFOLIO_OVERVIEW: 'Portfolio Overview',
    PORTFOLIO_DEVELOPMENT: 'Portfolio development',
    PORTFOLIO_LEADS: 'Leads and offer development',

    BENEFICIARY_TITLE: 'Who shall be included in your insurance?',
    BENEFICIARY_QUESTION: 'Whom would you like to cover?',
    BENEFICIARY_OPTION1: 'myself',
    BENEFICIARY_OPTION2: 'me and my family/household',

}

export default en;
