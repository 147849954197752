import React from 'react';
import classNames from 'classnames';
import './input.scss';
import { useLanguage } from '../../../helpers/customHooks';

const InputWrap = React.forwardRef(
    (
        {
            id,
            img,
            label,
            disabled = false,
            type = 'text',
            placeholder = '',
            value = '',
            keyRestrictions,
            onChange,
            className = '',
            containerClass = '',
            lable = '',
            error = '',
            imgClassNames = '',
            ignoreChange,
            onBlur = () => {},
            ...restProps
        },
        ref,
    ) => {
        useLanguage();
        const changeType = (target) => {
            if (target.type === 'custom') {
                target.setSelectionRange(0, target.value.length);
                target.type = 'number';
            }
        };
        const checkKey = (e) => {
            const charCode = (e.which) ? e.which : e.keyCode;
            if ((charCode > 31 && charCode !== 43 && (charCode < 48 || charCode > 57))) {
                e.preventDefault();
                e.stopPropagation();
            }
        };
        return (
            <div className={`input-field-container ${containerClass}`}>
                {!!lable && (
                    <div className="input-lable">{lable}</div>
                )}
                <div className={classNames('form-group input-wrap', {
                    'error-state': !!error
                })}>
                    {!!img && (
                        <img
                            alt="input"
                            src={img}
                            className={classNames('imput-img', imgClassNames)}
                        />
                    )}
                    <input
                        id={id}
                        onKeyPress={keyRestrictions ? e => checkKey(e) : null}
                        disabled={disabled}
                        type={type}
                        onClick={({ target }) => changeType(target)}
                        className={className}
                        placeholder={placeholder}
                        value={value}
                        onChange={e => (ignoreChange ? onChange(e) : onChange(e.target.value))}
                        onBlur={(e) => { onBlur(e); }}
                        ref={ref}
                        {...restProps}
                    />
                </div>
                {!!error && <p className="error-text">{error}</p> }
            </div>
        );
    }
);
InputWrap.displayName = 'InputWrap';

export default InputWrap;
