import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faTrash, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { messages } from '../../../../../constants/messages';
import { roles } from '../../../../constants';
import Input from '../../Common/Input/Input';
import InputDatepicker from '../../Common/InputDatepicker/InputDatepicker';
import Loader from '../../Common/Loader/Loader';
import clientsValidator, { usersValidator } from '../../../../helpers/validators';
import '../details.scss';

const EditForm = ({ required, user,
    resetBeError, lang, list = [],
    beError, type, fields, action, onDelete, onSend, onCancel }) => {
    const [values, setValues] = useState(action.obj);
    const [active, setActive] = useState(false);
    const [errors, setErrors] = useState({});
    const form = useRef(null);

    const onChange = (v, k) => {
        setValues((prev) => {
            let tmp = v;
            if (k === 'userpartnerrole_set') {
                if (action === 'add') {
                    tmp = [{ role: v.value }];
                }
                tmp = [{ ...prev[0], role: v.value }];
            }
            return {
                ...prev,
                [k]: tmp
            };
        });
    };

    const handleDelete = () => {
        if (!active) {
            onDelete();
        }
    };

    const handleSend = () => {
        setErrors({});
        if (!active) {
            setActive(true);
            const err = type === 'clients' ? clientsValidator(values) : usersValidator(values);
            resetBeError();
            if (!Object.values(err).some(v => typeof v === 'string' && v)) {
                onSend(values);
                return;
            }
            setActive(false);
            setErrors(err);
        }
    };

    useEffect(() => {
        if (beError) {
            form.current.scrollTo(0, 0);
            setActive(false);
        }
    }, [beError]);

    const validStatus = (st) => {
        setErrors(prev => ({
            ...prev,
            birthday: !st
        }));
    };

    if (type !== action.initiator) {
        return null;
    }

    return <div className="popup-wrapper_form-wrapper">
        <div className="popup-wrapper_form" ref={form}>
            <div className="popup-wrapper_form-wrapper_header">
                <p>{messages[`${type === 'clients' ? 'CUSTOMER' : 'USER'}_DETAILS`]}</p>
                <div>
                    {action.type === 'edit' && user !== values.email && <FontAwesomeIcon className="popup-wrapper_form-wrapper_header_delete bin" icon={faTrash} onClick={handleDelete} />}
                    <FontAwesomeIcon className="popup-wrapper_form-wrapper_header_delete" icon={faClose} onClick={onCancel} />
                </div>
            </div>
            {beError && <p className="popup-wrapper_form_error">{beError}</p>}
            <div>
                {
                    Object.entries(fields).map(([key, value], i) => {
                        if (key === 'userpartnerrole_set' || key === 'advisor') {
                            const val = key === 'advisor' ? list.filter(({ pk }) => pk === values[key])[0]
                                :
                                ((action.type === 'add' &&
                                    roles(messages)[0]) ||
                                    (roles(messages).filter(r =>
                                        r.value === values[key][0].role)[0] || roles[0]));
                            return <>
                                <p className="input-label">{value.title}</p>
                                <Select
                                    isDisabled={action.type !== 'add' && key === 'userpartnerrole_set' && user === values.email}
                                    placeholder={messages.SELECT_ADVISOR}
                                    onChange={v => onChange(key === 'advisor' ? v.pk : v, key)}
                                    menuPlacement="top"
                                    classNamePrefix="async-offer"
                                    className="select"
                                    value={val}
                                    options={key === 'advisor' ? list : roles(messages)}
                                /></>;
                        } else if (key === 'birthday') {
                            return <>
                                <p className="input-label">{value.title}</p><InputDatepicker
                                    dateValue={values.birthday || ''}
                                    onInputChange={v => onChange(v, 'birthday')}
                                    lang={lang}
                                    validStatus={st => validStatus(st)} /></>;
                        }
                        return <Input disabled={key === 'email' && user === values.email && action.type !== 'add'} error={errors[key]} key={i} required={required.indexOf(key) > -1} label={value.title} value={values[key] === undefined ? '' : values[key]} keyRestrictions={key === 'phone_number'} onChange={v => onChange(v, key)} />;
                    })
                }
                <div className="popup-wrapper_form_send-wrapper">
                    <button className={`popup-wrapper_form_send ${active && 'active'}`} onClick={handleSend}>
                        {active && <Loader />}
                        <span>{messages.SAVE}</span>
                        <FontAwesomeIcon icon={faFloppyDisk} />
                    </button>
                </div>
            </div></div></div>;
};

export default EditForm;
