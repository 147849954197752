import React, { useState, useEffect } from 'react';

import Table from '../Common/Table/Table';
import { shortenMerchantTitles } from '../../constants';

const MerchantsChildrenTab = ({ data }) => {
    const [profileData, setProfileData] = useState({});

    useEffect(() => {
        setProfileData(data);
    }, [data]);

    return <>
        <Table
            titles={shortenMerchantTitles}
            nav="merchant"
            data={profileData || []} />
    </>;
};
export default MerchantsChildrenTab;
