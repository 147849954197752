import React from 'react';
import { useSelector } from 'react-redux';
import { messages } from '../../../../constants/messages';
import Button from '../../Common/Button/Button';
import OfferManagementSelect from '../OfferManagementCommon/OfferManagementSelect';
import { selectStyles } from '../../../../store/issuerApp/issuerApp.selector';
import calculateColor from '../../../../helpers/calculateColor';
import offer_fields from '../constants';

const OfferEditMode = ({ data = {}, onDelete,
    handleFormDataChange, isBlurred, showDelete = true }) => {
    const { primaryColor } = useSelector(selectStyles);

    const onStrategyDelete = () => {
        onDelete();
    };

    return (
        <form
            className="offer-management-card__section__segments__form"
            style={{ display: (isBlurred && 'none') || 'initial' }}>
            <main className="offer-management-card__section__segments__offer">
                <div className="offer-management-card__section__segments__form__item" style={{ textAlign: 'center' }}>
                    <textarea
                        className="offer-management-card__section__segments__form__item__area"
                        value={data.offer_template ? data.offer_template.template : ''}
                        onChange={e => handleFormDataChange(e.target.value, 'template')} />
                    <Button type="submit" onClick={(e) => { e.preventDefault(); }}>Select existing</Button>
                </div>
                <div className="offer-management-card__section__segments__form__item">
                    <OfferManagementSelect
                        value={data.offered_products || []}
                        options={offer_fields.offered_products.options}
                        isMulti
                        isSearchable
                        onChange={e => handleFormDataChange(e, 'offered_products')} />
                </div>
                <div>
                    <div className="offer-management-card__section__segments__form__item">
                        <span style={{ fontWeight: 500, display: 'inline-block', marginBottom: 5 }}>
                            {offer_fields.delay.title}:
                        </span>
                        <div>
                            <input
                                value={data.delay}
                                style={{ backgroundColor: calculateColor(primaryColor, 0.15) }}
                                className="offer-management-card__section__segments__form__item__input"
                                onChange={e => handleFormDataChange(e.target.value, 'delay')}
                            />
                            <span style={{ fontWeight: 'normal', marginRight: 0 }}>
                                {offer_fields.delay.unit}
                            </span>
                        </div>
                    </div>
                    <div className="offer-management-card__section__segments__form__item">
                        <span style={{ fontWeight: 500, display: 'inline-block', marginBottom: 5 }}>
                            {offer_fields.reminder.title}:
                        </span>
                        <OfferManagementSelect
                            onChange={e => handleFormDataChange(e, 'reminder')}
                            isCustomized
                            options={offer_fields.reminder.options}
                            value={data.reminder} />
                    </div>
                    <div className="offer-management-card__section__segments__form__item">
                        <span style={{ fontWeight: 500, display: 'inline-block', marginBottom: 5 }}>
                            {offer_fields.coverage_check_enabled.title}:
                        </span>
                        <OfferManagementSelect
                            onChange={e => handleFormDataChange(e, 'coverage_check_enabled')}
                            isCustomized
                            options={offer_fields.coverage_check_enabled.options}
                            value={data.coverage_check_enabled} />
                    </div>
                    <div className="offer-management-card__section__segments__form__item">
                        <span style={{ fontWeight: 500, display: 'inline-block', marginBottom: 5 }}>
                            {offer_fields.channel.title}:
                        </span>
                        <OfferManagementSelect
                            onChange={e => handleFormDataChange(e, 'channel')}
                            isCustomized
                            menuPlacement="top"
                            options={offer_fields.channel.options}
                            value={data.channel} />
                    </div>
                    <div className="offer-management-card__section__segments__form__item">
                        <span style={{ fontWeight: 500, display: 'inline-block', marginBottom: 5 }}>
                            {offer_fields.item_type_specific.title}:
                        </span>
                        <OfferManagementSelect
                            onChange={e => handleFormDataChange(e, 'item_type_specific')}
                            isCustomized
                            menuPlacement="top"
                            options={offer_fields.item_type_specific.options}
                            value={data.item_type_specific} />
                    </div>
                </div>
                <div className="offer-management-card__section__segments__form__item">
                    <span style={{ fontWeight: 500, display: 'inline-block', marginBottom: 5 }}>
                        {offer_fields.allocation.title}:
                    </span>
                    <div>
                        <input
                            type="number"
                            value={data.allocation}
                            onChange={e => handleFormDataChange(e.target.value, 'allocation')}
                            style={{ backgroundColor: calculateColor(primaryColor, 0.15) }}
                            className="offer-management-card__section__segments__form__item__input"
                        />
                        <span style={{ fontWeight: 'normal', marginRight: 0 }}>
                            {offer_fields.allocation.unit}
                        </span>
                    </div>
                </div>
                {
                    showDelete ? <Button variant="text" onClick={e => onStrategyDelete(e)}>
                        {messages.DELETE}
                    </Button> : null
                }
            </main>
        </form>
    );
};

export default OfferEditMode;
