import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { fetchBatches, fetchContacts } from '../../../../store/issuerApp/issuerApp.actions';
import Table from '../Common/Table/Table';

import { notificationsTableItems } from '../../../constants';
import { messages } from '../../../../constants/messages';

const Notifications = ({ active, reload }) => {
    const [data, setData] = useState([]);
    const [popup, setPopup] = useState({ show: false, data: [] });

    const fetchData = () => fetchBatches()
        .then((res) => {
            setData(res.map(r => ({ ...r,
                info: r.result_json.info,
                created_at: new Date(r.created_at).toLocaleString('es')
            })
            ));
        });

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (reload) {
            fetchData();
        }
    }, [reload]);

    const onEdit = (row) => {
        fetchContacts({ batch: row.uuid })
            .then((r) => {
                setPopup({ show: true, title: row.info, data: r });
            });
    };

    return <div className={`details-wrapper ${active && 'active'}`}>
        {popup.show && <div className="popup-wrapper">
            <div className="popup-wrapper_form-wrapper">
                <div className="popup-wrapper_form">
                    <div className="popup-wrapper_form-wrapper_header">
                        <p>{messages.CUSTOMER_BATCH_UPLOAD}</p>
                        <FontAwesomeIcon className="popup-wrapper_form-wrapper_header_delete" icon={faClose} onClick={() => setPopup({ show: false })} />
                    </div>
                    <p>{popup.title}</p>
                    <div className="popup-wrapper_form_contacts">
                        {
                            popup.data
                                .map(({ first_name, last_name }, i) =>
                                    <p key={i}>{first_name} {last_name}</p>
                                )
                        }
                    </div>
                </div>
            </div>
        </div>}
        <Table
            cn="notification-table"
            isEditable
            onEdit={row => onEdit(row)}
            data={data}
            fields={Object.keys(notificationsTableItems(messages))}
            header={Object.values(notificationsTableItems(messages))} />
    </div>;
};

export default Notifications;
