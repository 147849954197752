import React from 'react';
import { useHistory } from 'react-router-dom';
import { messages } from '../../../../constants/messages';
import Button from '../../Common/Button/Button';
import PaymentProductsTable from './PaymentProductsTable/PaymentProductsTable';
import PaymentProducts from '../../../containers/PaymentProducts';

import './PaymentProductsSection.scss';

const PaymentProductsSection = () => {
    const history = useHistory();
    const addProduct = () => history.push('/app/payment-products/create');
    return (
        <PaymentProducts>
            <div className="container payment-products-section">
                <header className="payment-products-section__header">
                    <div className="payment-products-section__header-title">{messages.PAYMENT_PRODUCTS}</div>
                    <Button
                        size="large"
                        onClick={addProduct}
                        variant="contained"
                        type="button"
                    >
                        {messages.ADD_A_PAYMENT_PRODUCT}
                    </Button>
                </header>
                <main>
                    <PaymentProductsTable />
                </main>
            </div>
        </PaymentProducts>
    );
};
export default PaymentProductsSection;
