/* eslint-disable camelcase */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { messages } from '../../../constants/messages';
import { useLanguage } from '../../../helpers/customHooks';
import { setCurrentGoodsId, setInsuranceTypes, excludeInsuranceCategory } from '../../../store/productInsurance/productInsurance.actions';
import { currentGoodsId, selectInsuranceCategories } from '../../../store/selectors';
import CheckBoxField from '../../Common/Checkbox/CheckBoxField';
import InputWrap from '../../Common/Input/InputWrap';
import SearchImg from '../../../dist/images/search.svg';

import './UnqualifiedItemTypes.scss';
import { detectMobile } from '../../../helpers/renderingHelpers';
// import { getCategoriesCount } from '../../../helpers/dataHelpers';
// import { getCategoriesCount } from '../../../helpers/dataHelpers';

const searchItems = (groups, search) => {
    if (!search || search.length < 2) {
        return groups;
    }
    return groups.reduce((acc, { name: categoryName, item_types }) => {
        const searchString = search.toLowerCase();
        const items = item_types.filter(({ name }) =>
            name.toLowerCase().indexOf(searchString) !== -1);
        if (items.length) {
            acc.push({
                name: categoryName,
                item_types: items
            });
        }
        return acc;
    }, []);
};

const SearchField = ({ onSearch, value, fieldRef }) => (
    <InputWrap
        img={SearchImg}
        imgClassNames="search-icon"
        className="search-field"
        placeholder={messages.SEARCH}
        onChange={onSearch}
        value={value}
        ref={fieldRef}
    />
);

const UnqualifiedItemTypes = ({ isOpen, close }) => {
    useLanguage();
    const [search, setSearch] = useState('');
    const itemTypes = useSelector(selectInsuranceCategories);
    const selectedItemType = useSelector(currentGoodsId);
    const dispatch = useDispatch();
    const morePanel = useRef();
    const groups = searchItems(itemTypes, search);
    const onSearch = (value) => {
        setSearch(value);
    };
    const [transform, setTransform] = useState(0);

    const yPosition = useRef(0);
    const moving = useRef(false);

    const clearPosition = () => {
        setTransform(0);
        yPosition.current = 0;
    };

    const handleClickOutside = (event) => {
        if (morePanel.current && !morePanel.current.contains(event.target)
            && isOpen && !moving.current) {
            close();
            clearPosition();
            setSearch('');
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    const onSelectItemType = (type) => {
        dispatch(setCurrentGoodsId(type.id));
        dispatch(setInsuranceTypes(type));
        dispatch(excludeInsuranceCategory());
        close();
        clearPosition();
        setSearch('');
    };
    const fieldRef = useRef();

    const onScroll = useCallback(() => {
        if (fieldRef.current) {
            fieldRef.current.blur();
        }
    }, [fieldRef]);

    useEffect(() => {
        if (isOpen) {
            const windowHeight = window.innerHeight;
            const contentHeight = morePanel.current.offsetHeight;

            const offset = contentHeight - (windowHeight / 2);
            if (offset > 0) {
                setTransform(offset);
            }
            document.documentElement.style.overflowY = 'hidden';
            document.body.style.overflowY = 'hidden';
            // setTimeout(() => morePanel.current.classList.add('no-animation'), 1500);

            /* const itemsCount = getCategoriesCount(groups);
            if (fieldRef.current && itemsCount >= 5) {
                fieldRef.current.focus();
            } */
            if (!selectedItemType) {
                const firstItem = groups[0].item_types[0];
                onSelectItemType(firstItem.id);
            }
        } else {
            morePanel.current.classList.remove('no-animation');
            clearPosition();

            document.documentElement.style.overflowY = 'auto';
            document.body.style.overflowY = 'auto';
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const setUpStartPosition = (clientY) => {
        yPosition.current = clientY;
        moving.current = true;
    };

    const updatePosition = useCallback((clientY) => {
        if (moving.current && yPosition.current) {
            const diff = yPosition.current - clientY;
            const newTransform = transform - diff;
            if (newTransform > 0) {
                yPosition.current = clientY;
                setTransform(newTransform);
            } else {
                clearPosition();
            }
        }
    }, [transform]);

    const resetPosition = useCallback((/* event */) => {
        if (moving.current) {
            /* event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation(); */
            yPosition.current = 0;
            moving.current = false;
        }
    }, []);

    const onMouseDown = useCallback((event) => {
        if (!isOpen || detectMobile()) {
            return;
        }
        /* if (event.target.closest('.checkbox-field') ||
        event.target.closest('.input-field-container')) {
            return;
        } */
        // event.preventDefault();
        setUpStartPosition(event.clientY);
    }, [isOpen]);

    const onTouchStart = useCallback((event) => {
        if (!isOpen || !detectMobile()) {
            return;
        }
        /* if (event.target.closest('.checkbox-field') ||
        event.target.closest('.input-field-container')) {
            return;
        } */
        if (fieldRef.current) {
            fieldRef.current.blur();
        }
        const touch = event.changedTouches[0];
        if (touch) {
            // event.preventDefault();
            setUpStartPosition(touch.clientY);
        }
    }, [isOpen]);

    const onMouseMove = useCallback((event) => {
        if (!isOpen) {
            return;
        }
        const { clientY } = event;
        updatePosition(clientY);
    }, [isOpen, updatePosition]);

    const onTouchMove = useCallback((event) => {
        if (!isOpen) {
            return;
        }
        const touch = event.changedTouches[0];
        if (touch) {
            // event.preventDefault();
            updatePosition(touch.clientY);
        }
    }, [updatePosition, isOpen]);

    const onMouseUp = useCallback((event) => {
        if (!isOpen) {
            return;
        }
        resetPosition(event);
    }, [isOpen, resetPosition]);

    const onTouchEnd = useCallback((event) => {
        if (!isOpen) {
            return;
        }
        resetPosition(event);
    }, [isOpen, resetPosition]);

    useEffect(() => {
        dispatch(excludeInsuranceCategory());
    }, []);

    useEffect(() => {
        if (!detectMobile()) {
            document.addEventListener('mousemove', onMouseMove);
        }
        return () => document.removeEventListener('mousemove', onMouseMove);
    }, [onMouseMove]);
    useEffect(() => {
        if (!detectMobile()) {
            document.addEventListener('mouseup', onMouseUp);
        }
        return () => document.removeEventListener('mouseup', onMouseUp);
    }, [onMouseUp]);
    useEffect(() => {
        if (detectMobile()) {
            document.addEventListener('touchmove', onTouchMove, false);
        }
        return () => document.removeEventListener('touchmove', onTouchMove);
    }, [onTouchMove]);
    useEffect(() => {
        if (detectMobile()) {
            document.addEventListener('touchend', onTouchEnd, false);
        }
        return () => document.removeEventListener('touchend', onTouchEnd);
    }, [onTouchEnd]);

    useEffect(() => {
        const contentHeight = morePanel.current.offsetHeight;
        if (contentHeight - transform < 210) {
            morePanel.current.classList.remove('no-animation');
            close();
            setSearch('');
        }
    }, [transform, close]);

    return (
        <div className={`unqualified-item-types-wrapper ${isOpen ? 'unqualified-item-types-wrapper--open' : ''}`}>
            <div
                ref={morePanel}
                className="unqualified-item-types-content"
                style={{
                    transform: transform && !search ? `translateY(${transform}px)` : 'none'
                }}
                onMouseDown={onMouseDown}
                onTouchStart={onTouchStart}
            >
                <div className="unqualified-item-types-section">
                    <div className="unqualified-item-types-separator" />
                </div>
                <div className="unqualified-item-types-section">
                    <div className="unqualified-item-types-header">
                        <div className="unqualified-item-types-header__title">
                            {messages.PRODUCT_INSURANCE_STEP_TWO_HINT}
                        </div>
                        <SearchField
                            value={search}
                            onSearch={onSearch}
                            fieldRef={fieldRef}
                        />
                    </div>
                </div>
                <div
                    className="unqualified-item-types-groups-wrapper unqualified-item-types-section"
                    onScroll={onScroll}
                >
                    {groups.map((group, index) => (
                        (group.item_types.length && <div key={`group-${index}`} className="item-types-group unqualified-item-types-section">
                            <div className="group-name">
                                {group.name || messages.OTHER}
                            </div>
                            {group.item_types.map(itemType => (
                                <CheckBoxField
                                    key={itemType.id}
                                    label={itemType.name}
                                    onClick={() => onSelectItemType(itemType)}
                                    id={itemType.id}
                                    checked={selectedItemType === itemType.id}
                                />
                            ))}
                        </div>) || null
                    ))}
                </div>
            </div>
        </div>
    );
};

export default UnqualifiedItemTypes;
