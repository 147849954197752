import React from 'react';
import './popup.scss';
import EditForm from './EditForm';

const Popup = ({ required, user, list = [], lang, resetBeError, error, type, fields, action, onDelete, onSave, onCancel }) => <div className="popup-wrapper">
    <EditForm
        user={user}
        required={required}
        list={list}
        lang={lang}
        resetBeError={resetBeError}
        beError={error}
        type={type}
        fields={fields}
        action={action}
        onDelete={onDelete}
        onSend={onSave}
        onCancel={onCancel} />
</div>;

export default Popup;
