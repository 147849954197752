import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { messages } from '../../constants/messages';
import { getContractsList } from '../../store/contract/contract.actions';
import {
    selectContracts,
    selectContractsLoading,
    selectActualLanguage,
    selectWhiteLabelingStyles
} from '../../store/selectors';
import Header from '../Header';
import Contract from './Contract/Contract';
import Loader from '../Common/Loader/Loader';
import withTheme from '../../hocs/withTheme';

import './ContractsList.scss';
import { setupCustomStyles } from '../../helpers/renderingHelpers';

const ContractsList = () => {
    const dispatch = useDispatch();
    const actualLanguage = useSelector(selectActualLanguage);
    useEffect(() => {
        if (actualLanguage) {
            dispatch(getContractsList());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualLanguage]);

    const contracts = useSelector(selectContracts);
    const loading = useSelector(selectContractsLoading);

    const whiteLabelingStyles = useSelector(selectWhiteLabelingStyles);
    setupCustomStyles(whiteLabelingStyles);
    const history = useHistory();

    const onMoreDetailsClick = (uuid) => {
        history.push(`/purchases/${uuid}`);
    };

    const onClaimClick = (uuid) => {
        history.push(`/purchases/${uuid}/claim`);
    };

    return (
        <div className="contracts-list-wrapper content">
            <Header
                stepper={false}
                stepsTitle={messages.YOUR_POLICY_PURCHASES} />
            <div className="contracts-list">
                { contracts.map(contract => (
                    <Contract
                        key={contract.uuid}
                        contract={contract}
                        onMoreDetailsClick={() => onMoreDetailsClick(contract.uuid)}
                        onClaimClick={() => onClaimClick(contract.uuid)}
                    />
                ))}
            </div>
            { loading && <Loader /> }
        </div>
    );
};

export default withTheme(ContractsList);
