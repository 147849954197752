import { fetchWrapper } from '../../issuer-application/helpers/fetchHelper';
import {
    TOGGLE_LOADING,
    SET_VERIFY_TOKEN,
    SET_ISSUER_PROFILE,
    SET_STYLES,
    SET_SETTINGS,
    SET_PAYMENT_PRODUCTS, SET_WHITE_LABELS
} from './issuerApp.actionTypes';
import { selectStyles, selectVerifyToken } from './issuerApp.selector';
import {
    fillInEmptyValues,
    paymentProductsMapper,
    whiteLabelsMapper
} from '../../issuer-application/helpers/dataHelpers';
import { dataURItoBlob, getFileExtension } from '../../helpers/dataHelpers';

export const toggleLoading = loading => ({ type: TOGGLE_LOADING, loading });

export const setVerifyToken = verifyToken => ({ type: SET_VERIFY_TOKEN, verifyToken });

export const setIssuerProfile = issuerProfile => ({ type: SET_ISSUER_PROFILE, issuerProfile });

export const setStyles = styles => ({ type: SET_STYLES, styles });

export const setSettings = settings => ({ type: SET_SETTINGS, settings });

export const setPaymentProducts = paymentProducts =>
    ({ type: SET_PAYMENT_PRODUCTS, paymentProducts });

export const setWhiteLabels = whiteLabels =>
    ({ type: SET_WHITE_LABELS, whiteLabels });

export const login = body => (dispatch) => {
    dispatch(toggleLoading(true));
    return fetchWrapper({
        url: '/api/v1/auth/jwt/',
        method: 'POST',
        body,
    })
        .then((data) => {
            localStorage.setItem('iss_token', data.access);
            return Promise.resolve();
        })
        .catch(() => {
            localStorage.removeItem('iss_token');
            return Promise.reject();
        })
        .finally(() => dispatch(toggleLoading(false)));
};

export const getCounter = () => fetchWrapper({
    url: '/api/v1/partner-portal/whatsapp-connections/inbound_counter/',
    method: 'GET',
})
    .then(({ incoming_messages_count }) => Promise.resolve(incoming_messages_count));

export const resetCounter = () => fetchWrapper({
    url: '/api/v1/partner-portal/whatsapp-connections/reset_inbound_counter/',
    method: 'POST',
    body: {}
})
    .then(() => Promise.resolve());

export const fetchInboxUrl = () => fetchWrapper({
    url: '/api/v1/partner-portal/staff_members/get_chat_login/',
    method: 'GET',
})
    .then(res => Promise.resolve(res));

export const fetchServicesOptions = () => fetchWrapper({
    url: '/api/v1/partner-portal/services-options/',
    method: 'GET',
})
    .then(response => Promise.resolve(response));


export const fetchBatches = () => fetchWrapper({
    url: '/api/v1/partner-portal/contact-batches/',
    method: 'GET',
})
    .then(response => Promise.resolve(response));

export const fetchContacts = ({
    search = undefined, users = undefined, offset = 0, limit = 50, sort = 'first_name', order = '-', batch
}) => fetchWrapper({
    url: `/api/v1/partner-portal/contact-datas/${batch ? `?batch=${batch}` : `?offset=${offset}&limit=${limit}${search ? `&search=${search}` : ''}${users ? `&users=${users}` : ''}${sort ? `&ordering=${order}${sort}` : ''}`}`,
    method: 'GET',
})
    .then(response => Promise.resolve(response.results));

export const saveContact = body => fetchWrapper({
    url: '/api/v1/partner-portal/contact-datas/',
    method: 'POST',
    body
})
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err));

export const uploadContacts = (file, options, async = true) => {
    const payload = new FormData();
    // URL.dataURItoBlob(file)
    payload.append('file', file);
    payload.append('save_option', options.check ? 'override' : 'skip');
    payload.append('delimiter', options.delimiter);
    payload.append('async_upload', async);
    payload.append('batch_name', `batch_${new Date().getTime()}`);
    return fetchWrapper({
        url: '/api/v1/partner-portal/contact-datas/batch_upload/',
        method: 'POST',
        body: payload
    }, true)
        .then(res => Promise.resolve(res[async ? 'info' : 'warning']))
        .catch(r => Promise.reject(r[0]));
};

export const editContact = body => fetchWrapper({
    url: `/api/v1/partner-portal/contact-datas/${body.uuid}/`,
    method: 'PATCH',
    body
})
    .then(r => Promise.resolve(r))
    .catch(err => Promise.reject(err));

export const deleteContact = uuid =>
    // debugger;
    fetchWrapper({
        url: `/api/v1/partner-portal/contact-datas/${uuid}/`,
        method: 'DELETE'
    });


//
export const staffAutocomplete = search => fetchWrapper({
    url: `/api/v1/autocomplete/issuer-users/${search ? `?search=${search}` : ''}`,
    method: 'GET',
})
    .then(response => Promise.resolve(response.results));

export const fetchStaff = ({ search, sort, order }) => fetchWrapper({
    url: `/api/v1/partner-portal/staff_members/?${search ? `search=${search}` : ''}${sort ? `ordering=${order}${sort}` : ''}`,
    method: 'GET',
})
    .then(response => Promise.resolve(response));

export const saveStaff = body => fetchWrapper({
    url: '/api/v1/partner-portal/staff_members/',
    method: 'POST',
    body
})
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err));

export const editStaff = body => fetchWrapper({
    url: `/api/v1/partner-portal/staff_members/${body.uuid}/`,
    method: 'PATCH',
    body
})
    .then(r => Promise.resolve(r))
    .catch(err => Promise.reject(err));

export const deleteStaff = uuid =>
    // debugger;
    fetchWrapper({
        url: `/api/v1/partner-portal/staff_members/${uuid}/`,
        method: 'DELETE'
    });


export const sendOffer = async (merchantData, customerData) => fetchWrapper({
    url: '/api/v1/partner-portal/issuer/leads/',
    method: 'POST',
    body: {
        advisor_services_option: merchantData,
        initial_contact_data: customerData
    }
});

export const fetchLeads = ({ users, offset = 0, limit = 10000, sort, order }) => fetchWrapper({
    url: `/api/v1/partner-portal/issuer/leads/?limit=${limit}&offset=${offset}${users ? `&users=${users}` : ''}${sort ? `&ordering=${order}${sort}` : ''}`,
    method: 'GET',
})
    .then(response => Promise.resolve(response.results));

export const fetchContracts = ({ key = undefined, order = '-' }) => fetchWrapper({
    url: `/api/v1/partner-portal/contracts/${key ? `?ordering=${order}${key}` : ''}`,
    method: 'GET',
})
    .then(response => Promise.resolve(response.results));

export const fetchStatuses = () => fetchWrapper({
    url: '/api/v1/partner-portal/whatsapp-connections/',
    method: 'GET',
})
    .then(response => Promise.resolve(response));

export const saveStatus = body => fetchWrapper({
    url: '/api/v1/partner-portal/whatsapp-connections/',
    method: 'POST',
    body
})
    .then(response => Promise.resolve(response));

export const resetPassword = body => (dispatch) => {
    localStorage.removeItem('iss_token');
    dispatch(toggleLoading(true));
    return fetchWrapper({
        url: '/api/v1/password_reset/',
        method: 'POST',
        body,
    })
        .finally(() => toggleLoading(false));
};

export const setNewPassword = ({ password }) => (dispatch, getState) => {
    const token = selectVerifyToken(getState());
    if (!token) {
        return Promise.reject();
    }
    dispatch(toggleLoading(true));
    return fetchWrapper({
        url: '/api/v1/password_reset/confirm/',
        method: 'POST',
        body: { password, token },
    })
        .finally(() => toggleLoading(false));
};

export const verifyPasswordToken = ({ token }) => (
    fetchWrapper({
        url: '/api/v1/password_reset/validate_token/',
        method: 'POST',
        body: { token },
    })
);

export const fetchOfferTemplate = () => fetchWrapper({
    url: '/api/v1/partner-portal/issuer/offer-template-mappings/',
});

export const fetchInsuranceProducts = () => fetchWrapper({
    url: '/api/v1/autocomplete/insurance-products/'
});

export const fetchInsuranceProductsByType = insuranceType => (
    fetchWrapper({
        url: '/api/v1/autocomplete/insurance-products/',
        method: 'GET',
        body: {
            insurance_type: insuranceType
        }
    })
);

export const fetchAvailableWhiteLabelling = () => fetchWrapper({
    url: '/api/v1/autocomplete/issuer-mobile-available-white-labeling/',
});

export const fetchAvailableContries = () => fetchWrapper({
    url: '/api/v1/autocomplete/issuer-countries/',
});

export const getIssuerProfile = () => (dispatch) => {
    // const history = useHistory();
    dispatch(toggleLoading(true));
    return fetchWrapper({
        url: '/api/v1/partner-portal/profile/',
        method: 'GET'
    })
        .then((profile) => {
            // eslint-disable-next-line camelcase
            dispatch(setIssuerProfile(profile));
            return Promise.resolve(profile.role);
        })
        .catch(() => { localStorage.removeItem('iss_token'); })
        .finally(() => toggleLoading(false));
};
export const fetchActiveWhiteLabeling = () => (dispatch, getState) => {
    dispatch(toggleLoading(true));
    const initialStyles = selectStyles(getState());
    return fetchWrapper({
        url: '/api/v1/partner-portal/active-white-labeling/',
        method: 'GET',
        ignoreAuth: true
    })
        .then((styles) => {
            dispatch(setStyles(fillInEmptyValues(styles, initialStyles)));
        })
        .finally(() => toggleLoading(false));
};

export const getSettings = () => (dispatch) => {
    dispatch(toggleLoading(true));
    return fetchWrapper({
        url: '/api/v1/partner-portal/issuer/profile/',
        method: 'GET'
    })
        .then((settings) => {
            dispatch(setSettings(settings));
        })
        .finally(() => toggleLoading(false));
};

export const updateSettings = payload => async (dispatch) => {
    dispatch(toggleLoading(true));
    try {
        await fetchWrapper({
            url: '/api/v1/partner-portal/issuer/profile/',
            method: 'PUT',
            body: payload
        });
    } finally {
        dispatch(toggleLoading(false));
    }
};

export const fetchInsurances = country => (
    fetchWrapper({
        url: '/api/v1/partner-portal/issuer/insurance-mappings/',
        method: 'GET',
        body: {
            country
        }
    })
);

export const fetchItemTypes = () => fetchWrapper({
    url: '/api/v1/autocomplete/insurable-item-types/',
});

export const fetchLanguages = () => fetchWrapper({
    url: '/api/v1/autocomplete/issuer-cultures/',
});

export const fetchTransactionTypes = () => fetchWrapper({
    url: '/api/v1/autocomplete/transaction-types/'
});

export const fetchPoSEnvironments = () => fetchWrapper({
    url: '/api/v1/autocomplete/pos-environment/'
});

export const fetchOfferedServices = () => fetchWrapper({
    url: '/api/v1/autocomplete/issuer-insurance-products/'
});

export const addNewOffering = offering => (
    fetchWrapper({
        url: '/api/v1/partner-portal/issuer/demographic-template-mappings/',
        method: 'POST',
        body: offering
    })
);

export const updateOffering = (offering, id, productId) => (
    fetchWrapper({
        url:
    `/api/v1/partner-portal/issuer/demographic-template-mappings/${id}/${productId ? `?payment_product=${productId}` : ''}`,
        method: 'PUT',
        body: offering
    })
);

export const deleteOffering = (id, productId) => (
    fetchWrapper({
        url: `/api/v1/partner-portal/issuer/demographic-template-mappings/${id}/${productId ? `?payment_product=${productId}` : ''}`,
        method: 'DELETE',
        body: {}
    })
);

export const saveInsuranceCatalogue = payload => async (dispatch) => {
    dispatch(toggleLoading(true));
    try {
        await fetchWrapper({
            url: '/api/v1/partner-portal/issuer/insurance-mappings/',
            method: 'POST',
            body: payload
        });
    } finally {
        dispatch(toggleLoading(false));
    }
};

export const fetchWhiteLabels = () => async (dispatch) => {
    dispatch(toggleLoading(true));
    return fetchWrapper({
        url: '/api/v1/partner-portal/issuer/white-labelings/',
        method: 'GET',
        body: {}
    })
        .then((settings) => {
            dispatch(setWhiteLabels(whiteLabelsMapper(settings)));
        })
        .finally(() => toggleLoading(false));
};

export const deleteWhiteLabel = id => async (dispatch) => {
    dispatch(toggleLoading(true));
    return fetchWrapper({
        url: `/api/v1/partner-portal/issuer/white-labelings/${id}/`,
        method: 'DELETE',
    })
        .finally(() => toggleLoading(false));
};

export const putWhiteLabel = ({ logo, ...whiteLabelWithoutLogo }, id) =>
    async (dispatch) => {
        dispatch(toggleLoading(true));
        const formData = new FormData();
        if (logo) {
            const [{ attachment, type }] = logo;
            const extension = getFileExtension(type);
            const file = dataURItoBlob(attachment, `attachment.${extension}`);
            formData.append('logo', file);
        }
        Object.keys(whiteLabelWithoutLogo).forEach((key) => {
            formData.append(key, whiteLabelWithoutLogo[key]);
        });
        return fetchWrapper({
            url: `/api/v1/partner-portal/issuer/white-labelings/${id}/`,
            method: 'PATCH',
            body: formData,
        }, true)
            .finally(() => toggleLoading(false));
    };

export const postWhiteLabel = ({ logo, ...whiteLabelWithoutLogo }) =>
    async (dispatch) => {
        dispatch(toggleLoading(true));
        const formData = new FormData();
        const [{ attachment, type }] = logo;
        const extension = getFileExtension(type);
        const file = dataURItoBlob(attachment, `attachment.${extension}`);
        formData.append('logo', file);
        Object.keys(whiteLabelWithoutLogo).forEach((key) => {
            formData.append(key, whiteLabelWithoutLogo[key]);
        });
        return fetchWrapper({
            url: '/api/v1/partner-portal/issuer/white-labelings/',
            method: 'POST',
            body: formData,
        }, true)
            .finally(() => toggleLoading(false));
    };

export const fetchPaymentProducts = () => async (dispatch) => {
    dispatch(toggleLoading(true));
    return fetchWrapper({
        url: '/api/v1/partner-portal/issuer/payment-products/',
        method: 'GET',
    })
        .then((products) => {
            dispatch(setPaymentProducts(paymentProductsMapper(products)));
        })
        .finally(() => toggleLoading(false));
};

export const postPaymentProduct = paymentProduct => async (dispatch) => {
    dispatch(toggleLoading(true));
    return fetchWrapper({
        url: '/api/v1/partner-portal/issuer/payment-products/',
        method: 'POST',
        body: paymentProduct,
    })
        .finally(() => toggleLoading(false));
};

export const patchPaymentProduct = (paymentProduct, id) => async (dispatch) => {
    dispatch(toggleLoading(true));
    return fetchWrapper({
        url: `/api/v1/partner-portal/issuer/payment-products/${id}/`,
        method: 'PATCH',
        body: paymentProduct,
    })
        .finally(() => toggleLoading(false));
};

export const deletePaymentProduct = id => async (dispatch) => {
    dispatch(toggleLoading(true));
    return fetchWrapper({
        url: `/api/v1/partner-portal/issuer/payment-products/${id}/`,
        method: 'DELETE',
    })
        .finally(() => toggleLoading(false));
};

export const fetchPaymentSchemes = () => fetchWrapper({
    url: '/api/v1/autocomplete/payment-schemes/',
});

export const fetchPaymentSchemeSubtypes = () => fetchWrapper({
    url: '/api/v1/autocomplete/payment-scheme-subtypes/',
});

export const fetchPaymentTypes = () => fetchWrapper({
    url: '/api/v1/autocomplete/payment-types/',
});

export const fetchPaymentProductOfferTemplate = payment_product => (
    fetchWrapper({
        url: '/api/v1/partner-portal/issuer/offer-template-mappings/',
        method: 'GET',
        body: {
            payment_product,
        }
    })
);

export const fetchProductPaymentInsurances = (country, payment_product) => (
    fetchWrapper({
        url: '/api/v1/partner-portal/issuer/insurance-mappings/',
        method: 'GET',
        body: {
            payment_product,
            country
        }
    })
);

export const fetchPaymentProductWhiteLabelling = payment_product => (
    fetchWrapper({
        url: '/api/v1/autocomplete/issuer-mobile-available-white-labeling/',
        method: 'GET',
        body: {
            payment_product,
        }
    })
);

export const updateOfferingGroup = (uuid, data) => (
    fetchWrapper({
        url: `/api/v1/partner-portal/issuer/offer-template-mappings/${uuid}/`,
        method: 'PUT',
        body: data
    })
);

export const addOfferingGroup = data => (
    fetchWrapper({
        url: '/api/v1/partner-portal/issuer/offer-template-mappings/',
        method: 'POST',
        body: data
    })
);

export const deleteOfferingGroup = (uuid, id) => (
    fetchWrapper({
        url: `/api/v1/partner-portal/issuer/offer-template-mappings/${uuid}/${id ? `?payment_product=${id}` : ''}`,
        method: 'DELETE'
    })
);
