import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { messages, onSetLanguage } from '../../../constants/messages';
import { login } from '../../../store/issuerApp/issuerApp.actions';

import LoginScreen from '../../containers/LoginScreen';

import './Login.scss';
import Input from '../Common/Input/Input';

const useStyles = makeStyles({
    loginInput: {
        width: '100%',
    },
    loginSubmitBtn: {
        color: '#F8F9FF',
        textTransform: 'capitalize',
        padding: '15px',
        borderRadius: 10,
    }
});

const Login = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    // const [isChecked, setIsChecked] = useState(false);
    const [data, setData] = useState({
        email: '',
        password: '',
    });
    const [error, setError] = useState('');
    const [validationErrors, setValidationErrors] = useState({
        email: '',
        password: '',
    });

    useEffect(() => {
        onSetLanguage(navigator.language || navigator.userLanguage);
        if (localStorage.getItem('iss_token')) {
            history.push('/choice');
        }
    }, []);

    /* const handleCheck = () => {
        setIsChecked(prev => !prev);
    }; */

    const setValidationError = (field, message) => {
        setValidationErrors(prev => ({
            ...prev,
            [field]: message,
        }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValidationError(name, '');
        setError('');
        setData({
            ...data,
            [name]: value,
        });
    };

    const validate = () => {
        for (let i = 0; i < Object.entries(data).length; i += 1) {
            const [name, value] = Object.entries(data)[i];
            if (!value) {
                setValidationError(name, messages.EMPTY_FIELD_ERR);
                return false;
            }
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationSucceed = validate();
        if (!validationSucceed) return;
        dispatch(login(data))
            .then(() => { history.push('/choice'); })
            .catch(() => setError(messages.NO_ACCOUNT_ERR));
    };

    const onForgotPassword = () => {
        history.push('/forgot-password');
    };

    return (
        <LoginScreen>
            <form onSubmit={handleSubmit} className="login__form">
                <Input
                    label={messages.EMAIL_ADDRESS}
                    type="email"
                    variant="outlined"
                    name="email"
                    value={data.email}
                    handleChange={handleChange}
                    className={classes.loginInput}
                    err={validationErrors.email}
                />
                <div className="login__form-err-container">
                    <Input
                        isPassword
                        label={messages.PASSWORD}
                        type="password"
                        variant="outlined"
                        name="password"
                        value={data.password}
                        handleChange={handleChange}
                        className={classes.loginInput}
                        err={validationErrors.password}
                    />
                    <div className="err-container__err">{error}</div>
                </div>
                <div className="form__control-panel">
                    {/* <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                className={classes.loginCheckbox}
                                checked={isChecked}
                                onChange={handleCheck}
                                name="Checkbox"
                            />
                        }
                        label="Remember me"
                    /> */}
                    <a className="control-panel__forgot-password" onClick={onForgotPassword} href="#">
                        {messages.FORGOT_PASSWORD}
                    </a>
                </div>
                <Button
                    className={classes.loginSubmitBtn}
                    variant="contained"
                    type="submit"
                    color="primary"
                >
                    {messages.LOGIN}
                </Button>
            </form>
        </LoginScreen>
    );
};
export default Login;
