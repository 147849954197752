// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../dist/images/Unselect.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../dist/images/Select.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ":root{--colour_titles: #05264D;--primary: #00008F;--primary-opacity: #9a9ad4;--primary-light: #d0d0e7;--primaryhover: #000056;--font: 'Rubik';--bgcolor: #F8F9FF;--primary-light-opacity: (208, 208, 231, 0.5);--header-bgcolor: #ffffff;--input-bgcolor: #EDEDF7}.language-option{display:flex;align-items:center;position:relative;background-color:var(--header-bgcolor);border-radius:11px;margin-bottom:-40px}.language-option .lang-btn-wrap{padding-right:7.5px;width:24px;text-align:center;padding:12px 4px 12px 11px}.language-option .lang-btn-wrap .lang-btn-name{color:var(--colour_titles);font-size:14px;line-height:16px}.language-option .lang-arrow{height:4px;width:8.37px;background-position:center;background-repeat:no-repeat;background-size:contain;margin-right:9px}.language-option .option-wrapper{padding:5px 10px;border-radius:12px;background-color:#fff;box-shadow:0 0 11px 0 rgba(0,0,0,0.04);position:absolute;display:block;right:61px;top:-10px;z-index:4}.language-option .lang-elem-wrap{display:flex;padding:5.5px 0;align-items:center}.language-option .lang-elem-wrap .lang-btn-name{color:var(--colour_titles);font-size:12px;line-height:16px;padding-left:4px;min-width:33px}.language-option .lang-elem-wrap .lang-btn-indicator{height:14px;width:14px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;background-size:16px}.language-option .lang-elem-wrap .lang-btn-indicator.active-lang{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat center;background-size:16px}\n", ""]);
// Exports
module.exports = exports;
