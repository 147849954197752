import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    fetchAvailableWhiteLabelling,
    fetchPaymentProductWhiteLabelling,
    patchPaymentProduct,
    updateSettings
} from '../../../../store/issuerApp/issuerApp.actions';
import Dropdown from '../../Common/Dropdown/Dropdown';
import { selectPaymentProducts, selectSettings } from '../../../../store/issuerApp/issuerApp.selector';

import './OtherSettings.scss';
import CustomizedSwitch from '../../Common/Switch/Switch';
import { messages } from '../../../../constants/messages';

const settingsMapper = settingOptions => settingOptions.results.map(({ pk, value }) => ({
    pk,
    value,
}));

const OtherSettings = ({ productSettings, checked, setChecked }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [settingOption, setSettingOption] = useState('');
    const [options, setOptions] = useState([]);
    const [id, setId] = useState(null);
    const settings = useSelector(selectSettings);
    const paymentProducts = useSelector(selectPaymentProducts);


    useEffect(() => {
        if (productSettings) {
            const currentId = pathname.split('/').pop();
            setId(currentId);
            fetchPaymentProductWhiteLabelling(currentId)
                .then((response) => {
                    setOptions(settingsMapper(response));
                });
        } else {
            fetchAvailableWhiteLabelling()
                .then((response) => {
                    setOptions(settingsMapper(response));
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (settings) {
            if (productSettings && paymentProducts.length && id) {
                const { whiteLabeling } =
                    paymentProducts.find(({ uuid }) => uuid === id);
                if (whiteLabeling) {
                    setSettingOption(whiteLabeling.uuid);
                }
            } else {
                const { default_white_labeling: { uuid } } = settings;
                setSettingOption(uuid);
            }
        }
    }, [settings, paymentProducts, id]);

    const setCurrentOption = async (event) => {
        const { value } = event.target;

        if (productSettings) {
            await dispatch(patchPaymentProduct({ white_labeling: value }, id));
        } else {
            await dispatch(updateSettings({ default_white_labeling: value }));
        }
        setSettingOption(value);
    };

    return (
        settings && <div className="container other-settings">
            {
                productSettings ?
                    <header className="other-settings__header--product-settings">
                        <div className="other-settings__header-title">{messages.OTHER_SETTINGS}</div>
                        <CustomizedSwitch
                            checked={checked}
                            handleChange={setChecked}
                            label={messages.USE_GENERAL_SETTINGS}
                            name="isOtherSettingsOpen"
                        />
                    </header>
                    :
                    <header className="other-settings__header">
                        {messages.OTHER_SETTINGS}
                    </header>
            }
            {(!checked || !productSettings)
            &&
            <main className="other-settings__table">
                <div>
                    {messages.CI_CD_SETTING}
                </div>
                <div>
                    <Dropdown
                        value={settingOption}
                        handleChange={setCurrentOption}
                        name="country"
                        options={options}
                        color="light"
                    />
                </div>
            </main>}
        </div>
    );
};
export default OtherSettings;
