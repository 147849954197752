import React, { useRef/* useEffect, useCallback */ } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import LanguageOption from './LanguageOption/LanguageOption';
import Stepper from './Common/Stepper/Stepper';
import '../styles/Header.scss';
import { selectWhiteLabelingLogo, selectHeaderOption } from '../store/selectors';
// import { detectMobile } from '../helpers/renderingHelpers';

const { pathname } = window.location;

// TODO: to refactor

/* const touching = false;
let startY;
const prevTop = 0; */
// const topBeforeFocus = 0;

const Header = (props) => {
    const {
        steps,
        onStepper,
        stepsTitle,
        singleItemType,
        stepper,
        showLanguage,
        className,
        children
    } = props;

    const logo = useSelector(selectWhiteLabelingLogo);
    const isStickyHeader = useSelector(selectHeaderOption);
    /*  const [windowParams, setWindowParams] =
        useState({ initialHeight: window.innerHeight }); */

    const header = useRef();

    /* useEffect(() => {
        prevTop = 0;
        const content = header.current.closest('.content');
        if (content) {
            content.style.paddingTop = '0px';
            content.style.marginTop = '0px';
        }
    }, []);

    const fixHeader = (content, clientY, dist) => {
        const { current } = header;
        if (!current.classList.contains('resized')) {
            if (clientY < -60) {
                if (!current.classList.contains('sticky')) {
                    current.classList.add('sticky');
                    current.style.width = '100vw';
                    content.classList.add('scrollable');
                    const height = current.offsetHeight;
                    content.style.paddingTop = `${height + 20}px`;
                }
            } else if (current.classList.contains('sticky') && dist > 0) {
                content.style.paddingTop = '0px';
                current.classList.remove('sticky');
                content.classList.remove('scrollable');
            }
        }
    }; */

    /*  const scrollContent = useCallback((e) => {
        e.preventDefault();
        const { clientY } = e.changedTouches[0];
        const dist = clientY - startY;

        const content = header.current.closest('.content');

        if (!content) {
            return;
        }

        // const { height } = content.getBoundingClientRect();
        const rootHeight = document.getElementById('root').clientHeight;
        const border = +(dist < 0 && -rootHeight + content.scrollHeight);

        prevTop = Math.sign(dist) * Math.min(Math.sign(dist) * (dist + prevTop), Math.abs(border));

        content.style.marginTop = `${prevTop}px`;
        fixHeader(content, prevTop, dist);
    });

    const detectTouchStart = useCallback((e) => {
        startY = e.changedTouches[0] && e.changedTouches[0].clientY;
    }, []);

    const detectTouchMove = useCallback((e) => {
        touching = true;
        if (!e.target.className.includes('select__option')) {
            scrollContent(e);
        }
    }, []);

    const detectTouchEnd = useCallback(() => {
        if (touching) {
            touching = !touching;
        }
    }, []); */

    /*  const detectInputFocus = useCallback((e) => {
        const { target } = e;
         *//* const main = target.closest('.main');
        const { current } = header;
        console.log(`focused ${target.className}`);

        if (main) {
            if ((target.nodeName === 'INPUT' && !target.offsetParent.className.includes('select'))
            || target.className.indexOf('Stripe') > -1) {
                if (current) {
                    current.classList.add('resized');
                }
                if (!main.classList.contains('resized')) {
                    main.classList.add('resized');
                    main.style.height = `${main.offsetHeight + 84}px`;
                }
                return;
            }
            if (main.className.indexOf('resized') > -1) {
                if (current) {
                    current.classList.remove('resized');
                }
                main.classList.remove('resized');
                main.style.height = `${main.offsetHeight - 84}px`;
                 *//*  *//* const content = header.current.closest('.content');
                content.style.marginTop =
                `${parseInt(content.style.marginTop, 10) + 84}px`; *//*  *//*
            }
        } *//*

         *//* const { current } = header;
        if (!target.offsetParent.className.includes('select')) {
            if (current) {
                current.classList.add('resized');
            }
            const main = target.closest('.main');
            main.style.height = `${main.offsetHeight + 84}px`;
        } *//*
        const { current } = header;
        if (current) {
            topBeforeFocus = prevTop;
            const content = current.closest('.content');
            current.classList.add('resized');
            current.style.width = '100vw';
            const padding = current.offsetHeight + 20;
            content.style.paddingTop = `${padding}px`;
            const { top } = target.getBoundingClientRect();
            prevTop = prevTop - top + padding + 20;
            content.style.marginTop = `${prevTop}px`;
        }
    }, []); */

    /* const detectInputBlur = useCallback(() => {
        prevTop = topBeforeFocus;
        header.current.closest('.content').style.marginTop = `${prevTop}px`;
         *//* const { target } = e;
        const { current } = header;
        if (!target.offsetParent.className.includes('select')) {
            // const content = current.closest('.content');
            if (current) {
                current.classList.remove('resized');
            }
            const main = target.closest('.main');
            main.style.height = `${main.offsetHeight - 84}px`;
        } *//*
    }, []); */

    /* useEffect(() => {
        if (detectMobile()) {
            document.addEventListener('touchend', detectTouchEnd);
        }
        return () => document.removeEventListener('touchend', detectTouchEnd);
    }, [detectTouchEnd]);

    useEffect(() => {
        if (detectMobile()) {
            document.addEventListener('touchstart', detectTouchStart);
        }
        return () => document.removeEventListener('touchstart', detectTouchStart);
    }, [detectTouchStart]);

    useEffect(() => {
        if (detectMobile()) {
            document.addEventListener('touchmove', detectTouchMove, { passive: false });
        }
        return () => document.removeEventListener('touchmove', detectTouchMove);
    }, [detectTouchMove]); */

    /* useEffect(() => {
        const selectors = [...document.querySelectorAll('input')]
            .filter(({ id }) => !id.includes('select'));
        if (detectMobile()) {
            selectors.map(input => input.addEventListener('focus', detectInputFocus, true));
            // document.body.addEventListener('click', detectInputFocus);
        }
        return () => {
            selectors.map(input => input.removeEventListener('focus', detectInputFocus));
            // document.body.removeEventListener('click', detectInputFocus);
        };
    }, [detectInputFocus]);

    useEffect(() => {
        const selectors = [...document.querySelectorAll('input')]
            .filter(({ id }) => !id.includes('select'));
        if (detectMobile()) {
            selectors
                .map(input => input.addEventListener('blur', detectInputBlur, true));
        }
        return () => selectors
            .map(input => input.removeEventListener('blur', detectInputBlur));
    }, [detectInputBlur]); */

    /* const detectStripeClick = useCallback((e) => {
         *//* const el = document.getElementsByClassName('scrollable')[0];
        if (el && e.target.parentElement && e.target.parentElement.className.includes('footer')) {
            el.style.paddingTop = '0px';
            el.style.marginTop = '0px';
        } *//*
        if (e.target.classList.contains('StripeElement')) {
            e.stopPropagation();
            detectInputFocus(e);
        }
    }, []); */

    /* useEffect(() => {
        if (detectMobile()) {
            window.addEventListener('click', detectStripeClick, true);
        }

        window.removeEventListener('click', detectStripeClick);
    }, [detectStripeClick]); */

    /* const handleOpenKeyboard = useCallback((e) => {
        e.preventDefault();

        setWindowParams(prev => ({
            ...prev,
            currentHeight: window.innerHeight,
        }));
    }); */

    /* useEffect(() => {
        if (windowParams.currentHeight === windowParams.initialHeight) {
            // keyboard is closed
            prevTop = topBeforeFocus;
            header.current.closest('.content').style.marginTop = `${prevTop}px`;
        }

        document.getElementById('root').style.height = `${windowParams.currentHeight}px`;
    }, [windowParams]); */

    /* useEffect(() => {
        if (detectMobile()) {
            window.addEventListener('resize', handleOpenKeyboard, { passive: false });
        }
        return () => window.removeEventListener('resize', handleOpenKeyboard);
    }, [handleOpenKeyboard]); */

    return (
        <div className="header-wrapper">
            {isStickyHeader && <div
                className={`header-wrap sticky-header ${className || ''}`}>
                {stepper && !window.location.hash.includes('coverage') && <Stepper
                    onStepper={onStepper}
                    singleItemType={singleItemType} />
                }
                <div className="header-title">
                    {stepsTitle}
                </div>
                { children }
            </div>}
            <div
                ref={header}
                style={{ visibility: isStickyHeader ? 'hidden' : 'visible' }}
                className={`header-wrap main-header ${className || ''}`}>
                {showLanguage && <div className="language-wrap">
                    <LanguageOption />
                </div>}
                { !!logo && <img className="logo" alt="logo" src={logo} /> }
                {stepper && !window.location.hash.includes('coverage') && <Stepper
                    onStepper={onStepper}
                    stepsArr={steps}
                    singleItemType={singleItemType} />
                }
                <div className="header-title">
                    {stepsTitle}
                </div>
                { children }
            </div>
        </div>
    );
};

Header.propTypes = {
    stepper: PropTypes.bool,
    showLanguage: PropTypes.bool
};
Header.defaultProps = {
    stepper: !(pathname.includes('/insurances') || pathname.includes('/contracts')),
    showLanguage: true
};

export default Header;
