import React from 'react';
import { useSelector/* , useDispatch */ } from 'react-redux';
import {
    selectWhiteLabelingPrimaryColor,
} from '../../../../store/selectors';
import calculateColorWithOpacity from '../../../../helpers/calculateColor';

const Price = ({ product }) => {
    const whiteLabelingPrimaryColor = useSelector(selectWhiteLabelingPrimaryColor);
    const { currency_symbol, price, branding_name, payment_price } = product;

    const value = (+payment_price && payment_price) || price;
    return <div className="amount" style={{ borderBottom: `1px solid ${calculateColorWithOpacity(whiteLabelingPrimaryColor, '0.3')}` }}>
        {currency_symbol}
        <div className="product-price-wrapper">
            <div className="product-price">
                {value.split('.')[0]}
            </div>
            <div className="product-price">
                {value.split('.')[1]}
            </div>
        </div>
        <p className="product-name">{branding_name}</p>
    </div>;
};

export default Price;
