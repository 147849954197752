import React, { useEffect, useState } from 'react';
import InputWrap from '../../Common/Input/InputWrap';

const Input = ({
    errorCallback, attribute, data, config = {}, handleInputChange, label, disabled }) => {
    const [error, setError] = useState(null);

    const handleChange = (v) => {
        setError(config.validator(v).code);
        handleInputChange(v, attribute);
    };

    useEffect(() => {
        if (errorCallback) {
            errorCallback({ [attribute]: error === null ? !!data : !error });
        }
    }, [error, data]);

    return <><p className="input-lable">{label}</p>
        <InputWrap
            disabled={disabled}
            keyRestrictions={attribute === 'phone_number'}
            className="discovered-input"
            /* type={config.type ? config.type : 'text'} */
            value={data}
            onChange={handleChange}
            error={error}
            lable="" /></>;
};

export default Input;
