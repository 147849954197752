import React from 'react';
import ReactSelect from 'react-select';
import { useSelector } from 'react-redux';
import './Select.scss';

import { selectWhiteLabelingStyles } from '../../../store/selectors';
import { getInputBackground } from '../../../helpers/calculateColor';

const Select = ({ label = '', value = '', options = [], onChange, isSearchable = false,
    isClearable, isLoading = false, isCoveragePage = false, standalone = true }) => {
    const { whiteLabelingInputBackground, whiteLabelingBackgroundColor } =
        useSelector(selectWhiteLabelingStyles);
    const customStyles = {
        indicatorSeparator: () => ({
            width: 0,

        }),
        indicatorsContainer: provided => ({
            ...provided,
            visibility: isLoading ? 'hidden' : 'visible'
        }),
        valueContainer: provided => ({
            ...provided,
            overflow: 'unset',
        }),
        menuList: base => ({
            ...base,
            maxHeight: '150px'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            backgroundColor: whiteLabelingBackgroundColor,
            transformOrigin: 'top left',
            transform: state.isFocused ? 'translate(5px, -10px) scale(0.9) translate3d(0, 0, 0)' : 'translate(15px, 15px) scale(1) translate3d(0, 0, 0)',
            position: 'absolute',
            top: '-8px',
            left: '-15px',
            transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            padding: state.isFocused ? '0 15px 0 10px' : '0 0 1px 0',
            display: 'block !important',
            border: 0,
        }),
        singleValue: provided => ({
            ...provided,
            gridArea: '1/1/1/1'
        }),
        multiValueLabel: provided => ({
            ...provided,
            padding: '10px 20px !important',
            fontSize: 16
        }),
        option: provided => ({
            ...provided,
            padding: 15,
        }),
        control: () => ({
            fontSize: 16,
            backgroundColor: isCoveragePage ?
                whiteLabelingBackgroundColor : getInputBackground(whiteLabelingInputBackground),
            width: '100%',
            display: 'flex',
            padding: 7,
            boxSizing: 'border-box',
            borderRadius: isCoveragePage ? 5 : 10,
            borderTopRightRadius: standalone ? (isCoveragePage && 5) || 10 : 0,
            borderBottomRightRadius: standalone ? (isCoveragePage && 5) || 10 : 0,
            border: isCoveragePage ? 'initial' : 'none',
            boxShadow: isCoveragePage ? 'none' : '2px 4px 11px 0 rgba(0, 0, 0, 0.08)'
        }),
        menu: provided => ({
            ...provided,
            zIndex: 3,
        }),
    };

    const isObjEmpty = obj => !Object.keys(obj).length;

    return (
        <div className="select">
            {!isObjEmpty(value) &&
            <span className="select__label">
                {label}
            </span>}
            <ReactSelect
                classNamePrefix="select"
                placeholder={label}
                styles={customStyles}
                value={isObjEmpty(value) ? '' : value}
                options={options}
                onChange={onChange}
                isMulti={false}
                isClearable={isClearable}
                isSearchable={isSearchable}
            />
        </div>
    );
};

export default Select;
