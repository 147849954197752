import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../Common/Button/Button';
import { messages } from '../../../constants/messages';
import WhiteLabelingTable from './WhiteLabelingTable/WhiteLabelingTable';
import WhiteLabelingSettings from '../../containers/WhiteLabelingSettings';

import './WhiteLabeling.scss';


const WhiteLabeling = () => {
    const history = useHistory();

    const addWhiteLabel = () => history.push('./app/white-labeling/create');

    return (
        <WhiteLabelingSettings>
            <div className="container white-labels-section">
                <header className="white-labels-section__header">
                    <div className="white-labels-section__header-title">{messages.WHITE_LABELS}</div>
                    <Button
                        onClick={addWhiteLabel}
                        size="large"
                        variant="contained"
                        type="button"
                    >
                        {messages.ADD_WHITE_LABEL}
                    </Button>
                </header>
                <main>
                    <WhiteLabelingTable />
                </main>
            </div>
        </WhiteLabelingSettings>
    );
};

export default WhiteLabeling;
