/* eslint-disable import/prefer-default-export */
export const merchantGroupTitles = [{
    value: 'group_name',
    label: 'Merchant Group Name'
}, {
    value: 'category',
    label: 'Merchant Group Category'
}, {
    value: 'group_uuid',
    label: 'Merchant Group UUID'
}];

export const LeadReactionTitles = [{
    value: 'insurance',
    label: 'Offered Insurance'
}, {
    value: 'items',
    label: 'Covered purchased items'
}];

export const MerchantIdentificationTitles = [{
    value: 'payment_scheme',
    label: 'Payment Scheme'
}, {
    value: 'merchant_descriptor',
    label: 'Merchant Descriptor'
}, {
    value: 'merchant_id',
    label: 'Merchant ID'
}, {
    value: 'merchant_name',
    label: 'Merchant Name'
}, {
    value: 'merchant_address',
    label: 'Merchant Address'
}];

export const profileSectionTitles = [{
    label: 'Merchant Name',
    value: 'name'
}, {
    label: 'Merchant\'s Group Name',
    value: 'group_name'
}, {
    label: 'Classification',
    value: 'category'
}, {
    label: 'General Description',
    value: 'description'
}, {
    label: 'Address',
    value: 'address'
}, {
    label: 'Phone Number',
    value: 'phone_number'
}, {
    label: 'Website',
    value: 'website'
}, {
    label: 'Facebook Site',
    value: 'facebook_profile'
}];

export const groupProfileSectionTitles = [{
    label: 'Merchant Group Name',
    value: 'group_name'
}, {
    label: 'Country',
    value: 'country'
}, {
    label: 'Domain',
    value: 'domain'
}];

export const itemsSoldSectionTitles = [{
    value: 'name',
    label: 'Items Sold'
}, {
    value: 'percents_of_total_turnover',
    label: '% of Total Turnover'
}, {
    value: 'price',
    currency: true,
    label: 'Price Range'
}];

export const merchantTitles = [{
    value: 'group_name',
    label: 'Group Name'
}, {
    value: 'name',
    label: 'Name'
}, {
    value: 'category',
    label: 'Category'
}, {
    value: 'city',
    label: 'City'
}, {
    value: 'address',
    label: 'Address'
}, {
    value: 'uuid',
    label: 'UUID'
}];

export const shortenMerchantTitles = [{
    value: 'name',
    label: 'Name'
}, {
    value: 'city',
    label: 'City'
}, {
    value: 'address',
    label: 'Address'
}, {
    value: 'uuid',
    label: 'UUID'
}];

export const merchantListTabs = [{
    label: 'Merchant Groups',
}, {
    label: 'Merchants',
}];

export const merchantProfileTabs = [{
    label: 'Profile & Product'
}, {
    label: 'Value addes services at PoS'
}, {
    label: 'Merchant Identification'
}];

export const groupProfileTabs = [{
    label: 'Group Identification'
}, {
    label: 'Merchants Children'
}];

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const colors = ['rgba(5, 178, 202, 0.3)', 'rgb(199, 199, 199)'];

export const paymentIdentificationTitles = [{
    label: 'Payment Amount',
    value: 'payment_amount',
    currency: true
}, {
    label: 'Trigger',
    value: 'trigger'
}];
