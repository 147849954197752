import { tokenVerified } from './amplitude';
// import amplitude from 'amplitude-js';
import history from './history';
// import { messages } from '../constants/messages';

const backendAPI = process.env.REACT_APP_API_URL;

export function fetchWrapper(
  { url, method = 'GET', body = null, headers = {}, sideRequest = false },
  notStringify = false
) {
    const requestParams = {
        method,
        headers
    };
    if (method !== 'GET' && body) {
        if (notStringify) {
            requestParams.body = body;
        } else if (!sideRequest) {
            requestParams.body = JSON.stringify(body);
            headers['Content-Type'] = 'application/json';
        } else {
            const formData = new URLSearchParams();
            Object.entries(body).forEach(([key, value]) => formData.append(key, value));
            requestParams.body = formData;
            // requestParams.mode = 'no-cors';
        }
    }

    const authToken = localStorage.getItem('access_token');
    if (authToken && !sideRequest) {
        headers.Authorization = `JWT ${authToken}`;
    }
    const endpoint = sideRequest ? url : `${backendAPI}${url}`;
    return fetch(endpoint, requestParams)
        .then((response) => {
            if (!response) {
                return Promise.reject(null);
            }
            if (response.status >= 200 && response.status < 400) {
                try {
                    return sideRequest ? response.text() : response.json()
                        .catch(() => Promise.resolve({}));
                } catch (err) {
                    return Promise.resolve({});
                }
            }
            if (response.status === 401) {
                history.push('/not-valid');
            }
            if (response.status === 412) {
                return response.json()
                    .then(err => Promise.reject({ status: response.status, error: err.errors }));
            }
            return response.json()
                .then(err => Promise.reject(err));
        }).catch((error) => {
            if (error.status === 401) {
                history.push('/not-valid');
            }
            return Promise.reject(error);
        });
}

export function getToken(token) {
    localStorage.clear();
    return fetchWrapper({
        url: '/api/v1/auth/customer/',
        method: 'POST',
        body: { token }
    }).then((data) => {
        tokenVerified();
        localStorage.setItem('transaction_id', token);
        localStorage.setItem('mode', 'push');
        localStorage.setItem('access_token', data.token);
        return Promise.resolve();
    }).catch(() =>
        Promise.resolve()
        // history.replace('/not-valid');
    );
}

const parseLink = () => {
    const { pathname } = window.location;
    // const matches = /((\w{4,12}-?)){5}/.exec(url)[0];
    localStorage.removeItem('access_token');
    localStorage.removeItem('transaction_id');
    return getToken(pathname.replace('/seguros/', ''));
};

export const parseInsuranceLink = () => {
    /*  const androidApp = window.AlyynAndroidInterface;
    if (androidApp) {
        androidApp.resetInsurancePullToken();
    } */
    parseLink().then(() => {
        history.replace('/');
    });
};

export const parseContractsLink = () => {
    const url = window.location.pathname;
    const token = url.replace('/contracts/', '');
    tokenVerified();
    localStorage.setItem('access_token', token);
    history.replace('/purchases');
};

export const fetchReceipt = ({ uuid, language }) => {
    const authToken = localStorage.getItem('access_token');
    const headers = {
        'accept-language': language
    };
    if (authToken) {
        headers.Authorization = `JWT ${authToken}`;
    }
    const url = `/api/v1/customers/contracts/${uuid}/receipts/`;
    const requestParams = {
        method: 'GET',
        headers
    };
    return fetch(`${backendAPI}${url}`, requestParams)
        .then((response) => {
            if (!response) {
                return Promise.reject(null);
            }
            if (response.status >= 200 && response.status < 400) {
                try {
                    const type = response.headers.get('content-type');
                    const filename = response.headers.get('content-disposition');
                    return response.arrayBuffer()
                        .then(blob => ({
                            blob, type, filename
                        }));
                } catch (err) {
                    return Promise.resolve(null);
                }
            }
            return response.json()
                .then(err => Promise.reject(err));
        });
};
