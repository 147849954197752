import React from 'react';
import { messages } from '../../../../constants/messages';

const ProductIdBox = ({ id }) => (
    <div className="container container--color-primary-light">
        <div className="product-id-box__content">
            {messages.PRODUCT_ID}: {id}
        </div>
    </div>
);
export default ProductIdBox;
