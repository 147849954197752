import React, { useEffect, useState } from 'react';
import { fetchContracts } from '../../../../../store/issuerApp/issuerApp.actions';
import Table from '../../Common/Table/Table';
import { contractsTableItems } from '../../../../constants';
import { messages } from '../../../../../constants/messages';
import '../details.scss';

const Contracts = ({ active, reload }) => {
    const [data, setData] = useState(null);
    const [contracts, setContracts] = useState(null);
    const [result, setResult] = useState([]);
    const [search, setSearch] = useState('');
    const [sortSettings, setSortSettings] = useState({ key: null, order: undefined });

    useEffect(() => {
        fetchContracts({})
            .then((r) => {
                setResult(r);
            });
    }, []);

    useEffect(() => {
        if (reload) {
            fetchContracts((sortSettings.key && sortSettings) || {})
                .then((r) => {
                    setData(r);
                    reload();
                })
                .catch(() => { reload(); });
        }
    }, [reload]);

    useEffect(() => {
        const tmp = result.map(row => ({
            ...row,
            insurance_product_name: row.product_brand_name || row.insurance_product_name,
            coverage_start_date: new Date(row.coverage_start_date).toLocaleString('de'),
            premium: `${row.price} ${row.payment_scheme}` }));
        setContracts(tmp);
    }, [result]);

    useEffect(() => {
        setData(contracts);
    }, [contracts]);

    const onChange = (v) => {
        setSearch(v);
    };

    const onSort = (key) => {
        setSortSettings(prev => ({ key, order: prev.key === key && prev.order === '-' ? '+' : '-' }));
    };

    useEffect(() => {
        if (sortSettings.key) {
            fetchContracts(sortSettings)
                .then((r) => {
                    setResult(r);
                });
        }
    }, [sortSettings]);

    useEffect(() => {
        setData(data && contracts.filter(({ first_name, last_name }) =>
            (first_name || '').includes(search) || (last_name || '').includes(search)));
    }, [search]);

    return (data && <div className={`details-wrapper ${active && 'active'}`}>
        <input placeholder={`${messages.SEARCH}...`} className="search" value={search} onChange={e => onChange(e.target.value)} />
        <Table
            cn="certificates-table"
            isEditable={false}
            onSort={onSort}
            data={data}
            fields={Object.keys(contractsTableItems(messages))}
            header={Object.values(contractsTableItems(messages))} /></div>)
        || null;
};

export default Contracts;
