import React, { /* useRef, */ useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLanguage } from '../../../helpers/customHooks';

import Header from '../../Header';
import Footer from '../../Common/Footer/Footer';
import InputWrap from '../../Common/Input/InputWrap';
import InputDatepicker from '../../Common/InputDatepicker/InputDatepicker';
// import CustomDatePicker from '../../AirlineInsurance/CustomDatePicker';
import Hint from '../ItemAttributes/Hint';
import HintModal from '../HintModal/HintModal';
import { messages } from '../../../constants/messages';
import trash from '../../../dist/images/trash.svg';
import calendar from '../../../dist/images/Calendar.svg';
import pin from '../../../dist/images/pin.svg';

import { setCurrentStepNumber } from '../../../store/common/common.actions';
import { nextPageNavigation, changeAttribute } from '../../../helpers/amplitude';
// import { setStep } from '../../../store/productInsurance/productInsurance.actions';
// import { selectIndex, selectBirthdays } from '../../../store/common/common.selector';
import { selectItemTypeAttributes, selectProfile } from '../../../store/selectors';
import { selectCurrentStepNumber, selectBreadcrumbs } from '../../../store/common/common.selector';
import { saveItemTypeAttrubutes, setItemTypeAttributeValue, setStep } from '../../../store/productInsurance/productInsurance.actions';

// import { generateArray } from '../../../helpers/wizardHelpers';

import '../ItemAttributes/ItemAttributes.scss';
import './styles.scss';

const HelvetiaPreAttributes = () => {
    useLanguage();
    const { lead_uuid, tags } = useSelector(selectProfile);
    const dispatch = useDispatch();
    const attributes = useSelector(selectItemTypeAttributes);
    const currentStepNumber = useSelector(selectCurrentStepNumber);
    const breadcrumbs = useSelector(selectBreadcrumbs);
    const preAttributes = attributes.filter(attr => attr.position === 'PRE_OFFER');
    const [error, setError] = useState(null);
    const [isValid, setIsValid] = useState({});

    const dateAttributes = preAttributes.filter(({ key }) => key.includes('person')).sort();
    const birthdates = dateAttributes.filter(({ value }) => value !== '');

    const codeAttribute = preAttributes.filter(({ key }) => key.includes('postal_code'))[0];
    const [hintModalState, setHintModalState] = useState({});

    const onCodeValidate = () => {
        if (!codeAttribute.value) {
            setError(messages.MANDATORY_FIELD);
            return;
        }
        setError(null);
    };

    const onBack = () => {
        const { step } = breadcrumbs[currentStepNumber - 1];
        dispatch(setCurrentStepNumber(currentStepNumber - 1));
        dispatch(setStep(step));
    };

    const onNextStep = () => {
        if (!error && Object.values(isValid).every(item => item)) {
            dispatch(saveItemTypeAttrubutes('PRE'))
                .then(() => {
                    const { step } = breadcrumbs[currentStepNumber + 1];
                    nextPageNavigation({
                        uuid: lead_uuid,
                        page: step
                    });
                    dispatch(setCurrentStepNumber(currentStepNumber + 1));
                    dispatch(setStep(step));
                })
                .catch((response) => {
                    setError(response.item_attributes
                        .filter(({ non_field_errors }) => non_field_errors)[0].non_field_errors[0]
                    );
                });
        }
    };

    const addMore = () => {
        dispatch(setItemTypeAttributeValue({ key: `person_${birthdates.length + 1}_dob`, value: undefined }));
    };

    const handleIndex = (val) => {
        if (error) {
            setError(null);
        }

        changeAttribute({ uuid: lead_uuid, attribute: 'postal_code' });
        if (val.length <= 5) {
            dispatch(setItemTypeAttributeValue({ key: 'postal_code', value: val }));
        }
    };

    const handleDateChange = (val, i) => {
        changeAttribute({ uuid: lead_uuid, attribute: `person_${i + 1}_dob` });
        dispatch(setItemTypeAttributeValue({ key: `person_${i + 1}_dob`, value: !val ? undefined : val }));
        dispatch(setItemTypeAttributeValue({ key: `insured_dob_${i + 1}`, value: val }));
    };

    const shiftPreItemAttributesValues = (index) => {
        let { value } = birthdates[index] || '';
        if (value === undefined) {
            value = '';
        }

        dispatch(setItemTypeAttributeValue({ key: `person_${index}_dob`, value }));
        dispatch(setItemTypeAttributeValue({ key: `insured_dob_${index}`, value }));
    };

    const shiftPostItemAttributesValues = (index, v) => {
        const keys = ['female', 'insured_first_name', 'insured_last_name'];
        keys.map((k) => {
            let { value } = attributes.filter(({ key }) => key === `${k}_${index + 1}`)[0];
            if (v !== undefined) {
                value = v;
            }
            dispatch(setItemTypeAttributeValue({ key: `${k}_${index}`, value }));
            return k;
        });
    };

    const handleBirthdayDelete = (i) => {
        for (let j = 1; j < birthdates.length - i; j += 1) {
        // sometimes it will be rewritten; now we need quick and dirty solution
            shiftPreItemAttributesValues(i + j);
            shiftPostItemAttributesValues(i + j);
        }
        shiftPreItemAttributesValues(birthdates.length);
        shiftPostItemAttributesValues(birthdates.length, '');
        setIsValid(s => ({
            ...s, [`person_${birthdates.length}_dob`]: true
        }));
    };

    useEffect(() => {
    // that means that form was saved before successfully
        if (codeAttribute) {
            setIsValid(s => ({ ...s, postal_code: !codeAttribute.value }));
        }
    }, []);
    useEffect(() => {
        if (codeAttribute.value !== undefined) {
            onCodeValidate();
        }
    }, [codeAttribute.value]);

    const setValidationStatus = (status, k) => {
        setIsValid(s => ({ ...s, [`person_${k + 1}_dob`]: status }));
    };

    useEffect(() => {
        setIsValid(s => ({ ...s, postal_code: !error }));
    }, [error]);

    const onShowHint = () => {
        setHintModalState({
            shown: true,
            label: '',
            hint: messages.INSURED_HINT
        });
    };

    const onCloseHint = () => setHintModalState({ shown: false });

    if (!codeAttribute) {
        return null;
    }

    return <>
        <Header
            stepper
            stepsTitle={messages.BENEFICIARY_POSTAL_TITLE} />
        <div className="beneficiary-postal-wrap main">
            <div className="discovered-element-wrap">
                <div className="discovered-input-wrap">
                    <div className="discovered-input-title">
                        <div className="discovered-input-lable">{messages.POSTAL_CODE}</div>
                    </div>
                    <InputWrap
                        img={pin}
                        className="discovered-input"
                        type="number"
                        value={codeAttribute.value}
                        placeholder="Ej. 10291"
                        onChange={v => handleIndex(v)}
                        // onBlur={onCodeValidate}
                        error={error}
                    />
                </div>
            </div>
            <div className="discovered-element-wrap group">
                <div className="discovered-input-wrap">
                    <div className="discovered-input-title">
                        <div className="discovered-input-lable">{messages.INSURED}</div>
                        <Hint
                            onClick={() => onShowHint()}
                        />
                    </div>
                </div>
            </div>
            {birthdates.map((b, k) => <div className="datepicker-wrapper" key={k}>
                <InputDatepicker
                    tag
                    future={false}
                    dateValue={b.value}
                    icon={calendar}
                    validStatus={s => setValidationStatus(s, k)}
                    onInputChange={(value) => { handleDateChange(value, k); }} />
                {/* <CustomDatePicker
                    tag
                    icon={calendar}
                    label=""
                    onChange={(value) => { handleDateChange(value, k); }}
                    key={k}
                    date={b.value}
                    validStatus={s => setValidationStatus(s, k)}
                /> */}
                {(k && <div onClick={() => handleBirthdayDelete(k)}><img className="trash-bin" alt="trash" src={trash} /></div>) || null}
            </div>)}
            {birthdates.length < 8 && <div className="add-more" onClick={() => addMore()}>{`+ ${messages.ADD_MORE}`}</div>}
        </div>
        <HintModal {...hintModalState} onClose={onCloseHint} />
        <Footer
            showBack={tags.indexOf('helvetia_explanation') > -1}
            nextText={messages.SAVE_AND_NEXT}
            onNextStep={onNextStep}
            disabled={!Object.values(isValid).every(item => item)}
            onNavBack={onBack}
        />
    </>;
};

export default HelvetiaPreAttributes;
