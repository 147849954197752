import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDetailsOption, selectWhiteLabelingStyles } from '../../../store/selectors';
import { setCurrentDetailsOption, /* setTargetPhoto,  *//* setBreadcrumbs, */ setCurrentStepNumber } from '../../../store/common/common.actions';
import {
    setStep,
    uploadPhoto,
    setResetIndex,
} from '../../../store/productInsurance/productInsurance.actions';
import Header from '../../Header';
import { messages } from '../../../constants/messages';
import './InformScreen.scss';
import history from '../../../helpers/history';
import ImageDropZone from '../../../helpers/ImageDropZone';
import { setupCustomStyles } from '../../../helpers/renderingHelpers';
import Footer from '../../Common/Footer/Footer';
import { DETAILS_OPTIONS, STEPS } from '../../../constants/constants';
import withTheme from '../../../hocs/withTheme';
import CheckBoxField from '../../Common/Checkbox/CheckBoxField';
import { useLanguage } from '../../../helpers/customHooks';
import { selectBreadcrumbs, selectCurrentStepNumber, selectExtensions/* selectTargetPhoto */ } from '../../../store/common/common.selector';

const InformScreen = () => {
    const dispatch = useDispatch();
    const [dropzone, setDropzone] = useState(null);
    useLanguage();

    const currentDetailsOption = useSelector(selectDetailsOption) || 'camera';
    const whiteLabelingStyles = useSelector(selectWhiteLabelingStyles);
    setupCustomStyles(whiteLabelingStyles);

    const currentStepNumber = useSelector(selectCurrentStepNumber);
    const breadcrumbs = useSelector(selectBreadcrumbs);
    const targetPhoto = breadcrumbs[currentStepNumber].target;

    const extensions = useSelector(selectExtensions);

    const onNextStep = () => {
        switch (currentDetailsOption) {
            case DETAILS_OPTIONS.CAMERA:
                const step = STEPS.TAKE_PHOTO;
                /* breadcrumbs.splice(currentStepNumber, 0, { step });
                dispatch(setBreadcrumbs(breadcrumbs));
                dispatch(setCurrentStepNumber(currentStepNumber + 1)); */
                dispatch(setStep(step));
                // history.push('/store-purchase');
                break;
            case DETAILS_OPTIONS.GALLERY:
                dropzone.open();
                break;
            case DETAILS_OPTIONS.MANUALLY:
                dispatch(setStep(STEPS.PRODUCTS_LIST));
                history.push('/store-purchase');
                break;
            default:
        }
    };

    const onBackStep = () => {
        const { step, mode } = breadcrumbs[currentStepNumber - 1];
        dispatch(setCurrentStepNumber(currentStepNumber - 1));
        dispatch(setStep(step));
        if (mode) {
            history.push('/insurances/');
        }
    };

    const uploadNewPhoto = (photo, type) => {
        dispatch(uploadPhoto({
            photo, type
        }, targetPhoto))
            .then(() => {
                dispatch(setResetIndex(false));
                const { step } = breadcrumbs[currentStepNumber + 1];
                dispatch(setCurrentStepNumber(currentStepNumber + 1));
                dispatch(setStep(step));

                // history.push('/store-purchase');
            });
    };

    return (
        <div className="inform-screen-wrap">
            <Header
                stepsTitle={targetPhoto === 'receipt_photo' ? messages.TAKE_RECEIPT_PHOTO : messages.TAKE_ITEM_PHOTO} />
            <div className="inform-screen-checkbox-wrap">
                <CheckBoxField
                    onClick={() => dispatch(setCurrentDetailsOption('camera'))}
                    label={messages.OPTION_TAKE_A_PHOTO}
                    checked={currentDetailsOption === 'camera'}>
                    <p className="checkbox-field-description">{messages.OPTION_TAKE_A_PHOTO_DESCRIPTION}</p>
                </CheckBoxField>
                <CheckBoxField
                    onClick={() => dispatch(setCurrentDetailsOption('gallery'))}
                    label={messages.OPTION_UPLOAD_FROM_GGALLERY}
                    checked={currentDetailsOption === 'gallery'}>
                    <p className="checkbox-field-description">{messages.OPTION_UPLOAD_FROM_GGALLERY_DESCRIPTION}</p>
                </CheckBoxField>
            </div>
            <Footer
                showBack={!!currentStepNumber}
                onNextStep={onNextStep}
                onNavBack={onBackStep}
            />
            <ImageDropZone
                accept={extensions}
                nodeRef={node => (setDropzone(node))}
                onSubmit={uploadNewPhoto}
            />
        </div>
    );
};

export default withTheme(InformScreen);
