import calculateColorWithOpacity from '../../helpers/calculateColor';

const setupCustomStyles = (
    {
        backgroundColor,
        clickableColor,
        errorColor,
        headerColor,
        infoColor,
        primaryColor,
        successColor,
        textColor,
        warningColor,
    }) => {
    const root = document.documentElement;
    root.style.setProperty('--background-color', backgroundColor);
    root.style.setProperty('--clickable-color', clickableColor);
    root.style.setProperty('--error-color', errorColor);
    root.style.setProperty('--header-color', headerColor);
    root.style.setProperty('--info-color', infoColor);
    root.style.setProperty('--primary-color', primaryColor);
    root.style.setProperty('--primary-color-opacity', calculateColorWithOpacity(primaryColor, 0.15));
    root.style.setProperty('--success-color', successColor);
    root.style.setProperty('--text-color', textColor);
    root.style.setProperty('--warning-color', warningColor);
};
export default setupCustomStyles;

export const detectMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem));
};
