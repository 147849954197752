import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PaymentProducts from '../../../containers/PaymentProducts';
import OfferManagement from '../../OfferManagement/OfferManagement';
import OtherSettings from '../../GeneralSettings/OtherSettings/OtherSettings';
import { selectPaymentProducts } from '../../../../store/issuerApp/issuerApp.selector';
import { patchPaymentProduct } from '../../../../store/issuerApp/issuerApp.actions';

const isDefaultSettings = value => (!value ? 'issuer_default' : 'payment_product');

const checkedStateMapper = (
    {
        isOtherSettingsOpen: brand_usage,
        isInsuranceCatalogueOpen: insurance_mapping_usage,
        isOfferingSectionOpen: offer_template_usage,
    }) => ({
    brand_usage: isDefaultSettings(brand_usage),
    insurance_mapping_usage: isDefaultSettings(insurance_mapping_usage),
    offer_template_usage: isDefaultSettings(offer_template_usage),
});

const PaymentProductsSettings = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [isChecked, setIsChecked] = useState(null);
    const [id, setId] = useState(null);
    const paymentProducts = useSelector(selectPaymentProducts);

    const handleChange = (e) => {
        const { name, checked: value } = e.target;
        setIsChecked((prev) => {
            const temp = {
                ...prev,
                [name]: value,
            };
            dispatch(patchPaymentProduct(checkedStateMapper(temp), id));
            return temp;
        });
    };


    useEffect(() => {
        const currentId = pathname.split('/').pop();
        setId(currentId);
        if (paymentProducts.length) {
            const {
                isOtherSettingsOpen,
                isInsuranceCatalogueOpen,
                isOfferingSectionOpen
            } = paymentProducts.find(({ uuid }) => uuid === currentId);
            setIsChecked({
                isOtherSettingsOpen,
                isInsuranceCatalogueOpen,
                isOfferingSectionOpen
            });
        }
    }, [paymentProducts]);

    return (
        isChecked && (
            <PaymentProducts>
                <OfferManagement
                    productSettings
                    checked={isChecked.isOfferingSectionOpen}
                    setChecked={handleChange}
                />
                <OtherSettings
                    productSettings
                    checked={isChecked.isOtherSettingsOpen}
                    setChecked={handleChange}
                />
            </PaymentProducts>
        )
    );
};
export default PaymentProductsSettings;
