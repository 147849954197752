import createReducer from '../createReducer';
import { CONTRACTS_LIST_ERROR, CONTRACTS_LIST_RECEIVED, CONTRACTS_LIST_REQUESTED, TOGGLE_CONTRACTS_LOADING } from './contract.actionTypes';

const contract = {
    contracts: [],
    loading: false
};

const toggleContractLoading = (state, { loading }) => ({ ...state, loading });

const contractsListRequested = state => ({ ...state, loading: true });

const contractsListReceived = (state, { data }) => ({ ...state, loading: false, contracts: data });

const contractsListError = state => ({ ...state, loading: false, contracts: [] });

const contractReducer = createReducer(contract, {
    [CONTRACTS_LIST_REQUESTED]: contractsListRequested,
    [CONTRACTS_LIST_RECEIVED]: contractsListReceived,
    [CONTRACTS_LIST_ERROR]: contractsListError,
    [TOGGLE_CONTRACTS_LOADING]: toggleContractLoading
});

export default contractReducer;
