import React from 'react';
import cn from 'classnames';

const CheckBox = ({ toggleCallback, label, checked }) => {
    const onToggle = () => {
        toggleCallback();
    };

    return <div
        className="siccess-read"
        onClick={onToggle}>
        <div className={cn('checkbox-off', { 'checkbox-on': checked })}>
            <div />
        </div>
        <div>{label}</div>
    </div>;
};

export default CheckBox;
