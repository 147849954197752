import React, { /* useState, */ useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// import { ConnectButton } from '360dialog-connect-button';
import { saveStatus } from '../../../../../store/issuerApp/issuerApp.actions';
import { messages } from '../../../../../constants/messages';
import connection from '../../../../../dist/images/whatsapp-connection.svg';

const Unconnected = ({ role, callback }) => {
    const history = useHistory();
    // https://hub.360dialog.com/dashboard/app/CnfNDzPA/permissions?plan_selection=premium&lang=es&hosting_type=onpremise&redirect_url=http%3A%2F%2Flocalhost%3A8080%2Fbroker-portal%3Ftab%3Dwhatsapp
    const baseUrl = encodeURIComponent(`${process.env.REACT_APP_API_URL}/broker-portal?tab=whatsapp#`);
    useEffect(() => {
        const params = new URLSearchParams(window.location.hash);
        if (window.location.hash) {
            const client_id = params.get('client');
            const channels = params.get('channels');
            const channel = channels.replace(/[[\]]/g, '').split(',');
            // //////////////////////////////////////////////////////////////
            saveStatus({ client_id, channel_ids: channel, default_channel_id: channel[0] });
            history.replace('/broker-portal?tab=whatsapp');
            callback();
        }
    }, [window.location.hash]);

    // plan_selection=premium&

    return <div className="whatsapp-wrapper">
        <p className="whatsapp-msg">{messages.BEFORE_USING}</p>
        <img className="whatsapp-img" src={connection} alt="connection" />
        {role === 'team_manager' ?
            <>
                <p className="whatsapp-msg">{messages.TO_CREATE_AND_CONNECT}</p>
                <ul>
                    {messages.POINTS.map((p, i) => <li className="whatsapp-msg" key={i}>{p}</li>)}
                </ul>
                <div className="whatsapp-wrapper_btn">
                    <div
                        className="whatsapp-btn"
                        onClick={() => {
                            window.open(`https://hub.360dialog.com/dashboard/app/CnfNDzPA/permissions?redirect_url=${baseUrl}&lang=es&hosting_type=onpremise`, '_self');
                            // close();
                        }}>
                        <button>{messages.CREATE_WHATSAPP_ACCOUNT}</button>
                        {/* <ConnectButton
                            partnerId="CnfNDzPA"
                            label={messages.CREATE_WHATSAPP_ACCOUNT}
                            queryParameters={{
                                redirect_url: 'http://localhost:8080/broker-portal?tab=whatsapp',
                                plan_selection: 'premium',
                                lang: 'es',
                                hosting_type: 'onpremise',
                            }} /> */}
                    </div>
                </div>
            </> :
            <p className="whatsapp-msg">{messages.PLEASE_INFORM}</p>}
    </div>;
};

export default Unconnected;
