import React from 'react';
import classNames from 'classnames';
import { Player, BigPlayButton } from 'video-react';
import GalleryIcon from './GalleryIcon';
import ImageDropZone from '../../../../helpers/ImageDropZone';

import 'video-react/dist/video-react.css';
import './Attachments.scss';


const UploadArea = ({ className, onClick }) => (
    <div
        className={classNames('upload-area', className)}
        onClick={onClick}>
        <GalleryIcon />
    </div>
);

const Attachment = ({ attachment, removeAttachment, type, url }) => (
    <div className="attachment">
        { type.startsWith('image')
            ? (<div className="attachment-image" style={{ backgroundImage: `url(${attachment})` }} />)
            : (
                <Player
                    src={url || attachment}
                    fluid={false}
                    width="calc(100% - 16px)"
                    height="calc(100% - 16px)"
                >
                    <BigPlayButton position="center" />
                </Player>
            )
        }
        <div className="remove-button" onClick={removeAttachment}>
            <p className="remove-button-icon">+</p>
        </div>
    </div>
);

const Attachments = ({ attachments, addAttachment, removeAttachment }) => {
    let dropdzone = null;

    const onUploadPhoto = () => {
        dropdzone.open();
    };

    const lineCount = attachments.length > 2 ? 3 : 2;

    const onSubmitDropzone = (attachment, type, url) => {
        addAttachment({
            attachment,
            type,
            url
        });
    };

    return (
        <div className="attachments-wrapper">
            { !!attachments.length && (
                <div className={classNames('attached-images', `group-${lineCount}`)}>
                    {attachments.map(({ attachment, type, url }, index) => (
                        <Attachment
                            key={index}
                            attachment={attachment}
                            url={url}
                            type={type}
                            removeAttachment={() => removeAttachment(index)}
                        />
                    ))}
                </div>
            )}
            {!attachments.length
            &&
            <div className="attach-files">
                <UploadArea className="upload-photo" onClick={onUploadPhoto} />
                <ImageDropZone
                    accept="image/*, video/*"
                    nodeRef={node => (dropdzone = node)}
                    onSubmit={onSubmitDropzone}
                />
            </div>}
        </div>
    );
};

export default Attachments;
