import React, { useState } from 'react';
import OutsideClick from '../../LanguageOption/OutsideClick';
import Button from '../Button/Button';

import './DropdownButton.scss';

const DropdownButton = ({ children }) => {
    const [isOpen, setOpen] = useState(false);

    const toggle = () => setOpen(!isOpen);

    return (
        <div className="dropdown-button-wrapper">
            <OutsideClick
                setOpen={setOpen}>
                <Button
                    onClick={toggle}
                    type="default"
                    className="toggle-button"
                >
                    ...
                </Button>
                {isOpen && <div className="dropdown-btn">{children}</div>}
            </OutsideClick>
        </div>
    );
};

export default DropdownButton;
