import React from 'react';
import Hint from '../../ProductInsurance/ItemAttributes/Hint';

import { messages } from '../../../constants/messages';

const Card = ({ data, showDetails }) => <div className="recommendation-card">
    <p className="input-lable insurance-title section-title">{data.title}</p>
    {
        Object.keys(data).map(k => (k !== 'title' && data[k].length ? <>
            <p className="input-lable section-title">{messages[k.toUpperCase()]}</p>
            {data[k].map((n, i) => <div className="recommendation-card_insurance"><p key={i} className="input-lable">{n.title}</p>
                {n.description && <Hint type onClick={() => showDetails(n.description)} />}</div>)}
        </> : null))
    }
</div>;

export default Card;
