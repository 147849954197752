import React from 'react';
import CheckBoxField from '../../Common/Checkbox/CheckBoxField';

// -_- thanks to be that boolean values we need to save as strings
const CheckBoxSet = ({ data, onChange, labels }) => <div className="discovered-card-wrap_gender">
    <CheckBoxField
        label={labels[0]}
        onClick={() => onChange('true')}
        checked={data === 'true'} />
    <CheckBoxField
        label={labels[1]}
        onClick={() => onChange('false')}
        checked={data === 'false'} />
</div>;


export default CheckBoxSet;
