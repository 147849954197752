import React, { useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { selectRedsysData } from '../../../store/selectors';

import Iban from './Iban/Iban';
import CheckBoxSet from './CheckBoxSet/CheckBoxSet';

import Logo_Helvetia from '../../../dist/images/Logo_Helvetia.png';

const Redsys = React.forwardRef(({
    product,
    isIban,
    errorCallback,
    isHelvetia,
    isSanitas,
    isDetailedDataPrivacy,
    titles }, ref) => {
    const data = useSelector(selectRedsysData);
    const handleSubmit = () => {
        document.getElementById('redsys_submit').click();
    };

    useImperativeHandle(ref, () => ({ handleSubmit }));

    return <>
        <div className="card-form-wrapper" style={{ padding: '0' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img alt="logo" src={Logo_Helvetia} style={{ height: 50 }} />
            </div>
            <p className="iban-label" style={{ fontSize: 15 }}>
                Por favor, facilítenos la información y confirmación que necesita
                la compañía de seguros Helvetia España.
            </p>
            {isIban && <Iban errorCallback={errorCallback} />}
            <CheckBoxSet
                isSanitas={isSanitas}
                product={product}
                isHelvetia={isHelvetia}
                isDetailedDataPrivacy={isDetailedDataPrivacy}
                titles={titles}
                errorCallback={errorCallback} />
            <p className="redsys-steps_title">{titles.NEXT_STEPS.title}</p>
            <ul className="redsys-steps">
                {
                    titles.NEXT_STEPS.steps.map((title, i) =>
                        <li key={i}><p>{title}</p></li>)
                }
            </ul>
            <form style={{ visibility: 'hidden', position: 'absolute' }} name="pago" action={data.url} method="POST">
                <input type="text" name="Ds_MerchantParameters" value={data.payload && data.payload.Ds_MerchantParameters} size="100" />
                <input type="text" name="Ds_Signature" value={data.payload && data.payload.Ds_Signature} size="100" />
                <input type="text" name="Ds_SignatureVersion" value={data.payload && data.payload.Ds_SignatureVersion} />
                <input type="submit" value="Probar contra test" id="redsys_submit" />
            </form>
        </div>
    </>;
});

export default Redsys;
