/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    selectActualFooterScreen,
    selectLoading,
    selectWhiteLabelingStyles
} from '../store/selectors';
import CardDetails from '../components/Payment/CardDetails/CardDetails';
import '../styles/Payment.scss';
import { STEPS } from '../constants/constants';
import withTheme from '../hocs/withTheme';
import { setupCustomStyles } from '../helpers/renderingHelpers';

const Payment = () => {
    const whiteLabelingStyles = useSelector(selectWhiteLabelingStyles);
    const actualFooterScreen = useSelector(selectActualFooterScreen);
    const loading = useSelector(selectLoading);

    useEffect(() => {
        setupCustomStyles(whiteLabelingStyles);
    }, []);

    const renderWizard = (step) => {
        switch (step) {
            case STEPS.CARD_DETAILS:
                return (<CardDetails />);
            default:
                return null;
        }
    };
    return (
        <div
            className="payment-wrap content"
            style={{
                pointerEvents: (loading && 'none')
            }}>
            {renderWizard(actualFooterScreen)}
        </div>
    );
};

export default withTheme(Payment);
