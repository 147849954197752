import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core';
import SideBar from '../components/SideBar/SideBar';
import setupCustomStyles from '../helpers/renderingHelpers';
import createTheme from '../helpers/theme';
// import GeneralSettings from './GeneralSettings';
import { selectStyles, selectIssuerProfile } from '../../store/issuerApp/issuerApp.selector';
import {
    fetchActiveWhiteLabeling,
    fetchPaymentProducts,
    fetchWhiteLabels,
    getSettings,
    getIssuerProfile
} from '../../store/issuerApp/issuerApp.actions';
import PaymentProductsSettings from '../components/PaymentProducts/PaymentProductSettings/PaymentProductsSettings';
import EditPaymentProducts from '../components/PaymentProducts/EditPaymentProducts/EditPaymentProducts';
import PaymentProductsSection from '../components/PaymentProducts/PaymentProductsSection/PaymentProductsSection';
import WhiteLabeling from '../components/WhiteLabeling/WhiteLabeling';
import EditWhiteLabeling from '../components/WhiteLabeling/EditWhiteLabeling/EditWhiteLabeling';
import OfferManagement from './OfferManagementContainer';
import DashboardContainer from './DashboardContainer';

import '../styles/ApplicationScreen.scss';

const drawerWidth = 300;

const useStyles = makeStyles({
    appScreenContent: {
        marginLeft: drawerWidth,
        padding: 28,
        position: 'relative',
    },
});

const ApplicationScreen = () => {
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();
    const [theme, setTheme] = useState(null);
    const styles = useSelector(selectStyles);
    const history = useHistory();

    const profile = useSelector(selectIssuerProfile);
    const [page, setPage] = useState(false);

    if (!profile) {
        dispatch(getIssuerProfile(true));
    }

    useEffect(() => {
        if (profile && profile.role) {
            setPage(profile.role === 'issuer');
            if (profile.role !== 'issuer') {
                history.push('/login');
            }
        }
    }, [profile]);

    useEffect(() => {
        dispatch(fetchActiveWhiteLabeling());
    }, []);

    useEffect(() => {
        setupCustomStyles(styles);
        setTheme(createTheme(styles));
        dispatch(getSettings());
        dispatch(fetchWhiteLabels());
        dispatch(fetchPaymentProducts());
    }, [styles]);

    const renderSwitch = (tab) => {
        switch (tab) {
            case '?tab=dashboard':
                return (<DashboardContainer />);
            case '?tab=analytics':
                return (<>analytics</>);
            case '?tab=general-settings':
                return (<OfferManagement />);
            /* case '?tab=offer-management':
                return (<OfferManagement />); */
            case '?tab=payment-products':
                return (<PaymentProductsSection />);
            case '?tab=white-labeling':
                return (<WhiteLabeling />);
            case '?tab=users-management':
                return (<>users-management</>);
            case '?tab=integration-setting':
                return (<>integration-setting</>);
            default:
                return (<></>);
        }
    };


    return (
        theme && page &&
        <ThemeProvider theme={theme}>
            <div className="application-screen-wrapper">
                <SideBar />
                <main className={classes.appScreenContent}>
                    {renderSwitch(location.search)}
                    <Switch>
                        <Route
                            path="/app/payment-products/create"
                            component={() => <EditPaymentProducts />}
                            exact
                        />
                        <Route
                            path="/app/payment-products/settings/:id"
                            component={() => <PaymentProductsSettings />}
                            exact
                        />
                        <Route
                            path="/app/payment-products/edit/:id"
                            component={() => <EditPaymentProducts />}
                            exact
                        />
                        <Route
                            path="/app/white-labeling/edit/:id"
                            component={() => <EditWhiteLabeling />}
                            exact
                        />
                        <Route
                            path="/app/white-labeling/create"
                            component={() => <EditWhiteLabeling />}
                            exact
                        />
                    </Switch>
                </main>
            </div>
        </ThemeProvider>
    );
};

export default ApplicationScreen;
