import React, { useEffect, useState, useRef } from 'react';
import './stepper.scss';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { messages } from '../../../constants/messages';
import { selectWhiteLabelingStyles, /* selectRecognisedData */ } from '../../../store/selectors';
import { selectCurrentStepNumber, selectBreadcrumbs } from '../../../store/common/common.selector';
import { useLanguage } from '../../../helpers/customHooks';

const Stepper = ({ stepsArr = null }) => {
    useLanguage();
    const { whiteLabelingPrimaryColor } = useSelector(selectWhiteLabelingStyles);
    const breadcrumbs = useSelector(selectBreadcrumbs);
    const steps = stepsArr || [messages.STEP1, messages.STEP2, messages.STEP3];
    const [currentStepNumber, setCurrentStepNumber] = useState(0);
    const refs = useRef([]);
    const line = useRef(null);
    const currentStepIndex = useSelector(selectCurrentStepNumber);

    useEffect(() => {
        const { current } = refs;
        const { offsetLeft, offsetWidth } = current[0];
        line.current.style.width = `${current[2].offsetLeft - offsetLeft}px`;
        line.current.style.left = `${offsetLeft + offsetWidth / 2}px`;
    }, [steps]);

    useEffect(() => {
        if (breadcrumbs.length) {
            setCurrentStepNumber(breadcrumbs[currentStepIndex].position);
        }
    }, [breadcrumbs, currentStepIndex]);

    useEffect(() => {
    // it is for fake flow with customized steps' titles
        if (stepsArr) {
            setCurrentStepNumber(currentStepIndex);
        }
    }, [currentStepIndex]);

    return <div className="step-wrapper">
        <div className="step-progress">
            {
                steps.map((el, key) => (
                    <div key={key} className="step">
                        <div className="step-container">
                            <div
                                ref={(element) => {
                                    refs.current[key] = element;
                                }}
                                className={cn('step-body', {
                                    'active-step': currentStepNumber === key,
                                    'passed-step': key < currentStepNumber
                                })}>
                                <div
                                    className="step-number">
                                    <span>{key + 1}</span>
                                </div>
                                <svg className="completed-step" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                                    <title>Select</title>
                                    <desc>Created with Sketch.</desc>
                                    <g id="Logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g id="Style_guide" transform="translate(-691.000000, -1516.000000)" stroke="#FFFFFF">
                                            <g id="Select" transform="translate(692.000000, 1517.000000)">
                                                <circle id="Oval" fill={whiteLabelingPrimaryColor} strokeLinejoin="round" cx="7" cy="7" r="7" />
                                                <polyline id="Path" strokeWidth="1.5" strokeLinecap="round" points="3.96666667 6.89649121 5.69534545 8.75 10.0333333 5.25" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className={cn('step-label', {
                                active: currentStepNumber === key,
                                passed: key < currentStepNumber
                            })}>{el}</div>
                        </div>
                    </div>))
            }
            <div className="progress-line" ref={line} />
        </div>
    </div>;
};


export default Stepper;
