import { createMuiTheme } from '@material-ui/core/styles';
import calculateColorWithOpacity from '../../helpers/calculateColor';

const createTheme = (
    {
        backgroundColor,
        clickableColor,
        errorColor,
        headerColor,
        infoColor,
        primaryColor,
        successColor,
        textColor,
        warningColor,
    }) => (
    createMuiTheme({
        palette: {
            main: {
                light: '#FFFFFF',
                main: backgroundColor,
                dark: headerColor,
                border: '#C0BEBF',
                hover: clickableColor,
                contrastText: textColor,
            },
            primary: {
                light: calculateColorWithOpacity(primaryColor, 0.15),
                main: primaryColor,
            },
            error: {
                main: errorColor,
            },
            warning: {
                main: warningColor,
            },
            info: {
                main: infoColor,
            },
            success: {
                main: successColor,
            }
        },
        typography: {
            fontFamily: 'Rubik',
        },
    })
);

export default createTheme;
