import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectWhiteLabelingStyles, selectInsurancesList, isInsuranceLogoVisible } from '../store/selectors';
import {
    setPullInsurance, setCurrentStepNumber
} from '../store/common/common.actions';
import Header from '../components/Header';
import Button from '../components/Common/Button/Button';
import InsuranceOverview from '../components/InsuranceOverview/InsuranceOverview';
import { messages } from '../constants/messages';
import history from '../helpers/history';
import { setupCustomStyles } from '../helpers/renderingHelpers';
import withTheme from '../hocs/withTheme';
import { useLanguage } from '../helpers/customHooks';
import { getInsuranceTypes, setStep, saveItemType, buildConfiguration, setCurrentGoodsId } from '../store/productInsurance/productInsurance.actions';
import '../styles/ProductInsurancesList.scss';

const ProductInsurancesList = () => {
    const dispatch = useDispatch();
    useLanguage();

    const whiteLabelingStyles = useSelector(selectWhiteLabelingStyles);
    setupCustomStyles(whiteLabelingStyles);

    const isLogoVisible = useSelector(isInsuranceLogoVisible);

    const insurancesList = useSelector(selectInsurancesList);

    const [isDetailsOpened, setIsDetailsOpened] = useState(false);
    const [selectedInsurance, setSelectedInsurance] = useState(null);

    const clickOnDetails = (e, insurance) => {
        if (insurance) {
            setSelectedInsurance({
                ...insurance.product_information,
                currency: insurance.currency_symbol,
                insurance
            });
        }
        setIsDetailsOpened(prev => !prev);
        e.stopPropagation();
    };

    const startPullFlow = insurance => Promise.resolve(dispatch(setPullInsurance(insurance)))
        .then(() => {
            dispatch(setCurrentGoodsId(insurance.item_types[0].id));
            dispatch(getInsuranceTypes());
            const flow = dispatch(buildConfiguration(insurance));
            const { step } = flow[1];

            dispatch(saveItemType())
                .then(() => {
                    history.push('/store-purchase');
                    dispatch(setCurrentStepNumber(1));
                    dispatch(setStep(step));
                });
        });

    return (!isDetailsOpened ?
        <div className="product-insurances__wrapper content">
            <Header
            // TODO: add to localization file
                stepsTitle="Select your insurance" />
            <div className="product-insurances__list">
                {
                    insurancesList.map((insurance, i) => {
                        const { currency_symbol, min_price, product_information } = insurance;
                        const { branding_name, product_description } = product_information;
                        // not to forget logo
                        return <div key={i} className="product-insurances__list__item">
                            <div className="product-insurances__list__item__details">
                                <div className="product-insurances__list__item__details__product">
                                    <div className="product-insurances__list__item__details__product__name">{branding_name}</div>
                                    {isLogoVisible && product_information.underwriter_logo && (
                                        <img className="product-insurances__logo" src={product_information.underwriter_logo} alt="insurance logo" />
                                    )}
                                </div>
                                <div />
                                <div className="product-insurances__list__item__details__product">
                                    <div className="product-insurances__list__item__details__product__description">
                                        <p>{product_description}</p>
                                        <div
                                            className="product-insurances__list__item__purchase__more"
                                            onClick={e => clickOnDetails(e, insurance)}>
                                            {messages.MORE_DETAILS}
                                        </div>
                                    </div>
                                    <div className={`product-insurances__list__item__details__price ${!isLogoVisible && 'to-top'}`}>
                                        <p>From</p>
                                        <div>
                                            <span className="product-insurances__list__item__details__price__currency">{currency_symbol}</span>&nbsp;
                                            <div className="product-insurances__list__item__details__price__total">
                                                {Math.floor(min_price || 0)}
                                                <span>{Math.floor(((min_price || 0) % 1).toFixed(2) * 100) || ''}</span>
                                            </div>
                                            <p>per month</p>
                                        </div>
                                    </div>
                                </div>
                                {/* // TODO: add to localization file */}
                            </div>
                            <div className="product-insurances__list__item__purchase">
                                <Button
                                    className="footer-btn product-insurances__buy-btn"
                                    onClick={() => startPullFlow(insurance)}
                                    size="medium">
                                    Buy
                                </Button></div>
                        </div>;
                    })
                }
            </div>
        </div> :
        <InsuranceOverview
            insuranceId={selectedInsurance.uuid}
            hide={clickOnDetails}
            cover={selectedInsurance.cover}
            nonCover={selectedInsurance.not_covered}
            brandingName={selectedInsurance.branding_name}
            productDescription={selectedInsurance.product_description}
            contractLengthUnit={selectedInsurance.contract_length_unit}
            contractLength={selectedInsurance.contract_length}
            contractMinimumLength={selectedInsurance.contract_minimum_length}
            contractMaximumLength={selectedInsurance.contract_maximum_length}
            paymentScheme={selectedInsurance.payment_scheme}
            productSheet={selectedInsurance.payment_sheet}
            currency={selectedInsurance.currency}
            deductible={selectedInsurance.deductible}
            isLogoVisible={isLogoVisible}
            logo={selectedInsurance.underwriter_logo}
            onNext={() => startPullFlow(selectedInsurance.insurance)}
        />
    );
};

export default withTheme(ProductInsurancesList);
