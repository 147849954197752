import React from 'react';
import './spinner.scss';

const Spinner = () => (
    <div className="spinner-load">
        <div className="spinner-load-wrapper">
            <div className="lds-dual-ring" />
        </div>
    </div>
);

export default Spinner;
