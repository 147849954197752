const hr = {
CHOOSE_ITEM_TO_CHECK: "Please choose the item for which you want to check coverage",
INSURANCE_COMPANY_LABEL: 'Insurance Company',
INSURANCE_PRODUCT_LABEL: 'Insurance Product',
    //steps
    STEP1: 'Podaci',
    STEP2: 'Ponuda',
    STEP3: 'Zatvaranje',
    CHOOSE_ITEM_TO_INSURE: 'Molimo odaberite artikl koji želite osigurati.',
    // Insurance overview
    INSURANCE_OVERVIEW: 'Pregled osiguranja',
    COVERAGE: 'Obuhvaćeno:',
    NON_COVERED: ' Nisu obuhvaćeni:',
    DOWNLOAD_INSURANCE_PRODUCT: 'Preuzmite detaljan opis osiguranja',
    DOWNLOAD_TERMS_AND_CONDITIONS: 'Preuzmite uvjete i odredbe',
    PRODUCT_INSURANCE_STEP_ONE_TITLE: 'Samo nekoliko koraka kako biste se osigurali',
    PRODUCT_INSURANCE_STEP_TWO_TITLE: 'Otkriveni proizvodi s potvrde',
    PRODUCT_INSURANCE_STEP_THREE_TITLE: 'Odaberite željenu opciju:',
    PRODUCT_INSURANCE_STEP_TWO_HINT: 'Odaberite proizvod koji ste kupili i kojeg želite osigurati.',
    PRODUCT_INSURANCE_STEP_ONE_ONLINE: 'Recite nam, što želite zaštititi.',
    ENTER_MORE_DETAILS: ' Unesite više detalja o proizvodu koji želite osigurati. ',
    TAKE_RECEIPT_PHOTO: ' Molimo fotografirajte račun.',
    CHECK_RECEIPT: 'Molimo provjerite je li račun na fotografiji jasno vidljiv.',
    CHECK_ITEM_PHOTO: 'Molimo provjerite je li stavka jasna na slici.',
    TAKE_ITEM_PHOTO: 'Molimo vas da fotografirate svoj predmet koji će biti pokriven.',
    BUTTON: 'Dugme',
    MORE_DETAILS: 'Prikaži detalje',
    BACK: 'leđa',
    SKIP_RECEIPT: 'Preskoči potvrdu',
    NEXT: 'Sljedeće',
    GENERAL_CONDITIONS: 'Opći uvjeti',
    LENGTH: 'Trajanje: ',
    MINIMUM_LENGTH: 'Minimalno trajanje ugovora: ',
    MAXIMUM_LENGTH: 'Maksimalno trajanje ugovora: ',
    CONTRACT_TERMINATION: 'Raskid ugovora: ',
    COVERAGE_LIST: 'Pokrivenost osiguranja',
    SEARCH: 'Traži',
    OTHER: 'Ostalo',
    CLOSE: 'Zatvoriti',
    PHOTO: 'Fotografija',
    OR: 'Ili',
    OPTIONS: 'Mogućnosti',
    SHOW_MORE: 'Pokaži više',
    DO_NOT_INSURE: 'Nemojte osigurati ovu stavku',
    NOT_INSURABLE: 'Ovaj predmet nije osiguran',
    SELECT_ITEM_TYPE: 'Odaberite vrstu predmeta',
    ADD_UNRECOGNIZED_ITEM: 'Dodajte stavku',
    NAME: 'Ime',
    PLEASE_WAIT: 'Molim te čekaj',
    RECOGNIZE_PROCESSING: 'Aplikacija obrađuje fotografije(e) kako bi izvukla relevantne informacije.',
    PRICE: 'Cijena',
    CANCEL: 'Otkazati',
    OK: 'Ok',
    SAVE_AND_NEXT: 'Sljedeće',
    MY_NEW_ITEM: 'Moja nova stavka',

    // Inform Screen
    DEAR_CUSTOMER: 'Dragi kupče,',
    TO_OFFER_YOU: 'Kako bismo Vam ponudili osiguranje s najpovoljnijim opcijama, trebamo više informacija o proizvodu koji ste upravo kupili. Odaberite što Vam najviše odgovara:',
    /* OPTION_TAKE_A_PHOTO: 'Fotografirajte račun',
    OPTION_UPLOAD_FROM_GGALLERY: 'Pošaljite fotografiju računa', */
    OPTION_TAKE_A_PHOTO: 'Fotografirajte račun',
    OPTION_UPLOAD_FROM_GGALLERY: 'Pošaljite fotografiju računa',
    OPTION_ENTER_MANUALLY: 'Unesite podatke ručno',
    OPTION_TAKE_A_PHOTO_DESCRIPTION: 'Možda će se zatražiti pristup kameri uređaja.',
    OPTION_UPLOAD_FROM_GGALLERY_DESCRIPTION: 'Možda će se zatražiti pristup fotografijama, medijima i datotekama.',

    // Empty State
    SORRY: 'Oprosti',
    SORRY_MESSAGE: 'Ispričavamo se, ali veza za ponudu više ne vrijedi. Rado ćemo vam ponuditi sljedeću kupnju.',
    DONE: 'Gotovo',

    // Payment Methods
    PAYMENT_METHODS: 'Metode Plačanja',
    HOW_DO_YOU_WANT_TO_PAY: 'Kako želite platiti?',
    YOU_WILL_BE_SPENDING: '********',
    FOR_BUYING_INSURANCE_POLICIES: '********',
    CONFIRM_YOUR_CARD_DETAILS: 'Unesite podatke o platnoj kartici.',
    HDRC_MONEY_BACK: 'Unesite podatke o platnoj kartici.',
    CARDHOLDER_NAME: 'Ime vlasnika kartice',
    CARD_NUMBER: 'Broj kartice',
    EXPIRY_DATE: 'Rok valjanosti',
    CVV: 'Sigurnosni kod (CVV)',
    CONFIRM_AND_PAY: 'Potvrdite i kupite',
    I_HAVE_SUCCESSFULLY_READ: 'Pročitao/la sam i slažem se s uvjetima i odredbama.',
    ENTRY_A_FEW_MORE_DETAILS: 'Unesite osobne podatke.',
    FIRST_NAME: 'Ime',
    FAMILY_NAME: 'Prezime',
    BIRTHDAY: 'Rođendan',
    EMAIL_ADDRESS: 'E-mail adresa',
    PHONE_NUMBERS: 'Broj telefona',
    ADDRESS: 'Adresa',
    ENTRY_HERE: 'Uđi ovdje',
    MONTHLY: 'mjesečno',
    WEEKLY: 'tjedno',
    YEARLY: 'godišnje',
    OVERVIEW_YEARLY: 'Godišnje',
    OVERVIEW_MONTHLY: 'Mjesečno',
    OVERVIEW_WEEKLY: 'Tjedni',
    WEEKS: 'tjedni',
    MONTHS: 'mjeseci',
    YEARS: 'godine',
    WHAT_IS_NEXT: 'Sljedeći koraci:',
    INSURANCE_CERTIFICATE: (email) =>  `Potvrda o osiguranju bit će generirana i poslana na ${email}.`,

    DEDUCTIBLE: 'Odbitna franšiza:',


    MANDATORY_FIELD: 'Ovo je polje obavezno',
    INVALID_FORMAT: 'Nevažeća vrijednost',
    PRICE_SHOULD_BE_LOWER: 'Cijena bi trebala biti niža od vrijednosti transakcije',
    DATE_SHOULD_BE_PAST: 'Datum bi trebao biti u prošlosti',

    // Congratulations!
    CONGRATULATIONS: 'Čestitamo!!',
    GLAD_TO_INFORM_YOU: 'Uspješno ste kupili osiguranje te je Vaš kupljeni proizvod sada osiguran.',

    YOU_WILL_RECEIVE: 'Kroz nekoliko minuta primit ćete e-mail poruku s potvrdom o osiguranju.',

    YOU_ARE_PROTECTED: 'Zaštićeni ste',
    ALREADY_PURCHASED: 'Drago nam je obavijestiti vas da ste već kupili osiguranje i da je vaš voljeni predmet već zaštićen.',

    DOWNLOAD: 'preuzimanje potvrde',
    YOU_CAN_DOWNLOAD_HERE: 'Potvrdu o osiguranju možete preuzeti ovdje:',
    BACK_TO_OVERVIEW: 'natrag na pregled',

    // contracts list
    YOUR_POLICY_PURCHASES: 'Vaše kupnje osiguranja',
    PURCHASED_ON: 'Kupljeno dana',
    EXPIRING_ON: 'Istječe dana',
    WHATS_COVERED: 'Što je pokriveno',
    FILE_A_CLAIM: 'Podnijeti pritužbu',
    REACTIVATE: 'Ponovno aktivirati',

    // contract details
    DOWNLOAD_PURCHASE_RECEIPT: 'Preuzmite potvrdu o kupnji',
    INSURCANCE_CONTRACT: 'Ugovor o osiguranju',
    ITEM_IS_PROTECTED_BY_INSURANCE: 'Predmet je zaštićen osiguranjem',
    INSURANCE_COVERAGE_START: 'Početak osiguranja',
    INSURANCE_COVERAGE_UNTIL: 'Osiguranje do',
    DOWNLOAD_PRODUCT_SHEET: 'Preuzmite list proizvoda',
    DOWNLOAD_INSURANCE_CERTIFICATE: 'Preuzimanje certifikata',
    CANCEL_CONTRACT: 'Otkaži ugovor',

    // claim form
    PROVIDE_INFO_FOR_CLAIM: 'Molimo vas da nam pružite još informacija prije nego što obradimo vaš zahtjev',
    ATTACH_PHOTO_VIDEO: 'Priložite, ako je potrebno, fotografije ili videozapise koji prikazuju štetu.',
    DESCRIBE_WHAT_HAPPEND: 'Molim vas opišite što se dogodilo',
    ENTER_HERE: 'Uđi ovdje',
    SUBMIT_CLAIM: 'Podnesite zahtjev',
    PLEASE_ATTACH_FILES: 'Priložite datoteke',
    THANKS_FOR_REQUEST: 'Hvala što ste podnijeli zahtjev. Rado ćemo ga obraditi i dati vam odgovor u narednim danima.',
    YOUR_INSURANCE_COVERS: 'Vaše osiguranje pokriva popravak samo jednog od naših certificiranih partnera. Ovdje možete pronaći servis za popravke u vašoj blizini.',
    SHOW_PARTNER_NETWORK: 'Prikaži partnersku mrežu',

    // confirmation
    YOUR_CONTRACT_HAS_BEEN_CANCELLED: 'Vaš je ugovor otkazan. Uskoro ćete dobiti e-poštu s potvrdom',
    GOT_IT: 'Shvatila sam',
    THE_INSURANCE_WILL_BE_CANCELLED: 'Osiguranje će se otkazati počev od',
    ARE_YOU_SURE_TO_CANCEL: 'Jeste li sigurni da želite otkazati zaštitu svog',
    RETURN: 'Povratak',
    CONFIRM_REACTIVATION: 'Molimo potvrdite da želite ponovno aktivirati ugovor.',
    YOUR_CONTRACT_HAS_BEEN_REACTIVATED: 'Vaš je ugovor ponovno aktiviran. Uskoro ćete dobiti poruku s potvrdom.',

    // contract statuses
    ACTIVE: 'Aktivni',
    PENDING_CANCELLATION: 'Otkazana',
    VOID: 'Prekinut',

    BENEFICIARY_TITLE: 'Tko će biti uključen u vaše osiguranje?',
    BENEFICIARY_QUESTION: 'Whom would you like to cover?',
    BENEFICIARY_OPTION1: 'sebe',
    BENEFICIARY_OPTION2: 'ja i moja obitelj/kućanstvo',
}

export default hr
