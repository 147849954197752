import React, { useState, useEffect } from 'react';
import InputWrap from '../../Common/Input/InputWrap';
import InputDatepicker from '../../Common/InputDatepicker/InputDatepicker';
// import { messages } from '../../../constants/messages';
import { validateNif, validateMandatory } from '../../../helpers/validators';

import './styles.scss';

const Card = ({ data = [], handleDataChange, title,
    errorCallback }) => {
    const [error, setError] = useState({});
    /* const [cardData, setCardData] = useState([]); */

    /* useEffect(() => { setCardData(data); }, [data]); */

    useEffect(() => {
        let err = {}; let
            status = {};
        data.map(({ key, value }, k) => {
            err = { ...err, [key]: key.includes('insured_tax_id') ? validateNif(value) : validateMandatory(value) };
            return k;
        });

        status = Object.keys(err)
            .reduce((acc, key) => { acc[key] = !err[key]; return acc; }, {});
        console.log(status);
        errorCallback(status);
    }, []);

    const onValidate = (k, v) => {
        const err = k.includes('insured_tax_id') ? validateNif(v) : validateMandatory(v);
        setError(prev => ({ ...prev, [k]: err }));
        errorCallback({ [k]: !err });
    };

    const onDataChange = (value, key) => {
        setError(prev => ({ ...prev, [key]: null }));
        handleDataChange(value, key);
        onValidate(key, value);
    };

    if (!data || !data.length) {
        return null;
    }

    return <div className="discovered-card-wrap">
        <p className="discovered-card-wrap_title">{title}</p>
        {
            data.map(({ key, value, label, activation_tag, type }, i) =>
                <div className="discovered-element-wrap insuree" key={i}>
                    <div className="discovered-input-wrap">
                        <div className="discovered-input-title">
                            <div className="discovered-input-lable">{label}</div>
                        </div>
                        {type === 'date' ?
                            <InputDatepicker
                                key={i}
                                dateValue={value}
                                future={activation_tag === 'date_future_only'}
                                icon={false}
                                validStatus={(s) => { errorCallback({ [key]: s }); }}
                                onInputChange={(v) => {
                                    handleDataChange(v, key);
                                }} /> :
                            <InputWrap
                                className="discovered-input"
                                type="text"
                                value={value}
                                onChange={(v) => { onDataChange(v, key); }}
                                /* onBlur={() => onValidate(key)} */
                                error={error[key]} />
                        }
                    </div>
                </div>)
        }
    </div>;
};

export default Card;
