import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartPulse, faTooth, faPlane, faCross, faSection, faCreditCard, faHospital, faHelicopter, faPersonFallingBurst, faWallet, faMobileRetro } from '@fortawesome/free-solid-svg-icons';
import { messages } from '../../../constants/messages';

const sections = {
    medical: faHeartPulse,
    dental: faTooth,
    travel: faPlane,
    legal: faSection,
    funeral: faCross
};

const travel = {
    cancellation: faCreditCard,
    hospitalization: faHospital,
    repatriation: faHelicopter,
    accident: faPersonFallingBurst,
    wallet_theft: faWallet,
    device_theft: faMobileRetro
};

const List = ({ isTravel }) => {
    const description = isTravel ? messages.TRAVEL : messages.HELVETIA_EXPLANATION;
    return <ul className="assistance-list">
        {
            Object.entries(isTravel ? travel : sections)
                .map(([key, value]) => <li key={key}>
                    <div className="assistance-list_icon-wrapper"><FontAwesomeIcon icon={value} /></div>
                    <div>{description[key].description}</div>
                </li>)
        }
    </ul>;
};

export default List;
