import { fetchWrapper } from '../../helpers/helpers';
import {
    SET_ACTUAL_FOOTER_SCREEN, SET_CART_NUMBER,
    SET_CART_EXPIRY_DATE, SET_CART_CVV, SET_ERROR_POPUP, SET_VALIDATION_ERROR,
    SET_PAYMENT_VALIDATION, SET_CARD_VALID, SET_CERTIFICATE_URL, SET_CUSTOMER_DATA,
    SET_PAYMENT_INSURAMA_URL, SET_PAYMENT_REDSYS_DATA, SET_PAYMENT_INITIATION_ERROR
} from './payment.actionTypes';
// eslint-disable-next-line import/no-cycle
import { toggleLoading } from '../common/common.actions';
import { selectCustomerData } from './payment.selector';
import { currentInsuranceId, selectCustomerAttributes } from '../productInsurance/productInsurance.selector';
import { selectActualLanguage, selectProfile } from '../common/common.selector';
import { messages } from '../../constants/messages';

export const setActualFooterScreen = data => ({ type: SET_ACTUAL_FOOTER_SCREEN, data });
export const setСardNumber = cardNumber => ({ type: SET_CART_NUMBER, cardNumber });
export const setСardExpiryDate = cardExpiryDate => ({ type: SET_CART_EXPIRY_DATE, cardExpiryDate });
export const setСardCvv = cardCvv => ({ type: SET_CART_CVV, cardCvv });
export const setErrorPopup = message => ({ type: SET_ERROR_POPUP, message });
export const setValidationError = error => ({ type: SET_VALIDATION_ERROR, error });
export const setPaymentValid = status => ({ type: SET_PAYMENT_VALIDATION, status });
export const setCardValid = status => ({ type: SET_CARD_VALID, status });
export const setCertificateUrl = url => ({ type: SET_CERTIFICATE_URL, url });
export const setCustomerData = ({ key, value }) => ({ type: SET_CUSTOMER_DATA, key, value });
export const setPaymentInsuramaUrl = url => ({ type: SET_PAYMENT_INSURAMA_URL, url });
export const setPaymentRedsysData = data => ({ type: SET_PAYMENT_REDSYS_DATA, data });
export const setPaymentInitiationError = error => ({ type: SET_PAYMENT_INITIATION_ERROR, error });

export function savePersonalDetails(iban) {
    return (dispatch, getState) => {
        const actualLanguage = selectActualLanguage(getState());
        const { address_json } = selectProfile(getState());
        dispatch(toggleLoading(true));
        const customerData = selectCustomerData(getState());
        const customerAttributes = selectCustomerAttributes(getState());
        const payload = customerAttributes.reduce((acc, { attribute }) => {
            acc[attribute] = customerData[attribute] ? customerData[attribute].trim() : '';
            if (attribute === 'birthday') {
                acc[attribute] = customerData[attribute].split('.').reverse().join('-');
            }
            if (attribute === 'address' && customerData.address_json) {
                acc.address_json = customerData.address_json;
                acc.address_json.administrative_area_level_2 =
                    customerData.administrative_area_level_2 ||
                    address_json.administrative_area_level_2;
            }
            return acc;
        }, {});

        if (!iban) {
            delete payload.iban_number;
        }

        return fetchWrapper({
            url: '/api/v1/customers/personality/',
            method: 'POST',
            body: payload,
            headers: {
                'accept-language': actualLanguage
            }
        })
            .catch((err) => {
                if (err.email) {
                    dispatch(setErrorPopup(err.email[0]));
                }
                /* if(err.id_number) {
                    dispatch(setErrorPopup(err.id_number[0]));
                } */
            })
            .finally(() => {
                dispatch(setPaymentInitiationError(false));
                dispatch(toggleLoading(false));
            });
    };
}

export const saveConsentStatuses = data => (dispatch, getState) => {
    const actualLanguage = selectActualLanguage(getState());
    // this order only
    const statuses = ['advertisement', 'profile_building', 'contact_after_contract'];
    const body = data.map((item, i) => ({
        [statuses[i]]: item !== 'false'
    }));

    return fetchWrapper({
        url: '/api/v1/customers/personality/',
        method: 'POST',
        body: { consent_statuses: Object.assign({}, ...body) },
        headers: {
            'accept-language': actualLanguage
        }
    });
};

export function checkData() {
    return (dispatch, getState) => {
        const actualLanguage = selectActualLanguage(getState());
        dispatch(toggleLoading(true));
        return fetchWrapper({
            url: '/api/v1/customers/customer-product/confirm/',
            method: 'GET',

            headers: {
                'accept-language': actualLanguage
            }
        })
            .then(() => Promise.resolve())
            .catch(err => Promise.reject(err))
            .finally(() => dispatch(toggleLoading(false)));
    };
}

export function submitPayment(id) {
    return (dispatch, getState) => {
        const actualLanguage = selectActualLanguage(getState());
        dispatch(toggleLoading(true));
        return fetchWrapper({
            url: '/api/v1/customers/payment/',
            method: 'POST',
            body: {
                payment_method_id: id,
                customer_product: currentInsuranceId(getState()) || ''
            },
            headers: {
                'accept-language': actualLanguage
            }
        })
            .then((response) => {
                dispatch(setCertificateUrl(response.certificate_url));
            })
            .finally(() => dispatch(toggleLoading(false)));
    };
}

export function triggerRedsys({ url, payload }) {
    return fetchWrapper({
        url,
        method: 'POST',
        sideRequest: true,
        body: payload,
    })
        .then((response) => {
            window.open(url);
            document.getElementById('redsys').innerHTML = response;
            // const { protocol, host } = new URL(url);
        });
}

export function paymentInitiation() {
    return (dispatch, getState) => {
        const actualLanguage = selectActualLanguage(getState());
        dispatch(toggleLoading(true));
        return fetchWrapper({
            url: '/api/v1/customers/payment/initiation/',
            method: 'POST',
            body: {
                payment_method_id: '',
                customer_product: currentInsuranceId(getState()) || ''
            },
            headers: {
                'accept-language': actualLanguage
            }
        })
            .then(({ gateway, gateway_data }) => {
                if (gateway === 'REDSYS') {
                    // triggerRedsys(gateway_data);
                    dispatch(setPaymentRedsysData(gateway_data));
                } else if (gateway === 'INSURAMA') {
                    dispatch(setPaymentInsuramaUrl(gateway_data.payment_url));
                }
            })
            .catch((response) => {
                if (response.status === 412) {
                    const res = response.error &&
                     Object.values(response.error).map(err => err[0]).join(', ');
                    dispatch(setPaymentInitiationError(res || messages.TRY_AGAIN));
                }
            })
            .finally(() => { dispatch(toggleLoading(false)); });
    };
}

export function submitSecurePayment(id, securId) {
    return (dispatch, getState) => {
        const actualLanguage = selectActualLanguage(getState());
        dispatch(toggleLoading(true));
        return fetchWrapper({
            url: '/api/v1/customers/payment/confirmation/',
            method: 'POST',
            body: {
                payment_method_id: id,
                customer_product: currentInsuranceId(getState()) || '',
                payment_intent_id: securId
            },
            headers: {
                'accept-language': actualLanguage
            }
        })
            .finally(() => dispatch(toggleLoading(false)));
    };
}
