import React, { useState, useEffect } from 'react';
import MerchantsSkeleton from '../MerchantsSkeleton';
import { fetchMerchantData } from '../../../store/merchants/merchants.actions';
import LeadReactionTab from './LeadReactionTab';
import MerchantIdentificationTab from './MerchantIdentificationTab';
import ProfileAndProductTab from './ProfileAndProductTab';
import { merchantProfileTabs, LeadReactionTitles, MerchantIdentificationTitles } from '../../constants';

const MerchantProfile = ({ pk }) => {
    const [merchantData, setMerchantData] = useState({});

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    useEffect(() => {
        const data = fetchMerchantData(pk);
        setMerchantData(data || {});
    }, []);

    const renderWizard = (tab) => {
        let component = null;
        switch (tab) {
            case 0:
                component = <ProfileAndProductTab data={merchantData} />;
                break;
            case 1:
                component = <LeadReactionTab
                    data={merchantData.pos_identification}
                    titles={LeadReactionTitles} />;
                break;
            case 2:
                component = <MerchantIdentificationTab
                    data={merchantData}
                    titles={MerchantIdentificationTitles} />;
                break;
            default: break;
        }
        return component;
    };

    return <MerchantsSkeleton
        title={merchantData.name}
        subtitle={merchantData.group_name}
        tabs={merchantProfileTabs}
        onTabClick={index => handleTabClick(index)}
        activeTab={activeTab}
        separate={!activeTab}
        parentLink={merchantData.group_uuid && `/merchant-groups/${merchantData.group_uuid}`}>
        {renderWizard(activeTab)}
    </MerchantsSkeleton>;
};

export default MerchantProfile;
