import React, { useState, useEffect } from 'react';
import { segment_fields } from './constants';
import SegmentFragment from './OfferManagementSegment/SegmentFragment';
import StrategyBlock from './OfferManagementOffer/StrategyBlock';
import SegmentEditMode from './OfferManagementSegment/SegmentEditMode';
import Footer from './OfferManagementCommon/Footer';
import { generateArray, offeringMapper, initialSegment } from '../../helpers/offerManagementHelpers';

const rows = ['Segment', 'Offer Text', 'Offered Services', 'Offer Strategy', 'Traffic Allocation'];


const SegmentsTable = ({ data = [], isCategory, onCategoryCancel,
    deleteOffer, saveSegment, saveStrategy, addOffer, categoryUuid, productId }) => {
    const [segments, setSegments] = useState([]);
    const [activeItems, setActiveItems] = useState([]);

    useEffect(() => {
        setSegments(data);
    }, [data]);

    const [newSegment, setNewSegment] = useState([initialSegment]);
    let addedSegment = { ...initialSegment };

    const returnSegmentForSave = (fData) => {
        let body = {};
        Object.entries(fData)
            .map(([k, v]) => {
                if (k === 'uuid') {
                    return k;
                }
                if (k === 'age_group_codes') {
                    body[k] = !Array.isArray(v) ?
                        generateArray(+v.min_age / 10, Math.trunc(+v.max_age / 10)) : v;
                } else if (k === 'address_since') {
                    body = { ...body, ...v };
                } else {
                    body[k] = (k === 'customer_regions' && typeof v === 'string') ? v.split(', ') : v;
                }

                return k;
            });
        return body;
    };

    const handleMouseEvent = (ids, eType) => {
        const uuids = !Array.isArray(ids) ? [{ uuid: ids }] : ids;

        setActiveItems((prev) => {
            const temp = [...prev];
            uuids.map(({ uuid }) => {
                if (prev.indexOf(uuid) === -1) {
                    temp.push(uuid);
                } else if (eType === 'cancel') {
                    temp.splice(temp.indexOf(uuid), 1);
                }
                return uuid;
            });
            return temp;
        });
    };

    useEffect(() => {
        setNewSegment(initialSegment);
    }, [isCategory]);

    const onStrategySave = async (i, fData) => {
        const body = returnSegmentForSave({ ...segments[i] });
        body.template_allocations = [...fData];
        const payload = offeringMapper(body);

        const { template_allocations } = await saveStrategy(payload, segments[i].uuid, productId);
        handleMouseEvent(segments[i].template_allocations, 'cancel');
        setSegments((prev) => {
            template_allocations.map(({ uuid }, j) => {
                if (!fData[j].uuid) {
                    fData[j] = { ...fData[j], uuid };
                }
                return j;
            });

            prev[i].template_allocations = [...fData];
            return [...prev];
        });
        return Promise.resolve();
    };

    // TODO: move to helpers
    const resetSegmentAttributes = (full, attributes) => {
        let res = { ...full };
        Object.keys(segment_fields)
            .map((f) => {
                if (f === 'culture') {
                    res.culture = attributes.culture;
                } else if (f === 'address_since') {
                    const { customer_address_since_min = 0,
                        customer_address_since_max = 0 } = attributes;
                    res = { ...res, customer_address_since_max, customer_address_since_min };
                } else {
                    res[f] = !attributes[f] ? [] : attributes[f];
                }
                return f;
            });
        return res;
    };

    const onSegmentSave = async (fData, segment) => {
        const body = returnSegmentForSave(fData);
        const { uuid } = segments[segment];

        const payload = resetSegmentAttributes(segments[segment], body);

        await saveSegment(offeringMapper(payload), uuid);
        handleMouseEvent(uuid, 'cancel');
        setSegments((prev) => {
            prev[segment] = payload;
            return [...prev];
        });
    };

    const onSegmentDelete = (segment) => {
        const { uuid } = segments[segment];
        deleteOffer(uuid, productId)
            .then(() => {
                setSegments((prev) => {
                    prev.splice(segment, 1);
                    return [...prev];
                });
                handleMouseEvent(uuid, 'cancel');
            });
    };

    const handleNewSegmentChange = (newData) => {
        addedSegment = { ...newData, template_allocations: addedSegment.template_allocations };
    };

    const handleNewOfferChange = (newData) => {
        addedSegment = { ...addedSegment, template_allocations: newData };
    };

    const onSave = () => {
        const body = returnSegmentForSave(addedSegment);
        body.offer_template_mapping = categoryUuid;

        addOffer(offeringMapper(body, 'add'))
            .then((response) => {
                setSegments((prev) => {
                    const temp = [...prev];
                    const { template_allocations,
                        customer_regions, age_group_codes } = response;

                    // it is done not to fetch data after adding and
                    // because response structure is not the same as needed

                    const allocations =
                    { ...template_allocations[0],
                        ...addedSegment.template_allocations[0],
                        offer_template: template_allocations[0].offer_template };

                    addedSegment = {
                        ...response,
                        ...addedSegment,
                        customer_regions,
                        age_group_codes,
                        template_allocations: [allocations]
                    };


                    temp.unshift(addedSegment);
                    return temp;
                });
                onCategoryCancel();
            });
    };

    return (
        <React.Fragment>
            <div className="offer-management-card__section__segments">
                <div className="offer-management-card__section__segments__segment" style={{ border: 'none' }}>
                    <span>{rows[0]}</span>
                </div>
                <div className="offer-management-card__section__segments__offer">
                    {
                        rows.map((row, i) => i > 0 && <span key={i}>{row}</span>)
                    }
                </div>
            </div>
            {isCategory &&
            <React.Fragment>
                <div className="offer-management-card__section__segments">
                    <SegmentEditMode
                        data={newSegment}
                        isFooter={false}
                        onChange={(newData) => { handleNewSegmentChange(newData); }} />
                    <StrategyBlock
                        data={newSegment.template_allocations}
                        showDelete={false}
                        onChange={(newData) => { handleNewOfferChange(newData); }}
                        isActive />
                    <div className="offer-management-card__section__segments__footer">
                        <Footer
                            onCancel={onCategoryCancel}
                            onSave={onSave}
                            action="add" />
                    </div>
                </div>
            </React.Fragment>}
            {
                segments.map((item, i) =>
                    <div className="offer-management-card__section__segments">
                        <SegmentFragment
                            handleMouseOver={() => handleMouseEvent(item.uuid, 'edit')}
                            data={item}
                            key={item.uuid}
                            isActive={activeItems.indexOf(item.uuid) > -1}
                            onSave={newData => onSegmentSave(newData, i)}
                            onDelete={() => onSegmentDelete(i)}
                            onCancel={() => handleMouseEvent(item.uuid, 'cancel')} />
                        <StrategyBlock
                            culture={item.culture}
                            data={item.template_allocations}
                            showDelete
                            onEdit={ids => handleMouseEvent(ids, 'edit')}
                            activeItems={activeItems}
                            onSave={fData => onStrategySave(i, fData)}
                            onCancel={e => handleMouseEvent(item.template_allocations, 'cancel', e)} />
                    </div>)
            }
        </React.Fragment>
    );
};

export default SegmentsTable;
