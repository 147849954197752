import { POSITIONS } from '../constants/constants';
import { selectTransactionPrice, selectTags } from '../store/common/common.selector';

export const formatCustomerAttributes = customer_attributes =>
    customer_attributes
        .map((attr) => {
            let v = attr.value || '';
            if (attr.attribute === 'birthday' && attr.value) {
                v = v.split('-').reverse().join('.');
            }
            return { ...attr, position: POSITIONS[attr.position], value: v };
        });

export const formatConfigAttributes = (configuration_attributes) => {
    const configAttributes = {
        itemPhotoPosition: {},
        receiptPhotoPosition: {}
    };
    configuration_attributes.map(({ key, position, insurance_products }) => {
        const field = `${key === 'receipt_photo' ? 'receipt' : 'item'}PhotoPosition`;
        configAttributes[field].position = POSITIONS[position];
        configAttributes[field].list = insurance_products;
        return key;
    });
    return [configAttributes];
};

export const formatItemAttributes = (item_attributes/* , price */) => {
    const attributes = [];
    item_attributes.map((attr) => {
        /*  if (attr.key === 'price' && price) {
            attr.value = price;
        } */
        const initial = attr.key === 'person_1_dob' || attr.key.includes('insured_dob') || attr.key.includes('female') || attr.key === 'RentalCarNeeded' || attr.key === 'postal_code' ? undefined : '';

        let val = attr.value;
        if ((attr.key.includes('dob') || attr.type === 'date') && attr.value) {
            val = val.split('-').reverse().join('.');
        }
        attributes.push({
            ...attr,
            position: POSITIONS[attr.position],
            value: val || initial
        });
        return attr;
    });
    return attributes;
};

export const formatBeneficiaryAttributes = (beneficiary_attributes, mode) => {
    const result = []; let forWhom = { position: 'DISABLED', forUuid: null };
    if (beneficiary_attributes.length) {
        forWhom = beneficiary_attributes.find(attribute => attribute.key === 'for_whom');
        forWhom.position = POSITIONS[forWhom.position];
        forWhom.forUuid = mode !== 'pull' && forWhom.insurance_products[0];
        if (beneficiary_attributes.length && forWhom.position !== 'DISABLED') {
            beneficiary_attributes.map((attribute) => {
                if (attribute.position !== 'DISABLED') {
                    if (attribute.key === 'for_whom') {
                        attribute.value = !attribute.value ? 'me' : attribute.value;
                    }
                    result.push({ ...attribute, value: attribute.value || '' });
                }
                return attribute;
            });
        }
    }

    return {
        beneficiaryAttributes: result,
        beneficiaryPosition: forWhom };
};

export const prefillDataHelper = (data, prefill) => (dispatch, getState) => {
    let final = data;
    final = data.map((attribute) => {
        const result = prefill
            .find((p) => {
                const field = `${attribute.key ? 'key' : 'attribute'}`;
                return p[field] === attribute[field];
            }) || {};

        if (attribute.key === 'price') {
            if (!result.value) {
                result.value = selectTransactionPrice(getState());
            }
            if (selectTags(getState()).indexOf('hide_item_price') > -1) {
                result.value = '';
            }
        }
        return { ...attribute, value: result.value };
    });

    return final;
};
