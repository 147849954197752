import React, { useEffect, useState } from 'react';
import { fetchGroupData, fetchGroupMerchants } from '../../../store/merchants/merchants.actions';
import MerchantsSkeleton from '../MerchantsSkeleton';
import GroupIdentificationTab from './GroupIdentificationTab';
import MerchantsChildrenTab from './MerchantsChildrenTab';
import { groupProfileTabs } from '../../constants';

const GroupProfile = ({ pk }) => {
    const [groupData, setGroupData] = useState({});

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    useEffect(() => {
        const data = fetchGroupData(pk);
        const merchants = fetchGroupMerchants(pk);
        setGroupData(() => ({ ...data, children: merchants } || {}));
    }, []);

    const renderWizard = (tab) => {
        let component = null;
        switch (tab) {
            case 0:
                component = <GroupIdentificationTab data={groupData} />;
                break;
            case 1:
                component = <MerchantsChildrenTab data={groupData.children} />;
                break;
            default: break;
        }
        return component;
    };

    return <MerchantsSkeleton
        title={groupData.group_name}
        subtitle={groupData.category}
        tabs={groupProfileTabs}
        onTabClick={index => handleTabClick(index)}
        activeTab={activeTab}
        separate={!activeTab}>
        {renderWizard(activeTab)}
    </MerchantsSkeleton>;
};

export default GroupProfile;
