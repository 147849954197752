import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActualLanguage } from '../store/common/common.selector';
import { /* setGoogleAPIReady, */ setRedsysAPIReady } from '../store/common/common.actions';
/* import { API_KEY } from '../helpers/googleMapAPI'; */

const withLocalization = Component => (props) => {
    const language = useSelector(selectActualLanguage).slice(0, 2);

    const dispatch = useDispatch();

    /* useEffect(() => {
        if (language) {
            const scripts = document.querySelectorAll('script[src*="google"]');
            if (scripts.length) {
                scripts.forEach(script => script.remove());
                // delete google;
                delete window.google;
            }

            dispatch(setGoogleAPIReady(false));

            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&language=${language}`;
            script.async = true;
            script.defer = true;
            script.onload = () => setTimeout(() => dispatch(setGoogleAPIReady(true)), 500);
            document.head.append(script);
        }
    }, [language]); */

    useEffect(() => {
        dispatch(setRedsysAPIReady(false));

        const script = document.createElement('script');
        script.src = 'https://sis-t.redsys.es:25443/sis/NC/sandbox/redsysV2.js';
        script.async = true;
        script.defer = true;
        script.onload = () => setTimeout(() => dispatch(setRedsysAPIReady(true)), 500);
        document.head.append(script);
    }, [language]);

    return (
        <Component {...props} />
    );
};

export default withLocalization;
