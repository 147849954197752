import React from 'react';
import Button from '../../Common/Button/Button';
import { messages } from '../../../../constants/messages';

const Footer = ({ onSave, onDelete, onCancel, onAdd, action }) => (<footer className="offer-management-card__section__segments__form__footer">
    <Button variant="text" onClick={(e) => { e.stopPropagation(); onCancel(); }}>
        {messages.CANCEL}
    </Button>
    {
        action !== 'add' && <Button variant="text" onClick={(e) => { e.stopPropagation(); onDelete(); }}>
            {messages.DELETE}
        </Button>
    }
    {
        onAdd && <Button variant="text" onClick={(e) => { e.stopPropagation(); onAdd(); }}>
            {/* TODO: to add to localization file */}
            Add new
        </Button>
    }
    <Button type="submit" onClick={(e) => { e.stopPropagation(); e.preventDefault(); onSave(); }}>
        {messages.SAVE}
    </Button>
</footer>);

export default Footer;
