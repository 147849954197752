import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faBell as faSolidBell } from '@fortawesome/free-solid-svg-icons';

import { messages } from '../../../../constants/messages';

const Profile = ({ name, surname, notification }) => {
    const history = useHistory();
    const [push, setPush] = useState(false);

    useEffect(() => {
        if (notification) {
            setPush(!!notification);
        }
    }, [notification]);

    const logOut = () => {
        localStorage.removeItem('iss_token');
        history.push('/login');
    };
    return <div className="menu-profile">
        <div><p className="menu-profile_item">{name} {surname}</p>
            {/* <p className="menu-profile_manage">{messages.MANAGE_PROFILE}</p> */}
            <p className="menu-profile_manage" onClick={logOut}>{messages.LOG_OUT}</p></div>
        <div className="bell">
            {push && <div className="push" />}
            <FontAwesomeIcon
                /* style={push && { color: '#747373' }} */
                icon={push ? faSolidBell : faBell}
                onClick={() => { setPush(false); history.replace('/broker-portal?tab=notifications'); }} />
        </div>
    </div>;
};

export default Profile;
