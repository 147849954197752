import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './LanguageOption.scss';
import {
    selectCultures,
    selectActualLanguage,
    selectCurrencyPosition
} from '../../store/common/common.selector';
import { setActualLanguage, setCurrencyPosition } from '../../store/common/common.actions';
import LanguageDropdown from './LanguageDropdown';


const LanguageOption = () => {
    const dispatch = useDispatch();

    const cultures = useSelector(selectCultures);
    const actualLanguage = useSelector(selectActualLanguage);
    const currencyPosition = useSelector(selectCurrencyPosition);

    if (cultures.length === 1) {
        return null;
    }

    const selectLanguage = (activeLang, position) => {
        dispatch(setActualLanguage(activeLang, true));
        dispatch(setCurrencyPosition(position));
    };

    return (
        <LanguageDropdown
            changeLang={selectLanguage}
            cultures={cultures}
            actualLanguage={actualLanguage}
            currencyPosition={currencyPosition}
        />
    );
};

export default LanguageOption;
