import React from 'react';
import loader from '../../../dist/images/loader.svg';
import './spinner.scss';

const Spinner = () => (
    <div className="spinner-wrapper">
        <div className="spinner">
            <img src={loader} alt="Loader" />
        </div>
    </div>
);

export default Spinner;
